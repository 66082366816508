import { IMeterPlace } from '../../../../common/interfaces/interface';
import { MeterPanelsActionTypes } from '../types/meterpanels.action.types';
import { IMeter } from '../../../../model/interfaces/IMeter';

export interface IMeterPanelState {
    meterPanels: Array<IMeterPlace>;
    metersETDT: Array<IMeter>;
    selectedMeterPanels: Array<IMeterPlace>;
    meterPanelsLoading: boolean;
    addedMeterPanelIdStart: string;
    addedMeterPanelNumber: number;
    postMeterPanelsLoadingStatus: boolean;
    postAppendDevicesLoadingStatus: boolean;
    selectedMeterPanel: IMeterPlace;
    allSelectedMeters: Array<any>;
    selectedMeterETDT: IMeter;
}

const initialState: IMeterPanelState = {
    meterPanels: [],
    selectedMeterPanels: [],
    metersETDT: [],
    meterPanelsLoading: false,
    addedMeterPanelIdStart: '',
    addedMeterPanelNumber: -1,
    postMeterPanelsLoadingStatus: false,
    postAppendDevicesLoadingStatus: false,
    selectedMeterPanel: null,
    allSelectedMeters: [],
    selectedMeterETDT: null
};

export const meterPanelReducer = (state: IMeterPanelState = initialState, action: any): IMeterPanelState => {
    switch (action.type) {
        case MeterPanelsActionTypes.METERPANEL_IS_LOADING:
            return Object.assign({}, state, {meterPanelsLoading: action.payload});
        case MeterPanelsActionTypes.SAVE_METERPANELS:
            return Object.assign({}, state, {meterPanels: [...action.payload]});
        case MeterPanelsActionTypes.SAVE_SELECTED_METERPANELS:
            return Object.assign({}, state, {selectedMeterPanels: [...action.payload]});
        case MeterPanelsActionTypes.ADDED_METERPANELS_START_ID:
            return Object.assign({}, state, {addedMeterPanelIdStart: action.payload});
        case MeterPanelsActionTypes.ADDED_METERPANELS_NUMBER:
            return Object.assign({}, state, {addedMeterPanelNumber: action.payload});
        case MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS:
            return Object.assign({}, state, {postMeterPanelsLoadingStatus: action.payload});
        case MeterPanelsActionTypes.POST_APPENDDEVICES_LOADING_STATUS:
            return Object.assign({}, state, {postAppendDevicesLoadingStatus: action.payload});
        case MeterPanelsActionTypes.SAVE_METERS_ETDT:
            return Object.assign({}, state, {metersETDT: action.payload});
        case MeterPanelsActionTypes.SAVE_SELECTED_METER:
            return Object.assign({}, state, {selectedMeterPanel: action.payload});
        case MeterPanelsActionTypes.SAVE_ALL_SELECTED_METERS:
            return Object.assign({}, state, {allSelectedMeters: action.payload});
        case MeterPanelsActionTypes.SAVE_SELECTED_METER_ETDT:
            return Object.assign({}, state, {selectedMeterETDT: action.payload});
        default:
            return Object.assign({}, state);

    }
};