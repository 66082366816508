import { IconButton, Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Badge from '@material-ui/core/Badge';
import { Notifications } from '@material-ui/icons';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { CustomerActions } from '../../../features/customers/redux';
import { ICustomerState } from '../../../features/customers/redux/reducers/customer.reducer';
import { FacilityActions } from '../../../features/facilities/redux';
import { LoginActions } from '../../../features/login/redux';
import { SearchActions } from '../../../features/search/redux';
import { ISearchState } from '../../../features/search/redux/reducers/search.reducer';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { IReduxState } from '../../../model/redux-state';
import { history, store } from '../../../store';
import { sbColors } from '../../../theme/app.colors';
import { IKAB } from '../../interfaces/interface';
import { GlobalActions } from '../../redux';
import { IGlobalState } from '../../redux/reducers/global.reducer';
import { SlAutoComplete } from '../autocomplete';
import { SlBreadcrumbsComponent } from '../breadcrumbs/sl-breadcrumbs.component';
import { HomeIcon } from '../svgIcons/Home';
import { ISlTab, SlTabs } from '../tabs/sl-tabs.component';
import { getAppVersion } from '../../utils/getAppversion';
import { fetchSlimAuftrag } from '../../../features/slim/slim-api/auftrag-api/slimAuftrag.api';
import { SlimActions } from '../../../features/slim/redux';
import { ISlimState } from '../../../features/slim/redux/reducers/slim.reducer';
import { saveRefreshSlimNotification } from '../../../features/slim/redux/actions/slim.actions';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            width: '100%',
        },
        grow: {
            flexGrow: 1,
        },
        homeButton: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        menu: {
            cursor: 'pointer',
        },
        secondToolBar: {
            backgroundColor: sbColors.white,
        },
    });

export interface IHeaderProps extends WithStyles<typeof styles> {
    customerState: ICustomerState;
    searchState: ISearchState;
    globalState: IGlobalState;
    slimState: ISlimState;
    selectedTab: number;
    logout: () => void;
    currentLocation?: any;
    tabs?: Array<ISlTab>;
    onTabClick?: (tab: number) => void;
    getSuggestions?: (query: string) => void;
    showOnlyBackArrow?: boolean;
    saveAnlageActiveTab: (tab: number) => void;
}

interface IState {
    kabID: string;
    anchorEl: any;
    suggestions?: Array<any>;
    slimAuftrags?: Array<any>;
    slimAuftragsAnchorEL?: any;
}

export class Header extends React.Component<IHeaderProps, IState> {
    public static defaultProps: Partial<IHeaderProps> = {
        tabs: [],
    };

    constructor(props: IHeaderProps) {
        super(props);
        this.state = { kabID: '', anchorEl: null, slimAuftrags: [], slimAuftragsAnchorEL: null };
    }

    public handleSignOut = () => {
        this.props.logout();
    };

    public goToSetting = () => {
        history.push('/einstellungen');
    };
    public goToKundenanlage = item => {
        this.props.saveAnlageActiveTab(2);
        history.push('/slim/kabs/' + item.kundenanlagenbetreiber.KID + '/' + item.kundenanlage.AID);
    };

    public handleOnSuggestionSelected = (suggestion: any) => {
        const path = suggestion.Path.split('#');
        history.push(`/kabs/${path.join('/')}`);
    };

    public navigateTo = item => {
        // console.log('navigateTo: ', item);
        history.push(item && item.path ? item.path : '/kabs');
    };

    public fetchSuggestionApi = (event: { value: string; reason: string }) => {
        if (event.reason === 'input-changed' && event.value.length > 2) {
            this.props.getSuggestions(event.value);
        }
    };

    public async getNotification() {
        const slimData = await fetchSlimAuftrag('BEAUFTRAGT');
        // console.log('fetchSlimAuftrag: ', slimData);
        this.setState({ ...this.state, slimAuftrags: slimData.items ?slimData.items:[]});
    }

    public async componentDidMount() {
        this.getNotification();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.slimState && this.props.slimState.refreshSlimNotification) {
            console.log(this.props.slimState.refreshSlimNotification);
            store.dispatch(saveRefreshSlimNotification(false));
            this.getNotification();
        }
    }

    private buildBreadCrumbs = () => {
        if (
            this.props.customerState.selectedCustomer &&
            this.props.currentLocation &&
            this.props.currentLocation.pathname
        ) {
            const customer: IKAB = this.props.customerState.selectedCustomer;
            const name = customer.Anrede.Person
                ? customer.Anrede.Person.Nachname
                : customer.Anrede.Firma.Firmenname1;
            const { pathname } = this.props.currentLocation;
            const paths = pathname.split('/').filter(path => path !== '');
            paths.shift(); // remove the first item
            if (paths.length > 0) {
                return paths.map((path, index) =>
                    index === 0
                        ? {
                              label: `${path} - ${name}`,
                              path: `/kabs/${path}`,
                          }
                        : {
                              label: path,
                              path: `/kabs/${paths.slice(0, index).join('/')}/${path}`,
                          }
                );
            } else {
                return [
                    {
                        label: this.props.currentLocation.pathname.substring(1),
                        path: `${this.props.currentLocation.pathname}`,
                    },
                ];
            }
        } else {
            return this.props.currentLocation && this.props.currentLocation.pathname
                ? [
                      {
                          label: this.props.currentLocation.pathname.substring(1),
                          path: `${this.props.currentLocation.pathname}`,
                      },
                  ]
                : [];
        }
    };
    private renderSlimAuftrags() {
        return this.state.slimAuftrags.map(item => {
            const { kundenanlagenbetreiber, kundenanlage } = item;
            const { kabStammdaten } = kundenanlagenbetreiber;
            const { Anrede } = kabStammdaten;
            const { Firma } = Anrede;

            return (
                <MaterialUI.ListItem
                    button={true}
                    onClick={() => this.goToKundenanlage(item)}
                    key={item.id}
                >
                    <MaterialUI.ListItemText
                        primary={`${item.zaehlerplatzIDs.length} Zähler wurden bestellt`}
                        secondary={
                            <React.Fragment>
                                <span>{Firma && Firma.Firmenname1 ? Firma.Firmenname1 : ''}</span>
                                {' — '} {kundenanlage ? kundenanlage.KAname : ''}
                            </React.Fragment>
                        }
                    />
                </MaterialUI.ListItem>
            );
        });
    }

    private renderAppMenu() {
        return (
            <div style={{ textAlign: 'right' }}>
                <IconButton
                    aria-label="notifications"
                    color={'inherit'}
                    onClick={event => this.setState({ slimAuftragsAnchorEL: event.currentTarget })}
                >
                    <Badge badgeContent={this.state.slimAuftrags.length} color="secondary">
                        <Notifications color={'inherit'} />
                    </Badge>
                </IconButton>

                <MaterialUI.Button
                    data-testid="header-menu"
                    className={this.props.classes.menu}
                    color={'inherit'}
                    aria-owns={this.state.anchorEl ? 'fade-menu' : null}
                    onClick={event => this.setState({ anchorEl: event.currentTarget })}
                >
                    <MaterialUIIcons.MoreVert color={'inherit'} />
                </MaterialUI.Button>
                <MaterialUI.Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClick={() => this.setState({ anchorEl: null })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MaterialUI.List component="nav">
                        <MaterialUI.ListItem button={true} onClick={this.goToSetting}>
                            <MaterialUI.ListItemIcon>
                                <MaterialUIIcons.Settings />
                            </MaterialUI.ListItemIcon>
                            <MaterialUI.ListItemText primary={deTranslator.global.setting} />
                        </MaterialUI.ListItem>
                        <MaterialUI.ListItem button={true} onClick={this.handleSignOut}>
                            <MaterialUI.ListItemIcon>
                                <MaterialUIIcons.ExitToApp />
                            </MaterialUI.ListItemIcon>
                            <MaterialUI.ListItemText primary={deTranslator.global.logout} />
                        </MaterialUI.ListItem>
                        <MaterialUI.ListItem>
                            <MaterialUI.ListItemText secondary={`Version: ${getAppVersion()}`} />
                        </MaterialUI.ListItem>
                    </MaterialUI.List>
                </MaterialUI.Popover>

                <MaterialUI.Popover
                    open={Boolean(this.state.slimAuftragsAnchorEL)}
                    anchorEl={this.state.slimAuftragsAnchorEL}
                    onClick={() => this.setState({ slimAuftragsAnchorEL: null })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MaterialUI.List component="nav">{this.renderSlimAuftrags()}</MaterialUI.List>
                </MaterialUI.Popover>
            </div>
        );
    }

    public render() {
        return (
            <div>
                <MaterialUI.AppBar position="absolute">
                    <MaterialUI.Toolbar>
                        <MaterialUI.Grid item={true} xs={3} style={{ display: 'flex' }}>
                            <MaterialUI.Button onClick={this.navigateTo} color={'inherit'}>
                                <HomeIcon color={sbColors.orange} style={{ paddingTop: 5 }} />
                                {deTranslator.appTitle}
                            </MaterialUI.Button>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={6} style={{ textAlign: 'center' }}>
                            <SlAutoComplete
                                suggestions={this.props.searchState.suggestions}
                                onSuggestionSelected={this.handleOnSuggestionSelected}
                                fetchSuggestions={this.fetchSuggestionApi}
                            />
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={3}>
                            {this.renderAppMenu()}
                        </MaterialUI.Grid>
                    </MaterialUI.Toolbar>

                    <MaterialUI.LinearProgress
                        color={'secondary'}
                        variant={
                            this.props.globalState.loadingList.length === 0
                                ? 'determinate'
                                : 'indeterminate'
                        }
                        value={this.props.globalState.loadingList.length === 0 ? 100 : 0}
                    />

                    {this.buildBreadCrumbs().length > 0 ? (
                        <MaterialUI.Toolbar className={this.props.classes.secondToolBar}>
                            <SlBreadcrumbsComponent
                                items={this.buildBreadCrumbs()}
                                itemOnClick={this.navigateTo}
                            />
                        </MaterialUI.Toolbar>
                    ) : null}
                    {this.props.showOnlyBackArrow && (
                        <MaterialUI.Toolbar className={this.props.classes.secondToolBar}>
                            <SlBreadcrumbsComponent
                                showOnlyBackArrow={true}
                                items={this.buildBreadCrumbs()}
                                itemOnClick={this.navigateTo}
                            />
                        </MaterialUI.Toolbar>
                    )}

                    {this.props.tabs.length > 0 ? (
                        <MaterialUI.Toolbar className={this.props.classes.secondToolBar}>
                            <SlTabs
                                selectedTab={this.props.selectedTab}
                                tabs={this.props.tabs}
                                onTabClick={this.props.onTabClick}
                            />
                        </MaterialUI.Toolbar>
                    ) : (
                        ''
                    )}
                </MaterialUI.AppBar>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        slimState: state.slimState,
        customerState: state.customerState,
        searchState: state.searchState,
        globalState: state.globalState,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            SlimActions,
            CustomerActions,
            GlobalActions,
            FacilityActions,
            SearchActions,
            LoginActions
        ),
        dispatch
    );
};

export const HeaderComponent = MaterialUI.withStyles(styles)(Header);

export const SlHeader = connect<IHeaderProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<IHeaderProps>(MaterialUI.withStyles(styles)(Header));
