import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { ISlTab } from '../../../common/components/tabs/sl-tabs.component';
import {
    IAbleseTyp,
    IBillRecipient,
    IContactData,
    IDevice,
    IFacility,
    IKAB,
    IMeterPlace,
    IProduct,
} from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { ArchivesActions } from '../../archiv/redux';
import { IArchiveState } from '../../archiv/redux/reducers/archive.reducer';
import { CustomerActions } from '../../customers/redux';
import { ICustomerState } from '../../customers/redux/reducers/customer.reducer';
import { SlDevices } from '../../devices/components/devices/sl-devices.component';
import { SlMeterReading } from '../../devices/components/devices/sl-meter-reading.component';
import { DevicesActions } from '../../devices/redux';
import { IDevicesState } from '../../devices/redux/reducers/devices.reducer';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { IMeterPanelState } from '../../meterpanels/redux/reducers/meterpanels.reducer';
import { SettingsActions } from '../../settings/redux';
import { SlFacilityContacts } from '../components/sl-facility-contacts.component';
import { SlFacilityDataDetails } from '../components/sl-facility-data-details.component';
import { SlMeterPanels } from '../components/sl-meter-panel.component';
import { SLFacility } from '../forms/sl-facility.form';
import { ContactPersonActions, FacilityActions } from '../redux';
import { IContactPersonState } from '../redux/reducers/contactperson.reducer';
import { IFacilityState } from '../redux/reducers/facility.reducer';
import {
    buildDevicesDataTable,
    buildMeterPlacesDataTable,
    buildReadingData,
} from '../services/facilities.service';

export const facilityStyles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(3),
        },
        tabContainer: {
            height: '100vh',
            // backgroundColor: sbColors.white,
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 260px)',
            padding: theme.spacing(3),
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },
    });

export interface IKabFacilityDetailsPageProps extends WithStyles<typeof facilityStyles> {
    customerState: ICustomerState;
    facilityState: IFacilityState;
    contactPersonState: IContactPersonState;
    meterPanelState: IMeterPanelState;
    deviceState: IDevicesState;
    archiveState: IArchiveState;
    loadingList: Array<string>;
    match: any;
    location: any;
    ableseTypeList: Array<IAbleseTyp>;
    getCustomerById: (kabID: string) => void;
    saveSelectedCustomer: (customer: IKAB) => void;
    deleteContactData: (kabID: string) => void;
    setError: (error: IError) => void;
    error: IError;
    getBillToCustomer: (kabID: string) => void;
    getContactData: (kabID: string) => void;
    updateCustomer: (payload: IKAB) => void;
    updateBillToCustomer: (payload: IBillRecipient) => void;
    postBillToCustomer?: (value, isCreatedFromEditPage?: boolean) => void;
    postContactData: (value, isCreatedFromEditPage?: boolean) => void;
    updateContactData: (value) => void;
    getFacilities: (kabId: string) => void;
    getFacilityById: (kabID: string, aID: string) => void;
    saveSelectedFacility: (facility: IFacility) => void;
    getMeterPanels: (kabID: string, aID: string) => void;
    getContactPersons: (kabID: string, aID: string) => void;
    postContactPerson: (kabID: string, aID: string, contact: IContactData) => void;
    updateContactPerson?: (kabID: string, aID: string, contact: IContactData) => void;
    deleteContactPerson: (kabID: string, aID: string, cID: string) => void;
    getDevices: (kabId: string, anlageId: string) => void;
    saveSelectedDevice: (item: IDevice) => void;
    saveDevices: (items: Array<IDevice>) => void;
    addMeterReading: (kabID: string, aID: string, reading, selectedDevices) => void;
    updateMeterPanels: (kabID: string, aID: string, payload: Array<IMeterPlace>) => void;
    facilityForm: IForm;
    contactPersonForm: IForm;
    auftragForm: IForm;
    addReadingForm: IForm;
    editReadingForm: IForm;
    removeDeviceForm: IForm;
    updateFacility: (kabID: string, payload: IFacility) => void;
    getArchives: (kabID: string, aId: string) => void;
    postDevices: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    fetchFacilityData: (kabID: string, aID: string) => void;
    productList: Array<IProduct>;
    getProductList?: () => void;
    getFacilityServicesTyp?: () => void;
    saveActiveTab?: (tab: number) => void;
    getAbleseTypeList?: () => void;
}

interface IState {
    cardElevation: number;
    selectedTab: ISlTab;
    openFacilityModal: boolean;
}

class KabFacilityDetails extends React.Component<IKabFacilityDetailsPageProps, IState> {
    private readonly tabs: Array<ISlTab>;
    private kabID: string = null;
    private aID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;
    private allDevices: IDevice[] = [];

    constructor(props: IKabFacilityDetailsPageProps) {
        super(props);
        this.kabID = props.match.params.kabID;
        this.aID = props.match.params.aID;
        this.tabs = [
            {
                label: deTranslator.facility.masterData,
            },
            {
                label: deTranslator.global.contact,
            },
            {
                label: deTranslator.meterPanel.title,
            },
            {
                label: deTranslator.device.title,
            },
            {
                label: deTranslator.device.reading,
            },
            /* {
                 label: deTranslator.global.services,
             },*/
            {
                label: deTranslator.global.billing,
            },
            /* {
                 label: deTranslator.global.archiv
             },*/
        ];

        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ openFacilityModal: true }),
            },
        ];

        this.state = {
            cardElevation: 1,
            selectedTab: this.tabs[0],
            openFacilityModal: false,
        };
    }

    public componentDidMount() {
        // fetch bodyData from server
        this.props.fetchFacilityData(this.kabID, this.aID);
        this.props.getProductList();
        this.props.getAbleseTypeList();
    }

    public componentWillUnmount() {
        this.props.saveDevices([]);
    }

    public componentDidUpdate(prevProps) {
        if (
            this.props.match.params.kabID !== prevProps.match.params.kabID ||
            this.props.match.params.aID !== prevProps.match.params.aID
        ) {
            this.kabID = this.props.match.params.kabID;
            this.aID = this.props.match.params.aID;
            this.props.fetchFacilityData(this.kabID, this.aID);
        }
    }

    public handleAddContact = async () => {
        await this.props.postContactPerson(
            this.kabID,
            this.aID,
            this.props.contactPersonForm.formValue
        );
        this.props.getContactPersons(this.kabID, this.aID);
        this.props.getFacilityById(this.kabID, this.aID);
    };
    public handleEditContact = async () => {
        await this.props.updateContactPerson(
            this.kabID,
            this.aID,
            this.props.contactPersonForm.formValue
        );
        this.props.getContactPersons(this.kabID, this.aID);
        this.props.getFacilityById(this.kabID, this.aID);
    };

    private handleDeleteContact = async (contactPerson: IContactData) => {
        await this.props.deleteContactPerson(this.kabID, this.aID, contactPerson.ID);
        this.props.getContactPersons(this.kabID, this.aID);
        this.props.getFacilityById(this.kabID, this.aID);
    };

    private handleDismissError = () => {
        this.props.setError(null);
    };

    private buildTabs = () => {
        return this.tabs.map((tab: ISlTab) => {
            switch (tab.label) {
                case deTranslator.global.contact:
                    tab.badge = this.props.contactPersonState.contactPersons.length;
                    return tab;
                case deTranslator.meterPanel.title:
                    tab.badge = this.props.meterPanelState.meterPanels.length;
                    return tab;
                case deTranslator.device.title:
                    this.allDevices = this.props.deviceState.devices;
                    tab.badge = this.allDevices.filter(
                        item => (item.Ausbaudatum && item.Ausbaudatum === '') || !item.Ausbaudatum
                    ).length;
                    return tab;
                case deTranslator.global.archiv:
                    tab.badge = this.props.archiveState.archiveFiles.length;
                    return tab;
                case deTranslator.device.reading:
                    tab.badge = this.props.deviceState.devices.filter(
                        item => (item.Ausbaudatum && item.Ausbaudatum === '') || !item.Ausbaudatum
                    ).length;
                    return tab;
                default:
                    return tab;
            }
        });
    };

    public handleUpdateFacility = async () => {
        this.setState({ openFacilityModal: false });
        await this.props.updateFacility(this.kabID, this.props.facilityForm.formValue);
        this.props.getFacilityById(this.kabID, this.aID);
    };

    private renderMasterData() {
        if (this.props.facilityState.selectedFacility) {
            return (
                <div>
                    {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                        <SlActionBar items={this.masterDataActionBarItems} />
                    )}
                    <div className={this.props.classes.tabContainer}>
                        <div className={this.props.classes.tabContent}>
                            <SlFacilityDataDetails
                                facility={this.props.facilityState.selectedFacility}
                            />
                        </div>
                    </div>
                    <SLModal
                        title={'Anlage'}
                        onClose={() => this.setState({ openFacilityModal: false })}
                        hideClose={true}
                        open={this.state.openFacilityModal}
                        onOkButtonClick={this.handleUpdateFacility}
                        OkButtonDisabled={this.props.facilityForm.invalid}
                    >
                        <SLFacility
                            productList={this.props.productList}
                            facility={this.props.facilityState.selectedFacility}
                        />
                    </SLModal>
                </div>
            );
        }
        return null;
    }

    private renderListContactPersons() {
        const tableColumns = [
            { title: 'Anrede', field: 'Anrede' },
            { title: 'Titel', field: 'Titel' },
            { title: 'Vorname', field: 'Vorname' },
            { title: 'Nachname', field: 'Nachname' },
            { title: 'Firmenname', field: 'Firmenname1' },
            { title: 'Rolle', field: 'Rolle' },
            { title: 'Telefonnummer', field: 'Telefon' },
            { title: 'E-Mail', field: 'Email' },
            { title: 'Zusatzinformationen', field: 'Zusatzinformationen' },
        ];
        return (
            <SlFacilityContacts
                contactPersons={this.props.contactPersonState.contactPersons}
                tableColumns={tableColumns}
                addContact={this.handleAddContact}
                editContact={this.handleEditContact}
                contactPersonForm={this.props.contactPersonForm}
                deleteContact={this.handleDeleteContact}
            />
        );
    }

    private renderMeterPanels() {
        const { meterPanels } = this.props.meterPanelState;
        return (
            <SlMeterPanels
                kabID={this.kabID}
                aID={this.aID}
                dataTable={buildMeterPlacesDataTable(meterPanels)}
                meterPanels={this.props.meterPanelState.meterPanels}
            />
        );
    }

    private renderDevices() {
        const { meterPanels } = this.props.meterPanelState;
        return (
            <SlDevices
                kabID={this.kabID}
                aID={this.aID}
                devices={this.allDevices}
                dataTable={buildDevicesDataTable(this.allDevices, meterPanels)}
                auftragForm={this.props.auftragForm}
                removeDeviceForm={this.props.removeDeviceForm}
                onDeviceClick={this.props.saveSelectedDevice}
                selectedDevice={this.props.deviceState.selectedDevice}
            />
        );
    }

    private renderReading() {
        const { devices } = this.props.deviceState;
        const { meterPanels } = this.props.meterPanelState;
        return (
            <SlMeterReading
                kabID={this.kabID}
                aID={this.aID}
               // devices={this.props.deviceState.devices}
                dataTable={buildReadingData(devices, meterPanels, this.props.ableseTypeList)}
                selectedDevice={this.props.deviceState.selectedDevice}
                onDeviceClick={this.props.saveSelectedDevice}
            />
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader
                    currentLocation={this.props.location}
                    selectedTab={this.props.facilityState.activeTab}
                    tabs={this.buildTabs()}
                    onTabClick={this.props.saveActiveTab}
                />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[0].label &&
                        this.renderMasterData()}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[1].label &&
                        this.renderListContactPersons()}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[2].label &&
                        this.renderMeterPanels()}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[3].label &&
                        this.renderDevices()}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[4].label &&
                        this.renderReading()}
                    {/*{this.tabs[this.props.facilityState.activeTab].label === this.tabs[5].label &&
                    <FacilityServicePage/>}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[6].label &&
                    <FacilityBillingPage/>}
                    {this.tabs[this.props.facilityState.activeTab].label === this.tabs[7].label && this.renderArchive()}*/}
                </div>

                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDismissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>

                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        facilityForm: {
            formValue: getFormValues('SLFacilityForm')(state),
            invalid: isInvalid('SLFacilityForm')(state),
        },
        contactPersonForm: {
            formValue: getFormValues('contactPersonForm')(state),
            invalid: isInvalid('contactPersonForm')(state),
        },
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        addReadingForm: {
            formValue: getFormValues('AblesungHinzufuegenForm')(state),
            invalid: isInvalid('AblesungHinzufuegenForm')(state),
        },
        editReadingForm: {
            formValue: getFormValues('AblesungEditForm')(state),
            invalid: isInvalid('AblesungEditForm')(state),
        },

        removeDeviceForm: {
            formValue: getFormValues('SlRemoveDeviceForm')(state),
            invalid: isInvalid('SlRemoveDeviceForm')(state),
        },
        customerState: state.customerState,
        facilityState: state.facilityState,
        meterPanelState: state.meterPanelState,
        deviceState: state.deviceState,
        archiveState: state.archiveState,
        contactPersonState: state.contactPersonState,
        error: state.globalState.error,
        productList: state.settingState.productManagementList,
        loadingList: state.globalState.loadingList,
        ableseTypeList: state.settingState.ableseTypeList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            CustomerActions,
            GlobalActions,
            FacilityActions,
            MeterPanelsActions,
            DevicesActions,
            ContactPersonActions,
            ArchivesActions,
            SettingsActions
        ),
        dispatch
    );
};

export const FacilityDataComponent = MaterialUI.withStyles(facilityStyles)(KabFacilityDetails);

export const FacilityDataPage = withRouter(
    connect<IKabFacilityDetailsPageProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<IKabFacilityDetailsPageProps>(FacilityDataComponent)
);
