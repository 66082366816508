import {WithStyles} from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import {SlimDeviceType} from '../interfaces/slim.interface';
import {BasicTable, IMuiTableColumn} from "../../../common/components/table/mui-table.component";
import {IsoformatToGermanDate} from "../../../common/utils";
import {deTranslator} from "../../../assets/i18n/deTranslator";
import {numberFormatter} from "../../../common/utils/Functions";
import {MaterialUIIcons} from "../../../materialUI/MaterialUIIcons";

const styles = () =>
    MaterialUI.createStyles({});

interface IRGMeterReadingProps extends WithStyles<typeof styles> {
    device: SlimDeviceType;
    onEdit: (reading: any) => void;
}


class RegulatedMeterReadingComponent extends React.Component<IRGMeterReadingProps, any> {
    private readonly readingTableColumns: Array<IMuiTableColumn> = [];

    constructor(props: IRGMeterReadingProps) {
        super(props);

        this.readingTableColumns = [
            {
                title: 'Datum',
                field: 'Ablesedatum',
                defaultSort: 'desc',
                render: rowData => IsoformatToGermanDate(rowData.Ablesedatum),
            },
            {
                title: `Zählerstand Strombezug 1.8.0/1.8.1 (${deTranslator.einheit.kilowatt})`,
                field: 'Strombezugstand',
                emptyValue: '0',
                render: rowData => (rowData ? numberFormatter(rowData['Strombezugstand']) : 0),
            },
            {
                title: `Verbrauch Strombezug (${deTranslator.einheit.kilowatt})`,
                field: 'MengeStrombezug',
                render: rowData => rowData && numberFormatter(rowData['MengeStrombezug']),
                emptyValue: '0',
            },
            {
                title: `Zählerstand Einspeisung 2.8.0/2.8.1 (${deTranslator.einheit.kilowatt})`,
                field: 'Einspeisungstand',
                emptyValue: '0',
                render: rowData => (rowData ? numberFormatter(rowData['Einspeisungstand']) : 0),
            },
            {
                title: `Verbrauch Einspeisung (${deTranslator.einheit.kilowatt})`,
                field: 'MengeEinspeisung',
                render: rowData => rowData && numberFormatter(rowData['MengeEinspeisung']),
                emptyValue: '0',
            },
            {title: 'Wandlerfaktor', field: 'Wandlerfaktor'},
            {title: 'Ablesegrund', field: 'Ablesegrund'},
        ];
    }

    private getTableData = () => {
        let result = [];
        const {device} = this.props;

        if (device.ablesungList && device.ablesungList.items.length > 0) {
            const sortedItems = device.ablesungList.items.slice().sort((a, b) => {
                return new Date(b.Ablesedatum).getTime() - new Date(a.Ablesedatum).getTime();
            });

            result = sortedItems.map((item, index) => {
                const nextItem = sortedItems[index + 1];

                const MengeStrombezug = nextItem && item.Strombezugstand
                    ? item.Strombezugstand - nextItem.Strombezugstand
                    : 0;

                const MengeEinspeisung = nextItem
                    ? item.Einspeisungstand - (nextItem.Einspeisungstand || 0)
                    : 0;

                const Wandlerfaktor = device.Wandlerfaktor || '';

                const factor = Number(Wandlerfaktor) || 1;

                return {
                    ...item,
                    Wandlerfaktor,
                    MengeStrombezug: MengeStrombezug * factor,
                    MengeEinspeisung: MengeEinspeisung * factor,
                };
            });
        }

        return result;
    };


    public render() {
        const {device, onEdit} = this.props;
        return (
            <BasicTable
                columns={this.readingTableColumns}
                title={`Zählerstände - ${device.Seriennummer}`}
                options={{toolbar: true, actionsColumnIndex: -1}}
                data={[...this.getTableData()]}
                actions={[
                    {
                        icon: () => (
                            <MaterialUI.IconButton color="secondary">
                                <MaterialUIIcons.Edit/>
                            </MaterialUI.IconButton>
                        ),
                        tooltip: '',
                        onClick: (event, rowData) => {

                            onEdit(rowData);

                        },
                    },
                ]}
            />
        );
    }
}

export const RegulatedMeterReading = MaterialUI.withStyles(styles)(RegulatedMeterReadingComponent);
