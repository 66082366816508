import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CONSTANTS } from '../../../model/constants';


export class ProtectedRoute extends React.Component<any> {
    public render() {
        const {component: Component, ...rest} = this.props;
        const idToken = localStorage.getItem(CONSTANTS.idToken);

        return (
            <Route
                {...rest}
                render={props =>
                    idToken ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {from: props.location}
                            }}
                        />
                    )
                }
            />
        );
    }
}