import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ISLActionBar } from '../../../common/components/actionbar/sl-actionbar.component';
import { BasicTable, basicTableStyles } from '../../../common/components/table/mui-table.component';
import { IAuftrag, IDeviceType, IProduct } from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IsoformatToGermanDate } from '../../../common/utils';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history } from '../../../store';
import { appStyles } from '../../../theme/common.styles';
import { AuftragActions } from '../../auftrag/redux';
import { CustomerActions } from '../../customers/redux';
import { DevicesActions } from '../../devices/redux';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { SlimActions } from '../redux';
import { numberFormatter } from '../../../common/utils/Functions';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

export interface ISlimAblesungProps extends WithStyles<typeof styles> {
    kabId: string;
    aId: string;
    zaehlerplatz: any[];
    productList: Array<IProduct>;
    deviceTypeList: Array<IDeviceType>;
    slimGeraete: { items: any[]; nextToken: any };
    slimKab: any;
    facilityEmail?: any;
    appendDeviceForm?: IForm;
    postSlimAuftrag: (data: IAuftrag) => { ID: string };
    sendEmailSlimAuftrag: (payload: IAuftrag, auftragID: string, meterplaces?: Array<any>) => void;
    postSlimDevices: (kabId: string, anlageId: string, userEmail: string, payload: any) => void;
    getSlimDevices: (kabId: string, anlageId: string) => void;
}

interface IState {
    showOrderModal: boolean;
    meterPanelActionBarItems: Array<ISLActionBar>;
}
class SlimAblesungComponent extends React.Component<ISlimAblesungProps, IState> {
    constructor(props: ISlimAblesungProps) {
        super(props);
        this.state = {
            showOrderModal: false,
            meterPanelActionBarItems: [],
        };
    }

    public goToDevice = ({ ZID, Seriennummer }) => {
        const link = `/slim/kabs/${this.props.kabId}/${this.props.aId}/${ZID}/${Seriennummer}`;
        // console.log('link: ', link);
        history.push(link);
    };
    public getLastAblesung = list => {
        const sorted = [...list].sort((rowA, rowB) => {
            return new Date(rowB.Ablesedatum).getTime() - new Date(rowA.Ablesedatum).getTime();
        });

        return sorted[0];
    };

    private deviceTableColumns = () => {
        return [
            {
                title: 'Geräte-ID',
                field: 'Seriennummer',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToDevice(rowData)}
                        >
                            {rowData.Seriennummer}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Ablesedatum',
                field: 'Ablesedatum',
                render: rowData => {
                    const lastAbledung = this.getLastAblesung(rowData.ablesungList.items || []);
                    if (lastAbledung) {
                        return (
                            <MaterialUI.Typography>
                                {IsoformatToGermanDate(lastAbledung.Ablesedatum)}
                            </MaterialUI.Typography>
                        );
                    }
                    return '-';
                },
            },
            {
                title: 'Ablesestand HT (kWh)',
                field: 'Ablesestand',
                render: rowData => {
                    const lastAbledung = this.getLastAblesung(rowData.ablesungList.items || []);
                    if (lastAbledung) {
                        return (
                            <MaterialUI.Typography>
                                {numberFormatter(lastAbledung.ZaehlerstandHT)}
                            </MaterialUI.Typography>
                        );
                    }
                    return '-';
                },
            },
            {
                title: 'Ablesegund',
                field: 'Ablesegund',
                render: rowData => {
                    const lastAbledung = this.getLastAblesung(rowData.ablesungList.items || []);
                    if (lastAbledung) {
                        return (
                            <MaterialUI.Typography>
                                {lastAbledung === 'Sonstige'
                                    ? lastAbledung['AndereAblesegrund']
                                    : lastAbledung.Ablesegrund}
                            </MaterialUI.Typography>
                        );
                    }
                    return '-';
                },
            },
        ];
    };

    public render() {
        return (
            <div style={{ overflow: 'auto', height: 'calc(100vh - 165px)' }}>
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.deviceTableColumns()}
                        options={{
                            paging: false,
                            pageSize: 10,
                            selection: false,
                            maxBodyHeight: 'calc(100vh - 275px)',
                        }}
                        data={[...this.props.slimGeraete.items]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        facilityEmail: state.facilityState.selectedFacilityEmail,
        productList: state.settingState.productManagementList,
        deviceTypeList: state.settingState.deviceTypeList,
        slimKab: state.slimState.selectedSlimCustomer,
        slimGeraete: state.slimState.slimGeraete,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            MeterPanelsActions,
            DevicesActions,
            CustomerActions,
            AuftragActions,
            SlimActions
        ),
        dispatch
    );
};

export const SlimAblesung = connect<ISlimAblesungProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<any>(MaterialUI.withStyles(styles)(SlimAblesungComponent));
