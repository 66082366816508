import {Theme} from '@material-ui/core';
import {WithStyles} from '@material-ui/styles';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators, Dispatch} from 'redux';
import {getFormValues, isInvalid} from 'redux-form';
import {deTranslator} from '../../../assets/i18n/deTranslator';
import {ISLActionBar, SlActionBar,} from '../../../common/components/actionbar/sl-actionbar.component';
import {LoadingComponent} from '../../../common/components/buttons';
import {ErrorComponent} from '../../../common/components/error';
import {SlHeader} from '../../../common/components/header';
import {SlAlertComponent, SLModal} from '../../../common/components/modal';
import {ISlTab} from '../../../common/components/tabs/sl-tabs.component';
import {IProduct} from '../../../common/interfaces/interface';
import {GlobalActions} from '../../../common/redux';
import {IError, ISnackBar} from '../../../common/redux/reducers/global.reducer';
import {isNullOrUndefined, isUserRole, removeEmptykey,} from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import {MaterialUIIcons} from '../../../materialUI/MaterialUIIcons';
import {Roles} from '../../../model/enums';
import {IForm} from '../../../model/interfaces/IForm';
import {IReduxState} from '../../../model/redux-state';
import {sbColors} from '../../../theme/app.colors';
import {SettingsActions} from '../../settings/redux';
import {SlimDeviceDetails} from '../components/slimDeviceDetails.component';
import {SlimPanelDetails} from '../components/slimPanelDetails.component';
import {SlimDeviceUpdate} from '../forms/updateDevice.form';
import {SlimActions} from '../redux';
import {ISlimState} from '../redux/reducers/slim.reducer';
import {SlimReadingUpdate} from '../forms/updateReading.form';
import {setError} from '../../../common/redux/actions/global.action';
import {store} from '../../../store';
import {updateSlimAblesung} from '../slim-api/ablesung-api/ablesung.api';
import {fetchGeraeteById} from '../slim-api/geraete-api/geraete.api';
import {fetchZaehlerplatzByZID} from '../slim-api/zaehlerplatz-api/zaehlerplatz.api';
import {RegulatedMeterReading} from "../components/RegulatedMeterReading";
import {MeterReading} from "../components/MeterReading";
import {SlimReadingType} from "../interfaces/slim.interface";
import {SlimRegulatedReadingUpdate} from "../forms/UpdateReguledMeterReading";

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },

        marginRight: {
            marginRight: 24,
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 20,
            width: '100%',
            overflow: 'hidden',
        },

        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        tabContainer: {
            height: '100vh',
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 250px)',
            paddingTop: 24,
            paddingBottom: 24,
            paddingRight: 40,
            paddingLeft: 40,
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },

        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        smallInput: {
            width: '30%',
        },
        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
        inputInvisible: {
            marginTop: 0,
            padding: 10,
            width: '100%',
            height: 20,
        },
        block: {
            marginTop: 10,
        },
    });

export interface ISlimGeraeteProps extends WithStyles<typeof styles> {
    match: any;
    location: any;
    slimState: ISlimState;
    error: IError;
    setError: (error: IError) => void;
    fetchSlimKundeById: (id: string) => void;
    fetchSlimKundenAnlagenById: (kabId, anlageId) => void;
    fetchSlimKAKontaktperson: (kabId, anlageId) => void;
    saveAnlageActiveTab: (tab: number) => void;
    fetchSlimKAZaehlerplatz: (kabId, anlageId) => void;
    getProductList?: () => void;
    getAbleseTypeList?: () => void;
    getDeviceTypeList?: () => void;
    loadingList: Array<string>;
    getSlimDevices?: (kabId: string, aId: string) => void;
    setSnackbarStatus?: (item: ISnackBar) => void;
    deviceForm: IForm;
    readingForm: IForm;
    regulatedReadingForm: IForm;
    productList?: Array<IProduct>;
    updateSlimDevices?: (payload: Array<any>) => void;
    dispatch?: any;
}

interface IState {
    openAppendDeviceModal: boolean;
    openOrderModal: boolean;
    device: any;
    panel: any;
    openDeviceUpdateModal: boolean;
    selectedAblesung: any;
    selectedRegulatedReading: SlimReadingType | null;
}

class SlimGeraetComponent extends React.Component<ISlimGeraeteProps, IState> {
    private readonly tabs: Array<ISlTab>;
    private readonly kabID: string = null;
    private readonly aID: string = null;
    private readonly zId: string = null;
    private readonly gID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;

    constructor(props: ISlimGeraeteProps) {
        super(props);
        this.kabID = decodeURIComponent(props.match.params.kabID);
        this.aID = decodeURIComponent(props.match.params.aID);
        this.zId = decodeURIComponent(props.match.params.zId);
        this.gID = decodeURIComponent(props.match.params.gID);

        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'}/>,
                action: () => this.setState({...this.state, openDeviceUpdateModal: true}),
                disable: false,
            },
        ];

        this.state = {
            openOrderModal: false,
            openAppendDeviceModal: false,
            device: null,
            panel: null,
            openDeviceUpdateModal: false,
            selectedAblesung: null,
            selectedRegulatedReading: null
        };
    }

    public componentDidMount() {
        this.props.getProductList();
        this.getDeviceByID().then();
        this.getPanelById().then();
    }

    private getDeviceByID = async () => {
        const response = await fetchGeraeteById(this.gID, this.zId);

        this.setState({...this.state, device: response || null});
    };
    private getPanelById = async () => {
        const response = await fetchZaehlerplatzByZID(this.zId);
        this.setState({...this.state, panel: response || null});
    };

    private handleDissmissError = () => {
        this.props.setError(null);
    };

    private handleUpdateDevice = async () => {
        this.setState({...this.state, openDeviceUpdateModal: false});
        const {GrundtypId, ProduktIds, ...rest} = this.props.deviceForm.formValue;
        const payload = {
            ...rest,
            Produkts: this.props.productList.filter(product => ProduktIds.indexOf(product.id) >= 0),
        };

        await this.props.updateSlimDevices([removeEmptykey(payload)]);

        await this.getDeviceByID();
    };


    private handleUpdateReading = async () => {
        try {
            this.setState({...this.state, selectedAblesung: null});

            const payload = {
                id: this.props.readingForm.formValue.id,
                Ablesedatum: this.props.readingForm.formValue.Ablesedatum,
                ZaehlerstandHT: this.props.readingForm.formValue.ZaehlerstandHT,
                Ablesegrund: this.props.readingForm.formValue.Ablesegrund,
                AndereAblesegrund: this.props.readingForm.formValue.AndereAblesegrund || '',
            };

            // console.log('payload: ', payload);
            await updateSlimAblesung(payload);
            await this.getDeviceByID();
        } catch (error) {
            store.dispatch(setError({message: 'Update fehlgeschlagen', title: 'Error'}));
        }
    };


    private editRegulatedReading = async () => {
        try {
            this.setState({...this.state, selectedRegulatedReading: null});

            const payload = {
                id: this.props.regulatedReadingForm.formValue.id,
                Ablesedatum: this.props.regulatedReadingForm.formValue.Ablesedatum,
                Strombezugstand: this.props.regulatedReadingForm.formValue.Strombezugstand,
                Einspeisungstand: this.props.regulatedReadingForm.formValue.Einspeisungstand,
                Ablesegrund: this.props.regulatedReadingForm.formValue.Ablesegrund,
                AndereAblesegrund: this.props.regulatedReadingForm.formValue.AndereAblesegrund || '',
            };
            await updateSlimAblesung(payload);
            await this.getDeviceByID();
        } catch (e) {
            store.dispatch(setError({message: 'Update fehlgeschlagen', title: 'Error'}));
        }
    }

    private renderMeterPanelInfo = () => {
        return this.state.panel && <SlimPanelDetails meterPanel={this.state.panel}/>;
    };
    private renderDeviceInfo = () => {
        return (
            this.state.device && (
                <div className={this.props.classes.block}>
                    <SlimDeviceDetails device={this.state.device}/>
                </div>
            )
        );
    };


    public render() {
        const {device} = this.state;
        return (
            <div className={this.props.classes.root}>
                <SlHeader currentLocation={null} tabs={[]} showOnlyBackArrow={true}/>
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar/>
                    <MaterialUI.Toolbar/>
                    <div>
                        {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                            <SlActionBar items={[...this.masterDataActionBarItems]}/>
                        )}
                        <div className={this.props.classes.tabContent}>
                            {this.renderMeterPanelInfo()}
                            {this.renderDeviceInfo()}
                            {device && device.ZaehlerTyp !== "Reguliertezaehler" &&
                                <MeterReading device={device} onEdit={(data) => this.setState({
                                    ...this.state,
                                    selectedAblesung: data
                                })}/>}
                            {device && device.ZaehlerTyp === "Reguliertezaehler" &&
                                <RegulatedMeterReading device={device} onEdit={(data) => this.setState({
                                    ...this.state,
                                    selectedRegulatedReading: data
                                })}/>}
                        </div>
                    </div>
                </div>

                <SLModal
                    title={deTranslator.device.edit}
                    onClose={() => this.setState({...this.state, openDeviceUpdateModal: false})}
                    hideClose={true}
                    open={this.state.openDeviceUpdateModal}
                    onOkButtonClick={this.handleUpdateDevice}
                    OkButtonDisabled={this.props.deviceForm.invalid}
                >
                    <SlimDeviceUpdate
                        device={this.state.device}
                        productList={this.props.productList}
                    />
                </SLModal>
                {this.state.selectedAblesung && (
                    <SLModal
                        title={' Zählerstand anpassen'}
                        open={!!this.state.selectedAblesung}
                        onClose={() => this.setState({...this.state, selectedAblesung: null})}
                        OkButtonDisabled={false}
                        onOkButtonClick={this.handleUpdateReading}
                    >
                        <SlimReadingUpdate selectedReading={this.state.selectedAblesung}/>
                    </SLModal>
                )}
                {this.state.selectedRegulatedReading && (
                    <SLModal
                        title={' Zählerstand anpassen'}
                        open={!!this.state.selectedRegulatedReading}
                        onClose={() => this.setState({...this.state, selectedRegulatedReading: null})}
                        OkButtonDisabled={false}
                        onOkButtonClick={this.editRegulatedReading}
                    >
                        <SlimRegulatedReadingUpdate selectedReading={this.state.selectedRegulatedReading}/>
                    </SLModal>
                )}

                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDissmissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error}/>}
                </SlAlertComponent>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60}/>}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        slimState: state.slimState,
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
        productList: state.settingState.productManagementList,
        deviceForm: {
            formValue: getFormValues('SlimDeviceUpdateForm')(state),
            invalid: isInvalid('SlimDeviceUpdateForm')(state),
        },
        readingForm: {
            formValue: getFormValues('SlimReadingUpdateForm')(state),
            invalid: isInvalid('SlimReadingUpdateForm')(state),
        },
        regulatedReadingForm: {
            formValue: getFormValues('RegulatedReadingForm')(state),
            invalid: isInvalid('RegulatedReadingForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, SlimActions, GlobalActions, SettingsActions),
        dispatch
    );
};

export const SlimGeraetWithStyles = MaterialUI.withStyles(styles)(SlimGeraetComponent);

export const SlimGeraetDetailsPage = withRouter(
    connect<ISlimGeraeteProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<ISlimGeraeteProps>(SlimGeraetWithStyles)
);
