import {
    getFacilityServices,
    postFacilityService,
    removeFacilityService,
    updateFacilityService
} from './thunk/facility-services.thunk';


export { facilityServicesReducer } from './reducers/facility-service.reducer';

export const FacilityServicesActions = Object.assign({},
    {getFacilityServices: getFacilityServices},
    {postFacilityService: postFacilityService},
    {updateFacilityService: updateFacilityService},
    {removeFacilityService: removeFacilityService});