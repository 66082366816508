export const CustomerActionTypes = {
    CUSTOMER_IS_LOADING: 'CUSTOMER_IS_LOADING',
    SAVE_SELECTED_CUSTOMER: 'SAVE_SELECTED_CUSTOMER',
    SAVE_CUSTOMERS: 'SAVE_CUSTOMERS',
    ADDED_CUSTOMER_ID: 'ADDED_CUSTOMER_ID',
    SAVE_SELECTED_DEVIATINGBILLRECIPIENT: 'SAVE_SELECTED_DEVIATINGBILLRECIPIENT',
    DEVIATINGBILLRECIPIENT_IS_LOADING: 'DEVIATINGBILLRECIPIENT_IS_LOADING',
    SAVE_KAB_CONTACTDATA: 'SAVE_KAB_CONTACTDATA',
    SAVE_SELECTED_KAB_CONTACTPERSON: 'SAVE_SELECTED_KAB_CONTACTPERSON',
    CONTACTDATA_IS_LOADING: 'CONTACTDATA_IS_LOADING',
    SAVE_ORDERS: 'SAVE_ORDERS',
    SAVE_DASHBOARD_ACTIVE_TAB: 'SAVE_DASHBOARD_ACTIVE_TAB',
    SAVE_CUSTOMER_ACTIVE_TAB: 'SAVE_CUSTOMER_ACTIVE_TAB',
    SAVE_REMINDERS: 'SAVE_REMINDERS'
};