export const updateKundenanlage = /* GraphQL */ `
    mutation UpdateKundenanlage(
        $input: UpdateKundenanlageInput!
        $condition: ModelKundenanlageConditionInput
    ) {
        updateKundenanlage(input: $input, condition: $condition) {
            id
            organisation_id
            KID
            AID
            KAname
            Stichtag
            Adresse {
                Land
                Ort
                PLZ
                HausNummer
                Postfach
                Strasse
                Zusatzinformationen
            }
            Abrechnungsmodell
            Vertragsbeginn
            Vertragsende
            isMetergridProject
            Identifikationsnummer
            Verteilnetzbetreiber
            CodenummerVNB
            Metergrid {
                project
                url
                id
                location
                data_provider
                data_provider_ref
                name
                users
            }
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            erzeugungsanlageList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    Anlagentyp
                    Anlagengroesse
                    DatumInbetriebnahme
                    Registernummer
                    Unit
                    createdAt
                    updatedAt
                }
                nextToken
            }
            inbetriebnahmeprozess {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    InitialProcessDone
                    Dokumente {
                        Fertigstellungsanzeige
                        Inbetriebstellungsanzeige
                        ZaehlerschrankFoto
                        Kundenanlagenbestaetigung
                    }
                    KundenAnlage {
                        Meldepflichten {
                            meldepflichtVBN
                            meldepflichtUNB
                            meldepflichtHZA
                        }
                        Reststrommanagement {
                            vertragAbgeschlossen
                        }
                        VerbraucherEingetragen
                        Messkonzept {
                            Beschreibung
                            MesskonzeptEntschieden
                            MesskonzeptHochgeladen
                        }
                    }
                    Zaehler {
                        ReguliertenZaehler {
                            ZaehlerHinzugefuegt
                        }
                        Zaehlerplatzcheck {
                            ErstgespraechGefuehrt
                            AngebotEingeholt
                            TABKonform
                        }
                        Zaehlerplatzuebersicht {
                            MontageartEingetragen
                            GeraeteBeauftragen
                            GeraetePinVersendet
                        }
                    }
                    Kunden {
                        Auftragsverarbeitung {
                            Vertragausgefuellt
                        }
                        Produktmanagement {
                            ProduktAngelegt
                        }
                        Kundenuebersicht {
                            MieterstromteilnehmerHinzugefuegt
                            AbschlagsplaeneErstellt
                            AblesungenEingetragen
                        }
                        Vertragsmanagement {
                            VertraegeErstellt
                            VertraegeErhalten
                        }
                    }
                    Erzeugungsanlagen {
                        ErzeugungsanlagenEingetragen
                    }
                    KaAnmeldung {
                        BAFA {
                            KwkAnlageRegistriert
                        }
                        Stromversorgungserlaubnis
                        UBNregistriert
                        Verteilnetzbetreiber {
                            EZAangemeldet
                            EZALageplan
                            Netzanschluss
                            Mieterstromzuschlag
                            BeantragungMieterstromzuschlag
                            MieterstromzuschlagGenehmigt
                            BeauftragungZaehlersetzung
                            KaInbetriebsetzung
                            EinspeisevertragErhalten
                            BeantragungMaLoMeLo
                            MaLosMeLosErhalten
                            BeantragungMesskonzept
                            BestaetigungMesskonzept
                        }
                        Marktstammdatenregister {
                            EZAangemeldet
                            BenutzerkontoAngelegt
                            AkteurAnlagenbetreiberAngelegt
                            ErzeugungsanlageRegistriert
                            AkteurStromlieferantAngelegt
                            WeitereAnlageAngemeldet
                            VnbMeldebescheinigunggesendet
                            RegistereintragAnpassen
                        }
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            kontaktpersonList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    Rolle
                    AndereRolle
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                        }
                    }
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                    }
                    Email
                    Telefon
                    Kommentar
                    createdAt
                    updatedAt
                }
                nextToken
            }
            zaehlerplatzList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    ZID
                    WohnungNr
                    Montageart
                    AuftragAktion
                    Gebaeude
                    Lage
                    Malo
                    Melo
                    Stockwerk
                    Verbrauchsprognose
                    GeraetTyp
                    Nutzungstyp
                    Metergrid {
                        id
                        project
                        name
                        malo_id
                        melo_id
                        type
                        location
                        data_provider
                        data_provider_ref
                        users
                        url
                    }
                    geraeteList {
                        items {
                            id
                            organisation_id
                            KID
                            AID
                            ZID
                            Seriennummer
                            ZaehlerTyp
                            Einbaudatum
                            GeraetePIN
                            AuftragAktion
                            Ausbaudatum
                            AusbaustandStatus
                            Eichdatum
                            ObisCodeHT
                            ObisCodeNT
                            GeraetTyp
                            WandlerID1
                            WandlerID2
                            WandlerID3
                            Wandlerfaktor
                            ZaehlwerkNKS
                            ZaehlwerkVKS
                            Zaehlerversanddatum
                            createdAt
                            updatedAt
                        }
                        nextToken
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            dokumenteList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    Filename
                    Pfad
                    FileContents
                    Klassifizierung
                    AndereKlassifizierung
                    UserEmail
                    ContentType
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
