import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SLInputPriceField } from '../../../../common/components/forms/number-input.component';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';


const styles = () => MaterialUI.createStyles({});

export interface IAddFacilityServiceComponentProps extends WithStyles<typeof styles> {
    editMode?: boolean;
    defaultService?: any;
    initialize?: (customerdata: any) => void;
}


class AddFacilityService extends React.Component<IAddFacilityServiceComponentProps> {

    constructor(props: IAddFacilityServiceComponentProps) {
        super(props);
        if (this.props.editMode && this.props.defaultService) {
            this.props.initialize(this.props.defaultService);
        }
    }

    public render() {
        return (<div style={{padding: '20px 20px 40px'}}>
            <MaterialUI.Grid container={true} spacing={10}>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field name={'ID'}
                           label={'Dienstleistung'}
                           type={'text'}
                           disabled={this.props.editMode ? true : false}
                           component={SlTextInputComponent}
                           placeholder={''}
                           required={true}
                           validate={[FormValidator.required]}
                           options={{fullWidth: true, shrink: true}}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={2}>
                    <SLInputPriceField name={'Preis'}
                                       required={true}
                                       validate={[FormValidator.required]}
                                       label={'Preis'}/>
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}/>
            </MaterialUI.Grid>
        </div>);
    }

}

export const FacilityManagementForm = MaterialUI.withStyles(styles)(AddFacilityService);

export const SlFacilityManagementForm = reduxForm({
    form: 'SlFacilityManagementForm',
})(FacilityManagementForm);