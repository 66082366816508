import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlSelectInputComponent } from '../../../../common/components/forms';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IDashboardNews } from '../../../../common/interfaces/interface';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';

const styles = () => MaterialUI.createStyles({});

export interface INewsfeedsComponentProps extends WithStyles<typeof styles> {
    editMode: boolean;
    defaultFeed: IDashboardNews;
    initialize?: (data: IDashboardNews) => void;
}

class DashboardNewsForm extends React.Component<INewsfeedsComponentProps, {}> {
    constructor(props: INewsfeedsComponentProps) {
        super(props);
        if (this.props.editMode && this.props.defaultFeed) {
            const { createdAt, updatedAt, ...rest } = this.props.defaultFeed;
            this.props.initialize(rest);
        }
    }

    public render() {
        return (
            <div style={{ padding: '20px 20px 40px' }}>
                <MaterialUI.Grid container={true} spacing={5}>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'title'}
                            label={'News Titel'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required, FormValidator.maxLength50]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'description'}
                            label={'News Text'}
                            type={'text'}
                            multiline={true}
                            rows={5}
                            disableUnderline={true}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required, FormValidator.maxLength50]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'link'}
                            label={'News Link'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required, FormValidator.maxLength50]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} />

                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlSelectInputComponent}
                            required={true}
                            label={'News Link Text'}
                            name={'linkType'}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        >
                            <MaterialUI.MenuItem value={'EDIT'}>
                                {deTranslator.global.edit}
                            </MaterialUI.MenuItem>
                            <MaterialUI.MenuItem value={'READ_MORE'}>
                                {'Weiterlesen'}
                            </MaterialUI.MenuItem>
                            <MaterialUI.MenuItem value={'DISCOVER'}>
                                {'Entdecken'}
                            </MaterialUI.MenuItem>
                        </Field>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlSelectInputComponent}
                            required={true}
                            label={'News Status '}
                            name={'status'}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        >
                            <MaterialUI.MenuItem value={'ACTIVE'}>{'Aktiv'}</MaterialUI.MenuItem>
                            <MaterialUI.MenuItem value={'INACTIVE'}>
                                {'Inaktiv'}
                            </MaterialUI.MenuItem>
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const DashboardFormComponent = MaterialUI.withStyles(styles)(DashboardNewsForm);

export const SlDashboardForm = reduxForm({
    form: 'SlDashboardForm',
})(DashboardFormComponent);
