/*import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();*/

import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import { Page } from './main';
import slimBackendDev from './amplify-backend-dev';
import slimBackendTest from './amplify-backend-test';
import slimBackendProd from './amplify-backend-prod';
import { Amplify } from 'aws-amplify';
// import awsExports from './aws-exports';

let amplifyConfig = {};
if (process.env.REACT_APP_STAGE === 'dev') {
    amplifyConfig = {
        ...slimBackendDev,
        /* oauth: {
            ...slimBackendDev.oauth,
            scope: ['phone', 'email', 'profile'],
            redirectSignIn: 'http://localhost:3000/', // URL must be present on Callback URL(s) , check on App client settings sub section
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }*/
    };
    // console.log('dev: ');
}
if (process.env.REACT_APP_STAGE === 'test') {
    amplifyConfig = slimBackendTest;
    // console.log('test: ');
}
if (process.env.REACT_APP_STAGE === 'prod') {
    //  console.log('prod: ');
    amplifyConfig = slimBackendProd;
}

Amplify.configure({
    ...amplifyConfig,
    /*API: {
        graphql_headers: async () => {
            const session = await Auth.currentSession();
            // Use ID token instead of access token in API calls
            return {
                Authorization: session.getIdToken().getJwtToken(),
            };
        },
    },*/
});
const rootEl = document.getElementById('root');

ReactDOM.render(<Page />, rootEl as HTMLElement);

// new hot reloading
/*if (module.hot) {
    module.hot.accept('./main', () => {
        const NextApp = require('./main').default;
        ReactDOM.render(
            <NextApp/>,
            rootEl as HTMLElement
        );
    });
}*/
// registerServiceWorker();
