import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { titleStyles } from '../../../theme/common.styles';
import { SlimKabType } from '../interfaces/slim.interface';

const styles = () =>
    MaterialUI.createStyles({
        container: {
            marginBottom: 24,
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            minHeight: 20,
        },
        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        mediumInput: {
            width: '70%',
        },
        smallInput: {
            width: '30%',
        },
        marginRight: {
            marginRight: 24,
        },
        marginLeft: {
            marginLeft: 24,
        },
    });

export interface IKabBankdaten extends WithStyles<typeof styles> {
    customerdata: SlimKabType;
}

class KabBankDaten extends React.Component<IKabBankdaten, {}> {
    public render() {
        const { kabStammdaten } = this.props.customerdata;
        if (kabStammdaten.Bankdaten) {
            const { Kontoinhaber = '', BIC = '', IBAN = '' } = kabStammdaten.Bankdaten || {};
            return (
                <MaterialUI.Paper style={{ marginTop: 24, padding: 15 }}>
                    <MaterialUI.Typography variant="h5">Bankdaten</MaterialUI.Typography>

                    <MaterialUI.Grid container={true} direction={'row'}>
                        <MaterialUI.Grid item={true} xs={6}>
                            <MaterialUI.Grid
                                item={true}
                                xs={10}
                                zeroMinWidth={true}
                                className={this.props.classes.item}
                            >
                                <MaterialUI.Typography
                                    className={this.props.classes.label}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    variant="subtitle2"
                                >
                                    Kontoinhaber
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    className={this.props.classes.input}
                                    color={'inherit'}
                                >
                                    {Kontoinhaber}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={6}>
                            <MaterialUI.Grid
                                item={true}
                                xs={10}
                                zeroMinWidth={true}
                                className={this.props.classes.item}
                            >
                                <MaterialUI.Typography
                                    className={this.props.classes.label}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    variant="subtitle2"
                                >
                                    BIC
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    className={this.props.classes.input}
                                    color={'inherit'}
                                >
                                    {BIC || ''}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={6}>
                            <MaterialUI.Grid
                                item={true}
                                xs={10}
                                zeroMinWidth={true}
                                className={this.props.classes.item}
                            >
                                <MaterialUI.Typography
                                    className={this.props.classes.label}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    variant="subtitle2"
                                >
                                    IBAN
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    className={this.props.classes.input}
                                    color={'inherit'}
                                >
                                    {IBAN}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </MaterialUI.Paper>
            );
        }
        return null;
    }
}

export const SlimKabbankdaten = MaterialUI.withStyles(styles)(KabBankDaten);
