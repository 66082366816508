import { IEmailService, IFacility } from '../../../../common/interfaces/interface';
import { FacilityActionTypes } from '../types/facility.action.types';

export interface IFacilityState {
    facilities: Array<IFacility>;
    selectedFacility: IFacility;
    selectedFacilityEmail: IEmailService;
    facilityLoading: boolean;
    postFacilityLoadingStatus: boolean;
    activeTab: number;
}

const initialState: IFacilityState = {
    facilities: [],
    selectedFacility: null,
    selectedFacilityEmail: {
        PLZ: null,
        EmailEinbau: null,
        EmailAblesung: null,
    },
    facilityLoading: false,
    postFacilityLoadingStatus: false,
    activeTab: 0,
};

export const facilityReducer = (
    state: IFacilityState = initialState,
    action: any
): IFacilityState => {
    switch (action.type) {
        case FacilityActionTypes.FACILITY_IS_LOADING:
            return Object.assign({}, state, { facilityLoading: action.payload });
        case FacilityActionTypes.SAVE_FACILITIES:
            return Object.assign({}, state, { facilities: [...action.payload] });
        case FacilityActionTypes.SAVE_SELECTED_FACILITY:
            return Object.assign({}, state, { selectedFacility: action.payload });
        case FacilityActionTypes.POST_FACILITY_LOADING_STATUS:
            return Object.assign({}, state, { postFacilityLoadingStatus: action.payload });
        case FacilityActionTypes.SAVE_FACILITY_EMAIL:
            return Object.assign({}, state, { selectedFacilityEmail: action.payload });
        case FacilityActionTypes.SAVE_ACTIVE_TAB:
            return Object.assign({}, state, { activeTab: action.payload });
        default:
            return Object.assign({}, state);
    }
};
