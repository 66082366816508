import { API } from 'aws-amplify';
import { kontaktpersonByKundenanlageAID } from './graphql-operations/kontaktperson.queries';
import { getIdTokenForSlimApi } from '../utils';

export const getKontaktpersonByAID = async AID => {
    const lambdaAuthToken = await getIdTokenForSlimApi();
    const response = await (API.graphql({
        query: kontaktpersonByKundenanlageAID,
        variables: { AID: AID },
        authMode: 'AWS_LAMBDA',
        authToken: lambdaAuthToken,
    }) as Promise<{
        data: { kontaktpersonByKundenanlageAID: any };
    }>);

    return response.data.kontaktpersonByKundenanlageAID || { items: [] };
};
