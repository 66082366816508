import Moment from 'moment';
import { CONSTANTS } from '../../model/constants';
import * as Enumarations from '../../model/enums';
import { IContactData } from '../interfaces/interface';
import { FormValidator } from '../validators/form.validator';

const jwtDecode = require('jwt-decode');
const momentTz = require('moment-timezone');

export const isNullOrUndefined = (obj: any): boolean => {
    return typeof obj === 'undefined' || obj === null;
};

export const isNullOrEmpty = (obj: string | Array<any>): boolean => {
    return typeof obj === 'undefined' || obj === null || obj.length === 0;
};

export const IsoformatToGermanDate = (date: string): string => {
    if (date) {
        const match = date.match(/^\d{4}[-/]\d{2}[-/]\d{2}/);
        if (match) {
            const inputDate = Moment(new Date(date));
            return inputDate.isValid() ? inputDate.format('DD.MM.YYYY') : '-';
        }
        return date;
    }
    return date;
};

export const removeEmptykey = (data: any) => {
    Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '' || data[key].length === 0) {
            delete data[key];
        }
    });
    return Object.assign({}, data);
};

export const titleCase = str => {
    if (str) {
        const splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }
    return '';
};

export const convertBase64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const inputDateDisplayFormat = value => {
    if (value) {
        return value && `${value.split('-')[2]}.${value.split('-')[1]}.${value.split('-')[0]}`;
    }
    return '';
};
export const germanDateToISODateFormat = value => {
    if (value && FormValidator.isDateGermanFormatValid(value)) {
        return `${value.split('.')[2]}-${value.split('.')[1]}-${value.split('.')[0]}`;
    }
    return value;
};

export const capitalizeFirstLetter = value => {
    if (value && value !== '') {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return '';
};

export const addYear = (startDate: string, yearNumber: number) => {
    return Moment(startDate)
        .add(yearNumber, 'years')
        .subtract(1, 'days')
        .format('DD.MM.YYYY');
};

export const dateGermanFormat = date => {
    if (date) {
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(date));
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(date));
        // console.log(`${da}-${mo}-${ye}`);
        return `${da}.${mo}.${ye}`;
    }
    return '';
};

export const currencyFormatter = (value: number) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
        value ? value : 0
    );
};

export const numberFormatter = (value: number, maximumFractionDigits?: number, lang?: string) => {
    return new Intl.NumberFormat(lang || 'de-DE', {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigits || 3,
    }).format(value ? value : 0);
};

export const isDateBetween = (currentDate: string, from: string, to: string) => {
    return Moment(currentDate).isBetween(from, to, null, '[]');
};

export const sanitizerContact = (data: IContactData) => {
    if (data.Anrede.Typ === Enumarations.Salutation.Company) {
        data.Anrede.Person && delete data.Anrede.Person;
        const { Anrede, ...rest } = data;
        data.Anrede.Firma = removeEmptykey(data.Anrede.Firma);
        return Object.assign(
            {},
            {
                Anrede: { Firma: removeEmptykey(Anrede.Firma), Typ: Anrede.Typ },
                ...removeEmptykey(rest),
            }
        );
    } else if (data.Anrede.Typ !== Enumarations.Salutation.Company) {
        data.Anrede.Firma && delete data.Anrede.Firma;
        const { Anrede, ...rest } = data;
        data.Anrede.Person = removeEmptykey(data.Anrede.Person);
        return Object.assign(
            {},
            {
                Anrede: { Person: removeEmptykey(Anrede.Person), Typ: Anrede.Typ },
                ...removeEmptykey(rest),
            }
        );
    }
};

export const subtractStringAsNumber = (a: string, b: string, decimal?: number): string => {
    decimal = decimal || 0;
    const tmp = Number(a.replace(/,/g, '.')) - Number(b.replace(/,/g, '.'));
    return parseFloat(tmp.toFixed(decimal))
        .toString()
        .replace(/\./g, ',');
};
export const addStringAsNumber = (a: string, b: string, decimal?: number): string => {
    decimal = decimal || 2;
    const tmp = Number(a.replace(/,/g, '.')) + Number(b.replace(/,/g, '.'));
    return parseFloat(tmp.toFixed(decimal))
        .toString()
        .replace(/\./g, ',');
};

export const extractDateFromText = (text: string): string => {
    const result = text.match(/\d{2}[.]\d{2}[.]\d{4}/);
    return result ? result[0] : '';
};

export const percentRange = (
    value: number,
    percentString: string
): { min: number; max: number } => {
    let result = { min: 0, max: 0 };
    let percent = Number(percentString.replace('%', ''));
    result.min = value - (value * percent) / 100;
    result.max = value + (value * percent) / 100;
    return result;
};

export const calcalJahresverbrauchsprognose = (von: string, bis: string, verbrauch: string) => {
    const vonDatum = Moment(von);
    const bisDatum = Moment(bis);
    const diff = bisDatum.diff(vonDatum, 'days');
    const tmp = (Number(verbrauch.replace(/,/g, '.')) / diff) * 365;
    return parseFloat(tmp.toFixed(0))
        .toString()
        .replace(/\./g, ',');
};

export const jahresverbrauchsprognoseProTag = (
    von: string,
    bis: string,
    jahresverbrauchsprognose: string
) => {
    const vonDatum = Moment(von);
    const bisDatum = Moment(bis);
    const diff = bisDatum.diff(vonDatum, 'days');
    const tmp = (Number(jahresverbrauchsprognose.replace(/,/g, '.')) / 365) * diff;
    return parseFloat(tmp.toFixed(3))
        .toString()
        .replace(/\./g, ',');
};

export const deleteText = (data: string) => {
    return `Sind Sie sich sicher, dass Sie ${data} wirklich löschen wollen?`;
};

export const isUserRole = (role: string): boolean => {
    const token = localStorage.getItem(CONSTANTS.idToken);
    const profiles = jwtDecode(token).profile;
    const profilesAsArray = profiles
        .replace('[', '')
        .replace(']', '')
        .split(',')
        .map(item => item.trim());
    return profilesAsArray.indexOf(role) > -1;
};

export const stringToNumber = (value: string) => {
    return value ? parseFloat(value.replace(/\s/g, '').replace(',', '.')) : 0;
};

export const downloadFileFromBase64String = (
    dataUrl: string,
    fileName: string,
    contentType: string
) => {
    const base64String = dataUrl.split(',').pop();
    const downloadLink = document.createElement('a');

    //Store Blob in IE
    if (window.top.navigator.msSaveOrOpenBlob) {
        console.log('IE, EDGE');
        const byteCharacters = window.atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        downloadLink.setAttribute('href', dataUrl);
        downloadLink.setAttribute('download', fileName);
        downloadLink.click();
    }
};

export const defaultSort = (a, b) => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};

export const convertDateGermanDateFormat = (date: any) => {
    if (/^\d+$/.test(date)) {
        return date
            ? momentTz(new Date(Number(date)))
                  .tz('Europe/Berlin')
                  .format('DD.MM.YYYY')
            : '';
    }
    return date
        ? momentTz(new Date(date))
              .tz('Europe/Berlin')
              .format('DD.MM.YYYY')
        : '';
};

export const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
};
