import { Theme } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { sbColors } from '../../../theme/app.colors';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: sbColors.red,
        },
        info: {
            backgroundColor: sbColors.blue,
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
    });

export interface ISLBreadCrumb {
    label: string;
    path: string;
}

export interface ISnackBarComponentProps extends WithStyles<typeof styles> {
    open: boolean;
    text: string;
    handleClose: () => void;
    type?: 'success' | 'error' | 'info' | 'warning';
}

class SnackbarComponent extends React.Component<ISnackBarComponentProps, {}> {
    public static defaultProps: Partial<ISnackBarComponentProps> = {
        type: 'success',
    };

    public render() {
        return (
            <MaterialUI.Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.props.open}
                autoHideDuration={5000}
                onClose={this.props.handleClose}
            >
                <MaterialUI.SnackbarContent
                    className={this.props.classes[this.props.type]}
                    message={this.props.text}
                    action={[
                        <MaterialUI.IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.handleClose}
                        >
                            <MaterialUIIcons.Close className={this.props.classes.icon} />
                        </MaterialUI.IconButton>,
                    ]}
                />
            </MaterialUI.Snackbar>
        );
    }
}

export const SlSnackbarComponent = MaterialUI.withStyles(styles)(SnackbarComponent);
