import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { IAbleseTyp, IMeterPlace } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { IAblesungExt } from '../../../../model/interfaces/IAblesungExt';
import { SLablesungPanel } from './ablesung-panel.component';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
    });

export interface IAbleseHinzufuegenProps extends WithStyles<typeof styles> {
    ableseTypeList: Array<IAbleseTyp>;
    meterPanels: Array<IMeterPlace>;
    selectedDevices: Array<IAblesungExt>;
    initialize: (data) => void;
}

class AblesungHinzufuegen extends React.Component<IAbleseHinzufuegenProps, any> {
    public componentDidMount() {
        this.props.initialize({ Ablesungen: this.props.selectedDevices });
    }

    private renderContent = ({ fields }) => {
        return fields.map((ablesung, index) => {
            const meterPanel = this.props.meterPanels.find(
                meterpanel => meterpanel.ID === this.props.selectedDevices[index].ZID
            );
            return (
                <SLablesungPanel
                    key={`ablesung-${index}`}
                    ableseTypeList={this.props.ableseTypeList}
                    value={ablesung}
                    ablese={this.props.selectedDevices[index]}
                    ZaehlwerkVKS={this.props.selectedDevices[index].ZaehlwerkVKS}
                    ZaehlwerkNKS={this.props.selectedDevices[index].ZaehlwerkNKS}
                    meterPanel={meterPanel}
                />
            );
        });
    };

    public render() {
        return (
            <div>
                <div style={{ paddingTop: 20 }}>
                    <FieldArray name="Ablesungen" component={this.renderContent} />
                    <div style={{ marginBottom: '20px' }}>
                        <MaterialUI.Typography style={{ paddingTop: '35px' }}>
                            *Pflichtfelder
                        </MaterialUI.Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export const AblesungHinzufuegenComponent = MaterialUI.withStyles(styles)(AblesungHinzufuegen);

export const AblesungHinzufuegenReduxForm = reduxForm({
    form: 'AblesungHinzufuegenForm',
})(AblesungHinzufuegenComponent);

export const SLAblesungHinzufuegen = connect()(AblesungHinzufuegenReduxForm);
