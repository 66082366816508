export const slimAuftragEmailBody = () => {
    return `
    Hallo Kollegen,

 

anbei unsere Zähler-/Geräteaufträge (ZGA) für Messstellen einer Inhouseversorgung/Kundenanlage mit der Bitte um Bearbeitung und anschließender Rückmeldung an uns.

Auftragsart:

Ansprechpartner Anlage:

Adresse der Anlage:

Einbaudatum:


Bemerkungen:


Bitte die weiteren Informationen in der angehängten Datei beachten. Die "Anzahl Zähler" Zähler ("Zählerart") bitte aus eurem Lagerbestand entnehmen und verbauen.

Wir werden diesen dann im Nachgang auf unser Kundennetz Lager umbuchen lassen. Bitte alle Aufwände der nicht regulierten Zählersetzung auf die Auftragsnummer K6V30006 buchen.

Bei Rückfragen stehen wir gerne zur Verfügung.


Vielen Dank und Grüße

Euer StromLux Team
    
    
    `;
};
