import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { GeraetTypEnum, NutzungstypEnum } from '../../../model/enums';
import { sbColors } from '../../../theme/app.colors';
import { titleStyles } from '../../../theme/common.styles';
import { SlimZaehlerplatzType } from '../interfaces/slim.interface';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        infoPaper: {
            minWidth: '90%',
            padding: 20,
            margin: 'auto',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            width: '100%',
            overflow: 'hidden',
        },

        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
    });

export interface IMeterPanelInfoProps extends WithStyles<typeof styles> {
    meterPanel: SlimZaehlerplatzType;
}

class SlimMeterPanelInfo extends React.Component<IMeterPanelInfoProps, {}> {
    private readonly meterPanelInfo = [
        { label: 'Wohnungs-Nr.', key: 'WohnungNr' },
        { label: 'Gebäude', key: 'Gebaeude' },
        { label: 'Stockwerk', key: 'Stockwerk' },
        { label: deTranslator.meterPanel.location, key: 'Lage' },
        { label: 'Aktion Beauftragt', key: 'AuftragAktion' },
        { label: 'Messlokations-ID', key: 'Melo' },
        { label: 'Marktlokations-ID', key: 'Malo' },
        { label: 'Montageart', key: 'Montageart' },
        { label: deTranslator.meterPanel.Verbrauchsprognose, key: 'Verbrauchsprognose' },
    ];

    public render() {
        const Verbrauchsprognose = this.props.meterPanel.Verbrauchsprognose
            ? Number(this.props.meterPanel.Verbrauchsprognose.replace(/,/g, '.'))
            : 0;
        return (
            <MaterialUI.Paper elevation={2} className={this.props.classes.infoPaper}>
                <MaterialUI.Typography
                    variant={'h5'}
                    color={'inherit'}
                    className={`${this.props.classes.label} `}
                >
                    Informationen Zählerplatz
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ padding: 20 }}>
                    <MaterialUI.Grid container={true} direction={'row'} spacing={5}>
                        {this.meterPanelInfo.map(item => (
                            <MaterialUI.Grid
                                item={true}
                                xs={3}
                                key={item.key}
                                style={{ paddingBottom: 0 }}
                            >
                                <MaterialUI.Typography
                                    variant={'body2'}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    className={`${this.props.classes.label}`}
                                >
                                    {item.label}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    noWrap={true}
                                    color={'inherit'}
                                    className={`${this.props.classes.input}`}
                                >
                                    {item.key !== 'Verbrauchsprognose' &&
                                        this.props.meterPanel[item.key]}
                                    {item.key === 'Verbrauchsprognose' &&
                                        Math.round(Verbrauchsprognose)}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        ))}
                        <MaterialUI.Grid
                            item={true}
                            xs={true}
                            key={'Geraetetyp'}
                            style={{ paddingBottom: 0 }}
                        >
                            <MaterialUI.Typography
                                variant={'body2'}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                className={`${this.props.classes.label}`}
                            >
                                Gerätetyp
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                noWrap={true}
                                color={'inherit'}
                                className={`${this.props.classes.input}`}
                            >
                                {GeraetTypEnum[this.props.meterPanel['GeraetTyp']]}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid
                            item={true}
                            xs={true}
                            key={'Nutzungstyp'}
                            style={{ paddingBottom: 0 }}
                        >
                            <MaterialUI.Typography
                                variant={'body2'}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                className={`${this.props.classes.label}`}
                            >
                                Nutzungstyp
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                noWrap={true}
                                color={'inherit'}
                                className={`${this.props.classes.input}`}
                            >
                                {NutzungstypEnum[this.props.meterPanel['Nutzungstyp']]}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </div>
            </MaterialUI.Paper>
        );
    }
}

export const SlimPanelDetails = MaterialUI.withStyles(styles)(SlimMeterPanelInfo);
