import { saveAuftrag } from './actions/auftrag.actions';
import { postAuftrag, sendAbleseAuftrag, sendEmailAuftrag } from './thunk/auftrag.operations';

// export reducers
export { auftragReducer } from './reducers/auftrag.reducer';

// export actions and thunk operations
export const AuftragActions = Object.assign(
    {},
    { postAuftrag: postAuftrag },
    { saveAuftrag: saveAuftrag },
    { sendEmailAuftrag: sendEmailAuftrag },
    { sendAbleseAuftrag: sendAbleseAuftrag }
);
