import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IKAB } from '../../../common/interfaces/interface';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
    });

export interface IZusatzInfoFormProps extends WithStyles<typeof styles> {
    selectedCustomer: IKAB;
    initialize: (customerdata: any) => void;
}

class ZusatzInfoForm extends React.Component<IZusatzInfoFormProps, {}> {
    constructor(props: IZusatzInfoFormProps) {
        super(props);
        this.props.initialize(this.props.selectedCustomer);
    }

    public render() {
        return (
            <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                <MaterialUI.Grid container={true} spacing={2}>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Vorname Geschäftsführer/in '}
                            name={'Zusatzinfo.Vorname'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Nachname Geschäftsführer/in'}
                            name={'Zusatzinfo.Nachname'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Rechtsform'}
                            name={'Zusatzinfo.Rechtsform'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Zuständiges Finanzamt'}
                            name={'Zusatzinfo.Finanzamt'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Handelsregisternumer'}
                            name={'Zusatzinfo.Handelsregisternumer'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Registergericht'}
                            name={'Zusatzinfo.Registergericht'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Hauptzollamt'}
                            name={'Zusatzinfo.Hauptzollamt'}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const ZusatzInfoFormStyle = MaterialUI.withStyles(styles)(ZusatzInfoForm);

export const ZusatzInfoReduxForm = reduxForm({
    form: 'KabZusatzInfoForm', // a unique identifier for this form
    destroyOnUnmount: false, // dont delete the values when the form gets destroyed/tabs switch
})(ZusatzInfoFormStyle);

// const selector = formValueSelector('KabZusatzInfoForm');

const mapStateToProps = (state: IReduxState) => {
    return {};
};

export const KABZusatzInfoForm = connect(mapStateToProps)(ZusatzInfoReduxForm);
