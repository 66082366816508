import axios from 'axios';
import { reset } from 'redux-form';
import { IBillRecipient, IContactData, IKAB } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
} from '../../../../common/redux/actions/global.action';
import { removeEmptykey, sanitizerContact } from '../../../../common/utils/Functions';
import * as Enumarations from '../../../../model/enums';
import { HTTPStatusCodes } from '../../../../model/enums';
import { IReduxState } from '../../../../model/redux-state';
import { saveBillToCustmer } from '../actions/bill-to-customer.action';
import { contactDataLoadingStatus } from '../actions/contact-data.action';
import {
    addedCustomerId,
    saveCustomers,
    saveKabContactData,
    saveSelectedCustomer,
} from '../actions/customer.action';
import { CustomerActionTypes } from '../types/customer.action.types';

export const resetReduxForm = formName => dispatch => dispatch(reset(formName));

export const postCustomer = (payload: IKAB) => {
    const customer: IKAB = Object.assign({}, sanitizerKAB(payload));
    return async dispatch => {
        dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        try {
            const response = await axios.post(`kabs/`, customer);
            dispatch(addedCustomerId(response.data.ID));
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            return response.data.id;
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        }
    };
};

export const getCustomers = () => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            const response = await axios.get(`kabs`);
            if (response && response.data) {
                const customers = response.data === null ? [] : [...response.data];
                dispatch(saveCustomers([...customers]));
            } else {
                dispatch(saveCustomers([]));
            }
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        } catch (error) {
            dispatch(saveCustomers([]));
        }
    };
};

export const getCustomerById = (kabID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}`);
            if (response.status === HTTPStatusCodes.OK) {
                dispatch(saveSelectedCustomer(response.data[0]));
            } else {
                dispatch(saveSelectedCustomer(null));
            }
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        } catch (error) {
            dispatch(saveSelectedCustomer(null));
        }
    };
};

export const deleteCustomerById = (kabID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            await axios.delete(`kabs/${kabID}`);
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        }
    };
};

export const updateCustomer = payload => {
    return async dispatch => {
        const customer: IKAB = Object.assign({}, sanitizerKAB(payload));
        try {
            const {
                ZaehlerPlaetze,
                Geraete,
                Anlagen,
                Rechnungdempfaenger,
                ContactData,
                ...restKab
            } = customer;
            dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            await axios.put(`kabs/${customer.ID}`, restKab);
            dispatch(getCustomerById(customer.ID));
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        }
    };
};

export const postBillReceiver = (payload: IBillRecipient) => {
    return async (dispatch, getState: () => IReduxState) => {
        const kabId = getState().customerState.addedCustomerID
            ? getState().customerState.addedCustomerID
            : getState().customerState.selectedCustomer.ID;
        try {
            const billReceiver = sanitizerKAB(payload);
            dispatch(addLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
            await axios.post(`kabs/${kabId}/billrecipient`, billReceiver);
            dispatch(resetReduxForm('BillToCustomerForm')); // reset the form
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CUSTOMER_IS_LOADING));
        }
    };
};

export const getBillReceiver = (kabID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/billrecipient`);
            dispatch(saveBillToCustmer(response.data ? response.data[0] : null));
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        } catch (error) {
            dispatch(saveBillToCustmer(null));
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        }
    };
};
export const updateBillReceiver = (kabId: string, payload: IBillRecipient) => {
    return async dispatch => {
        try {
            const billReceiver = sanitizerKAB(payload);
            dispatch(addLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
            await axios.put(`kabs/${kabId}/billrecipient/${payload.ID}`, billReceiver);
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        }
    };
};

export const deleteBillReceiver = (kabID: string, id: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
            await axios.delete(`kabs/${kabID}/billrecipient/${id}`);
            dispatch(saveBillToCustmer(null));
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING));
        }
    };
};
export const getContactData = (kabID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/contactdata`);
            dispatch(saveKabContactData(response.data ? response.data : []));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
        }
        dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
    };
};
export const postContactData = (payload: IContactData) => {
    return async (dispatch, getState: () => IReduxState) => {
        const kabId = getState().customerState.addedCustomerID
            ? getState().customerState.addedCustomerID
            : getState().customerState.selectedCustomer.ID;

        try {
            const contactPerson: IContactData = sanitizerContact(payload);
            dispatch(addLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            await axios.post(`kabs/${kabId}/contactdata`, contactPerson);
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            dispatch(resetReduxForm('contactDataForm'));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
        }
    };
};

export const updateContactData = (payload: IContactData) => {
    return async (dispatch, getState: () => IReduxState) => {
        const kabId = getState().customerState.selectedCustomer.ID;
        try {
            const contactPerson: IContactData = sanitizerContact(payload);
            dispatch(addLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            await axios.put(`kabs/${kabId}/contactdata/${payload.ID}`, contactPerson);
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            dispatch(getContactData(kabId));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
        }
    };
};

export const deleteContactData = (kabID: string, ids: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
            await axios.delete(`kabs/${kabID}/contactdata?ids=${ids}`);
            dispatch(saveKabContactData(null));
            dispatch(contactDataLoadingStatus(false));
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(CustomerActionTypes.CONTACTDATA_IS_LOADING));
        }
    };
};

const sanitizerKAB = (data: IKAB | IBillRecipient) => {
    data.Adresse = removeEmptykey(data.Adresse);
    // convert plz to string
    data.Adresse = { ...data.Adresse, PLZ: data.Adresse.PLZ.toString() };
    if (data.Anrede.Person) {
        data.Anrede.Person = removeEmptykey(data.Anrede.Person);
    } else if (data.Anrede.Firma) {
        data.Anrede.Firma = removeEmptykey(data.Anrede.Firma);
    }

    const customer: IKAB = Object.assign({}, data);
    if (customer.Anrede.Typ === Enumarations.Salutation.Company && customer.Anrede.Person) {
        delete customer.Anrede.Person;
    } else if (customer.Anrede.Typ !== Enumarations.Salutation.Company && customer.Anrede.Firma) {
        delete customer.Anrede.Firma;
    }
    return customer;
};

export const sortCustomerById = () => {
    return (dispatch, getState: () => IReduxState) => {
        const customers = getState().customerState.customers;
        dispatch(saveCustomers([...customers.reverse()]));
    };
};
