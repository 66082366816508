// export actions

import {
    saveSelectedAblesTyp,
    saveSelectedDeviceType,
    saveSelectedService,
    saveSettingsEmails
} from './actions/settings.actions';
import {
    getFacilityServicesTyp,
    getMeterServiceEmails,
    getProductList,
    uploadMeterServiceEmails,
    postProduct,
    postFacilityServiceTyp,
    deleteFacilityServiceTyp,
    updateProduct,
    deleteProduct,
    getDeviceTypeList,
    postDeviceType,
    updateDeviceType,
    deleteDeviceType,
    postAbleseType,
    getAbleseTypeList,
    updateAbleseTyp,
    deleteAbleseTyp, downloadMeterServiceEmailsFile, getMeterServiceEmailsInfo
} from './thunk/settings.operation';

export { settingsReducer } from './reducers/settings.reducer';

export const SettingsActions = Object.assign({},
    {
        uploadMeterServiceEmails,
        getMeterServiceEmails,
        saveSettingsEmails: saveSettingsEmails,
        getProductList: getProductList,
        postProduct: postProduct,
        saveSelectedService: saveSelectedService,
        getFacilityServicesTyp: getFacilityServicesTyp,
        postFacilityServiceTyp: postFacilityServiceTyp,
        deleteFacilityServiceTyp: deleteFacilityServiceTyp,
        updateProduct: updateProduct,
        deleteProduct,
        getDeviceTypeList,
        saveSelectedDeviceType,
        postDeviceType,
        updateDeviceType,
        deleteDeviceType,
        postAbleseType,
        getAbleseTypeList,
        updateAbleseTyp,
        saveSelectedAblesTyp,
        deleteAbleseTyp,
        downloadMeterServiceEmailsFile,
        getMeterServiceEmailsInfo
    });


