import { getContactData, getCustomerById } from '../../../customers/redux/thunk/customer.operations';
import { getDevices } from '../../../devices/redux/thunk/devices.operations';
import { getMeterPanels } from '../../../meterpanels/redux/thunk/meterpanel.operations';
import { getDeviceTypeList } from '../../../settings/redux/thunk/settings.operation';
import { getContactPersons } from './contact.operations';
import { getFacilityById } from './facility.operations';

export const fetchFacilityData = (kabID: string, aID: string) => {
    return async (dispatch) => {
        try {
            dispatch(getCustomerById(kabID));
            dispatch(getFacilityById(kabID, aID));
            dispatch(getContactPersons(kabID, aID));
            dispatch(getMeterPanels(kabID, aID));
            dispatch(getDevices(kabID, aID));
            dispatch(getDeviceTypeList());
            dispatch(getContactData(kabID));

        } catch (error) {
            console.error(error);
        }
    };
};