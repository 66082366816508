import axios from 'axios';
import { IFacility, IZaehlDaten } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
    setSnackbarStatus,
} from '../../../../common/redux/actions/global.action';
import { isNullOrEmpty, isNullOrUndefined } from '../../../../common/utils';
import { resetReduxForm } from '../../../customers/redux/thunk/customer.operations';
import {
    saveFacilities,
    saveFacilityEmail,
    saveSelectedFacility,
} from '../actions/facility.action';
import { FacilityActionTypes } from '../types/facility.action.types';

export const getFacilityById = (kabID: string, aID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${aID}`);
            if (response.data.length === 0) {
                dispatch(saveSelectedFacility(null));
            } else {
                const selectedFacility: IFacility = response.data[0];
                dispatch(saveSelectedFacility(selectedFacility));
                // fetch the facility´s email
                dispatch(getFacilityEmail(selectedFacility.Adresse.PLZ));
            }
            dispatch(removeLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
        }
    };
};
export const getFacilities = (kabID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen`);
            const facilities =
                isNullOrEmpty(response.data) || isNullOrUndefined(response.data)
                    ? []
                    : [...response.data];
            dispatch(saveFacilities([...facilities]));
            dispatch(removeLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
        } catch (error) {
            dispatch(saveFacilities([]));
            dispatch(removeLoadingState(FacilityActionTypes.FACILITY_IS_LOADING));
        }
    };
};
export const postFacility = (kabID, payload: IFacility) => {
    return async dispatch => {
        payload.KID = kabID;
        payload.Adresse.Land = 'DE';
        const facility = payload;
        // Hotfix 266875)
        facility.VertragLaufzeitItems[0].AnzahlPeriode = payload.VertragLaufzeitItems[0]
            .AnzahlPeriode
            ? payload.VertragLaufzeitItems[0].AnzahlPeriode
            : 1;

        dispatch(addLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        try {
            const response = await axios.post(`kabs/${kabID}/anlagen`, facility);
            dispatch(resetReduxForm('SLFacilityForm'));
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            return response.data;
        } catch (error) {
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        }
    };
};

export const updateFacility = (kabID, payload: IFacility) => {
    return async dispatch => {
        payload.KID = kabID;
        const facility: IFacility = payload;
        const { Produkte, ContactData, ZaehlerPlaetze, Geraete, ...restFacility } = facility;
        dispatch(addLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        // convert all PLZ to string
        restFacility.Adresse.PLZ = restFacility.Adresse.PLZ.toString();
        try {
            await axios.put(`kabs/${kabID}/anlagen/${facility.ID}`, restFacility);
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            dispatch(resetReduxForm('SLFacilityForm'));
        } catch (error) {
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        }
    };
};

export const deleteFacilityById = (kabID: string, id: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            await axios.delete(`kabs/${kabID}/anlagen/${id}`);
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        } catch (error) {
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
        }
    };
};

export const getFacilityEmail = (zipCode: string) => {
    return async dispatch => {
        try {
            const response = await axios.get(`meterpanelservices/emails/${zipCode}`);
            dispatch(saveFacilityEmail(response.data ? response.data : ''));
        } catch (error) {
            console.error(error);
        }
    };
};

export const postZaehldaten = (payload: IZaehlDaten) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            await axios.post(`emails/zaehldaten`, payload);
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            dispatch(
                setSnackbarStatus({ flag: true, text: 'E-Mail wurde gesendet', type: 'success' })
            );
        } catch (error) {
            dispatch(removeLoadingState(FacilityActionTypes.POST_FACILITY_LOADING_STATUS));
            dispatch(
                setSnackbarStatus({
                    flag: true,
                    text: 'E-Mail wurde nicht gesendet',
                    type: 'error',
                })
            );
            console.error(error);
        }
    };
};
