export const SettingActionTypes = {
    UPLOAD_EMAIL: 'UPLOAD_EMAIL',
    FETCH_EMAILS: 'FETCH_EMAILS',
    FETCH_COST_LIST: 'FETCH_COST_LIST',
    SAVE_EMAILS: 'SAVE_EMAILS',
    SAVE_Product_LIST: 'SAVE_Product_LIST',
    SAVE_SERVICES: 'SAVE_SERVICES',
    SAVE_SELECTED_SERVICE: 'SAVE_SELECTED_SERVICE',
    FETCH_SERVICES_TYP: 'FETCH_SERVICES_TYP',
    POST_SERVICES_TYP: 'POST_SERVICES_TYP',
    FETCH_DEVICE_TYPE: 'FETCH_DEVICE_TYPE',
    SAVE_DEVICE_TYPE: 'SAVE_DEVICE_TYPE',
    SAVE_SELECTED_DEVICE_TYPE: 'SAVE_SELECTED_DEVICE_TYPE',
    FETCH_ABLESE_TYPE: 'FETCH_ABLESE_TYPE',
    SAVE_ABLESE_TYP: 'SAVE_ABLESE_TYP',
    SAVE_SELECTED_ABLESE_TYP: 'SAVE_SELECTED_ABLESE_TYP',
    ADD_EMAIL_INFO: 'ADD_EMAIL_INFO',
    ADD_EMAIL_FILE: 'ADD_EMAIL_FILE'
};