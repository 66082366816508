import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { SlFileInputComponent } from '../../../common/components/forms';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { store } from '../../../store';

const styles = (theme: Theme) => MaterialUI.createStyles({
    inputFieldClass: {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        borderColor: 'black',
        border: '1px solid ',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 0.2rem none',

        },
    }
});

interface ISlAddArchiveProps extends WithStyles<typeof styles> {
    fileExtension?: string
}


class SlAddArchive extends React.Component<ISlAddArchiveProps> {

    private filename: string = 'Keine Datei Ausgewählt';


    public render() {
        return (<div style={{padding: 35}}>
            <MaterialUI.Grid container={true}
                             spacing={10}
                             direction={'column'}>
                <MaterialUI.Grid item={true}>
                    <Field name={'file'}
                           type='file'
                           extension={this.props.fileExtension}
                           component={SlFileInputComponent}
                           label={'Datei'}
                           validate={[FormValidator.required]}
                           required={true}
                           fileName={this.filename}
                           onChange={(event, newValue) => {
                               this.filename = newValue && newValue.name ? newValue.name : '';
                               store.dispatch(change('SlUploadFileForm', 'Filename', this.filename));
                           }}
                           options={{fullWidth: true, shrink: true}}/>
                </MaterialUI.Grid>
            </MaterialUI.Grid>
        </div>);
    }

}

export const SlUploadFileForm = reduxForm({
    form: 'SlUploadFileForm', // a unique identifier for this form
    initialValues: {Filename: ''}
})(MaterialUI.withStyles(styles)(SlAddArchive));