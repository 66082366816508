import { IDevice } from '../../../../common/interfaces/interface';
import { DeviceActionTypes } from '../types/devices.action.types';

export const devicesLoadingStatus = (flag) => {
    return dispatch => dispatch({
        type: DeviceActionTypes.DEVICES_LOADING_STATUS,
        payload: flag
    });
};
export const showOrderDeviceModal = (flag: boolean) => {
    return dispatch => dispatch({
        type: DeviceActionTypes.SHOW_REMOVE_DEVICE_MODAL,
        payload: flag
    });
};
export const saveDevices = (items: Array<IDevice>) => {
    return dispatch => dispatch({
        type: DeviceActionTypes.SAVE_DEVICES,
        payload: items
    });
};

export const saveSelectedDevice = (item: IDevice) => {
    return dispatch => dispatch({
        type: DeviceActionTypes.SAVE_SELECTED_DEVICE,
        payload: item
    });
};

export const changeDevice = (previousDevice: any, currentDevice: any) => {
    return dispatch => dispatch({
        type: DeviceActionTypes.CHANGE_DEVICE,
        payload: {previousDevice: previousDevice, currentDevice: currentDevice}
    });
};

