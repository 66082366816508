import axios from 'axios';
import { IMeterPlace } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
} from '../../../../common/redux/actions/global.action';

import { isNullOrEmpty } from '../../../../common/utils';
import { removeEmptykey } from '../../../../common/utils/Functions';
import { IReduxState } from '../../../../model/redux-state';
import { DeviceActionTypes } from '../../../devices/redux/types/devices.action.types';
import {
    saveMeterPanels,
    saveSelectedMeter,
    saveSelectedMeterPanels,
} from '../actions/meterpanels.action';
import { MeterPanelsActionTypes } from '../types/meterpanels.action.types';

export const postMeterPanels = (kabID: string, aID: string, payload: any) => {
    return async dispatch => {
        try {
            if (payload.Zaehlplaetze && payload.Zaehlplaetze.length > 0) {
                dispatch(addLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
                // remove empty Counter place from list;
                const meterpanels: any = removeEmptyMeterPanels(payload);
                // remove empty object from each counter place
                const counterPlaces = [...meterpanels.Zaehlplaetze].map(counterplace =>
                    removeEmptykey(counterplace)
                );
                await axios.post(`kabs/${kabID}/anlagen/${aID}/meterplaces`, [...counterPlaces]);
                await dispatch(getMeterPanels(kabID, aID));
                dispatch(removeLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
            }
        } catch (error) {
            dispatch(removeLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
        }
    };
};

const removeEmptyMeterPanels = payload => {
    return {
        Zaehlplaetze: [...payload.Zaehlplaetze.filter((x: IMeterPlace) => x.WohnungNr.length > 0)],
    };
};

export const updateMeterPanels = (kabID: string, aID: string, payload: Array<IMeterPlace>) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
            const counterPlaces = payload.map(counterplace => {
                const { Geraete, ...data } = removeEmptykey(counterplace);
                return data;
            });
            await axios.put(`kabs/${kabID}/anlagen/${aID}/meterplaces`, counterPlaces);
            dispatch(getMeterPanels(kabID, aID));
            dispatch(removeLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
        }
    };
};

export const getMeterPanels = (kabID: string, aID: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${aID}/meterplaces`);
            const meterPanels =
                response.data === null || response.data === null
                    ? []
                    : [...response.data.sort(compare)];

            if (!isNullOrEmpty(getState().meterPanelState.addedMeterPanelIdStart)) {
                const newMeterPanels: Array<IMeterPlace> = [];
                for (let i = 0; getState().meterPanelState.addedMeterPanelNumber > i; i++) {
                    const index = meterPanels.findIndex(
                        (meterPanel: IMeterPlace) =>
                            meterPanel.ID ===
                            getZIDFromStartID(getState().meterPanelState.addedMeterPanelIdStart, i)
                    );
                    if (index > -1) {
                        newMeterPanels.push(meterPanels[index]);
                        meterPanels.splice(index, 1);
                    }
                }
                dispatch(saveMeterPanels([...newMeterPanels, ...meterPanels]));
                dispatch(saveSelectedMeterPanels([...newMeterPanels]));
            } else {
                dispatch(saveMeterPanels([...meterPanels]));
            }
            dispatch(removeLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
        } catch (error) {
            dispatch(saveMeterPanels([]));
            dispatch(removeLoadingState(MeterPanelsActionTypes.METERPANEL_IS_LOADING));
        }
    };
};

export const getMeterPanel = (kabID: string, aID: string, zID: string) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            const response = await axios.get(`kabs/${kabID}/anlagen/${aID}/meterplaces/${zID}`);
            if (response && response.data) {
                dispatch(saveSelectedMeter(response.data ? response.data[0] : null));
            }
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(saveSelectedMeter(null));
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const uploadMeterpanels = (kabID: string, aId: string, payload: any) => {
    return async () => {
        try {
            await axios.post(`kabs/${kabID}/anlagen/${aId}/meterplaces/upload`, payload);
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteMeterPanels = (kabID: string, aID: string, ids: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
            await axios.delete(`kabs/${kabID}/anlagen/${aID}/meterplaces?ids=${ids}`);
            dispatch(removeLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
        } catch (error) {
            dispatch(removeLoadingState(MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS));
        }
    };
};

const getZIDFromStartID = (startID: string, i: number) => {
    const prefix = '0'.repeat(6 - startID.toString().length);
    return 'Z-' + prefix + (+startID + i);
};

const compare = (a, b) => {
    const firstID = +a.ID.split('Z-')[1];
    const secondID = +b.ID.split('Z-')[1];
    return firstID - secondID;
};
