import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field } from 'redux-form';
import uuid from 'uuid';
import { renderItemForSelectBox, SlSelectInputComponent } from '../../../common/components/forms';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import * as Enumarations from '../../../model/enums';
import { modalStyles } from '../../../theme/modal.styles';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 30 },
    });

export interface IPartnerProps extends WithStyles<typeof styles> {
    personOrCompany?: string;
    mailBox?: string;
    street?: string;
    showTaxField: boolean;
    umsatzsteuerpflichtig?: string;
}

class Partner extends React.Component<IPartnerProps, {}> {
    public static defaultProps: Partial<IPartnerProps> = {
        showTaxField: false,
    };

    private isCompany() {
        return (
            this.props.personOrCompany &&
            this.props.personOrCompany === Enumarations.Salutation.Company
        );
    }

    private isPerson() {
        return (
            this.props.personOrCompany &&
            (this.props.personOrCompany === Enumarations.Salutation.Male ||
                this.props.personOrCompany === Enumarations.Salutation.Female)
        );
    }

    private renderTitle() {
        if (this.isPerson()) {
            return (
                <MaterialUI.Grid item={true} xs={4}>
                    <Field
                        component={SlSelectInputComponent}
                        label={'Title'}
                        name={'Anrede.Person.Titel'}
                        options={{ fullWidth: true, shrink: true }}
                    >
                        {renderItemForSelectBox(Enumarations.Title)}
                    </Field>
                </MaterialUI.Grid>
            );
        }
        return null;
    }

    private renderFirstNameOrCompanyName() {
        if (this.isPerson()) {
            return (
                <MaterialUI.Grid item={true} xs={6}>
                    <Field
                        component={SlTextInputComponent}
                        label={'Vorname'}
                        name={'Anrede.Person.Vorname'}
                        // warn={value => 'testst'}
                        required={true}
                        validate={[
                            FormValidator.required,
                            FormValidator.maxLength50,
                            FormValidator.minLength2,
                        ]}
                        options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                    />
                </MaterialUI.Grid>
            );
        } else if (this.isCompany()) {
            return (
                <MaterialUI.Grid item={true} xs={6}>
                    <Field
                        component={SlTextInputComponent}
                        label={'Firmenname (1. Zeile)'}
                        name={'Anrede.Firma.Firmenname1'}
                        required={true}
                        validate={[
                            FormValidator.required,
                            FormValidator.maxLength50,
                            FormValidator.minLength2,
                        ]}
                        options={{ fullWidth: true, shrink: true }}
                    />
                </MaterialUI.Grid>
            );
        }
        return null;
    }

    private renderLastNameOrCompanyName() {
        if (this.isPerson()) {
            return (
                <MaterialUI.Grid item={true} xs={6}>
                    <Field
                        component={SlTextInputComponent}
                        label={'Nachname'}
                        name={'Anrede.Person.Nachname'}
                        required={true}
                        validate={[
                            FormValidator.required,
                            FormValidator.maxLength50,
                            FormValidator.minLength2,
                        ]}
                        options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                    />
                </MaterialUI.Grid>
            );
        } else if (this.isCompany()) {
            return (
                <MaterialUI.Grid item={true} xs={6}>
                    <Field
                        component={SlTextInputComponent}
                        label={'Firmenname (2. Zeile)'}
                        validate={[FormValidator.maxLength50]}
                        name={'Anrede.Firma.Firmenname2'}
                        options={{ fullWidth: true, shrink: true }}
                    />
                </MaterialUI.Grid>
            );
        }
        return null;
    }

    private renderTaxField() {
        if (
            this.isCompany() &&
            this.props.showTaxField &&
            this.props.umsatzsteuerpflichtig === 'JA'
        ) {
            return (
                <MaterialUI.Grid
                    container={true}
                    spacing={2}
                    className={this.props.classes.container}
                >
                    <MaterialUI.Grid item={true} style={modalStyles.item} xs={6}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Steuernummer'}
                            name={'Anrede.Firma.Steuernummer'}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} style={modalStyles.item} xs={6}>
                        <Field
                            component={SlTextInputComponent}
                            label={'UmsatzSteuer-ID'}
                            name={'Anrede.Firma.UmsatzSteuerID'}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            );
        }
        return null;
    }

    public render() {
        return (
            <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                <MaterialUI.Grid container={true} spacing={2}>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlSelectInputComponent}
                            required={true}
                            label={'Anrede'}
                            name={'Anrede.Typ'}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        >
                            {renderItemForSelectBox(Enumarations.Salutation)}
                        </Field>
                    </MaterialUI.Grid>
                    {this.renderTitle()}
                </MaterialUI.Grid>
                <MaterialUI.Grid
                    container={true}
                    spacing={2}
                    className={this.props.classes.container}
                >
                    {this.renderFirstNameOrCompanyName()}
                    {this.renderLastNameOrCompanyName()}
                </MaterialUI.Grid>

                <MaterialUI.Grid
                    container={true}
                    spacing={2}
                    className={this.props.classes.container}
                >
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Straße'}
                            validate={
                                this.props.mailBox
                                    ? []
                                    : [
                                          FormValidator.required,
                                          FormValidator.maxLength50,
                                          FormValidator.minLength2,
                                      ]
                            }
                            name={'Adresse.Strasse'}
                            required={!this.props.mailBox}
                            options={{
                                fullWidth: true,
                                shrink: true,
                                showInfoIcon: true,
                                infoText:
                                    'Maximal 25 Zeichen Pflichtfeld falls Postfach nicht befüllt',
                            }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Hausnummer'}
                            name={'Adresse.HausNummer'}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid
                    container={true}
                    spacing={2}
                    className={this.props.classes.container}
                >
                    <MaterialUI.Grid item={true} xs={2}>
                        <Field
                            component={SlTextInputComponent}
                            label={'PLZ'}
                            name={'Adresse.PLZ'}
                            required={true}
                            validate={[FormValidator.required, FormValidator.zipCode]}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Ort'}
                            name={'Adresse.Ort'}
                            required={true}
                            validate={[
                                FormValidator.required,
                                FormValidator.maxLength50,
                                FormValidator.minLength2,
                            ]}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Postfach'}
                            name={'Adresse.Postfach'}
                            parse={value => (!value ? null : Number(value))}
                            type={'number'}
                            required={!this.props.street}
                            validate={
                                this.props.street
                                    ? [FormValidator.mailBox]
                                    : [FormValidator.required, FormValidator.mailBox]
                            }
                            options={{
                                fullWidth: true,
                                shrink: true,
                                showInfoIcon: true,
                                infoText: 'Pflichtfeld falls "Straße" nicht befüllt',
                            }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={2}>
                        <Field
                            component={SlSelectInputComponent}
                            required={true}
                            label={'Land'}
                            name={'Adresse.Land'}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        >
                            {Object.keys(Enumarations.Country).map(value => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={value}>
                                    {Enumarations.Country[value]}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                {this.isCompany() && this.props.showTaxField && (
                    <MaterialUI.Grid container={true} spacing={2} style={{ marginTop: 20 }}>
                        <MaterialUI.Grid item={true} style={modalStyles.item} xs={2}>
                            <Field
                                component={SlSelectInputComponent}
                                label={'Umsatzsteuerpflichtig'}
                                name={'Anrede.Firma.Umsatzsteuerpflichtig'}
                                options={{ fullWidth: true, shrink: true }}
                            >
                                <MaterialUI.MenuItem value={'JA'}>Ja</MaterialUI.MenuItem>
                                <MaterialUI.MenuItem value={'NEIN'}>Nein</MaterialUI.MenuItem>
                            </Field>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                )}
                {this.renderTaxField()}
            </div>
        );
    }
}

export const PartnerComponent = MaterialUI.withStyles(styles)(Partner);
