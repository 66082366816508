import Moment from 'moment';
import { Constants } from '../utils/constants';

export class FormValidator {
    public static maxLength(value: string, max: number) {
        return value && value.length > max
            ? `Muss weniger als ${max} Zeichen umfassen.`
            : undefined;
    }

    public static minLength(value: string, min: number) {
        return value && value.length < min ? `Muss ${min} oder mehr Zeichen umfassen.` : undefined;
    }

    public static required(value) {
        return value !== '' && value !== null && value !== undefined
            ? undefined
            : 'Bitte füllen Sie das Feld aus.';
    }

    public static requiredArray(value) {
        return value !== '' && value !== null && value !== undefined && value.length > 0
            ? undefined
            : 'Bitte füllen Sie das Feld aus.';
    }

    public static installationDate(value) {
        return value && !FormValidator.isDateGermanFormatValid(value)
            ? 'Das Einbaudatum entspricht nicht dem Format'
            : undefined;
    }

    public static isDateFormatValid(value) {
        return (
            value && new RegExp('^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$').test(value)
        );
    }

    public static validDateFormat = value =>
        value && new RegExp(/^(\d{4})[-/](\d{1,2})[-/](\d{1,2})$/).test(value);

    public static isValidDate(value) {
        if (FormValidator.validDateFormat(value)) {
            return Moment(new Date(value)).isValid() ? null : 'Das Datum ist ungültig';
        }
        return null;
    }

    public static isDateGermanFormatValid(value) {
        return value && new RegExp('^\\d{2}[.]\\d{2}[.]\\d{4}$').test(value);
    }

    public static meterReadingDate(value) {
        return value && !FormValidator.isDateFormatValid(value)
            ? 'Das Ablesedatum entspricht nicht dem Format'
            : undefined;
    }

    public static isDateBefore = valueToCompare => value => {
        const newValue = Moment(value, 'YYYY-MM-DD');
        const installationDate = Moment(valueToCompare, 'YYYY-MM-DD');
        return valueToCompare && value && newValue.isBefore(installationDate)
            ? `Das eingegebene Datum muss nach dem ${new Date(valueToCompare).toLocaleString(
                  'de-DE',
                  {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                  }
              )} liegen`
            : undefined;
    };
    public static isBiggerOrEqualThan = valueToCompare => value => {
        if (!valueToCompare) {
            return undefined;
        }
        return Number(value) < Number(valueToCompare)
            ? `Der eingetragene Zählerstand darf nicht kleiner sein als ${new Intl.NumberFormat(
                  'de-DE'
              ).format(valueToCompare)}.`
            : undefined;
    };

    public static expirationDate(value) {
        return value && !new RegExp('^[23]\\d{3}$').test(value)
            ? 'Das Eichdatum entspricht nicht dem Format'
            : undefined;
    }

    public static installationValue(value) {
        return value && !new RegExp('^\\d{1,10}$').test(value)
            ? 'Der Einbaustand entspricht nicht dem Format'
            : undefined;
    }

    public static obisCode(value) {
        return value && !new RegExp('^\\d-\\d:\\d\\.\\d\\.\\d$').test(value)
            ? 'Die Obis Kennziffer entspricht nicht dem Format: x-x:x.x.x'
            : undefined;
    }

    public static zipCode(value) {
        return value && !new RegExp('^[0-9]{5}$').test(value)
            ? `Postleitzahl ist nicht gültig`
            : undefined;
    }

    public static mailBox(value) {
        return value && !new RegExp('^[0-9]{4,8}$').test(value)
            ? `Muss weniger als 8 Zeichen umfassen.`
            : undefined;
    }

    public static isPrice(value) {
        return value && !new RegExp('\\d*\\,?\\d+').test(value)
            ? `Bitte geben Sie nur Ziffern ein!`
            : undefined;
    }

    public static isNumber(value) {
        return value && !new RegExp('^-?\\d*$').test(value)
            ? `Bitte geben Sie nur Ziffern ein!`
            : undefined;
    }

    public static age(value) {
        const birthday = Moment(value, 'YYYY-MM-DD');
        const age = Moment().diff(birthday, 'years');
        if (age > 17) {
            return undefined;
        } else {
            return 'Die Person muss mindestens 18 Jahre alt sein.';
        }
    }

    public static maxLength25(value) {
        return FormValidator.maxLength(value, 25);
    }

    public static maxLength50(value) {
        return FormValidator.maxLength(value, 50);
    }

    public static maxLength255(value) {
        return FormValidator.maxLength(value, 255);
    }

    public static minLength2(value: string) {
        return FormValidator.minLength(value, 2);
    }

    public static malo(value: string) {
        return value && !new RegExp('^[0-9a-zA-Z]{11}$').test(value)
            ? `MaLo muss 11 Zeichen umfassen.`
            : undefined;
    }

    public static melo(value: string) {
        return value && !new RegExp('^[0-9a-zA-Z]{33}$').test(value)
            ? `MeLo muss 33 Zeichen umfassen.`
            : undefined;
    }

    public static phoneNumber(value) {
        return value && !new RegExp('[0-9+/]{10,13}$').test(value)
            ? `Telefonnummer ist nicht gültig.`
            : undefined;
    }

    public static email(value) {
        return value &&
            !new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$').test(value)
            ? `Email nicht gültig.`
            : undefined;
    }

    public static normalizeDate = value => {
        if (!value) {
            return value;
        }
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length < 1) {
            return onlyNums;
        }
        if (onlyNums.length < 3) {
            return `${onlyNums.slice(0, 2)}`;
        } else if (onlyNums.length <= 4) {
            return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
        }
        return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(4, 8)}`;
    };

    public static validateZaehlwerkVKS = (value, ZaehlwerkVKS) => {
        const max = ZaehlwerkVKS ? ZaehlwerkVKS : Constants.ZaehlwerkVKS;
        if (value) {
            const preDecimal = value.split(',')[0];
            if (preDecimal) {
                return preDecimal.length <= max
                    ? undefined
                    : `Muss weniger als ${max} Zeichen vor dem Komma  umfassen.`;
            } else {
                return undefined;
            }
        }
        return undefined;
    };
    public static validateZaehlwerkNKS = (value, ZaehlwerkNKS) => {
        const max = ZaehlwerkNKS || ZaehlwerkNKS === 0 ? ZaehlwerkNKS : Constants.ZaehlwerkNKS;
        if (value) {
            const decimal = value.split(',')[1];
            if (decimal) {
                return decimal.length <= max
                    ? undefined
                    : `Muss weniger als ${max} Zeichen nach dem Komma  umfassen.`;
            } else {
                return undefined;
            }
        }
        return undefined;
    };

    public static devicePin(value) {
        return value && !new RegExp(/^\d{4}$/).test(value)
            ? `Der PIN ist nicht gültig.`
            : undefined;
    }

    public static validateKorrigierterVerbrauch = value => {
        if (value) {
            const preDecimalMax = 13;
            const decimalMax = 3;
            const preDecimal = value.split(',')[0];
            const decimal = value.split(',')[1];
            if (preDecimal && preDecimal.length > preDecimalMax) {
                return `Muss weniger als ${preDecimalMax} Zeichen vor dem Komma  umfassen.`;
            } else if (decimal && decimal.length > decimalMax) {
                return `Muss weniger als ${decimalMax} Zeichen nach dem Komma  umfassen.`;
            }
        }
        return undefined;
    };

    public static serialNumber(value) {
        return value && !new RegExp(/^[A-Za-z0-9]+$/).test(value)
            ? 'Ungültiges Format: es sind nur Buchstaben und Zahlen erlaubt.'
            : undefined;
    }
}
