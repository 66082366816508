import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent, SLModal } from '../../../../common/components/modal';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { IDeviceType } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { IForm } from '../../../../model/interfaces/IForm';
import { titleStyles } from '../../../../theme/common.styles';
import { SlDeviceTypeManagementForm } from './device-type.form';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    showAddModal: boolean;
    editDeviceType: boolean;
    shouldShowDeleteAlert: boolean;
    modalTitle: string;
}

export interface IDeviceTypeProps extends WithStyles<typeof styles> {
    deviceTypeList: Array<IDeviceType>;
    deviceTypeManagementForm: IForm;
    selectedDeviceType?: IDeviceType;
    onSelectedDeviceType?: (item: any) => void;
    onAddDeviceType?: (item) => void;
    onUpdateDeviceType?: (item) => void;
    onDelete?: (item: any) => void;
}

class DeviceTypeManagement extends React.Component<IDeviceTypeProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IDeviceTypeProps) {
        super(props);
        this.actionBarItems = [
            {
                label: deTranslator.device.addDeviceType,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () =>
                    this.setState({ ...this.state, showAddModal: true, editDeviceType: false }),
                disable: false,
            },
            {
                label: deTranslator.actions.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.props.selectedDeviceType &&
                    this.setState({
                        ...this.state,
                        showAddModal: true,
                        modalTitle: 'Grundtyp Bearbeiten',
                        editDeviceType: true,
                    }),
                disable: true,
            },
            {
                label: deTranslator.actions.delete,
                icon: <MaterialUIIcons.Delete color={'secondary'} />,
                action: () =>
                    this.props.selectedDeviceType &&
                    this.setState({
                        ...this.state,
                        shouldShowDeleteAlert: true,
                        editDeviceType: false,
                    }),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            showAddModal: false,
            editDeviceType: false,
            shouldShowDeleteAlert: false,
            modalTitle: 'Grundtyp Hinzufügen',
        };
    }

    private buildColumns = () => {
        return [
            {
                title: 'Name',
                field: 'grundTyp',
            },
        ];
    };

    public serviceOperation = () => {
        if (this.state.editDeviceType) {
            this.props.onUpdateDeviceType(this.props.deviceTypeManagementForm.formValue);
        } else {
            this.props.onAddDeviceType(this.props.deviceTypeManagementForm.formValue);
        }
        this.setState({ editDeviceType: false, showAddModal: false });
        this.props.onSelectedDeviceType(null);
    };
    public deleteDevicetype = () => {
        this.setState({ showAddModal: false, shouldShowDeleteAlert: false });
        this.props.onDelete(this.props.selectedDeviceType.id);
    };
    private buildTableActions = () => {
        const newValue = this.state.tableActionBarItems.slice(1).map(item => {
            item.disable = this.props.selectedDeviceType ? false : true;
            return item;
        });
        return [this.actionBarItems[0], ...newValue];
    };

    public render() {
        const deviceTypeList = [...this.props.deviceTypeList].map(item => {
            return Object.assign({}, item);
        });
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Geräte Grundtyp Verwaltung (Technik)
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                {/* <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div> */}
                <div>
                    <BasicTable columns={[...this.buildColumns()]} data={deviceTypeList} />
                </div>
                <SLModal
                    title={this.state.modalTitle}
                    open={this.state.showAddModal}
                    onOkButtonClick={this.serviceOperation}
                    OkButtonDisabled={this.props.deviceTypeManagementForm.invalid}
                    onClose={() => {
                        this.setState({ showAddModal: false, editDeviceType: false });
                        // this.props.onSelectedDeviceType(null);
                    }}
                >
                    <SlDeviceTypeManagementForm
                        currentFormValue={this.props.deviceTypeManagementForm.formValue}
                        editMode={this.state.editDeviceType}
                        defaultDeviceType={this.props.selectedDeviceType}
                    />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={'Geräte Grundtyp Löschen'}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.shouldShowDeleteAlert}
                    handleOK={this.deleteDevicetype}
                    handleCancel={() => this.setState({ shouldShowDeleteAlert: false })}
                    showCancelButton={true}
                >
                    {`${deTranslator.product.deleteTextStart}`}
                    {this.props.selectedDeviceType && (
                        <b>{this.props.selectedDeviceType.grundTyp}</b>
                    )}
                    {`${deTranslator.product.deleteTextEnd}`}
                </SlAlertComponent>
            </div>
        );
    }
}

export const DeviceTypeManagementComponent = MaterialUI.withStyles(styles)(DeviceTypeManagement);
