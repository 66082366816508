import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IWebinar } from '../../../../common/interfaces/interface';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { SlDateInputComponent, SlSelectInputComponent } from '../../../../common/components/forms';
import { sbColors } from '../../../../theme/app.colors';
import { WebinarStatusEnum } from '../../../../model/enums';
import { renderOptionsForSelectBox } from '../../../../common/components/forms/select-input.component';

const styles = () => MaterialUI.createStyles({});

export interface IWebinarProps extends WithStyles<typeof styles> {
    editMode: boolean;
    defaultWebinar: IWebinar;
    initialize?: (data: any) => void;
}

class WebinarNewsForm extends React.Component<IWebinarProps, {}> {
    constructor(props: IWebinarProps) {
        super(props);
        if (this.props.editMode && this.props.defaultWebinar) {
            const { createdAt, updatedAt, ...rest } = this.props.defaultWebinar;
            this.props.initialize(rest);
        }
    }

    public render() {
        return (
            <div style={{ padding: '20px 20px 40px' }}>
                <MaterialUI.Grid container={true} spacing={5}>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={`status`}
                            component={SlSelectInputComponent}
                            label={'Status'}
                            validate={[FormValidator.required]}
                            required={true}
                            style={{ color: sbColors.orange, width: '100%' }}
                            options={{ fullWidth: true }}
                        >
                            {renderOptionsForSelectBox(WebinarStatusEnum)}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'title'}
                            label={'Titel'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'subTitle'}
                            label={'Subtitle'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'content'}
                            label={'Inhalt'}
                            type={'text'}
                            multiline={true}
                            rows={5}
                            disableUnderline={true}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'publishedDate'}
                            label={'Datum'}
                            type={'text'}
                            component={SlDateInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} />
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            name={'publishedTimeFrom'}
                            label={'Uhrzeit von'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            name={'publishedTimeTo'}
                            label={'Uhrzeit bis'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={8}>
                        <Field
                            name={'link'}
                            label={'Link'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={''}
                            required={true}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} />
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const WebinarFormComponent = MaterialUI.withStyles(styles)(WebinarNewsForm);

export const SlWebinarForm = reduxForm({
    form: 'webinarForm',
})(WebinarFormComponent);
