import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import * as Enumarations from '../../../model/enums';
import { IReduxState } from '../../../model/redux-state';
import { FormValidator } from '../../validators/form.validator';
import { renderItemForSelectBox, SlSelectInputComponent } from './select-input.component';
import { SlTextInputComponent } from './text-field.component';


const styles = () => MaterialUI.createStyles({
    container: {
        padding: 20,
        maxHeight: 'calc(100vh - 323px)',
        overflowX: 'auto' as 'auto',
        overflowY: 'auto' as 'auto'
    }
});

export interface IContactDataProps extends WithStyles<typeof styles> {
    saveContactData?: (contactData, isCreatedFromEditPage?: boolean) => void;
    handleSubmit: any;
    valid: boolean;
    initialize: (contactData) => void;
    selectedContactData?: any;
    showRole?: boolean;
    wizardMode?: boolean;
    personOrCompany?: string;
    showCompany?: boolean;
}


class ContactPersonForm extends React.Component<IContactDataProps, {}> {

    public static defaultProps: Partial<IContactDataProps> = {

        wizardMode: false,
        showRole: false,
        showCompany: true,
    };

    constructor(props: IContactDataProps) {
        super(props);
        if (this.props.selectedContactData) {
            this.props.initialize(Object.assign({}, this.props.selectedContactData));
        } else {
            this.props.initialize(null);
        }

    };

    private renderPerson = () => {
        if (this.props.personOrCompany !== Enumarations.Salutation.Company) {
            return (
                <MaterialUI.Grid container={true} className={this.props.classes.container} spacing={2}>
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field component={SlTextInputComponent}
                               label={'Vorname'}
                               required={true}
                               name={'Anrede.Person.Vorname'}
                               validate={[FormValidator.required, FormValidator.maxLength50, FormValidator.minLength2]}
                               options={{fullWidth: true, shrink: true, showInfoIcon: true}}/>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field component={SlTextInputComponent}
                               label={'Nachname'}
                               name={'Anrede.Person.Nachname'}
                               required={true}
                               validate={[FormValidator.required, FormValidator.maxLength50, FormValidator.minLength2]}
                               options={{fullWidth: true, shrink: true, showInfoIcon: true}}/>

                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            );
        }
        return null;
    };

    private renderTitle = () => {
        if (this.props.personOrCompany !== Enumarations.Salutation.Company) {
            return (
                <MaterialUI.Grid item={true} xs={4}>
                    <Field component={SlSelectInputComponent}
                           label={'Title'}
                           name={'Anrede.Person.Titel'}
                           options={{fullWidth: true, shrink: true}}>
                        {renderItemForSelectBox(Enumarations.Title)}
                    </Field>
                </MaterialUI.Grid>
            );
        }
        return null;
    };

    private renderCompanyName = () => {
        if (this.props.personOrCompany === Enumarations.Salutation.Company) {
            return (
                <MaterialUI.Grid container={true} className={this.props.classes.container} spacing={2}>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field component={SlTextInputComponent}
                               label={'Firmenname'}
                               name={'Anrede.Firma.Firmenname1'}
                               required={true}
                               validate={[FormValidator.required, FormValidator.maxLength50, FormValidator.minLength2]}
                               options={{fullWidth: true, shrink: true}}/>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            );
        }

    };

    public render() {
        return (
            <div>
                <MaterialUI.Grid container={true} spacing={2} className={this.props.classes.container}>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field component={SlSelectInputComponent}
                               required={true}
                               label={'Anrede'}
                               name={'Anrede.Typ'}
                               validate={[FormValidator.required]}
                               options={{fullWidth: true, shrink: true}}>
                            {this.props.showCompany && renderItemForSelectBox(Enumarations.Salutation)}
                            {!this.props.showCompany && renderItemForSelectBox(Enumarations.SalutationNatural)}
                        </Field>
                    </MaterialUI.Grid>
                    {this.renderTitle()}
                    {this.props.showRole ? <MaterialUI.Grid item={true} xs={4}>
                        <Field component={SlSelectInputComponent}
                               required={this.props.showRole}
                               label={'Rolle'}
                               name={'Rolle'}
                               validate={this.props.showRole ? [FormValidator.required] : []}
                               options={{fullWidth: true, shrink: true}}>
                            {renderItemForSelectBox(Enumarations.ContactPersonRolles)}
                        </Field>
                    </MaterialUI.Grid> : ''}
                </MaterialUI.Grid>

                {this.renderPerson()}
                {this.renderCompanyName()}
                <MaterialUI.Grid container={true} className={this.props.classes.container} spacing={2}>
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field name="Telefon"
                               component={SlTextInputComponent}
                               required={(this.props['hasEmailValue']) ? false : true}
                               label="Telefonnnumer"
                               validate={this.props['hasEmailValue'] ? [] : [FormValidator.required]}
                               options={{
                                   fullWidth: true,
                                   shrink: true,
                                   showInfoIcon: true,
                                   infoText: deTranslator.toolTip.phoneOrEmail
                               }}/>

                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field name="Email"
                               component={SlTextInputComponent}
                               label="Email"
                               type="email"
                               required={this.props['hasTelephoneValue'] ? false : true}
                               validate={this.props['hasTelephoneValue'] ? [FormValidator.email] : [FormValidator.email, FormValidator.required, FormValidator.maxLength50]}
                               options={{
                                   fullWidth: true,
                                   shrink: true,
                                   showInfoIcon: true,
                                   infoText: deTranslator.toolTip.emailOrPhone
                               }}/>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
                <MaterialUI.Grid container={true} className={this.props.classes.container}
                                 justify={'flex-end'}>
                    <MaterialUI.Grid item={true} xs={12}>
                        <Field component={SlTextInputComponent}
                               validate={[FormValidator.maxLength255]}
                               label={'Zusatzinformationen'}
                               name={'Zusatzinformationen'}
                               options={{
                                   fullWidth: true,
                                   shrink: true,
                                   infoText: 'Maximal 255 Zeichen',
                                   showInfoIcon: true
                               }}/>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>);
    }


}

const mapStateToProps = (state: IReduxState) => {
    const hasEmailValue = selector(state, 'Email');
    const personOrCompany = selector(state, 'Anrede.Typ');
    const hasTelephoneValue = selector(state, 'Telefon');
    return {
        hasEmailValue,
        personOrCompany,
        hasTelephoneValue
    };
};

export const ContactPersonReduxForm = reduxForm({
    form: 'contactPersonForm', // a unique identifier for this form
    destroyOnUnmount: false // don t delete the values when the form gets destroyed/tabs switch
})(MaterialUI.withStyles(styles)(ContactPersonForm));

const selector = formValueSelector('contactPersonForm');


export const SlContactDataForm = connect(mapStateToProps)(ContactPersonReduxForm);
