import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import Moment from 'moment';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { SLModal } from '../../../../common/components/modal';
import {
    BasicTable,
    IMuiTableColumn,
} from '../../../../common/components/table/mui-table.component';
import { IFileInfo } from '../../../../common/interfaces/interface';
import { isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { Roles } from '../../../../model/enums';
import { IForm } from '../../../../model/interfaces/IForm';
import { titleStyles } from '../../../../theme/common.styles';
import { SlUploadFileForm } from '../../../archiv/components/sl-upload-file.form';

const styles = () => MaterialUI.createStyles({});

export interface IFacilityEmailProps extends WithStyles<typeof styles> {
    facilityEmailForm: IForm;
    emailFileInfo: IFileInfo;
    uploadFile: () => void;
    downloadFile: () => void;
}

interface IState {
    // tableActionBarItems: Array<ISLActionBar>;
    showModal: boolean;
}

class FacilityEmailComponent extends React.Component<IFacilityEmailProps, IState> {
    private readonly tableColumns: Array<IMuiTableColumn> = [];
    private tableActionBarItems: Array<ISLActionBar> = [];

    public constructor(props: IFacilityEmailProps) {
        super(props);

        this.tableActionBarItems = [
            {
                label: 'NEUE CSV-Datei hochladen',
                icon: null,
                action: () => this.setModalVisible(),
                disable: false,
            },
            {
                label: 'CSV-Datei runterladen',
                icon: null,
                action: () => this.handleDownloadFile(),
                disable: false,
            },
        ];

        this.state = {
            showModal: false,
        };

        this.tableColumns = [
            {
                title: 'Ordner',
                field: 'folder',
            },
            {
                title: 'Dateiname',
                field: 'Name',
            },
            {
                title: 'Hochgeladen am',
                field: 'LastModified',
                render: rowData => Moment(rowData.LastModified).format('DD.MM.YYYY'),
            },
            {
                title: 'Größe ',
                field: 'ContentLength',
                render: rowData => `${(rowData.ContentLength / 1024).toFixed(1)} KB`,
            },
        ];
    }

    private setModalVisible = () => {
        this.setState({ showModal: true });
    };
    public handleUpload = () => {
        this.props.uploadFile();
        this.setState({ showModal: false });
    };
    public handleDownloadFile = () => {
        if (!this.tableActionBarItems[1].disable) {
            this.props.downloadFile();
        }
    };

    private buildTabs = () => {
        if (this.props.emailFileInfo) {
            this.tableActionBarItems[1].disable = false;
        }

        return this.tableActionBarItems;
    };

    public render() {
        return (
            <div>
                <MaterialUI.Typography
                    variant={'h6'}
                    style={titleStyles.fontWeight350}
                    color={'inherit'}
                >
                    E-Mail Adressen Verwaltung
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTabs()} showDivider={false} />
                    )}
                </div>

                <BasicTable
                    columns={this.tableColumns}
                    data={
                        this.props.emailFileInfo
                            ? [{ folder: 'Zählerservice-Postfächer', ...this.props.emailFileInfo }]
                            : []
                    }
                />

                <SLModal
                    title={'CSV-Datei hochladen'}
                    okButtonText={'HOCHLADEN'}
                    fullWidth={false}
                    open={this.state.showModal}
                    onOkButtonClick={this.handleUpload}
                    OkButtonDisabled={this.props.facilityEmailForm.invalid}
                    onClose={() => {
                        this.setState({ showModal: false });
                    }}
                >
                    <SlUploadFileForm fileExtension={'.csv'} />
                </SLModal>
            </div>
        );
    }
}

export const FacilityEmail = MaterialUI.withStyles(styles)(FacilityEmailComponent);
