
import { IBillRecipient } from '../../common/interfaces/interface';
import {Salutation} from '../enums';


export const DEVIATING_BILL_RECIPIENT_DEFAULT_VALUE: IBillRecipient = {
    Anrede: {
        Typ: Salutation.Male,
        Person: {
            Titel: '',
            Vorname: '',
            Nachname: '',
            Geburtsdatum: '',
        },
    },
    Adresse: {
        ID: '',
        Land: '',
        PLZ: null,
        Ort: '',
        Strasse: '',
        HausNummer: '',
        Postfach: null,
        Zusatzinformationen:''
    }
}