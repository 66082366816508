import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import { listWebinars } from './query';
import { IWebinar } from '../../../../common/interfaces/interface';
import { createWebinar, updateWebinar } from './mutation';

export const getSlimWebinarList = async () => {
    const result = await (API.graphql({
        query: listWebinars,
        authMode: 'AWS_IAM',
    }) as Promise<{
        data: { listWebinars: any };
    }>);
    return result.data.listWebinars;
};

export const createSlimWebinar = async payload => {
    try {
        return API.graphql({
            query: createWebinar,
            authMode: 'AWS_IAM',
            variables: {
                input: {
                    ...payload,
                },
            },
        });
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};
export const updateSlimWebinar = async (data: IWebinar) => {
    try {
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        const result = await (API.graphql({
            query: updateWebinar,
            authMode: 'AWS_IAM',
            variables: { input: { ...payload } },
        }) as Promise<{ data: { updateWebinar: any } }>);
        return result['data'].updateWebinar;
    } catch (e) {
        console.error(e);
    }
};
