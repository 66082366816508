import {
    addedMeterPanelsNumber,
    addedMeterPanelsStartID,
    meterPanelsLoadingStatus,
    postAppendDevicesLoadingStatus,
    postMeterPanelsLoadingStatus,
    saveAllSelectedMeters,
    saveMeterPanels,
    saveMetersETDT,
    saveSelectedMeter,
    saveSelectedMeterETDT,
    saveSelectedMeterPanels,
} from './actions/meterpanels.action';
import {
    getMeterPanels,
    getMeterPanel,
    postMeterPanels,
    updateMeterPanels,
    uploadMeterpanels,
    deleteMeterPanels,
} from './thunk/meterpanel.operations';

import { fetchMeterPanelData } from './thunk/meterpanel-data.operations';

// export reducers
export { meterPanelReducer } from './reducers/meterpanels.reducer';

// export actions and thunk operations

export const MeterPanelsActions = Object.assign(
    {},
    { saveSelectedMeterPanels: saveSelectedMeterPanels },
    { saveMeterPanels: saveMeterPanels },
    { meterPanelsLoadingStatus: meterPanelsLoadingStatus },
    { addedMeterPanelsNumber: addedMeterPanelsNumber },
    { addedMeterPanelsStartID: addedMeterPanelsStartID },
    { postMeterPanelsLoadingStatus: postMeterPanelsLoadingStatus },
    { getMeterPanels: getMeterPanels },
    { postMeterPanels: postMeterPanels },
    { postAppendDevicesLoadingStatus: postAppendDevicesLoadingStatus },
    { saveSelectedMeter: saveSelectedMeter },
    { saveMeterETDTs: saveMetersETDT },
    { updateMeterPanels: updateMeterPanels },
    { saveAllSelectedMeters: saveAllSelectedMeters },
    { saveSelectedMeterETDT: saveSelectedMeterETDT },
    { uploadMeterpanels: uploadMeterpanels },
    { getMeterPanel: getMeterPanel },
    { fetchMeterPanelData: fetchMeterPanelData },
    { deleteMeterPanels: deleteMeterPanels }
);
