import { API } from 'aws-amplify';
import { GetSlimData, kundenanlagenbetreiberByKID } from './graphql-operations/kundenanlagenbetreibers.queries';
import { getIdTokenForSlimApi } from '../utils';


interface FetchResponse {
    items: any[];
    nextToken: string | null;
}

const fetchKabList = async (
    query: any,
    limit: number = 100,
    nextToken: string | null = null
): Promise<FetchResponse> => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
       // console.log('Fetching with nextToken:', nextToken);

        const response = await API.graphql({
            query,
            variables: {
                limit:limit,
                sortDirection: 'DESC',
                nextToken,
            },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as { data: { listKundenanlagenbetreibers: FetchResponse } };

       // console.log('Response:', response);
        return response.data.listKundenanlagenbetreibers;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

// Fetches all pages of data recursively
export const fetchAllSlimKab = async (
    query: any,
    limit: number = 100,
    nextToken: string | null = null
): Promise<any[]> => {
    try {
        const { items, nextToken: newNextToken } = await fetchKabList(query, limit, nextToken);

        if (newNextToken) {
            // Fetch next page and merge results
            const subsequentItems = await fetchAllSlimKab(query, limit, newNextToken);
            return [...items, ...subsequentItems];
        } else {
            // No more pages
            return items;
        }

    } catch (error) {
        console.error('Error fetching all data:', error);
        throw error;
    }
};

export const getKundenanlagenbetreiberByKID = async KID => {
    const lambdaAuthToken = await getIdTokenForSlimApi();

    const response = await (API.graphql({
        query: kundenanlagenbetreiberByKID,
        variables: { KID: KID },
        authMode: 'AWS_LAMBDA',
        authToken: lambdaAuthToken,
    }) as Promise<{
        data: { kundenanlagenbetreiberByKID: any };
    }>);

    return response.data.kundenanlagenbetreiberByKID.items[0] || null;
};



export const fetchSlimDataAsCSV= async (
): Promise<any> => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        // console.log('Fetching with nextToken:', nextToken);

        const response = await API.graphql({
            query:GetSlimData,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as { data: { getSlimData: {data_base64:string,format:string} } };

        // console.log('Response:', response);
        return response.data.getSlimData;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};




