import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import * as MaterialUI from '../../../materialUI/MaterialUI';

import { appStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        tableContainer: {
            height: 'calc(100vh - 220px)',
            // overflow: 'auto',
        },
    });

export interface ISlimKabProps extends WithStyles<typeof styles> {
    slimCustomers: any[];
    onCellClick: (data) => void;
}

// interface IState {}

class AllSlimKabs extends React.Component<ISlimKabProps, any> {
    private readonly customerTableHeaderItems: Array<IMuiTableColumn>;
    //  private selectedKab: any = null;
    //  private deleteTextTitle: string = null;

    constructor(props: ISlimKabProps) {
        super(props);

        this.customerTableHeaderItems = [
            {
                title: 'Name / Firma',
                field: 'CustomerName',
                render: (rowData: any) => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.clickOnKabId(rowData)}
                        >
                            {rowData.CustomerName}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Ort',
                field: 'Ort',
            },
            {
                title: 'Anzahl Anlagen',
                field: 'AnzahlAnlagen',
            },
        ];
    }

    private clickOnKabId = item => {
        this.props.onCellClick(`slim/kabs/${item.KID}`);
    };
    private buildTableBodyItems = () => {
        return this.props.slimCustomers.map((item: any) => {
            const { kabStammdaten } = item;
            const { Anrede, Adresse } = kabStammdaten;
            const { Firma } = Anrede;
            return {
                KID: item.KID,
                CustomerName: Firma.Firmenname1,
                Ort: Adresse.Ort,
                AnzahlAnlagen: item.kundenanlageList && item.kundenanlageList.items.length,
            };
        });
    };

    public render() {
        return (
            <div style={appStyles.container} className={this.props.classes.tableContainer}>
                <BasicTable
                    columns={this.customerTableHeaderItems}
                    options={{
                        maxBodyHeight: 'calc(100vh - 200px)',
                        actionsColumnIndex: -1,
                        sorting: true,
                    }}
                    data={this.buildTableBodyItems()}
                />
            </div>
        );
    }
}

export const SlimKABs = MaterialUI.withStyles(styles)(AllSlimKabs);
