import { WithStyles } from '@material-ui/styles';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { Storage } from 'aws-amplify';
import { BasicTable, IMuiTableColumn } from '../../../common/components/table/mui-table.component';
import {
    convertDateGermanDateFormat,
    downloadBlob,
    isUserRole,
} from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { DokumentKategorie } from '../../../model/constants';
import { Roles } from '../../../model/enums';
import { appStyles } from '../../../theme/common.styles';
import { ISlimFileData } from '../interfaces/slim.interface';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

const styles = theme =>
    MaterialUI.createStyles({
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 165px)',
            padding: theme.spacing(3),
        },
    });

export interface ISlimDokumenteProps extends WithStyles<typeof styles> {
    kabId: string;
    aId: string;
    dokumente: ISlimFileData[];
}

class SlimDokumenteComponent extends React.Component<ISlimDokumenteProps, any> {
    constructor(props: ISlimDokumenteProps) {
        super(props);
        this.state = {};
    }

    private downloadDocument = async (event, file) => {
        // console.log(file);
        const result: any = await Storage.get(file.Pfad, {
            download: true,
            contentType: file.ContentType,
        });
        if (result) {
            downloadBlob(result.Body, file.Filename);
        }
        /* const response = await slimDownloadDocument(
            this.props.kabId,
            this.props.aId,
            rowData['ID']
        );
        const result = response.data;
        if (result.FileContents) {
            downloadFileFromBase64String(
                result.FileContents,
                rowData.Filename,
                rowData.ContentType
            );
        }*/
    };

    private tableColumns(): Array<IMuiTableColumn> {
        return [
            {
                title: 'Dokumentenname',
                field: 'Filename',
            },
            {
                title: 'Klassifizierung',
                field: 'Klassifizierung',
                render: rowData => {
                    if (rowData) {
                        const category = DokumentKategorie.find(
                            item => item.value === rowData['Klassifizierung']
                        );

                        return category.value === 'Sonstige'
                            ? decodeURI(rowData.AndereKlassifizierung)
                            : category.label || '';
                    }

                    return '';
                },
            },
            {
                title: 'Uploaddatum',
                field: 'createdAt',
                defaultSort: 'desc',
                render: rowData => {
                    return rowData ? convertDateGermanDateFormat(rowData['createdAt']) : '';
                },
            },
        ];
    }
    public render() {
        return (
            <div className={this.props.classes.tabContent} style={appStyles.container}>
                <BasicTable
                    columns={this.tableColumns()}
                    options={{ maxBodyHeight: 'calc(100vh - 200px)', actionsColumnIndex: -1 }}
                    data={cloneDeep(
                        this.props.dokumente
                            ? this.props.dokumente.filter(
                                  doc =>
                                      DokumentKategorie.map(item => item.value).indexOf(
                                          doc.Klassifizierung
                                      ) > -1
                              )
                            : []
                    )}
                    actions={
                        isUserRole(Roles.Admin)
                            ? [
                                  {
                                      icon: () => (
                                          <MaterialUI.IconButton color="secondary">
                                              <MaterialUIIcons.CloudDownload />
                                          </MaterialUI.IconButton>
                                      ),
                                      tooltip: 'Herunterladen',
                                      onClick: this.downloadDocument,
                                      disable: true,
                                  },
                              ]
                            : []
                    }
                />
            </div>
        );
    }
}

export const SlimDokumente = MaterialUI.withStyles(styles)(SlimDokumenteComponent);
