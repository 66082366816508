import { ArchiveActionTypes } from '../types/archive.types';

export const fetchArchivesLoading = (flag) => {
    return dispatch => dispatch({
        type: ArchiveActionTypes.FETCH_ARCHIVES_LOADING,
        payload: flag
    });

};
export const saveArchives = (archives: Array<any>) => {
    return dispatch => dispatch({
        type: ArchiveActionTypes.SAVE_ARCHIVES,
        payload: archives
    });
};
export const saveFileBase64 = (data:string) => {
    return dispatch => dispatch({
        type: ArchiveActionTypes.DOWNLOAD_ARCHIVE,
        payload: data
    });
};

