import { Theme } from '@material-ui/core';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';

const styles = (theme: Theme) => MaterialUI.createStyles({
    inputField: {height: '2.18em'},
    wrapperInput: {
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        width: 300,
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    formLabel: {
        fontSize: 13,
        padding: 5
    },

    textContainer: {
        paddingTop: 5,
        width: '55%'
    }
});

const CustomizedFileInputs = ({
                                  input: {value: omitValue, onChange, onBlur, ...inputProps},
                                  meta: omitMeta,
                                  label,
                                  style,
                                  options,
                                  required,
                                  placeholder,
                                  extension,
                                  fileName,
                                  ...props
                              }) => {
    const {classes} = props;

    const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

    return (
        <div>
            <label className={classes.formLabel}>
                {label}
            </label>
            <div className={classes.wrapperInput}>
                <MaterialUI.Typography color="inherit"
                                       className={classes.textContainer}
                                       noWrap={true}>
                    {fileName}
                </MaterialUI.Typography>


                <MaterialUI.Button variant="contained"
                                   size="small"
                                   style={{color: sbColors.white}}
                                   color="secondary">
                    Datei auswählen
                </MaterialUI.Button>
                <input type="file"
                       onChange={adaptFileEventToValue(onChange)}
                       onBlur={adaptFileEventToValue(onBlur)}
                       accept={extension ? extension : '*'}
                       style={{
                           fontSize: 100,
                           position: 'absolute',
                           left: 0,
                           top: 0,
                           opacity: 0
                       }}/>
            </div>
        </div>


    );
};


export const SlFileInputComponent = MaterialUI.withStyles(styles)(CustomizedFileInputs);