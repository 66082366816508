import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent,
} from '../../../../common/components/forms';
import { SLMeterLevelInput } from '../../../../common/components/forms/number-input.component';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { SlAlertComponent } from '../../../../common/components/modal';
import { IAbleseTyp, IMeterPlace } from '../../../../common/interfaces/interface';
import { Constants } from '../../../../common/utils/constants';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
    jahresverbrauchsprognoseProTag,
    numberFormatter,
    percentRange,
    stringToNumber,
    subtractStringAsNumber,
} from '../../../../common/utils/Functions';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { AbleseStatus } from '../../../../model/enums';
import { IAblesungExt } from '../../../../model/interfaces/IAblesungExt';
import { sbColors } from '../../../../theme/app.colors';

const styles = () =>
    MaterialUI.createStyles({
        itemWidth: {
            flexGrow: 0,
            maxWidth: '11%',
            flexBasis: '11%',
        },
    });

export interface IAblesungPanelProps extends WithStyles<typeof styles> {
    value: IAblesungExt;
    ablese: IAblesungExt;
    dispatch?: any;
    ZaehlwerkVKS: number;
    ZaehlwerkNKS: number;
    ableseTypeList: Array<IAbleseTyp>;
    meterPanel: IMeterPlace;
    Ablesedatum: string;
    lastReadDatum: string;
    Wandlerfaktor: number;
}

interface IState {
    showSelectedBox: boolean;
    focusedDeviceIndex: number;
    showAlert: boolean;
}

class AblesungPanel extends React.Component<IAblesungPanelProps, IState> {
    public state = { showSelectedBox: false, focusedDeviceIndex: -1, showAlert: false };
    private readonly nachKommaStelle: number;
    private vorKommaStelle: number;

    constructor(props: IAblesungPanelProps) {
        super(props);
        this.nachKommaStelle = this.props.ZaehlwerkNKS
            ? this.props.ZaehlwerkNKS
            : Constants.ZaehlwerkNKS;
        this.vorKommaStelle = this.props.ZaehlwerkVKS
            ? this.props.ZaehlwerkVKS
            : Constants.ZaehlwerkVKS;
    }

    private verbrauchRechner = (newValue, oldValue) => {
        return subtractStringAsNumber(newValue, oldValue, this.nachKommaStelle);
    };

    private upDateField = (value, field) => {
        this.props.dispatch(
            change('AblesungHinzufuegenForm', `${this.props.value}.Ablesung.${field}`, value)
        );
    };

    private validateZaehlwerkVKS = value => {
        return FormValidator.validateZaehlwerkVKS(value, this.props.ZaehlwerkVKS);
    };
    private validateZaehlwerkNKS = value => {
        return FormValidator.validateZaehlwerkNKS(value, this.props.ZaehlwerkNKS);
    };

    private saveUnplausilValue = () => {
        this.upDateField(AbleseStatus.Plausibel, 'Status');
        this.setState({ showAlert: false });
    };

    private fieldOnBlur = (event, newValue) => {
        if (newValue !== '') {
            const oldValue = this.props.ablese.lastReadHT;
            const difference = this.verbrauchRechner(newValue, oldValue);
            const { Verbrauchsprognose } = this.props.meterPanel;
            this.props.dispatch(
                change(
                    'AblesungHinzufuegenForm',
                    `${this.props.value}.Ablesung.Status`,
                    AbleseStatus.Plausibel
                )
            );

            if (Number(difference.replace(/,/g, '.')) <= 0) {
                this.setState({ showAlert: true });
                this.upDateField(AbleseStatus.Unplausibel, 'Status');
            } else if (
                this.props.Ablesedatum &&
                Verbrauchsprognose &&
                Verbrauchsprognose !== ' ' &&
                Verbrauchsprognose !== ''
            ) {
                const { lastReadDatum } = this.props.ablese;
                const forecast = jahresverbrauchsprognoseProTag(
                    germanDateToISODateFormat(lastReadDatum),
                    this.props.Ablesedatum,
                    Verbrauchsprognose
                );
                const range = percentRange(Number(forecast.replace(/,/g, '.')), '20%');
                if (
                    range.min <= Number(difference.replace(/,/g, '.')) &&
                    Number(difference.replace(/,/g, '.')) <= range.max
                ) {
                    this.upDateField(AbleseStatus.Plausibel, 'Status');
                } else {
                    this.setState({ showAlert: true });
                    this.upDateField(AbleseStatus.Unplausibel, 'Status');
                }
            } else {
                this.upDateField(AbleseStatus.Plausibel, 'Status');
            }
            this.upDateField(difference, 'VerbrauchHT');
            this.props.dispatch(
                change(
                    'AblesungHinzufuegenForm',
                    `${this.props.value}.totalVerbrauchHT`,
                    numberFormatter(stringToNumber(difference) * Number(this.props.Wandlerfaktor))
                )
            );
        } else {
            this.upDateField('', 'VerbrauchHT');
        }
    };

    private korrigierterVerbrauchOnBlur = (event, newValue) => {
        if (newValue !== '') {
            this.upDateField(AbleseStatus.Ersatzwert, 'Status');
            this.upDateField(' ', 'VerbrauchHT');
            this.upDateField(' ', 'ZaehlerstandHT');
        }
    };

    public render() {
        return (
            <MaterialUI.Grid
                container={true}
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginBottom: 20 }}
            >
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.Seriennummer`}
                        label={'Geräte-ID'}
                        component={SlTextInputComponent}
                        readOnly={true}
                        disabled={true}
                        options={{ fullWidth: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.lastReadDatum`}
                        label={'Letztes Ablesedatum'}
                        component={SlTextInputComponent}
                        readOnly={true}
                        disabled={true}
                        options={{ fullWidth: true, shrink: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.lastReadHT`}
                        label={'Letzter Stand HT'}
                        component={SlTextInputComponent}
                        readOnly={true}
                        disabled={true}
                        options={{ fullWidth: true, shrink: true }}
                    />
                </MaterialUI.Grid>

                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.Ablesung.Ablesedatum`}
                        label={'Ablesedatum'}
                        format={inputDateDisplayFormat}
                        normalize={germanDateToISODateFormat}
                        component={SlDateInputComponent}
                        required={true}
                        options={{ fullWidth: true, shrink: true }}
                        validate={[
                            FormValidator.required,
                            FormValidator.meterReadingDate,
                            FormValidator.isDateBefore(
                                germanDateToISODateFormat(this.props.lastReadDatum)
                            ),
                        ]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <SLMeterLevelInput
                        name={`${this.props.value}.Ablesung.ZaehlerstandHT`}
                        label={`Zählerstand HT (${deTranslator.einheit.kilowatt})`}
                        onBlur={this.fieldOnBlur}
                        required={false}
                        validate={[this.validateZaehlwerkVKS, this.validateZaehlwerkNKS]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.totalVerbrauchHT`}
                        label={`Verbrauch (${deTranslator.einheit.kilowatt})`}
                        component={SlTextInputComponent}
                        readOnly={true}
                        disabled={true}
                        options={{ fullWidth: true, shrink: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <SLMeterLevelInput
                        name={`${this.props.value}.Ablesung.KorrigierterVerbrauch`}
                        label={`Korrigierter Verbrauch (${deTranslator.einheit.kilowatt})`}
                        onBlur={this.korrigierterVerbrauchOnBlur}
                        required={false}
                        validate={[FormValidator.validateKorrigierterVerbrauch]}
                    />
                </MaterialUI.Grid>

                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.Ablesung.Status`}
                        component={SlSelectInputComponent}
                        style={{ color: sbColors.orange }}
                        required={true}
                        label={'Status'}
                        validate={[FormValidator.required]}
                        options={{ fullWidth: true, shrink: true }}
                    >
                        {renderItemForSelectBox(AbleseStatus)}
                    </Field>
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} className={this.props.classes.itemWidth}>
                    <Field
                        name={`${this.props.value}.Ablesung.AblesegrundId`}
                        component={SlSelectInputComponent}
                        label={'Ablesegrund'}
                        required={true}
                        style={{ color: sbColors.orange }}
                        options={{
                            fullWidth: true,
                            shrink: true,
                        }}
                        validate={[FormValidator.required]}
                    >
                        {this.props.ableseTypeList.map((item, index) => (
                            <MaterialUI.MenuItem key={`${index}-${item.name}`} value={item.id}>
                                {`${item.name} (${currencyFormatter(item.preis / 100)})`}
                            </MaterialUI.MenuItem>
                        ))}
                    </Field>
                </MaterialUI.Grid>
                <SlAlertComponent
                    open={this.state.showAlert}
                    textOkButton={'Ja'}
                    textCancelButton={'Nein'}
                    handleOK={() => this.setState({ showAlert: false })}
                    handleCancel={this.saveUnplausilValue}
                >
                    Soll der Wert als unplausibel markiert werden?
                </SlAlertComponent>
            </MaterialUI.Grid>
        );
    }
}

const selector = formValueSelector('AblesungHinzufuegenForm');

const mapStateToProps = (state, props) => {
    const ablesegrund = selector(state, `${props.value}.Ablesung.Ablesegrund`);
    const Ablesedatum = selector(state, `${props.value}.Ablesung.Ablesedatum`);
    const lastReadDatum = selector(state, `${props.value}.lastReadDatum`);
    const Wandlerfaktor = selector(state, `${props.value}.Wandlerfaktor`);
    return { ablesegrund, Ablesedatum, lastReadDatum, Wandlerfaktor };
};

export const AblesungPanelComponent = MaterialUI.withStyles(styles)(AblesungPanel);

export const SLablesungPanel = connect(mapStateToProps)(AblesungPanelComponent);
