import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent } from '../../../common/components/modal';
import { ISlTab } from '../../../common/components/tabs/sl-tabs.component';
import { GlobalActions } from '../../../common/redux';
import { IError } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../model/enums';

import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history } from '../../../store';
import { DevicesActions } from '../../devices/redux';
import { ContactPersonActions, FacilityActions } from '../../facilities/redux';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { SlimActions } from '../../slim/redux';
import { SlimKABs } from '../../slim/pages/slimKabs.page';
import { ISlimState } from '../../slim/redux/reducers/slim.reducer';
import { SLCustomerData } from '../components/customerData.component';
import { SlCustomers } from '../components/sl-customers.component';
import { CustomerActions } from '../redux';
import { ICustomerState } from '../redux/reducers/customer.reducer';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        mainContent: {},

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },
    });

export interface ICustomersViewProps extends WithStyles<typeof styles> {
    // functions from actions creators
    getCustomers: () => void;
    getCustomerById: (kabID: string) => void;
    customerState: ICustomerState;
    slimState?: ISlimState;
    loadingList: Array<string>;
    error: IError;
    billToCustomerForm?: IForm;
    contactDataForm?: IForm;
    businessPartnerForm?: IForm;
    addedCustomerId: (id: string) => void;
    setError: (error: IError) => void;
    saveDashBoardActiveTab: (tab: number) => void;
    postCustomer?: (value) => void;
    postContactData?: (value) => void;
    postBillReceiver?: (value) => void;
    deleteCustomerById?: (kabID: string) => void;
    getSlimKunden?: () => void;
}

interface IState {
    showAddCustomerModal: boolean;
    selectedTab: ISlTab;
}

class CustomersView extends React.Component<ICustomersViewProps, IState> {
    private readonly tabs: Array<ISlTab>;
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: ICustomersViewProps) {
        super(props);
        this.tabs = [
            {
                label: 'TECHNIK KUNDEN',
                key:'TECHNIK'
            },
            {
                label: 'SLIM KUNDEN',
                key:'SLIM'
            },
        ];

        this.actionBarItems = [
            {
                label: deTranslator.global.newCustomer,
                icon: <MaterialUIIcons.AddBox />,
                action: () => this.showCustomerWizard(),
            },
        ];

        this.state = {
            showAddCustomerModal: false,
            selectedTab: this.tabs[0],
        };
    }

    public async componentDidMount() {
        this.props.addedCustomerId(null);
        this.props.getCustomers();
        this.props.getSlimKunden();
    }

    private goToCustomerDetail = (link: string) => {
        history.push(link);
    };

    private handleAddCustomer = async (
        businessPartner: any,
        contactData: any,
        billToCustomer?: any
    ) => {
        this.setState({ showAddCustomerModal: false });
        await this.props.postCustomer(businessPartner);
        if (contactData) {
            await this.props.postContactData(contactData);
        }
        if (billToCustomer) {
            await this.props.postBillReceiver(billToCustomer);
        }
        history.push(`kabs/${this.props.customerState.addedCustomerID}`);
    };

    private closeCustomerWizard = () => {
        this.setState({ showAddCustomerModal: false });
    };

    private handleDismissError = () => {
        this.props.setError(null);
    };

    private showCustomerWizard = () => {
        this.setState({ showAddCustomerModal: true });
    };

    private buildTabs = () => {
        return this.tabs.map((tab: ISlTab) => {
            switch (tab.key) {
                case 'TECHNIK':
                    tab.badge = this.props.customerState.customers.length;
                    return tab;
                case 'SLIM':
                    tab.badge = this.props.slimState.slimCustomers.items.length;
                    return tab;

                default:
                    return tab;
            }
        });
    };

    private handleDeleteKab = async (id: string) => {
        await this.props.deleteCustomerById(id);
        this.props.addedCustomerId(null);
        this.props.getCustomers();
    };

    private renderAllCustomers() {
        return (
            <div>
                {isUserRole(Roles.Admin) && <SlActionBar items={this.actionBarItems} />}
                <SlCustomers
                    onCellClick={this.goToCustomerDetail}
                    addKab={this.showCustomerWizard}
                    deleteKab={this.handleDeleteKab}
                    customers={this.props.customerState.customers}
                />
            </div>
        );
    }

    private renderSlimCustomers() {
        return (
            <SlimKABs
                slimCustomers={this.props.slimState.slimCustomers.items}
                onCellClick={this.goToCustomerDetail}
            />
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader
                    tabs={this.buildTabs()}
                    selectedTab={this.props.customerState.dashboardActiveTab}
                    onTabClick={this.props.saveDashBoardActiveTab}
                />

                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.tabs[this.props.customerState.dashboardActiveTab].label ===
                        this.tabs[0].label && this.renderAllCustomers()}
                    {this.tabs[this.props.customerState.dashboardActiveTab].label ===
                        this.tabs[1].label && this.renderSlimCustomers()}
                    <SLCustomerData
                        onClose={this.closeCustomerWizard}
                        onSubmit={this.handleAddCustomer}
                        open={this.state.showAddCustomerModal}
                    />

                    <SlAlertComponent
                        open={!isNullOrUndefined(this.props.error)}
                        showCancelButton={false}
                        handleOK={this.handleDismissError}
                    >
                        {this.props.error && <ErrorComponent error={this.props.error} />}
                    </SlAlertComponent>
                </div>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        businessPartnerForm: {
            formValue: getFormValues('BusinessPartnerForm')(state),
        },
        billToCustomerForm: {
            formValue: getFormValues('BillReceiver')(state),
        },
        contactDataForm: {
            formValue: getFormValues('contactPersonForm')(state),
        },
        customerState: state.customerState,
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
        slimState: state.slimState,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            CustomerActions,
            GlobalActions,
            DevicesActions,
            FacilityActions,
            ContactPersonActions,
            MeterPanelsActions,
            SlimActions
        ),
        dispatch
    );
};
export const CustomersViewComponent = MaterialUI.withStyles(styles)(CustomersView);

export const CustomersPage = withRouter<ICustomersViewProps>(
    connect(mapStateToProps, mapDispatchToProps)(CustomersViewComponent)
);
