import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlSelectInputComponent } from '../../../../common/components/forms';
import { SLInputPriceField } from '../../../../common/components/forms/number-input.component';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IProduct } from '../../../../common/interfaces/interface';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';

const styles = () => MaterialUI.createStyles({});

export interface IAddCostComponentProps extends WithStyles<typeof styles> {
    editMode: boolean;
    defaultProduct: IProduct;
    initialize?: (customerdata: IProduct) => void;
}

class AddProduct extends React.Component<IAddCostComponentProps, {}> {
    private readonly produktInfos: Array<{ label: string, key: string }>;

    constructor(props: IAddCostComponentProps) {
        super(props);
        this.produktInfos = [{label: 'Name', key: 'name'},
            {label: 'Preis', key: 'preis'},
            {label: 'Ebene', key: 'ebene'},
            {label: 'zyklus', key: 'zyklus'}];
        if (this.props.editMode && this.props.defaultProduct) {
            this.props.initialize(this.props.defaultProduct);
        }
    }

    public renderUnit() {
        return (<MaterialUI.InputAdornment
            position="end">{deTranslator.einheit.euro}</MaterialUI.InputAdornment>);
    }

    public render() {
        return (<div style={{padding: '20px 20px 40px'}}>
            <MaterialUI.Grid container={true} spacing={10}>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field name={'name'}
                           label={'Name'}
                           type={'text'}
                           component={SlTextInputComponent}
                           placeholder={''}
                           required={true}
                           validate={[FormValidator.required, FormValidator.maxLength50]}
                           options={{fullWidth: true, shrink: true}}
                    />
                </MaterialUI.Grid>
            </MaterialUI.Grid>

            <MaterialUI.Grid container={true} spacing={10}>
                <MaterialUI.Grid item={true} key={'preis'} xs={4}>
                    <SLInputPriceField name={'preis'}
                                       required={true}
                                       validate={[FormValidator.required]}
                                       label={'Preis'}/>
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field component={SlSelectInputComponent}
                           required={true}
                           label={'Ebene'}
                           name={'ebene'}
                           validate={[FormValidator.required]}
                           options={{fullWidth: true, shrink: true}}>
                        <MaterialUI.MenuItem
                            value={'Anlage'}>
                            {'Anlage'}
                        </MaterialUI.MenuItem>
                        <MaterialUI.MenuItem
                            value={'Gerät'}>
                            {'Gerät'}
                        </MaterialUI.MenuItem>
                    </Field>
                </MaterialUI.Grid>

                <MaterialUI.Grid item={true} xs={4}>
                    <Field component={SlSelectInputComponent}
                           required={true}
                           label={'Zyklus'}
                           name={'zyklus'}
                           validate={[FormValidator.required]}
                           options={{fullWidth: true, shrink: true}}>
                        <MaterialUI.MenuItem
                            value={'einmalig'}>
                            {'Einmalig'}
                        </MaterialUI.MenuItem>
                        <MaterialUI.MenuItem
                            value={'wiederkehrend'}>
                            {'Wiederkehrend'}
                        </MaterialUI.MenuItem>
                    </Field>
                </MaterialUI.Grid>
            </MaterialUI.Grid>

        </div>);
    }
}

export const AddProductComponent = MaterialUI.withStyles(styles)(AddProduct);
export const SlAddProduct = reduxForm({
    form: 'SlAddProductForm',
})(AddProductComponent);