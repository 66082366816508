import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import uuid from 'uuid';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { currencyFormatter, IsoformatToGermanDate } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';
import { titleStyles } from '../../../theme/common.styles';
import { SlimGeraetType } from '../interfaces/slim.interface';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        infoPaper: {
            minWidth: '90%',
            padding: 20,
            margin: 'auto',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            width: '100%',
            overflow: 'hidden',
        },

        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
    });

export interface IDeviceInfoProps extends WithStyles<typeof styles> {
    device: SlimGeraetType;
}

class SlimDeviceInfo extends React.Component<IDeviceInfoProps, {}> {
    private readonly deviceInfo = [
        { label: 'Geräte-ID', key: 'Seriennummer' },
        { label: 'Ablauf Eichgültigkeit', key: 'Eichdatum' },
        { label: 'Geräte-PIN', key: 'GeraetePIN' },
        { label: deTranslator.device.ZaehlwerkVKS, key: 'ZaehlwerkVKS' },
        { label: deTranslator.device.ZaehlwerkNKS, key: 'ZaehlwerkNKS' },
        { label: 'Obis Kennziffer HT', key: 'ObisCodeHT' },
        { label: 'Wandler-ID1', key: 'WandlerID1' },
        { label: 'Wandler-ID2', key: 'WandlerID2' },
        { label: 'Wandler-ID3', key: 'WandlerID3' },
        { label: 'Wandlerfaktor', key: 'Wandlerfaktor' },
        { label: 'Zählerversanddatum', key: 'Zaehlerversanddatum' },
    ];

    public renderItem = item => {
        switch (item.key) {
            case 'Zaehlerversanddatum':
                return (
                    <MaterialUI.Typography
                        noWrap={true}
                        key={uuid.v4()}
                        color={'inherit'}
                        className={`${this.props.classes.input}`}
                    >
                        {IsoformatToGermanDate(this.props.device[item.key])}
                    </MaterialUI.Typography>
                );
            default:
                return (
                    <MaterialUI.Typography
                        noWrap={true}
                        key={uuid.v4()}
                        color={'inherit'}
                        className={`${this.props.classes.input}`}
                    >
                        {this.props.device[item.key]}
                    </MaterialUI.Typography>
                );
        }
    };

    public render() {
        if (!this.props.device) {
            return null;
        }
        return (
            <MaterialUI.Paper elevation={2} className={this.props.classes.infoPaper}>
                <MaterialUI.Typography
                    variant={'h5'}
                    color={'inherit'}
                    className={`${this.props.classes.label} `}
                >
                    Informationen Gerät
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ padding: 20 }}>
                    <MaterialUI.Grid container={true} direction={'row'} spacing={5}>
                        {this.deviceInfo.map(item => (
                            <MaterialUI.Grid
                                item={true}
                                xs={3}
                                key={item.key}
                                style={{ paddingBottom: 0 }}
                            >
                                <MaterialUI.Typography
                                    variant={'body2'}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    className={`${this.props.classes.label}`}
                                >
                                    {item.label}
                                </MaterialUI.Typography>

                                {this.renderItem(item)}
                            </MaterialUI.Grid>
                        ))}
                        <MaterialUI.Grid item={true} xs={true} style={{ paddingBottom: 0 }}>
                            <MaterialUI.Typography
                                variant={'body2'}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                className={`${this.props.classes.label}`}
                            >
                                Produkte
                            </MaterialUI.Typography>

                            {this.props.device.Produkts &&
                                this.props.device.Produkts.map(product => (
                                    <MaterialUI.Typography
                                        noWrap={true}
                                        key={uuid.v4()}
                                        color={'inherit'}
                                        className={`${this.props.classes.input}`}
                                    >
                                        {product['name']} (
                                        {currencyFormatter(product['preis'] / 100)})
                                    </MaterialUI.Typography>
                                ))}
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </div>
            </MaterialUI.Paper>
        );
    }
}

export const SlimDeviceDetails = MaterialUI.withStyles(styles)(SlimDeviceInfo);
