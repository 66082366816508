
import { IBillRecipient } from '../../../../common/interfaces/interface';
import { CustomerActionTypes } from '../types/customer.action.types';

export const billLoadingStatus = flag => {
    return dispatch => dispatch({
        type: CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING,
        payload: flag
    });
};


export const saveBillToCustmer = (bill: IBillRecipient) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_SELECTED_DEVIATINGBILLRECIPIENT,
        payload: bill
    });
};






