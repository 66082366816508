import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { SLMeterPanelComponent } from './meterPanel.component';

const styles = () =>
    MaterialUI.createStyles({
        main: {
            position: 'absolute' as 'absolute',
            top: '23%',
            right: '30',
        },
        labels: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            //  padding: '20px'
        },
    });

export interface IMeterPanelContainerProps extends WithStyles<typeof styles> {
    initialize: (devices) => void;
}

class MeterPanelContainer extends React.Component<IMeterPanelContainerProps> {
    constructor(props: any) {
        super(props);
        this.props.initialize({
            Zaehlplaetze: Array(1)
                .fill(0)
                .map(() => this.getDefaultProperty()),
        });
    }

    private getDefaultProperty = () => {
        return {
            WohnungNr: '',
            Gebaeude: '',
            Stockwerk: '',
            Lage: '',
            GeraetTyp: 'MME60A',
        };
    };

    private onActionButtonRemoveHandler = (fields, index) => {
        if (this.fields.length > 1) {
            return fields.remove(index);
        }
    };

    private fields: any;
    private renderContent = ({ fields }) => {
        this.fields = fields;
        return fields.map((device, index) => {
            return (
                <SLMeterPanelComponent
                    key={'MeterPanel_' + index}
                    onKeyDownPressAction={() =>
                        this.fields.insert(this.fields.length, this.getDefaultProperty())
                    }
                    onClickActionButton={() => this.onActionButtonRemoveHandler(fields, index)}
                    value={device}
                    index={index}
                />
            );
        });
    };

    public render() {
        return (
            <div style={{ marginTop: 24 }}>
                <div>
                    <div>
                        <FieldArray name={'Zaehlplaetze'} component={this.renderContent} />
                    </div>
                    <div style={{ float: 'right' }}>
                        <MaterialUI.IconButton
                            onClick={e => {
                                this.fields.insert(this.fields.length, this.getDefaultProperty());
                                e.stopPropagation();
                            }}
                        >
                            <MaterialUIIcons.AddBox />
                        </MaterialUI.IconButton>
                    </div>

                    <MaterialUI.Grid item={true} xs={12} style={{ marginBottom: '20px' }}>
                        <MaterialUI.Typography style={{ paddingTop: '35px' }} variant="body1">
                            *Pflichtfelder
                        </MaterialUI.Typography>
                    </MaterialUI.Grid>
                </div>
            </div>
        );
    }
}

export const MeterPanelContainerComponent = MaterialUI.withStyles(styles)(MeterPanelContainer);

export const SLMeterPanelContainerComponent = reduxForm({
    form: 'MeterPanelForm',
})(MeterPanelContainerComponent);
