import { ISlTab } from '../../../../common/components/tabs/sl-tabs.component';
import { FacilityActionTypes } from '../types/facility.action.types';


export const facilityLoadingStatus = flag => {
    return dispatch => dispatch({
        type: FacilityActionTypes.FACILITY_IS_LOADING,
        payload: flag
    });
};

export const postFacilityLoadingStatus = (flag) => {
    return dispatch => dispatch({
        type: FacilityActionTypes.POST_FACILITY_LOADING_STATUS,
        payload: flag
    });
};

export const saveSelectedFacility = facility => {
    return dispatch => dispatch({
        type: FacilityActionTypes.SAVE_SELECTED_FACILITY,
        payload: facility
    });
};


export const saveFacilities = (facilities) => {
    return dispatch => dispatch({
        type: FacilityActionTypes.SAVE_FACILITIES,
        payload: facilities
    });
};


export const saveFacilityEmail = (email: any) => {
    return dispatch => dispatch({
        type: FacilityActionTypes.SAVE_FACILITY_EMAIL,
        payload: email
    });
};

export const saveActiveTab = (tab: ISlTab) => {
    return dispatch => dispatch({
        type: FacilityActionTypes.SAVE_ACTIVE_TAB,
        payload: tab
    });
};












