import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';

const styles = {
    disabledTextField: {
        backgroundColor: '#E2E2E2',
    },
    activeTextField: {
        backgroundColor: '#fff',
    },
};

const CustomizedTextInput = inputProps => {
    const {
        input,
        label,
        type,
        options,
        multiline,
        maxLength,
        placeholder,
        rows,
        readOnly,
        disabled,
        required,
        style,
        endAdornment,
        meta: { touched, error, warning },
    } = inputProps;

    // endAdornment: options && options.showInfoIcon && <TooltipButtonComponent text={options.infoText}/>
    return (
        <MaterialUI.TextField
            label={label}
            variant={'outlined'}
            className={
                disabled ? inputProps.classes.disabledTextField : inputProps.classes.activeTextField
            }
            type={type || 'text'}
            style={{ ...style }}
            placeholder={placeholder}
            {...input}
            error={touched && error ? true : false}
            multiline={multiline ? multiline : false}
            maxLength={maxLength}
            disabled={disabled ? disabled : false}
            rows={rows ? rows : 1}
            helperText={
                touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span style={{ color: sbColors.orange }}>{warning}</span>))
            }
            InputProps={{
                readOnly: readOnly ? readOnly : false,
                classes: {},
                endAdornment: endAdornment && endAdornment,
            }}
            InputLabelProps={{
                shrink: options && options.shrink ? options.shrink : true,
            }}
            fullWidth={options && options.fullWidth}
            required={required}
        />
    );
};

export const SlTextInputComponent = withStyles(styles)(CustomizedTextInput);
