import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../common/components/actionbar';
import { ISLActionBar } from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { SlSnackbarComponent } from '../../../common/components/snackbar/sl-snackbar.component';
import { ISlTab } from '../../../common/components/tabs/sl-tabs.component';
import { IProduct } from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError, ISnackBar } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined, isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { DokumentKategorie } from '../../../model/constants';
import { Roles } from '../../../model/enums';
import { IReduxState } from '../../../model/redux-state';
import {
    facilityStyles,
    IKabFacilityDetailsPageProps,
} from '../../facilities/pages/facility-data.page';
import { SettingsActions } from '../../settings/redux';
import { SlimAblesung } from '../components/slimAblesung';
import { SlimGeraete } from '../components/slimDevices';
import { SlimDokumente } from '../components/slimDokumente.component';
import { SlimKA } from '../components/slimKA';
import { SlimKAKontaktperson } from '../components/slimKAKontaktperson';
import { SlimMeterpanels } from '../components/slimMeterpanels';
import { AddProductForm } from '../forms/addProduct.form';
import { SlimKabAnlageType } from '../interfaces/slim.interface';
import { SlimActions } from '../redux';
import { ISlimState } from '../redux/reducers/slim.reducer';
import { ErrorComponent } from '../../../common/components/error';
import { SlimSensorspanels } from './slimSensors.page';
import { updateSlimAnlage } from '../slim-api/kundenanlage-api/kundenanlage.api';

export const slimFacilityStyles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(3),
        },
        tabContainer: {
            height: '100vh',
            // backgroundColor: sbColors.white,
        },
        tabContent: {
            height: 'calc(100vh - 165px)',
            padding: theme.spacing(3),
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },
    });
export interface ISlimFacilityProps extends WithStyles<typeof slimFacilityStyles> {
    match: any;
    location: any;
    slimState: ISlimState;
    snackbarStatus?: ISnackBar;
    productList?: IProduct[];
    fetchSlimKundenAnlagenByAID: (AID: string) => void;
    fetchSlimKAKontaktperson: (AID: string) => void;
    saveAnlageActiveTab: (tab: number) => void;
    fetchSlimKAZaehlerplatz: (AID: string) => void;
    fetchSlimKundeById: (id: string) => void;
    getProductList?: () => void;
    getAbleseTypeList?: () => void;
    getDeviceTypeList?: () => void;
    loadingList: Array<string>;
    getSlimDevices?: (AID: string) => void;
    setSnackbarStatus?: (item: ISnackBar) => void;
    updateSlimKundenAnlage?: (data: SlimKabAnlageType) => void;
    setError: (error: IError) => void;
    error: IError;
    fetchSlimSensorByKundenanlageId: (id: string) => void;
}
interface IState {
    cardElevation: number;
    selectedTab: ISlTab;
    openFacilityModal: boolean;
    selectedProducts: IProduct[];
}

class SLimFacilityDetails extends React.Component<ISlimFacilityProps, IState> {
    private readonly tabs: Array<ISlTab>;
    private kabID: string = null;
    private aID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;

    constructor(props: ISlimFacilityProps) {
        super(props);
        this.kabID = decodeURIComponent(props.match.params.kabID);
        this.aID = decodeURIComponent(props.match.params.aID);

        this.tabs = [
            {
                label: deTranslator.facility.masterData,
            },
            {
                label: deTranslator.global.contact,
            },
            {
                label: deTranslator.meterPanel.title,
            },
            {
                label: deTranslator.device.title,
            },
            {
                label: 'SENSOREN',
            },
            {
                label: deTranslator.device.reading,
            },
            {
                label: deTranslator.global.archiv,
            },
        ];

        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                disable: false,
                action: () => this.setState({ ...this.state, openFacilityModal: true }),
            },
        ];

        this.state = {
            selectedTab: this.tabs[0],
            cardElevation: 1,
            openFacilityModal: false,
            selectedProducts: [],
        };
    }

    public handleUpdateFacility = async () => {
        const { selectedSlimKundenAnlage } = this.props.slimState;
        this.setState({ ...this.state, openFacilityModal: false });
        // const payload = { ...selectedSlimKundenAnlage, Produkts: [...this.state.selectedProducts] };

        await updateSlimAnlage({
            id: selectedSlimKundenAnlage.id,
            Produkts: [...this.state.selectedProducts],
        });
        //  await this.props.updateSlimKundenAnlage(payload);
        // this.setState({ ...this.state, selectedProducts: [] });
        this.props.fetchSlimKundenAnlagenByAID(this.aID);
    };

    private buildTabs = () => {
        const {
            selectedSlimKundenAnlage,
            slimzaehlerplatzList,
            slimSensors,
        } = this.props.slimState;
        // return this.tabs;
        return this.tabs.map((tab: ISlTab) => {
            switch (tab.label) {
                case deTranslator.global.contact:
                    tab.badge = selectedSlimKundenAnlage
                        ? selectedSlimKundenAnlage.kontaktpersonList.items.length
                        : null;
                    return tab;
                case deTranslator.meterPanel.title:
                    tab.badge = selectedSlimKundenAnlage ? slimzaehlerplatzList.items.length : null;
                    return tab;
                case deTranslator.device.title:
                    /*this.allDevices = this.props.deviceState.devices.filter(
                        item => (item.Ausbaudatum && item.Ausbaudatum === '') || !item.Ausbaudatum
                    );*/
                    tab.badge = this.props.slimState.slimGeraete.items.length;
                    return tab;
                case deTranslator.global.archiv:
                    if (selectedSlimKundenAnlage && selectedSlimKundenAnlage.dokumenteList) {
                        const documents = selectedSlimKundenAnlage.dokumenteList.items.filter(
                            doc =>
                                DokumentKategorie.map(item => item.value).indexOf(
                                    doc.Klassifizierung
                                ) > -1
                        );

                        tab.badge = documents.length;
                    } else {
                        tab.badge = null;
                    }
                    return tab;
                case deTranslator.device.reading:
                    tab.badge = this.props.slimState.slimGeraete.items.length;
                    return tab;
                case 'SENSOREN':
                    tab.badge = slimSensors.items.length;
                    return tab;
                default:
                    return tab;
            }
        });
    };

    private handleOndevicesChange = () => {
        this.props.getSlimDevices(this.aID);
    };

    private initData() {
        this.props.fetchSlimKundeById(this.kabID);
        this.props.fetchSlimKundenAnlagenByAID(this.aID);
        this.props.fetchSlimKAKontaktperson(this.aID);
        this.props.fetchSlimKAZaehlerplatz(this.aID);
        this.props.getProductList();
        this.props.getDeviceTypeList();
        this.props.getSlimDevices(this.aID);
    }

    public componentDidMount() {
        // fetch bodyData from server
        this.initData();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.slimState && this.props.slimState.selectedSlimKundenAnlage) {
            const { selectedSlimKundenAnlage } = this.props.slimState;

            if (!prevProps.slimState.selectedSlimKundenAnlage) {
                this.props.fetchSlimSensorByKundenanlageId(selectedSlimKundenAnlage.id);
            } else if (
                prevProps.slimState.selectedSlimKundenAnlage &&
                prevProps.slimState.selectedSlimKundenAnlage.id !== selectedSlimKundenAnlage.id
            ) {
                this.props.fetchSlimSensorByKundenanlageId(selectedSlimKundenAnlage.id);
            }
        }

        if (prevProps.match.url !== this.props.match.url) {
            this.kabID = decodeURIComponent(this.props.match.params.kabID);
            this.aID = decodeURIComponent(this.props.match.params.aID);
            this.initData();
        }
    }

    private handleDismissError = () => {
        this.props.setError(null);
    };

    public render() {
        const {
            selectedSlimKundenAnlage,
            anlageActiveTab,
            AnlageKontaktperson,
            slimzaehlerplatzList,
        } = this.props.slimState;

        return (
            <div className={this.props.classes.root}>
                <SlHeader
                    currentLocation={null}
                    selectedTab={anlageActiveTab}
                    tabs={this.buildTabs()}
                    onTabClick={this.props.saveAnlageActiveTab}
                />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.tabs[anlageActiveTab].label === this.tabs[0].label && (
                        <div>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <SlActionBar items={this.masterDataActionBarItems} />
                            )}
                            <SlimKA facility={selectedSlimKundenAnlage} />
                        </div>
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[1].label && (
                        <SlimKAKontaktperson AnlageKontaktperson={AnlageKontaktperson.items} />
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[2].label && (
                        <SlimMeterpanels
                            kabId={this.kabID}
                            aId={this.aID}
                            selectedSlimKundenAnlage={selectedSlimKundenAnlage}
                            AnlageKontaktperson={AnlageKontaktperson.items}
                            zaehlerplatzList={slimzaehlerplatzList.items}
                        />
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[3].label && (
                        <SlimGeraete
                            kabId={this.kabID}
                            aId={this.aID}
                            selectedSlimKundenAnlage={selectedSlimKundenAnlage}
                            zaehlerplatzList={slimzaehlerplatzList.items}
                            onDevicesChange={this.handleOndevicesChange}
                        />
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[4].label && (
                        <SlimSensorspanels
                            kabId={this.kabID}
                            aId={this.aID}
                            selectedSlimKundenAnlage={selectedSlimKundenAnlage}
                        />
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[5].label && (
                        <SlimAblesung
                            kabId={this.kabID}
                            aId={this.aID}
                            zaehlerplatz={slimzaehlerplatzList.items}
                        />
                    )}
                    {this.tabs[anlageActiveTab].label === this.tabs[6].label && (
                        <SlimDokumente
                            kabId={this.kabID}
                            aId={this.aID}
                            dokumente={
                                selectedSlimKundenAnlage.dokumenteList
                                    ? selectedSlimKundenAnlage.dokumenteList.items
                                    : []
                            }
                        />
                    )}
                </div>
                {this.props.snackbarStatus && (
                    <SlSnackbarComponent
                        open={this.props.snackbarStatus.flag}
                        text={this.props.snackbarStatus.text}
                        type={this.props.snackbarStatus.type}
                        handleClose={() => this.props.setSnackbarStatus(null)}
                    />
                )}

                <SLModal
                    title={'Anlage Produkte hinzufügen'}
                    onClose={() => this.setState({ ...this.state, openFacilityModal: false })}
                    hideClose={true}
                    open={this.state.openFacilityModal}
                    onOkButtonClick={this.handleUpdateFacility}
                    OkButtonDisabled={false}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <AddProductForm
                        productList={this.props.productList}
                        selectFacility={selectedSlimKundenAnlage}
                        onSelect={items =>
                            this.setState({
                                ...this.state,
                                selectedProducts: items.map(item =>
                                    this.props.productList.find(product => product.id === item)
                                ),
                            })
                        }
                    />
                </SLModal>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDismissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        slimState: state.slimState,
        loadingList: state.globalState.loadingList,
        snackbarStatus: state.globalState.snackbarStatus,
        productList: state.settingState.productManagementList,
        error: state.globalState.error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, SlimActions, GlobalActions, SettingsActions),
        dispatch
    );
};

export const SLimFacilityComponent = MaterialUI.withStyles(facilityStyles)(SLimFacilityDetails);

export const SLimFacilityPage = withRouter(
    connect<IKabFacilityDetailsPageProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<IKabFacilityDetailsPageProps>(SLimFacilityComponent)
);
