import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

export interface ILoadingProps {
    size?: number;
    containerStyle?: any;

}

export class LoadingComponent extends React.Component<ILoadingProps, {}> {
    public static defaultProps: Partial<ILoadingProps> = {
        size: 60,
    };

    public render() {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                position: 'absolute',
                zIndex: 3000,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                top: 0,
                width: '100%',
                opacity: 1
            }}>
                <MaterialUI.CircularProgress size={this.props.size} style={{color: '#ff9800'}} thickness={7}/>

            </div>
        );
    }
}