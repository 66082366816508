import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';
import uuid from 'uuid';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { DeleteButton } from '../../../../common/components/buttons';
import { SlDateInputComponent, SlSelectInputComponent } from '../../../../common/components/forms';
import {
    SLMeterLevelInput,
    SlNumberInputComponent,
} from '../../../../common/components/forms/number-input.component';
import { SlMultipleSelectInputComponent } from '../../../../common/components/forms/select-input.component';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IDeviceType, IProduct } from '../../../../common/interfaces/interface';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
} from '../../../../common/utils/Functions';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { GeraetTypKey } from '../../../../model/enums';
import { store } from '../../../../store';

const selector = formValueSelector('AppendDeviceForm');

const styles = () =>
    MaterialUI.createStyles({
        container: {
            display: 'flex' as 'flex',
            justifyContent: 'space-between',
        },
        item: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
        },
        dropdown: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
            marginTop: -5,
        },
        actionButton: {
            maxWidth: '4%',
            flexBasis: '4%',
            marginTop: 25,
            marginLeft: 25,
        },
    });

interface IAppendDeviceEntry {
    GeraeteID: string;
    Grundtyp: IDeviceType;
    Einbaudatum: string;
    Eichdatum: Date;
    EinbaustandHT: string;
    ObisCodeHT: string;
    EinbaustandNT: string;
    ObisCodeNT: string;
    ZaehlwerkNKS: number;
    ZaehlwerkVKS: number;
    ProduktIds: Array<string>;
    GrundtypId: string;
    Type: string;
}

export interface IAppendDevicePanelProps extends WithStyles<typeof styles> {
    onClickActionButton: (index) => void;
    value: IAppendDeviceEntry;
    deviceTypeList: Array<IDeviceType>;
    productList?: Array<IProduct>;
    index: string;
    dispatch?: any;
    zaehlwerkVKS?: number;
    zaehlwerkNKS?: number;
    GeraetTyp: GeraetTypKey;
}

interface IState {
    selectedProducts: Array<string>;
    selectedDeviceTypes: string;
}

class AppendDevicePanel extends React.Component<IAppendDevicePanelProps, IState> {
    constructor(props: IAppendDevicePanelProps) {
        super(props);
        this.state = { selectedProducts: [], selectedDeviceTypes: '' };
    }

    public static defaultProps: Partial<IAppendDevicePanelProps> = {
        value: {
            GeraeteID: '',
            Grundtyp: null,
            Einbaudatum: '',
            Eichdatum: new Date(),
            EinbaustandHT: '',
            ObisCodeHT: '',
            EinbaustandNT: '',
            ObisCodeNT: '',
            ProduktIds: [],
            ZaehlwerkNKS: 0,
            ZaehlwerkVKS: 6,
            GrundtypId: '',
            Type: 'first',
        },
    };

    private validateZaehlwerkVKS = value => {
        return FormValidator.validateZaehlwerkVKS(value, this.props.zaehlwerkVKS);
    };
    private validateZaehlwerkNKS = value => {
        return FormValidator.validateZaehlwerkNKS(value, this.props.zaehlwerkNKS);
    };

    public renderAddButton() {
        const titleAdd = 'Zeile für zusätzliches Gerät hinzufügen';
        const titleRemove = 'Zeile für das Gerät entfernen';
        return this.props['type'] === 'first' ? (
            <div>
                <MaterialUI.Tooltip id="tooltip-icon" title={titleAdd}>
                    <MaterialUI.IconButton
                        onClick={() => {
                            this.props.onClickActionButton(this.props.index);
                        }}
                    >
                        <MaterialUIIcons.AddBox />
                    </MaterialUI.IconButton>
                </MaterialUI.Tooltip>
            </div>
        ) : (
            <div>
                <MaterialUI.Tooltip id="tooltip-icon" title={titleRemove}>
                    <DeleteButton
                        onClick={() => {
                            this.props.onClickActionButton(this.props.index);
                        }}
                    />
                </MaterialUI.Tooltip>
            </div>
        );
    }

    public renderItemForSelectBox(enums) {
        return Object.keys(enums)
            .filter(enumKey => enumKey !== 'None')
            .map(value => (
                <MaterialUI.MenuItem key={uuid.v4()} value={enums[value]}>
                    {enums[value]}
                </MaterialUI.MenuItem>
            ));
    }

    private onChangeDeviceType = event => {
        this.setState({ selectedDeviceTypes: event.target.value });
    };
    private onChangeProducts = event => {
        this.setState({ selectedProducts: event.target.value });
    };

    public hasRowValue = (): boolean => {
        return (
            this.props['grundtypId'] ||
            this.props['geraeteID'] ||
            this.props['einbaudatum'] ||
            this.props['eichdatum'] ||
            this.props['zaehlwerkVKS'] ||
            this.props['zaehlwerkNKS'] ||
            this.props['einbaustandHT'] ||
            this.props['obisCodeHT'] ||
            this.props['einbaustandNT'] ||
            this.props['obisCodeNT'] ||
            this.props['produktIds'].length > 0
        );
    };
    public renderWandlerFeld = () => {
        if (this.props.GeraetTyp === GeraetTypKey.Wandlerzaehler250A) {
            store.dispatch(
                change('AppendDeviceForm', `${this.props.value}.device.Wandlerfaktor`, 50)
            );
            return (
                <>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.WandlerID1`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 1'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.WandlerID2`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 2'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.WandlerID3`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 3'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <SlNumberInputComponent
                            name={`${this.props.value}.device.Wandlerfaktor`}
                            label={'Wandlerfaktor'}
                            required={false}
                        />
                    </MaterialUI.Grid>
                </>
            );
        }
        return null;
    };

    public render() {
        return (
            <div style={{ display: 'flex' }}>
                <MaterialUI.Grid
                    container={true}
                    style={{ marginBottom: 20, marginTop: 20, width: '96%' }}
                    direction={'row'}
                    spacing={1}
                >
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.GeraeteID`}
                            component={SlTextInputComponent}
                            label={'GeraeteID'}
                            required={true}
                            disabled={false}
                            options={{ fullWidth: true }}
                            validate={this.hasRowValue() ? [FormValidator.required] : []}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlSelectInputComponent}
                            label={'Grundtyp'}
                            options={{ fullWidth: true }}
                            name={`${this.props.value}.device.GrundtypId`}
                            required={true}
                            onChange={this.onChangeDeviceType}
                            validate={this.hasRowValue() ? [FormValidator.required] : []}
                        >
                            {this.props.deviceTypeList.map((deviceType, index) => (
                                <MaterialUI.MenuItem
                                    key={`${index}-${deviceType.id}`}
                                    value={deviceType.id}
                                >
                                    <MaterialUI.ListItemText primary={deviceType.grundTyp} />
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>

                    {this.renderWandlerFeld()}

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.Einbaudatum`}
                            component={SlDateInputComponent}
                            options={{ fullWidth: true }}
                            label={'Einbaudatum'}
                            required={true}
                            disabled={false}
                            type={'text'}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            validate={
                                this.hasRowValue()
                                    ? [FormValidator.required, FormValidator.isValidDate]
                                    : []
                            }
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.Eichdatum`}
                            component={SlTextInputComponent}
                            options={{ fullWidth: true }}
                            label={'Eichgültigkeit'}
                            required={true}
                            placeholder={'JJJJ'}
                            disabled={false}
                            validate={
                                this.hasRowValue()
                                    ? [FormValidator.required, FormValidator.expirationDate]
                                    : []
                            }
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlSelectInputComponent}
                            options={{ fullWidth: true }}
                            label={deTranslator.device.ZaehlwerkVKS}
                            name={`${this.props.value}.device.ZaehlwerkVKS`}
                            required={true}
                            validate={this.hasRowValue() ? [FormValidator.required] : []}
                        >
                            {Array.from(Array(9).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index + 1}>
                                    {index + 1}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlSelectInputComponent}
                            options={{ fullWidth: true }}
                            label={deTranslator.device.ZaehlwerkNKS}
                            required={true}
                            name={`${this.props.value}.device.ZaehlwerkNKS`}
                            validate={this.hasRowValue() ? [FormValidator.required] : []}
                        >
                            {Array.from(Array(4).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index}>
                                    {index.toString()}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlMultipleSelectInputComponent}
                            onChange={this.onChangeProducts}
                            label={'Produktauswahl'}
                            name={`${this.props.value}.device.ProduktIds`}
                            required={true}
                            options={{ fullWidth: true }}
                            validate={this.hasRowValue() ? [FormValidator.requiredArray] : []}
                        >
                            {this.props.productList
                                .filter(item => item.ebene === 'Gerät')
                                .map((product, index) => (
                                    <MaterialUI.MenuItem
                                        key={`${index}-${product.id}`}
                                        value={product.id}
                                    >
                                        <MaterialUI.Checkbox
                                            checked={
                                                this.state.selectedProducts.indexOf(product.id) > -1
                                            }
                                        />
                                        <MaterialUI.ListItemText
                                            primary={`${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`}
                                        />
                                    </MaterialUI.MenuItem>
                                ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.GeraetePIN`}
                            component={SlTextInputComponent}
                            label={'Geräte-PIN'}
                            type={'number'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.devicePin]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <SLMeterLevelInput
                            name={`${this.props.value}.device.EinbaustandHT`}
                            label={`EinbaustandHT (${deTranslator.einheit.kilowatt})`}
                            placeholder={``}
                            required={false}
                            validate={
                                this.hasRowValue()
                                    ? [this.validateZaehlwerkVKS, this.validateZaehlwerkNKS]
                                    : []
                            }
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.ObisCodeHT`}
                            options={{ fullWidth: true }}
                            component={SlTextInputComponent}
                            label={'ObisCodeHT'}
                            validate={[FormValidator.obisCode]}
                        />
                    </MaterialUI.Grid>
                    {/* <MaterialUI.Grid item={true} xs={true}>
                        <SLMeterLevelInput
                            name={`${this.props.value}.device.EinbaustandNT`}
                            label={`EinbaustandNT (${deTranslator.einheit.kilowatt})`}
                            required={false}
                            placeholder={`5000 (${deTranslator.einheit.kilowatt})`}
                            validate={
                                this.hasRowValue()
                                    ? [this.validateZaehlwerkVKS, this.validateZaehlwerkNKS]
                                    : []
                            }
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.device.ObisCodeNT`}
                            component={SlTextInputComponent}
                            label={'ObisCodeNT'}
                            option={{ fullWidth: true }}
                            validate={[FormValidator.obisCode]}
                        />
                    </MaterialUI.Grid> */}
                </MaterialUI.Grid>

                <div className={this.props.classes.actionButton}>{this.renderAddButton()}</div>
            </div>
        );
    }
}

export const AppendDevicePanelComponent = MaterialUI.withStyles(styles)(AppendDevicePanel);
export const SlAppendDevicePanelComponent = connect((state, props) => {
    const grundtypId = selector(state, `${props.value}.device.GrundtypId`);
    const geraeteID = selector(state, `${props.value}.device.GeraeteID`);
    const einbaudatum = selector(state, `${props.value}.device.Einbaudatum`);
    const eichdatum = selector(state, `${props.value}.device.Eichdatum`);

    const zaehlwerkVKS = selector(state, `${props.value}.device.ZaehlwerkVKS`);
    const zaehlwerkNKS = selector(state, `${props.value}.device.ZaehlwerkNKS`);
    const einbaustandHT = selector(state, `${props.value}.device.EinbaustandHT`);
    const obisCodeHT = selector(state, `${props.value}.device.ObisCodeHT`);
    const einbaustandNT = selector(state, `${props.value}.device.EinbaustandNT`);
    const obisCodeNT = selector(state, `${props.value}.device.ObisCodeNT`);
    const produktIds = selector(state, `${props.value}.device.ProduktIds`);
    const type = selector(state, `${props.value}.Type`);

    return {
        type,
        grundtypId,
        geraeteID,
        einbaudatum,
        eichdatum,
        zaehlwerkVKS,
        zaehlwerkNKS,
        einbaustandHT,
        obisCodeHT,
        einbaustandNT,
        obisCodeNT,
        produktIds,
    };
})(AppendDevicePanelComponent);
