import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { IDeviceType, IMeterPlace, IProduct } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { SlAppendDevicePanelComponent } from './appendDevicePanel.component';

const styles = () => {
    return MaterialUI.createStyles({
        main: {
            position: 'absolute' as 'absolute',
            top: '23%',
            right: '30',
        },
        labels: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop: 20,
            marginRight: 70,
        },
        item: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
        },
    });
};

export interface IAppendDeviceContainerProps extends WithStyles<typeof styles> {
    meterPanels: Array<IMeterPlace>;
    deviceTypeList: Array<IDeviceType>;
    productList: Array<IProduct>;
    initialize: (devices) => void;
    currentFormDevices?: any;
    showAddButton?: boolean;
}

class AppendDeviceContainer extends React.Component<IAppendDeviceContainerProps> {
    constructor(props: IAppendDeviceContainerProps) {
        super(props);
        const defaultItems = this.props.meterPanels.map((meterpanel: IMeterPlace) => {
            // console.log('meterpanel: ', meterpanel);
            return {
                WohnungNr: meterpanel.WohnungNr,
                Gebaeude: meterpanel.Gebaeude,
                ID: meterpanel.ID,
                GeraetTyp: meterpanel.GeraetTyp,
                device: {
                    ZID: meterpanel.ID,
                    GeraeteID: '',
                    GrundtypId: '',
                    Einbaudatum: '',
                    Eichdatum: '',
                    ZaehlwerkNKS: 0,
                    ZaehlwerkVKS: 6,
                    ProduktIds: [],
                },
                Type: 'first',
            };
        });
        if (this.props.currentFormDevices) {
            this.props.initialize({ devices: this.props.currentFormDevices.devices });
        } else {
            this.props.initialize({ devices: defaultItems });
        }
    }

    private getDefaultProperty = () => {
        return {
            WohnungNr: '',
            ID: '',
            device: {
                GeraeteID: '',
                Einbaudatum: null,
                Eichdatum: null,
                ZID: '',
                ZaehlwerkNKS: 0,
                ZaehlwerkVKS: 6,
                ProduktIds: [],
                GrundtypId: null,
            },
            Type: 'additional',
        };
    };

    private getNewEntry = (meterPanel: IMeterPlace) => {
        const newEntry = this.getDefaultProperty();
        newEntry.ID = meterPanel.ID;
        newEntry.WohnungNr = meterPanel.WohnungNr;
        newEntry.device.ZID = meterPanel.ID;
        newEntry.Type = 'additional';
        return newEntry;
    };

    private onActionButtonClickHandler = (fields, index, device) => {
        if (device.Type === 'first') {
            return fields.insert(index + 1, this.getNewEntry(device));
        } else {
            return fields.remove(index);
        }
    };

    private renderContent = ({ fields }) => {
        return fields.map((device, index) => {
            const item = fields.get(index);
            return (
                <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    key={'AppendDevicePanel_' + index}
                >
                    {item.Type === 'first' && (
                        <>
                            <MaterialUI.ListItem>
                                <MaterialUI.ListItemText
                                    primary={`Wohnungsnummer: ${item.WohnungNr} , Gebäude: ${
                                        item.Gebaeude ? item.Gebaeude : ''
                                    }`}
                                />
                            </MaterialUI.ListItem>
                            <MaterialUI.Divider />
                        </>
                    )}
                    <SlAppendDevicePanelComponent
                        onClickActionButton={() =>
                            this.onActionButtonClickHandler(fields, index, fields.get(index))
                        }
                        value={device}
                        deviceTypeList={this.props.deviceTypeList}
                        productList={this.props.productList}
                        showAddButton={this.props.showAddButton}
                        GeraetTyp={item.GeraetTyp}
                        index={index}
                    />
                </div>
            );
        });
    };

    public render() {
        return (
            <div style={{ paddingTop: 20 }}>
                <MaterialUI.Grid
                    container={true}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'stretch'}
                    spacing={0}
                >
                    <MaterialUI.Grid item={true} xs={12}>
                        <FieldArray name={'devices'} component={this.renderContent} />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid
                    container={true}
                    direction={'row'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    style={{ marginTop: '35px' }}
                >
                    <MaterialUI.Typography variant="h6">*Pflichtfelder</MaterialUI.Typography>
                    <MaterialUI.Tooltip
                        id="tooltip-info-icon"
                        title={
                            <div style={{ fontSize: '1rem', lineHeight: '1.2rem' }}>
                                Nicht befüllte Zeilen werden bei der
                                <br />
                                Gerätezuordnung nicht berücksichtigt
                            </div>
                        }
                    >
                        <MaterialUI.InputAdornment position="end">
                            <MaterialUI.IconButton
                                className="iconButton"
                                aria-label="Tooltip"
                                disabled={true}
                            >
                                <MaterialUIIcons.InfoOutlined />
                            </MaterialUI.IconButton>
                        </MaterialUI.InputAdornment>
                    </MaterialUI.Tooltip>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const AppendDeviceContainerComponent = MaterialUI.withStyles(styles)(AppendDeviceContainer);

export const AppendDeviceContainerReduxForm = reduxForm({
    form: 'AppendDeviceForm',
    destroyOnUnmount: true,
})(AppendDeviceContainerComponent);

export const SlAppendDeviceContainerComponent = connect()(AppendDeviceContainerReduxForm);
