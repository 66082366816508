export const kundenanlageByAID = /* GraphQL */ `
    query KundenanlageByAID(
        $AID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelKundenanlageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        kundenanlageByAID(
            AID: $AID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                KAname
                Stichtag
                Adresse {
                    Land
                    Ort
                    PLZ
                    HausNummer
                    Postfach
                    Strasse
                    Zusatzinformationen
                }
                Abrechnungsmodell
                Vertragsbeginn
                Vertragsende
                isMetergridProject
                Identifikationsnummer
                Verteilnetzbetreiber
                CodenummerVNB
                Metergrid {
                    project
                    url
                    id
                    location
                    data_provider
                    data_provider_ref
                    name
                    users
                }
                Produkts {
                    name
                    preis
                    ebene
                    zyklus
                    id
                }
                erzeugungsanlageList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        Anlagentyp
                        Anlagengroesse
                        DatumInbetriebnahme
                        Registernummer
                        Unit
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                inbetriebnahmeprozess {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        InitialProcessDone
                        Dokumente {
                            Fertigstellungsanzeige
                            Inbetriebstellungsanzeige
                            ZaehlerschrankFoto
                            Kundenanlagenbestaetigung
                        }
                        KundenAnlage {
                            VerbraucherEingetragen
                        }
                        Erzeugungsanlagen {
                            ErzeugungsanlagenEingetragen
                        }
                        KaAnmeldung {
                            Stromversorgungserlaubnis
                            UBNregistriert
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                kontaktpersonList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        Rolle
                        AndereRolle
                        Anrede {
                            Typ
                        }
                        Adresse {
                            Land
                            Ort
                            PLZ
                            HausNummer
                            Postfach
                            Strasse
                            Zusatzinformationen
                        }
                        Email
                        Telefon
                        Kommentar
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                zaehlerplatzList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        ZID
                        WohnungNr
                        Montageart
                        AuftragAktion
                        Gebaeude
                        Lage
                        Malo
                        Melo
                        Stockwerk
                        Verbrauchsprognose
                        GeraetTyp
                        Nutzungstyp
                        Metergrid {
                            id
                            project
                            name
                            malo_id
                            melo_id
                            type
                            location
                            data_provider
                            data_provider_ref
                            users
                            url
                        }
                        geraeteList {
                            nextToken
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                dokumenteList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        Filename
                        Pfad
                        FileContents
                        Klassifizierung
                        AndereKlassifizierung
                        UserEmail
                        ContentType
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
