import { IAbleseTyp, IFileInfo, IDeviceType, IProduct, IFile } from '../../../../common/interfaces/interface';
import { SettingActionTypes } from '../types/settings.types';

export interface ISettingState {
    emails: Array<any>
    emailFileInfo: IFileInfo;
    emailFile: IFile;
    productManagementList: Array<IProduct>,
    servicesTyp: Array<any>;
    selectedServiceTyp: any;
    deviceTypeList: Array<IDeviceType>;
    selectedDeviceType: IDeviceType;
    ableseTypeList: Array<IAbleseTyp>;
    selectedAbleseTyp: IAbleseTyp;
}

const initialState: ISettingState = {
    emails: [],
    productManagementList: [],
    servicesTyp: [],
    selectedServiceTyp: null,
    deviceTypeList: [],
    selectedDeviceType: null,
    ableseTypeList: [],
    selectedAbleseTyp: null,
    emailFileInfo: null,
    emailFile: null
};

export const settingsReducer = (state: ISettingState = initialState, action: any): ISettingState => {

    switch (action.type) {
        case SettingActionTypes.SAVE_EMAILS:
            return Object.assign({}, state, {emails: [...action.payload]});
        case SettingActionTypes.SAVE_Product_LIST:
            return Object.assign({}, state, {productManagementList: [...action.payload]});
        case SettingActionTypes.SAVE_SERVICES:
            return Object.assign({}, state, {servicesTyp: [...action.payload]});
        case SettingActionTypes.SAVE_SELECTED_SERVICE:
            return Object.assign({}, state, {selectedServiceTyp: action.payload});
        case SettingActionTypes.SAVE_DEVICE_TYPE:
            return Object.assign({}, state, {deviceTypeList: action.payload});
        case SettingActionTypes.SAVE_SELECTED_DEVICE_TYPE:
            return Object.assign({}, state, {selectedDeviceType: action.payload});
        case SettingActionTypes.SAVE_ABLESE_TYP:
            return Object.assign({}, state, {ableseTypeList: action.payload});
        case SettingActionTypes.SAVE_SELECTED_ABLESE_TYP:
            return Object.assign({}, state, {selectedAbleseTyp: action.payload});
        case SettingActionTypes.ADD_EMAIL_FILE:
            return Object.assign({}, state, {emailFile: action.payload});
        case SettingActionTypes.ADD_EMAIL_INFO:
            return Object.assign({}, state, {emailFileInfo: action.payload});
        default:
            return Object.assign({}, state);

    }
};