export const zaehlerplatzByKundenanlageAID = /* GraphQL */ `
    query ZaehlerplatzByKundenanlageAID(
        $AID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelZaehlerplatzFilterInput
        $limit: Int
        $nextToken: String
    ) {
        zaehlerplatzByKundenanlageAID(
            AID: $AID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                ZID
                WohnungNr
                Montageart
                AuftragAktion
                Gebaeude
                Lage
                Malo
                Melo
                Stockwerk
                Verbrauchsprognose
                GeraetTyp
                Nutzungstyp
                Metergrid {
                    id
                    project
                    name
                    malo_id
                    melo_id
                    type
                    location
                    data_provider
                    data_provider_ref
                    users
                    url
                }
                geraeteList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        ZID
                        Seriennummer
                        ZaehlerTyp
                        Einbaudatum
                        Produkts {
                            name
                            preis
                            ebene
                            zyklus
                            id
                        }
                        GeraetePIN
                        Metergrid {
                            url
                            id
                            serial
                            panel
                            conversion_factor
                            owner
                            project
                        }
                        AuftragAktion
                        Ausbaudatum
                        AusbaustandStatus
                        Eichdatum
                        ObisCodeHT
                        ObisCodeNT
                        GeraetTyp
                        WandlerID1
                        WandlerID2
                        WandlerID3
                        Wandlerfaktor
                        ZaehlwerkNKS
                        ZaehlwerkVKS
                        Zaehlerversanddatum
                        ablesungList {
                            nextToken
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                slimAuftrag {
                    id
                    auftrag_type
                    auftraggeber {
                        name
                        address {
                            street
                            streetNumber
                            postalCode
                            city
                            country
                            __typename
                        }
                        __typename
                    }
                    organisation_id
                    createdAt
                    auftragdatum
                    versanddatum
                    status
                    kundenanlagebetreiber_id
                    kundenanlage_id
                    zaehlerplatzIDs
                    updatedAt
                    __typename
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const zaehlerplatzByZID = /* GraphQL */ `
    query ZaehlerplatzByZID(
        $ZID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelZaehlerplatzFilterInput
        $limit: Int
        $nextToken: String
    ) {
        zaehlerplatzByZID(
            ZID: $ZID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                ZID
                WohnungNr
                Montageart
                AuftragAktion
                Gebaeude
                Lage
                Malo
                Melo
                Stockwerk
                Verbrauchsprognose
                GeraetTyp
                Nutzungstyp
                Metergrid {
                    id
                    project
                    name
                    malo_id
                    melo_id
                    type
                    location
                    data_provider
                    data_provider_ref
                    users
                    url
                }
                geraeteList {
                    items {
                        id
                        organisation_id
                        KID
                        AID
                        ZID
                        Seriennummer
                        ZaehlerTyp
                        Einbaudatum
                        Produkts {
                            name
                            preis
                            ebene
                            zyklus
                            id
                        }
                        GeraetePIN
                        Metergrid {
                            url
                            id
                            serial
                            panel
                            conversion_factor
                            owner
                            project
                        }
                        AuftragAktion
                        Ausbaudatum
                        AusbaustandStatus
                        Eichdatum
                        ObisCodeHT
                        ObisCodeNT
                        GeraetTyp
                        WandlerID1
                        WandlerID2
                        WandlerID3
                        Wandlerfaktor
                        ZaehlwerkNKS
                        ZaehlwerkVKS
                        Zaehlerversanddatum
                        ablesungList {
                            nextToken
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                sensors {
                    items {
                        id
                        organisation_id
                        kundenanlagenbetreiber_id
                        kundenanlage_id
                        zaehlerplatz_id
                        elementIot_id
                        Seriennummer
                        Typ
                        Einbaudatum
                        Ausbaudatum
                        Produkts {
                            name
                            preis
                            ebene
                            zyklus
                            id
                        }
                        Versanddatum
                        Technologie
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
