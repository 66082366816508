import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';

const selector = formValueSelector('MaloMeloForm');
const styles = () =>
    MaterialUI.createStyles({
        item: {
            marginBottom: 30,
        },
    });

interface IMaloMeloEntry {
    ID: string;
    WohnungNr: string;
    GeraeteID: string;
    Malo: string;
    Melo: string;
}

export interface IMaloMeloProps extends WithStyles<typeof styles> {
    onChange: (value, id, index) => void;
    value?: IMaloMeloEntry;
    index?: string;
    error?: boolean;
}

class MaloMelo extends React.Component<IMaloMeloProps, {}> {
    public styles = {
        container: {
            display: 'flex' as 'flex',
            justifyContent: 'space-between',
            padding: '20px',
        },
        item: {
            flexBasis: '20%',
        },
    };

    public render() {
        return (
            <MaterialUI.Grid
                container={true}
                wrap={'nowrap'}
                className={this.props.classes.item}
                spacing={2}
                justify={'space-between'}
            >
                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${this.props.value}.WohnungNr`}
                        label={'Wohnungs-Nr'}
                        component={SlTextInputComponent}
                        disabled={true}
                        fullWith={true}
                        validate={[]}
                    />
                </MaterialUI.Grid>

                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${this.props.value}.GeraeteID`}
                        label={'Geräte-ID'}
                        component={SlTextInputComponent}
                        disabled={true}
                        fullWith={true}
                        validate={[]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${this.props.value}.Malo`}
                        label={'MaLo'}
                        component={SlTextInputComponent}
                        fullWith={true}
                        required={true}
                        validate={[FormValidator.required, FormValidator.malo]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${this.props.value}.Melo`}
                        label={'MeLo'}
                        component={SlTextInputComponent}
                        fullWith={true}
                        required={true}
                        validate={[FormValidator.required, FormValidator.melo]}
                    />
                </MaterialUI.Grid>
            </MaterialUI.Grid>
        );
    }
}

export const MaloMeloComponent = MaterialUI.withStyles(styles)(MaloMelo);

export const SLMaloMeloComponent = connect((state, props) => {
    const WohnungNr = selector(state, `${props.value}.WohnungNr`);
    const GeraeteID = selector(state, `${props.value}.Gebaeude`);
    const Malo = selector(state, `${props.value}.Stockwerk`);
    const Melo = selector(state, `${props.value}.Lage`);

    return { WohnungNr, GeraeteID, Malo, Melo };
})(MaloMeloComponent);
