import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IEinbauauftragProps {
    color: any
}

class Einbauauftrag extends React.Component<IEinbauauftragProps, {}> {
    public static defaultProps: Partial<IEinbauauftragProps> = {
        color: 'inherit'

    };




    public render() {
        return (
            <MaterialUI.SvgIcon style={{padding: 'inherit', paddingRight: '10px'}}>
                <path
                    d="M5.8125 6.05664H2.11523V9.08008H6.41016V10H0.990234V1.46875H6.35156V2.39453H2.11523V5.13672H5.8125V6.05664ZM8.81836 10H7.73438V3.66016H8.81836V10ZM7.64648
        1.97852C7.64648 1.80273 7.69922 1.6543 7.80469 1.5332C7.91406 1.41211 8.07422 1.35156 8.28516 1.35156C8.49609 1.35156 8.65625 1.41211 
        8.76562 1.5332C8.875 1.6543 8.92969 1.80273 8.92969 1.97852C8.92969 2.1543 8.875 2.30078 8.76562 2.41797C8.65625 2.53516 8.49609 2.59375 
        8.28516 2.59375C8.07422 2.59375 7.91406 2.53516 7.80469 2.41797C7.69922 2.30078 7.64648 2.1543 7.64648 1.97852ZM11.584 3.66016L11.6191 
        4.45703C12.1035 3.84766 12.7363 3.54297 13.5176 3.54297C14.8574 3.54297 15.5332 4.29883 15.5449 5.81055V10H14.4609V5.80469C14.457 5.34766 
        14.3516 5.00977 14.1445 4.79102C13.9414 4.57227 13.623 4.46289 13.1895 4.46289C12.8379 4.46289 12.5293 4.55664 12.2637 4.74414C11.998 4.93164 
        11.791 5.17773 11.6426 5.48242V10H10.5586V3.66016H11.584ZM22.5469 6.90039C22.5469 7.86914 22.3242 8.64844 21.8789 9.23828C21.4336 9.82422 
        20.8359 10.1172 20.0859 10.1172C19.2852 10.1172 18.666 9.83398 18.2285 9.26758L18.1758 10H17.1797V1H18.2637V4.35742C18.7012 3.81445 
        19.3047 3.54297 20.0742 3.54297C20.8438 3.54297 21.4473 3.83398 21.8848 4.41602C22.3262 4.99805 22.5469 5.79492 22.5469 
        6.80664V6.90039ZM21.4629 6.77734C21.4629 6.03906 21.3203 5.46875 21.0352 5.06641C20.75 4.66406 20.3398 4.46289 19.8047 
        4.46289C19.0898 4.46289 18.5762 4.79492 18.2637 5.45898V8.20117C18.5957 8.86523 19.1133 9.19727 19.8164 9.19727C20.3359 9.19727 
        20.7402 8.99609 21.0293 8.59375C21.3184 8.19141 21.4629 7.58594 21.4629 6.77734ZM27.832 10C27.7695 9.875 27.7188 9.65234 27.6797 
        9.33203C27.1758 9.85547 26.5742 10.1172 25.875 10.1172C25.25 10.1172 24.7363 9.94141 24.334 9.58984C23.9355 9.23438 23.7363 8.78516 
        23.7363 8.24219C23.7363 7.58203 23.9863 7.07031 24.4863 6.70703C24.9902 6.33984 25.6973 6.15625 26.6074 6.15625H27.6621V5.6582C27.6621 
        5.2793 27.5488 4.97852 27.3223 4.75586C27.0957 4.5293 26.7617 4.41602 26.3203 4.41602C25.9336 4.41602 25.6094 4.51367 25.3477 4.70898C25.0859 
        4.9043 24.9551 5.14062 24.9551 5.41797H23.8652C23.8652 5.10156 23.9766 4.79688 24.1992 4.50391C24.4258 4.20703 24.7305 3.97266 25.1133 
        3.80078C25.5 3.62891 25.9238 3.54297 26.3848 3.54297C27.1152 3.54297 27.6875 3.72656 28.1016 4.09375C28.5156 4.45703 28.7305 4.95898 28.7461 
        5.59961V8.51758C28.7461 9.09961 28.8203 9.5625 28.9688 9.90625V10H27.832ZM26.0332 9.17383C26.373 9.17383 26.6953 9.08594 27 8.91016C27.3047 
        8.73438 27.5254 8.50586 27.6621 8.22461V6.92383H26.8125C25.4844 6.92383 24.8203 7.3125 24.8203 8.08984C24.8203 8.42969 24.9336 8.69531 25.1602 
        8.88672C25.3867 9.07812 25.6777 9.17383 26.0332 9.17383ZM34.3594 9.37305C33.9375 9.86914 33.3184 10.1172 32.502 10.1172C31.8262 10.1172 31.3105 
        9.92188 30.9551 9.53125C30.6035 9.13672 30.4258 8.55469 30.4219 7.78516V3.66016H31.5059V7.75586C31.5059 8.7168 31.8965 9.19727 32.6777 9.19727C33.5059 
        9.19727 34.0566 8.88867 34.3301 8.27148V3.66016H35.4141V10H34.3828L34.3594 9.37305ZM40.9805 10C40.918 9.875 40.8672 9.65234 40.8281 9.33203C40.3242 
        9.85547 39.7227 10.1172 39.0234 10.1172C38.3984 10.1172 37.8848 9.94141 37.4824 9.58984C37.084 9.23438 36.8848 8.78516 36.8848 8.24219C36.8848 
        7.58203 37.1348 7.07031 37.6348 6.70703C38.1387 6.33984 38.8457 6.15625 39.7559 6.15625H40.8105V5.6582C40.8105 5.2793 40.6973 4.97852 40.4707 
        4.75586C40.2441 4.5293 39.9102 4.41602 39.4688 4.41602C39.082 4.41602 38.7578 4.51367 38.4961 4.70898C38.2344 4.9043 38.1035 5.14062 38.1035 
        5.41797H37.0137C37.0137 5.10156 37.125 4.79688 37.3477 4.50391C37.5742 4.20703 37.8789 3.97266 38.2617 3.80078C38.6484 3.62891 39.0723 3.54297 
        39.5332 3.54297C40.2637 3.54297 40.8359 3.72656 41.25 4.09375C41.6641 4.45703 41.8789 4.95898 41.8945 5.59961V8.51758C41.8945 9.09961 41.9688 
        9.5625 42.1172 9.90625V10H40.9805ZM39.1816 9.17383C39.5215 9.17383 39.8438 9.08594 40.1484 8.91016C40.4531 8.73438 40.6738 8.50586 40.8105 
        8.22461V6.92383H39.9609C38.6328 6.92383 37.9688 7.3125 37.9688 8.08984C37.9688 8.42969 38.082 8.69531 38.3086 8.88672C38.5352 9.07812 38.8262 
        9.17383 39.1816 9.17383ZM47.5078 9.37305C47.0859 9.86914 46.4668 10.1172 45.6504 10.1172C44.9746 10.1172 44.459 9.92188 44.1035 9.53125C43.752 
        9.13672 43.5742 8.55469 43.5703 7.78516V3.66016H44.6543V7.75586C44.6543 8.7168 45.0449 9.19727 45.8262 9.19727C46.6543 9.19727 47.2051 8.88867 
        47.4785 8.27148V3.66016H48.5625V10H47.5312L47.5078 9.37305ZM50.748 10V4.49805H49.7461V3.66016H50.748V3.00977C50.748 2.33008 50.9297 1.80469 51.293 
        1.43359C51.6562 1.0625 52.1699 0.876953 52.834 0.876953C53.084 0.876953 53.332 0.910156 53.5781 0.976562L53.5195 1.85547C53.3359 1.82031 53.1406 
        1.80273 52.9336 1.80273C52.582 1.80273 52.3105 1.90625 52.1191 2.11328C51.9277 2.31641 51.832 2.60938 51.832 2.99219V3.66016H53.1855V4.49805H51.832V10H50.748ZM55.8574 2.125V3.66016H57.041V4.49805H55.8574V8.42969C55.8574 
        8.68359 55.9102 8.875 56.0156 9.00391C56.1211 9.12891 56.3008 9.19141 56.5547 9.19141C56.6797 9.19141 56.8516 9.16797 57.0703 9.12109V10C56.7852 10.0781 
        56.5078 10.1172 56.2383 10.1172C55.7539 10.1172 55.3887 9.9707 55.1426 9.67773C54.8965 9.38477 
        54.7734 8.96875 54.7734 8.42969V4.49805H53.6191V3.66016H54.7734V2.125H55.8574ZM61.377 4.63281C61.2129 4.60547 61.0352 4.5918 60.8438 
        4.5918C60.1328 4.5918 59.6504 4.89453 59.3965 5.5V10H58.3125V3.66016H59.3672L59.3848 4.39258C59.7402 3.82617 60.2441 3.54297 60.8965 
        3.54297C61.1074 3.54297 61.2676 3.57031 61.377 3.625V4.63281ZM66.0586 10C65.9961 9.875 65.9453 9.65234 65.9062 9.33203C65.4023 9.85547 
        64.8008 10.1172 64.1016 10.1172C63.4766 10.1172 62.9629 9.94141 62.5605 9.58984C62.1621 9.23438 61.9629 8.78516 61.9629 8.24219C61.9629 
        7.58203 62.2129 7.07031 62.7129 6.70703C63.2168 6.33984 63.9238 6.15625 64.834 6.15625H65.8887V5.6582C65.8887 5.2793 65.7754 4.97852 65.5488 
        4.75586C65.3223 4.5293 64.9883 4.41602 64.5469 4.41602C64.1602 4.41602 63.8359 4.51367 63.5742 4.70898C63.3125 4.9043 63.1816 5.14062 63.1816 
        5.41797H62.0918C62.0918 5.10156 62.2031 4.79688 62.4258 4.50391C62.6523 4.20703 62.957 3.97266 63.3398 3.80078C63.7266 3.62891 64.1504 3.54297 
        64.6113 3.54297C65.3418 3.54297 65.9141 3.72656 66.3281 4.09375C66.7422 4.45703 66.957 4.95898 66.9727 5.59961V8.51758C66.9727 9.09961 67.0469 
        9.5625 67.1953 9.90625V10H66.0586ZM64.2598 9.17383C64.5996 9.17383 64.9219 9.08594 65.2266 8.91016C65.5312 8.73438 65.752 8.50586 65.8887 
        8.22461V6.92383H65.0391C63.7109 6.92383 63.0469 7.3125 63.0469 8.08984C63.0469 8.42969 63.1602 8.69531 63.3867 8.88672C63.6133 9.07812 63.9043 
        9.17383 64.2598 9.17383ZM68.4141 6.77734C68.4141 5.78906 68.6426 5.00391 69.0996 4.42188C69.5566 3.83594 70.1621 3.54297 70.916 3.54297C71.6895 
        3.54297 72.293 3.81641 72.7266 4.36328L72.7793 3.66016H73.7695V9.84766C73.7695 10.668 73.5254 11.3145 73.0371 11.7871C72.5527 12.2598 71.9004 
        12.4961 71.0801 12.4961C70.623 12.4961 70.1758 12.3984 69.7383 12.2031C69.3008 12.0078 68.9668 11.7402 68.7363 11.4004L69.2988 10.75C69.7637 
        11.3242 70.332 11.6113 71.0039 11.6113C71.5312 11.6113 71.9414 11.4629 72.2344 11.166C72.5312 10.8691 72.6797 10.4512 72.6797 9.91211V9.36719C72.2461 9.86719 
        71.6543 10.1172 70.9043 10.1172C70.1621 10.1172 69.5605 9.81836 69.0996 9.2207C68.6426 8.62305 68.4141 7.80859 68.4141 6.77734ZM69.5039 6.90039C69.5039 7.61523 69.6504 8.17773 69.9434 8.58789C70.2363 8.99414 70.6465 9.19727 71.1738 9.19727C71.8574 9.19727 72.3594 8.88672 72.6797 8.26562V5.37109C72.3477 4.76562 71.8496 4.46289 71.1855 4.46289C70.6582 4.46289 70.2461 4.66797 69.9492 5.07812C69.6523 5.48828 69.5039 6.0957 69.5039 6.90039Z"
                    transform="translate(33 5)"
                    fill={this.props.color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.165 16.835L15.6201 18.874C15.7266 18.958 15.8604 19 16 19C16.1396 19 16.2734 18.958 16.3799 18.874L21.6465 15.7661C21.8604 15.647 22 15.416 22
        15.1499V8.8501C22 8.58398 21.8604 8.35303 21.6465 8.23389L21.0908 7.90576C20.3848 8.61133 19.6182 9.37793 18.8359 10.1602C19.1582 10.7085 19.333 11.3447 
        19.333 12C19.333 12.9282 18.9824 13.8184 18.3574 14.4751C17.7314 15.1313 16.8838 15.5 16 15.5C15.3213 15.5 14.665 15.2827 14.1133 14.8853C13.3076 15.6909 
        12.6289 16.3706 12.165 16.835ZM12.8496 2.56445L10 0L0 9H3V13.5H4.5L6.5 11.5L5 10C5 10 9.33301 5.93799 12.8496 2.56445Z"
                    fill={this.props.color}

                />
                <path
                    d="M16 4.29153e-08C15.5 4.29153e-08 15 0.17 14.59 0.58C11.72 3.45 6 9.17 6 9.17L7.5 10.67L4 14.17H2L0 18.17L2 20.17L6
        18.17V16.17L9.5 12.67L11 14.17C11 14.17 16.72 8.45 19.59 5.58C20.21 4.67 20.37 3.54 19.59 2.76L17.41 0.58C17 0.17 16.5 4.29153e-08 16 
        4.29153e-08ZM16 2.17L18 4.17L11 11.17L9 9.17L16 2.17Z"
                    transform="translate(1 1)"
                    fill={this.props.color}
                />
                <path
                    d="M2.22222 2.22222H0.276953C0.123996 2.22222 0 2.34552 0 2.5C0 2.65341 0.124238 2.77778 0.276953 2.77778H2.22222V4.72305C2.22222 4.876 2.34552 5 2.5 5C2.65341 5 2.77778 4.87576 2.77778 4.72305V2.77778H4.72305C4.876 2.77778 5 2.65448 5 2.5C5 2.34659 4.87576 2.22222 4.72305 2.22222H2.77778V0.276953C2.77778 0.123996 2.65448 0 2.5 0C2.34659 0 2.22222 0.124238 2.22222 0.276953V2.22222Z"
                    transform="translate(23 1)"
                    fill={this.props.color}
                />
                <path
                    d="M1.97222 1.97222H0.276953C-0.0141484 1.97222 -0.25 2.20752 -0.25 2.5C-0.25 2.79092 -0.0143915 3.02778 0.276953 3.02778H1.97222V4.72305C1.97222 5.01415 2.20752 5.25 2.5 5.25C2.79092 5.25 3.02778 5.01439 3.02778 4.72305V3.02778H4.72305C5.01415 3.02778 5.25 2.79248 5.25 2.5C5.25 2.20908 5.01439 1.97222 4.72305 1.97222H3.02778V0.276953C3.02778 -0.0141484 2.79248 -0.25 2.5 -0.25C2.20908 -0.25 1.97222 -0.0143915 1.97222 0.276953V1.97222Z"
                    transform="translate(23 1)"
                    stroke={this.props.color}
                    strokeWidth="0.5"
                />
            </MaterialUI.SvgIcon>
        );
    }


}

export const EinbauauftragIcon = (Einbauauftrag);
