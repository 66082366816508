import * as MaterialUI from '../../../materialUI/MaterialUI';
import { WithStyles } from '@material-ui/styles';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { IProduct } from '../../../common/interfaces/interface';
import * as React from 'react';
import { FormValidator } from '../../../common/validators/form.validator';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { DeleteButton } from '../../../common/components/buttons';
import uuid from 'uuid';
import { SensorTechnologie } from '../../../model/enums';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent,
} from '../../../common/components/forms';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
} from '../../../common/utils/Functions';
import { SlMultipleSelectInputComponent } from '../../../common/components/forms/select-input.component';

const selector = formValueSelector('AddSlimSensorForm');

const styles = () =>
    MaterialUI.createStyles({
        container: {
            display: 'flex' as 'flex',
            justifyContent: 'space-between',
        },
        item: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
        },
        dropdown: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
            marginTop: -5,
        },
        actionButton: {
            maxWidth: '4%',
            flexBasis: '4%',
            marginTop: 25,
            marginLeft: 25,
        },
    });

interface IAppendSensorEntry {
    organisation_id: string;
    zaehlerplatz_id: string;
    elementIot_id: string;
    Seriennummer: string;
    Typ: string;
    Einbaudatum: string;
    Ausbaudatum: string;
    Produkts: string[];
    Versanddatum: string;
    Technologie: string;
    Type: string;
}

export interface IAddSensorRowProps extends WithStyles<typeof styles> {
    onClickActionButton: (index) => void;
    value: IAppendSensorEntry;
    productList?: Array<IProduct>;
    index: string;
    editMode?: boolean;
    dispatch?: any;
    produktIds: any[];
}

interface IState {
    selectedProducts: Array<string>;
}

class AddSensorRow extends React.Component<IAddSensorRowProps, IState> {
    constructor(props: IAddSensorRowProps) {
        super(props);
        this.state = { selectedProducts: [...this.props.produktIds] };
    }

    public static defaultProps: Partial<IAddSensorRowProps> = {
        value: {
            organisation_id: '',
            zaehlerplatz_id: '',
            elementIot_id: '',
            Seriennummer: '',
            Typ: '',
            Einbaudatum: '',
            Ausbaudatum: '',
            Produkts: [],
            Versanddatum: '',
            Technologie: '',
            Type: 'first',
        },
    };

    public renderAddButton() {
        const titleAdd = 'Zeile für zusätzliches Sensor hinzufügen';
        const titleRemove = 'Zeile für das Sensor entfernen';
        if (this.props.editMode) {
            return null;
        }
        return this.props['type'] === 'first' ? (
            <div>
                <MaterialUI.Tooltip id="tooltip-icon" title={titleAdd}>
                    <MaterialUI.IconButton
                        onClick={() => {
                            this.props.onClickActionButton(this.props.index);
                        }}
                    >
                        <MaterialUIIcons.AddBox />
                    </MaterialUI.IconButton>
                </MaterialUI.Tooltip>
            </div>
        ) : (
            <div>
                <MaterialUI.Tooltip id="tooltip-icon" title={titleRemove}>
                    <DeleteButton
                        onClick={() => {
                            this.props.onClickActionButton(this.props.index);
                        }}
                    />
                </MaterialUI.Tooltip>
            </div>
        );
    }

    public renderItemForSelectBox(enums) {
        return Object.keys(enums)
            .filter(enumKey => enumKey !== 'None')
            .map(value => (
                <MaterialUI.MenuItem key={uuid.v4()} value={enums[value]}>
                    {enums[value]}
                </MaterialUI.MenuItem>
            ));
    }

    /*private onChangeDeviceType = event => {
        this.setState({ selectedDeviceTypes: event.target.value });
    };*/
    private onChangeProducts = event => {
        this.setState({
            selectedProducts: [...this.state.selectedProducts, ...event.target.value],
        });
    };

    public hasRowValue = (): boolean => {
        return (
            this.props['Seriennummer'] ||
            this.props['elementIot_id'] ||
            this.props['Einbaudatum'] ||
            this.props['Technologie']
        );
        // this.props['produktIds'].length > 0
    };

    public render() {
        return (
            <div style={{ display: 'flex' }}>
                <MaterialUI.Grid
                    container={true}
                    style={{ marginBottom: 20, marginTop: 20, width: '96%' }}
                    direction={'row'}
                    spacing={1}
                >
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.sensor.Seriennummer`}
                            component={SlTextInputComponent}
                            label={'SensorID'}
                            required={true}
                            disabled={false}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.required]}
                            // validate={this.hasRowValue() ? [FormValidator.required] : []}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.sensor.elementIot_id`}
                            component={SlTextInputComponent}
                            options={{ fullWidth: true }}
                            label={'ElementsID'}
                            required={true}
                            disabled={false}
                            type={'text'}
                            validate={[FormValidator.required]}
                            //validate={this.hasRowValue() ? [FormValidator.required] : []}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.sensor.Einbaudatum`}
                            component={SlDateInputComponent}
                            options={{ fullWidth: true }}
                            label={'Einbaudatum'}
                            required={true}
                            disabled={false}
                            type={'text'}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            validate={[FormValidator.isValidDate]}
                            /*validate={
                                this.hasRowValue()
                                    ? [FormValidator.required, FormValidator.isValidDate]
                                    : []
                            }*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${this.props.value}.sensor.Versanddatum`}
                            component={SlDateInputComponent}
                            options={{ fullWidth: true }}
                            label={'Versanddatum'}
                            required={false}
                            disabled={false}
                            type={'text'}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            validate={[FormValidator.isValidDate]}
                            //validate={this.hasRowValue() ? [FormValidator.isValidDate] : []}
                        />
                    </MaterialUI.Grid>

                    {this.props.editMode && (
                        <MaterialUI.Grid item={true} xs={true}>
                            <Field
                                name={`${this.props.value}.sensor.Ausbaudatum`}
                                component={SlDateInputComponent}
                                options={{ fullWidth: true }}
                                label={'Ausbaudatum'}
                                required={false}
                                disabled={false}
                                type={'text'}
                                format={inputDateDisplayFormat}
                                normalize={germanDateToISODateFormat}
                                validate={[FormValidator.isValidDate]}
                                // validate={this.hasRowValue() ? [FormValidator.isValidDate] : []}
                            />
                        </MaterialUI.Grid>
                    )}

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlSelectInputComponent}
                            options={{ fullWidth: true }}
                            label={'Technologie'}
                            required={true}
                            name={`${this.props.value}.sensor.Technologie`}
                            validate={[FormValidator.required]}
                            // validate={this.hasRowValue() ? [FormValidator.required] : []}
                        >
                            {renderItemForSelectBox(SensorTechnologie)}
                        </Field>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlMultipleSelectInputComponent}
                            onChange={this.onChangeProducts}
                            label={'Produktauswahl'}
                            name={`${this.props.value}.sensor.Produkts`}
                            required={true}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.requiredArray]}
                            // validate={this.hasRowValue() ? [FormValidator.requiredArray] : []}
                        >
                            {this.props.productList
                                .filter(item => item.ebene === 'Gerät')
                                .map((product, index) => (
                                    <MaterialUI.MenuItem
                                        key={`${index}-${product.id}`}
                                        value={product.id}
                                    >
                                        <MaterialUI.Checkbox
                                            checked={
                                                this.state.selectedProducts.indexOf(product.id) > -1
                                            }
                                        />
                                        <MaterialUI.ListItemText
                                            primary={`${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`}
                                        />
                                    </MaterialUI.MenuItem>
                                ))}
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                {/*<div className={this.props.classes.actionButton}>{this.renderAddButton()}</div>*/}
            </div>
        );
    }
}

export const AddSensorRowComponent = MaterialUI.withStyles(styles)(AddSensorRow);
export const AddSlimSensorRow = connect((state, props) => {
    const einbaudatum = selector(state, `${props.value}.sensor.Einbaudatum`);
    const type = selector(state, `${props.value}.Type`);

    return {
        einbaudatum,
        type,
    };
})(AddSensorRowComponent);
