export class User {
    public email: string;
    public profile: Array<string>;

    constructor(token: { email: string; profile: Array<string> }) {
        this.email = token.email;
        this.profile = token.profile;
    }


}