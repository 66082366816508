import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IGeraetewechselProps {
  color: any
}

class Geraetewechsel extends React.Component<IGeraetewechselProps, {}>{




  public render() {
    return (
      <MaterialUI.SvgIcon style={{ padding: 'inherit', paddingRight: '10px' }} >  
          <path
        d="M7.28906 8.88086C7 9.29492 6.5957 9.60547 6.07617 9.8125C5.56055 10.0156 4.95898 10.1172 4.27148 10.1172C3.57617 10.1172 2.95898 9.95508 2.41992 
        9.63086C1.88086 9.30273 1.46289 8.83789 1.16602 8.23633C0.873047 7.63477 0.722656 6.9375 0.714844 6.14453V5.40039C0.714844 4.11523 1.01367 3.11914 1.61133 
        2.41211C2.21289 1.70508 3.05664 1.35156 4.14258 1.35156C5.0332 1.35156 5.75 1.58008 6.29297 2.03711C6.83594 2.49023 7.16797 3.13477 7.28906 3.9707H6.16406C5.95312 
        2.8418 5.28125 2.27734 4.14844 2.27734C3.39453 2.27734 2.82227 2.54297 2.43164 3.07422C2.04492 3.60156 1.84961 4.36719 1.8457 5.37109V6.06836C1.8457 7.02539 
        2.06445 7.78711 2.50195 8.35352C2.93945 8.91602 3.53125 9.19727 4.27734 9.19727C4.69922 9.19727 5.06836 9.15039 5.38477 9.05664C5.70117 8.96289 5.96289 8.80469
         6.16992 8.58203V6.66602H4.19531V5.75195H7.28906V8.88086ZM11.6309 10.1172C10.7715 10.1172 10.0723 9.83594 9.5332 9.27344C8.99414 8.70703 8.72461 7.95117 8.72461 
         7.00586V6.80664C8.72461 6.17773 8.84375 5.61719 9.08203 5.125C9.32422 4.62891 9.66016 4.24219 10.0898 3.96484C10.5234 3.68359 10.9922 3.54297 11.4961 
         3.54297C12.3203 3.54297 12.9609 3.81445 13.418 4.35742C13.875 4.90039 14.1035 5.67773 14.1035 6.68945V7.14062H9.80859C9.82422 7.76562 10.0059 8.27148 
         10.3535 8.6582C10.7051 9.04102 11.1504 9.23242 11.6895 9.23242C12.0723 9.23242 12.3965 9.1543 12.6621 8.99805C12.9277 8.8418 13.1602 8.63477 13.3594 
         8.37695L14.0215 8.89258C13.4902 9.70898 12.6934 10.1172 11.6309 10.1172ZM11.4961 4.43359C11.0586 4.43359 10.6914 4.59375 10.3945 4.91406C10.0977 5.23047 
         9.91406 5.67578 9.84375 6.25H13.0195V6.16797C12.9883 5.61719 12.8398 5.19141 12.5742 4.89062C12.3086 4.58594 11.9492 4.43359 11.4961 4.43359ZM18.4277 
         4.63281C18.2637 4.60547 18.0859 4.5918 17.8945 4.5918C17.1836 4.5918 16.7012 4.89453 16.4473 5.5V10H15.3633V3.66016H16.418L16.4355 4.39258C16.791 3.82617 
         17.2949 3.54297 17.9473 3.54297C18.1582 3.54297 18.3184 3.57031 18.4277 3.625V4.63281ZM23.1094 10C23.0469 9.875 22.9961 9.65234 22.957 9.33203C22.4531 
         9.85547 21.8516 10.1172 21.1523 10.1172C20.5273 10.1172 20.0137 9.94141 19.6113 9.58984C19.2129 9.23438 19.0137 8.78516 19.0137 8.24219C19.0137 7.58203 
         19.2637 7.07031 19.7637 6.70703C20.2676 6.33984 20.9746 6.15625 21.8848 6.15625H22.9395V5.6582C22.9395 5.2793 22.8262 4.97852 22.5996 4.75586C22.373 4.5293 
         22.0391 4.41602 21.5977 4.41602C21.2109 4.41602 20.8867 4.51367 20.625 4.70898C20.3633 4.9043 20.2324 5.14062 20.2324 5.41797H19.1426C19.1426 5.10156 
         19.2539 4.79688 19.4766 4.50391C19.7031 4.20703 20.0078 3.97266 20.3906 3.80078C20.7773 3.62891 21.2012 3.54297 21.6621 3.54297C22.3926 3.54297 22.9648 
         3.72656 23.3789 4.09375C23.793 4.45703 24.0078 4.95898 24.0234 5.59961V8.51758C24.0234 9.09961 24.0977 9.5625 24.2461 9.90625V10H23.1094ZM21.3105 9.17383C21.6504 
         9.17383 21.9727 9.08594 22.2773 8.91016C22.582 8.73438 22.8027 8.50586 22.9395 8.22461V6.92383H22.0898C20.7617 6.92383 20.0977 7.3125 20.0977 8.08984C20.0977 
         8.42969 20.2109 8.69531 20.4375 8.88672C20.6641 9.07812 20.9551 9.17383 21.3105 9.17383ZM19.8926 1.9668C19.8926 1.79102 19.9453 1.64453 20.0508 1.52734C20.1602 
         1.40625 20.3203 1.3457 20.5312 1.3457C20.7422 1.3457 20.9023 1.40625 21.0117 1.52734C21.1211 1.64453 21.1758 1.79102 21.1758 1.9668C21.1758 2.14258 21.1211 
         2.28906 21.0117 2.40625C20.9023 2.52344 20.7422 2.58203 20.5312 2.58203C20.3203 2.58203 20.1602 2.52344 20.0508 2.40625C19.9453 2.28906 19.8926 2.14258 
         19.8926 1.9668ZM22.4121 1.97852C22.4121 1.80273 22.4648 1.6543 22.5703 1.5332C22.6797 1.41211 22.8398 1.35156 23.0508 1.35156C23.2617 1.35156 23.4219 
         1.41211 23.5312 1.5332C23.6406 1.6543 23.6953 1.80273 23.6953 1.97852C23.6953 2.1543 23.6406 2.30078 23.5312 2.41797C23.4219 2.53516 23.2617 2.59375 
         23.0508 2.59375C22.8398 2.59375 22.6797 2.53516 22.5703 2.41797C22.4648 2.30078 22.4121 2.1543 22.4121 1.97852ZM27.1934 
         2.125V3.66016H28.377V4.49805H27.1934V8.42969C27.1934 8.68359 27.2461 8.875 27.3516 9.00391C27.457 9.12891 27.6367 9.19141 27.8906 9.19141C28.0156 
         9.19141 28.1875 9.16797 28.4062 9.12109V10C28.1211 10.0781 27.8438 10.1172 27.5742 10.1172C27.0898 10.1172 26.7246 9.9707 26.4785 9.67773C26.2324
          9.38477 26.1094 8.96875 26.1094 8.42969V4.49805H24.9551V3.66016H26.1094V2.125H27.1934ZM32.2793 10.1172C31.4199 10.1172 30.7207 9.83594 30.1816 
          9.27344C29.6426 8.70703 29.373 7.95117 29.373 7.00586V6.80664C29.373 6.17773 29.4922 5.61719 29.7305 5.125C29.9727 4.62891 30.3086 4.24219 30.7383 
          3.96484C31.1719 3.68359 31.6406 3.54297 32.1445 3.54297C32.9688 3.54297 33.6094 3.81445 34.0664 4.35742C34.5234 4.90039 34.752 5.67773 34.752 
          6.68945V7.14062H30.457C30.4727 7.76562 30.6543 8.27148 31.002 8.6582C31.3535 9.04102 31.7988 9.23242 32.3379 9.23242C32.7207 9.23242 33.0449 9.1543 
          33.3105 8.99805C33.5762 8.8418 33.8086 8.63477 34.0078 8.37695L34.6699 8.89258C34.1387 9.70898 33.3418 10.1172 32.2793 10.1172ZM32.1445 4.43359C31.707 
          4.43359 31.3398 4.59375 31.043 4.91406C30.7461 5.23047 30.5625 5.67578 30.4922 6.25H33.668V6.16797C33.6367 5.61719 33.4883 5.19141 33.2227 4.89062C32.957 
          4.58594 32.5977 4.43359 32.1445 4.43359ZM41.625 8.50586L42.8438 3.66016H43.9277L42.082 10H41.2031L39.6621 5.19531L38.1621 10H37.2832L35.4434 
          3.66016H36.5215L37.7695 8.40625L39.2461 3.66016H40.1191L41.625 8.50586ZM47.666 10.1172C46.8066 10.1172 46.1074 9.83594 45.5684 9.27344C45.0293 
          8.70703 44.7598 7.95117 44.7598 7.00586V6.80664C44.7598 6.17773 44.8789 5.61719 45.1172 5.125C45.3594 4.62891 45.6953 4.24219 46.125 3.96484C46.5586 
          3.68359 47.0273 3.54297 47.5312 3.54297C48.3555 3.54297 48.9961 3.81445 49.4531 4.35742C49.9102 4.90039 50.1387 5.67773 
          50.1387 6.68945V7.14062H45.8438C45.8594 7.76562 46.041 8.27148 46.3887 8.6582C46.7402 9.04102 47.1855 9.23242 47.7246 9.23242C48.1074 9.23242 48.4316 
          9.1543 48.6973 8.99805C48.9629 8.8418 49.1953 8.63477 49.3945 8.37695L50.0566 8.89258C49.5254 9.70898 48.7285 10.1172 47.666 10.1172ZM47.5312 4.43359C47.0938 
          4.43359 46.7266 4.59375 46.4297 4.91406C46.1328 5.23047 45.9492 5.67578 45.8789 6.25H49.0547V6.16797C49.0234 5.61719 48.875 5.19141 48.6094 4.89062C48.3438 
          4.58594 47.9844 4.43359 47.5312 4.43359ZM53.9414 9.23242C54.3281 9.23242 54.666 9.11523 54.9551 8.88086C55.2441 8.64648 55.4043 8.35352 55.4355 
          8.00195H56.4609C56.4414 8.36523 56.3164 8.71094 56.0859 9.03906C55.8555 9.36719 55.5469 9.62891 55.1602 9.82422C54.7773 10.0195 54.3711 10.1172 53.9414 
          10.1172C53.0781 10.1172 52.3906 9.83008 51.8789 9.25586C51.3711 8.67773 51.1172 7.88867 51.1172 6.88867V6.70703C51.1172 6.08984 51.2305 5.54102 51.457
           5.06055C51.6836 4.58008 52.0078 4.20703 52.4297 3.94141C52.8555 3.67578 53.3574 3.54297 53.9355 3.54297C54.6465 3.54297 55.2363 3.75586 55.7051 
           4.18164C56.1777 4.60742 56.4297 5.16016 56.4609 5.83984H55.4355C55.4043 5.42969 55.248 5.09375 54.9668 4.83203C54.6895 4.56641 54.3457 4.43359 53.9355 
           4.43359C53.3848 4.43359 52.957 4.63281 52.6523 5.03125C52.3516 5.42578 52.2012 5.99805 52.2012 6.74805V6.95312C52.2012 7.68359 52.3516 8.24609 52.6523 
           8.64062C52.9531 9.03516 53.3828 9.23242 53.9414 9.23242ZM58.7637 4.42773C59.2441 3.83789 59.8691 3.54297 60.6387 3.54297C61.9785 3.54297 62.6543 4.29883 
           62.666 5.81055V10H61.582V5.80469C61.5781 5.34766 61.4727 5.00977 61.2656 4.79102C61.0625 4.57227 60.7441 4.46289 60.3105 4.46289C59.959 4.46289 59.6504 
           4.55664 59.3848 4.74414C59.1191 4.93164 58.9121 5.17773 58.7637 5.48242V10H57.6797V1H58.7637V4.42773ZM67.9805 8.31836C67.9805 8.02539 67.8691 
           7.79883 67.6465 7.63867C67.4277 7.47461 67.043 7.33398 66.4922 7.2168C65.9453 7.09961 65.5098 6.95898 65.1855 6.79492C64.8652 6.63086 64.627 6.43555
            64.4707 6.20898C64.3184 5.98242 64.2422 5.71289 64.2422 5.40039C64.2422 4.88086 64.4609 4.44141 64.8984 4.08203C65.3398 3.72266 65.9023 3.54297 66.5859
             3.54297C67.3047 3.54297 67.8867 3.72852 68.332 4.09961C68.7812 4.4707 69.0059 4.94531 69.0059 5.52344H67.916C67.916 5.22656 67.7891 4.9707 67.5352 
             4.75586C67.2852 4.54102 66.9688 4.43359 66.5859 4.43359C66.1914 4.43359 65.8828 4.51953 65.6602 4.69141C65.4375 4.86328 65.3262 5.08789 65.3262 
             5.36523C65.3262 5.62695 65.4297 5.82422 65.6367 5.95703C65.8438 6.08984 66.2168 6.2168 66.7559 6.33789C67.2988 6.45898 67.7383 6.60352 68.0742 
             6.77148C68.4102 6.93945 68.6582 7.14258 68.8184 7.38086C68.9824 7.61523 69.0645 7.90234 69.0645 8.24219C69.0645 8.80859 68.8379 9.26367 68.3848 
             9.60742C67.9316 9.94727 67.3438 10.1172 66.6211 10.1172C66.1133 10.1172 65.6641 10.0273 65.2734 9.84766C64.8828 9.66797 64.5762 9.41797 64.3535 
             9.09766C64.1348 8.77344 64.0254 8.42383 64.0254 8.04883H65.1094C65.1289 8.41211 65.2734 8.70117 65.543 8.91602C65.8164 9.12695 66.1758 9.23242 66.6211 
             9.23242C67.0312 9.23242 67.3594 9.15039 67.6055 8.98633C67.8555 8.81836 67.9805 8.5957 67.9805 8.31836ZM73.1074 10.1172C72.248 10.1172 71.5488 9.83594 
             71.0098 9.27344C70.4707 8.70703 70.2012 7.95117 70.2012 7.00586V6.80664C70.2012 6.17773 70.3203 5.61719 70.5586 5.125C70.8008 4.62891 71.1367 4.24219
              71.5664 3.96484C72 3.68359 72.4688 3.54297 72.9727 3.54297C73.7969 3.54297 74.4375 3.81445 74.8945 4.35742C75.3516 4.90039 75.5801 5.67773 75.5801
               6.68945V7.14062H71.2852C71.3008 7.76562 71.4824 8.27148 71.8301 8.6582C72.1816 9.04102 72.627 9.23242 73.166 9.23242C73.5488 9.23242 73.873 9.1543
                74.1387 8.99805C74.4043 8.8418 74.6367 8.63477 74.8359 8.37695L75.498 8.89258C74.9668 9.70898 74.1699 10.1172 73.1074 10.1172ZM72.9727 4.43359C72.5352 
                4.43359 72.168 4.59375 71.8711 4.91406C71.5742 5.23047 71.3906 5.67578 71.3203 6.25H74.4961V6.16797C74.4648 5.61719 74.3164 5.19141 74.0508 
                4.89062C73.7852 4.58594 73.4258 4.43359 72.9727 4.43359ZM78.0176 10H76.9336V1H78.0176V10Z"
        transform="translate(36 9)"
        fill={this.props.color}
      />
      <path
        d="M5.12946 0.555556C3.83488 0.555556 2.66243 1.10556 1.75867 2L0 0V5H4.39668L2.62824 2.98889C3.30728 2.34444 4.17196 1.94444 5.12946 1.94444C6.85882 1.94444 8.32926 3.22778 8.84221 5L10 4.56667C9.32096 2.23889 7.40107 0.555556 5.12946 0.555556Z"
        transform="translate(8.67871 26.7314) rotate(-150.218)"
        fill={this.props.color}
      />
      <path
        d="M5.12946 0.555556C3.83488 0.555556 2.66243 1.10556 1.75867 2L0 0V5H4.39668L2.62824 2.98889C3.30728 2.34444 4.17196 1.94444 5.12946 1.94444C6.85882 1.94444 8.32926 3.22778 8.84221 5L10 4.56667C9.32096 2.23889 7.40108 0.555556 5.12946 0.555556Z"
        transform="translate(14.7461) rotate(20.1078)"
        fill={this.props.color}
      />
      <path
        d="M11.9304 3.26974e-08C11.5576 3.26974e-08 11.1847 0.129524 10.879 0.441905C8.73901 2.62857 4.47389 6.98667 4.47389 6.98667L5.59237 8.12952L2.9826 10.7962H1.4913L0 13.8438L1.4913 15.3676L4.47389 13.8438V12.32L7.08367 9.65333L8.20214 10.7962C8.20214 10.7962 12.4673 6.4381 14.6073 4.25143C15.0696 3.5581 15.1889 2.69714 14.6073 2.10286L12.9817 0.441905C12.676 0.129524 12.3032 3.26974e-08 11.9304 3.26974e-08ZM11.9304 1.65333L13.4217 3.17714L8.20214 8.51048L6.71084 6.98667L11.9304 1.65333Z"
        transform="translate(11.999 8.4248)"
        fill={this.props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68555 1.02734L7.13574 0.137207C7.0166 0.0458984 6.86719 0 6.71094 0C6.55469 0 6.40527 0.0458984 6.28613 0.137207L0.395508 3.52002C0.15625 3.64941 
        0 3.90088 0 4.19043V11.0479C0 11.2588 0.0839844 11.4497 0.22168 11.5879L1.11914 9.52344H2.9834L3.30957 9.18018C3.0957 8.69385 2.98242 8.1626 2.98242 
        7.61914C2.98242 6.60889 3.375 5.63965 4.07422 4.92529C4.57129 4.41699 5.19434 4.06738 5.86914 3.90771C6.49902 3.26367 7.52734 2.21191 8.68555 1.02734ZM10.1797 
        9.01514C10.8252 8.33936 12.0459 7.06201 13.4219 5.62842V11.0479C13.4219 11.3369 13.2646 11.5884 13.0264 11.7183L7.13574 15.1011C7.0166 15.1924 6.86719 15.2383 6.71094 15.2383C6.55469 15.2383 6.40527 15.1924 6.28613 15.1011L3.99512 13.7852L4.84766 13.333V11.8096L5.41992 11.1929C5.8291 11.3472 6.26562 11.4287 6.71094 11.4287C7.69922 11.4287 8.64746 11.0273 9.34668 10.313C9.71191 9.93945 9.99414 9.49707 10.1797 9.01514Z"
        transform="translate(11.999 9.18701)"
        fill={this.props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.59863L10.3955 8.52002C10.1562 8.64941 10 8.90088 10 9.19043V11.7378L6.37988 13.874C6.27344 13.958 6.13965 14 6 14C5.86035 14 5.72656 13.958 5.62012 
        13.874L0.353516 10.7661C0.139648 10.647 0 10.416 0 10.1499V3.8501C0 3.58398 0.139648 3.35303 0.353516 3.23389L5.62012 0.125977C5.72656 0.0419922 5.86035 0 6 
        0C6.13965 0 6.27344 0.0419922 6.37988 0.125977L11.6465 3.23389C11.8604 3.35303 12 3.58398 12 3.8501V7.59863ZM3.64258 4.5249C4.26855 3.86865 5.11621 3.5 6 3.5C6.88379 3.5 7.73145 3.86865 8.35742 4.5249C8.98242 5.18164 9.33301 6.07178 9.33301 7C9.33301 7.92822 8.98242 8.81836 8.35742 9.4751C7.73145 10.1313 6.88379 10.5 6 10.5C5.11621 10.5 4.26855 10.1313 3.64258 9.4751C3.01758 8.81836 2.66699 7.92822 2.66699 7C2.66699 6.07178 3.01758 5.18164 3.64258 4.5249Z"
        transform="translate(0.999023 3.4248)"
        fill={this.props.color}
      />
      </MaterialUI.SvgIcon>
    );
  }


}

export const GeraetewechselIcon = (Geraetewechsel);
