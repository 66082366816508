import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import * as uuid from 'uuid';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlDateInputComponent, SlSelectInputComponent } from '../../../common/components/forms';
import { SlNumberInputComponent } from '../../../common/components/forms/number-input.component';
import { SlMultipleSelectInputComponent } from '../../../common/components/forms/select-input.component';

import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IDeviceType, IProduct } from '../../../common/interfaces/interface';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
} from '../../../common/utils/Functions';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { GeraetTypKey } from '../../../model/enums';
import { IReduxState } from '../../../model/redux-state';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        mediumInput: {},
        smallInput: {},
        date: {
            width: '30%',
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            overflow: 'hidden',
        },
        tinyInput: {
            width: '15%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
        responsiveContent: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                margin: 'auto',
                width: '70%',
            },
            [theme.breakpoints.up('lg')]: {
                margin: 'auto',
                width: '70%',
            },
        },
    });

export interface ISlimDeviceEditProps extends WithStyles<typeof styles> {
    deviceTypeList?: Array<IDeviceType>;
    readonly?: boolean;
    device?: any;
    initialize: (device: any) => void;
    currentDeviceTyp?: string;
    productList: Array<IProduct>;
}

interface IState {
    selectedProducts: Array<string>;
}

class DeviceUpdate extends React.Component<ISlimDeviceEditProps, IState> {
    constructor(props: ISlimDeviceEditProps) {
        super(props);

        // console.log('device: ', this.props.device);
        const {
            updatedAt,
            createdAt,
            ablesungList,
            AID,
            KID,
            organisation_id,
            ZID,
            ...restDevice
        } = this.props.device;
        this.props.initialize({
            ...restDevice,
            ProduktIds: this.props.device.Produkts.map(item => item.id),
        });
        this.state = {
            selectedProducts: this.props.device
                ? [...this.props.device.Produkts.map(item => item.id)]
                : [],
        };
    }

    private onChangeProducts = event => {
        this.setState({ selectedProducts: event.target.value });
    };
    public renderWandlerFeld = () => {
        if (this.props.device['GeraetTyp'] === GeraetTypKey.MME60A) {
            return null;
        }
        return (
            <>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field
                        name={`WandlerID1`}
                        component={SlTextInputComponent}
                        label={'Wandler-ID 1'}
                        required={false}
                        disabled={false}
                        options={{ fullWidth: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field
                        name={`WandlerID2`}
                        component={SlTextInputComponent}
                        label={'Wandler-ID 2'}
                        required={false}
                        disabled={false}
                        options={{ fullWidth: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field
                        name={`WandlerID3`}
                        component={SlTextInputComponent}
                        label={'Wandler-ID 3'}
                        required={false}
                        disabled={false}
                        options={{ fullWidth: true }}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={4}>
                    <SlNumberInputComponent
                        name={`Wandlerfaktor`}
                        label={'Wandlerfaktor'}
                        required={false}
                    />
                </MaterialUI.Grid>
            </>
        );
    };

    public render() {
        return (
            <div style={{ padding: 15 }}>
                <MaterialUI.Grid container={true} spacing={4} direction={'row'}>
                    {/*<MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={'Grundtyp'}
                            name={`GrundtypId`}
                            required={true}
                            onChange={this.onChangeDeviceType}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {this.props.deviceTypeList.map((deviceType, index) => (
                                <MaterialUI.MenuItem
                                    key={`${index}-${deviceType.id}`}
                                    value={deviceType.id}
                                >
                                    <MaterialUI.ListItemText primary={deviceType.grundTyp} />
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>*/}

                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Seriennummer"
                            readonly={this.props.readonly}
                            label={'Geräte-ID'}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.required, FormValidator.serialNumber]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Eichdatum"
                            readonly={this.props.readonly}
                            label={deTranslator.device.eichdate}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>

                    {/*  <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Einbaudatum"
                            readonly={this.props.readonly}
                            label={deTranslator.device.addDate}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlDateInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.required]}
                        />
                    </MaterialUI.Grid>*/}
                    {/* <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="EinbaustandHT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.addStateHT}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>*/}

                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="ObisCodeHT"
                            component={SlTextInputComponent}
                            label={deTranslator.device.obisCodeHT}
                            readonly={this.props.readonly}
                            className={`${this.props.classes.input} ${this.props.classes.tinyInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.obisCode]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    {/* <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Ausbaudatum"
                            label={deTranslator.device.removeDate}
                            readonly={this.props.readonly}
                            validate={[]}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            options={{ fullWidth: false, shrink: true }}
                        />
                    </MaterialUI.Grid>*/}

                    {/*  <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="AusbaustandHT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.removeStateHT}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>*/}
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={deTranslator.device.ZaehlwerkVKS}
                            name={`ZaehlwerkVKS`}
                            required={true}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {Array.from(Array(9).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index + 1}>
                                    {index + 1}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={deTranslator.device.ZaehlwerkNKS}
                            name={`ZaehlwerkNKS`}
                            required={true}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {Array.from(Array(4).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index}>
                                    {index.toString()}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`GeraetePIN`}
                            component={SlTextInputComponent}
                            label={'Geräte-PIN'}
                            type={'number'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.devicePin]}
                        />
                    </MaterialUI.Grid>

                    {this.renderWandlerFeld()}
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Zaehlerversanddatum"
                            readonly={this.props.readonly}
                            label={'Zählerversanddatum'}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlDateInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlMultipleSelectInputComponent}
                            onChange={this.onChangeProducts}
                            label={'Produktauswahl'}
                            name={`ProduktIds`}
                            required={false}
                            options={{ fullWidth: true }}
                            // validate={[FormValidator.requiredArray]}
                        >
                            {this.props.productList
                                .filter(item => item.ebene === 'Gerät')
                                .map((product, index) => (
                                    <MaterialUI.MenuItem
                                        key={`${index}-${product.id}`}
                                        value={product.id}
                                    >
                                        <MaterialUI.Checkbox
                                            checked={
                                                this.state.selectedProducts.indexOf(product.id) > -1
                                            }
                                        />
                                        <MaterialUI.ListItemText
                                            primary={`${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`}
                                        />
                                    </MaterialUI.MenuItem>
                                ))}
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const DeviceUpdateComponent = MaterialUI.withStyles(styles)(DeviceUpdate);
const DeviceUpdateComponentReduxForm = reduxForm({
    form: 'SlimDeviceUpdateForm',
})(DeviceUpdateComponent);

const selector = formValueSelector('SlimDeviceUpdateForm');

const mapStateToProps = (state: IReduxState) => {
    const currentDeviceTyp = selector(state, 'Grundtyp');
    return {
        currentDeviceTyp,
    };
};
export const SlimDeviceUpdate = connect(mapStateToProps)(DeviceUpdateComponentReduxForm);
