import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlAlertComponent } from '../../../common/components/modal';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import { IKAB } from '../../../common/interfaces/interface';
import { deleteText, isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles, Salutation } from '../../../model/enums';

import { appStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        tableContainer: {
            height: 'calc(100vh - 220px)',
            // overflow: 'auto',
        },
    });

export interface IAllKabsComponentProps extends WithStyles<typeof styles> {
    customers: Array<IKAB>;
    onCellClick: (link: string) => void;
    addKab?: () => void;
    deleteKab?: (kabID: string) => void;
}

interface IState {
    openAlert: boolean;
}

class AllKabs extends React.Component<IAllKabsComponentProps, IState> {
    private readonly customerTableHeaderItems: Array<IMuiTableColumn>;
    private selectedKab: any = null;
    private deleteTextTitle: string = null;

    constructor(props: IAllKabsComponentProps) {
        super(props);
        this.state = { openAlert: false };

        this.customerTableHeaderItems = [
            {
                title: 'Kunden-Nr',
                field: 'ID',
                defaultSort: 'desc',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.clickOnKabId(rowData)}
                        >
                            {rowData.ID}
                        </MaterialUI.Typography>
                    );
                },
            },
            { title: 'Name / Firma', field: 'customerName' },
            { title: 'Ort', field: 'Ort' },
            { title: 'Anzahl Anlagen', field: 'AnzahlAnlagen' },
        ];
    }

    private clickOnKabId = item => {
        this.props.onCellClick(`/kabs/${item.ID}`);
    };
    private buildTableBodyItems = () => {
        return this.props.customers.map((item: IKAB) => {
            const name =
                item.Anrede.Typ === Salutation.Company
                    ? item.Anrede.Firma.Firmenname1
                    : `${item.Anrede.Person.Vorname}  ${item.Anrede.Person.Nachname}`;
            return {
                ID: item.ID,
                customerName: name,
                Ort: item.Adresse.Ort,
                AnzahlAnlagen: item.AnzahlAnlagen ? item.AnzahlAnlagen : 0,
            };
        });
    };

    private onClickDeleteKab = (event, rowData) => {
        const { tableData, ...rest } = rowData;
        this.selectedKab = rest;
        this.deleteTextTitle = `${this.selectedKab.customerName} Löschen`;
        this.setState({ openAlert: true });
    };
    private deleteKab = async () => {
        // get Kab
        this.props.deleteKab(this.selectedKab.ID);
        this.selectedKab = null;
        this.deleteTextTitle = '';
        this.setState({ openAlert: false });
    };

    public render() {
        return (
            <div style={appStyles.container} className={this.props.classes.tableContainer}>
                <BasicTable
                    columns={this.customerTableHeaderItems}
                    options={{
                        maxBodyHeight: 'calc(100vh - 200px)',
                        actionsColumnIndex: -1,
                    }}
                    data={this.buildTableBodyItems()}
                    actions={
                        isUserRole(Roles.Admin)
                            ? [
                                  {
                                      icon: () => (
                                          <MaterialUIIcons.DeleteOutline color={'secondary'} />
                                      ),
                                      tooltip: 'Löschen',
                                      onClick: this.onClickDeleteKab,
                                  },
                              ]
                            : []
                    }
                />
                <SlAlertComponent
                    dialogTitle={this.deleteTextTitle}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.openAlert}
                    handleOK={this.deleteKab}
                    handleCancel={() => this.setState({ openAlert: false })}
                >
                    {this.selectedKab && `${deleteText('die Daten')}`}
                </SlAlertComponent>
            </div>
        );
    }
}

export const SlCustomers = MaterialUI.withStyles(styles)(AllKabs);
