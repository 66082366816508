import * as React from 'react';
import { useState } from 'react';
import { TextField } from '@material-ui/core';
import { SLModal } from '../../../common/components/modal';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { SlimAuftragStatusEnum } from '../../../model/enums';

const SlimAuftragStatusForm = ({ open, onSave, onClose }) => {
    const [status, setStatus] = useState('');

    const handleChange = item => {
        // setStatus(item);
        setStatus(item.target.value as string);
    };
    return (
        <SLModal
            title={'Status ändern'}
            fullScreen={false}
            open={open}
            onOkButtonClick={() => onSave(status)}
            OkButtonDisabled={!status}
            onClose={onClose}
        >
            <MaterialUI.Grid
                container={true}
                direction={'row'}
                spacing={1}
                style={{ marginBottom: 20, marginTop: 20, width: '50%' }}
            >
                <MaterialUI.Grid item={true} xs={12}>
                    <TextField
                        select={true}
                        label="Status"
                        value={status}
                        onChange={handleChange}
                        variant="outlined"
                        style={{ width: '200px' }}
                    >
                        {Object.keys(SlimAuftragStatusEnum).map((item, index) => (
                            <MaterialUI.MenuItem
                                key={`${index}-${item}`}
                                value={item}
                                onClick={() => handleChange(item)}
                            >
                                {SlimAuftragStatusEnum[item]}
                            </MaterialUI.MenuItem>
                        ))}
                    </TextField>
                </MaterialUI.Grid>
            </MaterialUI.Grid>
        </SLModal>
    );
};

export default SlimAuftragStatusForm;
