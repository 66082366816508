export const kontaktpersonByKundenanlageAID = /* GraphQL */ `
    query KontaktpersonByKundenanlageAID(
        $AID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelKontaktpersonFilterInput
        $limit: Int
        $nextToken: String
    ) {
        kontaktpersonByKundenanlageAID(
            AID: $AID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                Rolle
                AndereRolle
                Anrede {
                    Typ
                    Firma {
                        Firmenname1
                        Firmenname2
                        Steuernummer
                        UmsatzSteuerID
                        Umsatzsteuerpflichtig
                    }
                    Person {
                        Nachname
                        Vorname
                        Geburtsdatum
                        Titel
                    }
                }
                Adresse {
                    Land
                    Ort
                    PLZ
                    HausNummer
                    Postfach
                    Strasse
                    Zusatzinformationen
                }
                Email
                Telefon
                Kommentar
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
