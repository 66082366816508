import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IAusbauauftragProps {
    color: any
}

class Ausbauauftrag extends React.Component<IAusbauauftragProps, {}> {
    public static defaultProps: Partial<IAusbauauftragProps> = {
        color: 'inherit'

    };




    public render() {
        return (
            <MaterialUI.SvgIcon style={{padding: 'inherit', paddingRight: '10px'}}>
                <path
                    d="M5.70117 7.77344H2.12695L1.32422 10H0.164062L3.42188 1.46875H4.40625L7.66992 10H6.51562L5.70117 7.77344ZM2.4668 6.84766H5.36719L3.91406
        2.85742L2.4668 6.84766ZM12.4922 9.37305C12.0703 9.86914 11.4512 10.1172 10.6348 10.1172C9.95898 10.1172 9.44336 9.92188 9.08789 9.53125C8.73633 
        9.13672 8.55859 8.55469 8.55469 7.78516V3.66016H9.63867V7.75586C9.63867 8.7168 10.0293 9.19727 10.8105 9.19727C11.6387 9.19727 12.1895 8.88867 
        12.4629 8.27148V3.66016H13.5469V10H12.5156L12.4922 9.37305ZM18.8906 8.31836C18.8906 8.02539 18.7793 7.79883 18.5566 7.63867C18.3379 7.47461 
        17.9531 7.33398 17.4023 7.2168C16.8555 7.09961 16.4199 6.95898 16.0957 6.79492C15.7754 6.63086 15.5371 6.43555 15.3809 6.20898C15.2285 
        5.98242 15.1523 5.71289 15.1523 5.40039C15.1523 4.88086 15.3711 4.44141 15.8086 4.08203C16.25 3.72266 16.8125 3.54297 17.4961 3.54297C18.2148 
        3.54297 18.7969 3.72852 19.2422 4.09961C19.6914 4.4707 19.916 4.94531 19.916 5.52344H18.8262C18.8262 5.22656 18.6992 4.9707 18.4453 4.75586C18.1953 
        4.54102 17.8789 4.43359 17.4961 4.43359C17.1016 4.43359 16.793 4.51953 16.5703 4.69141C16.3477 4.86328 16.2363 5.08789 16.2363 5.36523C16.2363 5.62695
         16.3398 5.82422 16.5469 5.95703C16.7539 6.08984 17.127 6.2168 17.666 6.33789C18.209 6.45898 18.6484 6.60352 18.9844 6.77148C19.3203 6.93945 19.5684 
         7.14258 19.7285 7.38086C19.8926 7.61523 19.9746 7.90234 19.9746 8.24219C19.9746 8.80859 19.748 9.26367 19.2949 9.60742C18.8418 9.94727 18.2539 10.1172 
         17.5312 10.1172C17.0234 10.1172 16.5742 10.0273 16.1836 9.84766C15.793 9.66797 15.4863 9.41797 15.2637 9.09766C15.0449 8.77344 14.9355 8.42383 14.9355 
         8.04883H16.0195C16.0391 8.41211 16.1836 8.70117 16.4531 8.91602C16.7266 9.12695 17.0859 9.23242 17.5312 9.23242C17.9414 9.23242 18.2695 9.15039 18.5156 
         8.98633C18.7656 8.81836 18.8906 8.5957 18.8906 8.31836ZM26.7539 6.90039C26.7539 7.86914 26.5312 8.64844 26.0859 9.23828C25.6406 9.82422 25.043 10.1172 
         24.293 10.1172C23.4922 10.1172 22.873 9.83398 22.4355 9.26758L22.3828 10H21.3867V1H22.4707V4.35742C22.9082 3.81445 23.5117 3.54297 24.2812 3.54297C25.0508 
         3.54297 25.6543 3.83398 26.0918 4.41602C26.5332 4.99805 26.7539 5.79492 26.7539 6.80664V6.90039ZM25.6699 6.77734C25.6699 6.03906 25.5273 5.46875 25.2422 
         5.06641C24.957 4.66406 24.5469 4.46289 24.0117 4.46289C23.2969 4.46289 22.7832 4.79492 22.4707 5.45898V8.20117C22.8027 8.86523 23.3203 9.19727 24.0234 
         9.19727C24.543 9.19727 24.9473 8.99609 25.2363 8.59375C25.5254 8.19141 25.6699 7.58594 25.6699 6.77734ZM32.0391 10C31.9766 9.875 31.9258 9.65234 31.8867 
         9.33203C31.3828 9.85547 30.7812 10.1172 30.082 10.1172C29.457 10.1172 28.9434 9.94141 28.541 9.58984C28.1426 9.23438 27.9434 8.78516 27.9434 8.24219C27.9434 
         7.58203 28.1934 7.07031 28.6934 6.70703C29.1973 6.33984 29.9043 6.15625 30.8145 6.15625H31.8691V5.6582C31.8691 5.2793 31.7559 4.97852 31.5293 4.75586C31.3027 
         4.5293 30.9688 4.41602 30.5273 4.41602C30.1406 4.41602 29.8164 4.51367 29.5547 4.70898C29.293 4.9043 29.1621 5.14062 29.1621 5.41797H28.0723C28.0723 5.10156 
         28.1836 4.79688 28.4062 4.50391C28.6328 4.20703 28.9375 3.97266 29.3203 3.80078C29.707 3.62891 30.1309 3.54297 30.5918 3.54297C31.3223 3.54297 31.8945 3.72656 
         32.3086 4.09375C32.7227 4.45703 32.9375 4.95898 32.9531 5.59961V8.51758C32.9531 9.09961 33.0273 9.5625 33.1758 9.90625V10H32.0391ZM30.2402 9.17383C30.5801 
         9.17383 30.9023 9.08594 31.207 8.91016C31.5117 8.73438 31.7324 8.50586 31.8691 8.22461V6.92383H31.0195C29.6914 6.92383 29.0273 7.3125 29.0273 8.08984C29.0273 
         8.42969 29.1406 8.69531 29.3672 8.88672C29.5938 9.07812 29.8848 9.17383 30.2402 9.17383ZM38.5664 9.37305C38.1445 9.86914 37.5254 10.1172 36.709 10.1172C36.0332 
         10.1172 35.5176 9.92188 35.1621 9.53125C34.8105 9.13672 34.6328 8.55469 34.6289 7.78516V3.66016H35.7129V7.75586C35.7129 8.7168 36.1035 9.19727 36.8848 
         9.19727C37.7129 9.19727 38.2637 8.88867 38.5371 8.27148V3.66016H39.6211V10H38.5898L38.5664 9.37305ZM45.1875 10C45.125 9.875 45.0742 9.65234 45.0352 
         9.33203C44.5312 9.85547 43.9297 10.1172 43.2305 10.1172C42.6055 10.1172 42.0918 9.94141 41.6895 9.58984C41.291 9.23438 41.0918 8.78516 41.0918 8.24219C41.0918 
         7.58203 41.3418 7.07031 41.8418 6.70703C42.3457 6.33984 43.0527 6.15625 43.9629 6.15625H45.0176V5.6582C45.0176 5.2793 44.9043 4.97852 44.6777 4.75586C44.4512 
         4.5293 44.1172 4.41602 43.6758 4.41602C43.2891 4.41602 42.9648 4.51367 42.7031 4.70898C42.4414 4.9043 42.3105 5.14062 42.3105 5.41797H41.2207C41.2207 5.10156 
         41.332 4.79688 41.5547 4.50391C41.7812 4.20703 42.0859 3.97266 42.4688 3.80078C42.8555 3.62891 43.2793 3.54297 43.7402 3.54297C44.4707 3.54297 45.043 3.72656 
         45.457 4.09375C45.8711 4.45703 46.0859 4.95898 46.1016 5.59961V8.51758C46.1016 9.09961 46.1758 9.5625 46.3242 9.90625V10H45.1875ZM43.3887 9.17383C43.7285 
         9.17383 44.0508 9.08594 44.3555 8.91016C44.6602 8.73438 44.8809 8.50586 45.0176 8.22461V6.92383H44.168C42.8398 6.92383 42.1758 7.3125 42.1758 8.08984C42.1758 
         8.42969 42.2891 8.69531 42.5156 8.88672C42.7422 9.07812 43.0332 9.17383 43.3887 9.17383ZM51.7148 9.37305C51.293 9.86914 50.6738 10.1172 49.8574 10.1172C49.1816 
         10.1172 48.666 9.92188 48.3105 9.53125C47.959 9.13672 47.7812 8.55469 47.7773 7.78516V3.66016H48.8613V7.75586C48.8613 8.7168 49.252 9.19727 50.0332 
         9.19727C50.8613 9.19727 51.4121 8.88867 51.6855 8.27148V3.66016H52.7695V10H51.7383L51.7148 9.37305ZM54.9551 10V4.49805H53.9531V3.66016H54.9551V3.00977C54.9551 
         2.33008 55.1367 1.80469 55.5 1.43359C55.8633 1.0625 56.377 0.876953 57.041 0.876953C57.291 0.876953 57.5391 0.910156 57.7852 0.976562L57.7266 1.85547C57.543 
         1.82031 57.3477 1.80273 57.1406 1.80273C56.7891 1.80273 56.5176 1.90625 56.3262 2.11328C56.1348 2.31641 56.0391 2.60938 56.0391 
         2.99219V3.66016H57.3926V4.49805H56.0391V10H54.9551ZM60.0645 2.125V3.66016H61.248V4.49805H60.0645V8.42969C60.0645 8.68359 60.1172 8.875 60.2227 
         9.00391C60.3281 9.12891 60.5078 9.19141 60.7617 9.19141C60.8867 9.19141 61.0586 9.16797 61.2773 9.12109V10C60.9922 10.0781 60.7148 10.1172 60.4453 
         10.1172C59.9609 10.1172 59.5957 9.9707 59.3496 9.67773C59.1035 9.38477 58.9805 8.96875 58.9805 8.42969V4.49805H57.8262V3.66016H58.9805V2.125H60.0645ZM65.584 
         4.63281C65.4199 4.60547 65.2422 4.5918 65.0508 4.5918C64.3398 4.5918 63.8574 4.89453 63.6035 5.5V10H62.5195V3.66016H63.5742L63.5918 4.39258C63.9473 3.82617 
         64.4512 3.54297 65.1035 3.54297C65.3145 3.54297 65.4746 3.57031 65.584 3.625V4.63281ZM70.2656 10C70.2031 9.875 70.1523 9.65234 70.1133 9.33203C69.6094 9.85547 
         69.0078 10.1172 68.3086 10.1172C67.6836 10.1172 67.1699 9.94141 66.7676 9.58984C66.3691 9.23438 66.1699 8.78516 66.1699 8.24219C66.1699 7.58203 
         66.4199 7.07031 66.9199 6.70703C67.4238 6.33984 68.1309 6.15625 69.041 6.15625H70.0957V5.6582C70.0957 5.2793 69.9824 4.97852 69.7559 4.75586C69.5293
          4.5293 69.1953 4.41602 68.7539 4.41602C68.3672 4.41602 68.043 4.51367 67.7812 4.70898C67.5195 4.9043 67.3887 5.14062 67.3887 5.41797H66.2988C66.2988 
          5.10156 66.4102 4.79688 66.6328 4.50391C66.8594 4.20703 67.1641 3.97266 67.5469 3.80078C67.9336 3.62891 68.3574 3.54297 68.8184 3.54297C69.5488 3.54297
           70.1211 3.72656 70.5352 4.09375C70.9492 4.45703 71.1641 4.95898 71.1797 5.59961V8.51758C71.1797 9.09961 71.2539 9.5625 71.4023 9.90625V10H70.2656ZM68.4668 
           9.17383C68.8066 9.17383 69.1289 9.08594 69.4336 8.91016C69.7383 8.73438 69.959 8.50586 70.0957 8.22461V6.92383H69.2461C67.918 6.92383 67.2539 7.3125 67.2539 
           8.08984C67.2539 8.42969 67.3672 8.69531 67.5938 8.88672C67.8203 9.07812 68.1113 9.17383 68.4668 9.17383ZM72.6211 6.77734C72.6211 5.78906 72.8496 5.00391
            73.3066 4.42188C73.7637 3.83594 74.3691 3.54297 75.123 3.54297C75.8965 3.54297 76.5 3.81641 76.9336 4.36328L76.9863 3.66016H77.9766V9.84766C77.9766 
            10.668 77.7324 11.3145 77.2441 11.7871C76.7598 12.2598 76.1074 12.4961 75.2871 12.4961C74.8301 12.4961 74.3828 12.3984 73.9453 12.2031C73.5078 12.0078 
            73.1738 11.7402 72.9434 11.4004L73.5059 10.75C73.9707 11.3242 74.5391 11.6113 75.2109 11.6113C75.7383 11.6113 76.1484 11.4629 76.4414 11.166C76.7383 
            10.8691 76.8867 10.4512 76.8867 9.91211V9.36719C76.4531 9.86719 75.8613 10.1172 75.1113 10.1172C74.3691 10.1172 73.7676 9.81836 73.3066 9.2207C72.8496 8.62305 72.6211 7.80859 72.6211 6.77734ZM73.7109 6.90039C73.7109 7.61523 73.8574 8.17773 74.1504 8.58789C74.4434 8.99414 74.8535 9.19727 75.3809 9.19727C76.0645 9.19727 76.5664 8.88672 76.8867 8.26562V5.37109C76.5547 4.76562 76.0566 4.46289 75.3926 4.46289C74.8652 4.46289 74.4531 4.66797 74.1562 5.07812C73.8594 5.48828 73.7109 6.0957 73.7109 6.90039Z"
                    transform="translate(33 4)"
                    fill={this.props.color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.165 16.835L15.6201 18.874C15.7266 18.958 15.8604 19 16 19C16.1396 19 16.2734 18.958 16.3799
        18.874L21.6465 15.7661C21.8604 15.647 22 15.416 22 15.1499V8.8501C22 8.58398 21.8604 8.35303 21.6465 8.23389L21.0908 7.90576C20.3848 8.61133 19.6182 9.37793 18.8359 10.1602C19.1582 10.7085 19.333 11.3447 19.333 12C19.333 12.9282 18.9824 13.8184 18.3574 14.4751C17.7314 15.1313 16.8838 15.5 16 15.5C15.3213 15.5 14.665 15.2827 14.1133 14.8853C13.3076 15.6909 12.6289 16.3706 12.165 16.835ZM12.8496 2.56445L10 0L0 9H3V13.5H4.5L6.5 11.5L5 10C5 10 9.33301 5.93799 12.8496 2.56445Z"
                    fill={this.props.color}
                />
                <path
                    d="M16 4.29153e-08C15.5 4.29153e-08 15 0.17 14.59 0.58C11.72 3.45 6 9.17 6 9.17L7.5 10.67L4 14.17H2L0 18.17L2 20.17L6 18.17V16.17L9.5 12.67L11 14.17C11 14.17 16.72 8.45 19.59 5.58C20.21 4.67 20.37 3.54 19.59 2.76L17.41 0.58C17 0.17 16.5 4.29153e-08 16 4.29153e-08ZM16 2.17L18 4.17L11 11.17L9 9.17L16 2.17Z"
                    transform="translate(1 1)"
                    fill={this.props.color}

                />
                <path
                    d="M0.221562 0H2H3.77844C3.90061 0 4 0.124365 4 0.277778C4 0.43226 3.9008 0.555555 3.77844 0.555555H2.00974H0.221562C0.0993904 0.555555 0 0.43119 0 0.277778C0 0.123296 0.0991968 0 0.221562 0Z"
                    transform="translate(23 3.22217)"
                    fill={this.props.color}

                />
                <path
                    d="M2 -0.25H0.221562C-0.0881121 -0.25 -0.25 0.0402445 -0.25 0.277778C-0.25 0.513718 -0.0883365 0.805555 0.221562 0.805555H2.00974H3.77844C4.08811 0.805555 4.25 0.515311 4.25 0.277778C4.25 0.0418375 4.08834 -0.25 3.77844 -0.25H2Z"
                    transform="translate(23 3.22217)"
                    stroke={this.props.color}
                    strokeWidth="0.5"
                />
            </MaterialUI.SvgIcon>
        );
    }


}

export const AusbauauftragIcon = (Ausbauauftrag);
