import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import { kundenanlageByAID } from './graphql-operation/kundenanlage.queries';
import { updateKundenanlage } from './graphql-operation/kundenanlage.mutations';
import { getIdTokenForSlimApi } from '../utils';

export const getKundenanlageByAID = async AID => {
    const lambdaAuthToken = await getIdTokenForSlimApi();
    const response = await (API.graphql({
        query: kundenanlageByAID,
        variables: { AID: AID },
        authMode: 'AWS_LAMBDA',
        authToken: lambdaAuthToken,
    }) as Promise<{
        data: { kundenanlageByAID: any };
    }>);

    return response.data.kundenanlageByAID.items[0];
};

export const updateSlimAnlage = async (data: any) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        const result = await API.graphql({
            query: updateKundenanlage,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: { input: payload },
        });
        return result['data'].updateKundenanlage;
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};
