import { SlimActionTypes } from './slimActions.types';

export const saveSlimCustomers = (customers: { items: any[]; nextToken?: any }) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_CUSTOMERS,
            payload: customers,
        });
};

export const saveSelectedSlimCustomers = (customer: any) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SELECTED_SLIM_CUSTOMER,
            payload: customer,
        });
};

export const saveSlimCustomerTab = (tab: number) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_CUSTOMERACTIVETAB,
            payload: tab,
        });
};

export const saveSlimKundenAnlagen = (items: any[]) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_KUNDENANLAGEN,
            payload: items,
        });
};

export const saveSelectedSlimKundenAnlage = (item: any) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_SELECTED_KUNDENANLAGE,
            payload: item,
        });
};

export const saveAnlageKontaktperson = (item: any[]) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_ANLAGE_KONTAKTPERSON,
            payload: item,
        });
};

export const saveAnlageActiveTab = (tab: number) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_ANLAGE_ACTIVETAB,
            payload: tab,
        });
};

export const saveSlimAnlageZaehlerplatz = (items: any[]) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_ANLAGE_ZAEHLERPLATZ,
            payload: items,
        });
};

export const saveSlimAnlageGeraete = (items: any[]) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_ANLAGE_Geraete,
            payload: items,
        });
};

export const saveSlimAnlageSensors = payload => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_ANLAGE_Sensors,
            payload: payload,
        });
};
export const saveRefreshSlimNotification = payload => {
    return {
        type: SlimActionTypes.SET_SLIM_REFRESH_NOTIFICATION,
        payload: payload,
    };
};

export const saveSlimCSV= (data:any[]) => {
    return dispatch =>
        dispatch({
            type: SlimActionTypes.SAVE_SLIM_CSV,
            payload: data,
        });
};

