import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent,
} from '../../../common/components/forms';
import { SlNumberInputComponent } from '../../../common/components/forms/number-input.component';

import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { germanDateToISODateFormat, inputDateDisplayFormat } from '../../../common/utils/Functions';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';
import { AblesegrundEnums } from '../../../model/constants';
import {SlimReadingType} from "../interfaces/slim.interface";

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {},
    });

 interface ISlimReadingEditProps extends WithStyles<typeof styles> {
    selectedReading: SlimReadingType;
    initialize?: (reading: any) => void;
    watchAblesegrund?: string;
}



class RegulierteReadingUpdate extends React.Component<ISlimReadingEditProps, any> {
    constructor(props: ISlimReadingEditProps) {
        super(props);

        this.props.initialize({
            ...this.props.selectedReading,
        });
        this.state = {};
    }

    public render() {
        return (
            <div style={{ padding: 15 }}>
                <MaterialUI.Grid container={true} spacing={4} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlSelectInputComponent}
                            label={'Ablesegrund'}
                            name={'Ablesegrund'}
                            options={{ fullWidth: true, shrink: true }}
                        >
                            {renderItemForSelectBox(AblesegrundEnums)}
                        </Field>
                    </MaterialUI.Grid>
                    {this.props.watchAblesegrund === 'Sonstige' && (
                        <MaterialUI.Grid xs={4} item={true}>
                            <Field
                                name={`AndereAblesegrund`}
                                component={SlTextInputComponent}
                                label={'Andere Bezeichnung'}
                                required={false}
                                disabled={false}
                                options={{ fullWidth: true }}
                                validate={[]}
                            />
                        </MaterialUI.Grid>
                    )}
                    <MaterialUI.Grid item={true} xs={4} />
                    <MaterialUI.Grid item={true} xs={8} className={this.props.classes.item}>
                        <Field
                            name="Ablesedatum"
                            label={'Ablesedatum'}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlDateInputComponent}
                            validate={[FormValidator.required]}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={8} className={this.props.classes.item}>
                        <SlNumberInputComponent
                            name={`Strombezugstand`}
                            label={`Zählerstand Strombezug 1.8.0/1.8.1 (${deTranslator.einheit.kilowatt})`}
                            required={false}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8} className={this.props.classes.item}>
                        <SlNumberInputComponent
                            name={`Einspeisungstand`}
                            label={`Zählerstand Einspeisung 2.8.0/2.8.1 (${deTranslator.einheit.kilowatt})`}
                            required={false}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const RegulatedReadingUpdateComponent = MaterialUI.withStyles(styles)(RegulierteReadingUpdate);
const ReadingUpdateComponentReduxForm = reduxForm({
    form: 'RegulatedReadingForm',
})(RegulatedReadingUpdateComponent);

const selector = formValueSelector('RegulatedReadingForm');

const mapStateToProps = (state: IReduxState) => {
    const watchAblesegrund = selector(state, 'Ablesegrund');
    return {
        watchAblesegrund,
    };
};
export const SlimRegulatedReadingUpdate = connect(mapStateToProps)(ReadingUpdateComponentReduxForm);
