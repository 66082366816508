import {
    Visibility,
    VisibilityOff,
    InfoOutlined,
    Check,
    Save,
    Delete,
    DeleteOutline,
    EditOutlined,
    RemoveCircleOutline,
    HighlightOff,
    Error,
    Edit,
    AddBox,
    Drafts,
    Search,
    Cancel,
    Close,
    ArrowBack,
    Menu,
    MoreVert,
    Person,
    ExitToApp,
    Add,
    ExpandMore,
    Store,
    KeyboardArrowRight,
    CloudDownload,
    CloudUpload,
    ExpandMoreSharp,
    BusinessCenter,
    AccountCircle,
    Email,
    CloseOutlined,
    HighlightOffOutlined,
    Settings,
    ArrowDownward,
    ArrowUpward,
    Clear,
    SaveAlt,
    FilterList,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Remove,
    ViewColumn,
} from '@material-ui/icons';

export const MaterialUIIcons = {
    Visibility: Visibility,
    VisibilityOff: VisibilityOff,
    InfoOutlined: InfoOutlined,
    Check: Check,
    Save: Save,
    Delete: Delete,
    RemoveCircleOutline: RemoveCircleOutline,
    HighlightOff: HighlightOff,
    Error: Error,
    Edit: Edit,
    AddBox: AddBox,
    Drafts: Drafts,
    Search: Search,
    Cancel: Cancel,
    Close: Close,
    ArrowBack: ArrowBack,
    Menu: Menu,
    MoreVert: MoreVert,
    Person: Person,
    ExitToApp: ExitToApp,
    Add: Add,
    ExpandMore: ExpandMore,
    Store: Store,
    CloudDownload: CloudDownload,
    KeyboardArrowRight: KeyboardArrowRight,
    CloudUpload: CloudUpload,
    ExpandMoreSharp: ExpandMoreSharp,
    BusinessCenter: BusinessCenter,
    AccountCircle: AccountCircle,
    Email: Email,
    CloseOutlined: CloseOutlined,
    HighlightOffOutlined: HighlightOffOutlined,
    Settings: Settings,
    ArrowDownward: ArrowDownward,
    ArrowUpward: ArrowUpward,
    DeleteOutline: DeleteOutline,
    EditOutlined: EditOutlined,
    Clear: Clear,
    SaveAlt: SaveAlt,
    FilterList: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    ChevronRight: ChevronRight,
    ChevronLeft: ChevronLeft,
    Remove: Remove,
    ViewColumn: ViewColumn,
};
