export const updateAblesung = /* GraphQL */ `
    mutation UpdateAblesung($input: UpdateAblesungInput!, $condition: ModelAblesungConditionInput) {
        updateAblesung(input: $input, condition: $condition) {
            id
            organisation_id
            geraet_id
            Seriennummer
            ZID
            ZaehlerTyp
            Ablesedatum
            ZaehlerstandHT
            Einspeisungstand
            Strombezugstand
            Ablesegrund
            AndereAblesegrund
            Status
            VerbrauchHT
            Unit
            KorrigierterVerbrauch
            Abschlagshoehe
            Metergrid {
                timestamp
                value
                project
                url
                id
                measuring_point
                obis_code
            }
            createdAt
            updatedAt
        }
    }
`;
export const createAblesung = /* GraphQL */ `
    mutation CreateAblesung($input: CreateAblesungInput!, $condition: ModelAblesungConditionInput) {
        createAblesung(input: $input, condition: $condition) {
            id
            organisation_id
            geraet_id
            Seriennummer
            ZID
            ZaehlerTyp
            Ablesedatum
            ZaehlerstandHT
            Einspeisungstand
            Strombezugstand
            Ablesegrund
            AndereAblesegrund
            Status
            VerbrauchHT
            Unit
            KorrigierterVerbrauch
            Abschlagshoehe
            Metergrid {
                timestamp
                value
                project
                url
                id
                measuring_point
                obis_code
            }
            createdAt
            updatedAt
        }
    }
`;
export const createMetergridAblesung = /* GraphQL */ `
    mutation CreateMetergridAblesung($input: MetergridAblesungInput) {
        createMetergridAblesung(input: $input) {
            timestamp
            value
            project
            url
            id
            measuring_point
            obis_code
        }
    }
`;
