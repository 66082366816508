import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import { createSensor, updateSensor } from './mutation';
import { sensorByKundenanlageId } from './query';
import { getIdTokenForSlimApi } from '../utils';

export const createSlimSensor = async sensor => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        return API.graphql({
            query: createSensor,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    ...sensor,
                },
            },
        });
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};
export const updateSlimSensor = async data => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        return API.graphql({
            query: updateSensor,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    ...payload,
                },
            },
        });
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};

export const listSlimSensor = async kundenanlageId => {
    const lambdaAuthToken = await getIdTokenForSlimApi();
    return API.graphql({
        query: sensorByKundenanlageId,
        authMode: 'AWS_LAMBDA',
        authToken: lambdaAuthToken,
        variables: { kundenanlage_id: kundenanlageId },
    }) as Promise<{
        data: { sensorByKundenanlageId: any };
    }>;
};
