export const getAppVersion = () => {
    const build = process.env.REACT_APP_BUILD_NUMBER
        ? process.env.REACT_APP_BUILD_NUMBER.split('_').slice(-1)[0]
        : '';
    if (process.env.REACT_APP_STAGE === 'dev') {
        return `${process.env.REACT_APP_VERSION}-alpha.${build}`;
    }
    if (process.env.REACT_APP_STAGE === 'test') {
        return `${process.env.REACT_APP_VERSION}-beta.${build}`;
    }
    if (process.env.REACT_APP_STAGE === 'prod') {
        return `${process.env.REACT_APP_VERSION} (${build})`;
    }
};