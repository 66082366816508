import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IHomeProps {
    color: any
    style?: any;

}

class Home extends React.Component<IHomeProps, {}> {




    public render() {
        return (
            <MaterialUI.SvgIcon style={this.props.style}>
                <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z"
                      fill={this.props.color}
                      fillOpacity={this.props.color.length > 0 ? '1' : '0.54'}/>
            </MaterialUI.SvgIcon>
        );
    }


}

export const HomeIcon = (Home);
