export enum AblesegrundTypEnum {
    Turnusablesung = 'Turnusablesung',
    Mieterwechsel = 'Mieterwechsel',
    Produktwechsel = 'Produktwechsel',
    Einbau = 'Einbau',
    Ausbau = 'Ausbau',
    Sonstige = 'Sonstige',
    Vertragsstart = 'Vertragsstart',
}
export const AblesegrundList = [
    { value: AblesegrundTypEnum.Turnusablesung, label: 'Turnusablesung' },
    { value: AblesegrundTypEnum.Mieterwechsel, label: 'Mieterwechsel' },
    { value: AblesegrundTypEnum.Produktwechsel, label: 'Produktwechsel' },
    { value: AblesegrundTypEnum.Einbau, label: 'Einbau' },
    { value: AblesegrundTypEnum.Ausbau, label: 'Ausbau' },
    { value: AblesegrundTypEnum.Vertragsstart, label: 'Vertragsstart' },
    { value: AblesegrundTypEnum.Sonstige, label: 'Sonstige' },
];

export const PAGES_LIMIT=100;
