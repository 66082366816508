import axios from 'axios';
import * as jwtDecode from 'jwt-decode';
import { CONSTANTS } from '../../../model/constants';

interface IToken {
    id_token: string;
    access_token: string;
    refresh_token: string;
    expires_in: string;
}

export interface IOauth {
    domain: string;
    redirectSignIn: string;
    clientId: string;
    redirectSignOut?: string;
    responseType: string;
    scope?: Array<string>;
}

export class TokenService {
    public static requestPending: boolean = false;

    public static signIn(oauth: IOauth) {
        const { domain, redirectSignIn, responseType, clientId } = oauth;

        localStorage.setItem(CONSTANTS.domain, domain); // save domain in local storage
        // The url of the  Hosted UI
        const url = `${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`;
        // Launch hosted UI
        window.location.assign(url);
    }

    public static getTokenFromLocalStorage() {
        return localStorage.getItem(CONSTANTS.idToken);
    }

    public static signOut() {
        localStorage.removeItem(CONSTANTS.idToken);
        localStorage.removeItem(CONSTANTS.tokenExpire);
        localStorage.removeItem(CONSTANTS.accessToken);
        localStorage.removeItem(CONSTANTS.domain);
        localStorage.removeItem(CONSTANTS.refreshToken);
    }

    public static saveToken(token: IToken) {
        if (token) {
            localStorage.setItem(CONSTANTS.idToken, token.id_token);
            localStorage.setItem(CONSTANTS.tokenExpire, token.expires_in);
            localStorage.setItem(CONSTANTS.accessToken, token.access_token);
            token.refresh_token &&
                localStorage.setItem(CONSTANTS.refreshToken, token.refresh_token);
        }
    }

    public static async fetchToken() {
        const currentUrl = new URLSearchParams(window.location.href.split('?')[1]);
        const code = currentUrl.get('code') ? currentUrl.get('code') : null;
        let response = null;
        if (code) {
            const body = {
                grant_type: 'authorization_code',
                clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
                domain: process.env.REACT_APP_COGNITO_DOMAIN,
                code: code,
                redirect_uri: `${window.location.protocol}//${window.location.host}/auth`,
            };
            const httpResponse = await axios.post(`${process.env.REACT_APP_API_URL}auth`, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            response =
                httpResponse.data && httpResponse.data.token ? httpResponse.data.token : null;
        }
        return response;
    }

    public static async refreshToken() {
        try {
            const body = {
                code: ' ',
                grant_type: 'refresh_token',
                clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
                domain: process.env.REACT_APP_COGNITO_DOMAIN,
                redirect_uri: `${window.location.protocol}//${window.location.host}/auth`,
                refresh_token: localStorage.getItem(CONSTANTS.refreshToken),
            };
            TokenService.requestPending = true;
            return await (
                await fetch(`${process.env.REACT_APP_API_URL}auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
            ).json();
        } catch (e) {
            console.error(e);
        }
    }

    public static isTokenExpired() {
        return jwtDecode(localStorage.getItem(CONSTANTS.idToken)).exp < Date.now() / 1000;
    }
}
