export const listWebinars = /* GraphQL */ `
    query ListWebinars($filter: ModelWebinarFilterInput, $limit: Int, $nextToken: String) {
        listWebinars(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                title
                subTitle
                content
                link
                publishedDate
                publishedTimeFrom
                publishedTimeTo
                status
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getWebinar = /* GraphQL */ `
    query GetWebinar($id: ID!) {
        getWebinar(id: $id) {
            id
            title
            subTitle
            content
            status
            link
            publishedDate
            publishedTimeFrom
            publishedTimeTo
            createdAt
            updatedAt
        }
    }
`;
