export enum Salutation {
    Company = 'Firma',
    Female = 'Frau',
    Male = 'Herr',
}

export enum SalutationNatural {
    Female = 'Frau',
    Male = 'Herr',
}

export enum Title {
    None = '',
    Professor = 'Prof.',
    Dr = 'Dr.',
    Prof_Dr = 'Prof. Dr.',
}

export enum Product {
    None = '',
    Produkt1 = 'Produkte-1',
    Produkt2 = 'Produkte-2',
    Produkt3 = 'Produkte-3',
}

export const PaymentPlan: Array<{ key: string; label: string }> = [
    {
        key: 'Abrechnung-2',
        label: 'Abrechnung Jahresende',
    },
    {
        key: 'Abrechnung-1',
        label: 'Abrechnung rollierend',
    },
];

export enum Montage {
    Stecktechnik = 'Stecktechnik',
    DreiPunkt = '3-Punkt',
}

export enum Country {
    BE = 'Belgien',
    BG = 'Bulgarien',
    DK = 'Dänemark',
    DE = 'Deutschland',
    EE = 'Estland',
    FI = 'Finnland',
    FR = 'Frankreich',
    GR = 'Griechenland',
    IE = 'Irland',
    IT = 'Italien',
    LV = 'Lettland',
    LT = 'Litauen',
    LU = 'Luxemburg',
    MT = 'Malta',
    NL = 'Niederlande',
    AT = 'Österreich',
    PL = 'Polen',
    PT = 'Portugal',
    RO = 'Rumänien',
    SE = 'Schweden',
    SK = 'Slovakei',
    SI = 'Slowenien',
    ES = 'Spanien',
    CZ = 'Tschechische Republik',
    HU = 'Ungarn',
    GB = 'Großbritannien',
    CY = 'Zypern',
}

export enum Grundtyp {
    ZaehlerET = 'Zähler-ET',
    ZaehlerDT = 'Zähler-DT',
    Router = 'Router',
    Wandler = 'Wandler',
    Verteiler = 'Verteiler',
}

export enum ContactPersonRolles {
    Ansprechpartner = 'Ansprechpartner der Anlage',
    Hausmeister = 'Hausmeister',
    Installateur = 'Installateur',
    AnsprechpartnerAblesung = 'Ansprechpartner der Ablesung',
}

export enum AbleseStatus {
    Plausibel = 'Plausibel',
    Unplausibel = 'Unplausibel',
    Ersatzwert = 'Ersatzwert',
}

export enum AbleseStatusUnplausibel {
    Plausibel = 'Plausibel',
    Unplausibel = 'Unplausibel',
    Freigegeben = 'Freigegeben',
}
export enum SlimKontaktRollen {
    AnsprechpartnerKA = 'AnsprechpartnerKA',
    Elektroinstallateur = 'Elektroinstallateur',
}
export const KontaktRollen = [
    {
        label: 'Ansprechpartner Kundenanlage',
        value: 'AnsprechpartnerKA',
    },
    {
        label: 'Ansprechpartner vor Ort',
        value: 'AnsprechpartnerVO',
    },
    { label: 'Elektroinstallateur', value: 'Elektroinstallateur' },
    {
        label: 'Netzkundenbetreuer',
        value: 'netzkundenbetreuer',
    },
    {
        label: 'Solateur',
        value: 'solateur',
    },
    { label: 'Sonstige', value: 'Sonstige' },
];

export enum Ablesegrund {
    Turnusablesung = 'Turnusablesung',
    Zwischenablesung = 'Zwischenablesung',
    Kontrollablesung = 'Kontrollablesung',
    Geraetewechsel = 'Geraetewechsel',
    Geraeteeinbau = 'Geraeteeinbau',
    Geraeteausbau = 'Geraeteausbau',
    Vertragswechsel = 'Vertragswechsel',
    Kundenwunsch = 'Kundenwunsch',
}

export enum HTTPStatusCodes {
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    PRECONDITION_FAILED = 412,
    INTERNAL_SERVER_ERROR = 500,
}

export enum AuftragType {
    Wechselauftrag = 'Gerätewechsel',
    Entstoerungsauftrag = 'Entstoerung',
    Einbauauftrag = 'Neueinbau',
    Ableseauftrag = 'Ablese',
    Ausbauauftrag = 'Stillegung',
}

export enum AuftragTypeLabel {
    Wechsel = 'Gerätewechsel',
    Entstoerung = 'Entstörung',
    Einbau = 'Neueinbau',
    Ablese = 'Ablese',
    Ausbau = 'Stillegung',
}

export enum DienstleistungTyp {
    Zusatzablesung = 'Zusatzablesung',
    Zusatzfahrt = 'Zusatzfahrt',
    Dienstleistung3 = 'Dienstleistung-3',
    Dienstleistung4 = 'Dienstleistung-4',
}

export enum Roles {
    SimpleUser = 'stromlux_user',
    SuperUser = 'stromlux_superuser',
    Admin = 'stromlux_admin',
}

export enum Emails {
    StromluxEmail = 'stromlux@enbw.com',
}

export enum GeraetTypEnum {
    MME60A = 'moderne Messeinrichtung (60 A)',
    Wandlerzaehler250A = 'Wandlerzähler (250A) inkl. Wandlersatz',
}

export enum SB_GeraetTyp_Enum {
    MME60A = 'Direktmessung',
    Wandlerzaehler250A = 'Wandlermessung',
}

export enum GeraetTypKey {
    MME60A = 'MME60A',
    Wandlerzaehler250A = 'Wandlerzaehler250A',
}

export enum SensorTechnologie {
    LoraWan = 'LoraWan',
}
export enum SensorTyp {
    Stromzaehler = 'Stromzaehler',
    Sensor = 'Sensor',
    Wasserzaehler = 'Wasserzaehler',
}

export enum NutzungstypEnum {
    Verbraucherzaehler = 'Verbraucherzähler',
    Uebergabezaehler = 'Uebergabezähler',
    Erzeugungszaehler = 'Erzeugungszähler',
    Zwischenzaehler = 'Zwischenzähler',
}

export enum WebinarStatusEnum {
    ACTIVE = 'eingeblendet',
    UN_ACTIVE = 'ausgeblendet ',
}

export enum SlimAuftragStatusEnum {
    BESTELLT = 'Bestellt',
    BEAUFTRAGT = 'Beauftragt',
    VERSENDET = 'Versendet',
    VERBAUT = 'Verbaut',
}
