import axios from 'axios';
import { addLoadingState, removeLoadingState, setError } from '../../../../common/redux/actions/global.action';
import { IReduxState } from '../../../../model/redux-state';

import { saveFacilityServices } from '../actions/facility-services.actions';
import { FacilityServicesActionTypes } from '../types/facility-services.action.types';

export const getFacilityServices = (kabID: string, anlageId: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${anlageId}/dienstleistungen`);
            dispatch(saveFacilityServices(response.data ? response.data : []));
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));

        } catch (error) {
            dispatch(saveFacilityServices([]));
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        }
    };
};

export const postFacilityService = (kabID: string, anlageId: string, payload: any) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
            await axios.post(`kabs/${kabID}/anlagen/${anlageId}/dienstleistungen`, payload);
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        } catch (error) {
            dispatch(setError(error));
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        }
    };
};

export const updateFacilityService = (kabID: string, anlageId: string, payload: any, id: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
            await axios.put(`kabs/${kabID}/anlagen/${anlageId}/dienstleistungen/${id}`, payload);
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));

        } catch (error) {
            dispatch(setError(error));
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        }
    };
};

export const removeFacilityService = (kabID: string, anlageId: string, id: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
            await axios.delete(`kabs/${kabID}/anlagen/${anlageId}/dienstleistungen/${id}`);
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        } catch (error) {
            dispatch(setError(error));
            dispatch(removeLoadingState(FacilityServicesActionTypes.FACILITY_SERVICES_IS_LOADING));
        }
    };
};



