import { ISearchSuggestion } from '../../../../common/interfaces/interface';
import { SearchActionTypes } from '../types/search.action.types';

export interface ISearchState {
    suggestions: ISearchSuggestion;
    suggestionsLoading: boolean;
}

const initialState: ISearchState = {
    suggestions: null,
    suggestionsLoading: false
};

export const searchReducer = (state: ISearchState = initialState, action: any): ISearchState => {
    switch (action.type) {
        case SearchActionTypes.SUGGESTION_LOADING:
            return Object.assign({}, state, {suggestionsLoading: action.payload});
        case SearchActionTypes.SAVE_SEARCH_SUGGESTIONS:
            return Object.assign({}, state, {suggestions: action.payload});
        default:
            return Object.assign({}, state);
    }
};