import { IContactData, IKAB } from '../../../../common/interfaces/interface';
import { CustomerActionTypes } from '../types/customer.action.types';

export const addedCustomerId = (id: string) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.ADDED_CUSTOMER_ID,
        payload: id
    });

};

export const saveSelectedCustomer = (customer: IKAB) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_SELECTED_CUSTOMER,
        payload: customer
    });

};

export const saveKabContactData = (contactData: Array<IContactData>) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_KAB_CONTACTDATA,
        payload: contactData
    });

};

export const saveSelectedKABContactPerson = (contactData: IContactData) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_SELECTED_KAB_CONTACTPERSON,
        payload: contactData
    });

};


export const saveCustomers = (customers: Array<IKAB>) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_CUSTOMERS,
        payload: customers
    });
};

export const saveCustomersOrders = (orders: Array<any>) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_ORDERS,
        payload: orders
    });
};
export const saveDashBoardActiveTab = (tab: number) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_DASHBOARD_ACTIVE_TAB,
        payload: tab
    });
};
export const saveCustomerActiveTab = (tab: number) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_CUSTOMER_ACTIVE_TAB,
        payload: tab
    });
};
export const saveReminders = (reminders: Array<any>) => {
    return dispatch => dispatch({
        type: CustomerActionTypes.SAVE_REMINDERS,
        payload: reminders
    });
};

















