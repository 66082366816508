import { IAbleseTyp, IDevice } from '../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../common/utils';
import {
    numberFormatter,
    stringToNumber,
    subtractStringAsNumber,
} from '../../../common/utils/Functions';
import { AbleseStatus } from '../../../model/enums';
import { IAblesungExt } from '../../../model/interfaces/IAblesungExt';

export const buildIAblesungExt = (
    devices: Array<IDevice>,
    addNew?: boolean
): Array<IAblesungExt> => {
    let result: Array<IAblesungExt> = [];
    if (addNew) {
        result = devices.map((device: IDevice) => {
            let lastReading = null;
            const Wandlerfaktor = device.Wandlerfaktor ? device.Wandlerfaktor : 1;

            if (device.Ablesungen && device.Ablesungen.length > 0) {
                lastReading = device.Ablesungen[0];
                lastReading.Ablesedatum = device.Ablesungen[0].Ablesedatum;
                lastReading.Status = device.Ablesungen[0].Status;
                lastReading.ZaehlerstandHT = device.Ablesungen[0].ZaehlerstandHT || '0';
            }
            return {
                GeraeteID: device.ID,
                Seriennummer: device.GeraeteID,
                lastReadDatum: lastReading
                    ? IsoformatToGermanDate(lastReading.Ablesedatum)
                    : IsoformatToGermanDate(device.Einbaudatum),
                lastReadHT: lastReading ? lastReading.ZaehlerstandHT : device.EinbaustandHT || '0',
                Ablesung: null,
                ZID: device.ZID,
                ZaehlwerkVKS: device.ZaehlwerkVKS,
                ZaehlwerkNKS: device.ZaehlwerkNKS,
                Wandlerfaktor: Wandlerfaktor,
            };
        });
    } else {
        result = devices.map((device: IDevice) => {
            const Wandlerfaktor = device.Wandlerfaktor ? device.Wandlerfaktor : 1;
            if (device.Ablesungen && device.Ablesungen.length > 0) {
                return {
                    GeraeteID: device.ID,
                    Seriennummer: device.GeraeteID,
                    lastReadDatum: device.Ablesungen[1]
                        ? IsoformatToGermanDate(device.Ablesungen[1].Ablesedatum)
                        : IsoformatToGermanDate(device.Einbaudatum),
                    lastReadHT: device.Ablesungen[1]
                        ? device.Ablesungen[1].ZaehlerstandHT
                        : device.EinbaustandHT || '0',
                    Ablesung: device.Ablesungen[0],
                    ZID: device.ZID,
                    ZaehlwerkVKS: device.ZaehlwerkVKS,
                    ZaehlwerkNKS: device.ZaehlwerkNKS,
                    Wandlerfaktor: Wandlerfaktor,
                };
            }
            return {
                GeraeteID: device.ID,
                Seriennummer: device.GeraeteID,
                lastReadDatum: IsoformatToGermanDate(device.Einbaudatum),
                lastReadHT: device.EinbaustandHT || '0',
                ZID: device.ZID,
                Ablesung: null,
                ZaehlwerkVKS: device.ZaehlwerkVKS,
                ZaehlwerkNKS: device.ZaehlwerkNKS,
                Wandlerfaktor: Wandlerfaktor,
            };
        });
    }
    return result;
};

export const buildTableDataAblesungen = (
    selectedDevice: IDevice,
    ableseTypeList: Array<IAbleseTyp>
) => {
    let result = [];
    const Wandlerfaktor = selectedDevice.Wandlerfaktor ? selectedDevice.Wandlerfaktor : 1;
    const einbauItem = {
        Ablesedatum: selectedDevice.Einbaudatum,
        ZaehlerstandHT: selectedDevice.EinbaustandHT ? selectedDevice.EinbaustandHT : '0',
        VerbrauchHT: '0',
        ZaehlerstandNT: selectedDevice.EinbaustandNT ? selectedDevice.EinbaustandNT : '0',
        VerbrauchNT: '0',
        Status: AbleseStatus.Plausibel,
        Ablesegrund: 'Einbau',
        Wandlerfaktor: selectedDevice.Wandlerfaktor || '',
    };
    const ausbauItem = {
        Ablesedatum: selectedDevice.Ausbaudatum ? selectedDevice.Ausbaudatum : '',
        ZaehlerstandHT: selectedDevice.AusbaustandHT ? selectedDevice.AusbaustandHT : '0',
        VerbrauchHT: '0',
        ZaehlerstandNT: selectedDevice.AusbaustandNT ? selectedDevice.AusbaustandNT : '0',
        VerbrauchNT: '0',
        Status: AbleseStatus.Plausibel,
        Ablesegrund: 'Ausbau',
        Wandlerfaktor: selectedDevice.Wandlerfaktor || '',
    };

    if (selectedDevice && selectedDevice.Ablesungen && selectedDevice.Ablesungen.length > 0) {
        const { Ablesungen } = selectedDevice;
        result = Ablesungen.map(item => {
            const ablesegrund = ableseTypeList.find(
                ablesetyp => ablesetyp.id === item.AblesegrundId
            );

            return {
                Ablesedatum: item.Ablesedatum,
                ZaehlerstandHT: item.ZaehlerstandHT ? item.ZaehlerstandHT : ' ',
                VerbrauchHT: item.VerbrauchHT
                    ? numberFormatter(stringToNumber(item.VerbrauchHT) * Wandlerfaktor, 2)
                    : ' ',
                Status: item.Status ? item.Status : '',
                Ablesegrund: ablesegrund ? ablesegrund.name : '',
                KorrigierterVerbrauch: item.KorrigierterVerbrauch || ' ',
                Wandlerfaktor: selectedDevice.Wandlerfaktor || '',
            };
        });

        const lastAblesung = Ablesungen[0];
        // calculates "verbrauch" of ausbauItem
        const tmp =
            stringToNumber(ausbauItem.ZaehlerstandHT) -
            stringToNumber(lastAblesung.ZaehlerstandHT || '0');
        ausbauItem.VerbrauchHT = numberFormatter(
            parseFloat(tmp.toFixed(selectedDevice.ZaehlwerkNKS)) * Wandlerfaktor
        );
        result.unshift(einbauItem);
        result.push(ausbauItem);
    } else {
        // calculates "verbrauch" of ausbauItem
        ausbauItem.VerbrauchHT = subtractStringAsNumber(
            ausbauItem.ZaehlerstandHT,
            einbauItem.ZaehlerstandHT,
            selectedDevice.ZaehlwerkNKS
        );
        result.push(einbauItem, ausbauItem);
    }
    return result.filter(data => data.Ablesedatum !== '');
};
