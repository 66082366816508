import { SlimActionTypes } from '../actions/slimActions.types';

export interface ISlimState {
    slimCustomers: { items: any[]; nextToken: any };
    selectedSlimCustomer: any;
    customerActiveTab: number;
    slimKundenAnlagen: any[];
    selectedSlimKundenAnlage: any;
    AnlageKontaktperson: { items: any[]; nextToken: any };
    anlageActiveTab: number;
    slimzaehlerplatzList: { items: any[]; nextToken: any };
    slimGeraete: { items: any[]; nextToken: any };
    slimSensors: { items: any[]; nextToken: any };
    refreshSlimNotification: boolean;
}

const initialState: ISlimState = {
    slimCustomers: { items: [], nextToken: null },
    selectedSlimCustomer: null,
    customerActiveTab: 0,
    slimKundenAnlagen: [],
    selectedSlimKundenAnlage: null,
    AnlageKontaktperson: { items: [], nextToken: null },
    anlageActiveTab: 0,
    slimzaehlerplatzList: { items: [], nextToken: null },
    slimGeraete: { items: [], nextToken: null },
    slimSensors: { items: [], nextToken: null },
    refreshSlimNotification: false,
};

export const slimReducer = (state: ISlimState = initialState, action: any): ISlimState => {
    switch (action.type) {
        case SlimActionTypes.SAVE_SLIM_CUSTOMERS:
            return Object.assign({}, state, { slimCustomers: action.payload });
        case SlimActionTypes.SAVE_SELECTED_SLIM_CUSTOMER:
            return Object.assign({}, state, { selectedSlimCustomer: action.payload });
        case SlimActionTypes.SAVE_CUSTOMERACTIVETAB:
            return Object.assign({}, state, { customerActiveTab: action.payload });
        case SlimActionTypes.SAVE_SLIM_KUNDENANLAGEN:
            return Object.assign({}, state, { slimKundenAnlagen: action.payload });
        case SlimActionTypes.SAVE_SLIM_SELECTED_KUNDENANLAGE:
            return Object.assign({}, state, { selectedSlimKundenAnlage: action.payload });
        case SlimActionTypes.SAVE_SLIM_ANLAGE_KONTAKTPERSON:
            return Object.assign({}, state, { AnlageKontaktperson: action.payload });
        case SlimActionTypes.SAVE_SLIM_ANLAGE_ACTIVETAB:
            return Object.assign({}, state, { anlageActiveTab: action.payload });
        case SlimActionTypes.SAVE_SLIM_ANLAGE_ZAEHLERPLATZ:
            return Object.assign({}, state, { slimzaehlerplatzList: action.payload });

        case SlimActionTypes.SAVE_SLIM_ANLAGE_Geraete:
            return Object.assign({}, state, { slimGeraete: action.payload });
        case SlimActionTypes.SAVE_SLIM_ANLAGE_Sensors:
            return Object.assign({}, state, { slimSensors: action.payload });
        case SlimActionTypes.SET_SLIM_REFRESH_NOTIFICATION:
            return Object.assign({}, state, { refreshSlimNotification: action.payload });
        default:
            return Object.assign({}, state);
    }
};
