import { IContactData } from '../../../../common/interfaces/interface';
import { ContactPersonActionTypes } from '../types/contactperson.action.types';

export const contactPersonLoadingStatus = flag => {
    return {
        type: ContactPersonActionTypes.CONTACTPERSON_IS_LOADING,
        payload: flag
    };
};

export const addedContactPersonId = (id) => {
    return dispatch => dispatch({
        type: ContactPersonActionTypes.ADDED_CONTACTPERSON_ID,
        payload: id
    });

};

export const saveSelectedContactPerson = (contactperson: IContactData) => {
    return dispatch => dispatch({
        type: ContactPersonActionTypes.SAVE_SELECTED_CONTACTPERSON,
        payload: contactperson
    });

};

export const saveContactPersons = (contactpersons: Array<IContactData>) => {
    return dispatch => dispatch({
        type: ContactPersonActionTypes.SAVE_CONTACTPERSONS,
        payload: contactpersons
    });
};


