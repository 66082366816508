import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import * as uuid from 'uuid';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlDateInputComponent, SlSelectInputComponent } from '../../../../common/components/forms';
import { SlMultipleSelectInputComponent } from '../../../../common/components/forms/select-input.component';

import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IDevice, IDeviceType, IProduct } from '../../../../common/interfaces/interface';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
} from '../../../../common/utils/Functions';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { IReduxState } from '../../../../model/redux-state';
import { SB_ZAEHLER_TYP } from '../../../../model/constants';
import { SlNumberInputComponent } from '../../../../common/components/forms/number-input.component';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30,
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        mediumInput: {},
        smallInput: {},
        date: {
            width: '30%',
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            overflow: 'hidden',
        },
        tinyInput: {
            width: '15%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
        responsiveContent: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                margin: 'auto',
                width: '70%',
            },
            [theme.breakpoints.up('lg')]: {
                margin: 'auto',
                width: '70%',
            },
        },
    });

export interface IDeviceEditProps extends WithStyles<typeof styles> {
    deviceTypeList: Array<IDeviceType>;
    readonly?: boolean;
    device?: IDevice;
    initialize: (device: any) => void;
    currentDeviceTyp?: string;
    productList: Array<IProduct>;
}

interface IState {
    selectedProducts: Array<string>;
    selectedDeviceTypes: string;
}

class DeviceEdit extends React.Component<IDeviceEditProps, IState> {
    constructor(props: IDeviceEditProps) {
        super(props);
        this.props.initialize(this.props.device);
        this.state = {
            selectedProducts: [...this.props.device.ProduktIds],
            selectedDeviceTypes: this.props.device.GrundtypId,
        };
    }

    private onChangeDeviceType = event => {
        this.setState({ selectedDeviceTypes: event.target.value });
    };
    private onChangeProducts = event => {
        this.setState({ selectedProducts: event.target.value });
    };

    public renderWandlerFeld = () => {
        if (
            this.props.device &&
            this.props.device.Grundtyp.grundTyp === SB_ZAEHLER_TYP.WANDLER_MESSUNG
        ) {
            return (
                <>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name={`WandlerID1`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 1'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name={`WandlerID2`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 2'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name={`WandlerID3`}
                            component={SlTextInputComponent}
                            label={'Wandler-ID 3'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            /*style={{width:'50px'}}*/
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <SlNumberInputComponent
                            name={`Wandlerfaktor`}
                            label={'Wandlerfaktor'}
                            required={false}
                        />
                    </MaterialUI.Grid>
                </>
            );
        }
        return null;
    };

    public render() {
        return (
            <div style={{ padding: 15 }}>
                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={'Grundtyp'}
                            name={`GrundtypId`}
                            required={true}
                            onChange={this.onChangeDeviceType}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {this.props.deviceTypeList.map((deviceType, index) => (
                                <MaterialUI.MenuItem
                                    key={`${index}-${deviceType.id}`}
                                    value={deviceType.id}
                                >
                                    <MaterialUI.ListItemText primary={deviceType.grundTyp} />
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Eichdatum"
                            readonly={this.props.readonly}
                            label={deTranslator.device.eichdate}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Einbaudatum"
                            readonly={this.props.readonly}
                            label={deTranslator.device.addDate}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlDateInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.required]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="EinbaustandHT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.addStateHT}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="EinbaustandNT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.addStateNT}
                            options={{ fullWidth: true, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            component={SlTextInputComponent}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="ObisCodeHT"
                            component={SlTextInputComponent}
                            label={deTranslator.device.obisCodeHT}
                            readonly={this.props.readonly}
                            className={`${this.props.classes.input} ${this.props.classes.tinyInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.obisCode]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="ObisCodeNT"
                            label={deTranslator.device.obisCodeNT}
                            readonly={this.props.readonly}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.obisCode]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Ausbaudatum"
                            label={deTranslator.device.removeDate}
                            readonly={this.props.readonly}
                            validate={[]}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            options={{ fullWidth: false, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="AusbaustandHT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.removeStateHT}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="AusbaustandNT"
                            readonly={this.props.readonly}
                            label={deTranslator.device.removeStateNT}
                            options={{ fullWidth: true, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            component={SlTextInputComponent}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={deTranslator.device.ZaehlwerkVKS}
                            name={`ZaehlwerkVKS`}
                            required={true}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {Array.from(Array(9).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index + 1}>
                                    {index + 1}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlSelectInputComponent}
                            label={deTranslator.device.ZaehlwerkNKS}
                            name={`ZaehlwerkNKS`}
                            required={true}
                            style={{ width: '100%' }}
                            validate={[FormValidator.required]}
                        >
                            {Array.from(Array(4).keys()).map((item, index) => (
                                <MaterialUI.MenuItem key={uuid.v4()} value={index}>
                                    {index.toString()}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>

                    {this.renderWandlerFeld()}

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`GeraetePIN`}
                            component={SlTextInputComponent}
                            label={'Geräte-PIN'}
                            type={'number'}
                            required={false}
                            disabled={false}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.devicePin]}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            component={SlMultipleSelectInputComponent}
                            onChange={this.onChangeProducts}
                            label={'Produktauswahl'}
                            name={`ProduktIds`}
                            required={true}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.requiredArray]}
                        >
                            {this.props.productList
                                .filter(item => item.ebene === 'Gerät')
                                .map((product, index) => (
                                    <MaterialUI.MenuItem
                                        key={`${index}-${product.id}`}
                                        value={product.id}
                                    >
                                        <MaterialUI.Checkbox
                                            checked={
                                                this.state.selectedProducts.indexOf(product.id) > -1
                                            }
                                        />
                                        <MaterialUI.ListItemText
                                            primary={`${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`}
                                        />
                                    </MaterialUI.MenuItem>
                                ))}
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const DeviceEditComponent = MaterialUI.withStyles(styles)(DeviceEdit);
const DeviceEditComponentReduxForm = reduxForm({
    form: 'DeviceEditForm',
})(DeviceEditComponent);

const selector = formValueSelector('DeviceEditForm');

const mapStateToProps = (state: IReduxState) => {
    const currentDeviceTyp = selector(state, 'Grundtyp');
    return {
        currentDeviceTyp,
    };
};
export const SlDeviceEdit = connect(mapStateToProps)(DeviceEditComponentReduxForm);
