import { IMeterPlace } from '../../../../common/interfaces/interface';
import { IMeter } from '../../../../model/interfaces/IMeter';
import { MeterPanelsActionTypes } from '../types/meterpanels.action.types';

export const meterPanelsLoadingStatus = flag => {
    return {
        type: MeterPanelsActionTypes.METERPANEL_IS_LOADING,
        payload: flag
    };
};

export const postMeterPanelsLoadingStatus = flag => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.POST_METERPANEL_LOADING_STATUS,
        payload: flag
    });
};

export const postAppendDevicesLoadingStatus = flag => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.POST_APPENDDEVICES_LOADING_STATUS,
        payload: flag
    });
};

export const addedMeterPanelsStartID = (id: string) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.ADDED_METERPANELS_START_ID,
        payload: id
    });
};

export const addedMeterPanelsNumber = meterPanelsNumber => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.ADDED_METERPANELS_NUMBER,
        payload: meterPanelsNumber
    });
};

export const saveMeterPanels = (meterPanels: Array<IMeterPlace>) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_METERPANELS,
        payload: meterPanels
    });
};

export const saveAllSelectedMeters = (meters: Array<IMeter>) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_ALL_SELECTED_METERS,
        payload: meters
    });
};
export const saveSelectedMeterPanels = (meterPanels: Array<IMeterPlace>) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_SELECTED_METERPANELS,
        payload: meterPanels
    });
};

export const saveMetersETDT = (meterPanels: Array<IMeter>) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_METERS_ETDT,
        payload: meterPanels
    });
};
export const saveSelectedMeter = (meterPanel) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_SELECTED_METER,
        payload: meterPanel
    });
};
export const saveSelectedMeterETDT = (meterPanel) => {
    return dispatch => dispatch({
        type: MeterPanelsActionTypes.SAVE_SELECTED_METER_ETDT,
        payload: meterPanel
    });
};



