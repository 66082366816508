export const listSlimNews = /* GraphQL */ `
    query ListSlimNews($filter: ModelSlimNewsFilterInput, $limit: Int, $nextToken: String) {
        listSlimNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                title
                description
                link
                linkType
                status
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
