import { IAuftrag } from '../../../../common/interfaces/interface';
import { AuftragActionTypes } from '../types/auftrag.types';

export interface IAuftragState {
    auftraege: Array<IAuftrag>;
    selectedAuftrag: IAuftrag;
}

const initialState: IAuftragState = {
    auftraege: [],
    selectedAuftrag: null,
};

export const auftragReducer = (state: IAuftragState = initialState, action: any): IAuftragState => {
    switch (action.type) {
        case AuftragActionTypes.SAVE_AUFTRAG:
            return Object.assign({}, state, {auftraege: [...action.payload]});
        default:
            return Object.assign({}, state);

    }
};