import { getFacilities } from '../../../facilities/redux/thunk/facility.operations';
import { getBillReceiver, getContactData, getCustomerById } from './customer.operations';


export const fetchCustomerData = (kabID) => {
    return async (dispatch) => {
        try {
            dispatch(getContactData(kabID));
            dispatch(getBillReceiver(kabID));
            dispatch(getFacilities(kabID));
            dispatch(getCustomerById(kabID));
        } catch (error) {
        }
    };
};

