import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid, isPristine } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../common/components/actionbar';
import { ISLActionBar } from '../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { EinbauauftragIcon } from '../../../common/components/svgIcons/Einbauauftrag';
import { GeraetezuordnenIcon } from '../../../common/components/svgIcons/Geraetezuordnen';
import { MaloMeloErgaenzenIcon } from '../../../common/components/svgIcons/MaloMeloErgaenzen';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import { IAuftrag, IDeviceType, IMeterPlace, IProduct } from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import {
    defaultSort,
    downloadFileFromBase64String,
    isUserRole,
    stringToNumber,
} from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import {
    AuftragType,
    GeraetTypEnum,
    NutzungstypEnum,
    Roles,
    SlimKontaktRollen,
} from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history, store } from '../../../store';
import { sbColors } from '../../../theme/app.colors';
import { appStyles } from '../../../theme/common.styles';
import { AuftragActions } from '../../auftrag/redux';
import { CustomerActions } from '../../customers/redux';
import { SlAuftragComponent } from '../../devices/components/form/slAuftragComponent';
import { DevicesActions } from '../../devices/redux';
import { SLMaloMeloContainerComponent } from '../../meterpanels/components/malomelo/MaloMeloContainer.component';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { AddSlimDeviceContainer } from '../forms/AddDevice.form';
import { SlimActions } from '../redux';
import { AblesegrundEnums } from '../../../model/constants';
import { AddSlimSensorContainer } from '../forms/addSensors.form';
import SlimAuftragStatusForm from '../forms/slimAuftragStatus.form';
import {
    createPanelAuftrag,
    downloadSlimAuftragZga,
    updateAuftrag,
} from '../slim-api/auftrag-api/slimAuftrag.api';
import { addLoadingState, removeLoadingState } from '../../../common/redux/actions/global.action';
import { saveRefreshSlimNotification } from '../redux/actions/slim.actions';
import { updateZaehlerplatzById } from '../slim-api/zaehlerplatz-api/zaehlerplatz.api';
import { slimAuftragEmailBody } from '../services/slim-auftrag.service';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

export interface ISlimMeterpanels extends WithStyles<typeof styles> {
    kabId: string;
    aId: string;
    selectedSlimKundenAnlage: any;
    zaehlerplatzList: any[];
    AnlageKontaktperson: any[];
    productList: Array<IProduct>;
    deviceTypeList: Array<IDeviceType>;
    slimKab: any;
    facilityEmail?: any;
    auftragForm?: IForm;
    appendDeviceForm?: IForm;
    maloMeloForm?: IForm;
    addSensorForm?: IForm;
    fetchSlimKundenAnlagenByAID: (AID: string) => void;
    fetchSlimKAZaehlerplatz: (AID: string) => void;
    getSlimDevices?: (AID: string) => void;
    postSlimAuftrag: (data: IAuftrag) => { ID: string };
    sendEmailSlimAuftrag: (payload: IAuftrag, auftragID: string, meterplaces?: Array<any>) => void;
    postSlimDevices: (kundenAnlage: any, zaehlerplatzList: any[], payload: any) => void;
    updateSlimMeterpanel: (payload: any[]) => void;
    postSlimSensors: (payload: any[]) => void;
    fetchSlimSensorByKundenanlageId: (id: string) => void;
}

interface IState {
    elevation: number;
    showOrderModal: boolean;
    meterPanelActionBarItems: Array<ISLActionBar>;
    openAppendDeviceModal: boolean;
    openMaloMeloModal: boolean;
    kontaktPersonWarning: boolean;
    sort: string;
    openAddSensorModal: boolean;
    auftragWarning: boolean;
    openAuftragStatusModal: boolean;
}
class SlimMeterpanelsComponent extends React.Component<ISlimMeterpanels, IState> {
    private tableActionBarItems: Array<ISLActionBar>;
    private orderType: string = '';
    private orderTitle: string = '';
    private selectedMeterPanels: any[] = [];
    private selectedRowItems: Array<any> = [];
    private auftragWarning: string = 'Die Montageart ist nicht bei allen Zählerplätzen identisch';

    constructor(props: ISlimMeterpanels) {
        super(props);
        this.tableActionBarItems = [
            {
                label: deTranslator.global.mountungDeviceOrder,
                action: () => this.handleSendZGAEmail(process.env.REACT_APP_EINBAU_EMAIL),
                icon: <EinbauauftragIcon color={sbColors.orange} />,
                disable: true,
            },
            {
                label: deTranslator.global.appendDevices,
                action: () => this.handleAppendDeviceModal(),
                icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                disable: true,
            },
            {
                label: 'SENSOREN ZUORDNEN',
                action: () => this.handleAppendSensorModal(),
                icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                disable: true,
            },
            {
                label: deTranslator.global.maloMelo,
                action: () => this.handleAddMaloMelo(),
                icon: <MaloMeloErgaenzenIcon color={sbColors.orange} />,
                disable: true,
            },
            {
                label: 'Versand',
                action: () => this.handleSendZGAEmail(process.env.REACT_APP_ZGA_EMAIL),
                icon: null,
                disable: true,
            },
            {
                label: 'Status ändern',
                action: () => this.handlePanelStatus(),
                icon: null,
                disable: true,
            },
            {
                label: 'ZGA herunterladen',
                action: () => this.downloadZGA(),
                icon: null,
                disable: true,
            },
        ];
        this.state = {
            elevation: 1,
            showOrderModal: false,
            meterPanelActionBarItems: this.tableActionBarItems,
            openAppendDeviceModal: false,
            openMaloMeloModal: false,
            kontaktPersonWarning: false,
            openAddSensorModal: false,
            auftragWarning: false,
            openAuftragStatusModal: false,
            sort: 'asc',
        };
    }
    private getSelectedMeterPanels = data => {
        return data.map(rowData => {
            const { tableData, ...rest } = rowData;
            return rest;
        });
    };
    private handleAppendDeviceModal = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ ...this.state, openAppendDeviceModal: true });
        }
    };
    private handleAppendSensorModal = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ ...this.state, openAddSensorModal: true });
        }
    };

    private handleMountingOrderModal = () => {
        const partners = this.props.AnlageKontaktperson.find(
            item => item.Rolle === SlimKontaktRollen.AnsprechpartnerKA
        );
        const installateurs = this.props.AnlageKontaktperson.find(
            item => item.Rolle === SlimKontaktRollen.Elektroinstallateur
        );
        if (partners && installateurs) {
            if (this.selectedRowItems.length > 0) {
                this.orderType = AuftragType.Einbauauftrag;
                this.orderTitle = deTranslator.global.mountungDeviceOrder;

                this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
                const montageart = this.selectedMeterPanels[0].Montageart;
                if (this.selectedMeterPanels.every(item => item.Montageart === montageart)) {
                    this.setState({ showOrderModal: true });
                } else {
                    // show warning
                    this.setState({ auftragWarning: true });
                }
            }
        } else {
            this.setState({ ...this.state, kontaktPersonWarning: true });
        }
    };
    private handleAddMaloMelo = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ ...this.state, openMaloMeloModal: true });
        }
    };

    private downloadZGA = async () => {
        // create auftrag
        store.dispatch(addLoadingState('ATTACHMENT_LOAD'));
        this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
        const payload = {
            auftrag_type: 'Neueinbau',
            status: 'BEAUFTRAGT',
            auftraggeber: {
                name: '',
                address: {
                    street: '',
                    streetNumber: '',
                    postalCode: '',
                    city: '',
                    country: 'DE',
                },
            },
            organisation_id: this.props.selectedSlimKundenAnlage.organisation_id,
            auftragdatum: new Date().toISOString(),
            kundenanlagebetreiber_id: this.props.slimKab.id,
            kundenanlage_id: this.props.selectedSlimKundenAnlage.id,
            zaehlerplatzIDs: this.selectedMeterPanels.map(item => item.id),
        };

        const createdAuftrag = await createPanelAuftrag(payload);
        // update all panel slimAuftragID

        await Promise.all(
            this.selectedMeterPanels.map(item =>
                updateZaehlerplatzById({ id: item.id, slimAuftragID: createdAuftrag.id })
            )
        );

        // download zga
        const attachment = await downloadSlimAuftragZga(createdAuftrag.id);
        store.dispatch(removeLoadingState('ATTACHMENT_LOAD'));
        // console.log('attachments: ', attachment); // data_base64

        downloadFileFromBase64String(
            `data:application/pdf;base64,${attachment.data_base64}`,
            attachment.filename + '.pdf',
            'pdf'
        );
    };

    private handleSendZGAEmail = async sender => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);

            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            /* const montageart = this.selectedMeterPanels[0].Montageart;
           if (this.selectedMeterPanels.every(item => item.Montageart === montageart)) {
               this.setState({ showOrderModal: true });
           } else {
               // show warning
               this.setState({ auftragWarning: true });
           } */

            // store.dispatch(addLoadingState('ATTACHMENT_LOAD'));

            const mailtoLink = `mailto:${sender}?cc=${
                process.env.REACT_APP_SUPPORT_EMAIL
            }&body=${slimAuftragEmailBody()}`;

            window.location.href = mailtoLink;
        }
    };
    private handlePanelStatus = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ ...this.state, openAuftragStatusModal: true });
        }
    };

    private buildTableActions = (active: boolean) => {
        const newState = this.tableActionBarItems.map(item => {
            item.disable = !active;
            return item;
        });
        return [...newState];
    };
    private handleCheckBoxClick = items => {
        const selectedIds = items.map(row => row.ZID);
        if (this.state.sort === 'asc') {
            this.selectedRowItems = this.props.zaehlerplatzList
                .filter(panel => selectedIds.includes(panel.ZID))
                .sort((rowA, rowB) => defaultSort(rowA['WohnungNr'], rowB['WohnungNr']));
        }
        if (this.state.sort === 'desc') {
            this.selectedRowItems = this.props.zaehlerplatzList
                .filter(panel => selectedIds.includes(panel.ZID))
                .sort((rowA, rowB) => defaultSort(rowB['WohnungNr'], rowA['WohnungNr']));
        }
        this.setState({
            ...this.state,
            meterPanelActionBarItems: this.buildTableActions(items.length > 0),
        });
    };
    private submitAppendDeviceForm = async () => {
        this.setState({
            ...this.state,
            openAppendDeviceModal: false,
        });

        const payload = this.props.appendDeviceForm.formValue.devices
            .filter(res => res.device.GeraeteID !== '')
            .map(item => {
                const { GrundtypId, ProduktIds, GeraeteID, EinbaustandHT, ...rest } = item.device;
                return {
                    KID: this.props.kabId,
                    AID: this.props.aId,
                    organisation_id: this.props.selectedSlimKundenAnlage.organisation_id,
                    Seriennummer: GeraeteID,
                    ZaehlerTyp: 'Mieterstromzaehler',
                    Grundtyp: this.props.deviceTypeList.find(
                        deviceType => deviceType.id === GrundtypId
                    ),
                    Produkts: this.props.productList.filter(
                        product => ProduktIds.indexOf(product.id) >= 0
                    ),
                    Ablesungen: [
                        {
                            Ablesegrund: AblesegrundEnums.Einbau,
                            Ablesedatum: item.device.Einbaudatum,
                            ZaehlerstandHT: stringToNumber(item.device.EinbaustandHT) || 0,
                        },
                    ],
                    ...rest,
                };
            });

        await this.props.postSlimDevices(
            this.props.selectedSlimKundenAnlage,
            this.props.zaehlerplatzList,
            payload
        );
        this.props.getSlimDevices(this.props.aId);
        this.props.fetchSlimKundenAnlagenByAID(this.props.aId);
    };

    private submitAddSensorForm = async () => {
        try {
            this.setState({
                ...this.state,
                openAddSensorModal: false,
            });
            const payload = this.props.addSensorForm.formValue.sensors.map(item => ({
                ...item.sensor,
                Produkts: this.props.productList.filter(
                    product => item.sensor.Produkts.indexOf(product.id) >= 0
                ),
                kundenanlagenbetreiber_id: this.props.slimKab.id,
                kundenanlage_id: this.props.selectedSlimKundenAnlage.id,
            }));

            await this.props.postSlimSensors(payload);
            // fetch all sensors
            await this.props.fetchSlimSensorByKundenanlageId(
                this.props.selectedSlimKundenAnlage.id
            );
        } catch (e) {
            console.error(e);
        }
    };
    public addMaloMelo = async () => {
        this.setState({
            ...this.state,
            openMaloMeloModal: false,
            meterPanelActionBarItems: this.buildTableActions(false),
        });
        const payload: Array<IMeterPlace> = this.props.maloMeloForm.formValue.Zaehlplaetze.map(
            item => {
                return { Malo: item.Malo, Melo: item.Melo, id: item.ID };
            }
        );

        await this.props.updateSlimMeterpanel(payload);
        await this.props.fetchSlimKundenAnlagenByAID(this.props.aId);
        this.props.fetchSlimKAZaehlerplatz(this.props.aId);
        this.selectedRowItems = [];
    };

    private doMeterPanelOrder = async () => {
        this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
        const auftrag: IAuftrag = {
            KID: this.props.kabId,
            AID: this.props.aId,
            Type: this.orderType,
            MeterplaceIds: this.selectedMeterPanels.map(item => item.ZID),
            ...this.props.auftragForm.formValue,
            Service: 'Slim',
        };

        this.setState({
            ...this.state,
            showOrderModal: false,
        });
        const { ID } = await this.props.postSlimAuftrag(auftrag);
        if (ID) {
            this.props.sendEmailSlimAuftrag(auftrag, ID, this.selectedMeterPanels);
        }
    };

    private updateAuftragStatus = async status => {
        this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
        this.setState({
            ...this.state,
            openAuftragStatusModal: false,
        });
        store.dispatch(addLoadingState('LOAD'));
        await Promise.all(
            this.selectedMeterPanels
                .filter(item => item.slimAuftrag)
                .map(panel =>
                    updateAuftrag(panel.slimAuftrag.id, {
                        status: status,
                        versanddatum: status === 'VERSENDET' ? new Date().toISOString() : '',
                    })
                )
        );
        store.dispatch(saveRefreshSlimNotification(true));
        store.dispatch(removeLoadingState('LOAD'));
    };

    private goToMeterPanel = data => {
        history.push(`/slim/kabs/${this.props.kabId}/${this.props.aId}/${data.ZID}`);
    };

    private zaehlerplatzTableColumns = (): IMuiTableColumn[] => {
        return [
            {
                title: 'Wohnungs-Nr',
                field: 'WohnungNr',
                defaultSort: 'asc',
                customSort: (rowA, rowB) => {
                    if (rowA && rowB) {
                        return defaultSort(rowA.WohnungNr, rowB.WohnungNr);
                    }
                    return 0;
                },
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToMeterPanel(rowData)}
                        >
                            {rowData.WohnungNr}
                        </MaterialUI.Typography>
                    );
                },
            },
            { title: 'Gebäude', field: 'Gebaeude' },
            { title: 'Stockwerk', field: 'Stockwerk' },
            { title: deTranslator.meterPanel.location, field: 'Lage' },
            { title: 'Montageart', field: 'Montageart' },
            {
                title: 'Gerätetyp',
                field: 'GeraetTyp',
                render: rowData => {
                    return rowData && rowData['GeraetTyp']
                        ? GeraetTypEnum[rowData['GeraetTyp']]
                        : '';
                },
            },
            {
                title: 'Nutzungstyp',
                field: 'Nutzungstyp',
                render: rowData => {
                    return rowData && rowData['Nutzungstyp']
                        ? NutzungstypEnum[rowData['Nutzungstyp']]
                        : '';
                },
            },
            {
                title: 'Status',
                field: 'Status',
                render: rowData => {
                    return rowData.slimAuftrag && rowData.slimAuftrag.status
                        ? rowData.slimAuftrag.status
                        : '';
                },
            },
            {
                title: 'Versanddatum',
                field: 'versanddatum',
                render: rowData => {
                    return rowData.slimAuftrag && rowData.slimAuftrag.versanddatum
                        ? rowData.slimAuftrag.versanddatum
                        : '';
                },
            },
        ];
    };
    private sortTableData = (orderedColumnId, orderDirection) => {
        const field = this.zaehlerplatzTableColumns()[orderedColumnId]['field'];
        if (orderDirection === 'asc') {
            this.props.zaehlerplatzList.sort((rowA, rowB) => defaultSort(rowA[field], rowB[field]));
            this.selectedRowItems.sort((rowA, rowB) => defaultSort(rowA[field], rowB[field]));
        }
        if (orderDirection === 'desc') {
            this.props.zaehlerplatzList.sort((rowA, rowB) => defaultSort(rowB[field], rowA[field]));
            this.selectedRowItems.sort((rowA, rowB) => defaultSort(rowB[field], rowA[field]));
        }
        this.setState({
            ...this.state,
            sort: orderDirection,
        });
    };

    public render() {
        return (
            <div style={{ overflow: 'auto', height: 'calc(100vh - 165px)' }}>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.meterPanelActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.zaehlerplatzTableColumns()}
                        options={{
                            paging: false,
                            pageSize: 10,
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 275px)',
                        }}
                        data={[...this.props.zaehlerplatzList]}
                        onSelectionChange={this.handleCheckBoxClick}
                        onOrderChange={this.sortTableData}
                    />
                </div>

                <SLModal
                    title={this.orderTitle}
                    open={this.state.showOrderModal}
                    okButtonText={deTranslator.global.sendOrder}
                    onOkButtonClick={this.doMeterPanelOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            showOrderModal: false,
                        })
                    }
                >
                    <SlAuftragComponent
                        orderType={this.orderType}
                        facilityEmail={this.props.facilityEmail}
                        auftragDateLabel={
                            AuftragType.Einbauauftrag === this.orderType ? 'Einbaudatum' : null
                        }
                    />
                </SLModal>
                <SLModal
                    title={deTranslator.global.appendDevices}
                    fullScreen={true}
                    open={this.state.openAppendDeviceModal}
                    onOkButtonClick={this.submitAppendDeviceForm}
                    OkButtonDisabled={
                        this.props.appendDeviceForm.invalid || this.props.appendDeviceForm.pristine
                    }
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openAppendDeviceModal: false,
                        })
                    }
                >
                    <AddSlimDeviceContainer
                        meterPanels={this.selectedMeterPanels}
                        deviceTypeList={this.props.deviceTypeList}
                        productList={this.props.productList}
                    />
                </SLModal>
                <SLModal
                    title={'Sensor zuordnen'}
                    fullScreen={true}
                    open={this.state.openAddSensorModal}
                    onOkButtonClick={this.submitAddSensorForm}
                    OkButtonDisabled={
                        this.props.addSensorForm.invalid || this.props.addSensorForm.pristine
                    }
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openAddSensorModal: false,
                        })
                    }
                >
                    <AddSlimSensorContainer
                        meterPanels={this.selectedMeterPanels}
                        productList={this.props.productList}
                    />
                </SLModal>
                <SLModal
                    title={deTranslator.global.maloMelo}
                    onOkButtonClick={this.addMaloMelo}
                    OkButtonDisabled={this.props.maloMeloForm.invalid}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openMaloMeloModal: false,
                        })
                    }
                    open={this.state.openMaloMeloModal}
                >
                    <SLMaloMeloContainerComponent
                        selectedMeterPanels={this.selectedMeterPanels.map(item => ({
                            ID: item.id,
                            WohnungNr: item.WohnungNr,
                            ZID: item.ZID,
                            GeraeteID:
                                item.geraeteList && item.geraeteList.items.length > 0
                                    ? item.geraeteList.items[0].Seriennummer
                                    : '',
                            Malo: item.Malo,
                            Melo: item.Melo,
                        }))}
                    />
                </SLModal>
                <SlimAuftragStatusForm
                    open={this.state.openAuftragStatusModal}
                    onSave={this.updateAuftragStatus}
                    onClose={() => this.setState({ ...this.state, openAuftragStatusModal: false })}
                />
                <SlAlertComponent
                    dialogTitle={'Achtung !'}
                    open={this.state.kontaktPersonWarning}
                    showCancelButton={false}
                    handleOK={() => this.setState({ ...this.state, kontaktPersonWarning: false })}
                >
                    Es muss einen Ansprechpartner und einen Installateur vorhanden sein
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={'Achtung !'}
                    open={this.state.auftragWarning}
                    showCancelButton={false}
                    handleOK={() => {
                        this.setState({ auftragWarning: false });
                        this.setState({ showOrderModal: true });
                    }}
                >
                    {this.auftragWarning}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        facilityEmail: state.facilityState.selectedFacilityEmail,
        productList: state.settingState.productManagementList,
        deviceTypeList: state.settingState.deviceTypeList,
        slimKab: state.slimState.selectedSlimCustomer,
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        appendDeviceForm: {
            formValue: getFormValues('AddSlimDeviceForm')(state),
            invalid: isInvalid('AddSlimDeviceForm')(state),
            pristine: isPristine('AddSlimDeviceForm')(state),
        },
        addSensorForm: {
            formValue: getFormValues('AddSlimSensorForm')(state),
            invalid: isInvalid('AddSlimSensorForm')(state),
            pristine: isPristine('AddSlimSensorForm')(state),
        },
        maloMeloForm: {
            formValue: getFormValues('MaloMeloForm')(state),
            invalid: isInvalid('MaloMeloForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            MeterPanelsActions,
            DevicesActions,
            CustomerActions,
            AuftragActions,
            SlimActions
        ),
        dispatch
    );
};

export const SlimMeterpanels = connect<ISlimMeterpanels, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<any>(MaterialUI.withStyles(styles)(SlimMeterpanelsComponent));
