import axios from 'axios';
import {
    IAuftrag,
    IAuftragPDF,
    IDevice,
    IMeterPlace,
} from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
    setError,
    setSnackbarStatus,
} from '../../../../common/redux/actions/global.action';
import { removeEmptykey } from '../../../../common/utils/Functions';
import { ContactPersonRolles } from '../../../../model/enums';
import { IReduxState } from '../../../../model/redux-state';
import { AuftragActionTypes } from '../types/auftrag.types';

export const postAuftrag = (kabID: string, aID: string, payload: IAuftrag) => {
    return async (dispatch, getState: () => IReduxState) => {
        dispatch(addLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
        try {
            const partners = getState().contactPersonState.contactPersons.filter(
                item =>
                    item.Rolle === ContactPersonRolles.Ansprechpartner ||
                    ContactPersonRolles.Hausmeister
            );
            const installateurs = getState().contactPersonState.contactPersons.filter(
                item => item.Rolle === ContactPersonRolles.Installateur
            );

            if (partners.length > 0 && installateurs.length > 0) {
                payload.KID = kabID;
                payload.AID = aID;
                const response = await axios.post(`auftrag`, removeEmptykey(payload));
                dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
                return response.data;
            } else {
                dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
                dispatch(
                    setError({
                        title: 'Achtung!',
                        message:
                            'Es muss einen Ansprechpartner und einen Installateur vorhanden sein',
                    })
                );
                return { ID: null };
            }
        } catch (error) {
            dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
        }
    };
};

export const sendEmailAuftrag = (
    payload: IAuftrag,
    auftragID: string,
    meterplaces: Array<IMeterPlace>,
    deviceMeterpanels: Array<{ device: IDevice; meterplace: IMeterPlace }>
) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            const partners = getState().contactPersonState.contactPersons.find(
                item => item.Rolle === ContactPersonRolles.Ansprechpartner
            );
            const installateurs = getState().contactPersonState.contactPersons.find(
                item => item.Rolle === ContactPersonRolles.Installateur
            );
            const { Produkte, ...facility } = getState().facilityState.selectedFacility;

            const kabContactPerson = getState().customerState.kabContactData[0];
            const auftragData: IAuftragPDF = {
                AuftragID: auftragID,
                KAB: getState().customerState.selectedCustomer,
                Email: payload.Email,
                Anlage: facility,
                Type: payload.Type,
                Ansprechpartner: partners,
                Installateur: installateurs,
                Bemerkung: payload.Bemerkung ? payload.Bemerkung : ' ',
                Wunschdatum: payload.Ausfuehrungsdatum ? payload.Ausfuehrungsdatum : ' ',
                Meterplaces: meterplaces,
                DeviceMeterplaces: deviceMeterpanels,
                KabContactPerson: kabContactPerson,
            };
            await axios.post(`auftrag/sendauftrag `, auftragData);
        } catch (error) {
            dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
        }
    };
};

export const sendAbleseAuftrag = (payload: any) => {
    return async (dispatch, getState: () => IReduxState) => {
        const { Adresse, ID } = getState().facilityState.selectedFacility;
        payload = Object.assign({}, payload, { facility: { Adresse: Adresse, ID: ID } });
        try {
            await axios.post(`auftrag/sendablese `, payload);
            dispatch(
                setSnackbarStatus({ flag: true, text: 'E-Mail wurde gesendet', type: 'success' })
            );
        } catch (error) {
            dispatch(removeLoadingState(AuftragActionTypes.AUFTRAG_LOADING_STATUS));
            dispatch(
                setSnackbarStatus({
                    flag: true,
                    text: 'E-Mail wurde nicht gesendet',
                    type: 'error',
                })
            );
        }
    };
};
