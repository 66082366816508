import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { WithStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { IReduxState } from '../../../model/redux-state';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalActions } from '../../../common/redux';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { DevicesActions } from '../../devices/redux';
import { CustomerActions } from '../../customers/redux';
import { AuftragActions } from '../../auftrag/redux';
import { SlimActions } from '../redux';
import { getFormValues, isInvalid, isPristine } from 'redux-form';
import { ISlimMeterpanels } from '../components/slimMeterpanels';
import {
    currencyFormatter,
    defaultSort,
    IsoformatToGermanDate,
    isUserRole,
} from '../../../common/utils/Functions';
import { Roles } from '../../../model/enums';
import { appStyles } from '../../../theme/common.styles';
import { BasicTable, IMuiTableColumn } from '../../../common/components/table/mui-table.component';
import { IProduct } from '../../../common/interfaces/interface';
import { IForm } from '../../../model/interfaces/IForm';
import { AddSlimSensorContainer } from '../forms/addSensors.form';
import { SLModal } from '../../../common/components/modal';
import { deTranslator } from '../../../assets/i18n/deTranslator';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

export interface ISlimSensors extends WithStyles<typeof styles> {
    kabId: string;
    aId: string;
    selectedSlimKundenAnlage: any;
    slimKab: any;
    productList: Array<IProduct>;
    sensorList: { items: any[]; nextToken: any };
    addSensorForm?: IForm;
    postSlimSensors: (payload: any[]) => void;
    fetchSlimSensorByKundenanlageId: (id: string) => void;
    updatedSlimSensors: (payload: any[]) => void;
}

interface IState {
    actionBarItems: Array<ISLActionBar>;
    openEditModal: boolean;
}

class SlimSensorsComponent extends React.Component<ISlimSensors, IState> {
    private selectedSensorsToEdit: any[] = [];
    constructor(props: ISlimSensors) {
        super(props);
        this.state = {
            openEditModal: false,
            actionBarItems: [
                {
                    label: deTranslator.global.edit,
                    icon: null,
                    action: () => this.setState({ ...this.state, openEditModal: true }),
                    disable: true,
                },
            ],
        };
    }

    private buildTableActions = (active: boolean) => {
        const newState = this.state.actionBarItems.map(item => {
            item.disable = !active;
            return item;
        });
        return [...newState];
    };

    private handleCheckBoxClick = items => {
        this.selectedSensorsToEdit = items.map(row => {
            const { tableData, Zaehlerplatz, updatedAt, createdAt, ...rest } = row;

            return {
                WohnungNr: Zaehlerplatz.WohnungNr,
                Gebaeude: Zaehlerplatz.Gebaeude,
                zaehlerplatz_id: Zaehlerplatz.id,
                sensor: {
                    ...rest,
                    Produkts: rest.Produkts.map(product => product.id),
                },
                Type: 'first',
            };
        });

        /*this.selectedRowItems = items.filter(row => {
            const ausbauStand = row.ablesungList.items.find(
                reading => reading.Ablesegrund === AblesegrundEnums.Ausbau
            );
            return !ausbauStand;
        }); */

        this.setState({
            ...this.state,
            actionBarItems: this.buildTableActions(items.length > 0),
        });
    };

    private submitUpdateSensorForm = async () => {
        try {
            this.setState({
                ...this.state,
                openEditModal: false,
            });
            const payload = this.props.addSensorForm.formValue.sensors.map(item => ({
                ...item.sensor,
                Produkts: this.props.productList.filter(
                    product => item.sensor.Produkts.indexOf(product.id) >= 0
                ),
            }));
            await this.props.updatedSlimSensors(payload);
            // fetch all sensors
            await this.props.fetchSlimSensorByKundenanlageId(
                this.props.selectedSlimKundenAnlage.id
            );
        } catch (e) {
            console.error(e);
        }
    };

    private sensorsTableColumns = (): IMuiTableColumn[] => {
        return [
            {
                title: 'SensorID',
                field: 'Seriennummer',
                render: rowData => {
                    return rowData.Seriennummer;
                },
            },
            {
                title: 'Wohnungs-NR',
                field: 'WohnungNr',
                defaultSort: 'asc',
                customSort: (meterA, meterB) => {
                    if (meterA && meterB) {
                        return defaultSort(
                            meterA.Zaehlerplatz.WohnungNr,
                            meterB.Zaehlerplatz.WohnungNr
                        );
                    }
                    return 0;
                },
                render: rowData => {
                    return rowData && rowData.Zaehlerplatz ? rowData.Zaehlerplatz.WohnungNr : '';
                },
            },
            {
                title: 'Gebäude',
                field: 'Gebaeude',
                render: rowData => {
                    return rowData && rowData.Zaehlerplatz ? rowData.Zaehlerplatz.Gebaeude : '';
                },
            },
            {
                title: 'Versanddatum',
                field: 'Versanddatum',
                render: rowData => {
                    return rowData && rowData.Einbaudatum
                        ? IsoformatToGermanDate(rowData.Versanddatum)
                        : '-';
                },
            },

            {
                title: 'Einbaudatum',
                field: 'Einbaudatum',
                render: rowData => {
                    return rowData && rowData.Einbaudatum
                        ? IsoformatToGermanDate(rowData.Einbaudatum)
                        : '-';
                },
            },
            {
                title: 'Ausbaudatum',
                field: 'Ausbaudatum',
                render: rowData => {
                    return rowData && rowData.Ausbaudatum
                        ? IsoformatToGermanDate(rowData.Ausbaudatum)
                        : '-';
                },
            },
            {
                title: 'Technologie',
                field: 'Technologie',
                render: rowData => {
                    return rowData && rowData.Technologie ? rowData.Technologie : '-';
                },
            },
            {
                title: 'Produktauswahl',
                field: 'Produkt',
                render: rowData => {
                    return rowData && rowData.Produkts
                        ? rowData.Produkts.map(item => (
                              <li key={item.id}>
                                  {`${item.name} (${currencyFormatter(item['preis'] / 100)})`}
                              </li>
                          ))
                        : '';
                },
            },
            {
                title: 'ElementIotId',
                field: 'elementIot_id',
            },
        ];
    };
    public render() {
        return (
            <div style={{ overflow: 'auto', height: 'calc(100vh - 165px)' }}>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.actionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.sensorsTableColumns()}
                        options={{
                            paging: false,
                            pageSize: 10,
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 275px)',
                        }}
                        data={[...this.props.sensorList.items]}
                        onSelectionChange={this.handleCheckBoxClick}
                    />
                </div>

                <SLModal
                    title={'Sensor zuordnen'}
                    fullScreen={true}
                    open={this.state.openEditModal}
                    onOkButtonClick={this.submitUpdateSensorForm}
                    OkButtonDisabled={
                        this.props.addSensorForm.invalid || this.props.addSensorForm.pristine
                    }
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openEditModal: false,
                        })
                    }
                >
                    <AddSlimSensorContainer
                        productList={this.props.productList}
                        defaultSensors={this.selectedSensorsToEdit}
                        editMode={true}
                    />
                </SLModal>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        productList: state.settingState.productManagementList,
        slimKab: state.slimState.selectedSlimCustomer,
        sensorList: state.slimState.slimSensors,
        addSensorForm: {
            formValue: getFormValues('AddSlimSensorForm')(state),
            invalid: isInvalid('AddSlimSensorForm')(state),
            pristine: isPristine('AddSlimSensorForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            MeterPanelsActions,
            DevicesActions,
            CustomerActions,
            AuftragActions,
            SlimActions
        ),
        dispatch
    );
};

export const SlimSensorspanels = connect<ISlimMeterpanels, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<any>(MaterialUI.withStyles(styles)(SlimSensorsComponent));
