import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

const styles = (theme: Theme) => MaterialUI.createStyles({
    padding: {
        padding: `0 ${theme.spacing(2)}px`,
    },
});

export interface ISlTab {
    label: any;
    icon?: any;
    badge?: number;
    key?:string;
}

export interface ISlTabsProps extends WithStyles<typeof styles> {
    tabs: Array<ISlTab>;
    selectedTab: number;
    onTabClick?: (tab: number) => void;
}


class SlTabsComponent extends React.Component<ISlTabsProps> {

    public static defaultProps: Partial<ISlTabsProps> = {
        tabs: [],
    };


    private handleChange = (event, value) => {
        this.props.onTabClick(value);
    };

    private renderTab(tab: ISlTab, index?: number) {
        //  const badgeColor = this.props.tabs[this.props.selectedTab].label !== tab.label ? 'primary' : 'secondary';
        if (tab.badge) {
            return (<MaterialUI.Tab icon={tab.icon}
                                    key={`sl-tab-${index}`}
                                    data-testid='tab-item'
                                    label={`${tab.label} (${tab.badge})`}

            />);
        }
        return <MaterialUI.Tab key={`sl-tab-${index}`} icon={tab.icon} label={tab.label}/>;


    }

    public render() {
        return (
            <>
                <MaterialUI.Tabs
                    value={this.props.selectedTab}
                    onChange={this.handleChange}
                    variant={'fullWidth'}
                    centered={true}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    {this.props.tabs.map((tab, index) => this.renderTab(tab, index))}
                </MaterialUI.Tabs>
            </>
        );
    }


}

export const SlTabs = MaterialUI.withStyles(styles)(SlTabsComponent);
