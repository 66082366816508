import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid, reset } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { EinbauauftragIcon } from '../../../common/components/svgIcons/Einbauauftrag';
import { GeraetezuordnenIcon } from '../../../common/components/svgIcons/Geraetezuordnen';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import {
    IAbleseTyp,
    IAuftrag,
    IDevice,
    IDeviceType,
    IMeterPlace,
    IProduct,
} from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError } from '../../../common/redux/reducers/global.reducer';
import { IsoformatToGermanDate, isNullOrUndefined } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

import { AuftragType, Roles } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history } from '../../../store';
import { sbColors } from '../../../theme/app.colors';
import { AuftragActions } from '../../auftrag/redux';
import { SlAuftragComponent } from '../../devices/components/form/slAuftragComponent';
import { DevicesActions } from '../../devices/redux';
import { SettingsActions } from '../../settings/redux';
import { SlAppendDeviceContainerComponent } from '../components/append-devices/appendDeviceContainer.component';
import { SLMeterPanelInfo } from '../components/meterpanel-info/meterpanel-info.component';
import { SLMeterPanelEditComponent } from '../components/meterpanel-input/meterPanelEdit.component';
import { MeterPanelsActions } from '../redux';
import { IMeterPanelState } from '../redux/reducers/meterpanels.reducer';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },

        marginRight: {
            marginRight: 24,
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 20,
            width: '100%',
            overflow: 'hidden',
        },

        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        tabContainer: {
            height: '100vh',
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 260px)',
            paddingTop: 24,
            paddingBottom: 24,
            paddingRight: 40,
            paddingLeft: 40,
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },

        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        smallInput: {
            width: '30%',
        },
        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
        inputInvisible: {
            marginTop: 0,
            padding: 10,
            width: '100%',
            height: 20,
        },
        block: {
            marginTop: 10,
        },
    });

interface IKabMeterPanelDetailsProps extends WithStyles<typeof styles> {
    meterPanelState: IMeterPanelState;
    match: any;
    location: any;
    setError: (error: IError) => void;
    error: IError;
    fetchMeterPanelData: (kabID: string, aID: string, zID: string) => void;
    auftragForm?: IForm;
    maloMeloForm?: IForm;
    appendDeviceForm?: IForm;
    meterPanelForm?: IForm;
    facilityEmail?: any;
    loadingList: Array<string>;
    ableseTypeList?: Array<IAbleseTyp>;
    updateMeterPanels?: (kabID: string, aID: string, payload: Array<IMeterPlace>) => void;
    addDevices?: (devices) => void;
    postDevices: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    postAuftrag?: (kabID: string, aID: string, payload: any) => { ID: string };
    sendEmailAuftrag?: (
        payload: IAuftrag,
        auftragId: string,
        meterplaces: Array<IMeterPlace>,
        devices: Array<IDevice>
    ) => void;
    getMeterPanels?: (kabID: string, aID: string) => void;
    getDevices: (kabId: string, anlageId: string) => void;
    deviceTypeList: Array<IDeviceType>;
    productList: Array<IProduct>;
    getDeviceTypeList?: () => void;
    getProductList?: () => void;
}

interface IState {
    openOrderModal: boolean;
    openMaloMeloModal: boolean;
    openAppendDeviceModal: boolean;
    openMeterPanelModal: boolean;
}

class KabMeterPanelDetails extends React.Component<IKabMeterPanelDetailsProps, IState> {
    private kabID: string = null;
    private aID: string = null;
    private zID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;
    private deviceInfo: Array<IMuiTableColumn> = [
        {
            title: 'Geräte-ID',
            field: 'GeraeteID',
            render: rowData => {
                return (
                    <MaterialUI.Typography
                        style={basicTableStyles.underlineText}
                        color={'secondary'}
                        onClick={() => this.goToRelativeLocation(rowData.ID)}
                    >
                        {rowData.GeraeteID}
                    </MaterialUI.Typography>
                );
            },
        },
        { title: 'WHGs-Nr', field: 'WohnungNr' },
        { title: 'Grundtyp', field: 'Grundtyp' },
        {
            title: 'Einbaudatum',
            field: 'Einbaudatum',
            defaultSort: 'desc',
            render: rowData => IsoformatToGermanDate(rowData.Einbaudatum),
        },
        { title: 'Ausbaudatum', field: 'Ausbaudatum' },
        { title: 'Ablauf Eichgültigkeit', field: 'Eichdatum' },
    ];

    private orderType: AuftragType;
    private orderTitle: string;

    constructor(props: IKabMeterPanelDetailsProps) {
        super(props);
        this.kabID = props.match.params.kabID;
        this.aID = props.match.params.aID;
        this.zID = props.match.params.zID;
        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ openMeterPanelModal: true }),
            },
            {
                label: deTranslator.global.mountungDeviceOrder,
                icon: <EinbauauftragIcon color={sbColors.orange} />,
                action: () => this.handleMountingOrderModal(),
            },
            {
                label: deTranslator.global.appendDevices,
                icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                action: () => this.handleAppendDeviceModal(),
            },
        ];
        this.state = {
            openOrderModal: false,
            openMaloMeloModal: false,
            openAppendDeviceModal: false,
            openMeterPanelModal: false,
        };
    }

    public async componentDidMount() {
        this.props.getProductList();
        this.props.getDeviceTypeList();
        await this.props.fetchMeterPanelData(this.kabID, this.aID, this.zID);
    }

    public async componentDidUpdate(prevProps) {
        if (
            this.props.match.params.kabID !== prevProps.match.params.kabID ||
            this.props.match.params.aID !== prevProps.match.params.aID ||
            this.props.match.params.zID !== prevProps.match.params.zID
        ) {
            this.kabID = this.props.match.params.kabID;
            this.aID = this.props.match.params.aID;
            this.zID = this.props.match.params.zID;
            // fetch bodyData from server
            await this.props.fetchMeterPanelData(this.kabID, this.aID, this.zID);
        }
    }

    public renderContent = () => {
        if (this.props.meterPanelState.selectedMeterPanel) {
            return this.renderMasterData();
        }
        return null;
    };

    private goToRelativeLocation = (deviceId: string) => {
        history.push(`/kabs/${this.kabID}/${this.aID}/${this.zID}/${deviceId}`);
    };

    private handleUpdateMeterPanel = async () => {
        this.setState({ openMeterPanelModal: false });
        await this.props.updateMeterPanels(this.kabID, this.aID, [
            this.props.meterPanelForm.formValue,
        ]);
        await this.props.fetchMeterPanelData(this.kabID, this.aID, this.zID);
    };

    private handleAppendDeviceModal = () => {
        this.setState({ openAppendDeviceModal: true });
    };

    private handleMountingOrderModal = () => {
        this.orderType = AuftragType.Einbauauftrag;
        this.orderTitle = deTranslator.global.mountungDeviceOrder;
        this.setState({ openOrderModal: true });
    };

    private doMeterPanelOrder = async () => {
        const auftrag: any = Object.assign(
            {},
            {
                Type: this.orderType,
                MeterplaceIds: [this.props.meterPanelState.selectedMeterPanel.ID],
            },
            { ...this.props.auftragForm.formValue }
        );

        const { ID } = await this.props.postAuftrag(this.kabID, this.aID, auftrag);
        this.setState({ openOrderModal: false });
        ID &&
            this.props.sendEmailAuftrag(
                auftrag,
                ID,
                [this.props.meterPanelState.selectedMeterPanel],
                []
            );
    };

    private handleAddDevices = async () => {
        const payload = this.props.appendDeviceForm.formValue.devices.map(item => item.device);
        await this.props.postDevices(this.kabID, this.aID, payload);
        this.props.fetchMeterPanelData(this.kabID, this.aID, this.zID);
        this.props.getDevices(this.kabID, this.aID);
        this.props.getMeterPanels(this.kabID, this.aID);
        this.setState({ openAppendDeviceModal: false });
    };

    private buildTableDataGeraete = () => {
        const { selectedMeterPanel } = this.props.meterPanelState;
        if (selectedMeterPanel.Geraete) {
            // sort by Einbaudatum
            const sortedDevices = selectedMeterPanel.Geraete.sort((deviceA, deviceB) => {
                return (
                    new Date(deviceB.Einbaudatum).getTime() -
                    new Date(deviceA.Einbaudatum).getTime()
                );
            });
            return sortedDevices.map((geraet: IDevice) => {
                return {
                    ID: geraet.ID,
                    GeraeteID: geraet.GeraeteID,
                    WohnungNr: selectedMeterPanel.WohnungNr,
                    Grundtyp: geraet.Grundtyp,
                    Einbaudatum: geraet.Einbaudatum,
                    Ausbaudatum: geraet.Ausbaudatum
                        ? IsoformatToGermanDate(geraet.Ausbaudatum)
                        : '-',
                    Eichdatum: geraet.Eichdatum,
                };
            });
        }
        return [];
    };

    private handleDissmissError = () => {
        this.props.setError(null);
    };

    private renderModals() {
        return (
            <div>
                <SLModal
                    title={this.orderTitle}
                    open={this.state.openOrderModal}
                    onOkButtonClick={this.doMeterPanelOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    onClose={() => this.setState({ openOrderModal: false })}
                >
                    <SlAuftragComponent
                        facilityEmail={this.props.facilityEmail}
                        orderType={this.orderType}
                        auftragDateLabel={
                            AuftragType.Einbauauftrag === this.orderType ? 'Einbaudatum' : null
                        }
                    />
                </SLModal>
                <SLModal
                    title={deTranslator.global.appendDevices}
                    open={this.state.openAppendDeviceModal}
                    fullScreen={true}
                    onOkButtonClick={this.handleAddDevices}
                    OkButtonDisabled={this.props.appendDeviceForm.invalid}
                    onClose={() => this.setState({ openAppendDeviceModal: false })}
                >
                    <SlAppendDeviceContainerComponent
                        productList={this.props.productList}
                        deviceTypeList={this.props.deviceTypeList}
                        meterPanels={[this.props.meterPanelState.selectedMeterPanel]}
                    />
                </SLModal>
                <SLModal
                    title={'Zählplatz editieren'}
                    onClose={() => this.setState({ openMeterPanelModal: false })}
                    hideClose={true}
                    open={this.state.openMeterPanelModal}
                    onOkButtonClick={this.handleUpdateMeterPanel}
                    OkButtonDisabled={this.props.meterPanelForm.invalid}
                >
                    <SLMeterPanelEditComponent
                        meterPanel={this.props.meterPanelState.selectedMeterPanel}
                    />
                </SLModal>
            </div>
        );
    }

    private renderMasterData() {
        const { selectedMeterPanel } = this.props.meterPanelState;
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.masterDataActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer}>
                    <div className={this.props.classes.tabContent}>
                        <SLMeterPanelInfo meterPanel={selectedMeterPanel} />

                        <div className={this.props.classes.block}>
                            <MaterialUI.Divider />
                            <BasicTable
                                columns={this.deviceInfo}
                                title={`Zugeordnete ${deTranslator.device.title}`}
                                options={{ toolbar: true, maxBodyHeight: 'calc(100vh - 345px)' }}
                                data={this.buildTableDataGeraete()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader currentLocation={this.props.location} tabs={[]} />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.renderContent()}
                    {this.renderModals()}
                </div>
                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDissmissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        uploadFileForm: {
            formValue: getFormValues('SlUploadFileForm')(state),
            invalid: isInvalid('SlUploadFileForm')(state),
        },
        maloMeloForm: {
            formValue: getFormValues('MaloMeloForm')(state),
            invalid: isInvalid('MaloMeloForm')(state),
            doReset: () => reset('MaloMeloForm'),
        },
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        appendDeviceForm: {
            formValue: getFormValues('AppendDeviceForm')(state),
            invalid: isInvalid('AppendDeviceForm')(state),
        },
        meterPanelForm: {
            formValue: getFormValues('MeterPanelEditForm')(state),
            invalid: isInvalid('MeterPanelEditForm')(state),
        },
        customerState: state.customerState,
        facilityState: state.facilityState,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        meterPanelState: state.meterPanelState,
        deviceState: state.deviceState,
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
        productList: state.settingState.productManagementList,
        deviceTypeList: state.settingState.deviceTypeList,
        ableseTypeList: state.settingState.ableseTypeList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            MeterPanelsActions,
            GlobalActions,
            DevicesActions,
            SettingsActions,
            AuftragActions
        ),
        dispatch
    );
};

export const MeterPanelDataPage = withRouter(
    connect<IKabMeterPanelDetailsProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<IKabMeterPanelDetailsProps>(MaterialUI.withStyles(styles)(KabMeterPanelDetails))
);
