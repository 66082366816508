import { IContactData } from '../../../../common/interfaces/interface';
import { ContactPersonActionTypes } from '../types/contactperson.action.types';

export interface IContactPersonState {
    contactPersons: Array<IContactData>;
    selectedContactPerson: IContactData;
    contactPersonLoading: boolean;
    addedContactPersonId: string;
}

const initialState: IContactPersonState = {
    contactPersons: [],
    selectedContactPerson: null,
    contactPersonLoading: false,
    addedContactPersonId: ''
};

export const contactPersonReducer = (state: IContactPersonState = initialState, action: any): IContactPersonState => {
    switch (action.type) {
        case ContactPersonActionTypes.CONTACTPERSON_IS_LOADING:
            return Object.assign({}, state, {contactPersonLoading: action.payload});
        case ContactPersonActionTypes.SAVE_CONTACTPERSONS:
            return Object.assign({}, state, {contactPersons: [...action.payload]});
        case ContactPersonActionTypes.SAVE_SELECTED_CONTACTPERSON:
            return Object.assign({}, state, {selectedContactPerson: action.payload});
        case ContactPersonActionTypes.ADDED_CONTACTPERSON_ID:
            return Object.assign({}, state, {addedContactPersonId: action.payload});
        default:
            return Object.assign({}, state);

    }
};