import axios from 'axios';
import { IDevice } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
} from '../../../../common/redux/actions/global.action';
import { saveDevices, saveSelectedDevice, showOrderDeviceModal } from '../actions/devices.actions';
import { DeviceActionTypes } from '../types/devices.action.types';

export const getDevices = (kabId: string, anlageId: string) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            const response = await axios.get(`kabs/${kabId}/anlagen/${anlageId}/geraete`);
            dispatch(saveDevices(response.data ? response.data : []));
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(saveDevices([]));
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const getDeviceById = (kabId: string, anlageId: string, deviceID: string) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            const response = await axios.get(
                `kabs/${kabId}/anlagen/${anlageId}/geraete/${deviceID}`
            );

            if (response && response.data) {
                dispatch(saveSelectedDevice(response.data ? response.data : null));
            }
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(saveSelectedDevice(null));
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const postDevices = (kabID: string, aID: string, payload: Array<IDevice>) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            await axios.post(`kabs/${kabID}/anlagen/${aID}/geraete`, payload);
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const updateDevices = (kabID: string, aID: string, payload: Array<IDevice>) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            const devices = payload.map((item: IDevice) => {
                const { Produkte, Grundtyp, ...updatedDevice } = item;
                return updatedDevice;
            });
            await axios.put(`kabs/${kabID}/anlagen/${aID}/geraete`, devices);
            dispatch(showOrderDeviceModal(false));
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const deleteDevices = (kabID: string, aID: string, ids: string) => {
    return async dispatch => {
        dispatch(addLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        try {
            await axios.delete(`kabs/${kabID}/anlagen/${aID}/geraete?ids=${ids}`);
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        } catch (error) {
            dispatch(removeLoadingState(DeviceActionTypes.DEVICES_LOADING_STATUS));
        }
    };
};

export const handleRemovingDevice = (kabID: string, aID: string, payload: Array<IDevice>) => {
    return async dispatch => {
        const allDevices: Array<IDevice> = payload.map(deviceForm => {
            // const device = getState().deviceState.devices.find(item => item.GeraeteID === deviceForm.GeraeteID)
            return Object.assign({}, deviceForm, { AuftragAktion: '-' });
        });
        return dispatch(updateDevices(kabID, aID, allDevices));
    };
};
