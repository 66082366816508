export const sbColors = {

    blue: '#000099',
    orange: '#FF9800',
    red: '#F44336',
    white: '#FFFFFF',
    whiteSmoke: '#F3F5F8',
    body: '#3B3B3B',
    green:'#29C856'

};