import { TokenService } from '../../login/services/token.service';
import { CONSTANTS } from '../../../model/constants';

export const getIdTokenForSlimApi = async () => {
    if (TokenService.isTokenExpired()) {
        const body = {
            code: ' ',
            grant_type: 'refresh_token',
            clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirect_uri: `${window.location.protocol}//${window.location.host}/auth`,
            refresh_token: localStorage.getItem(CONSTANTS.refreshToken),
        };
        const httpResponse = await (
            await fetch(`${process.env.REACT_APP_API_URL}auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
        ).json();
        TokenService.saveToken(httpResponse.token);
        return httpResponse.token.id_token;
    }

    return localStorage.getItem(CONSTANTS.idToken);
};
