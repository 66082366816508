import {
    loginSave, loginLoadingStatus, logout,
} from './actions/login.action';

export const LoginActions = Object.assign({},

    {loginSave: loginSave},
    {loginLoadingStatus: loginLoadingStatus},
    {logout: logout});

export { loginReducer } from './reducers/login.reducer';