import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IDeviceType } from '../../../../common/interfaces/interface';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';


const styles = () => MaterialUI.createStyles({});

export interface IAddFacilityServiceComponentProps extends WithStyles<typeof styles> {
    editMode?: boolean;
    defaultDeviceType?: IDeviceType;
    initialize?: (customerdata: any) => void;
}


class AddDeviceType extends React.Component<IAddFacilityServiceComponentProps> {

    constructor(props: IAddFacilityServiceComponentProps) {
        super(props);
        if (this.props.editMode && this.props.defaultDeviceType) {
            this.props.initialize(this.props.defaultDeviceType);
        }
    }

    public render() {
        return (<div style={{padding: '20px 20px 40px'}}>
            <MaterialUI.Grid container={true} spacing={10}>
                <MaterialUI.Grid item={true} xs={4}>
                    <Field name={'grundTyp'}
                           label={'Name'}
                           type={'text'}
                           component={SlTextInputComponent}
                           placeholder={''}
                           required={true}
                           validate={[FormValidator.required]}
                           options={{fullWidth: true, shrink: true}}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}/>
            </MaterialUI.Grid>
        </div>);
    }

}

export const DeviceTypeManagementForm = MaterialUI.withStyles(styles)(AddDeviceType);

export const SlDeviceTypeManagementForm = reduxForm({
    form: 'DeviceTypeManagementForm',
})(DeviceTypeManagementForm);