import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent } from '../../../../common/components/modal';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { IAbleseTyp } from '../../../../common/interfaces/interface';
import { currencyFormatter, isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    shouldShowDeleteAlert: boolean;
}

export interface IAblesetypProps extends WithStyles<typeof styles> {
    ablesetypList: Array<IAbleseTyp>;
    onAddAblesetypDialog: () => void;
    onEditAblesetypDialog: (item) => void;
    selectedAblesetyp: IAbleseTyp;
    onSelectedAblesetyp: (item: IAbleseTyp) => void;
    onDeleteAblesetyp: () => void;
}

class AblesetypComponent extends React.Component<IAblesetypProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IAblesetypProps) {
        super(props);
        this.actionBarItems = [
            {
                label: 'Neuer Ablesetyp',
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.props.onAddAblesetypDialog(),
                disable: false,
            },
            {
                label: deTranslator.actions.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.props.selectedAblesetyp &&
                    this.props.onEditAblesetypDialog(this.props.selectedAblesetyp),
                disable: true,
            },
            {
                label: deTranslator.actions.delete,
                icon: <MaterialUIIcons.Delete color={'secondary'} />,
                action: () =>
                    this.props.selectedAblesetyp && this.setState({ shouldShowDeleteAlert: true }),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            shouldShowDeleteAlert: false,
        };
    }

    public handleTableRowClick = item => {
        this.props.onSelectedAblesetyp(this.props.ablesetypList.find(data => data.id === item.id));
    };
    public handleDeleteItem = () => {
        this.props.onDeleteAblesetyp();
        this.setState({ shouldShowDeleteAlert: false });
    };

    private buildTableActions = () => {
        const newValue = this.state.tableActionBarItems.slice(1).map(item => {
            item.disable = this.props.selectedAblesetyp ? false : true;
            return item;
        });
        return [this.actionBarItems[0], ...newValue];
    };

    private buildColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                render: rowData => {
                    const checked = this.props.selectedAblesetyp
                        ? this.props.selectedAblesetyp.id === rowData.id
                        : false;
                    return (
                        <div style={{ display: 'flex', marginLeft: '-10px' }}>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <MaterialUI.Radio
                                    onClick={() => this.handleTableRowClick(rowData)}
                                    checked={checked}
                                    value={rowData.id}
                                />
                            )}
                            <span style={{ marginTop: 11 }}>{rowData.name}</span>
                        </div>
                    );
                },
            },
            {
                title: 'Preis',
                field: 'preis',
                render: rowData => currencyFormatter(rowData.preis / 100),
            },
            {
                title: 'Zyklus',
                field: 'zyklus',
                emptyValue: '-',
            },
        ];
    };

    public render() {
        const ablesetypList = [...this.props.ablesetypList].map(produkt => {
            return Object.assign({}, produkt);
        });
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Ablesetypverwaltung
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div>
                <div>
                    <BasicTable
                        columns={[...this.buildColumns()]}
                        data={ablesetypList}
                        options={{ paging: true, pageSize: 5 }}
                    />
                </div>

                <SlAlertComponent
                    dialogTitle={`Ablesetyp ${deTranslator.actions.delete} `}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.shouldShowDeleteAlert}
                    handleOK={this.handleDeleteItem}
                    handleCancel={() => this.setState({ shouldShowDeleteAlert: false })}
                    showCancelButton={true}
                >
                    {`${deTranslator.product.deleteTextStart}`}
                    {this.props.selectedAblesetyp && <b>{this.props.selectedAblesetyp.name}</b>}
                    {`${deTranslator.product.deleteTextEnd}`}
                </SlAlertComponent>
            </div>
        );
    }
}

export const SLAblesetypComponent = MaterialUI.withStyles(styles)(AblesetypComponent);
