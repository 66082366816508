import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { titleStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        labelContainer: {
            display: 'flex',
            flexDirection: 'column',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
    });

export interface IReadInputProps extends WithStyles<typeof styles> {
    label: string;
    text: string;
}

class ReadInputComponent extends React.Component<IReadInputProps, {}> {
    public render() {
        return (
            <div>
                <div className={this.props.classes.labelContainer}>
                    <MaterialUI.Typography
                        color={'inherit'}
                        variant="subtitle2"
                        style={titleStyles.fontWeight350}
                        className={`${this.props.classes.label} `}
                    >
                        {this.props.label}
                    </MaterialUI.Typography>
                    <MaterialUI.Typography
                        color={'inherit'}
                        className={`${this.props.classes.input}  `}
                    >
                        {this.props.text}
                    </MaterialUI.Typography>
                </div>
            </div>
        );
    }
}

export const ReadInput = MaterialUI.withStyles(styles)(ReadInputComponent);
