import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { change, Field, FieldArray, reduxForm } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent
} from '../../../../common/components/forms';
import { SLMeterLevelInput } from '../../../../common/components/forms/number-input.component';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IDevice } from '../../../../common/interfaces/interface';
import { germanDateToISODateFormat, inputDateDisplayFormat } from '../../../../common/utils/Functions';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { AbleseStatus, Grundtyp } from '../../../../model/enums';
import { sbColors } from '../../../../theme/app.colors';

const styles = () => MaterialUI.createStyles({
    maxWidth: {
        width: 375
    },
    itemWidth: {
        flexGrow: 0,
        maxWidth: '12%',
        flexBasis: '12%',
    },
    disabledTextField: {
        backgroundColor: '#E2E2E2'
    }
});

export interface ISlRemoveDeviceProps extends WithStyles<typeof styles> {
    devices: Array<IDevice>;
    initialize?: (data) => void;
    dispatch?: any;
    currentFormDevices?: any;
}

interface IDeviceForm {
    GeraeteID: string;
    Grundtyp: string;
    ZID: string;
    Ausbaudatum: string;
    AusbaustandHT?: string;
    AusbaustandNT?: string;
    AusbaustandStatus?: string;
    ZaehlerstandHT?: string;
    ZaehlerstandNT?: string;

}

class SlRemoveDevice extends React.Component<ISlRemoveDeviceProps> {


    constructor(props: ISlRemoveDeviceProps) {
        super(props);
        const initialValues = this.props.devices.map(device => {
            return Object.assign({}, {
                ...device,
                GeraeteID: device.GeraeteID,
                AusbaustandHT: device.AusbaustandHT,
                AusbaustandNT: device.AusbaustandNT,
                AusbaustandStatus: device.AusbaustandStatus ? device.AusbaustandStatus : ''
            });
        });
        if (this.props.currentFormDevices) {
            this.props.initialize({devices: this.props.currentFormDevices.devices});
        } else {
            this.props.initialize({devices: initialValues});
        }

    }


    private convertToNumber(newValue) {
        return newValue ? Number(newValue.replace(/\./g, '').replace(',', '.')) : 0;
    }


    private setStatusDT(deviceForm: IDeviceForm, ausbaustandHT, ausbaustandNT, index) {
        if (this.convertToNumber(deviceForm.ZaehlerstandHT) < this.convertToNumber(ausbaustandHT)
            && this.convertToNumber(deviceForm.ZaehlerstandNT) < this.convertToNumber(ausbaustandNT)) {
            this.props.dispatch(change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Plausibel));
        } else {
            this.props.dispatch(change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Unplausibel));
        }
    }

    private setStatusET(deviceForm: IDeviceForm, ausbaustandHT, index) {
        if (this.convertToNumber(deviceForm.ZaehlerstandHT) < this.convertToNumber(ausbaustandHT)) {
            this.props.dispatch(change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Plausibel));
        } else {
            this.props.dispatch(change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Unplausibel));
        }
    }

    private setDeviceStatus(deviceForm: IDeviceForm, ausbaustandHT, ausbaustandNT, index) {
        switch (deviceForm.Grundtyp) {
            case Grundtyp.ZaehlerET:
                this.setStatusET(deviceForm, ausbaustandHT, index);
                break;
            case Grundtyp.ZaehlerDT:
                this.setStatusDT(deviceForm, ausbaustandHT, ausbaustandNT, index);
                break;
        }
    }

    private renderStatus = (device) => {


        enum plausibelOptions {
            Plausibel = 'Plausibel',
            Unplausibel = 'Unplausibel',
            Freigegeben = 'Freigegeben'
        }


        return (
            <Field name={`${device}.AusbaustandStatus`}
                   component={SlSelectInputComponent}
                   label={'Status'}
                   validate={[FormValidator.required]}
                   required={true}
                   style={{color: sbColors.orange, width: '90%'}}
                   options={{fullWidth: true}}>
                {renderItemForSelectBox(plausibelOptions)}
            </Field>
        );
    };


    private renderContent = ({fields}) => {
        return (fields.map((device, index, devicesField) => {
            // const deviceType = devicesField.get(index).Grundtyp;
            let ZaehlerstandHT = '0';
            let ZaehlerstandNT = '0';
            if (this.props.devices && this.props.devices.length > 0) {
                ZaehlerstandHT = this.props.devices[index].Ablesungen && this.props.devices[index].Ablesungen.length > 0 ?
                    this.props.devices[index].Ablesungen[0].ZaehlerstandHT : this.props.devices[index].EinbaustandHT;
                ZaehlerstandNT = this.props.devices[index].Ablesungen && this.props.devices[index].Ablesungen.length > 0 ?
                    this.props.devices[index].Ablesungen[0].ZaehlerstandNT : this.props.devices[index].EinbaustandNT;

            }


            return (
                <MaterialUI.Grid key={`device-${device}.ID`}
                                 container={true}
                                 spacing={2}>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${device}.GeraeteID`}
                            label={'Geräte-ID'}
                            component={SlTextInputComponent}
                            readOnly={true}
                            disabled={true}
                            options={{fullWidth: true}}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${device}.Ausbaudatum`}
                            label={'Ausbaudatum'}
                            component={SlDateInputComponent}
                            type={'text'}
                            options={{fullWidth: true}}
                            required={true}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            validate={[FormValidator.required, FormValidator.isValidDate]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <MaterialUI.TextField
                            label={'Letzter Stand HT'}
                            variant={'outlined'}
                            className={this.props.classes.disabledTextField}
                            disabled={true}
                            InputProps={{readOnly: true}}
                            InputLabelProps={{shrink: true}}
                            fullWidth={true}
                            defaultValue={ZaehlerstandHT}
                        />

                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <SLMeterLevelInput name={`${device}.AusbaustandHT`}
                                           label={`Ausbaustand HT`}
                                           required={true}
                                           validate={[FormValidator.required]}
                                           endAdornment={<MaterialUI.InputAdornment
                                               position="end">{deTranslator.einheit.kilowatt}</MaterialUI.InputAdornment>}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <MaterialUI.TextField
                            label={'Letzter Stand NT'}
                            variant={'outlined'}
                            className={this.props.classes.disabledTextField}
                            disabled={true}
                            InputProps={{readOnly: true}}
                            InputLabelProps={{shrink: true}}
                            fullWidth={true}
                            defaultValue={ZaehlerstandNT}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <SLMeterLevelInput
                            name={`${device}.AusbaustandNT`}
                            label={`Ausbaustand NT`}
                            required={false}
                            validate={[]}
                            // validate={deviceType === Grundtyp.ZaehlerDT ? [FormValidator.required] : []}
                            onChange={(event, newValue,) => this.setDeviceStatus(devicesField.get(index), devicesField.get(index).AusbaustandHT, newValue, index)}
                            endAdornment={<MaterialUI.InputAdornment
                                position="end">{deTranslator.einheit.kilowatt}</MaterialUI.InputAdornment>}

                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        {this.renderStatus(device)}
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            );
        }));
    };

    public render() {
        return (<div style={{paddingTop: 24}}>
            <FieldArray name="devices" component={this.renderContent}/>
        </div>);
    }
}

export const SlRemoveDeviceComponent = MaterialUI.withStyles(styles)(SlRemoveDevice);

export const SlRemoveDeviceForm = reduxForm({
    form: 'SlRemoveDeviceForm', // a unique identifier for this form
    destroyOnUnmount: true
})(SlRemoveDeviceComponent);
