import { IError, ISnackBar } from '../reducers/global.reducer';
import { GlobalActionTypes } from '../types/global.types';

export const setError = (error: IError) => {
    return {
        type: GlobalActionTypes.SET_ERROR,
        payload: error,
    };
};

export const addLoadingState = (loading: string) => {
    return {
        type: GlobalActionTypes.ADD_LOADING_STATE,
        payload: loading,
    };
};

export const removeLoadingState = (loading: string) => {
    return {
        type: GlobalActionTypes.REMOVE_LOADING_STATE,
        payload: loading,
    };
};

export const setSnackbarStatus = (value: ISnackBar) => {
    return dispatch =>
        dispatch({
            type: GlobalActionTypes.SHOW_SNACKBAR,
            payload: value,
        });
};
