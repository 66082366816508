import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { germanDateToISODateFormat, inputDateDisplayFormat } from '../../utils/Functions';
import { FormValidator } from '../../validators/form.validator';
import { SlDateInputComponent } from './date-input.component';
import { SlTextInputComponent } from './text-field.component';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
        size: {
            width: 40,
            height: 40,
        },
        sizeIcon: {
            fontSize: 20,
        },
        inputFieldClass: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            borderColor: 'black',
            border: '1px solid ',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: theme.palette.primary.main,
                boxShadow: '0 0 0 0.2rem none',
            },
        },
    });

export interface IAuftragProps extends WithStyles<typeof styles> {
    auftragDateLabel: string;
    valid: boolean;
    loadingStatus: boolean;
    regards?: string;
    handleSubmit?: any;
    initialize?: any;
    reset?: () => void;
    showDate?: boolean;
}

class AuftragComponent extends React.Component<IAuftragProps> {
    public static defaultProps: Partial<IAuftragProps> = {
        showDate: true,
    };

    public renderRegardInfo() {
        if (this.props.regards) {
            const charNumber = 255 - this.props.regards.length;
            return (
                <MaterialUI.Typography variant="caption" gutterBottom={true} align="right">
                    {`Maximal ${charNumber} Zeichen`}
                </MaterialUI.Typography>
            );
        }
        return (
            <MaterialUI.Typography variant="caption" gutterBottom={true} align="right">
                {'Das Feld darf maximal 255 Zeichen haben'}
            </MaterialUI.Typography>
        );
    }

    public render() {
        return (
            <div style={{ padding: 20 }}>
                <MaterialUI.Grid
                    container={true}
                    style={{ marginBottom: 0 }}
                    direction={'column'}
                    spacing={4}
                >
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field
                            name={'Email'}
                            required={true}
                            type="text"
                            component={SlTextInputComponent}
                            validate={[
                                FormValidator.required,
                                FormValidator.email,
                                FormValidator.maxLength50,
                            ]}
                            label={deTranslator.email.defaultEmail}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        {this.props.showDate && (
                            <Field
                                name={'Ausfuehrungsdatum'}
                                component={SlDateInputComponent}
                                label={this.props.auftragDateLabel}
                                shrink={true}
                                type={'text'}
                                format={inputDateDisplayFormat}
                                normalize={germanDateToISODateFormat}
                                validate={[FormValidator.isValidDate]}
                                required={false}
                                options={{ fullWidth: true, shrink: true }}
                            />
                        )}
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} justify="space-between">
                    <MaterialUI.Grid item={true} xs={8}>
                        <MaterialUI.Typography variant="h6">Bemerkung</MaterialUI.Typography>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={10} classes={{}}>
                        <div>{this.renderRegardInfo()}</div>
                        <Field
                            name={'Bemerkung'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={'Hier kann ein Freitext eingetragen werden...'}
                            multiline={true}
                            rows={5}
                            disableUnderline={true}
                            options={{ fullWidth: true }}
                            validate={[FormValidator.maxLength255]}
                            maxLength={255}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
                <div style={{ marginBottom: '20px' }}>
                    <MaterialUI.Typography style={{ paddingTop: '35px' }}>
                        *Pflichtfelder
                    </MaterialUI.Typography>
                </div>
            </div>
        );
    }
}

export const AuftragForm = MaterialUI.withStyles(styles)(AuftragComponent);
