export const updateZaehlerplatz = /* GraphQL */ `
    mutation UpdateZaehlerplatz(
        $input: UpdateZaehlerplatzInput!
        $condition: ModelZaehlerplatzConditionInput
    ) {
        updateZaehlerplatz(input: $input, condition: $condition) {
            id
            organisation_id
            KID
            AID
            ZID
            WohnungNr
            Montageart
            AuftragAktion
            Gebaeude
            Lage
            Malo
            Melo
            Stockwerk
            Verbrauchsprognose
            GeraetTyp
            Nutzungstyp
            Metergrid {
                id
                project
                name
                malo_id
                melo_id
                type
                location
                data_provider
                data_provider_ref
                users
                url
            }
            geraeteList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    ZID
                    Seriennummer
                    ZaehlerTyp
                    Einbaudatum
                    Produkts {
                        name
                        preis
                        ebene
                        zyklus
                        id
                    }
                    GeraetePIN
                    Metergrid {
                        url
                        id
                        serial
                        panel
                        conversion_factor
                        owner
                        project
                    }
                    AuftragAktion
                    Ausbaudatum
                    AusbaustandStatus
                    Eichdatum
                    ObisCodeHT
                    ObisCodeNT
                    GeraetTyp
                    WandlerID1
                    WandlerID2
                    WandlerID3
                    Wandlerfaktor
                    ZaehlwerkNKS
                    ZaehlwerkVKS
                    Zaehlerversanddatum
                    ablesungList {
                        items {
                            id
                            organisation_id
                            geraet_id
                            Seriennummer
                            ZID
                            ZaehlerTyp
                            Ablesedatum
                            ZaehlerstandHT
                            Einspeisungstand
                            Strombezugstand
                            Ablesegrund
                            AndereAblesegrund
                            Status
                            VerbrauchHT
                            Unit
                            KorrigierterVerbrauch
                            Abschlagshoehe
                            createdAt
                            updatedAt
                        }
                        nextToken
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
