import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent, SLModal } from '../../../../common/components/modal';
import { SlSnackbarComponent } from '../../../../common/components/snackbar/sl-snackbar.component';

import { EinbauauftragIcon } from '../../../../common/components/svgIcons/Einbauauftrag';
import { StoerungIcon } from '../../../../common/components/svgIcons/Stoerung';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../../common/components/table/mui-table.component';
import {
    IAbleseTyp,
    IContactData,
    IDevice,
    IFacility,
    IKAB,
    IMeterPlace,
    IZaehlDaten,
    IZaehlStand,
} from '../../../../common/interfaces/interface';
import { GlobalActions } from '../../../../common/redux';
import { ISnackBar } from '../../../../common/redux/reducers/global.reducer';
import { IsoformatToGermanDate } from '../../../../common/utils';
import {
    calcalJahresverbrauchsprognose,
    germanDateToISODateFormat,
    isUserRole,
    removeEmptykey,
} from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { AuftragType, ContactPersonRolles, Roles } from '../../../../model/enums';
import { IAblesungExt } from '../../../../model/interfaces/IAblesungExt';

import { IForm } from '../../../../model/interfaces/IForm';
import { IReduxState } from '../../../../model/redux-state';
import { history } from '../../../../store';
import { sbColors } from '../../../../theme/app.colors';
import { appStyles } from '../../../../theme/common.styles';
import { AuftragActions } from '../../../auftrag/redux';
import { SLZaehlDatenForm } from '../../../facilities/forms/facility-zaehlerstand.form';
import { FacilityActions } from '../../../facilities/redux';
import { MeterPanelsActions } from '../../../meterpanels/redux';
import { DevicesActions } from '../../redux';
import { IDevicesState } from '../../redux/reducers/devices.reducer';
import { buildIAblesungExt } from '../../services/devices.services';
import { SLAblesungHinzufuegen } from '../ablesung/ablesung-hinzufuegen.component';
import { SlAuftragComponent } from '../form/slAuftragComponent';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

interface ISlDevicesETDE extends WithStyles<typeof styles> {
    kabID: string;
    aID: string;
    // devices: Array<IDevice>;
    ableseTypeList: Array<IAbleseTyp>;
    dataTable: Array<any>;
    deviceState: IDevicesState;
    meterPanels: Array<IMeterPlace>;
    facilityEmail?: any;
    selectedDevice?: IDevice;
    onDeviceClick?: (device: IDevice) => void;
    doDevicesOrder?: (kabID: string, aID: string, payload: any) => void;
    getDevices?: (kabID: string, aID: string) => void;
    addReadingForm?: IForm;
    editReadingForm?: IForm;
    auftragForm?: IForm;
    facilityZaehlDatenForm?: IForm;
    facility?: IFacility;
    kab?: IKAB;
    postZaehldaten?: (payload: IZaehlDaten) => void;
    snackbarStatus?: ISnackBar;
    setSnackbarStatus?: (item: ISnackBar) => void;
    updateDevices?: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    updateMeterPanels?: (kabID: string, aId: string, payload: Array<IMeterPlace>) => void;
    getMeterPanels?: (kabID: string, aID: string) => void;
    sendAbleseAuftrag?: (payload: any) => void;
}

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    displayOrderDeviceModal: boolean;
    showAddReadingModal: boolean;
    selectedRowId: string;
    selectedRowItems: Array<string>;
    showSendReadingModal: boolean;
    openAlert: boolean;
    showUpdatePrognoseAlert: boolean;
    showAddPrognoseAlert: boolean;
    openAlertAblesung: boolean;
}

class SlMeterReadingComponent extends React.Component<ISlDevicesETDE, IState> {
    private readonly ableseInfo: Array<IMuiTableColumn> = [];
    private selectedReadings: Array<IAblesungExt> = [];
    private selectedRowItems: Array<any> = [];
    private orderType: string = '';
    private orderTitle: string = '';
    private editAbelesung: boolean = false;
    private Ansprechpartner: IContactData = null;

    constructor(props: ISlDevicesETDE) {
        super(props);
        this.state = {
            displayOrderDeviceModal: false,
            showAddReadingModal: false,
            showSendReadingModal: false,
            openAlert: false,
            showUpdatePrognoseAlert: false,
            showAddPrognoseAlert: false,
            selectedRowId: '',
            selectedRowItems: [],
            openAlertAblesung: false,
            tableActionBarItems: [
                {
                    label: 'Ableseauftrag',
                    action: () => this.handleOrder(),
                    icon: <EinbauauftragIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: 'Ablesung hinzufügen',
                    action: () => this.handleAddMeterReading(),
                    icon: <StoerungIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: `${deTranslator.device.zaehlerstand} ${deTranslator.global.edit}`,
                    action: () => this.handleEditMeterReading(),
                    icon: <StoerungIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: `${deTranslator.device.zaehlerstand} verschicken`,
                    action: () => this.handleSendMeterReading(),
                    icon: <StoerungIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: `Verbrauchsprognose anpassen`,
                    action: () => this.handleVerbrauchsprognose(),
                    icon: <StoerungIcon color={sbColors.orange} />,
                    disable: true,
                },
            ],
        };

        this.ableseInfo = [
            {
                title: 'Geräte-ID',
                field: 'GeraeteID',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToDevice(rowData)}
                        >
                            {rowData.GeraeteID}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Ablesedatum',
                field: 'letztAbleseDatum',
                defaultSort: 'desc',
                render: rowData =>
                    IsoformatToGermanDate(
                        rowData.letztAbleseDatum ? rowData.letztAbleseDatum : '-'
                    ),
            },
            {
                title: `Ablesestand HT (${deTranslator.einheit.kilowatt})`,
                field: 'letztAblesungStandHT',
            },
            { title: 'Nächste Ablesung', field: 'naechsteAblesungStand' },
            { title: 'Status', field: 'status' },
            { title: 'Ablesegrund', field: 'ablesegrund' },
        ];
    }

    public componentWillUnmount() {
        this.props.onDeviceClick(null);
    }

    public goToDevice = ({ ZID, ID }) => {
        history.push(`/kabs/${this.props.kabID}/${this.props.aID}/${ZID}/${ID}`);
    };

    private handleCheckBoxClick = items => {
        this.selectedRowItems = [...items];
        this.setState({ tableActionBarItems: this.buildTableActions(items.length > 0) });
    };

    private buildTableActions = (active: boolean) => {
        return this.state.tableActionBarItems.map(item => {
            item.disable = !active;
            return item;
        });
    };

    private handleOrder = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Ableseauftrag;
            this.orderTitle = 'Ableseauftrag';
            this.Ansprechpartner = this.props.facility.ContactData.find(
                item => item.Rolle === ContactPersonRolles.AnsprechpartnerAblesung
            );
            if (this.Ansprechpartner) {
                this.setState({ ...this.state, displayOrderDeviceModal: true });
            } else {
                this.setState({ ...this.state, openAlertAblesung: true });
            }
        }
    };

    private handleAddMeterReading = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedReadings = buildIAblesungExt(
                this.selectedRowItems.map(item => {
                    return this.props.dataTable.find(device => device.ID === item.ID);
                }),
                true
            );
            this.setState({ ...this.state, showAddReadingModal: true });
        }
    };
    private handleEditMeterReading = () => {
        if (this.selectedRowItems.every(item => item.Ablesungen.length > 0)) {
            this.selectedReadings = buildIAblesungExt(
                this.selectedRowItems.map(item => {
                    return this.props.dataTable.find(device => device.ID === item.ID);
                }),
                false
            );
            this.editAbelesung = true;
            this.setState({ ...this.state, showAddReadingModal: true });
        } else {
            this.setState({ ...this.state, openAlert: true });
        }
    };

    private handleSendMeterReading = () => {
        if (this.selectedRowItems.every(item => item.Ablesungen.length > 0)) {
            this.setState({ ...this.state, showSendReadingModal: true });
        } else {
            this.setState({ ...this.state, openAlert: true });
        }
    };
    private handleUpdateDeviceReading = async () => {
        const Ablesungen: Array<IAblesungExt> = this.props.addReadingForm.formValue['Ablesungen'];

        const devices = Ablesungen.map(item => {
            const device: IDevice = this.props.deviceState.devices.find(
                tmp => tmp.ID === item.GeraeteID
            );
            if (this.editAbelesung) {
                device.Ablesungen[0] = removeEmptykey(item.Ablesung);
                return device;
            } else {
                const oldReading = device.Ablesungen ? device.Ablesungen : [];
                return Object.assign({}, device, {
                    Ablesungen: [removeEmptykey(item.Ablesung), ...oldReading],
                });
            }
        });
        await this.props.updateDevices(this.props.kabID, this.props.aID, devices);
        this.editAbelesung = false;
        this.props.getDevices(this.props.kabID, this.props.aID);
        this.setState({ ...this.state, showUpdatePrognoseAlert: true });
    };

    private handleVerbrauchsprognose = () => {
        if (this.selectedRowItems.every(item => item.Ablesungen.length > 0)) {
            this.setState({ ...this.state, showAddPrognoseAlert: true });
            this.selectedReadings = buildIAblesungExt(
                this.selectedRowItems.map(item => {
                    return this.props.dataTable.find(device => device.ID === item.ID);
                }),
                false
            );
        } else {
            this.setState({ ...this.state, openAlert: true });
        }
    };

    private updateJahresverbrauchsprognose = () => {
        this.closeJahresverbrauchsprognoseAlert();
        this.updateMeterPanelJahresverbrauchsprognose(this.selectedReadings);
    };

    private handleJahresverbrauchsprognose = async () => {
        this.closeJahresverbrauchsprognoseAlert();
        this.updateMeterPanelJahresverbrauchsprognose(
            this.props.addReadingForm.formValue['Ablesungen']
        );
        this.setState({
            ...this.state,
            showAddReadingModal: false,
            showUpdatePrognoseAlert: false,
            showAddPrognoseAlert: false,
            tableActionBarItems: this.buildTableActions(false),
        });
    };
    private closeJahresverbrauchsprognoseAlert = () => {
        this.setState({
            ...this.state,
            showAddReadingModal: false,
            showUpdatePrognoseAlert: false,
            showAddPrognoseAlert: false,
        });
    };

    private updateMeterPanelJahresverbrauchsprognose = async (readings: Array<IAblesungExt>) => {
        const meterPanels = readings.map((item: IAblesungExt) => {
            const meterpanel = this.props.meterPanels.find(tmp => tmp.ID === item.ZID);
            const verbrauch =
                item.Ablesung.KorrigierterVerbrauch && item.Ablesung.KorrigierterVerbrauch !== ''
                    ? item.Ablesung.KorrigierterVerbrauch
                    : item.Ablesung.VerbrauchHT;
            meterpanel['Verbrauchsprognose'] = calcalJahresverbrauchsprognose(
                germanDateToISODateFormat(item.lastReadDatum),
                item.Ablesung.Ablesedatum,
                verbrauch
            );
            return meterpanel;
        });
        await this.props.updateMeterPanels(this.props.kabID, this.props.aID, meterPanels);
        await this.props.getMeterPanels(this.props.kabID, this.props.aID);
        this.props.setSnackbarStatus({
            flag: true,
            text: 'Jahresverbrauchsprognose wurde aktualisiert',
            type: 'success',
        });
    };
    private sendMeterReading = async () => {
        const zaehlerstand: Array<IZaehlStand> = [];
        this.selectedRowItems.forEach(item => {
            if (item.Ablesungen.length > 0) {
                const altStand =
                    item.Ablesungen.length > 1
                        ? item.Ablesungen[1].ZaehlerstandHT
                        : item.EinbaustandHT || '0';
                const altDatum =
                    item.Ablesungen.length > 1 ? item.Ablesungen[1].Ablesedatum : item.Einbaudatum;
                zaehlerstand.push({
                    GeraeteID: item.GeraeteID,
                    WohnungNr: item.WohnungNr,
                    Zeitraum: `${IsoformatToGermanDate(altDatum)} - ${IsoformatToGermanDate(
                        item.Ablesungen[0].Ablesedatum
                    )}`,
                    StandAlt: altStand,
                    StandNeu: item.Ablesungen[0].ZaehlerstandHT,
                    Verbrauch: item.Ablesungen[0].VerbrauchHT,
                });
            }
        });

        const payload: IZaehlDaten = {
            Email: this.props.facilityZaehlDatenForm.formValue.Email,
            EmailText: this.props.facilityZaehlDatenForm.formValue.EmailText,
            FacilityAdresse: this.props.facility.Adresse,
            KabAdresse: this.props.kab.Adresse,
            KabAnrede: this.props.kab.Anrede,
            Ablesedatum: IsoformatToGermanDate(this.selectedRowItems[0].Ablesungen[0].Ablesedatum),
            Zaehldaten: zaehlerstand,
        };
        this.props.postZaehldaten(payload);
        this.setState({ ...this.state, showSendReadingModal: false });
    };

    private doDevicesOrder = async () => {
        this.setState({
            ...this.state,
            displayOrderDeviceModal: false,
            tableActionBarItems: this.buildTableActions(false),
        });
        // remove order with ausbaudatum
        this.selectedReadings = this.selectedRowItems.map(item => {
            return this.props.dataTable.find(device => device.ID === item.ID);
        });
        const auftrag = Object.assign(
            {},
            {
                kabId: this.props.kabID,
                Type: this.orderType,
                contactPerson: this.Ansprechpartner,
                readings: this.selectedRowItems,
            },
            { ...this.props.auftragForm.formValue }
        );

        this.props.sendAbleseAuftrag(auftrag);
    };

    private closeAblesungModal = () => {
        this.editAbelesung = false;
        this.setState({
            ...this.state,
            showAddReadingModal: false,
            tableActionBarItems: this.buildTableActions(false),
        });
    };

    public render() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.tableActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.ableseInfo}
                        options={{
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 345px)',
                        }}
                        data={this.props.dataTable}
                        onSelectionChange={this.handleCheckBoxClick}
                    />
                </div>
                <SLModal
                    title={this.orderTitle}
                    open={this.state.displayOrderDeviceModal}
                    onOkButtonClick={this.doDevicesOrder}
                    okButtonText={deTranslator.global.sendOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            displayOrderDeviceModal: false,
                            tableActionBarItems: this.buildTableActions(false),
                        })
                    }
                >
                    <SlAuftragComponent
                        showDate={false}
                        facilityEmail={this.props.facilityEmail}
                        orderType={this.orderType}
                    />
                </SLModal>
                <SLModal
                    title={
                        this.editAbelesung
                            ? this.state.tableActionBarItems[2].label
                            : this.state.tableActionBarItems[1].label
                    }
                    fullScreen={true}
                    open={this.state.showAddReadingModal}
                    onOkButtonClick={this.handleUpdateDeviceReading}
                    OkButtonDisabled={this.props.addReadingForm.invalid}
                    onClose={this.closeAblesungModal}
                >
                    <SLAblesungHinzufuegen
                        ableseTypeList={this.props.ableseTypeList}
                        meterPanels={this.props.meterPanels}
                        selectedDevices={this.selectedReadings}
                        editMode={this.editAbelesung}
                    />
                </SLModal>
                <SLModal
                    title={'Zählerstände verschicken'}
                    fullScreen={false}
                    open={this.state.showSendReadingModal}
                    okButtonText={'SENDEN'}
                    onOkButtonClick={this.sendMeterReading}
                    OkButtonDisabled={this.props.facilityZaehlDatenForm.invalid}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            showSendReadingModal: false,
                            tableActionBarItems: this.buildTableActions(false),
                        })
                    }
                >
                    <SLZaehlDatenForm
                        facilityAdresse={this.props.facility.Adresse}
                        ablesung={
                            this.selectedRowItems.length > 0 &&
                            this.selectedRowItems[0].Ablesungen[0]
                        }
                    />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={''}
                    showCancelButton={false}
                    textOkButton={deTranslator.global.ok}
                    open={this.state.openAlert}
                    handleOK={() => this.setState({ ...this.state, openAlert: false })}
                >
                    Keine Ablesung vorhanden
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={''}
                    showCancelButton={false}
                    textOkButton={deTranslator.global.ok}
                    open={this.state.openAlertAblesung}
                    handleOK={() => this.setState({ ...this.state, openAlertAblesung: false })}
                >
                    Bitte pflegen Sie zuerst den Ansprechpartner für die Ablesung.
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={''}
                    showCancelButton={true}
                    textCancelButton={'Nein'}
                    textOkButton={'JA'}
                    open={this.state.showUpdatePrognoseAlert}
                    handleCancel={this.closeJahresverbrauchsprognoseAlert}
                    handleOK={this.handleJahresverbrauchsprognose}
                >
                    Möchten Sie die Jahresverbrauchsprognose für das Folgejahr anpassen?
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={''}
                    showCancelButton={true}
                    textCancelButton={'Nein'}
                    textOkButton={'JA'}
                    open={this.state.showAddPrognoseAlert}
                    handleCancel={this.closeJahresverbrauchsprognoseAlert}
                    handleOK={this.updateJahresverbrauchsprognose}
                >
                    Möchten Sie die Verbrauchsprognose anpassen?
                </SlAlertComponent>
                {this.props.snackbarStatus && (
                    <SlSnackbarComponent
                        open={this.props.snackbarStatus.flag}
                        text={this.props.snackbarStatus.text}
                        type={this.props.snackbarStatus.type}
                        handleClose={() => this.props.setSnackbarStatus(null)}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        deviceState: state.deviceState,
        meterPanels: state.meterPanelState.meterPanels,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        ableseTypeList: state.settingState.ableseTypeList,
        facility: state.facilityState.selectedFacility,
        snackbarStatus: state.globalState.snackbarStatus,
        kab: state.customerState.selectedCustomer,
        kabContact: state.customerState.kabContactData[0],
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        addReadingForm: {
            formValue: getFormValues('AblesungHinzufuegenForm')(state),
            invalid: isInvalid('AblesungHinzufuegenForm')(state),
        },
        editReadingForm: {
            formValue: getFormValues('AblesungEditForm')(state),
            invalid: isInvalid('AblesungEditForm')(state),
        },
        facilityZaehlDatenForm: {
            formValue: getFormValues('FacilityZaehlDatenForm')(state),
            invalid: isInvalid('FacilityZaehlDatenForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            DevicesActions,
            MeterPanelsActions,
            FacilityActions,
            AuftragActions
        ),
        dispatch
    );
};

export const SlMeterReading = connect<ISlDevicesETDE, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<ISlDevicesETDE>(MaterialUI.withStyles(styles)(SlMeterReadingComponent));
