import axios from 'axios';
import { addLoadingState, removeLoadingState } from '../../../../common/redux/actions/global.action';
import { IReduxState } from '../../../../model/redux-state';

import { saveArchives, saveFileBase64 } from '../actions/archive.actions';
import { ArchiveActionTypes } from '../types/archive.types';


export const getArchives = (kabID: string, aId: string) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(ArchiveActionTypes.FETCH_ARCHIVES_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${aId}/archiv`);
            dispatch(saveArchives(response.data ? [...response.data] : []));
            dispatch(removeLoadingState(ArchiveActionTypes.FETCH_ARCHIVES_LOADING));
        } catch (error) {
            dispatch(saveArchives([]));
        }
    };
};

export const postArchives = (kabID: string, aId: string, payload: any) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(ArchiveActionTypes.UPLOAD_ARCHIVES_LOADING));
            await axios.post(`kabs/${kabID}/anlagen/${aId}/archiv`, payload);
            dispatch(removeLoadingState(ArchiveActionTypes.UPLOAD_ARCHIVES_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(ArchiveActionTypes.UPLOAD_ARCHIVES_LOADING));
        }
    };
};
export const removeArchive = (kabID: string, aId: string, filename: string) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(ArchiveActionTypes.REMOVE_ARCHIVES_LOADING));
            await axios.delete(`kabs/${kabID}/anlagen/${aId}/archiv/${filename}`);
            dispatch(removeLoadingState(ArchiveActionTypes.REMOVE_ARCHIVES_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(ArchiveActionTypes.REMOVE_ARCHIVES_LOADING));
        }
    };
};
export const downloadArchive = (kabID: string, aId: string, filename: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(ArchiveActionTypes.DOWNLOAD_ARCHIVES_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${aId}/archiv/${filename}`);
            dispatch(saveFileBase64(response.data));
            dispatch(removeLoadingState(ArchiveActionTypes.DOWNLOAD_ARCHIVES_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(ArchiveActionTypes.DOWNLOAD_ARCHIVES_LOADING));
        }
    };
};

