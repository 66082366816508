import { getContactData, getCustomerById } from '../../../customers/redux/thunk/customer.operations';
import { getContactPersons } from '../../../facilities/redux/thunk/contact.operations';
import { getFacilityById } from '../../../facilities/redux/thunk/facility.operations';
import { getMeterPanel } from './meterpanel.operations';

export const fetchMeterPanelData = (kabID: string, aID: string, zID: string) => {
    return async (dispatch) => {
        try {
            dispatch(getCustomerById(kabID));
            dispatch(getFacilityById(kabID, aID));
            dispatch(getMeterPanel(kabID, aID, zID));
            dispatch(getContactPersons(kabID, aID));
            dispatch(getContactData(kabID));

        } catch (error) {
        }
    };
};