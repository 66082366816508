import {WithStyles} from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import {SlimDeviceType} from '../interfaces/slim.interface';
import {BasicTable, IMuiTableColumn} from "../../../common/components/table/mui-table.component";
import {IsoformatToGermanDate} from "../../../common/utils";
import {deTranslator} from "../../../assets/i18n/deTranslator";
import {numberFormatter} from "../../../common/utils/Functions";
import {MaterialUIIcons} from "../../../materialUI/MaterialUIIcons";

const styles = () =>
    MaterialUI.createStyles({});

interface IMeterReadingProps extends WithStyles<typeof styles> {
    device: SlimDeviceType;
    onEdit: (reading: any) => void;
}


class MeterReadingComponent extends React.Component<IMeterReadingProps, any> {
    private readonly readingTableColumns: Array<IMuiTableColumn> = [];

    constructor(props: IMeterReadingProps) {
        super(props);

        this.readingTableColumns = [
            {
                title: 'Datum',
                field: 'Ablesedatum',
                defaultSort: 'desc',
                render: rowData => IsoformatToGermanDate(rowData.Ablesedatum),
            },
            {
                title: `Stand HT (${deTranslator.einheit.kilowatt})`,
                field: 'ZaehlerstandHT',
                emptyValue: '0',
                render: rowData => (rowData ? numberFormatter(rowData['ZaehlerstandHT']) : 0),
            },
            {
                title: `Verbrauch HT (${deTranslator.einheit.kilowatt})`,
                field: 'Verbrauch',
                render: rowData => rowData && numberFormatter(rowData['Verbrauch']),
                emptyValue: '0',
            },
            {
                title: `Korrigierter Verbrauch (${deTranslator.einheit.kilowatt})`,
                field: 'KorrigierterVerbrauch',
                render: rowData => rowData && numberFormatter(rowData['KorrigierterVerbrauch']),
                emptyValue: '0 ',
            },
            {title: 'Status', field: 'Status'},
            {title: 'Wandlerfaktor', field: 'Wandlerfaktor'},
            {title: 'Ablesegrund', field: 'Ablesegrund'},
        ];
    }
    private getConsumption = (currentReading, beforeReading, ZaehlerTyp) => {
        let consumption = 0;
        if (ZaehlerTyp === 'Mieterstromzaehler') {
            consumption = (currentReading.ZaehlerstandHT || 0) - (beforeReading.ZaehlerstandHT || 0);
        }

        return consumption

    }

    private getTableData = () => {
        let result = [];
        const {device}= this.props;

        if (device && device.ZaehlerTyp !== "Reguliertezaehler" && device.ablesungList) {
            const {items} = device.ablesungList;
            // Sorting readings by date in descending order
            const sortedReadings = items.slice().sort((a, b) => new Date(b.Ablesedatum).getTime() - new Date(a.Ablesedatum).getTime());

             result = sortedReadings.map((currentReading, index) => {
                const nextReading = sortedReadings[index + 1];
                const verbrauch = nextReading ? this.getConsumption(currentReading, nextReading, currentReading.ZaehlerTyp) : 0;
                const wandlerfaktor = device['Wandlerfaktor'] || '';
                const consumption = verbrauch * Number(wandlerfaktor || 1);

                return {
                    ...currentReading,
                    Wandlerfaktor: wandlerfaktor,
                    Verbrauch: consumption,
                };
            });
        }

        return result;
    };


    public render() {
        const {device, onEdit} = this.props;
        return (
            <BasicTable
                columns={this.readingTableColumns}
                title={`Zählerstände - ${device.Seriennummer}`}
                options={{toolbar: true, actionsColumnIndex: -1}}
                data={[...this.getTableData()]}
                actions={[
                    {
                        icon: () => (
                            <MaterialUI.IconButton color="secondary">
                                <MaterialUIIcons.Edit/>
                            </MaterialUI.IconButton>
                        ),
                        tooltip: '',
                        onClick: (event, rowData) => {
                            onEdit(rowData)
                        },
                    },
                ]}
            />
        );
    }
}

export const MeterReading = MaterialUI.withStyles(styles)(MeterReadingComponent);
