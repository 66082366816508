import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { IKAB } from '../../../common/interfaces/interface';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { titleStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        container: {
            marginBottom: 24,
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            minHeight: 20,
        },
        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        mediumInput: {
            width: '70%',
        },
        smallInput: {
            width: '30%',
        },
        marginRight: {
            marginRight: 24,
        },
        marginLeft: {
            marginLeft: 24,
        },
    });

export interface IKabZusatzinfo extends WithStyles<typeof styles> {
    selectedCustomer: IKAB;
}

class KabZusatzinfoComponent extends React.Component<IKabZusatzinfo, {}> {
    public render() {
        const {
            Nachname = '',
            Vorname = '',
            Rechtsform = '',
            Finanzamt = '',
            Handelsregisternumer = '',
            Registergericht = '',
            Hauptzollamt = '',
        } = this.props.selectedCustomer.Zusatzinfo || {};
        return (
            <MaterialUI.Paper style={{ marginTop: 24, padding: 15 }}>
                <MaterialUI.Typography variant="h5">Weitere Informationen</MaterialUI.Typography>

                <MaterialUI.Grid container={true} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Vorname Geschäftsführer/in
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Vorname}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Nachname Geschäftsführer/in
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Nachname}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Rechtsform
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Rechtsform}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Zuständiges Finanzamt
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Finanzamt}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Handelsregisternumer
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Handelsregisternumer}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Registergericht
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Registergericht}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={6}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                className={this.props.classes.label}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                variant="subtitle2"
                            >
                                Hauptzollamt
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                className={this.props.classes.input}
                                color={'inherit'}
                            >
                                {Hauptzollamt}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </MaterialUI.Paper>
        );
    }
}

export const SlKabZusatzinfoComponentDetails = MaterialUI.withStyles(styles)(
    KabZusatzinfoComponent
);
