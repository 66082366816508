import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import axios from 'axios';
import * as React from 'react';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { downloadFileFromBase64String, isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';
import { LoadingComponent } from '../../../../common/components/buttons';
import { fetchSlimDataAsCSV } from '../../../slim/slim-api/kundenanlagenbetreibers-api/kundenanlagenbetreibers.api';


const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
        loadingIconContainer: {
            width: '100%',
            position: 'absolute',
            top: '0',
            right: '0',
        },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    loading: boolean;
}

export interface IGeraeteSettingProps extends WithStyles<typeof styles> {
}

class GeraeteSettingComponent extends React.Component<IGeraeteSettingProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IGeraeteSettingProps) {
        super(props);
        this.actionBarItems = [
            {
                label: 'Technik CSV Herunterladen',
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.handleTechnikCSVDownload(),
                disable: false,
            },
            {
                label: 'Slim CSV Herunterladen',
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.handleSlimCSVDownload(),
                disable: false,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            loading: false,
        };
    }

    public handleTechnikCSVDownload = async () => {
        try {
            this.setState({ ...this.state, loading: true });
            const response = await axios.get(`settings/download/devices`);
            const result = response.data;

            downloadFileFromBase64String(
                result.FileContents,
                result.FileName + '.csv',
                result.ContentType,
            );
            this.setState({ ...this.state, loading: false });
        } catch (e) {
            this.setState({ ...this.state, loading: false });
        }
    };



    public handleSlimCSVDownload = async () => {
        try {
            this.setState({ ...this.state, loading: true });
            const data = await fetchSlimDataAsCSV();
            let date = new Date()
                .toISOString()
                .substring(0, 10)
                .split('-')
                .reverse()
                .join('');
            const stage =
                process.env.REACT_APP_STAGE === 'prod' ? '' : process.env.REACT_APP_STAGE + '-';
            downloadFileFromBase64String(
                data.data_base64,
                stage + 'Slim-Geräteübersicht-' + date + '.csv',
                'text/csv',
            );
            this.setState({ ...this.state, loading: false });
        } catch (e) {
            this.setState({ ...this.state, loading: false });
        }
    };

    public render() {
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Geräteübersicht CSV
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.state.tableActionBarItems} showDivider={false} />
                    )}
                </div>

                {this.state.loading && (
                    <div className={this.props.classes.loadingIconContainer}>
                        <LoadingComponent size={70} />
                    </div>
                )}
            </div>
        );
    }
}

export const SLGeraeteSettingComponent = MaterialUI.withStyles(styles)(GeraeteSettingComponent);
