import { WithStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { sbColors } from '../../../theme/app.colors';

export function DialogTransition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = () =>
    MaterialUI.createStyles({
        toolbar: {
            backgroundColor: sbColors.blue,
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

export interface ISLModalProps extends WithStyles<typeof styles> {
    open: boolean;
    hideClose?: boolean;
    title?: string;
    children?: React.ReactNode;
    onClose?: () => void;
    fullScreen?: boolean;
    onOkButtonClick: () => void;
    disableEscapeKeyDown?: boolean;
    disableBackdropClick?: boolean;
    disableAutoFocus?: boolean;
    OkButtonDisabled?: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
    fullWidth?: boolean;
    showActionButtons?: boolean;
    maxWidth?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl';
}

class SLModalComponent extends React.Component<ISLModalProps, {}> {
    public static defaultProps: Partial<ISLModalProps> = {
        hideClose: false,
        disableEscapeKeyDown: false,
        disableBackdropClick: true,
        disableAutoFocus: true,
        fullScreen: false,
        title: '',
        okButtonText: 'SPEICHERN',
        cancelButtonText: 'Abbrechen',
        fullWidth: true,
        showActionButtons: true,
        maxWidth: 'lg',
    };

    public handleClose = () => {
        this.props.onClose();
    };

    public render() {
        return (
            <div>
                <MaterialUI.Dialog
                    // TransitionComponent={DialogTransition}
                    fullScreen={this.props.fullScreen}
                    maxWidth={this.props.maxWidth}
                    fullWidth={this.props.fullWidth}
                    disableAutoFocus={this.props.disableAutoFocus}
                    open={this.props.open}
                    onClose={this.handleClose}
                    disableEscapeKeyDown={this.props.disableEscapeKeyDown}
                    disableBackdropClick={this.props.disableBackdropClick}
                >
                    <MaterialUI.DialogTitle
                        disableTypography={true}
                        className={this.props.classes.toolbar}
                    >
                        <MaterialUI.Typography variant="h5" color={'inherit'}>
                            {this.props.title}
                        </MaterialUI.Typography>
                        <MaterialUIIcons.Close
                            onClick={this.handleClose}
                            style={{ cursor: 'pointer' }}
                            color={'inherit'}
                        />
                    </MaterialUI.DialogTitle>

                    <MaterialUI.DialogContent style={{ overflow: 'auto' }}>
                        {this.props.children}
                    </MaterialUI.DialogContent>

                    {this.props.showActionButtons && (
                        <MaterialUI.DialogActions>
                            <MaterialUI.Button
                                variant="outlined"
                                color="primary"
                                onClick={this.handleClose}
                            >
                                {this.props.cancelButtonText}
                            </MaterialUI.Button>
                            <MaterialUI.Button
                                variant="contained"
                                color="primary"
                                onClick={this.props.onOkButtonClick}
                                disabled={this.props.OkButtonDisabled}
                            >
                                {this.props.okButtonText}
                            </MaterialUI.Button>
                        </MaterialUI.DialogActions>
                    )}
                </MaterialUI.Dialog>
            </div>
        );
    }
}

export const SLModal = MaterialUI.withStyles(styles)(SLModalComponent);
