export const MeterPanelsActionTypes = {
    METERPANEL_IS_LOADING: 'METERPANEL_IS_LOADING',
    SAVE_SELECTED_METERPANELS: 'SAVE_SELECTED_METERPANEL',
    SAVE_METERPANELS: 'SAVE_METERPANELS',
    ADDED_METERPANELS_START_ID: 'ADDED_METERPANELS_START_ID',
    ADDED_METERPANELS_NUMBER: 'ADDED_METERPANELS',
    POST_METERPANEL_LOADING_STATUS: 'POST_METERPANEL_LOADING_STATUS',
    POST_APPENDDEVICES_LOADING_STATUS: 'POST_APPENDDEVICES_LOADING_STATUS',
    SAVE_METERS_ETDT: 'SAVE_METERS_ETDT',
    SAVE_SELECTED_METER: 'SAVE_SELECTED_METER',
    SAVE_SELECTED_METER_ETDT: 'SAVE_SELECTED_METER_ETDT',
    SAVE_ALL_SELECTED_METERS: 'SAVE_ALL_SELECTED_METERS',
    POST_DEVICE_SETUP_LOADING_STATUS: 'POST_DEVICE_SETUP_LOADING_STATUS',
};