import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import uuid from 'uuid';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { history } from '../../../store';
import { sbColors } from '../../../theme/app.colors';
import { capitalizeFirstLetter } from '../../utils/Functions';

const styles = MaterialUI.createStyles({
    container: {
        padding: 0,
        display: 'flex',
        paddingTop: 0,
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        cursor: 'pointer',
        background: 'transparent',
        '&:hover': {
            color: 'red',
        },
    },
    separator: { paddingLeft: 0, paddingRight: 0, paddingTop: 5 },
    textStyle: {
        textDecoration: 'none',
        '&:hover': {
            color: sbColors.blue,
        },
    },
    backButton: {
        cursor: 'pointer',
        marginRight: 10,
        paddingTop: 5,
    },
});

export interface ISLBreadCrumb {
    label: string;
    path: string;
}

export interface IBreadcrumbsProps extends WithStyles<typeof styles> {
    items: Array<ISLBreadCrumb>;
    itemOnClick: (path: ISLBreadCrumb) => void;
    showOnlyBackArrow?: boolean;
}

class Breadcrumbs extends React.Component<IBreadcrumbsProps, {}> {
    private renderItem(item: ISLBreadCrumb, index: number) {
        const isLastItem = this.props.items.length - 1 === index;
        const lastFonSize = isLastItem ? 'h5' : 'h6';
        const textColor = isLastItem ? 'secondary' : 'primary';
        return (
            <div key={uuid.v4()} className={this.props.classes.item} data-testid="breadcrumbs-item">
                <MaterialUI.Typography
                    variant={lastFonSize}
                    color={textColor}
                    onClick={() => !isLastItem && this.props.itemOnClick(item)}
                    className={this.props.classes.textStyle}
                >
                    {capitalizeFirstLetter(item.label)}
                </MaterialUI.Typography>
                {this.props.items.length - 1 !== index && (
                    <span className={this.props.classes.separator}>
                        {<MaterialUIIcons.KeyboardArrowRight color={'secondary'} />}
                    </span>
                )}
            </div>
        );
    }

    public render() {
        return (
            <div className={this.props.classes.container}>
                {this.props.showOnlyBackArrow && (
                    <MaterialUIIcons.ArrowBack
                        onClick={() => history.goBack()}
                        color={'secondary'}
                        className={this.props.classes.backButton}
                    />
                )}
                {this.props.items.length > 0 && (
                    <MaterialUIIcons.ArrowBack
                        onClick={() =>
                            this.props.itemOnClick(this.props.items[this.props.items.length - 2])
                        }
                        color={'secondary'}
                        className={this.props.classes.backButton}
                    />
                )}
                {this.props.items.map((item: ISLBreadCrumb, index) => this.renderItem(item, index))}
            </div>
        );
    }
}

export const SlBreadcrumbsComponent = MaterialUI.withStyles(styles)(Breadcrumbs);
