import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import uuid from 'uuid';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { IDevice } from '../../../../common/interfaces/interface';
import { currencyFormatter } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { sbColors } from '../../../../theme/app.colors';
import { titleStyles } from '../../../../theme/common.styles';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        infoPaper: {
            minWidth: '90%',
            padding: 20,
            margin: 'auto',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            width: '100%',
            overflow: 'hidden',
        },

        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
    });

export interface IDeviceInfoProps extends WithStyles<typeof styles> {
    device: IDevice;
}

class DeviceInfo extends React.Component<IDeviceInfoProps, {}> {
    private readonly deviceInfo = [
        { label: 'GeraeteID', key: 'GeraeteID' },
        { label: 'Grundtyp', key: 'Grundtyp' },
        { label: 'Ablauf Eichgültigkeit', key: 'Eichdatum' },
        { label: 'Geräte-PIN', key: 'GeraetePIN' },
        { label: deTranslator.device.ZaehlwerkVKS, key: 'ZaehlwerkVKS' },
        { label: deTranslator.device.ZaehlwerkNKS, key: 'ZaehlwerkNKS' },
        { label: 'Obis Kennziffer HT', key: 'ObisCodeHT' },
        { label: 'Obis Kennziffer NT', key: 'ObisCodeNT' },
        { label: 'Produkte', key: 'Produkte' },
        { label: 'Wandlerfaktor', key: 'Wandlerfaktor' },
        { label: 'Wandler-ID 1', key: 'WandlerID1' },
        { label: 'Wandler-ID 2', key: 'WandlerID2' },
        { label: 'Wandler-ID 3', key: 'WandlerID3' },
    ];

    public renderItem = (text, key) => {
        return (
            <MaterialUI.Typography
                noWrap={true}
                key={key}
                color={'inherit'}
                className={`${this.props.classes.input}`}
            >
                {text}
            </MaterialUI.Typography>
        );
    };

    public render() {
        return (
            <MaterialUI.Paper elevation={2} className={this.props.classes.infoPaper}>
                <MaterialUI.Typography
                    variant={'h5'}
                    color={'inherit'}
                    className={`${this.props.classes.label} `}
                >
                    Informationen Gerät
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ padding: 20 }}>
                    <MaterialUI.Grid container={true} direction={'row'} spacing={5}>
                        {this.deviceInfo.map(item => (
                            <MaterialUI.Grid
                                item={true}
                                xs={3}
                                key={uuid.v4()}
                                style={{ paddingBottom: 0 }}
                            >
                                <MaterialUI.Typography
                                    variant={'body2'}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    className={`${this.props.classes.label}`}
                                >
                                    {item.label}
                                </MaterialUI.Typography>

                                {item.key === 'Grundtyp' &&
                                    this.renderItem(
                                        this.props.device[item.key]['grundTyp'],
                                        item.key
                                    )}
                                {item.key !== 'Grundtyp' &&
                                    item.key !== 'Produkte' &&
                                    this.renderItem(this.props.device[item.key], item.key)}
                                {item.key === 'Produkte' &&
                                    this.props.device.Produkte.map(product =>
                                        this.renderItem(
                                            `${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`,
                                            product.id
                                        )
                                    )}
                            </MaterialUI.Grid>
                        ))}
                    </MaterialUI.Grid>
                </div>
            </MaterialUI.Paper>
        );
    }
}

export const SLDeviceInfo = MaterialUI.withStyles(styles)(DeviceInfo);
