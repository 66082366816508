export const SlimActionTypes = {
    SAVE_SLIM_CUSTOMERS: 'SAVE_SLIM_CUSTOMERS',
    SAVE_SELECTED_SLIM_CUSTOMER: 'SAVE_SELECTED_SLIM_CUSTOMER',
    SAVE_CUSTOMERACTIVETAB: 'SAVE_CUSTOMERACTIVETAB',
    SAVE_SLIM_KUNDENANLAGEN: 'SAVE_SLIM_KUNDENANLAGEN',
    SAVE_SLIM_SELECTED_KUNDENANLAGE: 'SAVE_SLIM_SELECTED_KUNDENANLAGE',
    SAVE_SLIM_ANLAGE_KONTAKTPERSON: 'SAVE_SLIM_ANLAGE_KONTAKTPERSON',
    SAVE_SLIM_ANLAGE_ACTIVETAB: 'SAVE_SLIM_ANLAGE_ACTIVETAB',
    SAVE_SLIM_ANLAGE_ZAEHLERPLATZ: 'SAVE_SLIM_ANLAGE_ZAEHLERPLATZ',
    SAVE_SLIM_ANLAGE_Geraete: 'SAVE_SLIM_ANLAGE_Geraete',
    SAVE_SLIM_ANLAGE_Sensors: 'SAVE_SLIM_ANLAGE_Sensors',
    SET_SLIM_REFRESH_NOTIFICATION: 'SET_SLIM_REFRESH_NOTIFICATION',
    SAVE_SLIM_CSV:'SAVE_SLIM_CSV',
};
