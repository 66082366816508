import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { IMeterPlace } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { Montage, SB_GeraetTyp_Enum } from '../../../../model/enums';
import { sbColors } from '../../../../theme/app.colors';
import { titleStyles } from '../../../../theme/common.styles';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        infoPaper: {
            minWidth: '90%',
            padding: 20,
            margin: 'auto',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            width: '100%',
            overflow: 'hidden',
        },

        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
    });

export interface IMeterPanelInfoProps extends WithStyles<typeof styles> {
    meterPanel: IMeterPlace;
}

class MeterPanelInfo extends React.Component<IMeterPanelInfoProps, {}> {
    private readonly meterPanelInfo = [
        { label: 'Wohnungs-Nr.', key: 'WohnungNr' },
        { label: 'Gebäude', key: 'Gebaeude' },
        { label: 'Stockwerk', key: 'Stockwerk' },
        { label: deTranslator.meterPanel.location, key: 'Lage' },
        { label: 'Aktion Beauftragt', key: 'AuftragAktion' },
        { label: 'Messlokations-ID', key: 'Melo' },
        { label: 'Marktlokations-ID', key: 'Malo' },
        { label: 'Montageart', key: 'Montageart' },
        { label: deTranslator.meterPanel.Verbrauchsprognose, key: 'Verbrauchsprognose' },
    ];

    private renderItem(data, key) {
        const meterPanelInfoHandler = {
            WohnungNr: data[key],
            Gebaeude: data[key],
            Stockwerk: data[key],
            Lage: data[key],
            Melo: data[key],
            Malo: data[key],
            Montageart: Montage[data[key]] || data[key],
            Verbrauchsprognose: Math.round(data[key] ? data[key] : 0),
        };

        return meterPanelInfoHandler[key];
    }

    public render() {
        /* const Verbrauchsprognose = this.props.meterPanel.Verbrauchsprognose
            ? Number(this.props.meterPanel.Verbrauchsprognose.replace(/,/g, '.'))
            : 0; */
        return (
            <MaterialUI.Paper elevation={2} className={this.props.classes.infoPaper}>
                <MaterialUI.Typography
                    variant={'h5'}
                    color={'inherit'}
                    className={`${this.props.classes.label} `}
                >
                    Informationen Zählerplatz
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ padding: 20 }}>
                    <MaterialUI.Grid container={true} direction={'row'} spacing={5}>
                        {this.meterPanelInfo.map(item => (
                            <MaterialUI.Grid
                                item={true}
                                xs={3}
                                key={item.key}
                                style={{ paddingBottom: 0 }}
                            >
                                <MaterialUI.Typography
                                    variant={'body2'}
                                    style={titleStyles.fontWeight350}
                                    color={'inherit'}
                                    className={`${this.props.classes.label}`}
                                >
                                    {item.label}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    noWrap={true}
                                    color={'inherit'}
                                    className={`${this.props.classes.input}`}
                                >
                                    {this.renderItem(this.props.meterPanel, item.key)}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        ))}
                        <MaterialUI.Grid
                            item={true}
                            xs={3}
                            key={'Geraetetyp'}
                            style={{ paddingBottom: 0 }}
                        >
                            <MaterialUI.Typography
                                variant={'body2'}
                                style={titleStyles.fontWeight350}
                                color={'inherit'}
                                className={`${this.props.classes.label}`}
                            >
                                Gerätetyp
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                noWrap={true}
                                color={'inherit'}
                                className={`${this.props.classes.input}`}
                            >
                                {SB_GeraetTyp_Enum[this.props.meterPanel['GeraetTyp']]}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </div>
            </MaterialUI.Paper>
        );
    }
}

export const SLMeterPanelInfo = MaterialUI.withStyles(styles)(MeterPanelInfo);
