import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IGeraeteProps {
    color: any
    fontSize?: string;
}

class Geraete extends React.Component<IGeraeteProps, {}> {




    public render() {
        return (
            <MaterialUI.SvgIcon style={{padding: 'inherit', fontSize: this.props.fontSize}}>
                <path
                    d="M16 4.29153e-08C15.5 4.29153e-08 15 0.17 14.59 0.58C11.72 3.45 6 9.17 6 9.17L7.5 10.67L4 14.17H2L0 18.17L2 20.17L6 18.17V16.17L9.5 12.67L11 14.17C11 14.17 16.72 8.45 19.59 5.58C20.21 4.67 20.37 3.54 19.59 2.76L17.41 0.58C17 0.17 16.5 4.29153e-08 16 4.29153e-08ZM16 2.17L18 4.17L11 11.17L9 9.17L16 2.17Z"
                    transform="translate(2 1)"
                    fill={this.props.color}
                    fillOpacity={this.props.color.length > 0 ? '1' : '0.54'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6484 1.3479L9.57031 0.179932C9.41016 0.0600586 9.20996 0 9 0C8.79004 0 8.58984 0.0600586 8.42969 0.179932L0.530273
          4.62012C0.209961 4.79004 0 5.12012 0 5.5V14.5C0 14.7769 0.111328 15.0276 0.295898 15.2087L1.5 12.5H4L4.43848 12.0483C4.15234 
          11.4102 4 10.7129 4 10C4 8.67383 4.52637 7.4021 5.46484 6.46436C6.13086 5.7981 6.96582 5.33911 7.86914 5.12939C8.71387 4.28394 
          10.0938 2.90283 11.6484 1.3479ZM13.6533 11.8308C14.5195 10.9431 16.1562 9.26758 18 7.38672V14.5C18 14.8799 17.79 15.21 17.4697 
          15.3799L9.57031 19.8201C9.41016 19.9399 9.20996 20 9 20C8.79004 20 8.58984 19.9399 8.42969 19.8201L5.35742 18.0933L6.5 17.5V15.5L7.26758 14.6902C7.81738 
          14.8931 8.40332 15 9 15C10.3262 15 11.5977 14.4731 12.5352 13.5356C13.0254 13.0454 13.4043 12.4636 13.6533 11.8308Z"
                    transform="translate(2 2)"
                    fill={this.props.color}
                    fillOpacity={this.props.color.length > 0 ? '1' : '0.54'}
                />
            </MaterialUI.SvgIcon>
        );
    }


}

export const GeraeteIcon = (Geraete);
