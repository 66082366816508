import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlAlertComponent } from '../../../common/components/modal';
import { IFacility } from '../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../model/enums';

const styles = () =>
    MaterialUI.createStyles({
        facilityId: {
            fontSize: 18,
            paddingTop: '5px',
            lineHeight: '1.8em',
        },
        cardContainer: { position: 'relative', height: '100%' },
        icon: { right: 0, bottom: 0, position: 'absolute' },
        card: {
            width: 330,
            cursor: 'pointer',
        },
        actions: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    });

export interface IFacilityCardComponentProps extends WithStyles<typeof styles> {
    facility: IFacility;
    deleteFacility: (facility: IFacility) => void;
    onSelected: (facility: IFacility) => void;
}

interface IState {
    open: boolean;
    elevation: number;
    openDeleteAlert: boolean;
}

class FacilityCard extends React.Component<IFacilityCardComponentProps, IState> {
    private readonly contractEnd: string = '';

    constructor(props: IFacilityCardComponentProps) {
        super(props);
        this.state = {
            open: false,
            elevation: 1,
            openDeleteAlert: false,
        };
        if (this.props.facility && this.props.facility.VertragLaufzeitItems) {
            const { VertragLaufzeitItems } = this.props.facility;
            this.contractEnd = VertragLaufzeitItems[0].Ende
                ? IsoformatToGermanDate(VertragLaufzeitItems[0].Ende)
                : '';
        }
    }

    private handleOndelete = () => {
        this.setState({ openDeleteAlert: true });
    };

    private deleteCard = () => {
        this.props.deleteFacility(this.props.facility);
        this.setState({ openDeleteAlert: false });
    };
    private selectCard = () => {
        this.props.onSelected(this.props.facility);
    };

    private cancelDelete = () => {
        this.setState({ openDeleteAlert: false });
    };

    public render() {
        const vertragStart = IsoformatToGermanDate(
            this.props.facility.VertragLaufzeitItems[0].Start
        );
        const hsNr = this.props.facility.Adresse.HausNummer
            ? this.props.facility.Adresse.HausNummer
            : '';
        return (
            <div>
                <MaterialUI.Card
                    className={this.props.classes.card}
                    onMouseOver={() => this.setState({ elevation: 7 })}
                    onMouseOut={() => this.setState({ elevation: 1 })}
                    elevation={this.state.elevation}
                >
                    <MaterialUI.CardHeader
                        onClick={this.selectCard}
                        title={this.props.facility.ID}
                        subheader={''}
                    />

                    <MaterialUI.CardContent
                        onClick={() => this.props.onSelected(this.props.facility)}
                    >
                        <MaterialUI.Typography noWrap={true}>
                            {`${this.props.facility.Adresse.Strasse} ${hsNr}`}
                        </MaterialUI.Typography>
                        <MaterialUI.Typography noWrap={true}>
                            {`${this.props.facility.Adresse.PLZ} ${this.props.facility.Adresse.Ort}`}
                        </MaterialUI.Typography>
                        <MaterialUI.Typography variant={'caption'}>
                            {`${vertragStart} - ${this.contractEnd}`}
                        </MaterialUI.Typography>
                    </MaterialUI.CardContent>
                    <MaterialUI.CardActions className={this.props.classes.actions}>
                        {isUserRole(Roles.Admin) && (
                            <MaterialUI.IconButton onClick={this.handleOndelete}>
                                <MaterialUIIcons.DeleteOutline color={'secondary'} />
                            </MaterialUI.IconButton>
                        )}
                    </MaterialUI.CardActions>
                </MaterialUI.Card>
                <SlAlertComponent
                    dialogTitle={deTranslator.facility.alert.title}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.openDeleteAlert}
                    handleOK={this.deleteCard}
                    handleCancel={this.cancelDelete}
                    showCancelButton={true}
                >
                    {`${deTranslator.facility.alert.contentStart}`}
                    {this.props.facility && <b>{this.props.facility.ID}</b>}
                    {`${deTranslator.facility.alert.contentEnd}`}
                </SlAlertComponent>
            </div>
        );
    }
}

export const SlFacilityCard = MaterialUI.withStyles(styles)(FacilityCard);
