export const createGeraet = /* GraphQL */ `
    mutation CreateGeraet($input: CreateGeraetInput!, $condition: ModelGeraetConditionInput) {
        createGeraet(input: $input, condition: $condition) {
            id
            organisation_id
            KID
            AID
            ZID
            Seriennummer
            ZaehlerTyp
            Einbaudatum
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            GeraetePIN
            Metergrid {
                url
                id
                serial
                panel
                conversion_factor
                owner
                project
            }
            AuftragAktion
            Ausbaudatum
            AusbaustandStatus
            Eichdatum
            ObisCodeHT
            ObisCodeNT
            GeraetTyp
            WandlerID1
            WandlerID2
            WandlerID3
            Wandlerfaktor
            ZaehlwerkNKS
            ZaehlwerkVKS
            Zaehlerversanddatum
            ablesungList {
                items {
                    id
                    organisation_id
                    geraet_id
                    Seriennummer
                    ZID
                    ZaehlerTyp
                    Ablesedatum
                    ZaehlerstandHT
                    Einspeisungstand
                    Strombezugstand
                    Ablesegrund
                    AndereAblesegrund
                    Status
                    VerbrauchHT
                    Unit
                    KorrigierterVerbrauch
                    Abschlagshoehe
                    Metergrid {
                        timestamp
                        value
                        project
                        url
                        id
                        measuring_point
                        obis_code
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const createMetergridZaehler = /* GraphQL */ `
    mutation CreateMetergridZaehler($input: MetergridZaehlerInput) {
        createMetergridZaehler(input: $input) {
            url
            id
            serial
            panel
            conversion_factor
            owner
            project
        }
    }
`;
export const updateGeraet = /* GraphQL */ `
    mutation UpdateGeraet($input: UpdateGeraetInput!, $condition: ModelGeraetConditionInput) {
        updateGeraet(input: $input, condition: $condition) {
            id
            organisation_id
            KID
            AID
            ZID
            Seriennummer
            ZaehlerTyp
            Einbaudatum
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            GeraetePIN
            Metergrid {
                url
                id
                serial
                panel
                conversion_factor
                owner
                project
            }
            AuftragAktion
            Ausbaudatum
            AusbaustandStatus
            Eichdatum
            ObisCodeHT
            ObisCodeNT
            GeraetTyp
            WandlerID1
            WandlerID2
            WandlerID3
            Wandlerfaktor
            ZaehlwerkNKS
            ZaehlwerkVKS
            Zaehlerversanddatum
            ablesungList {
                items {
                    id
                    organisation_id
                    geraet_id
                    Seriennummer
                    ZID
                    ZaehlerTyp
                    Ablesedatum
                    ZaehlerstandHT
                    Einspeisungstand
                    Strombezugstand
                    Ablesegrund
                    AndereAblesegrund
                    Status
                    VerbrauchHT
                    Unit
                    KorrigierterVerbrauch
                    Abschlagshoehe
                    Metergrid {
                        timestamp
                        value
                        project
                        url
                        id
                        measuring_point
                        obis_code
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;
