import {
    changeDevice,
    saveDevices,
    saveSelectedDevice,
    showOrderDeviceModal,
} from './actions/devices.actions';
import {
    getDevices,
    postDevices,
    updateDevices,
    getDeviceById,
    handleRemovingDevice,
    deleteDevices,
} from './thunk/devices.operations';
import { fetchDeviceData } from './thunk/device-data.operations';

// export reducers
export { devicesReducer } from './reducers/devices.reducer';

// export actions and thunk operations
export const DevicesActions = Object.assign(
    {},
    { getDevices: getDevices },
    { getDeviceById: getDeviceById },
    { saveDevices: saveDevices },
    { saveSelectedDevice: saveSelectedDevice },
    { showOrderDeviceModal: showOrderDeviceModal },
    { postDevices: postDevices },
    { updateDevices: updateDevices },
    { fetchDeviceData: fetchDeviceData },
    { handleRemovingDevice: handleRemovingDevice },
    { changeDevice: changeDevice },
    { deleteDevices: deleteDevices }
);
