import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

import { ICustomerState } from '../redux/reducers/customer.reducer';
import { PartnerComponent } from './partner.component';

const styles = () => MaterialUI.createStyles({
    container: {marginTop: 10}
});

export interface IBillToCustomerProps extends WithStyles<typeof styles> {

    personOrCompany?: string;
    mailBox?: string;
    street?: string;
    showTaxField: boolean;
    customerState?: ICustomerState;
    initialize: (customerdata: any) => void;
    editMode?: boolean;
}


class BillReceiverCustomer extends React.Component<IBillToCustomerProps, {}> {
    constructor(props: IBillToCustomerProps) {
        super(props);
        this.initializeData();
    }

    private initializeData() {
        if (this.props.editMode) {
            this.props.initialize(this.props.customerState.selectedDeviatinBillRecipient);
        } else {
            this.props.initialize(null);
        }
    }

    public render() {
        return <PartnerComponent
            personOrCompany={this.props.personOrCompany}
            mailBox={this.props.mailBox}
            street={this.props.street}
            showTaxField={false}/>;

    }

}

export const BillReceiverCustomerComponent = MaterialUI.withStyles(styles)(BillReceiverCustomer);

export const BillReceiverReduxForm = reduxForm({
    form: 'BillReceiver', // a unique identifier for this form
    destroyOnUnmount: false // dont delete the values when the form gets destroyed/tabs switch
})(BillReceiverCustomerComponent);

const selector = formValueSelector('BillReceiver');

const mapStateToProps = (state: IReduxState) => {
    const personOrCompany = selector(state, 'Anrede.Typ');
    const mailBox = selector(state, 'Adresse.Postfach');
    const street = selector(state, 'Adresse.Strasse');
    return {
        personOrCompany,
        mailBox,
        street,
    };
};


export const SLBillReceiverForm = connect(mapStateToProps)(BillReceiverReduxForm);
