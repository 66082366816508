import axios from 'axios';
import { addLoadingState, removeLoadingState } from '../../../../common/redux/actions/global.action';
import { IReduxState } from '../../../../model/redux-state';

import { saveBillAsCSVFile, saveFacilityBilling } from '../actions/facility-billing.action';
import { FacilityBillingActionTypes } from '../types/facility-billing.actions.types';

export const getFacilityBilling = (kabID: string, anlageId: string, from: string, to: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${anlageId}/abrechnung?von=${from}&bis=${to}`);
            dispatch(saveFacilityBilling(response.data ? response.data : null));
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));

        } catch (error) {
            dispatch(saveFacilityBilling(null));
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        }
    };
};

export const getFacilityBillingFile = (kabID: string, anlageId: string, from: string, to: string, format: string) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${anlageId}/abrechnung?von=${from}&bis=${to}&format=${format}`);
            dispatch(saveBillAsCSVFile(response.data ? response.data : null));
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        }
    };
};


export const updateBill = (kabID: string, anlageId: string, billId: string) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
            await axios.put(`kabs/${kabID}/anlagen/${anlageId}/rechnungen/${billId}`);

            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        }
    };
};

export const getBills = (kabID: string, anlageId: string) => {

    return async (dispatch) => {
        try {
            dispatch(addLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
            await axios.get(`kabs/${kabID}/anlagen/${anlageId}/rechnungen`);
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        } catch (error) {
            dispatch(removeLoadingState(FacilityBillingActionTypes.FACILITY_BILLING_IS_LOADING));
        }
    };
};


