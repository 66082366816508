import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { SlActionBar } from '../../../common/components/actionbar';
import { ISLActionBar } from '../../../common/components/actionbar/sl-actionbar.component';
import { inputDateMask } from '../../../common/components/forms/date-input.component';
import { SLModal } from '../../../common/components/modal';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import { IAuftrag, IDevice, IDeviceType, IProduct } from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import {
    defaultSort,
    germanDateToISODateFormat,
    IsoformatToGermanDate,
    isUserRole,
    removeEmptykey,
    stringToNumber,
} from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { AuftragType, GeraetTypEnum, Roles } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history, store } from '../../../store';
import { appStyles } from '../../../theme/common.styles';
import { AuftragActions } from '../../auftrag/redux';
import { CustomerActions } from '../../customers/redux';
import { DevicesActions } from '../../devices/redux';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { SlimActions } from '../redux';
import { AblesegrundEnums } from '../../../model/constants';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { GeraetewechselIcon } from '../../../common/components/svgIcons/Geraetewechsel';
import { sbColors } from '../../../theme/app.colors';
import { SlAuftragComponent } from '../../devices/components/form/slAuftragComponent';
import axios from 'axios';
import { GeraeteIcon } from '../../../common/components/svgIcons/Geraete';
import { ChangeSlimDevices } from '../forms/ChangeSlimDevice.form';
import Moment from 'moment';
import { IDevicesState } from '../../devices/redux/reducers/devices.reducer';
import { RemoveSlimDeviceForm } from '../forms/removeSlimDeviceForm';
import { AddSlimReadingDeviceForm } from '../forms/add-reading.form';
import {
    createMGAblesung,
    createSlimAblesung,
    updateSlimAblesung,
} from '../slim-api/ablesung-api/ablesung.api';
import { getObiscode } from '../redux/thunk/slimThunk';
import { addLoadingState, removeLoadingState } from '../../../common/redux/actions/global.action';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

export interface ISlimDevicesProps extends WithStyles<typeof styles> {
    kabId: string;
    aId: string;
    selectedSlimKundenAnlage: any;
    zaehlerplatzList: any[];
    productList: Array<IProduct>;
    deviceTypeList: Array<IDeviceType>;
    slimGeraete: { items: any[]; nextToken: any };
    deviceState?: IDevicesState;
    slimKab: any;
    changeDevice?: (previousDevice: any, currentDevice: any) => void;
    AnlageKontaktperson?: any[];
    facilityEmail?: any;
    auftragForm?: IForm;
    addDeviceForm?: IForm;
    removeDeviceForm?: IForm;
    addSlimReadingDeviceForm?: IForm;
    postSlimAuftrag: (data: IAuftrag) => { ID: string };
    fetchSlimKAZaehlerplatz: (kabId, anlageId) => void;
    getSlimDevices?: (AID: string) => void;
    fetchSlimKundenAnlagenByAID: (AID: string) => void;
    sendEmailSlimAuftrag: (
        payload: IAuftrag,
        auftragID: string,
        meterplaces?: Array<any>,
        deviceMeterpanels?: Array<any>
    ) => void;
    postSlimDevices: (kundenAnlage: any, zaehlerplatzList: any[], payload: any) => void;
    updateSlimDevices?: (payload: Array<any>) => void;
    ausbauSlimDevices?: (KA, data: any[]) => void;
    onDevicesChange: () => void;
}

interface IState {
    showOrderModal: boolean;
    actionBarItems: Array<ISLActionBar>;
    openVersandModal: boolean;
    showRemoveDeviceWarning: boolean;
    showOrderDeviceModal: boolean;
    showChangeDeviceModal: boolean;
    showRemoveDeviceModal: boolean;
    showAddReadingModal: boolean;
}
class SlimDevicesComponent extends React.Component<ISlimDevicesProps, IState> {
    private selectedRowItems: Array<any> = [];
    private versanddatum: string = '';
    private orderType: string = '';
    private orderTitle: string = '';
    private selectedDevices: Array<IDevice> = [];

    constructor(props: ISlimDevicesProps) {
        super(props);
        this.state = {
            showOrderModal: false,
            openVersandModal: false,
            showRemoveDeviceWarning: false,
            showOrderDeviceModal: false,
            showChangeDeviceModal: false,
            showRemoveDeviceModal: false,
            showAddReadingModal: false,
            actionBarItems: [
                {
                    label: 'Versanddatum',
                    icon: null,
                    action: () => this.setState({ ...this.state, openVersandModal: true }),
                    disable: true,
                },
                {
                    label: deTranslator.global.changeDevice,
                    action: () => this.handleChangesDevices(),
                    icon: <GeraetewechselIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: deTranslator.global.removeDeviceOrder,
                    action: () => this.handleAusbauAuftrag(),
                    icon: <GeraetewechselIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: deTranslator.global.deviceRemovalConfirmation,
                    action: () => this.handleRemoveDevicesConfirmation(),
                    icon: <GeraetewechselIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: 'Rückmeldung Gerätewechsel',
                    // action: () => console.log('Rückmeldung Gerätewechsel'),
                    action: () => this.handleChangesDevicesConfirmation(),
                    icon: <GeraeteIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: 'Ablesung Hinzufügen',
                    // action: () => console.log('Rückmeldung Gerätewechsel'),
                    action: () => this.handleAddReading(),
                    icon: <GeraeteIcon color={sbColors.orange} />,
                    disable: true,
                },
            ],
        };
    }

    private buildTableActions = (active: boolean) => {
        const newState = this.state.actionBarItems.map(item => {
            item.disable = !active;
            return item;
        });
        return [...newState];
    };
    private handleChangesDevices = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Wechselauftrag;
            this.orderTitle = deTranslator.global.changeDeviceOrder;
            const removedDevice = this.selectedRowItems.find(
                item => item.Ausbaudatum && item.Ausbaudatum !== ''
            );
            if (removedDevice) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };
    private handleAusbauAuftrag = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Ausbauauftrag;
            this.orderTitle = deTranslator.global.changeDeviceOrder;
            const removedDevice = this.selectedRowItems.find(
                item => item.Ausbaudatum && item.Ausbaudatum !== ''
            );
            if (removedDevice) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };

    private handleRemoveDevicesConfirmation() {
        if (this.selectedRowItems.length > 0) {
            this.selectedDevices = this.getSelectedDevices();
            if (this.findDeviceWithAusbaudatum(this.selectedDevices)) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showRemoveDeviceModal: true });
            }
        }
    }
    private handleChangesDevicesConfirmation() {
        if (this.selectedRowItems.length > 0) {
            this.selectedDevices = this.getSelectedDevices();
            if (this.findDeviceWithAusbaudatum(this.selectedDevices)) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showChangeDeviceModal: true });
            }
        }
    }

    private handleAddReading() {
        if (this.selectedRowItems.length > 0) {
            console.log('this.selectedRowItems: ', this.selectedRowItems);
            this.setState({ showAddReadingModal: true });
        }
    }

    private getSelectedDevices = () => {
        return this.selectedRowItems.map(item => {
            const { tableData, ...rest } = item;
            return {
                ...rest,
                GeraeteID: item.Seriennummer,
                Ablesungen: [...item.ablesungList.items].sort(
                    (a, b) => new Date(b.Ablesedatum).getTime() - new Date(a.Ablesedatum).getTime()
                ),
            };
        });
    };
    private findDeviceWithAusbaudatum = devices => {
        return devices.find(item => item.Ausbaudatum && item.Ausbaudatum !== '');
    };
    private getSelectedMeterPanels = () => {
        return this.getSelectedDevices()
            .filter(item => item)
            .map(device => device.ZID)
            .map(id => this.props.zaehlerplatzList.find(counterPlace => counterPlace.ZID === id));
    };

    private handleCheckBoxClick = items => {
        this.selectedRowItems = items.filter(row => {
            const ausbauStand = row.ablesungList.items.find(
                reading => reading.Ablesegrund === AblesegrundEnums.Ausbau
            );
            return !ausbauStand;
        });

        this.setState({
            ...this.state,
            actionBarItems: this.buildTableActions(items.length > 0),
        });
    };
    public goToDevice = ({ ZID, id }) => {
        const link = `/slim/kabs/${this.props.kabId}/${this.props.aId}/${ZID}/${id}`;
        history.push(link);
    };
    public handleVersanddatumChange = event => {
        this.versanddatum = event.target.value;
    };
    public updateVersanddatum = async () => {
        const devices = this.selectedRowItems.map(item => {
            return {
                id: item.id,
                Zaehlerversanddatum: germanDateToISODateFormat(this.versanddatum),
            };
        });
        this.setState({ ...this.state, openVersandModal: false });
        await this.props.updateSlimDevices(devices);
        this.props.onDevicesChange();
    };

    private doDevicesOrder = async () => {
        try {
            this.setState({ showOrderDeviceModal: false });
            const auftrag: any = Object.assign(
                {},
                {
                    Type: this.orderType,
                    DeviceIds: this.selectedRowItems.map(item => item.Seriennummer),
                    KID: this.props.kabId,
                    AID: this.props.aId,
                    Service: 'Slim',
                },
                { ...this.props.auftragForm.formValue }
            );

            const deviceMeterplaces = this.selectedRowItems.map(item => {
                const meterpanel = this.props.zaehlerplatzList.find(
                    meter => meter.ZID === item.ZID
                );
                return {
                    device: { ...item, GeraeteID: item.Seriennummer || '' },
                    meterplace: meterpanel,
                };
            });
            const response = await axios.post(`auftrag`, removeEmptykey(auftrag));
            await this.props.sendEmailSlimAuftrag(
                auftrag,
                response.data['ID'],
                [],
                deviceMeterplaces
            );

            this.selectedRowItems = [];
            this.selectedDevices = [];
        } catch (e) {}
    };

    public deviceChange = () => {
        const previousDevice = this.props.removeDeviceForm.formValue
            ? this.props.removeDeviceForm.formValue
            : this.props.deviceState.changedDevice.previousDevice;
        let currentDevice = this.props.addDeviceForm.formValue
            ? this.props.addDeviceForm.formValue
            : this.props.deviceState.changedDevice.currentDevice;

        if (!currentDevice && previousDevice.devices) {
            const nextDevice = [];
            for (const device of previousDevice.devices) {
                const meterPanel = this.props.zaehlerplatzList.find(
                    item => item.ZID === device.ZID
                );
                nextDevice.push({
                    WohnungNr: meterPanel.WohnungNr,
                    Gebaeude: meterPanel.Gebaeude,
                    ZID: meterPanel.ZID,
                    device: {
                        ZID: meterPanel.ZID,
                        GeraeteID: '',
                        GrundtypId: '',
                        Einbaudatum: Moment(device.Ausbaudatum)
                            .add(1, 'd')
                            .format('YYYY-MM-DD'),
                        Eichdatum: '',
                        ZaehlwerkNKS: null,
                        ZaehlwerkVKS: null,
                        ProduktIds: [],
                    },
                    Type: 'first',
                });
            }
            currentDevice = { devices: [...nextDevice] };
        }

        this.props.changeDevice(previousDevice, currentDevice);
    };

    private submitAppendDeviceForm = async () => {
        const payload = this.props.deviceState.changedDevice.currentDevice.devices.map(item => {
            const { GrundtypId, ProduktIds, GeraeteID, EinbaustandHT, ...rest } = item.device;
            return {
                KID: this.props.kabId,
                AID: this.props.aId,
                organisation_id: this.props.selectedSlimKundenAnlage.organisation_id,
                Seriennummer: GeraeteID,
                ZaehlerTyp: 'Mieterstromzaehler',
                Grundtyp: this.props.deviceTypeList.find(
                    deviceType => deviceType.id === GrundtypId
                ),
                Produkts: this.props.productList.filter(
                    product => ProduktIds.indexOf(product.id) >= 0
                ),
                Ablesungen: [
                    {
                        Ablesegrund: AblesegrundEnums.Einbau,
                        Ablesedatum: item.device.Einbaudatum,
                        ZaehlerstandHT: stringToNumber(item.device.EinbaustandHT) || 0,
                    },
                ],
                ...rest,
            };
        });
        await this.props.postSlimDevices(
            this.props.selectedSlimKundenAnlage,
            this.props.zaehlerplatzList,
            payload
        );
    };

    private handleRemoveDevices = async removedDevices => {
        await this.props.ausbauSlimDevices(this.props.selectedSlimKundenAnlage, removedDevices);
    };

    private saveChangedDevicesConfirmation = async () => {
        try {
            this.selectedDevices = [];
            this.selectedRowItems = [];
            this.setState({ showChangeDeviceModal: false });

            const removedDevices = this.props.deviceState.changedDevice.previousDevice.devices.map(
                item => {
                    const device = this.props.slimGeraete.items.find(data => data.id === item.id);
                    return {
                        id: item.id,
                        organisation_id: item.organisation_id,
                        geraet_id: item.id,
                        Seriennummer: item.Seriennummer,
                        ZID: item.ZID,
                        Ablesegrund: AblesegrundEnums.Ausbau,
                        Ausbaudatum: item.Ausbaudatum,
                        Ablesedatum: item.Ausbaudatum,
                        ZaehlerstandHT: stringToNumber(item.AusbaustandHT) || 0,
                        Metergrid: { ...device.Metergrid },
                    };
                }
            );
            await this.handleRemoveDevices(removedDevices);
            await this.submitAppendDeviceForm();

            this.props.getSlimDevices(this.props.aId);
            this.props.fetchSlimKundenAnlagenByAID(this.props.aId);
        } catch (e) {
            console.error(e);
        }
    };

    private ausbauAblesung = async () => {
        try {
            this.setState({ showRemoveDeviceModal: false });
            const { devices } = this.props.removeDeviceForm.formValue;

            await this.props.ausbauSlimDevices(
                this.props.selectedSlimKundenAnlage,
                devices.map(item => ({
                    id: item.id,
                    organisation_id: item.organisation_id,
                    geraet_id: item.id,
                    Seriennummer: item.Seriennummer,
                    ZID: item.ZID,
                    Ablesegrund: AblesegrundEnums.Ausbau,
                    Ausbaudatum: item.Ausbaudatum,
                    Ablesedatum: item.Ausbaudatum,
                    ZaehlerstandHT: stringToNumber(item.AusbaustandHT) || 0,
                    Metergrid: { ...item.Metergrid },
                }))
            );
            this.selectedRowItems = [];
            this.selectedDevices = [];
            this.props.onDevicesChange();
        } catch (e) {
            console.error(e);
        }
    };
    private addReading = async () => {
        try {
            this.setState({ showAddReadingModal: false });
            store.dispatch(addLoadingState('addReading'));

            for (const ablesung of this.props.addSlimReadingDeviceForm.formValue.Ablesungen) {
                const device = this.selectedRowItems.find(item => item.id === ablesung.geraet_id);
                // save in Slim
                const createdAblesung = await createSlimAblesung(ablesung);
                // save in metergrid
                const measuringPointData = await createMGAblesung({
                    measuring_point: device.Metergrid['url'] || '',
                    obis_code: getObiscode(device.ObisCodeHT), // obis_code
                    timestamp: new Date(ablesung.Ablesedatum).toISOString(), // date iso conform string 12h
                    value: (ablesung.ZaehlerstandHT || 0).toString(),
                    project: this.props.selectedSlimKundenAnlage.Metergrid.url, // projecturl
                });
                // update slim
                await updateSlimAblesung({
                    id: createdAblesung['id'],
                    Metergrid: { ...measuringPointData },
                });
                store.dispatch(removeLoadingState('addReading'));
            }
            this.props.getSlimDevices(this.props.aId);
            this.selectedRowItems = [];
            this.selectedDevices = [];
        } catch (e) {
            store.dispatch(removeLoadingState('addReading'));
            console.error(e);
        }
    };

    private deviceTableColumns = (): IMuiTableColumn[] => {
        return [
            {
                title: 'Geräte-ID',
                field: 'Seriennummer',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToDevice(rowData)}
                        >
                            {rowData.Seriennummer}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Wohnungs-Nr',
                field: 'WohnungNr',
                defaultSort: 'asc',
                customSort: (a, b) => {
                    const meterA = this.props.zaehlerplatzList.find(item => item.ZID === a.ZID);
                    const meterB = this.props.zaehlerplatzList.find(item => item.ZID === b.ZID);
                    if (meterA && meterB) {
                        return defaultSort(meterA.WohnungNr, meterB.WohnungNr);
                    }
                    return 0;
                },
                render: rowData => {
                    const meter = this.props.zaehlerplatzList.find(
                        item => item.ZID === rowData.ZID
                    );
                    return (
                        <MaterialUI.Typography>
                            {meter ? meter.WohnungNr : ''}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Gebäude',
                field: 'Gebaeude',
                render: rowData => {
                    const meter = this.props.zaehlerplatzList.find(
                        item => item.ZID === rowData.ZID
                    );
                    return (
                        <MaterialUI.Typography>
                            {meter.Gebaeude ? meter.Gebaeude : ''}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Grundtyp',
                field: 'Grundtyp',
                render: rowData => {
                    return rowData && rowData['GeraetTyp']
                        ? GeraetTypEnum[rowData['GeraetTyp']]
                        : '';
                },
            },
            {
                title: 'Einbaudatum',
                field: 'Einbaudatum',
                render: rowData => {
                    if (rowData) {
                        const einbauStand = rowData.ablesungList.items.find(
                            reading => reading.Ablesegrund === AblesegrundEnums.Einbau
                        );
                        return einbauStand ? IsoformatToGermanDate(einbauStand.Ablesedatum) : '';
                    }
                    return '';
                },
            },
            {
                title: 'Ausbaudatum',
                field: 'Ausbaudatum',
                render: rowData => {
                    if (rowData) {
                        const ausbauStand = rowData.ablesungList.items.find(
                            reading => reading.Ablesegrund === AblesegrundEnums.Ausbau
                        );
                        return ausbauStand ? IsoformatToGermanDate(ausbauStand.Ablesedatum) : '';
                    }
                    return '';
                },
            },
            {
                title: 'Ablauf Eichgültigkeit',
                field: 'Eichdatum',
                render: rowData => (rowData.Eichdatum ? rowData.Eichdatum : '-'),
            },
        ];
    };

    public render() {
        return (
            <div style={{ overflow: 'auto', height: 'calc(100vh - 165px)' }}>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.actionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.deviceTableColumns()}
                        options={{
                            paging: false,
                            pageSize: 10,
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 275px)',
                            selectionProps: rowData => {
                                const ausbauStand = rowData.ablesungList.items.find(
                                    reading => reading.Ablesegrund === AblesegrundEnums.Ausbau
                                );
                                return {
                                    disabled: !!ausbauStand,
                                    color: 'secondary',
                                };
                            },
                        }}
                        data={this.props.slimGeraete.items}
                        onSelectionChange={this.handleCheckBoxClick}
                    />
                </div>
                <SLModal
                    title={'Versanddatum'}
                    onClose={() => this.setState({ ...this.state, openVersandModal: false })}
                    open={this.state.openVersandModal}
                    onOkButtonClick={this.updateVersanddatum}
                    OkButtonDisabled={false}
                >
                    <div style={{ padding: 20 }}>
                        <MaterialUI.TextField
                            variant={'outlined'}
                            label={'Versanddatum'}
                            placeholder={'TT.MM.JJJJ'}
                            onChange={this.handleVersanddatumChange}
                            InputProps={{
                                inputComponent: inputDateMask,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth={false}
                            required={true}
                        />
                    </div>
                </SLModal>

                <SLModal
                    title={this.orderTitle}
                    open={this.state.showOrderDeviceModal}
                    onOkButtonClick={this.doDevicesOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    okButtonText={deTranslator.global.sendOrder}
                    onClose={() => {
                        this.setState({
                            showOrderDeviceModal: false,
                        });
                    }}
                >
                    <SlAuftragComponent
                        orderType={this.orderType}
                        facilityEmail={this.props.facilityEmail}
                        title={this.orderTitle}
                        auftragDateLabel={
                            AuftragType.Wechselauftrag === this.orderType ? 'Einbaudatum' : null
                        }
                    />
                </SLModal>
                <SLModal
                    title={'Rückmeldung Geräteausbau'}
                    open={this.state.showRemoveDeviceModal}
                    onOkButtonClick={this.ausbauAblesung}
                    OkButtonDisabled={this.props.removeDeviceForm.invalid}
                    onClose={() => {
                        this.setState({ showRemoveDeviceModal: false });
                    }}
                >
                    <RemoveSlimDeviceForm devices={[...this.selectedDevices]} />
                </SLModal>

                <SLModal
                    title={'Ablesung Hinzufügen'}
                    open={this.state.showAddReadingModal}
                    onOkButtonClick={this.addReading}
                    OkButtonDisabled={this.props.addSlimReadingDeviceForm.invalid}
                    onClose={() => {
                        this.setState({ showAddReadingModal: false });
                    }}
                >
                    <AddSlimReadingDeviceForm devices={[...this.selectedRowItems]} />
                </SLModal>

                <ChangeSlimDevices
                    title={'Rückmeldung Gerätewechsel'}
                    open={this.state.showChangeDeviceModal}
                    devices={this.selectedDevices}
                    deviceTypeList={this.props.deviceTypeList}
                    productList={this.props.productList}
                    meterpanels={this.getSelectedMeterPanels()}
                    removeDeviceForm={this.props.removeDeviceForm}
                    appendDeviceForm={this.props.addDeviceForm}
                    onSave={this.saveChangedDevicesConfirmation}
                    handleStepChange={this.deviceChange}
                    currentDevice={this.props.deviceState.changedDevice.currentDevice}
                    previousDevice={this.props.deviceState.changedDevice.previousDevice}
                    onClose={() => {
                        this.props.changeDevice(null, null);
                        this.setState({
                            showChangeDeviceModal: false,
                        });
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        deviceState: state.deviceState,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        productList: state.settingState.productManagementList,
        deviceTypeList: state.settingState.deviceTypeList,
        slimKab: state.slimState.selectedSlimCustomer,
        slimGeraete: state.slimState.slimGeraete,
        AnlageKontaktperson: state.slimState.AnlageKontaktperson,

        removeDeviceForm: {
            formValue: getFormValues('RemoveSlimDeviceComponent')(state),
            invalid: isInvalid('RemoveSlimDeviceComponent')(state),
        },
        addDeviceForm: {
            formValue: getFormValues('AddSlimDeviceForm')(state),
            invalid: isInvalid('AddSlimDeviceForm')(state),
        },
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        addSlimReadingDeviceForm: {
            formValue: getFormValues('AddSlimReadingDeviceForm')(state),
            invalid: isInvalid('AddSlimReadingDeviceForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            MeterPanelsActions,
            DevicesActions,
            CustomerActions,
            AuftragActions,
            SlimActions
        ),
        dispatch
    );
};

export const SlimGeraete = connect<ISlimDevicesProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<any>(MaterialUI.withStyles(styles)(SlimDevicesComponent));
