import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import {
    createAblesung,
    createMetergridAblesung,
    updateAblesung,
} from './graphql-operations/ablesung.mutations';
import { getIdTokenForSlimApi } from '../utils';

export interface CreateAblesungInput {
    id?: string | null;
    organisation_id: string;
    geraet_id: string;
    Seriennummer?: string | null;
    ZID: string;
    ZaehlerTyp: 'Mieterstromzaehler' | 'Reguliertezaehler';
    Ablesedatum: string;
    ZaehlerstandHT?: number | null;
    Einspeisungstand?: number | null;
    Strombezugstand?: number | null;
    Ablesegrund?: string | null;
    AndereAblesegrund?: string | null;
    Status?: string | null;
    VerbrauchHT?: number | null;
    Unit?: string | null;
    KorrigierterVerbrauch?: number | null;
    Abschlagshoehe?: boolean | null;
    Metergrid?: any | null;
}

// tslint:disable-next-line:interface-name
export interface UpdateAblesungInput {
    id: string;
    organisation_id?: string | null;
    geraet_id?: string | null;
    Seriennummer?: string | null;
    ZID?: string | null;
    ZaehlerTyp?: 'Mieterstromzaehler' | 'Reguliertezaehler';
    Ablesedatum?: string | null;
    ZaehlerstandHT?: number | null;
    Einspeisungstand?: number | null;
    Strombezugstand?: number | null;
    Ablesegrund?: string | null;
    AndereAblesegrund?: string | null;
    Status?: string | null;
    VerbrauchHT?: number | null;
    Unit?: string | null;
    KorrigierterVerbrauch?: number | null;
    Abschlagshoehe?: boolean | null;
    Metergrid?: any | null;
}

export const createSlimAblesung = async (ablesung: CreateAblesungInput) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(ablesung), ['__typename']);
        const response = await (API.graphql({
            query: createAblesung,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    ...payload,
                },
            },
        }) as Promise<{
            data: { createAblesung: any };
        }>);
        return response.data.createAblesung;
    } catch (e) {
        console.error(e);
    }
};

export const updateSlimAblesung = async (data: UpdateAblesungInput) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        const result = await (API.graphql({
            query: updateAblesung,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: { input: { ...payload } },
        }) as Promise<{ data: { updateAblesung: any } }>);
        return result['data'].updateAblesung;
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};

export const createMGAblesung = async data => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const result = (await API.graphql({
            query: createMetergridAblesung,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: { input: { ...data } },
        })) as any;
        return result.data['createMetergridAblesung'];
    } catch (e) {
        console.error(e);
        return null;
    }
};
