import { WithStyles } from '@material-ui/styles';
import cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';
import { SLModal } from '../../../common/components/modal';
import { BasicTable, IMuiTableColumn } from '../../../common/components/table/mui-table.component';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { KontaktRollen } from '../../../model/enums';
import { appStyles } from '../../../theme/common.styles';
import { SlimKaKontaktpersonType } from '../interfaces/slim.interface';
import { ReadInput } from './readInput';

const styles = theme =>
    MaterialUI.createStyles({
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 165px)',
            padding: theme.spacing(3),
        },
    });

export interface ISlimKAKontaktperson extends WithStyles<typeof styles> {
    AnlageKontaktperson: any[];
}

interface IState {
    openModal: boolean;
    selectedKontakt: SlimKaKontaktpersonType;
}
class SlimKAKontaktpersonComponent extends React.Component<ISlimKAKontaktperson, IState> {
    constructor(props: ISlimKAKontaktperson) {
        super(props);
        this.state = {
            openModal: false,
            selectedKontakt: null,
        };
    }
    private closeModal = () => {
        this.setState({ ...this.state, openModal: false, selectedKontakt: null });
    };

    private kontaktTableColumns(): Array<IMuiTableColumn> {
        return [
            {
                title: 'Rolle',
                field: 'Rolle',
                render: (rowData: SlimKaKontaktpersonType) => {
                    let rolle: { label: string; value: string } = { label: '', value: '' };
                    if (rowData) {
                        rolle = KontaktRollen.find(item => item.value === rowData.Rolle);
                        rolle =
                            rolle.value === 'Sonstige'
                                ? { label: rowData.AndereRolle, value: rolle.value }
                                : rolle;
                    }

                    return rowData ? rolle.label : '';
                },
            },
            {
                title: 'Name',
                field: 'name',
                render: (rowData: SlimKaKontaktpersonType) => {
                    const { Anrede } = rowData;
                    const { Person } = Anrede;
                    return `${Person.Nachname}, ${Person.Vorname}`;
                },
            },
            { title: 'Telefonnummer', field: 'Telefon' },
            { title: 'E-Mail Adresse', field: 'Email' },
            {
                title: 'Kommentar',
                field: 'Kommentar',
                render: (rowData: SlimKaKontaktpersonType) => {
                    return rowData.Kommentar ? `${rowData.Kommentar.substr(0, 100)}...` : '';
                },
            },
        ];
    }
    public render() {
        let rolle = { label: '', value: '' };
        if (this.state.selectedKontakt) {
            rolle = KontaktRollen.find(item => item.value === this.state.selectedKontakt.Rolle);
            rolle =
                rolle.value === 'Sonstige'
                    ? { label: this.state.selectedKontakt.AndereRolle, value: rolle.value }
                    : rolle;
        }

        return (
            <div className={this.props.classes.tabContent} style={appStyles.container}>
                <BasicTable
                    columns={this.kontaktTableColumns()}
                    options={{ maxBodyHeight: 'calc(100vh - 200px)', actionsColumnIndex: -1 }}
                    data={cloneDeep(this.props.AnlageKontaktperson)}
                    onRowClick={(event, rowData) => {
                        this.setState({ ...this.state, openModal: true, selectedKontakt: rowData });
                    }}
                />
                {this.state.selectedKontakt && (
                    <SLModal
                        title={'Kontakt'}
                        onClose={this.closeModal}
                        open={this.state.openModal}
                        fullScreen={false}
                        showActionButtons={false}
                    >
                        <MaterialUI.Grid container={true} spacing={4}>
                            <MaterialUI.Grid item={true} xs={4}>
                                <ReadInput
                                    label={'Bezeichnung der Kontaktperson'}
                                    text={rolle.label}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={12}>
                                <ReadInput
                                    label={'Firmenname/Geschäftsbezeichnung'}
                                    text={
                                        this.state.selectedKontakt.Anrede.Firma
                                            ? this.state.selectedKontakt.Anrede.Firma.Firmenname1
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Anrede'}
                                    text={this.state.selectedKontakt.Anrede.Typ}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Titel'}
                                    text={
                                        this.state.selectedKontakt.Anrede.Person
                                            ? this.state.selectedKontakt.Anrede.Person.Titel
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Vorname'}
                                    text={this.state.selectedKontakt.Anrede.Person.Vorname}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Nachname'}
                                    text={this.state.selectedKontakt.Anrede.Person.Nachname}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Straße'}
                                    text={
                                        this.state.selectedKontakt.Adresse
                                            ? this.state.selectedKontakt.Adresse.Strasse
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Hausnummer'}
                                    text={
                                        this.state.selectedKontakt.Adresse
                                            ? this.state.selectedKontakt.Adresse.HausNummer
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Postleitzahl'}
                                    text={
                                        this.state.selectedKontakt.Adresse
                                            ? this.state.selectedKontakt.Adresse.PLZ
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Ort'}
                                    text={
                                        this.state.selectedKontakt.Adresse
                                            ? this.state.selectedKontakt.Adresse.Ort
                                            : ''
                                    }
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'Telefonnummer'}
                                    text={this.state.selectedKontakt.Telefon || ''}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={6}>
                                <ReadInput
                                    label={'E-Mail Adresse'}
                                    text={this.state.selectedKontakt.Email || ''}
                                />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={12}>
                                <ReadInput
                                    label={'Kommentar'}
                                    text={this.state.selectedKontakt.Kommentar || ''}
                                />
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>
                    </SLModal>
                )}
            </div>
        );
    }
}

export const SlimKAKontaktperson = MaterialUI.withStyles(styles)(SlimKAKontaktpersonComponent);
