import { WithStyles } from '@material-ui/styles';
import * as React from 'react';

import { FieldArray, reduxForm } from 'redux-form';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { SLMaloMeloComponent } from './MaloMelo.component';

const styles = () =>
    MaterialUI.createStyles({
        main: {
            position: 'absolute' as 'absolute',
            top: '23%',
            right: '30',
        },
        labels: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
        },
        labelItem: {},
    });

export interface IMaloMeloContainerProps extends WithStyles<typeof styles> {
    initialize: (meterPanels) => void;
    reset: () => void;
    selectedMeterPanels: Array<{
        ID: string;
        WohnungNr: string;
        ZID: string;
        GeraeteID: string;
        Malo: string;
        Melo: string;
        Version?: number;
    }>;
    onFieldChange?: (flag: boolean) => void;
}

interface IState {
    maloMeloHeader: Array<string>;
}

class MaloMeloContainer extends React.Component<IMaloMeloContainerProps, IState> {
    private fields: any;

    public constructor(props: IMaloMeloContainerProps) {
        super(props);

        const initializeValues = [...this.props.selectedMeterPanels];
        this.state = {
            maloMeloHeader: ['Wohnungs-Nr', 'Geräte-ID', 'MaLo*', 'MeLo*'],
        };
        this.props.initialize({ Zaehlplaetze: initializeValues });
    }

    public componentWillUnmount() {
        this.props.reset();
    }

    private renderContent = ({ fields }) => {
        this.fields = fields;
        return fields.map((device, index) => {
            return <SLMaloMeloComponent key={'MaloMelo_' + index} value={device} index={index} />;
        });
    };

    public render() {
        return (
            <div style={{ paddingTop: 20 }}>
                <div>
                    <FieldArray name={'Zaehlplaetze'} component={this.renderContent} />
                </div>
                <MaterialUI.Grid
                    container={true}
                    direction={'row'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    style={{ marginTop: '35px' }}
                >
                    <MaterialUI.Typography variant="body1">*Pflichtfelder</MaterialUI.Typography>
                    <MaterialUI.Tooltip
                        id="tooltip-info-icon"
                        title={
                            <div style={{ fontSize: '1rem', lineHeight: '1.2rem' }}>
                                Nicht befüllte Zeilen werden bei der
                                <br />
                                Gerätezuordnung nicht berücksichtigt
                            </div>
                        }
                    >
                        <MaterialUI.InputAdornment position="end">
                            <MaterialUI.IconButton
                                className="iconButton"
                                aria-label="Tooltip"
                                disabled={true}
                            >
                                <MaterialUIIcons.InfoOutlined />
                            </MaterialUI.IconButton>
                        </MaterialUI.InputAdornment>
                    </MaterialUI.Tooltip>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const MaloMeloContainerComponent = MaterialUI.withStyles(styles)(MaloMeloContainer);

export const SLMaloMeloContainerComponent = reduxForm({
    form: 'MaloMeloForm',
})(MaloMeloContainerComponent);
