export const modalStyles = {
    container: {
        padding: 20,
        maxHeight: 'calc(100vh - 323px)',
        overflowX: 'auto' as 'auto',
        overflowY: 'auto' as 'auto'
    },
    item: {paddingTop: 20},
    header: {padding: 20},
    divider: {
        color: '#ff9800',
        backgroundColor: '#ff9800',
        height: '4px',
        top: '-8px'
    },
    btn: {
        position: 'absolute' as 'absolute',
        bottom: -20,
        right: -25

    }
};