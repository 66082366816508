import { ISearchSuggestion } from '../../../../common/interfaces/interface';
import { SearchActionTypes } from '../types/search.action.types';

export const suggestionLoading = (flag) => {
    return dispatch => dispatch({
        type: SearchActionTypes.SUGGESTION_LOADING,
        payload: flag
    });
};

export const saveSuggestions = (items:ISearchSuggestion) => {
    return dispatch => dispatch({
        type: SearchActionTypes.SAVE_SEARCH_SUGGESTIONS,
        payload: items
    });
};