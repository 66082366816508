import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Field } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';
import { SlTextInputComponent } from './text-field.component';


const priceFormatCustom = (props) => {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            onChange={onChange}
            getInputRef={inputRef}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
};

const renderUnit = () => {
    return (<MaterialUI.InputAdornment
        position="end">{deTranslator.einheit.euro}</MaterialUI.InputAdornment>);
};


export const inputLevelMask = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            onChange={onChange}
            getInputRef={inputRef}
            // thousandSeparator={'.'}
            decimalSeparator={','}
        />
    );
};


const CustomizedTextFormatInput = (inputProps, customizedComponent) => {
    const {
        input,
        label,
        type,
        options,
        multiline,
        maxLength,
        placeholder,
        rows,
        readOnly,
        disabled,
        required,
        style,
        endAdornment,
        addons,
        meta: {touched, error, warning}
    } = inputProps;

    const disableStyle = disabled ? {backgroundColor: '#E2E2E2'} : {backgroundColor: '#fff'};
    const inputStyle = Object.assign({}, style, disableStyle);
    return (
        <MaterialUI.TextField
            label={label}
            variant={'outlined'}
            type={type}
            placeholder={placeholder}
            {...input}
            error={touched && error ? true : false}
            multiline={multiline ? multiline : false}
            maxLength={maxLength}
            disabled={disabled ? disabled : false}
            rows={rows ? rows : 1}
            helperText={touched && ((error && <span>{error}</span>) ||
                (warning && <span style={{color: sbColors.orange}}>{warning}</span>))}
            InputProps={{
                inputComponent: customizedComponent,
                readOnly: readOnly ? readOnly : false,
                classes: {},
                style: {...inputStyle},
                addons: addons,
                endAdornment: endAdornment && endAdornment
            }}
            InputLabelProps={{
                shrink: true
            }}
            fullWidth={options && options.fullWidth}
            required={required}
        />
    );
};


export const proxyPriceInputComponent = inputProps => CustomizedTextFormatInput(inputProps, priceFormatCustom);
export const LevelHOC = inputProps => CustomizedTextFormatInput(inputProps, inputLevelMask);


export const SlNumberInputComponent = (inputProps: { name: string, label: string, required?: boolean, validate?: Array<any>, disabled?: boolean }) => {
    const {name, label, required, validate, disabled} = inputProps;
    return (<Field component={SlTextInputComponent}
                   label={label}
                   parse={value => !value ? null : Number(value)}
                   name={name}
                   type={'number'}
                   disabled={disabled}
                   required={required}
                   validate={validate}
                   options={{fullWidth: true, shrink: true, showInfoIcon: false}}/>);
};


export const SLInputPriceField = (inputProps: { name: string, label: string, required: boolean, validate: Array<any>, disabled?: boolean }) => {
    const {name, label, required, validate, disabled} = inputProps;
    return (<Field name={name}
                   label={label}
                   type={'text'}
                   disabled={disabled}
                   component={proxyPriceInputComponent}
                   required={required}
                   parse={value => {
                       // convert euro to cent
                       return Number(value.replace(/[$.]+/g, '').replace(/,/g, '.')) * 100;
                   }}
                   format={value => {
                       // convert cent to euro
                       return (value / 100).toFixed(2)
                           .toString().replace(/\./g, ',');
                   }}
                   validate={validate}
                   options={{fullWidth: true, shrink: true}}
                   endAdornment={renderUnit()}
    />);
};


export const SLMeterLevelInput = (inputProps: {
    name: string, label: string, required: boolean,
    validate: Array<any>, disabled?: boolean, endAdornment?: any,
    placeholder?: string, onChange?: any, warn?: any, readOnly?: boolean,
    onBlur?: any
}) => {
    const {
        name, label, required, validate, disabled, endAdornment,
        onChange, warn, readOnly, onBlur
    } = inputProps;
    return (<Field name={name}
                   label={label}
                   type={'text'}
                   disabled={disabled}
                   component={LevelHOC}
                   required={required}
                   onChange={onChange}
                   warn={warn}
                   onBlur={onBlur}
                   readOnly={readOnly}
                   validate={validate}
                   options={{fullWidth: true, shrink: true}}
                   endAdornment={endAdornment}
    />);
};


