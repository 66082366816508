import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, Field, FieldArray, FormProps, formValueSelector, reduxForm } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlDateInputComponent, SlSelectInputComponent } from '../../../common/components/forms';
import { SlCheckboxInput } from '../../../common/components/forms/checkbox-input';
import { SlMultipleSelectInputComponent } from '../../../common/components/forms/select-input.component';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IFacility, IProduct } from '../../../common/interfaces/interface';
import {
    currencyFormatter,
    germanDateToISODateFormat,
    inputDateDisplayFormat,
} from '../../../common/utils/Functions';
import { isContractStartBeforeContractEnd } from '../../../common/validators/facility.validator';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { PaymentPlan } from '../../../model/enums';
import { IReduxState } from '../../../model/redux-state';
import { store } from '../../../store';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30,
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        mediumInput: {},
        smallInput: {},
        date: {
            width: '30%',
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            overflow: 'hidden',
        },
        tinyInput: {
            width: '15%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
        responsiveContent: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                margin: 'auto',
                width: '70%',
            },
            [theme.breakpoints.up('lg')]: {
                margin: 'auto',
                width: '70%',
            },
        },
    });

export interface IFacilityProps extends WithStyles<typeof styles>, FormProps<any, {}, {}> {
    productList: Array<IProduct>;
    readonly?: boolean;
    facility?: IFacility;
    initialize: (facility: IFacility) => void;
    VertragLaufzeitItems?: Array<any>;
}

interface IState {
    selectedProducts: Array<string>;
}

class SlFacilityForm extends React.Component<IFacilityProps, IState> {
    constructor(props: IFacilityProps) {
        super(props);
        this.state = { selectedProducts: [] };

        if (this.props.facility) {
            this.props.initialize(this.props.facility);
            this.state = {
                selectedProducts: this.props.facility.ProduktIds
                    ? [...this.props.facility.ProduktIds]
                    : [],
            };
        } else {
            this.props.initialize(this.getDefaultProperty());
            this.state = { selectedProducts: [] };
        }
    }

    private getDefaultProperty = (): any => {
        return {
            Adresse: null,
            Abrechnungsmodell: PaymentPlan[0].key,
            ProduktIds: [],
            VertragLaufzeitItems: [
                {
                    AnzahlPeriode: null,
                    Datum: Moment().format('YYYY-MM-DD'),
                    Start: Moment().format('YYYY-MM-DD'),
                },
            ],
        };
    };

    private handleChange = event => {
        this.setState({ selectedProducts: event.target.value });
    };

    private onDurationchange = (event, newValue) => {
        if (newValue === 0) {
            store.dispatch(change('SLFacilityForm', `VertragLaufzeitItems[0].Ende`, ''));
        } else {
            store.dispatch(
                change(
                    'SLFacilityForm',
                    `VertragLaufzeitItems[0].Ende`,
                    Moment(this.props.VertragLaufzeitItems[0].Start)
                        .add(newValue, 'y')
                        .subtract(1, 'days')
                        .format('YYYY-MM-DD')
                )
            );
        }
    };

    public renderContractPeriod = ({ fields }) => {
        return fields.map((contract, indexField) => {
            if (indexField === 0) {
                return (
                    <MaterialUI.Grid
                        container={true}
                        key={`${indexField}-VertragLaufzeitItems`}
                        spacing={3}
                        direction={'row'}
                    >
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                name={`${contract}.Start`}
                                d={true}
                                require
                                disabled={!!this.props.facility}
                                label={deTranslator.facility.contractBegin}
                                format={inputDateDisplayFormat}
                                normalize={germanDateToISODateFormat}
                                options={{ fullWidth: true, shrink: true }}
                                component={SlDateInputComponent}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                validate={[FormValidator.required]}
                            />
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                name={`${contract}.Ende`}
                                required={true}
                                disabled={false}
                                label={deTranslator.facility.contractEnd}
                                format={inputDateDisplayFormat}
                                normalize={germanDateToISODateFormat}
                                options={{ fullWidth: true, shrink: true }}
                                component={SlDateInputComponent}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                validate={[FormValidator.required]}
                            />
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                );
            }
            return null;
        });
    };

    public render() {
        return (
            <div style={{ paddingTop: 24 }}>
                <FieldArray name={'VertragLaufzeitItems'} component={this.renderContractPeriod} />

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Abrechnungsmodell"
                            component={SlSelectInputComponent}
                            label={deTranslator.facility.payModel}
                            readonly={this.props.readonly}
                            validate={[]}
                            required={true}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            options={{ fullWidth: false, shrink: true }}
                        >
                            {PaymentPlan.map((item, index) => (
                                <MaterialUI.MenuItem key={`${index}-${item.key}`} value={item.key}>
                                    {item.label}
                                </MaterialUI.MenuItem>
                            ))}
                        </Field>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="ProduktIds"
                            component={SlMultipleSelectInputComponent}
                            label={deTranslator.facility.product}
                            readonly={this.props.readonly}
                            onChange={this.handleChange}
                            options={{ fullWidth: false, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                        >
                            {this.props.productList
                                .filter(item => item.ebene !== 'Gerät')
                                .map((product, index) => (
                                    <MaterialUI.MenuItem
                                        key={`${index}-${product.id}`}
                                        value={product.id}
                                    >
                                        <MaterialUI.Checkbox
                                            checked={
                                                this.state.selectedProducts.indexOf(product.id) > -1
                                            }
                                        />
                                        <MaterialUI.ListItemText
                                            primary={`${product.name} (${currencyFormatter(
                                                product.preis / 100
                                            )})`}
                                        />
                                    </MaterialUI.MenuItem>
                                ))}
                        </Field>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={8} className={this.props.classes.item}>
                        <Field
                            name="Adresse.Strasse"
                            label={deTranslator.address.street}
                            component={SlTextInputComponent}
                            required={true}
                            className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight} `}
                            readonly={this.props.readonly}
                            validate={[FormValidator.required, FormValidator.maxLength255]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Adresse.HausNummer"
                            label={deTranslator.address.streetNumber}
                            readonly={this.props.readonly}
                            options={{ fullWidth: true, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.tinyInput} ${this.props.classes.marginRight} `}
                            component={SlTextInputComponent}
                            validate={[FormValidator.maxLength25]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            component={SlTextInputComponent}
                            name="Adresse.PLZ"
                            required={true}
                            label={deTranslator.address.zipCode}
                            validate={[FormValidator.required, FormValidator.zipCode]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={8} className={this.props.classes.item}>
                        <Field
                            name="Adresse.Ort"
                            required={true}
                            label={deTranslator.address.city}
                            readonly={this.props.readonly}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight}`}
                            validate={[
                                FormValidator.required,
                                FormValidator.minLength2,
                                FormValidator.maxLength25,
                            ]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
                <MaterialUI.Grid container={true} spacing={1} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={12}>
                        <Field
                            name="Zusatzinformationen"
                            required={false}
                            label={deTranslator.global.moreInfo}
                            readonly={false}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            // className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.maxLength255]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="isMetergridProject"
                            required={false}
                            label={'Create Metergrid Project ID'}
                            disabled={!!this.props.facility}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlCheckboxInput}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

const selector = formValueSelector('SLFacilityForm');
const mapStateToProps = (state: IReduxState) => {
    const VertragLaufzeitItems = selector(state, 'VertragLaufzeitItems');
    return {
        VertragLaufzeitItems,
    };
};

export const SlFacilityFormComponent = MaterialUI.withStyles(styles)(SlFacilityForm);

const SLFacilityReduxForm = reduxForm({
    form: 'SLFacilityForm',
    validate: isContractStartBeforeContractEnd,
})(SlFacilityFormComponent);

export const SLFacility = connect(mapStateToProps)(SLFacilityReduxForm);
