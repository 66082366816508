import {
    addedCustomerId,
    saveCustomerActiveTab,
    saveCustomers,
    saveDashBoardActiveTab,
    saveSelectedCustomer,
    saveSelectedKABContactPerson,
} from './actions/customer.action';
import { fetchCustomerData } from './thunk/customer-page.operations';
import {
    deleteBillReceiver,
    deleteContactData,
    deleteCustomerById,
    getBillReceiver,
    getContactData,
    getCustomerById,
    getCustomers,
    postBillReceiver,
    postContactData,
    postCustomer,
    sortCustomerById,
    updateBillReceiver,
    updateContactData,
    updateCustomer,
} from './thunk/customer.operations';

// export reducers
export { customerReducer } from './reducers/customer.reducer';

// export actions and thunk operations
export const CustomerActions = Object.assign(
    {},
    { getCustomerById: getCustomerById },
    { getCustomers: getCustomers },
    { postCustomer: postCustomer },
    { saveCustomers: saveCustomers },
    { saveSelectedCustomer: saveSelectedCustomer },
    { postContactData: postContactData },
    { deleteContactData: deleteContactData },
    { getContactData: getContactData },
    { postBillReceiver: postBillReceiver },
    { getBillReceiver: getBillReceiver },
    { deleteBillReceiver: deleteBillReceiver },
    { sortCustomerById: sortCustomerById },
    { addedCustomerId: addedCustomerId },
    { updateCustomer: updateCustomer },
    { updateBillReceiver: updateBillReceiver },
    { updateContactData: updateContactData },
    { fetchCustomerData: fetchCustomerData },
    { saveSelectedKABContactPerson: saveSelectedKABContactPerson },
    { saveDashBoardActiveTab: saveDashBoardActiveTab },
    { saveCustomerActiveTab: saveCustomerActiveTab },
    { deleteCustomerById: deleteCustomerById }
);
