import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { SlimKabAnlageType } from '../interfaces/slim.interface';

const styles = () =>
    MaterialUI.createStyles({
        facilityId: {
            fontSize: 18,
            paddingTop: '5px',
            lineHeight: '1.8em',
        },
        cardContainer: { position: 'relative', height: '100%' },
        icon: { right: 0, bottom: 0, position: 'absolute' },
        card: {
            width: 330,
            cursor: 'pointer',
        },
        actions: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    });

export interface ISlimCardKA extends WithStyles<typeof styles> {
    kundenAnlage: SlimKabAnlageType;
    onSelected: (facility: SlimKabAnlageType) => void;
}

interface IState {
    elevation: number;
}
class SlimKAComponent extends React.Component<ISlimCardKA, IState> {
    constructor(props: ISlimCardKA) {
        super(props);
        this.state = {
            elevation: 1,
        };
    }
    public render() {
        const { kundenAnlage } = this.props;
        return (
            <MaterialUI.Card
                className={this.props.classes.card}
                onMouseOver={() => this.setState({ elevation: 7 })}
                onMouseOut={() => this.setState({ elevation: 1 })}
                elevation={this.state.elevation}
                onClick={() => this.props.onSelected(this.props.kundenAnlage)}
            >
                <MaterialUI.CardHeader title={kundenAnlage.KAname} subheader={''} />

                <MaterialUI.CardContent>
                    <MaterialUI.Typography noWrap={true}>
                        {`${kundenAnlage.Adresse.Strasse} ${kundenAnlage.Adresse.HausNummer || ''}`}
                    </MaterialUI.Typography>
                    <MaterialUI.Typography noWrap={true}>
                        {`${kundenAnlage.Adresse.PLZ || ''} ${kundenAnlage.Adresse.Ort || ''}`}
                    </MaterialUI.Typography>
                </MaterialUI.CardContent>
            </MaterialUI.Card>
        );
    }
}

export const SlimKACard = MaterialUI.withStyles(styles)(SlimKAComponent);
