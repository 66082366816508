import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, initialize, isDirty, isInvalid, isPristine, isValid } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlContactDataForm } from '../../../common/components/forms';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { store } from '../../../store/index';
import { sbColors } from '../../../theme/app.colors';
import { CustomerActions } from '../redux';
import { SLBillReceiverForm } from './sl-bill-receiver.form';
import { SLBusinessPartnerForm } from './sl-businessPartner.form';

const styles = (theme: Theme) => {
    return MaterialUI.createStyles({
        dialogContent: {
            padding: 0,
            overflowX: 'hidden',
        },
        toolbar: {
            backgroundColor: sbColors.blue,
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
        },
        connectorActive: {
            '& $connectorLine': {
                borderColor: theme.palette.secondary.main,
            },
        },
        connectorCompleted: {
            '& $connectorLine': {
                borderColor: theme.palette.primary.main,
            },
        },
        connectorDisabled: {
            '& $connectorLine': {
                borderColor: theme.palette.grey[100],
            },
        },
        connectorLine: {
            transition: theme.transitions.create('border-color'),
        },

        stepperContent: { marginLeft: 35, borderLeft: '1px solid #FF9800' },
        stepLabel: {
            padding: 24,
            backgroundColor: 'whitesmoke',
        },
        labelChildren: {
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        actionButton: {
            width: 170,
        },
    });
};

export interface ICustomerDataProps extends WithStyles<typeof styles> {
    open: boolean;
    onClose?: () => void;
    onSubmit?: (businessPartner: any, contactData: any, billToCustomer?: any) => void;
    billToCustomerForm?: IForm;
    contactDataForm?: IForm;
    businessPartnerForm?: IForm;
    postCustomer?: (value) => void;
    postContactData?: (value) => void;
    postBillReceiver?: (value) => void;
}

interface IState {
    value: number;
    activeStep: number;
    steps: Array<{ label: string }>;
}

export class CustomerData extends React.Component<ICustomerDataProps, IState> {
    private contactFormValue: any = null;

    constructor(props: ICustomerDataProps) {
        super(props);
        this.state = {
            value: 0,
            activeStep: 0,
            steps: [
                { label: deTranslator.user.businessPartner },
                { label: deTranslator.user.contactData },
                { label: deTranslator.user.billReceiver },
            ],
        };
    }

    private saveCustomerData = async () => {
        this.props.onSubmit(
            this.props.businessPartnerForm.formValue,
            this.props.contactDataForm.formValue,
            this.props.billToCustomerForm.formValue
        );
    };

    private resetStep = () => {
        this.resetBusinessPartner();
        this.resetContactDaten();
        this.resetBillReceiver();
        this.setState(state => ({
            activeStep: 0,
        }));
    };

    private handleNextStep = () => {
        this.setState(state => ({
            activeStep: this.state.activeStep + 1,
        }));
    };
    private handleBackStep = () => {
        this.setState(state => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    private resetBusinessPartner = () => {
        store.dispatch(initialize('BusinessPartnerForm', null));
    };
    private resetContactDaten = () => {
        store.dispatch(initialize('contactPersonForm', null));
    };
    private resetBillReceiver = () => {
        store.dispatch(initialize('BillReceiver', null));
    };

    private getStepContent = step => {
        if (this.props.contactDataForm.formValue) {
            this.contactFormValue = this.props.contactDataForm.formValue;
        }

        switch (step) {
            case 0:
                return <SLBusinessPartnerForm showTaxField={true} />;
            case 1:
                return (
                    <SlContactDataForm
                        wizardMode={true}
                        selectedContactData={this.props.contactDataForm.formValue}
                    />
                );
            case 2:
                return <SLBillReceiverForm showTaxField={false} />;
            default:
                return null;
        }
    };

    public render() {
        const connector = (
            <MaterialUI.StepConnector
                style={{ marginLeft: 35 }}
                classes={{
                    line: this.props.classes.connectorLine,
                    root: this.props.classes.connectorActive,
                }}
            />
        );

        return (
            <div>
                <MaterialUI.Dialog
                    open={this.props.open}
                    maxWidth={'lg'}
                    disableEscapeKeyDown={false}
                    fullWidth={true}
                    onExit={this.resetStep}
                    scroll={'paper'}
                >
                    <MaterialUI.DialogTitle
                        disableTypography={true}
                        className={this.props.classes.toolbar}
                    >
                        <MaterialUI.Typography variant="h5" color="inherit">
                            {deTranslator.global.newCustomer}
                        </MaterialUI.Typography>
                        <MaterialUIIcons.Close
                            onClick={this.props.onClose}
                            style={{ cursor: 'pointer' }}
                            color={'inherit'}
                        />
                    </MaterialUI.DialogTitle>

                    <MaterialUI.DialogContent className={this.props.classes.dialogContent}>
                        <MaterialUI.Stepper
                            activeStep={this.state.activeStep}
                            style={{ padding: 0 }}
                            nonLinear={true}
                            connector={connector}
                            orientation="vertical"
                        >
                            {/*business partner*/}
                            <MaterialUI.Step>
                                <MaterialUI.StepLabel
                                    className={this.props.classes.stepLabel}
                                    icon={
                                        <MaterialUIIcons.BusinessCenter
                                            color={
                                                this.state.activeStep === 0 ||
                                                this.props.businessPartnerForm.formValue
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        />
                                    }
                                >
                                    <MaterialUI.Typography variant={'h6'}>
                                        {this.state.steps[0].label}
                                    </MaterialUI.Typography>
                                </MaterialUI.StepLabel>
                                <MaterialUI.StepContent
                                    className={this.props.classes.stepperContent}
                                >
                                    {this.getStepContent(this.state.activeStep)}
                                </MaterialUI.StepContent>
                            </MaterialUI.Step>

                            {/*Kontakdaten*/}
                            <MaterialUI.Step>
                                <MaterialUI.StepLabel
                                    className={this.props.classes.stepLabel}
                                    icon={
                                        <MaterialUIIcons.AccountCircle
                                            color={
                                                this.state.activeStep === 1 ||
                                                this.props.contactDataForm.formValue
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        />
                                    }
                                    error={false}
                                >
                                    <div className={this.props.classes.labelChildren}>
                                        <MaterialUI.Typography variant={'h6'}>
                                            {this.state.steps[1].label}
                                        </MaterialUI.Typography>
                                        {this.props.contactDataForm.dirty && (
                                            <div
                                                className={this.props.classes.labelChildren}
                                                onClick={this.resetContactDaten}
                                            >
                                                <MaterialUI.Typography
                                                    variant={'body1'}
                                                    style={{ paddingRight: 8 }}
                                                >
                                                    {deTranslator.global.resetTab}
                                                </MaterialUI.Typography>
                                                <MaterialUIIcons.HighlightOffOutlined
                                                    color={'secondary'}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </MaterialUI.StepLabel>
                                <MaterialUI.StepContent
                                    className={this.props.classes.stepperContent}
                                >
                                    {this.getStepContent(this.state.activeStep)}
                                </MaterialUI.StepContent>
                            </MaterialUI.Step>

                            {/*Rechnungsempfänger*/}
                            <MaterialUI.Step>
                                <MaterialUI.StepLabel
                                    className={this.props.classes.stepLabel}
                                    optional={
                                        <MaterialUI.Typography variant={'caption'}>
                                            {deTranslator.global.optional}
                                        </MaterialUI.Typography>
                                    }
                                    icon={
                                        <MaterialUIIcons.Email
                                            color={
                                                this.state.activeStep === 2 &&
                                                this.props.billToCustomerForm.formValue
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        />
                                    }
                                >
                                    <div className={this.props.classes.labelChildren}>
                                        <MaterialUI.Typography variant={'h6'}>
                                            {this.state.steps[2].label}
                                        </MaterialUI.Typography>
                                        {this.props.billToCustomerForm.dirty && (
                                            <div
                                                className={this.props.classes.labelChildren}
                                                onClick={this.resetBillReceiver}
                                            >
                                                <MaterialUI.Typography
                                                    variant={'body1'}
                                                    style={{ paddingRight: 8 }}
                                                >
                                                    {deTranslator.global.resetTab}
                                                </MaterialUI.Typography>
                                                <MaterialUIIcons.HighlightOffOutlined
                                                    color={'secondary'}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </MaterialUI.StepLabel>
                                <MaterialUI.StepContent
                                    className={this.props.classes.stepperContent}
                                >
                                    {this.getStepContent(this.state.activeStep)}
                                </MaterialUI.StepContent>
                            </MaterialUI.Step>
                        </MaterialUI.Stepper>
                    </MaterialUI.DialogContent>

                    <MaterialUI.DialogActions>
                        <MaterialUI.Button
                            variant="outlined"
                            className={this.props.classes.actionButton}
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBackStep}
                        >
                            {deTranslator.global.back}
                        </MaterialUI.Button>

                        {this.state.activeStep !== 2 && (
                            <MaterialUI.Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleNextStep}
                                className={this.props.classes.actionButton}
                                disabled={
                                    this.props.businessPartnerForm.invalid ||
                                    this.props.contactDataForm.invalid
                                }
                            >
                                {deTranslator.global.next}
                            </MaterialUI.Button>
                        )}
                        {this.state.activeStep === 2 && (
                            <MaterialUI.Button
                                color="primary"
                                variant="contained"
                                className={this.props.classes.actionButton}
                                onClick={this.saveCustomerData}
                                disabled={
                                    this.props.businessPartnerForm.invalid ||
                                    (this.props.billToCustomerForm.invalid &&
                                        this.props.billToCustomerForm.dirty)
                                }
                            >
                                {deTranslator.global.addCustomer}
                            </MaterialUI.Button>
                        )}
                    </MaterialUI.DialogActions>
                </MaterialUI.Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        businessPartnerForm: {
            formValue: getFormValues('BusinessPartnerForm')(state),
            invalid: isInvalid('BusinessPartnerForm')(state),
            dirty: isDirty('BusinessPartnerForm')(state),
            valid: isValid('BusinessPartnerForm')(state),
            pristine: isPristine('BusinessPartnerForm')(state),
        },
        billToCustomerForm: {
            formValue: getFormValues('BillReceiver')(state),
            invalid: isInvalid('BillReceiver')(state),
            dirty: isDirty('BillReceiver')(state),
        },
        contactDataForm: {
            formValue: getFormValues('contactPersonForm')(state),
            invalid: isInvalid('contactPersonForm')(state),
            dirty: isDirty('contactPersonForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(Object.assign({}, CustomerActions), dispatch);
};

export const CustomerDataComponent = MaterialUI.withStyles(styles)(CustomerData);

export const SLCustomerData = connect<{}, {}, ICustomerDataProps>(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDataComponent);
