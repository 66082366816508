import { WithStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import * as React from 'react';
import { forwardRef, ReactNode } from 'react';

import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { sbColors } from '../../../theme/app.colors';

const tableIcons = {
    Add: forwardRef((props, ref: any) => <MaterialUIIcons.AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <MaterialUIIcons.Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <MaterialUIIcons.Clear {...props} ref={ref} />),
    DeleteOutline: forwardRef((props, ref: any) => (
        <MaterialUIIcons.DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref: any) => (
        <MaterialUIIcons.ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref: any) => <MaterialUIIcons.Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <MaterialUIIcons.SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <MaterialUIIcons.FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <MaterialUIIcons.FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <MaterialUIIcons.LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => (
        <MaterialUIIcons.ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: forwardRef((props, ref: any) => (
        <MaterialUIIcons.ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref: any) => <MaterialUIIcons.Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <MaterialUIIcons.Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => (
        <MaterialUIIcons.ArrowUpward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref: any) => (
        <MaterialUIIcons.Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref: any) => (
        <MaterialUIIcons.ViewColumn {...props} ref={ref} />
    )),
    Save: forwardRef((props, ref: any) => <MaterialUIIcons.Save {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <MaterialUIIcons.Delete {...props} ref={ref} />),
};

export const basicTableStyles = {
    table: {
        backgroundColor: 'white',
    },
    row: {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    },
    body: {
        display: 'block',
        // height: 'calc(100vh - 330px)',
        overflowY: 'scroll',
    },
    headerCell: {
        border: '1px solid ',
        borderColor: sbColors.whiteSmoke,
        backgroundColor: sbColors.whiteSmoke,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5,
        // width: 100,
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    underlineText: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    cell: {
        color: sbColors.body,
    },
};

export const styles = () => MaterialUI.createStyles({});

export interface IMuiTableColumn {
    title: string;
    field: string;
    type?: any;
    emptyValue?:
        | string
        | React.ReactElement<any>
        | ((data: any) => React.ReactElement<any> | string);
    render?: (data: any) => any;
    defaultSort?: 'asc' | 'desc';
    cellStyle?: any;
    customSort?: any;
}

interface IMuiTableComponentProps extends WithStyles<typeof styles> {
    columns: Array<IMuiTableColumn>;
    data: Array<any>;
    detailPanel?: (rowData: any) => ReactNode;
    options?: any;
    title?: string;
    localization?: any;
    actions?: any;
    onSelectionChange?: any;
    onRowClick?: (event, rowData) => void;
    onOrderChange?: (orderedColumnId, orderDirection) => void;
}

export class BasicTableComponent extends React.Component<IMuiTableComponentProps, {}> {
    public static defaultProps: Partial<IMuiTableComponentProps> = {
        title: '',
        data: [],
        columns: [],
        options: {},
        localization: {},
    };

    private defaultOptions = {
        toolbar: false,
        paging: false,
        showEmptyDataSourceMessage: false,
        rowStyle: {
            backgroundColor: sbColors.white,
        },
        headerStyle: {
            background: sbColors.blue,
            fontWeight: 500,
            color: sbColors.white,
        },
    };

    private defaultLocalization = {
        pagination: {
            labelDisplayedRows: '{from}-{to} von {count}', // {from}-{to} of {count}
            labelRowsPerPage: 'Zeilen pro Seite:', // Rows per page:
            firstAriaLabel: 'Erste Seite', // First Page
            firstTooltip: 'Erste Seite', // First Page
            previousAriaLabel: 'Vorherige Seite', // Previous Page
            previousTooltip: 'Vorherige Seite', // Previous Page
            nextAriaLabel: 'Nächste Seite', // Next Page
            nextTooltip: 'Nächste Seite', // Next Page
            lastAriaLabel: 'Letzte Seite', // Last Page
            lastTooltip: 'Letzte Seite', // Last Page
            labelRowsSelect: 'Zeilen', // Zeile
        },
        toolbar: {
            nRowsSelected: '{0} Zeile(n) ausgewählt', // {0} row(s) selected
            showColumnsTitle: 'Spalten anzeigen', // Show Columns
            showColumnsAriaLabel: 'Spalten anzeigen', // Show Columns
            exportTitle: 'Exportieren', // Export
            exportAriaLabel: 'Exportieren', // Export
            exportName: 'Als CSV exportieren', // Export as CSV
            searchTooltip: 'Suchen', // Search
        },
        header: {
            actions: '', // Actions
        },
        body: {
            emptyDataSourceMessage: 'Keine Einträge an zu zeigen', // No records to display
            filterRow: {
                filterTooltip: 'Filtern', // Filter
            },
        },
    };

    public render() {
        return (
            <MaterialTable
                icons={tableIcons}
                title={this.props.title}
                data={this.props.data}
                detailPanel={this.props.detailPanel}
                columns={this.props.columns}
                options={Object.assign({}, this.defaultOptions, this.props.options)}
                localization={Object.assign({}, this.defaultLocalization, this.props.localization)}
                actions={this.props.actions}
                onSelectionChange={this.props.onSelectionChange}
                onRowClick={this.props.onRowClick}
                onOrderChange={this.props.onOrderChange}
            />
        );
    }
}

export const BasicTable = MaterialUI.withStyles(styles)(BasicTableComponent);
