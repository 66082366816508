/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'eu-central-1',
    aws_appsync_graphqlEndpoint:
        'https://7g7524jb75gr7hhlo7rnwza4yy.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
    aws_appsync_apiKey: 'da2-ds7d366rmzfebhecwzd5wezdy4',
    aws_cloud_logic_custom: [
        {
            name: 'metergridRestApi',
            endpoint: 'https://rvyco27l4m.execute-api.eu-central-1.amazonaws.com/master',
            region: 'eu-central-1',
        },
    ],
    aws_cognito_identity_pool_id: 'eu-central-1:b02c512b-7082-4d89-8ddf-f58950a1f19d',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_WELIWVga7',
    aws_user_pools_web_client_id: '3saa78d3mafkf52f36bg1q0m7i',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: ['REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS'],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'slimappstorage121307-master',
    aws_user_files_s3_bucket_region: 'eu-central-1',
};

export default awsmobile;
