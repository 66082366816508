import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { SlContactDataForm } from '../../../common/components/forms';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { BasicTable, IMuiTableColumn } from '../../../common/components/table/mui-table.component';
import { IContactData } from '../../../common/interfaces/interface';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles, Salutation } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { sbColors } from '../../../theme/app.colors';
import { appStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            height: '100vh',
            backgroundColor: sbColors.white,
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 260px)',
        },
    });

export interface ISlFacilityContacts extends WithStyles<typeof styles> {
    contactPersons: Array<IContactData>;
    contactPersonForm: IForm;
    tableColumns: Array<IMuiTableColumn>;
    addContact?: (contact?: IContactData) => void;
    editContact?: (contact?: IContactData) => void;
    deleteContact?: (contact: IContactData) => void;
    showRole?: boolean;
}

interface IState {
    openContactPersonModal: boolean;
    selectedContactData: IContactData;
    openAlert: boolean;
}

class SlFacilityContactsComponent extends React.Component<ISlFacilityContacts, IState> {
    private readonly contactActionBarItems: Array<ISLActionBar>;
    private contactModus: 'ADD' | 'EDIT' = 'ADD';

    public static defaultProps: Partial<ISlFacilityContacts> = {
        showRole: true,
    };

    private rowActions = [];

    constructor(props: ISlFacilityContacts) {
        super(props);

        this.state = { openContactPersonModal: false, selectedContactData: null, openAlert: false };
        this.contactActionBarItems = [
            {
                label: deTranslator.contactPerson.new,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: this.addContactModal,
            },
        ];

        this.buildRowActions();
    }

    buildRowActions() {
        if (isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) {
            this.rowActions = [
                {
                    icon: () => <MaterialUIIcons.EditOutlined color={'secondary'} />,
                    tooltip: 'Bearbeiten ',
                    onClick: this.editContactModal,
                },
                {
                    icon: () => <MaterialUIIcons.DeleteOutline color={'secondary'} />,
                    tooltip: 'Löschen User',
                    onClick: this.deleteContactModal,
                },
            ];
        } else {
            this.rowActions = [
                {
                    icon: () => <MaterialUIIcons.EditOutlined color={'secondary'} />,
                    tooltip: 'Bearbeiten ',
                    onClick: this.editContactModal,
                },
            ];
        }
    }

    private handleAddContact = () => {
        this.setState({ openContactPersonModal: false });
        if (this.contactModus === 'ADD') {
            this.props.addContact();
        } else {
            this.props.editContact();
        }
        this.setState({ selectedContactData: null });
    };

    private addContactModal = () => {
        this.contactModus = 'ADD';
        this.setState({ openContactPersonModal: true });
    };

    private editContactModal = (event, rowData) => {
        this.contactModus = 'EDIT';
        let selectedContactData: IContactData = this.props.contactPersons.find(
            item => item.ID === rowData.ID
        );
        this.setState({ selectedContactData: selectedContactData });
        this.setState({ openContactPersonModal: true });
    };
    private deleteContactModal = (event, rowData) => {
        const { tableData, ...rest } = rowData;
        this.setState({
            selectedContactData: rest,
            openAlert: true,
        });
    };

    private closeContactModal = () => {
        this.setState({ openContactPersonModal: false, selectedContactData: null });
    };

    private confirmDeleteText = (contactPerson: any): string => {
        const name = '';
        if (contactPerson.Anrede) {
            const name =
                contactPerson.Anrede === Salutation.Company
                    ? contactPerson.Firmenname1
                    : `${contactPerson.Vorname} ${contactPerson.Nachname}`;
            return `${deTranslator.contactPerson.alert.contentStart}  ${name} ${deTranslator.contactPerson.alert.contentEnd}`;
        }

        return `${deTranslator.contactPerson.alert.contentStart}  ${name} ${deTranslator.contactPerson.alert.contentEnd}`;
    };
    private buildDataTable = () => {
        return this.props.contactPersons.map((item: IContactData) => {
            if (item.Anrede && item.Anrede.Typ) {
                const { Anrede, ...rest } = item;
                if (item.Anrede.Typ === Salutation.Company) {
                    return {
                        Anrede: Anrede.Typ,
                        ...Anrede.Firma,
                        ...rest,
                    };
                } else {
                    return {
                        Anrede: Anrede.Typ,
                        ...Anrede.Person,
                        ...rest,
                    };
                }
            } else {
                return item;
            }
        });
    };

    public render() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.contactActionBarItems} />
                )}
                <div className={this.props.classes.tabContent} style={appStyles.container}>
                    <BasicTable
                        columns={this.props.tableColumns}
                        options={{ maxBodyHeight: 'calc(100vh - 200px)', actionsColumnIndex: -1 }}
                        data={this.buildDataTable()}
                        actions={this.rowActions}
                    />
                </div>
                <SLModal
                    title={deTranslator.user.contactData}
                    onClose={this.closeContactModal}
                    hideClose={true}
                    open={this.state.openContactPersonModal}
                    onOkButtonClick={this.handleAddContact}
                    OkButtonDisabled={this.props.contactPersonForm.invalid}
                    fullScreen={false}
                >
                    <SlContactDataForm
                        selectedContactData={this.state.selectedContactData}
                        showSave={false}
                        showRole={this.props.showRole}
                    />
                </SLModal>

                <SlAlertComponent
                    dialogTitle={deTranslator.contactPerson.alert.title}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.openAlert}
                    handleOK={() => {
                        this.props.deleteContact(this.state.selectedContactData);
                        this.setState({ openAlert: false, selectedContactData: null });
                    }}
                    handleCancel={() => this.setState({ openAlert: false })}
                >
                    {this.state.selectedContactData &&
                        this.confirmDeleteText(this.state.selectedContactData)}
                </SlAlertComponent>
            </div>
        );
    }
}

export const SlFacilityContacts = MaterialUI.withStyles(styles)(SlFacilityContactsComponent);
