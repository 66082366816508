import { IAbrechnungsItem } from '../../../../common/interfaces/interface';
import { FacilityBillingActionTypes } from '../types/facility-billing.actions.types';

export const saveFacilityBilling = (billing: any) => {
    return {
        type: FacilityBillingActionTypes.SAVE_FACILITY_BILLING,
        payload: billing
    };
};

export const saveFacilityBillingPeriods = (periods: Array<IAbrechnungsItem>) => {
    return {
        type: FacilityBillingActionTypes.SAVE_BILLING_PERIODS,
        payload: periods
    };
};

export const saveSelectedFacilityBillingPeriod = (index: number) => {
    return {
        type: FacilityBillingActionTypes.SAVE_SELECTED_BILLING_PERIOD_INDEX,
        payload: index
    };
};

export const saveBillAsCSVFile = (data: string) => {
    return {
        type: FacilityBillingActionTypes.SAVE_BILL_AS_CSV_FORMAT,
        payload: data
    };
};