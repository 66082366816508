import { saveContactPersons, saveSelectedContactPerson } from './actions/contactperson.action';
import { saveActiveTab, saveFacilities, saveSelectedFacility } from './actions/facility.action';
import {
    deleteContactPerson,
    getContactPersons,
    postContactPerson,
    updateContactPerson
} from './thunk/contact.operations';
import { fetchFacilityData } from './thunk/facility-data.operations';
import {
    deleteFacilityById,
    getFacilities,
    getFacilityById, getFacilityEmail,
    postFacility, postZaehldaten,
    updateFacility
} from './thunk/facility.operations';


// export reducers
export { facilityReducer } from './reducers/facility.reducer';
export { contactPersonReducer } from './reducers/contactperson.reducer';

// export actions and thunk operations
export const FacilityActions = Object.assign({},
    {getFacilities: getFacilities},
    {postFacility: postFacility},
    {updateFacility: updateFacility},
    {saveSelectedFacility: saveSelectedFacility},
    {saveFacilities: saveFacilities},
    {getFacilityById: getFacilityById},
    {deleteFacilityById: deleteFacilityById},
    {fetchFacilityData: fetchFacilityData},
    {getFacilityEmail: getFacilityEmail},
    {saveActiveTab: saveActiveTab},
    {postZaehldaten: postZaehldaten}
);
export const ContactPersonActions = Object.assign({},
    {saveSelectedContactPerson: saveSelectedContactPerson},
    {saveContactPersons: saveContactPersons},
    {postContactPerson: postContactPerson},
    {deleteContactPerson: deleteContactPerson},
    {getContactPersons: getContactPersons},
    {updateContactPerson: updateContactPerson});