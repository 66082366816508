import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IAblesung, IAdresse } from '../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../common/utils';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { Emails } from '../../../model/enums';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
        size: {
            width: 40,
            height: 40,
        },
        sizeIcon: {
            fontSize: 20,
        },
        inputFieldClass: {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            borderColor: 'black',
            border: '1px solid ',
            fontSize: 16,
            padding: '10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderColor: theme.palette.primary.main,
                boxShadow: '0 0 0 0.2rem none',
            },
        },
    });

export interface IFacilityZaehlDaten extends WithStyles<typeof styles> {
    facilityAdresse: IAdresse;
    ablesung: IAblesung;
    initialize?: any;
}

class FacilityZaehlDatenForm extends React.Component<IFacilityZaehlDaten> {
    /* public static defaultProps: Partial<IFacilityZaehlDaten> = {

    };*/

    private defaultEmailText: string = ``;

    public componentDidMount() {
        const { Strasse, HausNummer, PLZ, Ort } = this.props.facilityAdresse;
        const Ablesedatum = IsoformatToGermanDate(this.props.ablesung.Ablesedatum);
        this.defaultEmailText = `Sehr geehrte Damen und Herren,\n\nin Ihrer Kundenanlage in der ${Strasse} ${HausNummer}, ${PLZ} ${Ort} haben wir am ${Ablesedatum} eine Ablesung der verbauten StromLux-Zähler durchgeführt.\n\nFür die Verbrauchsabrechnung Ihrer Inhouse-Teilnehmer haben wir die Daten für Sie zusammengefasst.
\n\nVielen Dank für die Gute Zusammenarbeit,\n\nDominik Meinzer`;
        this.props.initialize({ EmailText: this.defaultEmailText, Email: Emails.StromluxEmail });
    }

    public render() {
        return (
            <div style={{ padding: 20 }}>
                <MaterialUI.Grid container={true} style={{ marginBottom: 16 }} spacing={10}>
                    <MaterialUI.Grid item={true} xs={6}>
                        <Field
                            name={'Email'}
                            required={true}
                            type="text"
                            component={SlTextInputComponent}
                            validate={[
                                FormValidator.required,
                                FormValidator.email,
                                FormValidator.maxLength50,
                            ]}
                            label={deTranslator.email.defaultEmail}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={10} classes={{}}>
                        <Field
                            name={'EmailText'}
                            label={'E-Mail-Text:'}
                            type={'text'}
                            component={SlTextInputComponent}
                            placeholder={'Hier kann ein Freitext eingetragen werden...'}
                            multiline={true}
                            rows={15}
                            disableUnderline={true}
                            options={{ fullWidth: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const SLZaehlDatenForm = reduxForm({
    form: 'FacilityZaehlDatenForm', // a unique identifier for this form
})(MaterialUI.withStyles(styles)(FacilityZaehlDatenForm));
