import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

export interface ITooltipComponentProps {
    text?: string,
    style?: {},
    children?: any

}

export class TooltipButtonComponent extends React.Component<ITooltipComponentProps, {}> {
    private static getDefaultAdornment() {
        return (
            <MaterialUI.IconButton className="iconButton"

                                   aria-label="Tooltip">
                <MaterialUIIcons.InfoOutlined/>
            </MaterialUI.IconButton>
        );
    }

    public static defaultProps: Partial<ITooltipComponentProps> = {
        text: 'Das Feld darf maximal 25 Zeichen haben',
        children: TooltipButtonComponent.getDefaultAdornment()
    };

    public render() {
        return (<MaterialUI.Tooltip
            id="tooltip-info-icon"
            title={<div>{this.props.text}</div>}>
            <MaterialUI.InputAdornment position="end">
                {this.props.children}
            </MaterialUI.InputAdornment>
        </MaterialUI.Tooltip>);
    }
}