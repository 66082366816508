import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import {
    IDevice,
    IDeviceType,
    IMeterPlace,
    IProduct,
} from '../../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../../common/utils';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { IForm } from '../../../../model/interfaces/IForm';
import { sbColors } from '../../../../theme/app.colors';
import { SlAppendDeviceContainerComponent } from '../../../meterpanels/components/append-devices/appendDeviceContainer.component';
import { SlRemoveDeviceForm } from '../form/sl-remove-device.form';

const styles = () =>
    MaterialUI.createStyles({
        toolbar: {
            backgroundColor: sbColors.blue,
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

export interface ISlDevicesChangeComponent extends WithStyles<typeof styles> {
    title: string;
    open: boolean;
    devices: Array<IDevice>;
    previousDevice: any;
    currentDevice: any;
    removeDeviceForm: IForm;
    appendDeviceForm: IForm;
    meterpanels: Array<IMeterPlace>;
    deviceTypeList: Array<IDeviceType>;
    productList: Array<IProduct>;
    onClose: () => void;
    onSave: () => void;
    handleStepChange?: () => void;
}

interface IState {
    activeStep: number;
    steps: Array<string>;
}

class SlDevicesChangeComponent extends React.Component<ISlDevicesChangeComponent, IState> {
    private removeDeviceInfo = [
        { title: 'WHGs-Nr', field: 'WohnungNr' },
        { title: 'Gebäude', field: 'Gebaeude' },
        { title: 'Geräte-ID', field: 'GeraeteID' },
        {
            title: 'Grundtyp',
            field: 'Grundtyp',
            render: rowData => rowData.Grundtyp.grundTyp,
        },
        {
            title: 'Ausbaudatum',
            field: 'Ausbaudatum',
            render: rowData => IsoformatToGermanDate(rowData.Ausbaudatum),
        },
        { title: 'AusbaustandHT', field: 'AusbaustandHT' },
        { title: 'AusbaustandNT', field: 'AusbaustandNT' },
    ];
    private addDeviceInfo = [
        { title: 'WHGs-Nr', field: 'WohnungNr' },
        { title: 'Gebäude', field: 'Gebaeude' },
        { title: 'Geräte-ID', field: 'GeraeteID' },
        {
            title: 'Grundtyp',
            field: 'Grundtyp',
            render: rowData => rowData.Grundtyp.grundTyp,
        },
        {
            title: 'Einbaudatum',
            field: 'Einbaudatum',
            render: rowData => IsoformatToGermanDate(rowData.Einbaudatum),
        },
        { title: 'EinbaustandHT', field: 'EinbaustandHT' },
        { title: 'EinbaustandNT', field: 'EinbaustandNT' },
    ];

    constructor(props: ISlDevicesChangeComponent) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: ['Geräteausbau', 'Geräteeinbau', 'Zusammenfassung'],
        };
    }

    private handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep < 1 ? 0 : activeStep - 1,
        });
    };

    private handleNext = () => {
        const { activeStep } = this.state;
        this.props.handleStepChange();
        this.setState({ activeStep: activeStep + 1 });
    };

    private handleClose = () => {
        this.setState({ activeStep: 0 });
        this.props.onClose();
    };

    private getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <SlRemoveDeviceForm
                        devices={this.props.devices}
                        currentFormDevices={this.props.previousDevice}
                    />
                );
            case 1:
                return (
                    <SlAppendDeviceContainerComponent
                        showAddButton={false}
                        productList={this.props.productList}
                        deviceTypeList={this.props.deviceTypeList}
                        currentFormDevices={this.props.currentDevice}
                        meterPanels={this.props.meterpanels}
                    />
                );
            case 2:
                return (
                    this.props.currentDevice && this.props.previousDevice && this.renderOverview()
                );

            default:
                return null;
        }
    }

    private renderOverview = () => {
        const addedDevices = this.props.currentDevice.devices.map(item => {
            const grundTyp = this.props.deviceTypeList.find(
                tmp => tmp.id === item.device.GrundtypId
            );
            return {
                WohnungNr: item.WohnungNr,
                Gebaeude: item.Gebaeude,
                Grundtyp: grundTyp,
                ...item.device,
            };
        });
        const removedDevices = this.props.previousDevice.devices.map(item => {
            const counterplace = this.props.meterpanels.find(tmp => tmp.ID === item.ZID);
            return { WohnungNr: counterplace.WohnungNr, Gebaeude: counterplace.Gebaeude, ...item };
        });
        return (
            <div>
                <BasicTable
                    columns={this.removeDeviceInfo}
                    title={'Geräteausbau'}
                    data={removedDevices}
                    options={{
                        paging: true,
                        pageSize: 10,
                        toolbar: true,
                        maxBodyHeight: 'calc(100vh - 605px)',
                    }}
                />
                <div style={{ marginBottom: 50 }} />

                <BasicTable
                    columns={this.addDeviceInfo}
                    options={{
                        paging: true,
                        pageSize: 10,
                        toolbar: true,
                        maxBodyHeight: 'calc(100vh - 605px)',
                    }}
                    title={'Geräteeinbau'}
                    data={addedDevices}
                />
            </div>
        );
    };

    public render() {
        return (
            <MaterialUI.Dialog
                open={this.props.open}
                maxWidth={'lg'}
                fullWidth={true}
                fullScreen={true}
                scroll={'paper'}
            >
                <MaterialUI.DialogTitle
                    disableTypography={true}
                    className={this.props.classes.toolbar}
                >
                    <MaterialUI.Typography variant="h5" color={'inherit'}>
                        {this.props.title}
                    </MaterialUI.Typography>
                    <MaterialUIIcons.Close
                        onClick={this.handleClose}
                        style={{ cursor: 'pointer' }}
                        color={'inherit'}
                    />
                </MaterialUI.DialogTitle>

                <MaterialUI.DialogContent>
                    <MaterialUI.Stepper activeStep={this.state.activeStep} nonLinear={true}>
                        {this.state.steps.map(label => {
                            return (
                                <MaterialUI.Step key={label}>
                                    <MaterialUI.StepLabel>{label}</MaterialUI.StepLabel>
                                </MaterialUI.Step>
                            );
                        })}
                    </MaterialUI.Stepper>

                    <div>{this.getStepContent(this.state.activeStep)}</div>
                </MaterialUI.DialogContent>

                <MaterialUI.DialogActions>
                    <MaterialUI.Button color="primary" onClick={this.handleClose}>
                        {deTranslator.global.cancel}
                    </MaterialUI.Button>
                    <MaterialUI.Button color="primary" variant="outlined" onClick={this.handleBack}>
                        {deTranslator.global.back}
                    </MaterialUI.Button>
                    {this.state.activeStep > 1 ? (
                        <MaterialUI.Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                this.setState({ activeStep: 0 });
                                this.props.onSave();
                            }}
                        >
                            {deTranslator.global.save}
                        </MaterialUI.Button>
                    ) : (
                        <MaterialUI.Button
                            color="primary"
                            variant="contained"
                            disabled={
                                this.state.activeStep === 0
                                    ? this.props.removeDeviceForm.invalid
                                    : this.props.appendDeviceForm.invalid
                            }
                            onClick={this.handleNext}
                        >
                            {deTranslator.global.next}
                        </MaterialUI.Button>
                    )}
                </MaterialUI.DialogActions>
            </MaterialUI.Dialog>
        );
    }
}

export const SlDevicesChange = MaterialUI.withStyles(styles)(SlDevicesChangeComponent);
