import { withStyles } from '@material-ui/styles';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import * as MaterialUI from '../../../materialUI/MaterialUI';

import { sbColors } from '../../../theme/app.colors';
import { TooltipButtonComponent } from '../buttons/tooltip-button.component';

const styles = {};

export const inputIbanMask = props => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={true}
            mask={[
                /[A-Z]/,
                /[A-Z]/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
            ]}
            showMask={false}
        />
    );
};

const CustomizedIbanInputs = inputProps => {
    const {
        input,
        label,
        type,
        options,
        rows,
        readOnly,
        disabled,
        required,
        style,
        meta: { touched, error, warning },
    } = inputProps;

    return (
        <MaterialUI.TextField
            variant={'outlined'}
            label={label}
            type={type}
            style={{ ...style }}
            placeholder={'DEXX XXXX XXXX XXXX XXXX XX'}
            {...input}
            error={touched && error ? true : false}
            disabled={disabled ? disabled : false}
            rows={rows ? rows : 1}
            helperText={
                touched &&
                ((error && <span>{error}</span>) ||
                    (warning && <span style={{ color: sbColors.orange }}>{warning}</span>))
            }
            InputProps={{
                inputComponent: inputIbanMask,
                readOnly: readOnly ? readOnly : false,
                classes: {
                    input: options && options.inputFieldClass,
                },
                endAdornment: options && options.showInfoIcon && (
                    <TooltipButtonComponent text={options.infoText} />
                ),
            }}
            InputLabelProps={{
                shrink: options && options.shrink ? options.shrink : true,
            }}
            fullWidth={options && options.fullWidth}
            required={required}
        />
    );
};

export const SlIBANComponent = withStyles(styles)(CustomizedIbanInputs);
