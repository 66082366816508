import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { IProduct } from '../../../common/interfaces/interface';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { AddSlimSensorRow } from './addSensorRow.form';
import { SensorTyp } from '../../../model/enums';

const styles = () => {
    return MaterialUI.createStyles({
        main: {
            position: 'absolute' as 'absolute',
            top: '23%',
            right: '30',
        },
        labels: {
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop: 20,
            marginRight: 70,
        },
        item: {
            flexBasis: '5%',
            paddingLeft: 5,
            paddingRight: 5,
        },
    });
};

export interface IAddSlimSensorProps extends WithStyles<typeof styles> {
    meterPanels?: any[];
    productList: Array<IProduct>;
    initialize: (sensors) => void;
    defaultSensors?: any;
    showAddButton?: boolean;
    editMode?: boolean;
}

class AddSlimSensorClass extends React.Component<IAddSlimSensorProps, any> {
    constructor(props: IAddSlimSensorProps) {
        super(props);

        if (this.props.defaultSensors) {
            this.props.initialize({ sensors: this.props.defaultSensors });
        } else {
            const defaultItems = this.props.meterPanels.map(meterpanel => {
                return {
                    WohnungNr: meterpanel.WohnungNr,
                    Gebaeude: meterpanel.Gebaeude,
                    zaehlerplatz_id: meterpanel.id,
                    sensor: {
                        organisation_id: meterpanel.organisation_id,
                        zaehlerplatz_id: meterpanel.id,
                        elementIot_id: '',
                        Seriennummer: '',
                        Typ: SensorTyp.Stromzaehler,
                        Einbaudatum: '',
                        Ausbaudatum: '',
                        Produkts: [],
                        Versanddatum: '',
                        Technologie: 'LoraWan',
                    },
                    Type: 'first',
                };
            });
            this.props.initialize({ sensors: defaultItems });
        }
    }

    private getDefaultProperty = meterpanel => {
        return {
            WohnungNr: meterpanel.WohnungNr,
            Gebaeude: meterpanel.Gebaeude,
            zaehlerplatz_id: meterpanel.id,
            sensor: {
                organisation_id: meterpanel.organisation_id,
                zaehlerplatz_id: meterpanel.id,
                elementIot_id: '',
                Seriennummer: '',
                Typ: SensorTyp.Stromzaehler,
                Einbaudatum: '',
                Ausbaudatum: '',
                Produkts: [],
                Versanddatum: '',
                Technologie: 'LoraWan',
            },
            Type: 'additional',
        };
    };

    private getNewEntry = meterPanel => {
        const newEntry = this.getDefaultProperty(meterPanel);

        newEntry.zaehlerplatz_id = meterPanel.id;
        newEntry.WohnungNr = meterPanel.WohnungNr;
        newEntry.sensor.zaehlerplatz_id = meterPanel.id;
        newEntry.Type = 'additional';
        newEntry.sensor.Typ = SensorTyp.Stromzaehler;
        return newEntry;
    };

    private onActionButtonClickHandler = (fields, index, device) => {
        if (device.Type === 'first') {
            return fields.insert(index + 1, this.getNewEntry(device));
        } else {
            return fields.remove(index);
        }
    };

    private renderContent = ({ fields }) => {
        return fields.map((sensor, index) => {
            const item = fields.get(index);
            return (
                <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    key={'AppendSensorPanel_' + index}
                >
                    {item.Type === 'first' && (
                        <>
                            <MaterialUI.ListItem>
                                <MaterialUI.ListItemText
                                    primary={`Wohnungsnummer: ${item.WohnungNr} , Gebäude: ${
                                        item.Gebaeude ? item.Gebaeude : ''
                                    }`}
                                />
                            </MaterialUI.ListItem>
                            <MaterialUI.Divider />
                        </>
                    )}
                    <AddSlimSensorRow
                        onClickActionButton={() =>
                            this.onActionButtonClickHandler(fields, index, fields.get(index))
                        }
                        value={sensor}
                        productList={this.props.productList}
                        showAddButton={this.props.showAddButton}
                        index={index}
                        editMode={this.props.editMode}
                        produktIds={
                            item.sensor.Produkts.length > 0
                                ? item.sensor.Produkts.map(productId => productId)
                                : []
                        }
                    />
                </div>
            );
        });
    };

    public render() {
        return (
            <div style={{ paddingTop: 20 }}>
                <MaterialUI.Grid
                    container={true}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'stretch'}
                    spacing={0}
                >
                    <MaterialUI.Grid item={true} xs={12}>
                        <FieldArray name={'sensors'} component={this.renderContent} />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid
                    container={true}
                    direction={'row'}
                    justify={'flex-start'}
                    alignItems={'center'}
                    style={{ marginTop: '35px' }}
                >
                    <MaterialUI.Typography variant="h6">*Pflichtfelder</MaterialUI.Typography>
                    <MaterialUI.Tooltip
                        id="tooltip-info-icon"
                        title={
                            <div style={{ fontSize: '1rem', lineHeight: '1.2rem' }}>
                                Nicht befüllte Zeilen werden bei der
                                <br />
                                Gerätezuordnung nicht berücksichtigt
                            </div>
                        }
                    >
                        <MaterialUI.InputAdornment position="end">
                            <MaterialUI.IconButton
                                className="iconButton"
                                aria-label="Tooltip"
                                disabled={true}
                            >
                                <MaterialUIIcons.InfoOutlined />
                            </MaterialUI.IconButton>
                        </MaterialUI.InputAdornment>
                    </MaterialUI.Tooltip>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const AddSlimSensorContainerComponent = MaterialUI.withStyles(styles)(AddSlimSensorClass);

export const AddSlimSensorContainerReduxForm = reduxForm({
    form: 'AddSlimSensorForm',
    destroyOnUnmount: true,
})(AddSlimSensorContainerComponent);

export const AddSlimSensorContainer = connect()(AddSlimSensorContainerReduxForm);
