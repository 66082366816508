import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

import { ICustomerState } from '../redux/reducers/customer.reducer';
import { PartnerComponent } from './partner.component';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
    });

export interface IBusinessPartnerProps extends WithStyles<typeof styles> {
    personOrCompany?: string;
    initialize: (customerdata: any) => void;
    mailBox?: string;
    street?: string;
    showTaxField: boolean;
    customerState?: ICustomerState;
    editMode?: boolean;
    umsatzsteuerpflichtig?: string;
}

class BusinessPartner extends React.Component<IBusinessPartnerProps, {}> {
    public static defaultProps: Partial<IBusinessPartnerProps> = {
        showTaxField: false,
        editMode: false,
    };

    constructor(props: IBusinessPartnerProps) {
        super(props);
        this.initializeData();
    }

    private initializeData() {
        if (this.props.editMode) {
            this.props.initialize(this.props.customerState.selectedCustomer);
        }
    }

    public render() {
        return (
            <PartnerComponent
                personOrCompany={this.props.personOrCompany}
                mailBox={this.props.mailBox}
                street={this.props.street}
                showTaxField={true}
                umsatzsteuerpflichtig={this.props.umsatzsteuerpflichtig}
            />
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    const personOrCompany = selector(state, 'Anrede.Typ');
    const mailBox = selector(state, 'Adresse.Postfach');
    const street = selector(state, 'Adresse.Strasse');
    const initialValues = { Adresse: { Land: 'DE' } };
    const firma = selector(state, 'Anrede.Firma');
    const umsatzsteuerpflichtig = firma ? firma.Umsatzsteuerpflichtig : '';
    return {
        personOrCompany,
        mailBox,
        street,
        initialValues,
        umsatzsteuerpflichtig,
    };
};

export const BusinessPartnerComponent = MaterialUI.withStyles(styles)(BusinessPartner);
const BusinessPartnerComponentReduxForm = reduxForm({
    form: 'BusinessPartnerForm', // a unique identifier for this form
    destroyOnUnmount: false, // dont delete the values when the form gets destroyed/tabs switch
})(BusinessPartnerComponent);

const selector = formValueSelector('BusinessPartnerForm');

export const SLBusinessPartnerForm = connect(mapStateToProps)(BusinessPartnerComponentReduxForm);
