export const titleStyles = {

    fontWeight350: {
        fontWeight: 350
    },
};

export const appStyles = {
    container: {
        margin: 'auto',
        maxWidth: '95%',
        paddingLeft: 10,
        paddingRight: 10
    }
};