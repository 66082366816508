// exports reducer

import { fetchArchivesLoading, saveFileBase64, saveArchives } from './actions/archive.actions';
import { downloadArchive, getArchives, postArchives, removeArchive } from './thunk/archive.operations';

export { archiveReducer } from './reducers/archive.reducer';

// export actions

export const ArchivesActions = Object.assign({},
    {fetchArchivesLoading: fetchArchivesLoading},
    {saveArchives: saveArchives},
    {getArchives: getArchives},
    {postArchives: postArchives},
    {removeArchive:removeArchive},
    {downloadArchive:downloadArchive},
    {saveFileBase64:saveFileBase64}
);