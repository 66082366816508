import {Theme} from '@material-ui/core';
import {WithStyles} from '@material-ui/styles';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators, Dispatch} from 'redux';

import {getFormValues, isInvalid} from 'redux-form';
import {deTranslator} from '../../../assets/i18n/deTranslator';
import {ISLActionBar, SlActionBar} from '../../../common/components/actionbar/sl-actionbar.component';
import {SlHeader} from '../../../common/components/header';
import {BasicTable, basicTableStyles, IMuiTableColumn,} from '../../../common/components/table/mui-table.component';
import {ISlTab} from '../../../common/components/tabs/sl-tabs.component';
import {GlobalActions} from '../../../common/redux';
import {ISnackBar} from '../../../common/redux/reducers/global.reducer';
import {
    currencyFormatter,
    defaultSort,
    IsoformatToGermanDate,
    isUserRole,
    removeEmptykey,
} from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import {Roles} from '../../../model/enums';
import {IReduxState} from '../../../model/redux-state';
import {history} from '../../../store';
import {sbColors} from '../../../theme/app.colors';
import {SettingsActions} from '../../settings/redux';
import {SlimPanelDetails} from '../components/slimPanelDetails.component';
import {SlimActions} from '../redux';
import {ISlimState} from '../redux/reducers/slim.reducer';
import {fetchGeraeteByZID} from '../slim-api/geraete-api/geraete.api';
import {fetchZaehlerplatzByZID, updateZaehlerplatzById} from '../slim-api/zaehlerplatz-api/zaehlerplatz.api';
import {MaterialUIIcons} from "../../../materialUI/MaterialUIIcons";
import {SLMeterPanelEditComponent} from "../../meterpanels/components/meterpanel-input/meterPanelEdit.component";
import {SLModal} from "../../../common/components/modal";
import {IForm} from "../../../model/interfaces/IForm";

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },

        marginRight: {
            marginRight: 24,
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 20,
            width: '100%',
            overflow: 'hidden',
        },

        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        tabContainer: {
            height: '100vh',
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 260px)',
            paddingTop: 24,
            paddingBottom: 24,
            paddingRight: 40,
            paddingLeft: 40,
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },

        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        smallInput: {
            width: '30%',
        },
        input: {
            marginTop: 0,
            background: sbColors.whiteSmoke,
            padding: 10,
            width: '100%',
            height: 20,
        },
        inputInvisible: {
            marginTop: 0,
            padding: 10,
            width: '100%',
            height: 20,
        },
        block: {
            marginTop: 10,
        },
    });

export interface ISlimZaehlerplatzProps extends WithStyles<typeof styles> {
    match: any;
    location: any;
    slimState: ISlimState;
    fetchSlimKundeById: (id: string) => void;
    fetchSlimKundenAnlagenByAID: (anlageId) => void;
    fetchSlimKAKontaktperson: (kabId, anlageId) => void;
    saveAnlageActiveTab: (tab: number) => void;
    fetchSlimKAZaehlerplatz: (kabId, anlageId) => void;
    getProductList?: () => void;
    getAbleseTypeList?: () => void;
    getDeviceTypeList?: () => void;
    loadingList: Array<string>;
    meterPanelForm?: IForm;
    getSlimDevices?: (kabId: string, aId: string) => void;
    setSnackbarStatus?: (item: ISnackBar) => void;
}

interface IState {
    openAppendDeviceModal: boolean;
    openOrderModal: boolean;
    selectZaehlerplatz: any;
    openMeterPanelModal: boolean;
    devices: any[];
}

class SlimZaehlerplatzComponent extends React.Component<ISlimZaehlerplatzProps, any> {
    private readonly tabs: Array<ISlTab>;
    private readonly kabID: string = null;
    private aID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;
    private orderType: string = '';
    private orderTitle: string = '';

    constructor(props: ISlimZaehlerplatzProps) {
        super(props);
        this.kabID = decodeURIComponent(props.match.params.kabID);
        this.aID = decodeURIComponent(props.match.params.aID);
        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'}/>,
                disable: false,
                action: () => this.setState({...this.state, openMeterPanelModal: true}),
            },
        ];
        this.state = {
            openOrderModal: false,
            openAppendDeviceModal: false,
            selectZaehlerplatz: null,
            openMeterPanelModal: false,
            devices: [],
        };
    }

    public componentDidMount() {
        // fetch bodyData from server
        const {zId} = this.props.match.params;
        fetchZaehlerplatzByZID(zId).then(result =>
            this.setState({...this.state, selectZaehlerplatz: result})
        );
        fetchGeraeteByZID(zId).then(result =>
            this.setState({...this.state, devices: result.items})
        );

        this.props.getProductList();
        this.props.getDeviceTypeList();
    }

    private handleUpdateMeterPanel = async () => {
        try {
            //  this.props. dispatch(addLoadingState('IS_LOADING'));
            const {
                geraeteList,
                Metergrid,
                sensors,
                updatedAt,
                createdAt,
                ...formData
            } = this.props.meterPanelForm.formValue;
            await updateZaehlerplatzById(removeEmptykey(formData));
            const {zId} = this.props.match.params;
            const result = await fetchZaehlerplatzByZID(zId);
            this.setState({...this.state, selectZaehlerplatz: result, openMeterPanelModal: false})
        } catch (e) {

        }


    }

    public goToDevice = rowData => {
        history.push(`/slim/kabs/${rowData.KID}/${rowData.AID}/${rowData.ZID}/${rowData.id}`);
    };
    private deviceInfo = () => [
        {
            title: 'Geräte-ID',
            field: 'Seriennummer',
            render: rowData => {
                return (
                    <MaterialUI.Typography
                        style={basicTableStyles.underlineText}
                        color={'secondary'}
                        onClick={() => this.goToDevice(rowData)}
                    >
                        {rowData.Seriennummer}
                    </MaterialUI.Typography>
                );
            },
        },
        {
            title: 'WHGs-Nr',
            field: 'WohnungNr',
            render: rowData => {
                return this.state.selectZaehlerplatz ? this.state.selectZaehlerplatz.WohnungNr : '';
            },
        },
        {
            title: 'GerätTyp',
            field: 'ZaehlerTyp',
            render: rowData => {
                return rowData ? rowData['ZaehlerTyp'] : '';
            },
        },
        {
            title: 'Einbaudatum',
            field: 'Einbaudatum',
            render: rowData => {
                const einbau = rowData.ablesungList.items.find(
                    item => item.Ablesegrund === 'Einbau'
                );
                return einbau ? IsoformatToGermanDate(einbau.Ablesedatum) : '-';
            },
        },
        {
            title: 'Ausbaudatum',
            field: 'Ausbaudatum',
            render: rowData => {
                const ausBau = rowData.ablesungList.items.find(
                    item => item.Ablesegrund === 'Ausbau'
                );
                return ausBau ? IsoformatToGermanDate(ausBau.Ablesedatum) : '-';
            },
        },
        {title: 'Ablauf Eichgültigkeit', field: 'Eichdatum'},
    ];

    private sensorsTableColumns = (): IMuiTableColumn[] => {
        return [
            {
                title: 'SensorID',
                field: 'Seriennummer',
                render: rowData => {
                    return rowData.Seriennummer;
                },
            },
            {
                title: 'Wohnungs-NR',
                field: 'WohnungNr',
                defaultSort: 'asc',
                customSort: (meterA, meterB) => {
                    if (meterA && meterB) {
                        return defaultSort(
                            meterA.Zaehlerplatz.WohnungNr,
                            meterB.Zaehlerplatz.WohnungNr
                        );
                    }
                    return 0;
                },
                render: rowData => {
                    return rowData && rowData.Zaehlerplatz ? rowData.Zaehlerplatz.WohnungNr : '';
                },
            },
            {
                title: 'Gebäude',
                field: 'Gebaeude',
                render: rowData => {
                    return rowData && rowData.Zaehlerplatz ? rowData.Zaehlerplatz.Gebaeude : '';
                },
            },

            {
                title: 'Einbaudatum',
                field: 'Einbaudatum',
                render: rowData => {
                    return rowData && rowData.Einbaudatum
                        ? IsoformatToGermanDate(rowData.Einbaudatum)
                        : '-';
                },
            },
            {
                title: 'Ausbaudatum',
                field: 'Ausbaudatum',
                render: rowData => {
                    return rowData && rowData.Ausbaudatum
                        ? IsoformatToGermanDate(rowData.Ausbaudatum)
                        : '-';
                },
            },
            {
                title: 'Technologie',
                field: 'Technologie',
                render: rowData => {
                    return rowData && rowData.Technologie ? rowData.Technologie : '-';
                },
            },
            {
                title: 'Produktauswahl',
                field: 'Produkt',
                render: rowData => {
                    return rowData && rowData.Produkts
                        ? rowData.Produkts.map(item => (
                            <li key={item.id}>
                                {`${item.name} (${currencyFormatter(item['preis'] / 100)})`}
                            </li>
                        ))
                        : '';
                },
            },
            {
                title: 'ElementIotId',
                field: 'elementIot_id',
            },
        ];
    };




    public renderDevices() {
        const {selectZaehlerplatz} = this.state;
        if (selectZaehlerplatz && selectZaehlerplatz.geraeteList) {
            return (
                <div className={this.props.classes.block}>
                    <MaterialUI.Divider/>
                    <BasicTable
                        columns={this.deviceInfo()}
                        title={`Zugeordnete ${deTranslator.device.title}`}
                        options={{toolbar: true, maxBodyHeight: 'calc(100vh - 345px)'}}
                        data={this.state.devices}
                    />
                </div>
            );
        }
        return null;
    }

    public renderSensors() {
        const {selectZaehlerplatz} = this.state;
        if (selectZaehlerplatz && selectZaehlerplatz.sensors && selectZaehlerplatz.sensors.items) {
            return (
                <div className={this.props.classes.block}>
                    <MaterialUI.Divider/>
                    <BasicTable
                        columns={this.sensorsTableColumns()}
                        title={`Zugeordnete Sensoren`}
                        options={{toolbar: true, maxBodyHeight: 'calc(100vh - 345px)'}}
                        data={selectZaehlerplatz.sensors.items.map(item => ({
                            ...item,
                            Zaehlerplatz: selectZaehlerplatz,
                        }))}
                    />
                </div>
            );
        }
        return null;
    }

    public renderContent() {
        const {selectZaehlerplatz} = this.state;
        if (selectZaehlerplatz) {
            return (
                <div>
                    {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                        <SlActionBar items={this.masterDataActionBarItems}/>
                    )}
                    <div className={this.props.classes.tabContainer}>
                        <div className={this.props.classes.tabContent}>
                            <SlimPanelDetails meterPanel={selectZaehlerplatz}/>
                            {this.renderDevices()}
                            {this.renderSensors()}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader currentLocation={null} tabs={[]} showOnlyBackArrow={true}/>

                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar/>
                    <MaterialUI.Toolbar/>
                    {this.renderContent()}
                </div>
                <SLModal
                    title={'Zählplatz editieren'}
                    onClose={() => this.setState({openMeterPanelModal: false})}
                    hideClose={true}
                    open={this.state.openMeterPanelModal}
                    onOkButtonClick={this.handleUpdateMeterPanel}
                    OkButtonDisabled={this.props.meterPanelForm.invalid}
                >
                    <SLMeterPanelEditComponent
                        meterPanel={this.state.selectZaehlerplatz}
                        appType={'SLIM'}
                    />
                </SLModal>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        slimState: state.slimState,
        meterPanelForm: {
            formValue: getFormValues('MeterPanelEditForm')(state),
            invalid: isInvalid('MeterPanelEditForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, SlimActions, GlobalActions, SettingsActions),
        dispatch
    );
};

export const SlimZaehlerplatzWithStyles = MaterialUI.withStyles(styles)(SlimZaehlerplatzComponent);

export const SlimZaehlerplatzPage = withRouter(
    connect<ISlimZaehlerplatzProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<ISlimZaehlerplatzProps>(SlimZaehlerplatzWithStyles)
);
