import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { SlimReadingRowForm } from './reading-row.form';

const styles = () =>
    MaterialUI.createStyles({
        maxWidth: {
            width: 375,
        },
        itemWidth: {
            flexGrow: 0,
            maxWidth: '12%',
            flexBasis: '12%',
        },
        disabledTextField: {
            backgroundColor: '#E2E2E2',
        },
    });

export interface IAddDeviceReadingProps extends WithStyles<typeof styles> {
    devices: Array<any>;
    initialize?: (data) => void;
    dispatch?: any;
    currentFormDevices?: any;
}

class SlimAddReadingDevice extends React.Component<IAddDeviceReadingProps> {
    public componentDidMount() {
        this.props.initialize({
            Ablesungen: this.props.devices.map(item => {
                return {
                    organisation_id: item.organisation_id,
                    geraet_id: item.id,
                    Seriennummer: item.Seriennummer,
                    ZID: item.ZID,
                    ZaehlerTyp: 'Mieterstromzaehler',
                    Unit: 'kWh',
                };
            }),
        });
    }

    private renderContent = ({ fields }) => {
        return fields.map((ablesung, index) => {
            let lastReading = null;
            if (
                this.props &&
                this.props.devices &&
                this.props.devices[index] &&
                this.props.devices[index].ablesungList &&
                this.props.devices[index].ablesungList.items.length > 0
            ) {
                lastReading = this.props.devices[index].ablesungList.items.sort(
                    (rowA, rowB) =>
                        new Date(rowB.createdAt).getTime() - new Date(rowA.createdAt).getTime()
                )[0];
            }

            return (
                <div key={this.props.devices[index].id}>
                    <SlimReadingRowForm formRow={ablesung} lastReading={lastReading} />
                </div>
            );
        });
    };

    public render() {
        return (
            <div>
                <div style={{ paddingTop: 20 }}>
                    {this.props.devices.length > 0 ? (
                        <FieldArray name="Ablesungen" component={this.renderContent} />
                    ) : null}
                    <div style={{ marginBottom: '20px' }}>
                        <MaterialUI.Typography style={{ paddingTop: '35px' }}>
                            *Pflichtfelder
                        </MaterialUI.Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export const SlimAddReadingDeviceComponent = MaterialUI.withStyles(styles)(SlimAddReadingDevice);

const AddReadingReduxForm = reduxForm({
    form: 'AddSlimReadingDeviceForm', // a unique identifier for this form
    destroyOnUnmount: true,
})(SlimAddReadingDeviceComponent);
export const AddSlimReadingDeviceForm = connect()(AddReadingReduxForm);
