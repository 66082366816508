import axios from 'axios';
import { IReduxState } from '../../../../model/redux-state';
import { saveSuggestions, suggestionLoading } from '../actions/search.actions';

export const getSuggestions = (query: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        dispatch(suggestionLoading(true));
        try {
            // query = query.toUpperCase();
            const response = await axios.get(`search?id=${query}`);
            dispatch(saveSuggestions(response && response.data ? response.data : null));
            dispatch(suggestionLoading(false));
        } catch (error) {
            // dispatch(setError(global));
            dispatch(saveSuggestions(null));
            dispatch(suggestionLoading(false));
        }
    };
};
