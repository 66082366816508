import * as React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { germanDateToISODateFormat, inputDateDisplayFormat } from '../../../common/utils/Functions';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent,
} from '../../../common/components/forms';
import { FormValidator } from '../../../common/validators/form.validator';
import { SlNumberInputComponent } from '../../../common/components/forms/number-input.component';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { sbColors } from '../../../theme/app.colors';
import { AblesegrundTypEnum } from '../common/constant';
import { CreateAblesungInput } from '../slim-api/ablesung-api/ablesung.api';

interface IProps {
    formRow: any;
    lastReading: CreateAblesungInput | null;
}
class ReadingRowForm extends React.Component<IProps, any> {
    public render() {
        const { formRow, lastReading } = this.props;

        return (
            <MaterialUI.Grid
                container={true}
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ marginBottom: 20 }}
                key={`${formRow}.geraet_id`}
            >
                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${formRow}.Seriennummer`}
                        label={'Geräte-ID'}
                        component={SlTextInputComponent}
                        readOnly={true}
                        disabled={true}
                        options={{ fullWidth: true }}
                    />
                </MaterialUI.Grid>

                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${formRow}.Ablesedatum`}
                        label={'Ablesedatum'}
                        format={inputDateDisplayFormat}
                        normalize={germanDateToISODateFormat}
                        component={SlDateInputComponent}
                        required={true}
                        options={{ fullWidth: true, shrink: true }}
                        validate={[
                            FormValidator.required,
                            FormValidator.meterReadingDate,
                            // this.dateValidator,

                            FormValidator.isDateBefore(
                                germanDateToISODateFormat(lastReading.Ablesedatum || null)
                            ),
                        ]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}>
                    <SlNumberInputComponent
                        name={`${formRow}.ZaehlerstandHT`}
                        label={`Zählerstand HT (${deTranslator.einheit.kilowatt})`}
                        required={true}
                        validate={[
                            FormValidator.required,
                            FormValidator.isBiggerOrEqualThan(lastReading.ZaehlerstandHT || null),
                        ]}
                        // validate={[]}
                    />
                </MaterialUI.Grid>
                <MaterialUI.Grid item={true} xs={3}>
                    <Field
                        name={`${formRow}.Ablesegrund`}
                        component={SlSelectInputComponent}
                        label={'Ablesegrund'}
                        required={true}
                        style={{ color: sbColors.orange }}
                        options={{
                            fullWidth: true,
                            shrink: true,
                        }}
                        validate={[FormValidator.required]}
                    >
                        {renderItemForSelectBox(AblesegrundTypEnum)}
                    </Field>
                </MaterialUI.Grid>
            </MaterialUI.Grid>
        );
    }
}

export const SlimReadingRowForm = connect()(ReadingRowForm);
