import axios from 'axios';
import { setError } from '../common/redux/actions/global.action';
import { IError } from '../common/redux/reducers/global.reducer';
import { TokenService } from '../features/login/services/token.service';
import { HTTPStatusCodes } from '../model/enums';
import { history, store } from '../store';

export const responseInterceptor = {
    onFulfilled: response => response,
    onRejected: async (error) => {
        const myError: IError = {statusCode: -1, message: null};

        if (error.response) {
            switch (error.response.status) {
                case HTTPStatusCodes.BAD_REQUEST:
                    myError.statusCode = error.response.status;
                    myError.message = error.response.data.message || error.response.data.messages.join(' ');
                    store.dispatch(setError(myError)); // dispatch error in store
                    break;
                case HTTPStatusCodes.UNAUTHORIZED:
                    const originalRequest = error.config;
                    if (TokenService.isTokenExpired() && !TokenService.requestPending) {
                        const httpResponse = await TokenService.refreshToken();
                        TokenService.requestPending = false;
                        if (httpResponse.token && httpResponse.token.id_token) {
                            TokenService.saveToken(httpResponse.token);
                            originalRequest.headers['Authorization'] = httpResponse.token.id_token;
                        } else {
                            TokenService.signOut(); // call logout action
                            history.push('/login'); // redirect to login
                        }
                    }
                    // retry the request
                    return axios.request(originalRequest);
                case HTTPStatusCodes.PRECONDITION_FAILED:
                    myError.statusCode = error.response.status;
                    myError.message = 'Die Daten wurden bereits von einem anderen Sachbearbeiter geändert. Bitte aktualisieren Sie die Seite um die neuen Daten zu laden!';
                    store.dispatch(setError(myError));// dispatch error in store
                    break;
                case HTTPStatusCodes.INTERNAL_SERVER_ERROR:
                    myError.statusCode = HTTPStatusCodes.INTERNAL_SERVER_ERROR;
                    myError.message = error.response.data.message || error.response.data.messages[0];
                    store.dispatch(setError(myError));
                    break;
            }

        } else {
            // TokenService.signOut(); // call logout action
            // history.push('/login'); // redirect to login
            // myError.message = error.message;
            console.error(error.message);

        }
        // Do something with response global
        return Promise.reject(myError);
    }
};

export const requestInterceptor = {
    onFulfilled: config => {
        config.headers = {
            'Authorization': `Bearer ${TokenService.getTokenFromLocalStorage()}`,
            'Content-Type': 'application/json',
        };
        return config;
    },
    onRejected: error => Promise.reject(error)
};