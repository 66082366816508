import { WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../../common/components/actionbar/sl-actionbar.component';
import { ErrorComponent } from '../../../../common/components/error';

import { SlAlertComponent, SLModal } from '../../../../common/components/modal';
import { AusbauauftragIcon } from '../../../../common/components/svgIcons/Ausbauauftrag';
import { GeraeteIcon } from '../../../../common/components/svgIcons/Geraete';
import { GeraetewechselIcon } from '../../../../common/components/svgIcons/Geraetewechsel';
import { GeraetezuordnenIcon } from '../../../../common/components/svgIcons/Geraetezuordnen';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../../common/components/table/mui-table.component';
import {
    IAuftrag,
    IDevice,
    IDeviceType,
    IMeterPlace,
    IProduct,
} from '../../../../common/interfaces/interface';
import { GlobalActions } from '../../../../common/redux';
import { IsoformatToGermanDate } from '../../../../common/utils';
import { deleteText, isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { AuftragType, Roles } from '../../../../model/enums';
import { IForm } from '../../../../model/interfaces/IForm';
import { IReduxState } from '../../../../model/redux-state';
import { history } from '../../../../store';
import { sbColors } from '../../../../theme/app.colors';
import { appStyles } from '../../../../theme/common.styles';
import { AuftragActions } from '../../../auftrag/redux';
import { MeterPanelsActions } from '../../../meterpanels/redux';
import { IMeterPanelState } from '../../../meterpanels/redux/reducers/meterpanels.reducer';
import { DevicesActions } from '../../redux';
import { IDevicesState } from '../../redux/reducers/devices.reducer';
import { SlRemoveDeviceForm } from '../form/sl-remove-device.form';
import { SlAuftragComponent } from '../form/slAuftragComponent';
import { SlDevicesChange } from './sl-devices-change.component';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

interface ISlDevicesComponent extends WithStyles<typeof styles> {
    kabID: string;
    aID: string;
    meterPanelState: IMeterPanelState;
    deviceState?: IDevicesState;
    facilityEmail?: any;
    devices: Array<IDevice>;
    dataTable: Array<any>;
    selectedDevice: IDevice;
    onDeviceClick: (device: IDevice) => void;
    deviceTypeList: Array<IDeviceType>;
    productList: Array<IProduct>;
    addRemoveDeviceForm?: IForm;
    auftragForm: IForm;
    appendDeviceForm?: IForm;
    updateDevices?: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    getDevices?: (kabId: string, anlageId: string) => void;
    getMeterPanels: (kabID: string, aID: string) => void;
    addDevices?: (devices) => void;
    postDevices: (kabID: string, aID: string, payload: IDevice) => void;
    handleRemovingDevice?: (kabID: string, aID: string, device: Array<any>) => void;
    changeDevice?: (previousDevice: any, currentDevice: any) => void;
    postAuftrag?: (kabID: string, aID: string, payload: any) => { ID: string };
    deleteDevices?: (kabID: string, aID: string, ids: string) => void;
    sendEmailAuftrag?: (
        payload: IAuftrag,
        auftragId: string,
        meterplaces: Array<IMeterPlace>,
        deviceMeterpanels: Array<{ device: IDevice; meterplace: IMeterPlace }>
    ) => void;
}

interface IState {
    showRemoveDeviceWarning: boolean;
    showOrderDeviceModal: boolean;
    showRemoveDeviceModal: boolean;
    selectedRowId: string;
    showChangeDeviceModal: boolean;
    showDeviceActionWarning: boolean;
    showDeviceRepairAlert: boolean;
    tableActionBarItems: Array<ISLActionBar>;
    openDeleteAlert: boolean;
}

class SlDevicesComponent extends React.Component<ISlDevicesComponent, IState> {
    private readonly deviceInfo: Array<IMuiTableColumn> = [];
    private orderType: string = '';
    private orderTitle: string = '';
    private selectedDevices: Array<IDevice> = [];
    private selectedRowItems: Array<any> = [];

    constructor(props: ISlDevicesComponent) {
        super(props);
        this.state = {
            showRemoveDeviceWarning: false,
            showOrderDeviceModal: false,
            showRemoveDeviceModal: false,
            selectedRowId: '',
            showChangeDeviceModal: false,
            showDeviceActionWarning: false,
            showDeviceRepairAlert: false,
            openDeleteAlert: false,
            tableActionBarItems: [
                {
                    label: deTranslator.global.changeDevice,
                    action: () => this.handleChangesDevices(),
                    icon: <GeraetewechselIcon color={sbColors.orange} />,
                    disable: true,
                },
                /* {
                    label: deTranslator.global.faults,
                    action: () => this.handleDeviceFailure(),
                    icon: <StoerungIcon color={sbColors.orange}/>,
                    disable: true,
                },*/
                {
                    label: deTranslator.global.removeDeviceOrder,
                    action: () => this.handleRemoveDeviceOrder(),
                    icon: <AusbauauftragIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: deTranslator.global.deviceRemovalConfirmation,
                    action: () => this.handleRemoveDevicesConfirmation(),
                    icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                    disable: true,
                },
                {
                    label: 'Rückmeldung Gerätewechsel',
                    action: () => this.handleChangesDevicesConfirmation(),
                    icon: <GeraeteIcon color={sbColors.orange} />,
                    disable: true,
                },
                isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)
                    ? {
                          label: `${deTranslator.global.delete}`,
                          action: () => this.handleDeleteDevices(),
                          icon: <MaterialUIIcons.Delete />,
                          disable: true,
                      }
                    : null,
            ],
        };

        this.deviceInfo = [
            {
                title: 'Geräte-ID',
                field: 'GeraeteID',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToDevice(rowData)}
                        >
                            {rowData.GeraeteID}
                        </MaterialUI.Typography>
                    );
                },
            },
            {
                title: 'Wohnungs-Nr',
                field: 'WohnungNr',
                render: rowData => {
                    return <MaterialUI.Typography>{rowData.WohnungNr}</MaterialUI.Typography>;
                },
            },
            {
                title: 'Gebäude',
                field: 'Gebaeude',
                render: rowData => {
                    return <MaterialUI.Typography>{rowData.Gebaeude}</MaterialUI.Typography>;
                },
            },
            {
                title: 'Grundtyp',
                field: 'Grundtyp',
                render: rowData => rowData.Grundtyp.grundTyp,
            },
            {
                title: 'Einbaudatum',
                field: 'Einbaudatum',
                defaultSort: 'desc',
                render: rowData =>
                    IsoformatToGermanDate(rowData.Einbaudatum ? rowData.Einbaudatum : '-'),
            },
            {
                title: 'Ausbaudatum',
                field: 'Ausbaudatum',
                render: rowData =>
                    IsoformatToGermanDate(rowData.Ausbaudatum ? rowData.Ausbaudatum : '-'),
            },
            {
                title: 'Ablauf Eichgültigkeit',
                field: 'Eichdatum',
                render: rowData => (rowData.Eichdatum ? rowData.Eichdatum : '-'),
            },
        ];
    }

    public componentWillUnmount() {
        this.props.onDeviceClick(null);
    }

    private handleCheckBoxClick = data => {
        this.selectedRowItems = data.filter(item => item.Ausbaudatum && item.Ausbaudatum === '-');
        this.setState({ tableActionBarItems: [...this.buildTableActions(data.length > 0)] });
    };

    private getSelectedDevices = () => {
        return this.selectedRowItems.map(item => {
            return this.props.devices.find(device => device.ID === item.ID);
        });
    };

    private getSelectedMeterPanels = () => {
        return this.getSelectedDevices()
            .filter(item => item)
            .map(device => device.ZID)
            .map(id =>
                this.props.meterPanelState.meterPanels.find(counterPlace => counterPlace.ID === id)
            );
    };
    private findDeviceWithAusbaudatum = devices => {
        return devices.find(item => item.Ausbaudatum && item.Ausbaudatum !== '');
    };

    private handleChangesDevices = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Wechselauftrag;
            this.orderTitle = deTranslator.global.changeDeviceOrder;
            this.selectedDevices = this.getSelectedDevices();
            const removedDevice = this.selectedDevices.find(
                item => item.Ausbaudatum && item.Ausbaudatum !== ''
            );
            if (removedDevice) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        } else {
            this.setState({ showDeviceActionWarning: true });
        }
    };

    private handleRemoveDevicesConfirmation = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedDevices = this.getSelectedDevices();
            if (this.findDeviceWithAusbaudatum(this.selectedDevices)) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showRemoveDeviceModal: true });
            }
        }
    };
    private handleRemoveDeviceOrder = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Ausbauauftrag;
            this.orderTitle = deTranslator.global.removeDeviceOrder;
            this.selectedDevices = this.getSelectedDevices();
            this.setState({ showOrderDeviceModal: true });
        }
    };

    private handleDeviceFailure = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Entstoerungsauftrag;
            this.orderTitle = deTranslator.global.faults;
            this.selectedDevices = this.getSelectedDevices();
            if (this.findDeviceWithAusbaudatum(this.selectedDevices)) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };

    private handleChangesDevicesConfirmation() {
        if (this.selectedRowItems.length > 0) {
            this.selectedDevices = this.getSelectedDevices();
            if (this.findDeviceWithAusbaudatum(this.selectedDevices)) {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showChangeDeviceModal: true });
            }
        }
    }

    private handleDeleteDevices = () => {
        if (this.selectedRowItems.length > 0) {
            this.setState({ openDeleteAlert: true });
        }
    };

    private doDevicesOrder = async () => {
        this.setState({ showOrderDeviceModal: false });
        const auftrag: any = Object.assign(
            {},
            {
                Type: this.orderType,
                DeviceIds: this.selectedDevices.map(item => item.ID),
            },
            { ...this.props.auftragForm.formValue }
        );

        const deviceMeterplaces = this.selectedRowItems.map(item => {
            const meterpanel = this.props.meterPanelState.meterPanels.find(
                meter => meter.ID === item.ZID
            );
            return { device: item, meterplace: meterpanel };
        });
        const { ID } = await this.props.postAuftrag(this.props.kabID, this.props.aID, auftrag);

        ID && this.props.sendEmailAuftrag(auftrag, ID, [], deviceMeterplaces);
        this.selectedRowItems = [];
        this.selectedDevices = [];
        this.selectedRowItems = [];
    };

    private removeDeviceOrder = async (devices: Array<any>) => {
        this.setState({ showRemoveDeviceModal: false });
        await this.props.handleRemovingDevice(this.props.kabID, this.props.aID, devices);
        await this.props.getDevices(this.props.kabID, this.props.aID);
        this.selectedDevices = [];
        this.selectedRowItems = [];
    };

    private saveChangedDevicesConfirmation = async () => {
        this.selectedDevices = [];
        this.selectedRowItems = [];
        this.setState({ showChangeDeviceModal: false });
        await this.removeDeviceOrder(this.props.deviceState.changedDevice.previousDevice.devices);
        const payload = this.props.deviceState.changedDevice.currentDevice.devices.map(
            item => item.device
        );
        await this.props.postDevices(this.props.kabID, this.props.aID, payload);
        await this.props.getMeterPanels(this.props.kabID, this.props.aID);
        await this.props.getDevices(this.props.kabID, this.props.aID);
    };

    private buildTableActions = (active: boolean) => {
        return this.state.tableActionBarItems.map(item => {
            item.disable = !active;
            return item;
        });
    };

    public goToDevice = ({ ZID, ID }) => {
        const link = `/kabs/${this.props.kabID}/${this.props.aID}/${ZID}/${ID}`;
        history.push(link);
    };

    public onDeviceChange = () => {
        const previousDevice = this.props.addRemoveDeviceForm.formValue
            ? this.props.addRemoveDeviceForm.formValue
            : this.props.deviceState.changedDevice.previousDevice;
        let currentDevice = this.props.appendDeviceForm.formValue
            ? this.props.appendDeviceForm.formValue
            : this.props.deviceState.changedDevice.currentDevice;

        if (!currentDevice && previousDevice.devices) {
            const nextDevice = [];
            for (const device of previousDevice.devices) {
                const meterPanel: IMeterPlace = this.props.meterPanelState.meterPanels.find(
                    item => item.ID === device.ZID
                );
                nextDevice.push({
                    WohnungNr: meterPanel.WohnungNr,
                    Gebaeude: meterPanel.Gebaeude,
                    ID: meterPanel.ID,
                    device: {
                        ZID: meterPanel.ID,
                        GeraeteID: '',
                        GrundtypId: '',
                        Einbaudatum: Moment(device.Ausbaudatum)
                            .add(1, 'd')
                            .format('YYYY-MM-DD'),
                        Eichdatum: '',
                        ZaehlwerkNKS: null,
                        ZaehlwerkVKS: null,
                        ProduktIds: [],
                    },
                    Type: 'first',
                });
            }
            currentDevice = { devices: [...nextDevice] };
        }

        this.props.changeDevice(previousDevice, currentDevice);
    };
    public closeModal = () => {
        this.selectedDevices = [];
        this.setState({ showRemoveDeviceModal: false });
        this.setState({ tableActionBarItems: [...this.buildTableActions(false)] });
    };

    public deleteDevices = async () => {
        const ids = this.selectedRowItems.map(item => item.ID);
        this.setState({ openDeleteAlert: false });
        await this.props.deleteDevices(this.props.kabID, this.props.aID, ids.join(','));
        this.selectedDevices = [];
        this.selectedRowItems = [];
        await this.props.getMeterPanels(this.props.kabID, this.props.aID);
        await this.props.getDevices(this.props.kabID, this.props.aID);
    };

    public render() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.tableActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.deviceInfo}
                        options={{
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 275px)',
                            selectionProps: rowData => ({
                                disabled: rowData.Ausbaudatum !== '-',
                                color: 'primary',
                            }),
                        }}
                        data={this.props.dataTable}
                        onSelectionChange={this.handleCheckBoxClick}
                    />
                </div>
                <SlAlertComponent
                    open={this.state.showRemoveDeviceWarning}
                    showCancelButton={false}
                    handleOK={() => this.setState({ showRemoveDeviceWarning: false })}
                >
                    <ErrorComponent error={{ message: deTranslator.error.removeDevice }} />
                </SlAlertComponent>
                <SLModal
                    title={this.orderTitle}
                    open={this.state.showOrderDeviceModal}
                    onOkButtonClick={this.doDevicesOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    okButtonText={deTranslator.global.sendOrder}
                    onClose={() => {
                        this.selectedDevices = [];
                        this.setState({
                            showOrderDeviceModal: false,
                            tableActionBarItems: [...this.buildTableActions(false)],
                        });
                    }}
                >
                    <SlAuftragComponent
                        orderType={this.orderType}
                        facilityEmail={this.props.facilityEmail}
                        title={this.orderTitle}
                    />
                </SLModal>
                <SLModal
                    title={'Rückmeldung Geräteausbau'}
                    open={this.state.showRemoveDeviceModal}
                    onOkButtonClick={() =>
                        this.removeDeviceOrder(this.props.addRemoveDeviceForm.formValue.devices)
                    }
                    OkButtonDisabled={this.props.addRemoveDeviceForm.invalid}
                    onClose={this.closeModal}
                >
                    <SlRemoveDeviceForm devices={this.selectedDevices} />
                </SLModal>
                <SlDevicesChange
                    title={'Rückmeldung Gerätewechsel'}
                    open={this.state.showChangeDeviceModal}
                    devices={this.selectedDevices}
                    deviceTypeList={this.props.deviceTypeList}
                    productList={this.props.productList}
                    meterpanels={this.getSelectedMeterPanels()}
                    removeDeviceForm={this.props.addRemoveDeviceForm}
                    appendDeviceForm={this.props.appendDeviceForm}
                    onSave={this.saveChangedDevicesConfirmation}
                    handleStepChange={this.onDeviceChange}
                    currentDevice={this.props.deviceState.changedDevice.currentDevice}
                    previousDevice={this.props.deviceState.changedDevice.previousDevice}
                    onClose={() => {
                        this.selectedDevices = [];
                        this.props.changeDevice(null, null);
                        this.setState({
                            showChangeDeviceModal: false,
                            tableActionBarItems: [...this.buildTableActions(false)],
                        });
                    }}
                />
                <SlAlertComponent
                    dialogTitle={'Geräte Löschen'}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.openDeleteAlert}
                    handleOK={this.deleteDevices}
                    handleCancel={() => this.setState({ openDeleteAlert: false })}
                >
                    {`${deleteText('die Daten')}`}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        meterPanelState: state.meterPanelState,
        deviceState: state.deviceState,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        deviceTypeList: state.settingState.deviceTypeList,
        productList: state.settingState.productManagementList,
        addRemoveDeviceForm: {
            formValue: getFormValues('SlRemoveDeviceForm')(state),
            invalid: isInvalid('SlRemoveDeviceForm')(state),
        },
        appendDeviceForm: {
            formValue: getFormValues('AppendDeviceForm')(state),
            invalid: isInvalid('AppendDeviceForm')(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, GlobalActions, DevicesActions, MeterPanelsActions, AuftragActions),
        dispatch
    );
};

export const SlDevices = connect<ISlDevicesComponent, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<ISlDevicesComponent>(MaterialUI.withStyles(styles)(SlDevicesComponent));
