import * as React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isInvalid } from 'redux-form';
import { Theme } from '@material-ui/core';
import { bindActionCreators, Dispatch } from 'redux';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../../common/components/actionbar/sl-actionbar.component';
import { IWebinar } from '../../../../common/interfaces/interface';
import { WithStyles } from '@material-ui/styles';
import { IForm } from '../../../../model/interfaces/IForm';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { IReduxState } from '../../../../model/redux-state';
import { GlobalActions } from '../../../../common/redux';
import { SettingsActions } from '../../redux';
import { SlimActions } from '../../../slim/redux';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { IDashboadNewsProps } from '../dasboardNews/dashboardNews.component';
import {
    getSlimWebinarList,
    updateSlimWebinar,
} from '../../../slim/slim-api/webinarApi/webinar.api';
import { isUserRole } from '../../../../common/utils/Functions';
import { Roles, WebinarStatusEnum } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { SLModal } from '../../../../common/components/modal';
import { SlWebinarForm } from './webinar.form';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    showAddDialog: boolean;
    selectedItem: IWebinar;
    webinarList: { items: Array<IWebinar>; nextToken: any };
    editMode: boolean;
}

export interface IWebinarComponentProps extends WithStyles<typeof styles> {
    webinarForm?: IForm;
}
class WebinarComponent extends React.Component<IWebinarComponentProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IDashboadNewsProps) {
        super(props);
        this.actionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.state.selectedItem &&
                    this.setState({ ...this.state, showAddDialog: true, editMode: true }),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            showAddDialog: false,
            selectedItem: null,
            editMode: false,
            webinarList: { items: [], nextToken: null },
        };
    }

    public fetchWebinar = async () => {
        const response = await getSlimWebinarList();
        this.setState({ ...this.state, webinarList: response });
    };

    public editWebinar = async () => {
        const { tableData, ...rest } = this.props.webinarForm.formValue;
        await updateSlimWebinar({
            ...rest,
        });

        this.setState({ ...this.state, showAddDialog: false });
    };

    public handleWebinar = async () => {
        this.setState({ ...this.state, showAddDialog: false });

        if (this.state.editMode) {
            await this.editWebinar();
            this.setState({ ...this.state, selectedItem: null, editMode: false });
        }

        this.fetchWebinar().then();
    };

    public handleTableRowClick = item => {
        this.setState({
            ...this.state,
            selectedItem: this.state.webinarList.items.find(data => data.id === item.id),
        });
    };

    public componentDidMount() {
        this.fetchWebinar().then();
    }
    private buildTableActions = () => {
        return this.state.tableActionBarItems.map(item => {
            item.disable = !this.state.selectedItem;
            return item;
        });
    };

    private buildColumns = () => {
        return [
            {
                title: 'Status',
                field: 'status',
                render: rowData => {
                    const checked = this.state.selectedItem
                        ? this.state.selectedItem.id === rowData.id
                        : false;
                    return (
                        <div style={{ display: 'flex', marginLeft: '-10px' }}>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <MaterialUI.Radio
                                    onClick={() => this.handleTableRowClick(rowData)}
                                    checked={checked}
                                    value={rowData.id}
                                />
                            )}
                            <span style={{ marginTop: 11 }}>
                                {rowData.status ? WebinarStatusEnum[rowData.status] : ''}
                            </span>
                        </div>
                    );
                },
            },
            {
                title: 'Titel',
                field: 'title',
            },
            {
                title: 'Subtitle',
                field: 'subTitle',
            },
            {
                title: 'Inhalt',
                field: 'content',
                emptyValue: '',
            },

            {
                title: 'Datum',
                field: 'publishedDate',
                emptyValue: '',
            },
            {
                title: 'Uhrzeit',
                field: 'publishedTimeFrom',
                emptyValue: '',
                render: rowData => {
                    return rowData
                        ? `${rowData.publishedTimeFrom} - ${rowData.publishedTimeTo}`
                        : '';
                },
            },
            {
                title: 'Link',
                field: 'link',
            },
        ];
    };

    public render() {
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Startseite Webinar anpassen
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div>
                <div>
                    <BasicTable
                        columns={[...this.buildColumns()]}
                        data={[...this.state.webinarList.items]}
                        options={{ paging: true, pageSize: 5 }}
                    />
                </div>
                <SLModal
                    title={'Startseite Webinar anpassen'}
                    open={this.state.showAddDialog}
                    onOkButtonClick={this.handleWebinar}
                    OkButtonDisabled={this.props.webinarForm.invalid}
                    onClose={() => {
                        this.setState({ showAddDialog: false });
                    }}
                >
                    <SlWebinarForm
                        defaultWebinar={this.state.selectedItem}
                        editMode={this.state.editMode}
                    />
                </SLModal>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        webinarForm: {
            formValue: getFormValues('webinarForm')(state),
            invalid: isInvalid('webinarForm')(state),
        },
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
    };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, GlobalActions, SettingsActions, SlimActions),
        dispatch
    );
};

export const SLWebinar = connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(MaterialUI.withStyles(styles)(WebinarComponent));
