import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import {
    zaehlerplatzByKundenanlageAID,
    zaehlerplatzByZID,
} from './graphql-operations/zaehlerplatz.queries';
import { updateZaehlerplatz } from './graphql-operations/zaehlerplatz.mutations';
import { getIdTokenForSlimApi } from '../utils';

export const getZaehlerplatzByAID = async AID => {
    const lambdaAuthToken = await getIdTokenForSlimApi();
    const response = await (API.graphql({
        query: zaehlerplatzByKundenanlageAID,
        variables: { AID: AID },
        authMode: 'AWS_LAMBDA',
        authToken: lambdaAuthToken,
    }) as Promise<{
        data: { zaehlerplatzByKundenanlageAID: any };
    }>);
    return response.data.zaehlerplatzByKundenanlageAID;
};

export const fetchZaehlerplatzByZID = async ZID => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: zaehlerplatzByZID,
            variables: { ZID: ZID },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { zaehlerplatzByZID: any };
        }>);
        return response.data.zaehlerplatzByZID.items[0];
    } catch (e) {
        console.error(e);
    }
};

export const updateZaehlerplatzById = async data => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        const result = await API.graphql({
            query: updateZaehlerplatz,
            variables: { input: { ...payload } },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        });

        return result['data'].updateZaehlerplatz;
    } catch (e) {
        console.error(e);
    }
};
