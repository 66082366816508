import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { IError } from '../../redux/reducers/global.reducer';

export interface IErrorProps {
    error: IError;
}

export class ErrorComponent extends React.Component<IErrorProps, {}> {
    public render() {
        return (
            <div>
                <MaterialUI.Grid item={true} xs={12}>
                    <div>
                        <MaterialUI.Grid container={true} direction={'row'}>
                            <MaterialUI.Grid item={true} xs={2} style={{ marginTop: '20px' }}>
                                <MaterialUIIcons.Error style={{ fontSize: 30 }} color={'error'} />
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={10} style={{ marginTop: '20px' }}>
                                <MaterialUI.Typography variant={'h5'}>
                                    {this.props.error.title
                                        ? this.props.error.title
                                        : deTranslator.error.systemError}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                            <MaterialUI.Grid item={true} xs={12} style={{ marginTop: '20px' }}>
                                {this.props.error && this.props.error.message && (
                                    <MaterialUI.Typography
                                        style={{ marginTop: '15px', color: '#000000' }}
                                        variant="h6"
                                    >
                                        {this.props.error.message}
                                    </MaterialUI.Typography>
                                )}

                                {this.props.error && this.props.error.statusCode && (
                                    <MaterialUI.Typography
                                        style={{ marginTop: '15px', color: '#000000' }}
                                        variant="h6"
                                    >
                                        {`Der Vorgang konnte aufgrund eines Fehlers (${this.props.error.statusCode}) nicht abgeschlossen werden.`}
                                    </MaterialUI.Typography>
                                )}
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>
                    </div>
                </MaterialUI.Grid>
            </div>
        );
    }
}
