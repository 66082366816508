import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { SlIBANComponent } from '../../../common/components/forms/iban-input.component';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IKAB } from '../../../common/interfaces/interface';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
    });

export interface IBankDatenFormProps extends WithStyles<typeof styles> {
    selectedCustomer: IKAB;
    initialize: (customerdata: any) => void;
}

class BankDatenForm extends React.Component<IBankDatenFormProps, {}> {
    constructor(props: IBankDatenFormProps) {
        super(props);
        this.props.initialize(this.props.selectedCustomer);
    }

    public render() {
        return (
            <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                <MaterialUI.Grid container={true} spacing={2}>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Kontoinhaber'}
                            name={'Bankdaten.Kontoinhaber'}
                            validate={[FormValidator.required]}
                            require={true}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlTextInputComponent}
                            label={'Name der Bank'}
                            name={'Bankdaten.Bankname'}
                            validate={[FormValidator.required]}
                            require={true}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={4}>
                        <Field
                            component={SlIBANComponent}
                            label={'IBAN'}
                            name={'Bankdaten.IBAN'}
                            validate={[FormValidator.required]}
                            require={true}
                            options={{ fullWidth: true, shrink: true, showInfoIcon: false }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const BankDataFormStyle = MaterialUI.withStyles(styles)(BankDatenForm);

export const BankDatenReduxForm = reduxForm({
    form: 'BankDatenForm', // a unique identifier for this form
    destroyOnUnmount: false, // dont delete the values when the form gets destroyed/tabs switch
})(BankDataFormStyle);

const mapStateToProps = (state: IReduxState) => {
    return {};
};

export const KABBankDatenForm = connect(mapStateToProps)(BankDatenReduxForm);
