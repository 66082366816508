import { saveFacilityBillingPeriods, saveSelectedFacilityBillingPeriod } from './actions/facility-billing.action';
import { getBills, getFacilityBilling, getFacilityBillingFile, updateBill } from './thunk/facility-billing.thunk';


export { facilityBillingReducer } from './reducers/facility-billing.reducer';

export const FacilityBillingsActions = Object.assign({},
    {getFacilityBilling: getFacilityBilling},
    {saveFacilityBillingPeriods: saveFacilityBillingPeriods},
    {saveSelectedFacilityBillingPeriod: saveSelectedFacilityBillingPeriod},
    {getFacilityBillingFile: getFacilityBillingFile},
    {updateBill: updateBill},
    {getBills: getBills});