import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { IProduct } from '../../../../common/interfaces/interface';
import { currencyFormatter, isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
}

export interface ICostManagementProps extends WithStyles<typeof styles> {
    productList: Array<IProduct>;
    onAddProductDialog: () => void;
    onEditProductDialog: (item) => void;
    selectedProduct: IProduct;
    onSelectedProduct: (item: IProduct) => void;
    onDeleteProductDialog: () => void;
}

class ProductManagementComponent extends React.Component<ICostManagementProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: ICostManagementProps) {
        super(props);
        this.actionBarItems = [
            {
                label: deTranslator.product.add,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.props.onAddProductDialog(),
                disable: false,
            },
            {
                label: deTranslator.product.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.props.selectedProduct &&
                    this.props.onEditProductDialog(this.props.selectedProduct),
                disable: true,
            },
            {
                label: deTranslator.product.delete,
                icon: <MaterialUIIcons.Delete color={'secondary'} />,
                action: () => this.props.selectedProduct && this.props.onDeleteProductDialog(),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
        };
    }

    public handleTableRowClick = item => {
        this.props.onSelectedProduct(item);
    };

    private buildTableActions = () => {
        const newValue = this.state.tableActionBarItems.slice(1).map(item => {
            item.disable = this.props.selectedProduct ? false : true;
            return item;
        });
        return [this.actionBarItems[0], ...newValue];
    };

    private buildColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                render: rowData => {
                    const checked = this.props.selectedProduct
                        ? this.props.selectedProduct.id === rowData.id
                        : false;
                    return (
                        <div style={{ display: 'flex', marginLeft: '-10px' }}>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <MaterialUI.Radio
                                    onClick={() => this.handleTableRowClick(rowData)}
                                    checked={checked}
                                    value={rowData.id}
                                />
                            )}
                            <span style={{ marginTop: 11 }}>{rowData.name}</span>
                        </div>
                    );
                },
            },
            {
                title: 'Preis',
                field: 'preis',
                render: rowData => currencyFormatter(rowData.preis / 100),
            },
            {
                title: 'Ebene',
                field: 'ebene',
            },
            {
                title: 'Zyklus',
                field: 'zyklus',
                emptyValue: '-',
            },
        ];
    };

    public render() {
        const productList = [...this.props.productList].map(produkt => {
            return Object.assign({}, produkt);
        });
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Produktverwaltung
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div>
                <div>
                    <BasicTable
                        columns={[...this.buildColumns()]}
                        data={productList}
                        options={{ paging: true, pageSize: 5 }}
                    />
                </div>
            </div>
        );
    }
}

export const SLProductManagement = MaterialUI.withStyles(styles)(ProductManagementComponent);
