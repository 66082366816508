import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid, isPristine } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { EinbauauftragIcon } from '../../../common/components/svgIcons/Einbauauftrag';
import { GeraetezuordnenIcon } from '../../../common/components/svgIcons/Geraetezuordnen';
import { MaloMeloErgaenzenIcon } from '../../../common/components/svgIcons/MaloMeloErgaenzen';
import {
    BasicTable,
    basicTableStyles,
    IMuiTableColumn,
} from '../../../common/components/table/mui-table.component';
import {
    IAuftrag,
    IDevice,
    IDeviceType,
    IMeterPlace,
    IProduct,
} from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { deleteText, isUserRole } from '../../../common/utils/Functions';
import { meterPanelValidation } from '../../../common/validators/facility.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

import { AuftragType, Roles, SB_GeraetTyp_Enum } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history } from '../../../store';
import { sbColors } from '../../../theme/app.colors';
import { appStyles } from '../../../theme/common.styles';
import { SlUploadFileForm } from '../../archiv/components/sl-upload-file.form';
import { AuftragActions } from '../../auftrag/redux';
import { CustomerActions } from '../../customers/redux';
import { SlAuftragComponent } from '../../devices/components/form/slAuftragComponent';
import { DevicesActions } from '../../devices/redux';
import { IDevicesState } from '../../devices/redux/reducers/devices.reducer';
import { SlAppendDeviceContainerComponent } from '../../meterpanels/components/append-devices/appendDeviceContainer.component';
import { SLMaloMeloContainerComponent } from '../../meterpanels/components/malomelo/MaloMeloContainer.component';
import { SLMeterPanelContainerComponent } from '../../meterpanels/components/meterpanel-input/meterPanelContainer.component';
import { MeterPanelsActions } from '../../meterpanels/redux';

const styles = () =>
    MaterialUI.createStyles({
        tabContainer: {
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 10,
        },
    });

interface ISlMeterPanelProps extends WithStyles<typeof styles> {
    kabID: string;
    aID: string;
    deviceState: IDevicesState;
    meterPanels: Array<IMeterPlace>;
    dataTable: Array<any>;
    productList: Array<IProduct>;
    meterPanelForm?: IForm;
    auftragForm?: IForm;
    deviceTypeList: Array<IDeviceType>;
    appendDeviceForm?: IForm;
    maloMeloForm?: IForm;
    uploadFileForm?: IForm;
    facilityEmail?: any;
    updateMeterPanels?: (kabID: string, aID: string, payload: Array<IMeterPlace>) => void;
    uploadMeterpanels?: (kabID: string, aId: string, payload: any) => void;
    getMeterPanels?: (kabID: string, aID: string) => void;
    postAuftrag?: (kabID: string, aID: string, payload: any) => { ID: string };
    postMeterPanels: (kabID: string, aID: string, meterPanels: Array<IMeterPlace>) => void;
    postDevices: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    getDevices: (kabId: string, anlageId: string) => void;
    sendEmailAuftrag?: (
        payload: IAuftrag,
        auftragId: string,
        meterplaces: Array<IMeterPlace>,
        deviceMeterpanels: Array<{ device: IDevice; meterplace: IMeterPlace }>
    ) => void;
    deleteMeterPanels?: (kabID: string, aID: string, ids: string) => void;
    deleteDevices?: (kabID: string, aID: string, ids: string) => void;
}

interface IState {
    showAddMeterPanelModal: boolean;
    meterPanelActionBarItems: Array<ISLActionBar>;
    showOrderModal: boolean;
    openAppendDeviceModal: boolean;
    openMaloMeloModal: boolean;
    openUploadFileModal: boolean;
    addMeterPanelsWarning: boolean;
    auftragWarning: boolean;
    openDeleteAlert: boolean;
}

class SlMeterPanel extends React.Component<ISlMeterPanelProps, IState> {
    private readonly tableActionBarItems: Array<ISLActionBar>;
    private readonly meterPanelTableColumn: Array<IMuiTableColumn>;
    private selectedMeterPanels: Array<IMeterPlace> = [];
    private orderType: string = '';
    private orderTitle: string = '';
    private selectedRowItems: Array<any> = [];
    private meterPanelWarning: string = '';
    private auftragWarning: string = 'Die Montageart ist nicht bei allen Zählerplätzen identisch';

    constructor(props: ISlMeterPanelProps) {
        super(props);
        this.tableActionBarItems = [
            {
                label: deTranslator.meterPanel.addMeterPanel,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.handleAddMeterPanel(),
                disable: false,
            },
            {
                label: deTranslator.meterPanel.importMeterPanel,
                icon: <MaterialUIIcons.CloudUpload color={'secondary'} />,
                action: () => this.setState({ openUploadFileModal: true }),
                disable: false,
            },
            {
                label: deTranslator.global.mountungDeviceOrder,
                action: () => this.handleMountingOrderModal(),
                icon: <EinbauauftragIcon color={sbColors.orange} />,
                disable: true,
            },

            {
                label: deTranslator.global.appendDevices,
                action: () => this.handleAppendDeviceModal(),
                icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                disable: true,
            },
            {
                label: deTranslator.global.maloMelo,
                action: () => this.handleAddMaloMelo(),
                icon: <MaloMeloErgaenzenIcon color={sbColors.orange} />,
                disable: true,
            },
            isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)
                ? {
                      label: `${deTranslator.global.delete}`,
                      action: () => this.handleDeleteMeterplaces(),
                      icon: <MaterialUIIcons.Delete />,
                      disable: true,
                  }
                : null,
        ];

        this.meterPanelTableColumn = [
            {
                title: 'Wohnungs-Nr',
                field: 'WohnungNr',
                render: rowData => {
                    return (
                        <MaterialUI.Typography
                            style={basicTableStyles.underlineText}
                            color={'secondary'}
                            onClick={() => this.goToMeterPanel(rowData)}
                        >
                            {rowData.WohnungNr}
                        </MaterialUI.Typography>
                    );
                },
            },
            { title: 'Gebäude', field: 'Gebaeude' },
            { title: 'Stockwerk', field: 'Stockwerk' },
            { title: deTranslator.meterPanel.location, field: 'Lage' },
            {
                title: 'Grundtyp',
                field: 'GeraetTyp',
                render: rowData => {
                    return rowData && rowData.GeraetTyp ? SB_GeraetTyp_Enum[rowData.GeraetTyp] : '';
                },
            },
            { title: 'Montageart', field: 'Montageart' },
        ];

        this.state = {
            showAddMeterPanelModal: false,
            openUploadFileModal: false,
            meterPanelActionBarItems: this.tableActionBarItems,
            showOrderModal: false,
            openAppendDeviceModal: false,
            openMaloMeloModal: false,
            addMeterPanelsWarning: false,
            auftragWarning: false,
            openDeleteAlert: false,
        };
    }

    private getSelectedMeterPanels = data => {
        return data.map(rowData => {
            return this.props.meterPanels.find(item => item.ID === rowData.ID);
        });
    };
    private handleCheckBoxClick = items => {
        this.selectedRowItems = [...items];
        this.setState({ meterPanelActionBarItems: this.buildTableActions(items.length > 0) });
    };

    private handleAddMeterPanel = () => {
        this.setState({ showAddMeterPanelModal: true });
    };
    private handleMountingOrderModal = () => {
        if (this.selectedRowItems.length > 0) {
            this.orderType = AuftragType.Einbauauftrag;
            this.orderTitle = deTranslator.global.mountungDeviceOrder;
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            const montageart = this.selectedMeterPanels[0].Montageart;
            if (this.selectedMeterPanels.every(item => item.Montageart === montageart)) {
                this.setState({ ...this.state, showOrderModal: true });
            } else {
                // show warning
                this.setState({ ...this.state, auftragWarning: true });
            }
        }
    };
    private handleAppendDeviceModal = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ openAppendDeviceModal: true });
        }
    };
    private handleAddMaloMelo = () => {
        if (this.selectedRowItems.length > 0) {
            this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
            this.setState({ openMaloMeloModal: true });
        }
    };
    private handleDeleteMeterplaces = () => {
        if (this.selectedRowItems.length > 0) {
            this.setState({ openDeleteAlert: true });
        }
    };

    public onAddMeterPanels = async () => {
        // check duplicate entry
        if (meterPanelValidation(this.props.meterPanelForm.formValue.Zaehlplaetze)) {
            this.meterPanelWarning = `Die Kombination aus Wohnungs-Nr., Gebäude, Stockwerk und Lage muss eindeutig sein`;
            this.setState({ addMeterPanelsWarning: true });
        } else {
            this.setState({
                showAddMeterPanelModal: false,
                meterPanelActionBarItems: this.buildTableActions(false),
            });
            await this.props.postMeterPanels(
                this.props.kabID,
                this.props.aID,
                this.props.meterPanelForm.formValue
            );
            this.selectedRowItems = [];
        }
    };

    private doMeterPanelOrder = async () => {
        const auftrag: any = Object.assign(
            {},
            {
                Type: this.orderType,
                MeterplaceIds: this.selectedMeterPanels.map(item => item.ID),
            },
            { ...this.props.auftragForm.formValue }
        );

        this.setState({
            ...this.state,
            showOrderModal: false,
            meterPanelActionBarItems: this.buildTableActions(false),
        });
        const { ID } = await this.props.postAuftrag(this.props.kabID, this.props.aID, auftrag);
        ID && this.props.sendEmailAuftrag(auftrag, ID, this.selectedMeterPanels, []);
        this.selectedRowItems = [];
    };
    private submitAppendDeviceForm = async () => {
        const payload = this.props.appendDeviceForm.formValue.devices
            .filter(res => res.device.GeraeteID !== '')
            .map(item => item.device);

        const duplicates = payload
            .map(data => data.GeraeteID)
            .filter((item, index, arr) => arr.indexOf(item) !== index);

        if (duplicates.length > 0) {
            this.meterPanelWarning = `Die Geräte IDs müssen eindeutig sein`;
            this.setState({ addMeterPanelsWarning: true });
        } else {
            this.setState({
                openAppendDeviceModal: false,
                meterPanelActionBarItems: this.buildTableActions(false),
            });

            await this.props.postDevices(this.props.kabID, this.props.aID, payload);
            await this.props.getDevices(this.props.kabID, this.props.aID);
            await this.props.getMeterPanels(this.props.kabID, this.props.aID);
            this.selectedRowItems = [];
        }
    };
    public addMaloMelo = async () => {
        this.setState({
            openMaloMeloModal: false,
            meterPanelActionBarItems: this.buildTableActions(false),
        });
        const payload: Array<IMeterPlace> = this.props.maloMeloForm.formValue.Zaehlplaetze.map(
            item => {
                const meterplace = this.props.meterPanels.find(meter => meter.ID === item.ID);
                return Object.assign({}, meterplace, { Malo: item.Malo, Melo: item.Melo });
            }
        );
        await this.props.updateMeterPanels(this.props.kabID, this.props.aID, payload);
        this.props.getMeterPanels(this.props.kabID, this.props.aID);
        this.selectedRowItems = [];
    };

    private uploadMeterPanels = () => {
        this.setState({ openUploadFileModal: false });
        const reader = new FileReader();
        reader.readAsDataURL(this.props.uploadFileForm.formValue.file);
        reader.onload = async () => {
            const archive: any = {
                Filename: this.props.uploadFileForm.formValue.Filename,
                FileContents: reader.result,
            };
            await this.props.uploadMeterpanels(this.props.kabID, this.props.aID, archive);
            await this.props.getMeterPanels(this.props.kabID, this.props.aID);
            this.selectedRowItems = [];
        };
    };

    private buildTableActions = (active: boolean) => {
        const newState = this.tableActionBarItems.slice(2).map(item => {
            item.disable = !active;
            return item;
        });
        return [this.tableActionBarItems[0], this.tableActionBarItems[1], ...newState];
    };

    private goToMeterPanel = ({ ID }) => {
        history.push(`/kabs/${this.props.kabID}/${this.props.aID}/${ID}`);
    };

    private deleteMeterPlaces = async () => {
        this.selectedMeterPanels = this.getSelectedMeterPanels(this.selectedRowItems);
        let meterPanelIDs: Array<string> = [];
        let deviceIDs: Array<string> = [];

        for (const meterPanel of this.selectedMeterPanels) {
            meterPanelIDs.push(meterPanel.ID);
            deviceIDs = [...deviceIDs, ...meterPanel.Geraete.map(item => item.ID)];
        }
        this.setState({ openDeleteAlert: false });
        // delete Zaehlerplatz
        await this.props.deleteMeterPanels(
            this.props.kabID,
            this.props.aID,
            meterPanelIDs.join(',')
        );
        // delete Geraete
        deviceIDs.length &&
            (await this.props.deleteDevices(this.props.kabID, this.props.aID, deviceIDs.join(',')));

        // get the new data
        await this.props.getDevices(this.props.kabID, this.props.aID);
        await this.props.getMeterPanels(this.props.kabID, this.props.aID);
        this.selectedRowItems = [];
    };

    public render() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.state.meterPanelActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <BasicTable
                        columns={this.meterPanelTableColumn}
                        options={{
                            paging: false,
                            pageSize: 10,
                            selection: isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser),
                            maxBodyHeight: 'calc(100vh - 275px)',
                        }}
                        data={this.props.dataTable}
                        onSelectionChange={this.handleCheckBoxClick}
                    />
                </div>
                <SLModal
                    title={'Zählerplätze'}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            showAddMeterPanelModal: false,
                            meterPanelActionBarItems: this.buildTableActions(false),
                        })
                    }
                    open={this.state.showAddMeterPanelModal}
                    onOkButtonClick={this.onAddMeterPanels}
                    OkButtonDisabled={
                        this.props.meterPanelForm.invalid || this.props.meterPanelForm.pristine
                    }
                >
                    <SLMeterPanelContainerComponent />
                </SLModal>
                {this.state.showOrderModal && (
                    <SLModal
                        title={this.orderTitle}
                        open={this.state.showOrderModal}
                        okButtonText={deTranslator.global.sendOrder}
                        onOkButtonClick={this.doMeterPanelOrder}
                        OkButtonDisabled={this.props.auftragForm.invalid}
                        onClose={() =>
                            this.setState({
                                ...this.state,
                                showOrderModal: false,
                                meterPanelActionBarItems: this.buildTableActions(false),
                            })
                        }
                    >
                        <SlAuftragComponent
                            orderType={this.orderType}
                            facilityEmail={this.props.facilityEmail}
                            auftragDateLabel={
                                AuftragType.Einbauauftrag === this.orderType ? 'Einbaudatum' : null
                            }
                        />
                    </SLModal>
                )}
                <SLModal
                    title={deTranslator.global.appendDevices}
                    fullScreen={true}
                    open={this.state.openAppendDeviceModal}
                    onOkButtonClick={this.submitAppendDeviceForm}
                    OkButtonDisabled={
                        this.props.appendDeviceForm.invalid || this.props.appendDeviceForm.pristine
                    }
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openAppendDeviceModal: false,
                            meterPanelActionBarItems: this.buildTableActions(false),
                        })
                    }
                >
                    <SlAppendDeviceContainerComponent
                        meterPanels={this.selectedMeterPanels}
                        deviceTypeList={this.props.deviceTypeList}
                        productList={this.props.productList}
                    />
                </SLModal>
                <SLModal
                    title={deTranslator.global.maloMelo}
                    onOkButtonClick={this.addMaloMelo}
                    OkButtonDisabled={this.props.maloMeloForm.invalid}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openMaloMeloModal: false,
                            meterPanelActionBarItems: this.buildTableActions(false),
                        })
                    }
                    open={this.state.openMaloMeloModal}
                >
                    <SLMaloMeloContainerComponent
                        selectedMeterPanels={this.selectedMeterPanels.map(item => ({
                            ID: item.ID,
                            WohnungNr: item.WohnungNr,
                            ZID: item.ID,
                            GeraeteID:
                                item.Geraete && item.Geraete.length > 0
                                    ? item.Geraete[0].GeraeteID
                                    : '',
                            Malo: item.Malo,
                            Melo: item.Melo,
                            GeraetTyp: item.GeraetTyp,
                            Version: item.Version,
                        }))}
                    />
                </SLModal>
                <SLModal
                    title={deTranslator.meterPanel.importMeterPanel}
                    open={this.state.openUploadFileModal}
                    fullWidth={false}
                    OkButtonDisabled={this.props.uploadFileForm.invalid}
                    okButtonText={deTranslator.archiv.fileUpload}
                    onOkButtonClick={this.uploadMeterPanels}
                    onClose={() =>
                        this.setState({
                            ...this.state,
                            openUploadFileModal: false,
                            meterPanelActionBarItems: this.buildTableActions(false),
                        })
                    }
                >
                    <SlUploadFileForm fileExtension={'.csv'} />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={'Achtung !'}
                    open={this.state.addMeterPanelsWarning}
                    showCancelButton={false}
                    handleOK={() => this.setState({ ...this.state, addMeterPanelsWarning: false })}
                >
                    {this.meterPanelWarning}
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={'Achtung !'}
                    open={this.state.auftragWarning}
                    showCancelButton={false}
                    handleOK={() => {
                        this.setState({ auftragWarning: false });
                        this.setState({ showOrderModal: true });
                    }}
                >
                    {this.auftragWarning}
                </SlAlertComponent>
                <SlAlertComponent
                    dialogTitle={`${deTranslator.meterPanel.title} ${deTranslator.global.delete}`}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.openDeleteAlert}
                    handleOK={this.deleteMeterPlaces}
                    handleCancel={() => this.setState({ openDeleteAlert: false })}
                >
                    {`${deleteText('die Daten')}`}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        deviceState: state.deviceState,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        deviceTypeList: state.settingState.deviceTypeList,
        productList: state.settingState.productManagementList,
        uploadFileForm: {
            formValue: getFormValues('SlUploadFileForm')(state),
            invalid: isInvalid('SlUploadFileForm')(state),
        },
        maloMeloForm: {
            formValue: getFormValues('MaloMeloForm')(state),
            invalid: isInvalid('MaloMeloForm')(state),
        },
        appendDeviceForm: {
            formValue: getFormValues('AppendDeviceForm')(state),
            invalid: isInvalid('AppendDeviceForm')(state),
            pristine: isPristine('AppendDeviceForm')(state),
        },

        meterPanelForm: {
            formValue: getFormValues('MeterPanelForm')(state),
            invalid: isInvalid('MeterPanelForm')(state),
            pristine: isPristine('MeterPanelForm')(state),
        },
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
    };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            GlobalActions,
            MeterPanelsActions,
            DevicesActions,
            CustomerActions,
            AuftragActions
        ),
        dispatch
    );
};

export const SlMeterPanels = connect<ISlMeterPanelProps, any, any>(
    mapStateToProps,
    mapDispatchToProps
)<ISlMeterPanelProps>(MaterialUI.withStyles(styles)(SlMeterPanel));
