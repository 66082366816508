import { WithStyles } from '@material-ui/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import * as React from 'react';
import AutoSuggest from 'react-autosuggest';
import { debounce, throttle } from 'throttle-debounce';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { sbColors } from '../../../theme/app.colors';
import { ISearchSuggestion } from '../../interfaces/interface';

const styles = MaterialUI.createStyles({
    form: {
        borderRadius: 5,
        marginRight: 0,
        width: '100%',
    },
    search: {
        height: 35,
        paddingLeft: 10,
    },
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: 4,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },

    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    input: {
        padding: '10px 12px',
    },
    title: {
        paddingLeft: 5,
        paddingTop: 5,
        color: sbColors.orange,
        textAlign: 'left',
    },
});

export interface IAutoCompleteProps extends WithStyles<typeof styles> {
    suggestions: ISearchSuggestion;
    fetchSuggestions: (event: { value: string; reason: string }) => void;
    onSuggestionSelected: (suggestion: any) => void;
    debounceTime?: number;
    throttleTime?: number;
}

interface IState {
    query: string;
}

class AutoComplete extends React.Component<IAutoCompleteProps, IState> {
    public static defaultProps: Partial<IAutoCompleteProps> = {
        suggestions: null,
        debounceTime: 500,
        throttleTime: 500,
    };

    private readonly autoCompleteSearchDebounced: any = null;
    private readonly autoCompleteSearchThrottled: any = null;

    constructor(props: IAutoCompleteProps) {
        super(props);
        this.state = { query: '' };
        this.autoCompleteSearchDebounced = debounce(500, this.autoCompleteSearch);
        this.autoCompleteSearchThrottled = throttle(500, this.autoCompleteSearch);
    }

    private autoCompleteSearch = (q: any) => {
        this.props.fetchSuggestions(q);
    };

    private getSuggestionValue = (suggestion: any) => suggestion.ID;

    private getSectionSuggestions = (section: any) => section.Results;

    private handleChange = (event: any, { newValue }) => {
        this.setState({
            query: newValue,
        });
    };
    private handleSuggestionsFetchRequested = (event: any) => {
        if (event.value.length < 5) {
            this.autoCompleteSearchThrottled(event);
        } else {
            this.autoCompleteSearchDebounced(event);
        }
    };

    private handleClearRequest = () => {};

    private handleOnSuggestionSelected = (event: any, { suggestion }) => {
        this.props.onSuggestionSelected(suggestion);
    };

    private renderInputComponent = inputProps => {
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;

        return (
            <MaterialUI.TextField
                className={classes.form}
                InputProps={{
                    disableUnderline: true,
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                    endAdornment: (
                        <MaterialUI.InputAdornment position="end" style={{ padding: 5 }}>
                            <MaterialUIIcons.Search color={'secondary'} />
                        </MaterialUI.InputAdornment>
                    ),
                }}
                {...other}
            />
        );
    };

    private renderSuggestion = (suggestion: any, { query, isHighlighted }) => {
        const matches = match(suggestion.ID, query);
        const parts =
            suggestion && suggestion.GeraeteID
                ? parse(suggestion.GeraeteID, matches)
                : parse(suggestion.ID, matches);
        let path = '';
        if (suggestion.Path) {
            const allKeys = suggestion.Path.split('#');
            allKeys.pop();
            path = allKeys.length > 0 ? `(${allKeys.join(' - ')})` : '';
        }

        return (
            <MaterialUI.MenuItem
                selected={isHighlighted}
                data-testid="autocomplete-item"
                component="div"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    {parts.map((part, index) => {
                        return part.highlight ? (
                            <span key={String(index)} style={{ fontWeight: 500 }}>
                                {part.text}
                            </span>
                        ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        );
                    })}
                </div>

                <span>{path}</span>
            </MaterialUI.MenuItem>
        );
    };

    private renderSectionTitle = (section: any) => {
        return (
            <div className={this.props.classes.title}>
                {section.Results && section.Results.length > 0 && <strong>{section.Title}</strong>}
            </div>
        );
    };

    public render() {
        const { classes } = this.props;
        let suggestions = [];

        if (this.props.suggestions) {
            suggestions = Object.keys(this.props.suggestions)
                .map((key: string) => {
                    return {
                        Title: deTranslator.global[key],
                        Results: this.props.suggestions[key],
                    };
                })
                .filter((searchResults: any) => searchResults.Results.length > 0);
        }

        return (
            <AutoSuggest
                id={'sl-autosuggest'}
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSectionTitle={this.renderSectionTitle}
                renderInputComponent={this.renderInputComponent}
                getSectionSuggestions={this.getSectionSuggestions}
                highlightFirstSuggestion={false}
                onSuggestionsClearRequested={this.handleClearRequest}
                onSuggestionSelected={this.handleOnSuggestionSelected}
                inputProps={{
                    classes,
                    placeholder: deTranslator.global.search,
                    value: this.state.query,
                    onChange: this.handleChange,
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                    <MaterialUI.Paper
                        {...options.containerProps}
                        style={{
                            backgroundColor: 'white',
                            zIndex: 3,
                            maxHeight: 300,
                            overflow: 'auto',
                        }}
                        square={true}
                    >
                        {options.children}
                    </MaterialUI.Paper>
                )}
            />
        );
    }
}

export const SlAutoComplete = MaterialUI.withStyles(styles)(AutoComplete);
