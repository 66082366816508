import { FacilityServicesActionTypes } from '../types/facility-services.action.types';


export interface IFacilityServiceState {
    facilityServices: Array<any>;
}

const initialState: IFacilityServiceState = {
    facilityServices: [],
};

export const facilityServicesReducer = (state: IFacilityServiceState = initialState, action: any): IFacilityServiceState => {

    switch (action.type) {
        case FacilityServicesActionTypes.SAVE_FACILITY_SERVICES:
            return Object.assign({}, state, {facilityServices: action.payload});
        default:
            return Object.assign({}, state);

    }


};