import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { AuftragForm } from '../../../../common/components/forms';
import { IAuftrag } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { AuftragType, Emails } from '../../../../model/enums';
import { IReduxState } from '../../../../model/redux-state';

const styles = () =>
    MaterialUI.createStyles({
        container: { marginTop: 10 },
    });

export interface IAuftragProps extends WithStyles<typeof styles> {
    orderType: string;
    title: string;
    facilityEmail: any;
    auftragDateLabel: string;
    valid?: boolean;
    loadingStatus: boolean;
    regards?: string;
    handleSubmit?: any;
    onSubmitData?: (value) => void;
    initialize?: (customerdata: any) => void;
    showDate?: boolean;
}

class AuftragContainer extends React.Component<IAuftragProps, {}> {
    public static defaultProps: Partial<IAuftragProps> = {
        showDate: true,
    };

    constructor(props: IAuftragProps) {
        super(props);

        const date = Moment(new Date()).format('DD.MM.YYYY');
        const defaultEmailText = `Bitte vereinbaren Sie einen Termin mit dem Ansprechpartner,\n\nWunschdatum der Ablesung ist am: ${date} \n\nAblesung im Auftrag des Kundenanlagenbetreibers`;

        const auftrag: IAuftrag = {
            Ausfuehrungsdatum: '',
            Email:
                this.props.orderType !== AuftragType.Ableseauftrag
                    ? this.props.facilityEmail.EmailEinbau
                    : Emails.StromluxEmail,
            Type: this.props.orderType,
            Bemerkung: this.props.orderType === AuftragType.Ableseauftrag ? defaultEmailText : '',
        };
        this.props.initialize(auftrag);
    }

    public render() {
        return (
            <div>
                <AuftragForm
                    showDate={this.props.showDate}
                    handleSubmit={this.props.handleSubmit}
                    auftragDateLabel={this.props.auftragDateLabel || deTranslator.global.wishDate}
                    valid={this.props.valid}
                    regards={this.props.regards}
                    loadingStatus={this.props.loadingStatus}
                />
            </div>
        );
    }
}

export const AuftragComponent = MaterialUI.withStyles(styles)(AuftragContainer);

const AuftragComponentReduxForm = reduxForm({
    form: 'AuftragForm', // a unique identifier for this form
})(AuftragComponent);
const selector = formValueSelector('AuftragForm');

const mapStateToProps = (state: IReduxState) => {
    const regards = selector(state, 'Bemerkung');
    return {
        regards,
    };
};

export const SlAuftragComponent = connect<IAuftragProps>(mapStateToProps)(
    AuftragComponentReduxForm
);
