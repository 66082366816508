
import { IReduxState } from '../../../../model/redux-state';
import { history } from '../../../../store/index';
import { TokenService } from '../../services/token.service';
import { LoginActionTypes } from './login.action.types';

export const loginLoadingStatus = flag => {
    return {
        type: LoginActionTypes.LOGIN_IS_LOADING,
        payload: flag
    };
};

export const loginSave = user => {
    return {
        type: LoginActionTypes.SAVE_USERNAME,
        payload: user
    };
};

export const logout = () => {
    return async (dispatch, getState: () => IReduxState) => {
        dispatch(loginSave(null));
        TokenService.signOut();
        history.push('');
    };
};




