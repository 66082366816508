import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import { listSlimNews } from './graphql-operations/slimNews.queries';
import { IDashboardNews } from '../../../../common/interfaces/interface';
import {
    createSlimNews,
    deleteSlimNews,
    updateSlimNews,
} from './graphql-operations/slimNews.mutations';

export const getSlimDashboadNews = async () => {
    const result = await (API.graphql({
        query: listSlimNews,
        authMode: 'API_KEY',
    }) as Promise<{
        data: { listSlimNews: any };
    }>);
    return result.data.listSlimNews;
};

export const addSlimDashboadNews = async (payload: any) => {
    try {
        const response = await (API.graphql({
            query: createSlimNews,
            authMode: 'API_KEY',
            variables: {
                input: {
                    ...payload,
                },
            },
        }) as Promise<{
            data: { createSlimNews: any };
        }>);
        return response.data.createSlimNews;
    } catch (e) {
        console.error(e);
    }
};
export const updateSlimDashboadNews = async (data: IDashboardNews) => {
    try {
        const payload = omitDeep(cloneDeep(data), ['__typename']);
        const result = await (API.graphql({
            query: updateSlimNews,
            authMode: 'API_KEY',
            variables: { input: { ...payload } },
        }) as Promise<{ data: { updateSlimNews: any } }>);
        return result['data'].updateSlimNews;
    } catch (e) {
        console.error(e);
    }
};

export const deleteSlimDashboadNews = (feed: IDashboardNews) => {
    try {
        return API.graphql({
            query: deleteSlimNews,
            variables: { input: { id: feed.id } },
            authMode: 'API_KEY',
        });
    } catch (e) {
        console.error(e);
    }
};
