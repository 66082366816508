import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';
import { LoadingComponent } from '../../../../common/components/buttons';
import { getSlimAuftragAsCSV } from '../../../slim/slim-api/slimAuftrag-api';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
        loadingIconContainer: {
            width: '100%',
            position: 'absolute',
            top: '0',
            right: '0',
        },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    loading: boolean;
}

export interface IGeraeteSettingProps extends WithStyles<typeof styles> {}

class SlimAuftragSettingComponent extends React.Component<any, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IGeraeteSettingProps) {
        super(props);
        this.actionBarItems = [
            {
                label: 'Slim Aufträge Herunterladen',
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.handleSlimAuftragCSVDownload(),
                disable: false,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            loading: false,
        };
    }

    public handleSlimAuftragCSVDownload = async () => {
        try {
            this.setState({ ...this.state, loading: true });
            const csvContent = await getSlimAuftragAsCSV();

            const blob = new Blob([csvContent.csv], { type: 'text/csv;charset=utf-8;' });
            const filename = `slimaufträge-${new Date().toISOString().substring(0, 10)}.csv`;
            if (navigator.msSaveBlob) {
                // In case of IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    // Browsers that support HTML5 download attribute
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
            this.setState({ ...this.state, loading: false });
        } catch (e) {
            this.setState({ ...this.state, loading: false });
        }
    };

    public render() {
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Slim Aufträge CSV
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.state.tableActionBarItems} showDivider={false} />
                    )}
                </div>

                {this.state.loading && (
                    <div className={this.props.classes.loadingIconContainer}>
                        <LoadingComponent size={70} />
                    </div>
                )}
            </div>
        );
    }
}

export const SlimAuftragSettings = MaterialUI.withStyles(styles)(SlimAuftragSettingComponent);
