// export actions and thunk operations
import axios from 'axios';
import {
    saveAnlageActiveTab,
    saveAnlageKontaktperson,
    saveSlimAnlageZaehlerplatz,
    saveSlimCustomerTab,
    saveSlimKundenAnlagen,
} from './actions/slim.actions';
import {
    ausbauSlimDevices,
    fetchSlimKAKontaktperson,
    fetchSlimKAZaehlerplatz,
    fetchSlimKundeById,
    fetchSlimKundenAnlagenByAID,
    fetchSlimSensorByKundenanlageId, getSlimCSV,
    getSlimDevices,
    getSlimKunden,
    postSlimAuftrag,
    postSlimDevices,
    postSlimSensors,
    sendEmailSlimAuftrag,
    updatedSlimSensors,
    updateSlimDevicesList,
    updateSlimKundenAnlage,
    updateSlimMeterpanel,
} from './thunk/slimThunk';

export const slimAxios = axios.create({
    baseURL: process.env.REACT_APP_Slim_API_URL,
});

export const SlimActions = Object.assign(
    {},
    { getSlimKunden: getSlimKunden },
    { saveSlimCustomerTab: saveSlimCustomerTab },
    { fetchSlimKundeById: fetchSlimKundeById },
    { saveSlimKundenAnlagen: saveSlimKundenAnlagen },
    { fetchSlimKundenAnlagenByAID: fetchSlimKundenAnlagenByAID },
    { fetchSlimKAKontaktperson: fetchSlimKAKontaktperson },
    { saveAnlageKontaktperson: saveAnlageKontaktperson },
    { saveAnlageActiveTab: saveAnlageActiveTab },
    { fetchSlimKAZaehlerplatz: fetchSlimKAZaehlerplatz },
    { saveSlimAnlageZaehlerplatz: saveSlimAnlageZaehlerplatz },
    { postSlimAuftrag: postSlimAuftrag },
    { sendEmailSlimAuftrag: sendEmailSlimAuftrag },
    { postSlimDevices: postSlimDevices },
    { getSlimDevices: getSlimDevices },
    { updateSlimMeterpanel: updateSlimMeterpanel },
    { updateSlimDevices: updateSlimDevicesList },
    { updateSlimKundenAnlage: updateSlimKundenAnlage },
    { ausbauSlimDevices: ausbauSlimDevices },
    { postSlimSensors: postSlimSensors },
    { fetchSlimSensorByKundenanlageId: fetchSlimSensorByKundenanlageId },
    { updatedSlimSensors: updatedSlimSensors },
    {getSlimCSV:getSlimCSV}
);
