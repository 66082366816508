import { FormControl, InputLabel, Select, Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { IProduct } from '../../../common/interfaces/interface';
import { currencyFormatter } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { SlimKabAnlageType } from '../interfaces/slim.interface';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
    });

export interface IAddProductProps extends WithStyles<typeof styles> {
    selectFacility: SlimKabAnlageType;
    productList: Array<IProduct>;
    onSelect: (id) => void;
}

interface IState {
    selectedProductIds: Array<string>;
}

class AddProduct extends React.Component<IAddProductProps, IState> {
    constructor(props: IAddProductProps) {
        super(props);

        this.state = {
            selectedProductIds:
                this.props.selectFacility && this.props.selectFacility.Produkts
                    ? [...this.props.selectFacility.Produkts.map(item => item.id)]
                    : [],
        };
    }
    handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            selectedProductIds: event.target.value as string[],
        });
        this.props.onSelect(event.target.value as string[]);
    };

    public render() {
        return (
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel>Produkte auswählen</InputLabel>
                    <Select
                        variant="outlined"
                        multiple={true}
                        value={this.state.selectedProductIds}
                        onChange={this.handleChange}
                        fullWidth={true}
                        input={
                            <MaterialUI.OutlinedInput
                                name={'Produkte auswählen'}
                                labelWidth={150}
                            />
                        }
                        renderValue={selected => {
                            const products = (selected as string[])
                                .map(item =>
                                    this.props.productList.find(product => product.id === item)
                                )
                                .map(tmp => tmp.name);
                            return products.join(', ');
                        }}
                        // MenuProps={MenuProps}
                    >
                        {this.props.productList
                            .filter(item => item.ebene !== 'Gerät')
                            .map((product, index) => (
                                <MaterialUI.MenuItem
                                    key={`${index}-${product.id}`}
                                    value={product.id}
                                >
                                    <MaterialUI.Checkbox
                                        checked={
                                            this.state.selectedProductIds.indexOf(product.id) > -1
                                        }
                                    />
                                    <MaterialUI.ListItemText
                                        primary={`${product.name} (${currencyFormatter(
                                            product.preis / 100
                                        )})`}
                                    />
                                </MaterialUI.MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export const AddProductForm = MaterialUI.withStyles(styles)(AddProduct);
