import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IStoerungProps {
    color: any
}

class Stoerung extends React.Component<IStoerungProps, {}> {


    public render() {
        return (
            <MaterialUI.SvgIcon style={{padding: 'inherit', paddingRight: '8px'}}>
                <path
                    d="M3.50391 6.19727C2.53906 5.91992 1.83594 5.58008 1.39453 5.17773C0.957031 4.77148 0.738281 4.27148 0.738281 3.67773C0.738281 3.00586 1.00586
           2.45117 1.54102 2.01367C2.08008 1.57227 2.7793 1.35156 3.63867 1.35156C4.22461 1.35156 4.74609 1.46484 5.20312 1.69141C5.66406 1.91797 6.01953 
           2.23047 6.26953 2.62891C6.52344 3.02734 6.65039 3.46289 6.65039 3.93555H5.51953C5.51953 3.41992 5.35547 3.01562 5.02734 2.72266C4.69922 2.42578 
           4.23633 2.27734 3.63867 2.27734C3.08398 2.27734 2.65039 2.40039 2.33789 2.64648C2.0293 2.88867 1.875 3.22656 1.875 3.66016C1.875 4.00781 2.02148 
           4.30273 2.31445 4.54492C2.61133 4.7832 3.11328 5.00195 3.82031 5.20117C4.53125 5.40039 5.08594 5.62109 5.48438 5.86328C5.88672 6.10156 6.18359 
           6.38086 6.375 6.70117C6.57031 7.02148 6.66797 7.39844 6.66797 7.83203C6.66797 8.52344 6.39844 9.07812 5.85938 9.49609C5.32031 9.91016 4.59961 
           10.1172 3.69727 10.1172C3.11133 10.1172 2.56445 10.0059 2.05664 9.7832C1.54883 9.55664 1.15625 9.24805 0.878906 8.85742C0.605469 8.4668 0.46875 
           8.02344 0.46875 7.52734H1.59961C1.59961 8.04297 1.78906 8.45117 2.16797 8.75195C2.55078 9.04883 3.06055 9.19727 3.69727 9.19727C4.29102 9.19727 
           4.74609 9.07617 5.0625 8.83398C5.37891 8.5918 5.53711 8.26172 5.53711 7.84375C5.53711 7.42578 5.39062 7.10352 5.09766 6.87695C4.80469 6.64648
            4.27344 6.41992 3.50391 6.19727ZM9.41602 2.125V3.66016H10.5996V4.49805H9.41602V8.42969C9.41602 8.68359 9.46875 8.875 9.57422 9.00391C9.67969 
            9.12891 9.85938 9.19141 10.1133 9.19141C10.2383 9.19141 10.4102 9.16797 10.6289 9.12109V10C10.3438 10.0781 10.0664 10.1172 9.79688 10.1172C9.3125 
            10.1172 8.94727 9.9707 8.70117 9.67773C8.45508 9.38477 8.33203 8.96875 8.33203 8.42969V4.49805H7.17773V3.66016H8.33203V2.125H9.41602ZM11.4668
             6.77148C11.4668 6.15039 11.5879 5.5918 11.8301 5.0957C12.0762 4.59961 12.416 4.2168 12.8496 3.94727C13.2871 3.67773 13.7852 3.54297 14.3438
              3.54297C15.207 3.54297 15.9043 3.8418 16.4355 4.43945C16.9707 5.03711 17.2383 5.83203 17.2383 6.82422V6.90039C17.2383 7.51758 17.1191 8.07227
               16.8809 8.56445C16.6465 9.05273 16.3086 9.43359 15.8672 9.70703C15.4297 9.98047 14.9258 10.1172 14.3555 10.1172C13.4961 10.1172 12.7988 
               9.81836 12.2637 9.2207C11.7324 8.62305 11.4668 7.83203 11.4668 6.84766V6.77148ZM12.5566 6.90039C12.5566 7.60352 12.7188 8.16797 13.043
                8.59375C13.3711 9.01953 13.8086 9.23242 14.3555 9.23242C14.9062 9.23242 15.3438 9.01758 15.668 8.58789C15.9922 8.1543 16.1543 7.54883 
                16.1543 6.77148C16.1543 6.07617 15.9883 5.51367 15.6562 5.08398C15.3281 4.65039 14.8906 4.43359 14.3438 4.43359C13.8086 4.43359 13.377 
                4.64648 13.0488 5.07227C12.7207 5.49805 12.5566 6.10742 12.5566 6.90039ZM12.416 1.9668C12.416 1.79102 12.4688 1.64453 12.5742 1.52734C12.6836 
                1.40625 12.8438 1.3457 13.0547 1.3457C13.2656 1.3457 13.4258 1.40625 13.5352 1.52734C13.6445 1.64453 13.6992 1.79102 13.6992 1.9668C13.6992
                 2.14258 13.6445 2.28906 13.5352 2.40625C13.4258 2.52344 13.2656 2.58203 13.0547 2.58203C12.8438 2.58203 12.6836 2.52344 12.5742 2.40625C12.4688
                  2.28906 12.416 2.14258 12.416 1.9668ZM14.9355 1.97852C14.9355 1.80273 14.9883 1.6543 15.0938 1.5332C15.2031 1.41211 15.3633 1.35156 15.5742
                   1.35156C15.7852 1.35156 15.9453 1.41211 16.0547 1.5332C16.1641 1.6543 16.2188 1.80273 16.2188 1.97852C16.2188 2.1543 16.1641 2.30078 16.0547 
                   2.41797C15.9453 2.53516 15.7852 2.59375 15.5742 2.59375C15.3633 2.59375 15.2031 2.53516 15.0938 2.41797C14.9883 2.30078 14.9355 2.1543 
                   14.9355 1.97852ZM21.6621 4.63281C21.498 4.60547 21.3203 4.5918 21.1289 4.5918C20.418 4.5918 19.9355 4.89453 19.6816 
                   5.5V10H18.5977V3.66016H19.6523L19.6699 4.39258C20.0254 3.82617 20.5293 3.54297 21.1816 3.54297C21.3926 3.54297 21.5527 
                   3.57031 21.6621 3.625V4.63281ZM26.5781 9.37305C26.1562 9.86914 25.5371 10.1172 24.7207 10.1172C24.0449 10.1172 23.5293 
                   9.92188 23.1738 9.53125C22.8223 9.13672 22.6445 8.55469 22.6406 7.78516V3.66016H23.7246V7.75586C23.7246 8.7168 24.1152 
                   9.19727 24.8965 9.19727C25.7246 9.19727 26.2754 8.88867 26.5488 8.27148V3.66016H27.6328V10H26.6016L26.5781 9.37305ZM30.3105 
                   3.66016L30.3457 4.45703C30.8301 3.84766 31.4629 3.54297 32.2441 3.54297C33.584 3.54297 34.2598 4.29883 34.2715 5.81055V10H33.1875V5.80469C33.1836 
                   5.34766 33.0781 5.00977 32.8711 4.79102C32.668 4.57227 32.3496 4.46289 31.916 4.46289C31.5645 4.46289 31.2559 4.55664 30.9902 4.74414C30.7246
                    4.93164 30.5176 5.17773 30.3691 5.48242V10H29.2852V3.66016H30.3105ZM35.6484 6.77734C35.6484 5.78906 35.877 5.00391 36.334 4.42188C36.791 
                    3.83594 37.3965 3.54297 38.1504 3.54297C38.9238 3.54297 39.5273 3.81641 39.9609 4.36328L40.0137 3.66016H41.0039V9.84766C41.0039 10.668 
                    40.7598 11.3145 40.2715 11.7871C39.7871 12.2598 39.1348 12.4961 38.3145 12.4961C37.8574 12.4961 37.4102 12.3984 36.9727 12.2031C36.5352 
                    12.0078 36.2012 11.7402 35.9707 11.4004L36.5332 10.75C36.998 11.3242 37.5664 11.6113 38.2383 11.6113C38.7656 11.6113 39.1758 11.4629 39.4688 
                    11.166C39.7656 10.8691 39.9141 10.4512 39.9141 9.91211V9.36719C39.4805 9.86719 38.8887 10.1172 38.1387 10.1172C37.3965 10.1172 36.7949 9.81836
                     36.334 9.2207C35.877 8.62305 35.6484 7.80859 35.6484 6.77734ZM36.7383 6.90039C36.7383 7.61523 36.8848 8.17773 37.1777 8.58789C37.4707 8.99414 
                     37.8809 9.19727 38.4082 9.19727C39.0918 9.19727 39.5938 8.88672 39.9141 8.26562V5.37109C39.582 4.76562 39.084 4.46289 38.4199 4.46289C37.8926 
                     4.46289 37.4805 4.66797 37.1836 5.07812C36.8867 5.48828 36.7383 6.0957 36.7383 6.90039Z"
                    transform="translate(31 8)"
                    fill={this.props.color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9043 17.2715L15.6201 18.874C15.7266 18.958 15.8604 19 16 19C16.1396 19 16.2734 18.958 16.3799 18.874L21.6465 15.7661C21.8604 15.647 22
          15.416 22 15.1499V8.8501C22 8.58398 21.8604 8.35303 21.6465 8.23389L19.333 6.86865L17.3125 8.7832C17.6982 8.95703 18.0547 9.20703 18.3574
           9.5249C18.9824 10.1816 19.333 11.0718 19.333 12C19.333 12.9282 18.9824 13.8184 18.3574 14.4751C17.7314 15.1313 16.8838 15.5 16 15.5C15.6465 15.5 15.2979 15.4409 14.9678 15.3281L12.9043 17.2715ZM11.207 1.08643L5.50098 12.5L7.5 13.5L6.33398 17H3V9H0L10 0L11.207 1.08643Z"
                    transform="translate(0 3)"
                    fill={this.props.color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.520844 9.87842e-05L0 16.1329H2.21576V26.0317L7.38586 12.8334H4.43151L7.86728 2.55405L0.520844 9.87842e-05Z"
                    transform="translate(15.0312 0.272949) rotate(25.8335)"
                    fill={this.props.color}
                />
            </MaterialUI.SvgIcon>
        );
    }


}

export const StoerungIcon = (Stoerung);
