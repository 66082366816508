import { ArchiveActionTypes } from '../types/archive.types';


export interface IArchiveState {
    archiveFiles: Array<any>;
    archiveLoading: boolean;
    downloadedArchive: string;

}

const initialState: IArchiveState = {
    archiveFiles: [],
    archiveLoading: false,
    downloadedArchive: null
};

export const archiveReducer = (state: IArchiveState = initialState, action: any): IArchiveState => {

    switch (action.type) {
        case ArchiveActionTypes.SAVE_ARCHIVES:
            return Object.assign({}, state, {archiveFiles: [...action.payload]});
        case ArchiveActionTypes.FETCH_ARCHIVES_LOADING:
            return Object.assign({}, state, {archiveLoading: action.payload});
        case ArchiveActionTypes.DOWNLOAD_ARCHIVE:
            return Object.assign({}, state, {downloadedArchive: action.payload});
        default:
            return Object.assign({}, state);

    }


};