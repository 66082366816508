import axios from 'axios';
import { IContactData } from '../../../../common/interfaces/interface';
import {
    addLoadingState,
    removeLoadingState,
    setError,
} from '../../../../common/redux/actions/global.action';
import { sanitizerContact } from '../../../../common/utils/Functions';

import { contactPersonLoadingStatus, saveContactPersons } from '../actions/contactperson.action';
import { ContactPersonActionTypes } from '../types/contactperson.action.types';

export const postContactPerson = (kabID, aID, payload) => {
    return async dispatch => {
        const contactPerson: IContactData = sanitizerContact(payload);
        dispatch(addLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
        try {
            await axios.post(`kabs/${kabID}/anlagen/${aID}/contactpersons`, contactPerson);
        } catch (error) {
            dispatch(contactPersonLoadingStatus(false));
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
            dispatch(setError(error));
        }
    };
};

export const updateContactPerson = (kabID, aID, payload) => {
    return async dispatch => {
        const contactPerson: IContactData = sanitizerContact(payload);
        dispatch(addLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
        try {
            await axios.put(
                `kabs/${kabID}/anlagen/${aID}/contactpersons/${contactPerson.ID}`,
                contactPerson
            );
        } catch (error) {
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
            dispatch(setError(error));
        }
    };
};

export const deleteContactPerson = (kabID: string, aID: string, ids: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
            await axios.delete(`kabs/${kabID}/anlagen/${aID}/contactpersons/?ids=${ids}`);
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
        } catch (error) {
            dispatch(contactPersonLoadingStatus(false));
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
            dispatch(setError(error));
        }
    };
};

export const getContactPersons = (kabID: string, aID: string) => {
    return async dispatch => {
        try {
            dispatch(addLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
            const response = await axios.get(`kabs/${kabID}/anlagen/${aID}/contactpersons`);
            dispatch(saveContactPersons([...response.data]));
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
        } catch (error) {
            dispatch(saveContactPersons([]));
            dispatch(removeLoadingState(ContactPersonActionTypes.CONTACTPERSON_IS_LOADING));
        }
    };
};
