import { API } from 'aws-amplify';
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';
import {
    createGeraet,
    createMetergridZaehler,
    updateGeraet,
} from './graphql-operations/geraete.mutation';
import {
    geraeteByKundenanlageAID,
    geraeteByZID,
    getGeraet,
} from './graphql-operations/geraete.queries';
import { getIdTokenForSlimApi } from '../utils';

export const createSlimDevice = async device => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: createGeraet,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    ...device,
                },
            },
        }) as Promise<{ data: { createGeraet: any } }>);

        if (response.data && response.data.createGeraet) {
            return response.data.createGeraet;
        }
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};

export const createMGZaehler = async data => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const result = (await API.graphql({
            query: createMetergridZaehler,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: { input: { ...data } },
        })) as any;
        return result.data['createMetergridZaehler'];
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const updateSlimDevice = async device => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const payload = omitDeep(cloneDeep(device), ['__typename']);
        const result = (await API.graphql({
            query: updateGeraet,
            variables: { input: { ...payload } },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        })) as any;
        return result.data['updateGeraet'];
    } catch (e) {}
};

export const fetchGeraeteById = async (id, ZID) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const filter = {
            ZID: {
                eq: ZID,
            },
        };
        const response = await (API.graphql({
            query: getGeraet,
            variables: { id: id, filter: filter },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { getGeraet: any };
        }>);
        return response.data.getGeraet;
    } catch (e) {
        console.error(e);
    }
};
export const fetchGeraeteByZID = async ZID => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: geraeteByZID,
            variables: { ZID: ZID },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { geraeteByZID: any };
        }>);
        return response.data.geraeteByZID;
    } catch (e) {
        console.error(e);
    }
};

export const fetchGeraeteByAID = async (AID, filter) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: geraeteByKundenanlageAID,
            variables: { AID: AID, filter: filter },
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { geraeteByKundenanlageAID: any };
        }>);
        return response.data.geraeteByKundenanlageAID;
    } catch (e) {
        console.error(e);
    }
};
