import { getIdTokenForSlimApi } from '../utils';
import { API } from 'aws-amplify';
import { downloadSlimAuftrag } from './grahql/queries';

export const getSlimAuftragAsCSV = async () => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: downloadSlimAuftrag,
            variables: {},
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { downloadSlimAuftrag: any };
        }>);

        return response.data.downloadSlimAuftrag;
    } catch (e) {
        throw e;
    }
};
