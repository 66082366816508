import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, initialize, isInvalid } from 'redux-form';
import uuid from 'uuid';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    ISLActionBar,
    SlActionBar,
} from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { ISlTab } from '../../../common/components/tabs/sl-tabs.component';
import {
    IBillRecipient,
    IContactData,
    IFacility,
    IKAB,
    IProduct,
} from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../model/enums';

import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { history, store } from '../../../store';
import { appStyles } from '../../../theme/common.styles';
import { DevicesActions } from '../../devices/redux';
import { SlFacilityCard } from '../../facilities/components/sl-facility-card.component';
import { SlFacilityContacts } from '../../facilities/components/sl-facility-contacts.component';
import { SLFacility } from '../../facilities/forms/sl-facility.form';
import { ContactPersonActions, FacilityActions } from '../../facilities/redux';
import { IFacilityState } from '../../facilities/redux/reducers/facility.reducer';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { SettingsActions } from '../../settings/redux';
import { SlKabBankDatenComponentDetails } from '../components/kabBankDaten.component';
import { SlKabZusatzinfoComponentDetails } from '../components/kabZustazInfo';
import { SLBillReceiverForm } from '../components/sl-bill-receiver.form';
import { SLBusinessPartnerForm } from '../components/sl-businessPartner.form';
import { SlCustomerDataDetails } from '../components/sl-customer-data-details.component';
import { SlDeviatingBillRecipientDetails } from '../components/sl-deviating-billRecipient-details.component';
import { KABBankDatenForm } from '../form/bankdata.form';
import { KABZusatzInfoForm } from '../form/zusatzinfo.form';
import { CustomerActions } from '../redux';
import { ICustomerState } from '../redux/reducers/customer.reducer';
import axios from 'axios';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        tabContainer: {
            height: '100vh',
            // backgroundColor: sbColors.white,
        },
        tabContent: {
            overflow: 'auto',
            height: 'calc(100vh - 265px)',
            margin: 0,
        },
        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },
    });

export interface IKabCustomerDataPageProps extends WithStyles<typeof styles> {
    customerState: ICustomerState;
    facilityState: IFacilityState;
    match: any;
    location: any;
    loadingList: Array<string>;
    getCustomerById: (kabID: string) => void;
    saveSelectedCustomer: (customer: IKAB) => void;
    deleteContactData: (kabID: string, contactPersonID: string) => void;
    setError: (error: IError) => void;
    error: IError;
    getBillReceiver: (kabID: string) => void;
    getContactData: (kabID: string) => void;
    displayContactPersonEditModal: (isOpen: boolean) => void;
    displayDeviatingBillRecipientEditModal: (isOpen: boolean) => void;
    displayKABEditModal: (isOpen: boolean) => void;
    updateCustomer: (payload: IKAB) => void;
    updateBillReceiver?: (kabID: string, value) => void;
    postBillReceiver: (payload: IBillRecipient) => void;
    postContactData: (value, isCreatedFromEditPage?: boolean) => void;
    postFacility: (kabID: string, payload: IFacility) => void;
    deleteFacilityById: (kabID: string, aID: string) => void;
    updateContactData: (value) => void;
    getFacilities: (kabId: string) => void;
    saveSelectedFacility: (facility: IFacility) => void;
    getMeterPanels: (kabID: string, aID: string) => void;
    getContactPersons: (kabID: string, aID: string) => void;
    facilityForm: IForm;
    customerDataForm: IForm;
    billReceiverDataForm: IForm;
    contactPersonForm: IForm;
    kabZusatzInfoForm: IForm;
    bankDatenFormForm: IForm;
    fetchCustomerData: (kabID: string) => void;
    getProductList?: () => void;
    productList: Array<IProduct>;
    saveSelectedKABContactPerson: (contactPerson: IContactData) => void;
    saveCustomerActiveTab: (tab: number) => void;
    getFacilityById: (kabID: string, aID: string) => void;
    deleteMeterPanels?: (kabID: string, aID: string, ids: string) => void;
    deleteDevices?: (kabID: string, aID: string, ids: string) => void;
    deleteContactPerson?: (kabID: string, aID: string, ids: string) => void;
}

interface IState {
    cardElevation: number;
    openFacilityModal: boolean;
    facilityValid: boolean;
    openBusinessModal: boolean;
    openBillModal: boolean;
    openContactPersonModal: boolean;
    openFacilityAlert: boolean;
    openMoreInfoModal: boolean;
    openBankdatenModal: boolean;
}

class KabCustomerData extends React.Component<IKabCustomerDataPageProps, IState> {
    private readonly tabs: Array<ISlTab>;
    private kabID: string = null;
    private readonly facilityActionBarItems: Array<ISLActionBar>;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;

    constructor(props: IKabCustomerDataPageProps) {
        super(props);
        this.kabID = props.match.params.kabID;

        this.tabs = [
            {
                label: deTranslator.user.masterData,
            },
            {
                label: deTranslator.global.contact,
            },
            {
                label: deTranslator.facility.facilities,
            },
        ];
        this.facilityActionBarItems = [
            {
                label: deTranslator.facility.new,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.showAddFacilityForm(),
            },
        ];
        this.masterDataActionBarItems = [
            {
                label: `${deTranslator.user.businessPartner} bearbeiten`,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ ...this.state, openBusinessModal: true }),
            },
            {
                label: `Rechnungsempfänger bearbeiten`,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ ...this.state, openBillModal: true }),
            },
            {
                label: `Weitere Informationen bearbeiten`,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ ...this.state, openMoreInfoModal: true }),
            },
            {
                label: `Bankdaten bearbeiten `,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ ...this.state, openBankdatenModal: true }),
            },
        ];

        this.state = {
            openContactPersonModal: false,
            openBillModal: false,
            openBusinessModal: false,
            cardElevation: 1,
            openFacilityModal: false,
            facilityValid: false,
            openFacilityAlert: false,
            openMoreInfoModal: false,
            openBankdatenModal: false,
        };
    }

    public componentDidMount() {
        // fetch bodyData from server
        this.props.fetchCustomerData(this.kabID);
        this.props.getProductList();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.match.params.kabID !== prevProps.match.params.kabID) {
            this.kabID = this.props.match.params.kabID;
            this.props.fetchCustomerData(this.kabID);
        }
    }

    public showAddFacilityForm = () => {
        if (this.props.productList.length !== 0) {
            this.setState({ ...this.state, openFacilityModal: true });
        } else {
            this.setState({ ...this.state, openFacilityAlert: true });
        }
    };
    private handleDissmissError = () => {
        this.props.setError(null);
    };

    private buildTabs = () => {
        if (this.props.facilityState.facilities) {
            return this.tabs.map((tab: ISlTab) => {
                switch (tab.label) {
                    case deTranslator.facility.facilities:
                        tab.badge = this.props.facilityState.facilities.length;
                        return tab;
                    case deTranslator.global.contact:
                        tab.badge = this.props.customerState.kabContactData.length;
                        return tab;
                    default:
                        return tab;
                }
            });
        }
        return this.tabs;
    };

    private handleOnFacilityCardClick = (facility: IFacility) => {
        this.props.saveSelectedFacility(facility);
        this.props.getMeterPanels(this.kabID, facility.ID);
        this.props.getContactPersons(this.kabID, facility.ID);
        history.push('/kabs/' + this.props.match.params.kabID + '/' + facility.ID);
    };
    public handleAddFacility = async () => {
        this.setState({ ...this.state, openFacilityModal: false });
        const anlage = this.props.facilityForm.formValue;
        const createdAnlageId = await this.props.postFacility(
            this.kabID,
            this.props.facilityForm.formValue
        );
        // create metergrid project
        if (anlage.isMetergridProject) {
            await axios.post(`kabs/${this.kabID}/anlagen/${createdAnlageId['ID']}/metergrid`);
        }

        this.props.getFacilities(this.kabID);
    };
    private handleFacilityCardClickDelete = async (facility: IFacility) => {
        // delete facility
        await this.props.deleteFacilityById(facility.KID, facility.ID);
        this.props.getFacilities(facility.KID);
    };
    public updateBusinessPartner = async () => {
        this.setState({ ...this.state, openBusinessModal: false });
        await this.props.updateCustomer(this.props.customerDataForm.formValue);
        this.props.getCustomerById(this.kabID);
        store.dispatch(initialize('BusinessPartnerForm', null)); // reset the redux form
    };

    public updateMoreInfo = async () => {
        this.setState({ ...this.state, openMoreInfoModal: false });
        await this.props.updateCustomer(this.props.kabZusatzInfoForm.formValue);
        this.props.getCustomerById(this.kabID);
        store.dispatch(initialize('KabZusatzInfoForm', null)); // reset the redux form
    };
    public updateBankData = async () => {
        this.setState({ ...this.state, openBankdatenModal: false });
        await this.props.updateCustomer(this.props.bankDatenFormForm.formValue);
        this.props.getCustomerById(this.kabID);
        store.dispatch(initialize('BankDatenForm', null)); // reset the redux form
    };

    public closeBusinessModal = () => {
        store.dispatch(initialize('BusinessPartnerForm', null)); // reset the redux form
        this.setState({ ...this.state, openBusinessModal: false });
    };

    public updateBillReceiver = async () => {
        this.setState({ ...this.state, openBillModal: false });
        if (!this.props.customerState.selectedDeviatinBillRecipient) {
            await this.props.postBillReceiver(this.props.billReceiverDataForm.formValue);
        } else {
            await this.props.updateBillReceiver(
                this.kabID,
                this.props.billReceiverDataForm.formValue
            );
        }
        this.props.getBillReceiver(this.kabID);
        store.dispatch(initialize('BillReceiver', null)); // reset the redux form
    };

    public addContactPerson = async () => {
        await this.props.postContactData(this.props.contactPersonForm.formValue);
        this.props.getContactData(this.kabID);
        this.props.saveSelectedKABContactPerson(null);
        store.dispatch(initialize('BillReceiver', null)); // reset the redux form
    };
    public handleEditContact = async () => {
        await this.props.updateContactData(this.props.contactPersonForm.formValue);
        this.props.getContactData(this.kabID);
        this.props.saveSelectedKABContactPerson(null);
        store.dispatch(initialize('BillReceiver', null)); // reset the redux form
    };

    private handleDeleteContact = async (contactPerson: IContactData) => {
        await this.props.deleteContactData(this.kabID, contactPerson.ID);
        this.props.getContactData(this.kabID);
    };

    public renderContactPerson() {
        const tableColumns = [
            { title: 'Anrede', field: 'Anrede' },
            { title: 'Titel', field: 'Titel' },
            { title: 'Vorname', field: 'Vorname' },
            { title: 'Nachname', field: 'Nachname' },
            { title: 'Firmenname', field: 'Firmenname1' },
            { title: 'Telefonnummer', field: 'Telefon' },
            { title: 'E-Mail', field: 'Email' },
            { title: 'Zusatzinformationen', field: 'Zusatzinformationen' },
        ];
        return (
            <div>
                <div className={this.props.classes.tabContainer}>
                    <SlFacilityContacts
                        contactPersons={this.props.customerState.kabContactData}
                        showRole={false}
                        tableColumns={tableColumns}
                        addContact={this.addContactPerson}
                        editContact={this.handleEditContact}
                        contactPersonForm={this.props.contactPersonForm}
                        deleteContact={this.handleDeleteContact}
                    />
                </div>
            </div>
        );
    }

    private renderModal = () => {
        return (
            <div>
                <SLModal
                    title={deTranslator.user.businessPartner}
                    onClose={this.closeBusinessModal}
                    hideClose={true}
                    open={this.state.openBusinessModal}
                    onOkButtonClick={this.updateBusinessPartner}
                    OkButtonDisabled={this.props.customerDataForm.invalid}
                >
                    <SLBusinessPartnerForm
                        showTaxField={true}
                        showSave={false}
                        editMode={true}
                        customerState={this.props.customerState}
                    />
                </SLModal>

                <SLModal
                    title={deTranslator.user.billReceiver}
                    onClose={() => this.setState({ ...this.state, openBillModal: false })}
                    hideClose={true}
                    open={this.state.openBillModal}
                    onOkButtonClick={this.updateBillReceiver}
                    OkButtonDisabled={this.props.billReceiverDataForm.invalid}
                >
                    <SLBillReceiverForm
                        showTaxField={false}
                        showSave={false}
                        editMode={true}
                        customerState={this.props.customerState}
                    />
                </SLModal>
                <SLModal
                    title={'Weitere Informationen'}
                    onClose={() => this.setState({ ...this.state, openMoreInfoModal: false })}
                    hideClose={true}
                    open={this.state.openMoreInfoModal}
                    onOkButtonClick={this.updateMoreInfo}
                    OkButtonDisabled={false}
                >
                    <KABZusatzInfoForm
                        selectedCustomer={this.props.customerState.selectedCustomer}
                    />
                </SLModal>
                <SLModal
                    title={'Bankdaten'}
                    onClose={() => this.setState({ ...this.state, openBankdatenModal: false })}
                    hideClose={true}
                    open={this.state.openBankdatenModal}
                    onOkButtonClick={this.updateBankData}
                    OkButtonDisabled={this.props.bankDatenFormForm.invalid}
                >
                    <KABBankDatenForm
                        selectedCustomer={this.props.customerState.selectedCustomer}
                    />
                </SLModal>
            </div>
        );
    };

    public renderMasterData() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.masterDataActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <MaterialUI.Grid
                        container={true}
                        wrap={'nowrap'}
                        direction={'column'}
                        className={this.props.classes.tabContent}
                    >
                        <MaterialUI.Grid item={true} style={{ padding: 0 }}>
                            {this.props.customerState.selectedCustomer && (
                                <SlCustomerDataDetails
                                    customerdata={this.props.customerState.selectedCustomer}
                                />
                            )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {this.props.customerState.selectedDeviatinBillRecipient && (
                                <SlDeviatingBillRecipientDetails
                                    deviatingBillRecipient={
                                        this.props.customerState.selectedDeviatinBillRecipient
                                    }
                                />
                            )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {this.props.customerState.selectedCustomer &&
                                this.props.customerState.selectedCustomer.Zusatzinfo && (
                                    <SlKabZusatzinfoComponentDetails
                                        selectedCustomer={this.props.customerState.selectedCustomer}
                                    />
                                )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {this.props.customerState.selectedCustomer &&
                                this.props.customerState.selectedCustomer.Bankdaten && (
                                    <SlKabBankDatenComponentDetails
                                        selectedCustomer={this.props.customerState.selectedCustomer}
                                    />
                                )}
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </div>
            </div>
        );
    }

    public renderFacilities() {
        return (
            <div>
                {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                    <SlActionBar items={this.facilityActionBarItems} />
                )}
                <div className={this.props.classes.tabContainer}>
                    <MaterialUI.Grid
                        container={true}
                        spacing={10}
                        className={this.props.classes.tabContent}
                    >
                        {this.props.facilityState.facilities.map(facility => (
                            <MaterialUI.Grid item={true} key={uuid.v4()}>
                                <SlFacilityCard
                                    deleteFacility={this.handleFacilityCardClickDelete}
                                    onSelected={this.handleOnFacilityCardClick}
                                    facility={facility}
                                />
                            </MaterialUI.Grid>
                        ))}
                    </MaterialUI.Grid>
                </div>

                <SLModal
                    title={deTranslator.facility.add}
                    onClose={() => this.setState({ ...this.state, openFacilityModal: false })}
                    OkButtonDisabled={this.props.facilityForm.invalid}
                    open={this.state.openFacilityModal}
                    okButtonText={deTranslator.facility.add}
                    onOkButtonClick={this.handleAddFacility}
                >
                    <SLFacility productList={this.props.productList} />
                </SLModal>
            </div>
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader
                    currentLocation={this.props.location}
                    selectedTab={this.props.customerState.customerActiveTab}
                    tabs={this.buildTabs()}
                    onTabClick={this.props.saveCustomerActiveTab}
                />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.tabs[this.props.customerState.customerActiveTab].label ===
                        this.tabs[0].label && this.renderMasterData()}
                    {this.tabs[this.props.customerState.customerActiveTab].label ===
                        this.tabs[1].label && this.renderContactPerson()}
                    {this.tabs[this.props.customerState.customerActiveTab].label ===
                        this.tabs[2].label && this.renderFacilities()}
                </div>

                {this.renderModal()}

                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDissmissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
                <SlAlertComponent
                    dialogTitle={'Keine Produkte Vorhanden'}
                    open={this.state.openFacilityAlert}
                    showCancelButton={false}
                    handleOK={() => this.setState({ openFacilityAlert: false })}
                >
                    {`Unter Einstellungen können Sie neue Produkte hinzufügen`}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        facilityForm: {
            formValue: getFormValues('SLFacilityForm')(state),
            invalid: isInvalid('SLFacilityForm')(state),
        },
        customerDataForm: {
            formValue: getFormValues('BusinessPartnerForm')(state),
            invalid: isInvalid('BusinessPartnerForm')(state),
        },
        billReceiverDataForm: {
            formValue: getFormValues('BillReceiver')(state),
            invalid: isInvalid('BillReceiver')(state),
        },
        contactPersonForm: {
            formValue: getFormValues('contactPersonForm')(state),
            invalid: isInvalid('contactPersonForm')(state),
        },
        kabZusatzInfoForm: { formValue: getFormValues('KabZusatzInfoForm')(state) },
        bankDatenFormForm: {
            formValue: getFormValues('BankDatenForm')(state),
            invalid: isInvalid('BankDatenForm')(state),
        },
        customerState: state.customerState,
        facilityState: state.facilityState,
        error: state.globalState.error,
        productList: state.settingState.productManagementList,
        loadingList: state.globalState.loadingList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign(
            {},
            CustomerActions,
            GlobalActions,
            FacilityActions,
            MeterPanelsActions,
            DevicesActions,
            SettingsActions,
            ContactPersonActions
        ),
        dispatch
    );
};

export const KabCustomerDataComponent = MaterialUI.withStyles(styles)(KabCustomerData);
export const CustomerDataPage = withRouter(
    connect<IKabCustomerDataPageProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<IKabCustomerDataPageProps>(KabCustomerDataComponent)
);
