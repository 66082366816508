import { withStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { TooltipButtonComponent } from '../buttons';

const styles = {
    selectField: {
        // backgroundColor: 'red',
        height: 18,
    }
};

export const renderItemForSelectBox = (enums: any) => {
    return Object.keys(enums)
        .map((value, index) => <MaterialUI.MenuItem
            key={`${index}-${value}`}
            value={enums[value]}>
            {enums[value]}
        </MaterialUI.MenuItem>);
};


export const renderOptionsForSelectBox = (enums: any) => {
    return Object.keys(enums)
        .map((value, index) => <MaterialUI.MenuItem
            key={`${index}-${value}`}
            value={value}>
            {enums[value]}
        </MaterialUI.MenuItem>);
};


const CustomizedSelectInput = (inputProps: any) => {
    const {
        input,
        label,
        required,
        options,
        children,
        style,
        disabled
    } = inputProps;

    const disableStyle = disabled ? {backgroundColor: '#E2E2E2'} : {backgroundColor: '#fff'};
    const inputStyle = Object.assign({}, style, disableStyle);

    return (

        <MaterialUI.TextField
            label={label}
            variant={'outlined'}
            select={true}
            style={{...inputStyle}}
            {...input}
            disabled={disabled ? disabled : false}
            InputProps={{
                classes: {
                    input: inputProps.classes.selectField,
                },
                multiple: true,
                endAdornment: options && options.showInfoIcon && <TooltipButtonComponent text={options.infoText}/>

            }}
            SelectProps={{
                classes: {
                    root: inputProps.classes.selectField,
                }

            }}

            InputLabelProps={{
                shrink: options && options.shrink ? options.shrink : true,
            }}
            fullWidth={options && options.fullWidth}
            required={required}
        >
            <MaterialUI.MenuItem disabled={true} value={''}>
                Bitte Auswählen
            </MaterialUI.MenuItem>
            {children}
        </MaterialUI.TextField>


    );
};


const CustomizedMultipleSelectInput = (inputProps: any) => {
    const {
        input,
        label,
        required,
        options,
        children,
        style,
        disabled
    } = inputProps;

    const disableStyle = disabled ? {backgroundColor: '#E2E2E2'} : {backgroundColor: '#fff'};
    const inputStyle = Object.assign({}, style, disableStyle);

    return (
        <MaterialUI.FormControl
            fullWidth={options && options.fullWidth}
            variant="outlined"
            style={{...inputStyle}}
            required={required}>
            <MaterialUI.InputLabel style={{backgroundColor: 'white'}} shrink={true}>{label}</MaterialUI.InputLabel>
            <MaterialUI.Select
                multiple={true}
                input={
                    <MaterialUI.OutlinedInput
                        labelWidth={170}
                        name={label}
                    />
                }
                {...input}
                disabled={disabled ? disabled : false}
                renderValue={(selected: any) => selected.length > 1 ? `${selected.length} Produkte` : `${selected.length} Produkt`

                }
            >
                {children}
            </MaterialUI.Select>
        </MaterialUI.FormControl>


    );
};


export const SlSelectInputComponent = withStyles(styles)(CustomizedSelectInput);
export const SlMultipleSelectInputComponent = withStyles(styles)(CustomizedMultipleSelectInput);
