import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { IFacility } from '../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../common/utils';
import { currencyFormatter } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { PaymentPlan } from '../../../model/enums';

import { titleStyles } from '../../../theme/common.styles';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        mediumInput: {
            width: '70%',
        },
        smallInput: {
            width: '30%',
        },
        date: {
            width: '30%',
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            overflow: 'hidden',
        },
        tinyInput: {
            width: '15%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
        responsiveContent: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                margin: 'auto',
                width: '70%',
            },
            [theme.breakpoints.up('lg')]: {
                margin: 'auto',
                width: '70%',
            },
        },
    });

export interface IFacilityDataDetailsProps extends WithStyles<typeof styles> {
    facility: IFacility;
}

class FacilityDataDetailsComponent extends React.Component<IFacilityDataDetailsProps> {
    private renderProducts = () => {
        if (this.props.facility.Produkte && this.props.facility.Produkte.length > 0) {
            return (
                <MaterialUI.Grid item={true} xs={6} className={this.props.classes.item}>
                    <MaterialUI.Typography
                        color={'inherit'}
                        variant="subtitle2"
                        style={titleStyles.fontWeight350}
                        className={this.props.classes.label}
                    >
                        {`${deTranslator.facility.product}`}
                    </MaterialUI.Typography>

                    {this.props.facility.Produkte.map(product => (
                        <div
                            key={product.id}
                            className={this.props.classes.InputContainer}
                            style={{ marginBottom: 10 }}
                        >
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input}  ${this.props.classes.marginRight}`}
                            >
                                {`${product.name} (${currencyFormatter(product.preis / 100)})`}
                            </MaterialUI.Typography>
                        </div>
                    ))}
                </MaterialUI.Grid>
            );
        }
        return null;
    };

    public render() {
        const { VertragLaufzeitItems } = this.props.facility;
        const paymentPlan = PaymentPlan.find(
            item => item.key === this.props.facility.Abrechnungsmodell
        );

        return (
            <MaterialUI.Paper
                elevation={2}
                style={{ padding: 20, width: '90%' }}
                className={this.props.classes.responsiveContent}
            >
                <MaterialUI.Grid container={true} direction={'row'}>
                    <MaterialUI.Grid
                        item={true}
                        xs={12}
                        zeroMinWidth={true}
                        className={this.props.classes.item}
                    >
                        <div className={this.props.classes.labelContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.label}  ${this.props.classes.marginRight}`}
                            >
                                {`${deTranslator.address.street}`}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.label} ${this.props.classes.smallInput}`}
                            >
                                {`${deTranslator.address.streetNumber}`}
                            </MaterialUI.Typography>
                        </div>
                        <div className={this.props.classes.InputContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input}  ${this.props.classes.marginRight} `}
                            >
                                {this.props.facility.Adresse.Strasse}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput}`}
                            >
                                {this.props.facility.Adresse.HausNummer}
                            </MaterialUI.Typography>
                        </div>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={12} className={this.props.classes.item}>
                        <div className={this.props.classes.labelContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.label} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            >
                                {`${deTranslator.address.zipCode}`}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.label} ${this.props.classes.mediumInput}`}
                            >
                                {`${deTranslator.address.city}`}
                            </MaterialUI.Typography>
                        </div>
                        <div className={this.props.classes.InputContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            >
                                {this.props.facility.Adresse.PLZ}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input} ${this.props.classes.mediumInput}`}
                            >
                                {this.props.facility.Adresse.Ort}
                            </MaterialUI.Typography>
                        </div>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={12} className={this.props.classes.item}>
                        <div className={this.props.classes.labelContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.date} ${this.props.classes.marginRight}`}
                            >
                                {`${deTranslator.facility.contractBegin}`}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={`${this.props.classes.date} ${this.props.classes.paddingLeft}`}
                            >
                                {`${deTranslator.facility.contractEnd}`}
                            </MaterialUI.Typography>
                        </div>
                        <div className={this.props.classes.InputContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            >
                                {IsoformatToGermanDate(
                                    this.props.facility.VertragLaufzeitItems[0].Start
                                )}
                            </MaterialUI.Typography>

                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            >
                                {IsoformatToGermanDate(VertragLaufzeitItems[0].Ende)}
                            </MaterialUI.Typography>
                        </div>
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={12} className={this.props.classes.item}>
                        <MaterialUI.Typography
                            color={'inherit'}
                            variant="subtitle2"
                            style={titleStyles.fontWeight350}
                            className={this.props.classes.label}
                        >
                            {`${deTranslator.global.moreInfo}`}
                        </MaterialUI.Typography>
                        <div className={this.props.classes.InputContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input}  ${this.props.classes.marginRight}`}
                            >
                                {this.props.facility.Zusatzinformationen &&
                                    this.props.facility.Zusatzinformationen}
                            </MaterialUI.Typography>
                        </div>
                    </MaterialUI.Grid>
                    {this.props.facility.Metergrid && (
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                {`Project ID (Metergrid)`}
                            </MaterialUI.Typography>
                            <div className={this.props.classes.InputContainer}>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={`${this.props.classes.input}  ${this.props.classes.marginRight}`}
                                >
                                    {this.props.facility.Metergrid.id}
                                </MaterialUI.Typography>
                            </div>
                        </MaterialUI.Grid>
                    )}
                </MaterialUI.Grid>

                <MaterialUI.Divider className={this.props.classes.divider} />

                <MaterialUI.Grid container={true} spacing={10} direction={'row'}>
                    <MaterialUI.Grid
                        item={true}
                        xs={6}
                        zeroMinWidth={true}
                        className={this.props.classes.item}
                    >
                        <MaterialUI.Typography
                            color={'inherit'}
                            variant="subtitle2"
                            style={titleStyles.fontWeight350}
                            className={this.props.classes.label}
                        >
                            {`${deTranslator.facility.payModel}`}
                        </MaterialUI.Typography>
                        <div className={this.props.classes.InputContainer}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={`${this.props.classes.input}  ${this.props.classes.marginRight}`}
                            >
                                {paymentPlan.label}
                            </MaterialUI.Typography>
                        </div>
                    </MaterialUI.Grid>

                    {this.renderProducts()}
                </MaterialUI.Grid>
            </MaterialUI.Paper>
        );
    }
}

export const SlFacilityDataDetails = MaterialUI.withStyles(styles)(FacilityDataDetailsComponent);
