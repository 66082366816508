import Moment from 'moment';
import { IMeterPlace } from '../interfaces/interface';
import { FormValidator } from './form.validator';

export const isContractStartBeforeContractEnd = (value) => {
    const errors = {};
    const durationArrayErrors = [];

    if (value && value.VertragLaufzeitItems && value.VertragLaufzeitItems.length > 0) {
        value.VertragLaufzeitItems.forEach((item, itemIndex) => {
            const durationErrors = {};

            if (FormValidator.validDateFormat(item.Ende) && FormValidator.validDateFormat(item.Start)) {
                if (!Moment(item.Start).isSameOrBefore(item.Ende, 'day')) {
                    durationErrors['Ende'] = 'ungültig Vertragsbeginn';
                    durationArrayErrors[itemIndex] = durationErrors;
                }
            }

        });
    }

    if (durationArrayErrors.length) {
        errors['VertragLaufzeitItems'] = durationArrayErrors;
    }
    return errors;
};

export const meterPanelValidation = (value) => {
    let containDuplicate = false;
    if (value && value.length > 0) {
        const meterPanel = value
            .filter(data => data.WohnungNr !== '')
            .map((item: IMeterPlace) => {
                return {WohnungNr: item.WohnungNr, Gebaeude: item.Gebaeude, Stockwerk: item.Stockwerk};
            });

        const tmp = [...meterPanel];
        // find duplicate item
        meterPanel.forEach((item) => {
            tmp.shift();
            if (checkIfObjectExists(tmp, item)) {
                containDuplicate = true;
            }
        });
    }

    return containDuplicate;


};

function checkIfObjectExists(arr, current) {
    return arr.some((e) => {
        return JSON.stringify(e) === JSON.stringify(current);
    });
}

