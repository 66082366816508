export const createSlimNews = /* GraphQL */ `
    mutation CreateSlimNews($input: CreateSlimNewsInput!, $condition: ModelSlimNewsConditionInput) {
        createSlimNews(input: $input, condition: $condition) {
            id
            title
            description
            link
            linkType
            status
            createdAt
            updatedAt
        }
    }
`;

export const updateSlimNews = /* GraphQL */ `
    mutation UpdateSlimNews($input: UpdateSlimNewsInput!, $condition: ModelSlimNewsConditionInput) {
        updateSlimNews(input: $input, condition: $condition) {
            id
            title
            description
            link
            linkType
            status
            createdAt
            updatedAt
        }
    }
`;

export const deleteSlimNews = /* GraphQL */ `
    mutation DeleteSlimNews($input: DeleteSlimNewsInput!, $condition: ModelSlimNewsConditionInput) {
        deleteSlimNews(input: $input, condition: $condition) {
            id
            title
            description
            link
            linkType
            status
            createdAt
            updatedAt
        }
    }
`;
