export const getGeraet = /* GraphQL */ `
    query GetGeraet($id: ID!) {
        getGeraet(id: $id) {
            id
            organisation_id
            KID
            AID
            ZID
            Seriennummer
            ZaehlerTyp
            Einbaudatum
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            GeraetePIN
            Metergrid {
                url
                id
                serial
                panel
                conversion_factor
                owner
                project
            }
            AuftragAktion
            Ausbaudatum
            AusbaustandStatus
            Eichdatum
            ObisCodeHT
            ObisCodeNT
            GeraetTyp
            WandlerID1
            WandlerID2
            WandlerID3
            Wandlerfaktor
            ZaehlwerkNKS
            ZaehlwerkVKS
            Zaehlerversanddatum
            ablesungList {
                items {
                    id
                    organisation_id
                    geraet_id
                    Seriennummer
                    ZID
                    ZaehlerTyp
                    Ablesedatum
                    ZaehlerstandHT
                    Einspeisungstand
                    Strombezugstand
                    Ablesegrund
                    AndereAblesegrund
                    Status
                    VerbrauchHT
                    Unit
                    KorrigierterVerbrauch
                    Abschlagshoehe
                    Metergrid {
                        timestamp
                        value
                        project
                        url
                        id
                        measuring_point
                        obis_code
                        StromBezug {
                            timestamp
                            value
                            project
                            url
                            id
                            measuring_point
                            obis_code
                        }
                        Einspeisung {
                            timestamp
                            value
                            project
                            url
                            id
                            measuring_point
                            obis_code
                        }
                    }
                    createdAt
                    updatedAt
                }
                nextToken
            }
            createdAt
            updatedAt
        }
    }
`;

export const geraeteByZID = /* GraphQL */ `
    query GeraeteByZID(
        $ZID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelGeraetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        geraeteByZID(
            ZID: $ZID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                ZID
                Seriennummer
                ZaehlerTyp
                Einbaudatum
                Produkts {
                    name
                    preis
                    ebene
                    zyklus
                    id
                }
                GeraetePIN
                Metergrid {
                    url
                    id
                    serial
                    panel
                    conversion_factor
                    owner
                    project
                }
                AuftragAktion
                Ausbaudatum
                AusbaustandStatus
                Eichdatum
                ObisCodeHT
                ObisCodeNT
                GeraetTyp
                WandlerID1
                WandlerID2
                WandlerID3
                Wandlerfaktor
                ZaehlwerkNKS
                ZaehlwerkVKS
                Zaehlerversanddatum
                ablesungList {
                    items {
                        id
                        organisation_id
                        geraet_id
                        Seriennummer
                        ZID
                        ZaehlerTyp
                        Ablesedatum
                        ZaehlerstandHT
                        Einspeisungstand
                        Strombezugstand
                        Ablesegrund
                        AndereAblesegrund
                        Status
                        VerbrauchHT
                        Unit
                        KorrigierterVerbrauch
                        Abschlagshoehe
                        Metergrid {
                            timestamp
                            value
                            project
                            url
                            id
                            measuring_point
                            obis_code
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const geraeteByKundenanlageAID = /* GraphQL */ `
    query GeraeteByKundenanlageAID(
        $AID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelGeraetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        geraeteByKundenanlageAID(
            AID: $AID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                KID
                AID
                ZID
                Seriennummer
                ZaehlerTyp
                Einbaudatum
                Produkts {
                    name
                    preis
                    ebene
                    zyklus
                    id
                }
                GeraetePIN
                Metergrid {
                    url
                    id
                    serial
                    panel
                    conversion_factor
                    owner
                    project
                }
                AuftragAktion
                Ausbaudatum
                AusbaustandStatus
                Eichdatum
                ObisCodeHT
                ObisCodeNT
                GeraetTyp
                WandlerID1
                WandlerID2
                WandlerID3
                Wandlerfaktor
                ZaehlwerkNKS
                ZaehlwerkVKS
                Zaehlerversanddatum
                ablesungList {
                    items {
                        id
                        organisation_id
                        geraet_id
                        Seriennummer
                        ZID
                        ZaehlerTyp
                        Ablesedatum
                        ZaehlerstandHT
                        Einspeisungstand
                        Strombezugstand
                        Ablesegrund
                        AndereAblesegrund
                        Status
                        VerbrauchHT
                        Unit
                        KorrigierterVerbrauch
                        Abschlagshoehe
                        Metergrid {
                            timestamp
                            value
                            project
                            url
                            id
                            measuring_point
                            obis_code
                        }
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
