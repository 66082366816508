import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { IDashboardNews } from '../../../../common/interfaces/interface';
import { isUserRole } from '../../../../common/utils/Functions';
import { connect } from 'react-redux';
import { getFormValues, isInvalid } from 'redux-form';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { titleStyles } from '../../../../theme/common.styles';
import { SlDashboardForm } from './newsfeed.form';
import { SlAlertComponent, SLModal } from '../../../../common/components/modal';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalActions } from '../../../../common/redux';
import { SettingsActions } from '../../redux';
import { IReduxState } from '../../../../model/redux-state';
import { IForm } from '../../../../model/interfaces/IForm';
import { SlimActions } from '../../../slim/redux';
import {
    addSlimDashboadNews,
    deleteSlimDashboadNews,
    getSlimDashboadNews,
    updateSlimDashboadNews,
} from '../../../slim/slim-api/slimNews-api/slimNews.api';

// const jwtDecode = require('jwt-decode');

export enum FeedStatus {
    None = '',
    ACTIVE = 'Aktiv',
    INACTIVE = 'Inaktiv',
}
export enum LinkType {
    None = '',
    EDIT = 'Bearbeiten',
    READ_MORE = 'Weiterlesen',
    DISCOVER = 'Entdecken',
}

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    showAddDialog: boolean;
    showDeleteDialog: boolean;
    selectedItem: IDashboardNews;
    newsList: { items: Array<IDashboardNews>; nextToken: any };
    editMode: boolean;
}

export interface IDashboadNewsProps extends WithStyles<typeof styles> {
    dashboardForm?: IForm;
}

class DashboardNewsComponent extends React.Component<IDashboadNewsProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IDashboadNewsProps) {
        super(props);
        this.actionBarItems = [
            {
                label: 'Neuer Push',
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () => this.setState({ ...this.state, showAddDialog: true }),
                disable: false,
            },
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.state.selectedItem &&
                    this.setState({ ...this.state, showAddDialog: true, editMode: true }),
                disable: true,
            },
            {
                label: deTranslator.global.delete,
                icon: <MaterialUIIcons.Delete color={'secondary'} />,
                action: () =>
                    this.state.selectedItem &&
                    this.setState({ ...this.state, showDeleteDialog: true }),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            showAddDialog: false,
            showDeleteDialog: false,
            selectedItem: null,
            editMode: false,
            newsList: { items: [], nextToken: null },
        };
    }

    public fetchFeeds = async () => {
        const response = await getSlimDashboadNews();
        this.setState({ ...this.state, newsList: response });
    };

    public componentDidMount() {
        this.fetchFeeds().then();
    }

    public handleFeed = async () => {
        this.setState({ ...this.state, showAddDialog: false });

        if (this.state.editMode) {
            await this.editFeed();
            this.setState({ ...this.state, selectedItem: null, editMode: false });
        }

        if (!this.state.editMode) {
            await this.addFeed();
        }
        this.fetchFeeds().then();
    };

    public handleDelete = async () => {
        this.setState({ ...this.state, showDeleteDialog: false });
        await this.deleteFeed();
    };

    public addFeed = async () => {
        // const decodeToken = jwtDecode(localStorage.getItem(CONSTANTS.idToken));
        await addSlimDashboadNews({
            ...this.props.dashboardForm.formValue,
            // editors: [{ email: decodeToken['email'], updatedAt: new Date().toISOString() }],
        });
    };

    public editFeed = async () => {
        // const decodeToken = jwtDecode(localStorage.getItem(CONSTANTS.idToken));
        const { tableData, ...rest } = this.props.dashboardForm.formValue;
        await updateSlimDashboadNews({
            ...rest,
        });
        this.fetchFeeds().then();

        this.setState({ ...this.state, showAddDialog: false });
    };

    public deleteFeed = async () => {
        await deleteSlimDashboadNews(this.state.selectedItem);
        this.fetchFeeds().then();
    };

    public handleTableRowClick = item => {
        this.setState({
            ...this.state,
            selectedItem: this.state.newsList.items.find(data => data.id === item.id),
        });
    };

    private buildTableActions = () => {
        const newValue = this.state.tableActionBarItems.slice(1).map(item => {
            item.disable = !this.state.selectedItem;
            return item;
        });
        return [this.actionBarItems[0], ...newValue];
    };

    private buildColumns = () => {
        return [
            {
                title: 'News Titel',
                field: 'title',
                render: rowData => {
                    const checked = this.state.selectedItem
                        ? this.state.selectedItem.id === rowData.id
                        : false;
                    return (
                        <div style={{ display: 'flex', marginLeft: '-10px' }}>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <MaterialUI.Radio
                                    onClick={() => this.handleTableRowClick(rowData)}
                                    checked={checked}
                                    value={rowData.id}
                                />
                            )}
                            <span style={{ marginTop: 11 }}>{rowData.title}</span>
                        </div>
                    );
                },
            },
            {
                title: 'News Text',
                field: 'description',
            },
            {
                title: 'Link',
                field: 'link',
            },
            {
                title: 'Link text',
                field: 'linkType',
                render: rowData => rowData && LinkType[rowData.linkType],
                emptyValue: '',
            },
            {
                title: 'Status',
                field: 'status',
                render: rowData => rowData && FeedStatus[rowData.status],
                emptyValue: '',
            },
        ];
    };

    public render() {
        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Slim Dashboard Push
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div>
                <div>
                    <BasicTable
                        columns={[...this.buildColumns()]}
                        data={[...this.state.newsList.items]}
                        options={{ paging: true, pageSize: 5 }}
                    />
                </div>
                <SLModal
                    title={'Dashboard News hinzufügen'}
                    open={this.state.showAddDialog}
                    onOkButtonClick={this.handleFeed}
                    OkButtonDisabled={this.props.dashboardForm.invalid}
                    onClose={() => {
                        this.setState({ showAddDialog: false });
                    }}
                >
                    <SlDashboardForm
                        defaultFeed={this.state.selectedItem}
                        editMode={this.state.editMode}
                    />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={deTranslator.global.delete}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.showDeleteDialog}
                    handleOK={this.handleDelete}
                    handleCancel={() => this.setState({ showDeleteDialog: false })}
                    showCancelButton={true}
                >
                    {`${deTranslator.product.deleteTextStart}`}
                    {this.state.selectedItem && <b>{this.state.selectedItem.title}</b>}
                    {`${deTranslator.product.deleteTextEnd}`}
                </SlAlertComponent>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        dashboardForm: {
            formValue: getFormValues('SlDashboardForm')(state),
            invalid: isInvalid('SlUploadFileForm')(state),
        },
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
    };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, GlobalActions, SettingsActions, SlimActions),
        dispatch
    );
};

export const SLDashboardNews = connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(MaterialUI.withStyles(styles)(DashboardNewsComponent));
