import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';
import { appStyles } from '../../../theme/common.styles';

const styles = MaterialUI.createStyles({
    container: {
        display: 'flex',
        paddingBottom: 10,
        paddingTop: '0px',
        paddingLeft: 0,
        flexDirection: 'row',
    },
    separator: {
        borderLeft: '1px solid white;height:20px',
        marginRight: 0,
        marginLeft: 0,
        marginTop: 8,
    },
    button: {
        paddingRight: 10,
        paddingLeft: 10,
        '&:hover': {
            color: sbColors.white,
            backgroundColor: sbColors.orange,
        },
    },
    icon: {
        marginRight: 5,
    },
    label: {
        paddingTop: 2,
    },
});

export interface ISLActionBar {
    label: any;
    icon: any;
    action: (items: any) => void;
    disable?: boolean;
}

export interface ISLActionBarProps extends WithStyles<typeof styles> {
    items?: Array<ISLActionBar>;
    onItemClick?: (item: ISLActionBar) => void;
    showDivider?: boolean;
}

class SlActionBarComponent extends React.Component<ISLActionBarProps, any> {
    public static defaultProps: Partial<ISLActionBarProps> = {
        showDivider: true,
    };

    private renderOnItem = (item: ISLActionBar, index?: number) => {
        return (
            <MaterialUI.Button
                variant="outlined"
                color={'secondary'}
                className={this.props.classes.button}
                style={{ borderRadius: 20 }}
                size="small"
                key={`sl-actionnar-${index}`}
                disabled={item.disable}
            >
                {/* <div style={{marginRight: 5}}>
                            {item.icon}
                        </div>*/}
                {item.label}
            </MaterialUI.Button>
        );
    };

    private renderItems = (item: any, index: number) => {
        if (index === 0) {
            return (
                <MaterialUI.Button
                    variant="outlined"
                    color={'secondary'}
                    key={`sl-actionnar-${index}`}
                    className={this.props.classes.button}
                    style={{
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                    }}
                    size="small"
                    disabled={item.disable}
                >
                    {/* <div style={{marginRight: 5}}>
                            {item.icon}
                        </div>*/}
                    {item.label}
                </MaterialUI.Button>
            );
        } else if (this.props.items.length - 1 === index) {
            return (
                <MaterialUI.Button
                    variant="outlined"
                    color={'secondary'}
                    key={`sl-actionnar-${index}`}
                    className={this.props.classes.button}
                    style={{
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                    }}
                    size="small"
                    disabled={item.disable}
                >
                    {/* <div style={{marginRight: 5}}>
                            {item.icon}
                        </div>*/}
                    {item.label}
                </MaterialUI.Button>
            );
        } else {
            return (
                <MaterialUI.Button
                    variant="outlined"
                    color={'secondary'}
                    key={`sl-actionnar-${index}`}
                    className={this.props.classes.button}
                    size="small"
                    disabled={item.disable}
                >
                    {/* <div style={{marginRight: 5}}>
                            {item.icon}
                        </div>*/}
                    {item.label}
                </MaterialUI.Button>
            );
        }
    };

    private renderList(item: ISLActionBar, index: number) {
        if (item) {
            return (
                <div
                    key={`sl-renderList-${index}`}
                    style={{ display: 'flex' }}
                    data-testid="actionbar-item"
                >
                    <div onClick={() => item.action(item)}>
                        {this.props.items.length === 1 && this.renderOnItem(item, index)}
                        {this.props.items.length > 1 && this.renderItems(item, index)}
                    </div>
                    {this.props.items.length - 1 !== index && (
                        <div className={this.props.classes.separator} />
                    )}
                </div>
            );
        }
    }

    public render() {
        return (
            <div style={appStyles.container}>
                <div className={this.props.classes.container}>
                    {this.props.items.map((item: ISLActionBar, index: number) =>
                        this.renderList(item, index)
                    )}
                </div>
            </div>
        );
    }
}

export const SlActionBar = MaterialUI.withStyles(styles)(SlActionBarComponent);
