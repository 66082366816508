import {API} from 'aws-amplify';
import {getIdTokenForSlimApi} from '../utils';
import {slimAuftragByStatus, zgaPdfGenerator} from './graphql-operations/queries';
import {createSlimAuftrag, updateSlimAuftrag} from './graphql-operations/mutations';

export const fetchSlimAuftrag = async status => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();

        const response = await (API.graphql({
            query: slimAuftragByStatus,
            variables: {status: status},
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { slimAuftragByStatus: any };
        }>);
        return response.data['slimAuftragByStatus'];
    } catch (e) {
        console.error(e);
    }
};

export const downloadSlimAuftragZga = async slimAuftragID => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();

        const response = await (API.graphql({
            query: zgaPdfGenerator,
            variables: {slimAuftragID: slimAuftragID},
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
        }) as Promise<{
            data: { zgaPdfGenerator: any };
        }>);
        return response.data['zgaPdfGenerator'];
    } catch (e) {
        console.error(e);
    }
};

export const createPanelAuftrag = async data => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: createSlimAuftrag,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    ...data,
                },
            },
        }) as Promise<{
            data: { createSlimAuftrag: any };
        }>);
        return response.data['createSlimAuftrag'];
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};

export const updateAuftrag = async (id, data) => {
    try {
        const lambdaAuthToken = await getIdTokenForSlimApi();
        const response = await (API.graphql({
            query: updateSlimAuftrag,
            authMode: 'AWS_LAMBDA',
            authToken: lambdaAuthToken,
            variables: {
                input: {
                    id: id,
                    ...data,
                },
            },
        }) as Promise<{
            data: { updateSlimAuftrag: any };
        }>);
        return response.data['updateSlimAuftrag'];
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
};
