import * as React from 'react';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';
import * as MaterialUI from '../../../materialUI/MaterialUI';


export interface IDeleteButtonsProps {
    onClick: () => void;
}

export class DeleteButton extends React.Component<IDeleteButtonsProps, {}> {


    public handleClick = () => {
        this.props.onClick();
    };

    public render() {
        return <MaterialUI.IconButton onClick={this.handleClick}>
            <MaterialUIIcons.RemoveCircleOutline tabIndex={-1}/>
        </MaterialUI.IconButton>;
    }
}
