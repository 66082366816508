import { IDevice } from '../../../../common/interfaces/interface';
import { DeviceActionTypes } from '../types/devices.action.types';

export interface IDevicesState {
    devices: Array<IDevice>;
    selectedDevice: IDevice;
    devicesLoading: boolean;
    displayOrderDeviceModal: boolean;
    changedDevice: { previousDevice: any, currentDevice: any };
}

const initialState: IDevicesState = {
    devices: [],
    selectedDevice: null,
    devicesLoading: false,
    displayOrderDeviceModal: false,
    changedDevice: {previousDevice: null, currentDevice: null}
};

export const devicesReducer = (state: IDevicesState = initialState, action: any): IDevicesState => {
    switch (action.type) {
        case DeviceActionTypes.DEVICES_LOADING_STATUS:
            return Object.assign({}, state, {devicesLoading: action.payload});
        case DeviceActionTypes.SAVE_DEVICES:
            return Object.assign({}, state, {devices: [...action.payload]});
        case DeviceActionTypes.SAVE_SELECTED_DEVICE:
            return Object.assign({}, state, {selectedDevice: action.payload});
        case DeviceActionTypes.SHOW_REMOVE_DEVICE_MODAL:
            return Object.assign({}, state, {displayOrderDeviceModal: action.payload});
        case DeviceActionTypes.CHANGE_DEVICE:
            return Object.assign({}, state, {changedDevice: action.payload});

        default:
            return Object.assign({}, state);

    }
};