import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalActions } from '../../../common/redux';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

import * as LoginBackground from '../../../static/images/login-background.png';
import * as LogoStromLux from '../../../static/images/Logo_StromLux_320.jpeg';
import * as LogoNetzeBW from '../../../static/images/netze-bw.png';
import { sbColors } from '../../../theme/app.colors';
import { LoginActions } from '../redux';
import { ILoginState } from '../redux/reducers/login.reducer';
import { IOauth, TokenService } from '../services/token.service';

const styles = () =>
    MaterialUI.createStyles({
        loginPage: {
            position: 'absolute',
            top: '20%',
        },
        container: {
            padding: '25px',
            width: 350,
            maxHeight: 450,
        },
        logoContainer: {
            width: '100%',
        },
        backgroundImage: {
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
        },
        loginContainer: {
            background: sbColors.white,
            zIndex: 1,
            display: 'flex',
            padding: 30,
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            width: 350,
        },
        label: {
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 1,
            width: 132,
        },
    });

interface ILoginPageProps extends WithStyles<typeof styles> {
    handleSubmit?: any;
    valid: boolean;
    loginState: ILoginState;
    login: (username: string, password: string, redirectUrl?: string) => void;
    showPassword: (visible: boolean) => void;
    location: any;
}

interface IState {
    token: any;
}

class Login extends React.Component<ILoginPageProps, IState> {
    private readonly oauth: IOauth;

    constructor(props: ILoginPageProps) {
        super(props);

        this.state = { token: null };
        const redirectUrl = `${window.location.protocol}//${window.location.host}/auth`;
        this.oauth = {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            redirectSignIn: redirectUrl,
            redirectSignOut: redirectUrl,
            responseType: 'code', // code or token
        };
    }

    public loginWithEnbWIDP = () => {
        TokenService.signIn(this.oauth);
    };

    public render() {
        return (
            <div>
                <img
                    alt={'LogoNetzeBW'}
                    src={LogoNetzeBW}
                    style={{ position: 'absolute', zIndex: 1, width: 150, left: 15, top: 30 }}
                />
                <img
                    alt={'LoginBackground'}
                    src={LoginBackground}
                    style={{ position: 'absolute' }}
                    className={this.props.classes.backgroundImage}
                />
                <MaterialUI.Grid
                    container={true}
                    justify="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <MaterialUI.Paper elevation={2} className={this.props.classes.loginContainer}>
                        <img
                            src={LogoStromLux}
                            style={{ width: 100, marginBottom: 50 }}
                            alt="logo"
                        />
                        <MaterialUI.Button
                            type="button"
                            size="large"
                            variant="contained"
                            onClick={this.loginWithEnbWIDP}
                            color="secondary"
                            fullWidth={true}
                        >
                            ANMELDEN
                        </MaterialUI.Button>
                        {/* <button onClick={() => Auth.federatedSignIn()}>Open Hosted UI</button>*/}
                    </MaterialUI.Paper>
                </MaterialUI.Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        loginState: state.loginState,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(Object.assign({}, LoginActions, GlobalActions), dispatch);
};

export const LoginPage = withRouter(
    connect<ILoginPageProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )(MaterialUI.withStyles(styles)(Login))
);
