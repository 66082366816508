export const deTranslator = {
    appTitle: 'Netze BW StromLux',
    login: {
        error: {
            username: 'Dieser Account ist uns leider nicht bekannt',
            password: 'Passwort ungültig. Versuchen Sie es bitte noch einmal',
            default: 'Account oder Passwort ungültig. Versuchen Sie es bitte noch einmal',
        },
    },
    user: {
        profile: 'Profil',
        firstName: 'Vorname',
        name: 'Nachname',
        masterData: 'Stammdaten',
        number: 'Kundennummer',
        birthday: 'Geburtsdatum',
        companyName: 'Firmenname',
        companyName2: 'Firmenname (2.)',
        salutation: 'Anrede',
        type: 'Kundeart',
        company: 'Firma',
        taxNumber: 'Steuernummer',
        saleTaxId: 'Umsatzstr.-ID',
        person: 'Person',
        businessPartner: 'Geschäftspartner',
        billReceiver: 'Abweichender Rechnungsempfänger',
        contactData: 'Kontaktdaten',
    },
    global: {
        kabs: 'KAB',
        facilities: 'Anlagen',
        meterplaces: 'Zählerplätze',
        devices: 'Geräte',
        ok: 'OK',
        search: 'Suche…',
        cancel: 'Abbrechen',
        edit: 'Bearbeiten',
        save: 'Speichern',
        close: 'Schließen',
        delete: 'Löschen',
        contact: 'Kontakt',
        firstName: 'Vorname',
        name: 'Nachname',
        logout: 'Abmelden',
        newContact: 'Neuer Kontakt',
        customerContact: 'Kontakt: Kunde',
        open: 'öffnen',
        moreInfo: 'Zusatzinformationen',
        removeDeviceOrder: 'Ausbauauftrag',
        completeRemoveDeviceOrder: 'Gesamt Ausbauauftrag',
        changeDevice: 'Gerätewechsel',
        change: 'Ändern',
        select: '',
        faults: 'Entstörung',
        doneFaults: 'Entstörung durchgeführt',
        deviceRemovalConfirmation: 'Rückmeldung Geräteausbau',
        changeDeviceOrder: 'Wechselauftrag',
        mountungDeviceOrder: 'Einbauauftrag',
        appendDevices: 'Geräte zuordnen',
        maloMelo: 'Um MaLo / MeLo ergänzen',
        wishDate: 'Wunschdatum',
        allUser: 'Alle Kunden',
        allNotifications: 'Aktuelle Erinnerungen',
        newCustomer: 'Neuer Kunde',
        archiv: 'Dokumente',
        billing: 'Abrechnung',
        services: 'Dienstleistungen',
        next: 'Nächste',
        back: 'Zurück',
        optional: 'Optional',
        resetTab: 'Reiter zurücksetzen',
        addCustomer: 'Kunde Hinzufügen',
        sendOrder: 'AUFTRAG ABSCHICKEN',
        setting: 'Einstellungen',
        price: 'Preis',
        orders: 'AUFTRÄGE',
        dataDelete: 'Sind Sie sich sicher, dass Sie die Daten wirklich löschen wollen?',
    },
    archiv: {
        new: 'Datei hinzufügen',
        delete: 'Datei löschen',
        deleteContentStart: 'Wollen Sie die Datei ',
        deleteContentEnd: ' löschen?',
        fileUpload: 'Datei Hochladen',
        downloadcsv: 'Download als .csv',
    },
    email: {
        serviceEmail: 'E-Mail an entsprechenden Zählerservice',
        defaultEmail: 'E-Mail',
        alternativeLabel: 'Alternative E-Mail',
    },
    facility: {
        masterData: 'Stammdaten',
        title: 'Anlage',
        new: 'Neue Anlage',
        add: 'Anlage Hinzufügen',
        facilities: 'Anlagen',
        contractBegin: 'Vertragsbeginn',
        contractEnd: 'Vertragsende',
        contractDuration: 'Vertragslaufzeit',
        payModel: 'Abrechnungsmodell',
        product: 'Zugeordnete Produkte',
        monatge: 'Montageart',
        addressAndContract: 'Anschrift und Vertragslaufzeit',
        billStatus: 'Status ändern',
        alert: {
            title: 'Anlage löschen',
            contentStart: 'Wollen Sie Anlage ',
            contentEnd: ' löschen?',
        },
    },
    device: {
        title: 'Geräte',
        reading: 'Ablesestände',
        obisCodeHT: 'Obis-Kennziffer HT',
        obisCodeNT: 'Obis-Kennziffer NT',
        eichdate: 'Ablauf Eichgültigkeit',
        grundTyp: 'Grundtyp',
        addDate: 'Einbaudatum',
        addStateNT: 'Einbaustand NT',
        addStateHT: 'Einbaustand HT',
        removeDate: 'Ausbaudatum',
        removeStateNT: 'Ausbaustand NT',
        removeStateHT: 'Ausbaustand HT',
        addReading: 'Ablesung hinzufügen',
        editReading: 'Ablesung bearbeiten',
        edit: 'Gerät bearbeiten',
        ZaehlwerkVKS: 'Vorkommastellen',
        ZaehlwerkNKS: 'Nachkommastellen',
        addDeviceType: 'Neuer Grundtyp ',
        zaehlerstand: 'Zählerstände',
    },
    malemelo: {
        alert: {
            content:
                'Wenn Sie die Eingabemaske schließen, werden alle eingetragen Daten verworfen.',
            title: 'Eingabemaske schließen',
        },
    },
    contactPerson: {
        partner: 'Ansprechpartner',
        new: 'Neuer Kontakt',
        rolle: 'Rolle',
        alert: {
            title: 'Kontaktdaten löschen?',
            contentStart: 'Sollen die Kontaktdaten von ',
            contentEnd: ' gelöscht werden?',
        },
    },
    abeleseStand: {
        unplausibel: 'unplausibel',
        plausibel: 'plausibel',
        freigegeben: 'freigegeben',
        infoText:
            'Der eingetragene Wert liegt außerhalb der prognostizierten Tolaranzgrenze. \n' +
            'Soll dieser trotzdem freigegeben werden?\n' +
            '\n' +
            'Zum Annehmen des Wertes den Status auf freigegeben ändern.',
    },
    meterPanel: {
        title: 'Zählerplätze',
        addMeterPanel: 'Neuer Zählerplatz',
        importMeterPanel: 'Zählerplätze Hochladen',
        apartmentNr: 'Wohnungs-Nr.',
        building: 'Gebäude',
        Malo: 'Marktlokations-ID',
        Verbrauchsprognose: 'Verbrauchsprognose',
        Melo: 'Messlokations-ID',
        floor: 'Stockwerk',
        location: 'Lage Zählerplatz',
        Montage: 'Montageart',
    },
    einheit: {
        kilowatt: 'kWh',
        euro: '€',
    },

    toolTip: {
        newCustomer: 'Neuer Kunde anlegen',
        phoneOrEmail: 'Pflichtfeld falls “Email” nicht befüllt',
        emailOrPhone: 'Pflichtfeld falls “Telefonnummer” nicht befüllt',
    },
    address: {
        street: 'Straße',
        zipCode: 'PLZ',
        streetNumber: 'HausNr',
        city: 'Ort',
        country: 'Land',
        mailBox: 'Postfach',
        moreInfo: 'Zusatzinformationen',
    },
    product: {
        add: 'Neues Produkt',
        delete: 'Produkt Löschen',
        edit: 'Produkt Bearbeiten',
        deleteTextStart: 'Wollen Sie ',
        deleteTextEnd: ' löschen?',
    },
    service: {
        add: 'Neue Dienstleistung',
        delete: 'Dienstleistung Löschen',
        edit: 'Dienstleistung Bearbeiten',
        deleteTextStart: 'Wollen Sie ',
        deleteTextEnd: ' löschen?',
    },
    actions: {
        add: 'Hinzufügen',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        deleteTextStart: 'Wollen Sie ',
        deleteTextEnd: 'löschen?',
    },
    error: {
        chooseDevice: 'Bitte, wählen Sie mindestens ein Gerät aus',
        systemError: 'Ein Systemfehler ist aufgetreten',
        removeDevice:
            'Ein der ausgewählten Geräte wurde bereits ausgebaut. Bitte überprüfen Sie die Auswahl.',
    },
    bill: {
        changeStatus: 'Rechnungsstatus ändern',
    },
};
