import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { IKAB } from '../../../common/interfaces/interface';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { Country } from '../../../model/enums';
import { titleStyles } from '../../../theme/common.styles';

const styles = () =>
    MaterialUI.createStyles({
        container: {
            marginBottom: 24,
        },
        mask: {
            display: 'flex',
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            minHeight: 20,
        },
        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        mediumInput: {
            width: '70%',
        },
        smallInput: {
            width: '30%',
        },
        marginRight: {
            marginRight: 24,
        },
        marginLeft: {
            marginLeft: 24,
        },
    });

export interface ICustomerDataDetailsProps extends WithStyles<typeof styles> {
    customerdata: IKAB;
}

class CustomerDataDetailsComponent extends React.Component<ICustomerDataDetailsProps> {
    public render() {
        const title =
            this.props.customerdata.Anrede.Person && this.props.customerdata.Anrede.Person.Titel
                ? this.props.customerdata.Anrede.Person.Titel
                : '';

        return (
            <MaterialUI.Paper style={{ padding: 15 }} id={'customerDataPaperElement'}>
                <MaterialUI.Typography variant="h5">
                    {deTranslator.user.businessPartner}
                </MaterialUI.Typography>

                <MaterialUI.Grid container={true} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={12} sm={6} style={{ padding: 10 }}>
                        <MaterialUI.Grid
                            item={true}
                            xs={10}
                            zeroMinWidth={true}
                            className={this.props.classes.item}
                        >
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                {deTranslator.user.number}
                            </MaterialUI.Typography>

                            <MaterialUI.Typography
                                color={'inherit'}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.ID}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={false}
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                {this.props.customerdata.Anrede.Person &&
                                    deTranslator.user.salutation}
                                {this.props.customerdata.Anrede.Firma && deTranslator.user.type}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={false}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.Anrede.Person &&
                                    `${this.props.customerdata.Anrede.Typ} ${title}`}
                                {this.props.customerdata.Anrede.Firma && deTranslator.user.company}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} style={{ display: 'flex' }} xs={10}>
                            <MaterialUI.Grid
                                item={true}
                                xs={true}
                                style={{ marginRight: '30px', width: '50%' }}
                                className={this.props.classes.item}
                            >
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={this.props.classes.label}
                                >
                                    {this.props.customerdata.Anrede.Person &&
                                        deTranslator.user.firstName}
                                    {this.props.customerdata.Anrede.Firma &&
                                        deTranslator.user.companyName}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={this.props.classes.input}
                                >
                                    {this.props.customerdata.Anrede.Firma &&
                                        this.props.customerdata.Anrede.Firma.Firmenname1}
                                    {this.props.customerdata.Anrede.Person &&
                                        this.props.customerdata.Anrede.Person.Vorname}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>

                            <MaterialUI.Grid
                                item={true}
                                xs={true}
                                style={{ width: '50%' }}
                                className={this.props.classes.item}
                            >
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={this.props.classes.label}
                                >
                                    {this.props.customerdata.Anrede.Person &&
                                        deTranslator.user.name}
                                    {this.props.customerdata.Anrede.Firma &&
                                        deTranslator.user.companyName2}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={this.props.classes.input}
                                >
                                    {this.props.customerdata.Anrede.Firma &&
                                        this.props.customerdata.Anrede.Firma.Firmenname2}
                                    {this.props.customerdata.Anrede.Person &&
                                        this.props.customerdata.Anrede.Person.Nachname}
                                </MaterialUI.Typography>
                            </MaterialUI.Grid>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                Umsatzsteuerpflichtig
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.Anrede.Firma &&
                                    this.props.customerdata.Anrede.Firma.Umsatzsteuerpflichtig}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                style={titleStyles.fontWeight350}
                                variant="subtitle2"
                                className={this.props.classes.label}
                            >
                                {deTranslator.user.taxNumber}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={true}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.Anrede.Firma &&
                                    this.props.customerdata.Anrede.Firma.Steuernummer}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>

                    {/*address*/}
                    <MaterialUI.Grid item={true} xs={12} sm={6} style={{ padding: 10 }}>
                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <div className={this.props.classes.labelContainer}>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={`${this.props.classes.label} ${this.props.classes.mediumInput}`}
                                >
                                    {`${deTranslator.address.street}`}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={`${this.props.classes.label} ${this.props.classes.smallInput}`}
                                >
                                    {`${deTranslator.address.streetNumber}`}
                                </MaterialUI.Typography>
                            </div>

                            <div className={this.props.classes.InputContainer}>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight} `}
                                >
                                    {this.props.customerdata.Adresse.Strasse}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={`${this.props.classes.input} ${this.props.classes.smallInput}`}
                                >
                                    {this.props.customerdata.Adresse.HausNummer}
                                </MaterialUI.Typography>
                            </div>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <div className={this.props.classes.labelContainer}>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={`${this.props.classes.label} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                >
                                    {`${deTranslator.address.zipCode}`}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    style={titleStyles.fontWeight350}
                                    variant="subtitle2"
                                    className={`${this.props.classes.label} ${this.props.classes.mediumInput}`}
                                >
                                    {`${deTranslator.address.city}`}
                                </MaterialUI.Typography>
                            </div>
                            <div className={this.props.classes.InputContainer}>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                >
                                    {this.props.customerdata.Adresse.PLZ}
                                </MaterialUI.Typography>
                                <MaterialUI.Typography
                                    color={'inherit'}
                                    noWrap={true}
                                    className={`${this.props.classes.input} ${this.props.classes.mediumInput}`}
                                >
                                    {this.props.customerdata.Adresse.Ort}
                                </MaterialUI.Typography>
                            </div>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                {deTranslator.address.mailBox}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={true}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.Adresse.Postfach &&
                                    this.props.customerdata.Adresse.Postfach}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                Land
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={true}
                                className={this.props.classes.input}
                            >
                                {Country[this.props.customerdata.Adresse.Land] || ''}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>

                        <MaterialUI.Grid item={true} xs={10} className={this.props.classes.item}>
                            <MaterialUI.Typography
                                color={'inherit'}
                                variant="subtitle2"
                                style={titleStyles.fontWeight350}
                                className={this.props.classes.label}
                            >
                                {deTranslator.user.saleTaxId}
                            </MaterialUI.Typography>
                            <MaterialUI.Typography
                                color={'inherit'}
                                noWrap={true}
                                className={this.props.classes.input}
                            >
                                {this.props.customerdata.Anrede.Firma &&
                                    this.props.customerdata.Anrede.Firma.UmsatzSteuerID}
                            </MaterialUI.Typography>
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </MaterialUI.Paper>
        );
    }
}

export const SlCustomerDataDetails = MaterialUI.withStyles(styles)(CustomerDataDetailsComponent);
