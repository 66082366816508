// export reducers
import { saveSuggestions, suggestionLoading } from './actions/search.actions';
import { getSuggestions } from './thunk/search.operations';

export { searchReducer } from './reducers/search.reducer';

// export actions
export const SearchActions = Object.assign({}, {
    suggestionLoading: suggestionLoading,
    saveSuggestions: saveSuggestions,
    getSuggestions: getSuggestions
});