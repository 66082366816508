import { createBrowserHistory } from 'history';
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
    ReducersMapObject,
    Store,
} from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { globalReducer } from '../common/redux';
import { archiveReducer } from '../features/archiv/redux';
import { auftragReducer } from '../features/auftrag/redux';
import { customerReducer } from '../features/customers/redux';
import { devicesReducer } from '../features/devices/redux';
import { contactPersonReducer, facilityReducer } from '../features/facilities/redux';
import { facilityBillingReducer } from '../features/facility-billing/redux';
import { facilityServicesReducer } from '../features/facility-services/redux';
import { meterPanelReducer } from '../features/meterpanels/redux';
import { searchReducer } from '../features/search/redux';
import { settingsReducer } from '../features/settings/redux';
import { slimReducer } from '../features/slim/redux/reducers/slim.reducer';
import { IReduxState } from '../model/redux-state';

const g: any = global;
const composeEnhancers = g.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const ReducerMap: ReducersMapObject = {};
ReducerMap['form'] = formReducer;
ReducerMap['globalState'] = globalReducer;
// ReducerMap['loginState'] = loginReducer;
ReducerMap['customerState'] = customerReducer;
ReducerMap['facilityState'] = facilityReducer;
ReducerMap['contactPersonState'] = contactPersonReducer;
ReducerMap['meterPanelState'] = meterPanelReducer;
ReducerMap['deviceState'] = devicesReducer;
ReducerMap['archiveState'] = archiveReducer;
ReducerMap['searchState'] = searchReducer;
ReducerMap['settingState'] = settingsReducer;
ReducerMap['billingState'] = facilityBillingReducer;
ReducerMap['facilityServiceState'] = facilityServicesReducer;
ReducerMap['auftragState'] = auftragReducer;
ReducerMap['slimState'] = slimReducer;

const createdStore: Store<IReduxState> = createStore(combineReducers(ReducerMap), enhancer);
export const history = createBrowserHistory();
export const store = createdStore;
