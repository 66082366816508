import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { LoadingComponent } from '../../../common/components/buttons';
import { SlHeader } from '../../../common/components/header';
import { BasicTable } from '../../../common/components/table/mui-table.component';
import { ISlTab } from '../../../common/components/tabs/sl-tabs.component';
import { GlobalActions } from '../../../common/redux';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';
import { history } from '../../../store';
import { appStyles } from '../../../theme/common.styles';
import { SlimKabbankdaten } from '../components/kabBank';
import { SlimKabRechnungDaten } from '../components/kabRechnungDaten';
import { SlimKabStammDaten } from '../components/kabStammdaten';
import { SlimKabZusatztinfo } from '../components/kabZusatzInfo';
import { SlimKACard } from '../components/slimCard';
import { SlimActions } from '../redux';
import { ISlimState } from '../redux/reducers/slim.reducer';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(2),
        },
        tabContainer: {
            // backgroundColor: sbColors.white,
        },
        tabContent: {
            height: 'calc(100vh - 165px)',
            margin: 0,
        },
        loadingIconContainer: {
            position: 'absolute',
            top: '30%',
            right: '45%',
        },
    });

export interface ISlimCustomerProps extends WithStyles<typeof styles> {
    match: any;
    location: any;
    slimState: ISlimState;
    saveSlimCustomerTab: (tab: number) => void;
    fetchSlimKundeById: (id: string) => void;
}

class SlimCustomerComponent extends React.Component<ISlimCustomerProps, any> {
    private readonly tabs: Array<ISlTab>;
    private readonly kabID: string = null;

    constructor(props: ISlimCustomerProps) {
        super(props);
        this.kabID = decodeURIComponent(props.match.params.kabID);
        this.tabs = [
            {
                label: deTranslator.user.masterData,
            },
            {
                label: deTranslator.global.contact,
            },
            {
                label: deTranslator.facility.facilities,
            },
        ];
    }

    public componentDidMount() {
        // fetch bodyData from server
        this.props.fetchSlimKundeById(this.kabID);
        // this.props.fetchSlimKundenAnlagen(this.kabID);
    }
    private handleOnFacilityCardClick = facility => {
        history.push('/slim/kabs/' + this.props.match.params.kabID + '/' + facility.AID);
    };

    private buildTabs = () => {
        const { selectedSlimCustomer } = this.props.slimState;
        if (selectedSlimCustomer) {
            return this.tabs.map((tab: ISlTab) => {
                switch (tab.label) {
                    case deTranslator.facility.facilities:
                        tab.badge = selectedSlimCustomer.kundenanlageList.items.length;
                        return tab;
                    default:
                        return tab;
                }
            });
        }
        return this.tabs;
    };

    public renderMasterData() {
        const { selectedSlimCustomer } = this.props.slimState;
        if (!selectedSlimCustomer) {
            return (
                <div>
                    <LoadingComponent size={70} />
                </div>
            );
        }
        return (
            <div style={{ overflow: 'auto' }}>
                <div className={this.props.classes.tabContainer} style={appStyles.container}>
                    <MaterialUI.Grid
                        container={true}
                        wrap={'nowrap'}
                        direction={'column'}
                        className={this.props.classes.tabContent}
                    >
                        <MaterialUI.Grid item={true} style={{ padding: 0 }}>
                            {selectedSlimCustomer && selectedSlimCustomer.kabStammdaten && (
                                <SlimKabStammDaten
                                    customerdata={this.props.slimState.selectedSlimCustomer}
                                />
                            )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {selectedSlimCustomer && selectedSlimCustomer.kabRechnungAdress && (
                                <SlimKabRechnungDaten
                                    customerdata={this.props.slimState.selectedSlimCustomer}
                                />
                            )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {selectedSlimCustomer && selectedSlimCustomer.kabStammdaten && (
                                <SlimKabZusatztinfo
                                    customerdata={this.props.slimState.selectedSlimCustomer}
                                />
                            )}
                        </MaterialUI.Grid>
                        <MaterialUI.Grid item={true} zeroMinWidth={true} style={{ padding: 0 }}>
                            {selectedSlimCustomer && selectedSlimCustomer && (
                                <SlimKabbankdaten
                                    customerdata={this.props.slimState.selectedSlimCustomer}
                                />
                            )}
                        </MaterialUI.Grid>
                    </MaterialUI.Grid>
                </div>
            </div>
        );
    }
    public renderContactPerson() {
        const { kabKontakt } = this.props.slimState.selectedSlimCustomer;
        const { Anrede } = kabKontakt;
        const kontakt = {
            Anrede: Anrede.Typ,
            Titel: Anrede.Person.Titel,
            Vorname: Anrede.Person.Vorname || '',
            Nachname: Anrede.Person.Nachname || '',
            Telefon: kabKontakt.Telefon || '',
            Email: kabKontakt.Email || '',
        };
        const tableColumns = [
            { title: 'Anrede', field: 'Anrede' },
            { title: 'Titel', field: 'Titel' },
            { title: 'Vorname', field: 'Vorname' },
            { title: 'Nachname', field: 'Nachname' },
            { title: 'Telefonnummer', field: 'Telefon' },
            { title: 'E-Mail', field: 'Email' },
        ];

        return (
            <div style={{ overflow: 'auto' }}>
                <div className={this.props.classes.tabContainer}>
                    <div className={this.props.classes.tabContent} style={appStyles.container}>
                        <BasicTable
                            columns={tableColumns}
                            options={{
                                maxBodyHeight: 'calc(100vh - 200px)',
                                actionsColumnIndex: -1,
                            }}
                            data={[kontakt]}
                        />
                    </div>
                </div>
            </div>
        );
    }

    public renderKundenAnlagen() {
        const { selectedSlimCustomer } = this.props.slimState;

        return (
            <div style={{ overflow: 'auto', height: 'calc(100vh - 130px)' }}>
                <div className={this.props.classes.tabContainer}>
                    <MaterialUI.Grid
                        container={true}
                        spacing={10}
                        className={this.props.classes.tabContent}
                    >
                        {selectedSlimCustomer.kundenanlageList.items.map(item => (
                            <MaterialUI.Grid item={true} key={item.id}>
                                <SlimKACard
                                    onSelected={this.handleOnFacilityCardClick}
                                    kundenAnlage={item}
                                />
                            </MaterialUI.Grid>
                        ))}
                    </MaterialUI.Grid>
                </div>
            </div>
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader
                    currentLocation={null}
                    selectedTab={this.props.slimState.customerActiveTab}
                    tabs={this.buildTabs()}
                    onTabClick={this.props.saveSlimCustomerTab}
                />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.tabs[this.props.slimState.customerActiveTab].label ===
                        this.tabs[0].label && this.renderMasterData()}
                    {this.tabs[this.props.slimState.customerActiveTab].label ===
                        this.tabs[1].label && this.renderContactPerson()}
                    {this.tabs[this.props.slimState.customerActiveTab].label ===
                        this.tabs[2].label && this.renderKundenAnlagen()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IReduxState) => {
    return {
        slimState: state.slimState,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(Object.assign({}, SlimActions, GlobalActions), dispatch);
};

export const SlimCustmerWithStyles = MaterialUI.withStyles(styles)(SlimCustomerComponent);

export const SlimCustomer = withRouter(
    connect<ISlimCustomerProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<ISlimCustomerProps>(SlimCustmerWithStyles)
);
