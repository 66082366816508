import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import {
    renderItemForSelectBox,
    SlDateInputComponent,
    SlSelectInputComponent,
} from '../../../common/components/forms';
import { SLMeterLevelInput } from '../../../common/components/forms/number-input.component';
import { SlTextInputComponent } from '../../../common/components/forms/text-field.component';
import { IDevice } from '../../../common/interfaces/interface';
import {
    germanDateToISODateFormat,
    inputDateDisplayFormat,
    numberFormatter,
} from '../../../common/utils/Functions';
import { FormValidator } from '../../../common/validators/form.validator';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { sbColors } from '../../../theme/app.colors';

const styles = () =>
    MaterialUI.createStyles({
        maxWidth: {
            width: 375,
        },
        itemWidth: {
            flexGrow: 0,
            maxWidth: '12%',
            flexBasis: '12%',
        },
        disabledTextField: {
            backgroundColor: '#E2E2E2',
        },
    });

export interface ISlRemoveDeviceProps extends WithStyles<typeof styles> {
    devices: Array<IDevice>;
    initialize?: (data) => void;
    dispatch?: any;
    currentFormDevices?: any;
}

interface IDeviceForm {
    GeraeteID: string;
    Grundtyp: string;
    ZID: string;
    Ausbaudatum: string;
    AusbaustandHT?: string;
    AusbaustandNT?: string;
    AusbaustandStatus?: string;
    ZaehlerstandHT?: string;
    ZaehlerstandNT?: string;
}

class SlimRemoveDevice extends React.Component<ISlRemoveDeviceProps> {
    constructor(props: ISlRemoveDeviceProps) {
        super(props);
        const initialValues = this.props.devices.map(device => {
            return Object.assign(
                {},
                {
                    ...device,
                    GeraeteID: device.GeraeteID,
                    AusbaustandHT: device.AusbaustandHT,
                    AusbaustandStatus: device.AusbaustandStatus ? device.AusbaustandStatus : '',
                }
            );
        });
        if (this.props.currentFormDevices) {
            this.props.initialize({ devices: this.props.currentFormDevices.devices });
        } else {
            this.props.initialize({ devices: initialValues });
        }
    }

    private convertToNumber(newValue) {
        return newValue ? Number(newValue.replace(/\./g, '').replace(',', '.')) : 0;
    }

    private validateAusbauDatum = (newValue, allValues, props, name) => {
        const index = Number(name.match(/\d/g)[0]);
        const Ablesungen = allValues.devices[index] ? allValues.devices[index].Ablesungen : [];
        Ablesungen.sort(
            (itemA, itemB) =>
                new Date(itemB.Ablesedatum).getTime() - new Date(itemA.Ablesedatum).getTime()
        );
        const lastReading = Ablesungen[0];
        return new Date(newValue).getTime() - new Date(lastReading.Ablesedatum).getTime() > 0 ||
            new Date(newValue).getTime() - new Date(lastReading.Ablesedatum).getTime() === 0
            ? null
            : `Das Datum muss am gleichen Tag oder später als der  ${lastReading.Ablesedatum}`;
    };

    private validateZarhlerstand = (newValue, allValues, props, name) => {
        const index = Number(name.match(/\d/g)[0]);
        const Ablesungen = allValues.devices[index] ? allValues.devices[index].Ablesungen : [];
        Ablesungen.sort(
            (itemA, itemB) =>
                new Date(itemB.Ablesedatum).getTime() - new Date(itemA.Ablesedatum).getTime()
        );
        const lastReading = Ablesungen[0];
        return Number(newValue) - Number(lastReading.ZaehlerstandHT) > 0 ||
            Number(newValue) - Number(lastReading.ZaehlerstandHT) === 0
            ? null
            : `Das Datum muss am gleichen Tag oder später als der  ${numberFormatter(
                  lastReading.ZaehlerstandHT
              )}`;
    };

    /* private setStatusET(deviceForm: IDeviceForm, ausbaustandHT, index) {
        if (this.convertToNumber(deviceForm.ZaehlerstandHT) < this.convertToNumber(ausbaustandHT)) {
            this.props.dispatch(
                change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Plausibel)
            );
        } else {
            this.props.dispatch(
                change('SlRemoveDeviceForm', `devices[${index}].Status`, AbleseStatus.Unplausibel)
            );
        }
    } */

    /*  private setDeviceStatus(deviceForm: IDeviceForm, ausbaustandHT, index) {
        switch (deviceForm.Grundtyp) {
            case Grundtyp.ZaehlerET:
                this.setStatusET(deviceForm, ausbaustandHT, index);
                break;
        }
    }*/

    private renderStatus = device => {
        enum plausibelOptions {
            Plausibel = 'Plausibel',
            Unplausibel = 'Unplausibel',
            Freigegeben = 'Freigegeben',
        }

        return (
            <Field
                name={`${device}.AusbaustandStatus`}
                component={SlSelectInputComponent}
                label={'Status'}
                validate={[FormValidator.required]}
                required={true}
                style={{ color: sbColors.orange, width: '90%' }}
                options={{ fullWidth: true }}
            >
                {renderItemForSelectBox(plausibelOptions)}
            </Field>
        );
    };

    private renderContent = ({ fields }) => {
        return fields.map((device, index, devicesField) => {
            // const deviceType = devicesField.get(index).Grundtyp;
            let ZaehlerstandHT = '0';
            // let ZaehlerstandNT = '0';
            if (this.props.devices && this.props.devices.length > 0) {
                ZaehlerstandHT =
                    this.props.devices[index].Ablesungen &&
                    this.props.devices[index].Ablesungen.length > 0
                        ? this.props.devices[index].Ablesungen[0].ZaehlerstandHT
                        : this.props.devices[index].EinbaustandHT;
                /*  ZaehlerstandNT =
                    this.props.devices[index].Ablesungen &&
                    this.props.devices[index].Ablesungen.length > 0
                        ? this.props.devices[index].Ablesungen[0].ZaehlerstandNT
                        : this.props.devices[index].EinbaustandNT;*/
            }

            return (
                <MaterialUI.Grid key={`device-${device}.GeraeteID`} container={true} spacing={2}>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${device}.GeraeteID`}
                            label={'Geräte-ID'}
                            component={SlTextInputComponent}
                            readOnly={true}
                            disabled={true}
                            options={{ fullWidth: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <Field
                            name={`${device}.Ausbaudatum`}
                            label={'Ausbaudatum'}
                            component={SlDateInputComponent}
                            type={'text'}
                            options={{ fullWidth: true }}
                            required={true}
                            format={inputDateDisplayFormat}
                            normalize={germanDateToISODateFormat}
                            validate={[
                                FormValidator.required,
                                FormValidator.isValidDate,
                                this.validateAusbauDatum,
                            ]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <MaterialUI.TextField
                            label={'Letzter Stand HT'}
                            variant={'outlined'}
                            className={this.props.classes.disabledTextField}
                            disabled={true}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth={true}
                            defaultValue={ZaehlerstandHT}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true}>
                        <SLMeterLevelInput
                            name={`${device}.AusbaustandHT`}
                            label={`Ausbaustand HT`}
                            required={true}
                            validate={[FormValidator.required, this.validateZarhlerstand]}
                            endAdornment={
                                <MaterialUI.InputAdornment position="end">
                                    {deTranslator.einheit.kilowatt}
                                </MaterialUI.InputAdornment>
                            }
                        />
                    </MaterialUI.Grid>

                    <MaterialUI.Grid item={true} xs={true}>
                        {this.renderStatus(device)}
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            );
        });
    };

    public render() {
        return (
            <div style={{ paddingTop: 24 }}>
                <FieldArray name="devices" component={this.renderContent} />
            </div>
        );
    }
}

export const SlimRemoveDeviceComponent = MaterialUI.withStyles(styles)(SlimRemoveDevice);

export const RemoveSlimDeviceForm = reduxForm({
    form: 'RemoveSlimDeviceComponent', // a unique identifier for this form
    destroyOnUnmount: true,
})(SlimRemoveDeviceComponent);
