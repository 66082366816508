import { IAbrechnungsItem } from '../../../../common/interfaces/interface';
import { FacilityBillingActionTypes } from '../types/facility-billing.actions.types';


export interface IBillingState {
    facilityBilling: any;
    billPeriods: Array<IAbrechnungsItem>;
    selectedPeriod: number;
    billAsBase64: string;
}

const initialState: IBillingState = {
    facilityBilling: null,
    billPeriods: [],
    selectedPeriod: 0,
    billAsBase64: null

};

export const facilityBillingReducer = (state: IBillingState = initialState, action: any): IBillingState => {

    switch (action.type) {
        case FacilityBillingActionTypes.SAVE_FACILITY_BILLING:
            return Object.assign({}, state, {facilityBilling: action.payload});
        case FacilityBillingActionTypes.SAVE_BILLING_PERIODS:
            return Object.assign({}, state, {billPeriods: action.payload});
        case FacilityBillingActionTypes.SAVE_SELECTED_BILLING_PERIOD_INDEX:
            return Object.assign({}, state, {selectedPeriod: action.payload});
        case FacilityBillingActionTypes.SAVE_BILL_AS_CSV_FORMAT:
            return Object.assign({}, state, {billAsBase64: action.payload});
        default:
            return Object.assign({}, state);

    }


};