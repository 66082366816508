import axios from 'axios';
import { IAbleseTyp, IDeviceType, IProduct } from '../../../../common/interfaces/interface';
import { addLoadingState, removeLoadingState, setSnackbarStatus } from '../../../../common/redux/actions/global.action';

import { IReduxState } from '../../../../model/redux-state';
import {
    saveAblesTyp,
    saveDeviceTypeList, saveEmailFile, saveEmailFileInfo,
    saveProductList,
    saveServices,
    saveSettingsEmails
} from '../actions/settings.actions';

import { SettingActionTypes } from '../types/settings.types';

export const uploadMeterServiceEmails = (payload: any) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.UPLOAD_EMAIL));
            await axios.post(`meterpanelservices/emails`, payload);
            dispatch(setSnackbarStatus({flag: true, text: 'Datei wurde hochgeladen', type: 'success'}));
            dispatch(removeLoadingState(SettingActionTypes.UPLOAD_EMAIL));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.UPLOAD_EMAIL));
            dispatch(setSnackbarStatus({flag: true, text: 'Datei wurde nicht hochgeladen', type: 'error'}));
        }
    };
};

export const downloadMeterServiceEmailsFile = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`meterpanelservices/emails/download`);
            dispatch(saveEmailFile(response.data ? response.data : null));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getMeterServiceEmailsInfo = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`meterpanelservices/emails/metadata`);
            dispatch(saveEmailFileInfo(response.data ? response.data : null));
        } catch (error) {
            console.error(error);
        }
    };
};

export const getMeterServiceEmails = () => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_EMAILS));
            const response = await axios.get(`meterpanelservices/emails`);
            dispatch(saveSettingsEmails(response.data ? [...response.data] : []));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_EMAILS));
        } catch (error) {
            dispatch(saveSettingsEmails([]));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_EMAILS));
        }
    };
};

export const getProductList = () => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_COST_LIST));
            const response = await axios.get(`products`);
            dispatch(saveProductList(response.data ? response.data : []));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        } catch (error) {
            dispatch(saveProductList([]));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        }
    };
};

export const postProduct = (product: IProduct) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_COST_LIST));
            await axios.post(`products`, product);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        }
    };
};
export const updateProduct = (product: IProduct) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_COST_LIST));
            await axios.put(`products/${product.id}`, product);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        }
    };
};
export const deleteProduct = (productId) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_COST_LIST));
            await axios.delete(`products/${productId}`);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_COST_LIST));
        }
    };
};

export const getFacilityServicesTyp = () => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_SERVICES_TYP));
            const response = await axios.get(`dienstleistungstypen`);
            dispatch(saveServices(response.data ? [...response.data] : []));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_SERVICES_TYP));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_SERVICES_TYP));
        }
    };
};

export const postFacilityServiceTyp = (service: any) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.POST_SERVICES_TYP));
            let allServices = [];

            if (getState().settingState.servicesTyp.find(item => item.ID === service.ID)) {
                allServices = getState().settingState.servicesTyp.map(item => {
                    if (item.ID === service.ID) {
                        item = Object.assign({}, item, service);
                    }
                    return item;
                });
            } else {
                allServices = [service, ...getState().settingState.servicesTyp];
            }

            await axios.put(`dienstleistungstypen`, allServices);
            dispatch(removeLoadingState(SettingActionTypes.POST_SERVICES_TYP));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.POST_SERVICES_TYP));
        }
    };
};

export const deleteFacilityServiceTyp = (service: any) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.POST_SERVICES_TYP));
            const allServices = getState().settingState.servicesTyp.filter(item => item.ID !== service.ID);
            await axios.put(`dienstleistungstypen`, allServices);
            dispatch(removeLoadingState(SettingActionTypes.POST_SERVICES_TYP));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.POST_SERVICES_TYP));
        }
    };
};

export const getDeviceTypeList = () => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
            const response = await axios.get(`deviceTypes`);
            dispatch(saveDeviceTypeList(response.data ? response.data : []));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        } catch (error) {
            dispatch(saveDeviceTypeList([]));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        }
    };
};

export const postDeviceType = (item: IDeviceType) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
            await axios.post(`deviceTypes`, item);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        }
    };
};

export const updateDeviceType = (item: IDeviceType) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
            await axios.put(`deviceTypes/${item.id}`, item);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        }
    };
};

export const deleteDeviceType = (id: string) => {
    return async (dispatch, getState: () => IReduxState) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
            await axios.delete(`deviceTypes/${id}`);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_DEVICE_TYPE));
        }
    };
};

export const postAbleseType = (item: IAbleseTyp) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
            await axios.post(`ablesetyp`, item);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        }
    };
};

export const getAbleseTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
            const response = await axios.get(`ablesetyp`);
            dispatch(saveAblesTyp(response.data ? response.data : []));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        } catch (error) {
            dispatch(saveAblesTyp([]));
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        }
    };
};

export const updateAbleseTyp = (item: IAbleseTyp) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
            await axios.put(`ablesetyp/${item.id}`, item);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        }
    };
};

export const deleteAbleseTyp = (id: string) => {
    return async (dispatch) => {
        try {
            dispatch(addLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
            await axios.delete(`ablesetyp/${id}`);
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        } catch (error) {
            dispatch(removeLoadingState(SettingActionTypes.FETCH_ABLESE_TYPE));
        }
    };
};

