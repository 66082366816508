import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IMaloMeloErgaenzenProps {
    color: any
}

class MaloMeloErgaenzen extends React.Component<IMaloMeloErgaenzenProps, {}> {
    public static defaultProps: Partial<IMaloMeloErgaenzenProps> = {
        color: 'inherit'

    };




    public render() {
        return (
            <MaterialUI.SvgIcon style={{padding: 'inherit', paddingRight: '10px'}}>
                <path d="M6.99609 1.46875V7.26953C6.99219 8.07422 6.73828 8.73242 6.23438 9.24414C5.73438 9.75586 5.05469 10.043 4.19531 10.1055L3.89648 10.1172C2.96289 10.1172 2.21875 9.86523
         1.66406 9.36133C1.10938 8.85742 0.828125 8.16406 0.820312 7.28125V1.46875H1.93359V7.24609C1.93359 7.86328 2.10352 8.34375 2.44336 8.6875C2.7832 9.02734 3.26758 9.19727 3.89648
          9.19727C4.5332 9.19727 5.01953 9.02734 5.35547 8.6875C5.69531 8.34766 5.86523 7.86914 5.86523 7.25195V1.46875H6.99609ZM9.62109 3.66016L9.65039 4.36328C10.1152 3.81641 
          10.7422 3.54297 11.5312 3.54297C12.418 3.54297 13.0215 3.88281 13.3418 4.5625C13.5527 4.25781 13.8262 4.01172 14.1621 3.82422C14.502 3.63672 14.9023 3.54297 15.3633 
          3.54297C16.7539 3.54297 17.4609 4.2793 17.4844 5.75195V10H16.4004V5.81641C16.4004 5.36328 16.2969 5.02539 16.0898 4.80273C15.8828 4.57617 15.5352 4.46289 15.0469
           4.46289C14.6445 4.46289 14.3105 4.58398 14.0449 4.82617C13.7793 5.06445 13.625 5.38672 13.582 5.79297V10H12.4922V5.8457C12.4922 4.92383 12.041 4.46289 11.1387
            4.46289C10.4277 4.46289 9.94141 4.76562 9.67969 5.37109V10H8.5957V3.66016H9.62109ZM23.7246 1.46875L26.5137 8.42969L29.3027 1.46875H30.7617V10H29.6367V6.67773L29.7422
             3.0918L26.9414 10H26.0801L23.2852 3.10938L23.3965 6.67773V10H22.2715V1.46875H23.7246ZM36.4922 10C36.4297 9.875 36.3789 9.65234 36.3398 9.33203C35.8359 9.85547 
             35.2344 10.1172 34.5352 10.1172C33.9102 10.1172 33.3965 9.94141 32.9941 9.58984C32.5957 9.23438 32.3965 8.78516 32.3965 8.24219C32.3965 7.58203 32.6465 7.07031 
             33.1465 6.70703C33.6504 6.33984 34.3574 6.15625 35.2676 6.15625H36.3223V5.6582C36.3223 5.2793 36.209 4.97852 35.9824 4.75586C35.7559 4.5293 35.4219 4.41602 34.9805 
             4.41602C34.5938 4.41602 34.2695 4.51367 34.0078 4.70898C33.7461 4.9043 33.6152 5.14062 33.6152 5.41797H32.5254C32.5254 5.10156 32.6367 4.79688 32.8594 4.50391C33.0859
              4.20703 33.3906 3.97266 33.7734 3.80078C34.1602 3.62891 34.584 3.54297 35.0449 3.54297C35.7754 3.54297 36.3477 3.72656 36.7617 4.09375C37.1758 4.45703 37.3906 4.95898 37.4062
               5.59961V8.51758C37.4062 9.09961 37.4805 9.5625 37.6289 9.90625V10H36.4922ZM34.6934 9.17383C35.0332 9.17383 35.3555 9.08594 35.6602 8.91016C35.9648 8.73438 36.1855 8.50586 
               36.3223 8.22461V6.92383H35.4727C34.1445 6.92383 33.4805 7.3125 33.4805 8.08984C33.4805 8.42969 33.5938 8.69531 33.8203 8.88672C34.0469 9.07812 34.3379 9.17383 34.6934 
               9.17383ZM40.2832 10H39.1992V1H40.2832V10ZM41.7363 6.77148C41.7363 6.15039 41.8574 5.5918 42.0996 5.0957C42.3457 4.59961 42.6855 4.2168 43.1191 3.94727C43.5566 3.67773
                44.0547 3.54297 44.6133 3.54297C45.4766 3.54297 46.1738 3.8418 46.7051 4.43945C47.2402 5.03711 47.5078 5.83203 47.5078 6.82422V6.90039C47.5078 7.51758 47.3887 8.07227
                 47.1504 8.56445C46.916 9.05273 46.5781 9.43359 46.1367 9.70703C45.6992 9.98047 45.1953 10.1172 44.625 10.1172C43.7656 10.1172 43.0684 9.81836 42.5332 9.2207C42.002 
                 8.62305 41.7363 7.83203 41.7363 6.84766V6.77148ZM42.8262 6.90039C42.8262 7.60352 42.9883 8.16797 43.3125 8.59375C43.6406 9.01953 44.0781 9.23242 44.625 9.23242C45.1758 9.23242 45.6133 9.01758 45.9375
                  8.58789C46.2617 8.1543 46.4238 7.54883 46.4238 6.77148C46.4238 6.07617 46.2578 5.51367 45.9258 5.08398C45.5977 4.65039 45.1602 4.43359 44.6133 4.43359C44.0781 4.43359
                   43.6465 4.64648 43.3184 5.07227C42.9902 5.49805 42.8262 6.10742 42.8262 6.90039ZM51.123 6.81836H48.2637V5.93359H51.123V6.81836ZM52.4004 10.7324H51.4688L55.0312 
                   1.46875H55.957L52.4004 10.7324ZM61.7285 1.46875L64.5176 8.42969L67.3066 1.46875H68.7656V10H67.6406V6.67773L67.7461 3.0918L64.9453 10H64.084L61.2891 
                   3.10938L61.4004 6.67773V10H60.2754V1.46875H61.7285ZM73.2129 10.1172C72.3535 10.1172 71.6543 9.83594 71.1152 9.27344C70.5762 8.70703 70.3066 7.95117 70.3066
                    7.00586V6.80664C70.3066 6.17773 70.4258 5.61719 70.6641 5.125C70.9062 4.62891 71.2422 4.24219 71.6719 3.96484C72.1055 3.68359 72.5742 3.54297 73.0781 
                    3.54297C73.9023 3.54297 74.543 3.81445 75 4.35742C75.457 4.90039 75.6855 5.67773 75.6855 6.68945V7.14062H71.3906C71.4062 7.76562 71.5879 8.27148 71.9355
                     8.6582C72.2871 9.04102 72.7324 9.23242 73.2715 9.23242C73.6543 9.23242 73.9785 9.1543 74.2441 8.99805C74.5098 8.8418 74.7422 8.63477 74.9414 8.37695L75.6035
                      8.89258C75.0723 9.70898 74.2754 10.1172 73.2129 10.1172ZM73.0781 4.43359C72.6406 4.43359 72.2734 4.59375 71.9766 4.91406C71.6797 5.23047 71.4961 5.67578 
                      71.4258 6.25H74.6016V6.16797C74.5703 5.61719 74.4219 5.19141 74.1562 4.89062C73.8906 4.58594 73.5312 4.43359 73.0781 4.43359ZM78.123 
                      10H77.0391V1H78.123V10ZM79.5762 6.77148C79.5762 6.15039 79.6973 5.5918 79.9395 5.0957C80.1855 4.59961 80.5254 4.2168 80.959 3.94727C81.3965 
                      3.67773 81.8945 3.54297 82.4531 3.54297C83.3164 3.54297 84.0137 3.8418 84.5449 4.43945C85.0801 5.03711 85.3477 5.83203 85.3477 6.82422V6.90039C85.3477 
                      7.51758 85.2285 8.07227 84.9902 8.56445C84.7559 9.05273 84.418 9.43359 83.9766 9.70703C83.5391 9.98047 83.0352 10.1172 82.4648 10.1172C81.6055 10.1172
                       80.9082 9.81836 80.373 9.2207C79.8418 8.62305 79.5762 7.83203 79.5762 6.84766V6.77148ZM80.666 6.90039C80.666 7.60352 80.8281 8.16797 81.1523 
                       8.59375C81.4805 9.01953 81.918 9.23242 82.4648 9.23242C83.0156 9.23242 83.4531 9.01758 83.7773 8.58789C84.1016 8.1543 84.2637 7.54883 84.2637 
                       6.77148C84.2637 6.07617 84.0977 5.51367 83.7656 5.08398C83.4375 4.65039 83 4.43359 82.4531 4.43359C81.918 4.43359 81.4863 4.64648 81.1582 
                       5.07227C80.8301 5.49805 80.666 6.10742 80.666 6.90039ZM88.9629 6.81836H86.1035V5.93359H88.9629V6.81836ZM91.4004 10H90.2754V1.46875H91.4004V10ZM93.4629 
                       10V1.46875H95.8711C96.6133 1.46875 97.2695 1.63281 97.8398 1.96094C98.4102 2.28906 98.8496 2.75586 99.1582 3.36133C99.4707 3.9668 99.6289 4.66211 
                       99.6328 5.44727V5.99219C99.6328 6.79688 99.4766 7.50195 99.1641 8.10742C98.8555 8.71289 98.4121 9.17773 97.834 9.50195C97.2598 9.82617 96.5898 
                       9.99219 95.8242 10H93.4629ZM94.5879 2.39453V9.08008H95.7715C96.6387 9.08008 97.3125 8.81055 97.793 8.27148C98.2773 7.73242 98.5195 6.96484
                        98.5195 5.96875V5.4707C98.5195 4.50195 98.291 3.75 97.834 3.21484C97.3809 2.67578 96.7363 2.40234 95.9004 2.39453H94.5879ZM3.45117 22.1172C2.5918 
                        22.1172 1.89258 21.8359 1.35352 21.2734C0.814453 20.707 0.544922 19.9512 0.544922 19.0059V18.8066C0.544922 18.1777 0.664062 17.6172 0.902344 
                        17.125C1.14453 16.6289 1.48047 16.2422 1.91016 15.9648C2.34375 15.6836 2.8125 15.543 3.31641 15.543C4.14062 15.543 4.78125 15.8145 5.23828
                         16.3574C5.69531 16.9004 5.92383 17.6777 5.92383 18.6895V19.1406H1.62891C1.64453 19.7656 1.82617 20.2715 2.17383 20.6582C2.52539 21.041 
                         2.9707 21.2324 3.50977 21.2324C3.89258 21.2324 4.2168 21.1543 4.48242 20.998C4.74805 20.8418 4.98047 20.6348 5.17969 20.377L5.8418 20.8926C5.31055 
                         21.709 4.51367 22.1172 3.45117 22.1172ZM3.31641 16.4336C2.87891 16.4336 2.51172 16.5938 2.21484 16.9141C1.91797 17.2305 1.73438 17.6758 1.66406
                          18.25H4.83984V18.168C4.80859 17.6172 4.66016 17.1914 4.39453 16.8906C4.12891 16.5859 3.76953 16.4336 3.31641 16.4336ZM10.248 16.6328C10.084 
                          16.6055 9.90625 16.5918 9.71484 16.5918C9.00391 16.5918 8.52148 16.8945 8.26758 17.5V22H7.18359V15.6602H8.23828L8.25586 16.3926C8.61133
                           15.8262 9.11523 15.543 9.76758 15.543C9.97852 15.543 10.1387 15.5703 10.248 15.625V16.6328ZM10.875 18.7773C10.875 17.7891 11.1035 17.0039 
                           11.5605 16.4219C12.0176 15.8359 12.623 15.543 13.377 15.543C14.1504 15.543 14.7539 15.8164 15.1875 16.3633L15.2402 15.6602H16.2305V21.8477C16.2305 
                           22.668 15.9863 23.3145 15.498 23.7871C15.0137 24.2598 14.3613 24.4961 13.541 24.4961C13.084 24.4961 12.6367 24.3984 12.1992 24.2031C11.7617
                            24.0078 11.4277 23.7402 11.1973 23.4004L11.7598 22.75C12.2246 23.3242 12.793 23.6113 13.4648 23.6113C13.9922 23.6113 14.4023 23.4629 14.6953 
                            23.166C14.9922 22.8691 15.1406 22.4512 15.1406 21.9121V21.3672C14.707 21.8672 14.1152 22.1172 13.3652 22.1172C12.623 22.1172 12.0215 21.8184 
                            11.5605 21.2207C11.1035 20.623 10.875 19.8086 10.875 18.7773ZM11.9648 18.9004C11.9648 19.6152 12.1113 20.1777 12.4043 20.5879C12.6973 20.9941 
                            13.1074 21.1973 13.6348 21.1973C14.3184 21.1973 14.8203 20.8867 15.1406 20.2656V17.3711C14.8086 16.7656 14.3105 16.4629 13.6465 16.4629C13.1191 
                            16.4629 12.707 16.668 12.4102 17.0781C12.1133 17.4883 11.9648 18.0957 11.9648 18.9004ZM21.7852 22C21.7227 21.875 21.6719 21.6523 21.6328 
                            21.332C21.1289 21.8555 20.5273 22.1172 19.8281 22.1172C19.2031 22.1172 18.6895 21.9414 18.2871 21.5898C17.8887 21.2344 17.6895 20.7852
                             17.6895 20.2422C17.6895 19.582 17.9395 19.0703 18.4395 18.707C18.9434 18.3398 19.6504 18.1562 20.5605 18.1562H21.6152V17.6582C21.6152 
                             17.2793 21.502 16.9785 21.2754 16.7559C21.0488 16.5293 20.7148 16.416 20.2734 16.416C19.8867 16.416 19.5625 16.5137 19.3008 16.709C19.0391
                              16.9043 18.9082 17.1406 18.9082 17.418H17.8184C17.8184 17.1016 17.9297 16.7969 18.1523 16.5039C18.3789 16.207 18.6836 15.9727 19.0664 
                              15.8008C19.4531 15.6289 19.877 15.543 20.3379 15.543C21.0684 15.543 21.6406 15.7266 22.0547 16.0938C22.4688 16.457 22.6836 16.959 22.6992
                               17.5996V20.5176C22.6992 21.0996 22.7734 21.5625 22.9219 21.9062V22H21.7852ZM19.9863 21.1738C20.3262 21.1738 20.6484 21.0859 20.9531
                                20.9102C21.2578 20.7344 21.4785 20.5059 21.6152 20.2246V18.9238H20.7656C19.4375 18.9238 18.7734 19.3125 18.7734 20.0898C18.7734 20.4297
                                 18.8867 20.6953 19.1133 20.8867C19.3398 21.0781 19.6309 21.1738 19.9863 21.1738ZM18.5684 13.9668C18.5684 13.791 18.6211 13.6445 18.7266
                                  13.5273C18.8359 13.4062 18.9961 13.3457 19.207 13.3457C19.418 13.3457 19.5781 13.4062 19.6875 13.5273C19.7969 13.6445 19.8516 13.791
                                   19.8516 13.9668C19.8516 14.1426 19.7969 14.2891 19.6875 14.4062C19.5781 14.5234 19.418 14.582 19.207 14.582C18.9961 14.582 18.8359 
                                   14.5234 18.7266 14.4062C18.6211 14.2891 18.5684 14.1426 18.5684 13.9668ZM21.0879 13.9785C21.0879 13.8027 21.1406 13.6543 21.2461 
                                   13.5332C21.3555 13.4121 21.5156 13.3516 21.7266 13.3516C21.9375 13.3516 22.0977 13.4121 22.207 13.5332C22.3164 13.6543 22.3711 13.8027
                                    22.3711 13.9785C22.3711 14.1543 22.3164 14.3008 22.207 14.418C22.0977 14.5352 21.9375 14.5938 21.7266 14.5938C21.5156 14.5938 21.3555 
                                    14.5352 21.2461 14.418C21.1406 14.3008 21.0879 14.1543 21.0879 13.9785ZM25.4238 15.6602L25.459 16.457C25.9434 15.8477 26.5762 15.543 
                                    27.3574 15.543C28.6973 15.543 29.373 16.2988 29.3848 17.8105V22H28.3008V17.8047C28.2969 17.3477 28.1914 17.0098 27.9844 16.791C27.7812 
                                    16.5723 27.4629 16.4629 27.0293 16.4629C26.6777 16.4629 26.3691 16.5566 26.1035 16.7441C25.8379 16.9316 25.6309 17.1777 25.4824 
                                    17.4824V22H24.3984V15.6602H25.4238ZM32.0391 21.1152H35.748V22H30.7148V21.2031L34.2129 16.5566H30.7676V15.6602H35.5723V16.4277L32.0391
                                     21.1152ZM39.5098 22.1172C38.6504 22.1172 37.9512 21.8359 37.4121 21.2734C36.873 20.707 36.6035 19.9512 36.6035 19.0059V18.8066C36.6035 
                                     18.1777 36.7227 17.6172 36.9609 17.125C37.2031 16.6289 37.5391 16.2422 37.9688 15.9648C38.4023 15.6836 38.8711 15.543 39.375 
                                     15.543C40.1992 15.543 40.8398 15.8145 41.2969 16.3574C41.7539 16.9004 41.9824 17.6777 41.9824 18.6895V19.1406H37.6875C37.7031
                                      19.7656 37.8848 20.2715 38.2324 20.6582C38.584 21.041 39.0293 21.2324 39.5684 21.2324C39.9512 21.2324 40.2754 21.1543 40.541 
                                      20.998C40.8066 20.8418 41.0391 20.6348 41.2383 20.377L41.9004 20.8926C41.3691 21.709 40.5723 22.1172 39.5098 22.1172ZM39.375 
                                      16.4336C38.9375 16.4336 38.5703 16.5938 38.2734 16.9141C37.9766 17.2305 37.793 17.6758 37.7227 18.25H40.8984V18.168C40.8672 
                                      17.6172 40.7188 17.1914 40.4531 16.8906C40.1875 16.5859 39.8281 16.4336 39.375 16.4336ZM44.2676 15.6602L44.3027 16.457C44.7871 
                                      15.8477 45.4199 15.543 46.2012 15.543C47.541 15.543 48.2168 16.2988 48.2285 17.8105V22H47.1445V17.8047C47.1406 17.3477 47.0352
                                       17.0098 46.8281 16.791C46.625 16.5723 46.3066 16.4629 45.873 16.4629C45.5215 16.4629 45.2129 16.5566 44.9473 16.7441C44.6816 
                                       16.9316 44.4746 17.1777 44.3262 17.4824V22H43.2422V15.6602H44.2676Z"
                      transform="translate(34 2)"
                      fill="black" fillOpacity={'0.2'}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z"
                      transform="translate(5 9)" fill={this.props.color}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7998 2H16C17.0996 2 18 2.8999 18 4V8.83594L16 6.94531V4H14V7H4V4H2V20H5.2002V22H2C0.900391
  22 0 21.1001 0 20V4C0 2.8999 0.900391 2 2 2H6.2002C6.59961 0.799805 7.7002 0 9 0C10.2998 0 11.4004 0.799805 11.7998 2ZM10 3C10 2.3999 9.59961 
  2 9 2C8.40039 2 8 2.3999 8 3C8 3.6001 8.40039 4 9 4C9.59961 4 10 3.6001 10 3Z" fill={this.props.color}/>

            </MaterialUI.SvgIcon>
        );
    }


}

export const MaloMeloErgaenzenIcon = (MaloMeloErgaenzen);
