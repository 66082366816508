import { IAbleseTyp, IDeviceType, IFile, IFileInfo, IProduct } from '../../../../common/interfaces/interface';
import { SettingActionTypes } from '../types/settings.types';

export const saveSettingsEmails = (emails: Array<any>) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_EMAILS,
        payload: emails
    });
};

export const saveProductList = (list: Array<IProduct>) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_Product_LIST,
        payload: list
    });
};


export const saveServices = (services: Array<any>) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_SERVICES,
        payload: services
    });
};

export const saveSelectedService = (service: any) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_SELECTED_SERVICE,
        payload: service
    });
};

export const saveDeviceTypeList = (services: Array<IDeviceType>) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_DEVICE_TYPE,
        payload: services
    });
};

export const saveSelectedDeviceType = (item: IDeviceType) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_SELECTED_DEVICE_TYPE,
        payload: item
    });
};

export const saveAblesTyp = (item: Array<IAbleseTyp>) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_ABLESE_TYP,
        payload: item
    });
};

export const saveSelectedAblesTyp = (item: IAbleseTyp) => {
    return dispatch => dispatch({
        type: SettingActionTypes.SAVE_SELECTED_ABLESE_TYP,
        payload: item
    });
};

export const saveEmailFileInfo = (item: IFileInfo) => {
    return dispatch => dispatch({
        type: SettingActionTypes.ADD_EMAIL_INFO,
        payload: item
    });
};

export const saveEmailFile = (item: IFile) => {
    return dispatch => dispatch({
        type: SettingActionTypes.ADD_EMAIL_FILE,
        payload: item
    });
};