import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../common/components/actionbar';
import { ISLActionBar } from '../../../common/components/actionbar/sl-actionbar.component';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { AusbauauftragIcon } from '../../../common/components/svgIcons/Ausbauauftrag';
import { GeraetewechselIcon } from '../../../common/components/svgIcons/Geraetewechsel';
import { GeraetezuordnenIcon } from '../../../common/components/svgIcons/Geraetezuordnen';
import { StoerungIcon } from '../../../common/components/svgIcons/Stoerung';
import { BasicTable, IMuiTableColumn } from '../../../common/components/table/mui-table.component';
import {
    IAbleseTyp,
    IAblesung,
    IAuftrag,
    IDevice,
    IDeviceType,
    IMeterPlace,
    IProduct,
} from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined, IsoformatToGermanDate } from '../../../common/utils';
import { isUserRole } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../materialUI/MaterialUIIcons';

import { AuftragType, Roles } from '../../../model/enums';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { sbColors } from '../../../theme/app.colors';
import { AuftragActions } from '../../auftrag/redux';
import { SLMeterPanelInfo } from '../../meterpanels/components/meterpanel-info/meterpanel-info.component';
import { MeterPanelsActions } from '../../meterpanels/redux';
import { IMeterPanelState } from '../../meterpanels/redux/reducers/meterpanels.reducer';
import { SLDeviceInfo } from '../components/devices/device-info.component';
import { SlDeviceEdit } from '../components/devices/sl-device.edit';
import { SlRemoveDeviceForm } from '../components/form/sl-remove-device.form';
import { SlAuftragComponent } from '../components/form/slAuftragComponent';
import { DevicesActions } from '../redux';
import { IDevicesState } from '../redux/reducers/devices.reducer';
import { buildTableDataAblesungen } from '../services/devices.services';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
        },

        divider: {},
        marginRight: {
            marginRight: 24,
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 20,
            width: '100%',
            overflow: 'hidden',
        },

        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(3),
        },
        tabContainer: {
            height: '100vh',
            // backgroundColor: sbColors.white,
        },
        tabContent: {
            overflowY: 'auto',
            height: 'calc(100vh - 260px)',
            paddingTop: 24,
            paddingBottom: 24,
            paddingRight: 40,
            paddingLeft: 40,
        },

        responsiveContent: {
            [theme.breakpoints.up('lg')]: {
                marginTop: 20,
                marginLeft: '10%',
                marginRight: '10%',
                marginBottom: 20,
            },
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        InputContainer: {
            display: 'flex',
            width: '100%',
        },
        smallInput: {
            width: '30%',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        inputInvisible: {
            marginTop: 0,
            padding: 10,
            width: '100%',
            height: 20,
        },
        block: {
            marginTop: 20,
        },
    });

interface IKabDeviceDetailsProps extends WithStyles<typeof styles> {
    match: any;
    addReadingForm?: IForm;
    editReadingForm?: IForm;
    auftragForm?: IForm;
    deviceForm?: IForm;
    removeDeviceForm?: IForm;
    facilityEmail?: any;
    deviceState: IDevicesState;
    location: any;
    meterPanelState: IMeterPanelState;
    loadingList: Array<string>;
    setError: (error: IError) => void;
    error: IError;
    deviceTypeList: Array<IDeviceType>;
    productList?: Array<IProduct>;
    ableseTypeList?: Array<IAbleseTyp>;
    getDevices: (kabID: string, aID: string) => void;
    fetchDeviceData: (kabID: string, aID: string, zID: string, gID: string) => void;
    saveSelectedDevice: (device: IDevice) => void;
    getDeviceById: (kabID: string, aID: string, deviceID: string) => void;
    updateDevices?: (kabID: string, aID: string, payload: Array<IDevice>) => void;
    addMeterReading?: (kabID: string, aID: string, form, selectedDevices) => void;
    handleRemovingDevice?: (kabID: string, aID: string, device: Array<any>) => void;
    postAuftrag?: (kabID: string, aID: string, payload: any) => { ID: string };
    sendEmailAuftrag?: (
        payload: IAuftrag,
        auftragId: string,
        meterplaces: Array<IMeterPlace>,
        deviceMeterpanels: Array<{ device: IDevice; meterplace: IMeterPlace }>
    ) => void;
}

interface IState {
    openDeviceEditModal: boolean;
    showRemoveDeviceWarning: boolean;
    showOrderDeviceModal: boolean;
    showRemoveDeviceModal: boolean;
    selectedAblesung: IAblesung;
    openDeleteAlertAblesung: boolean;
}

class KabDeviceDetails extends React.Component<IKabDeviceDetailsProps, IState> {
    private kabID: string = null;
    private aID: string = null;
    private zID: string = null;
    private gID: string = null;
    private readonly masterDataActionBarItems: Array<ISLActionBar>;

    private orderTitle: string;
    private orderType: AuftragType;
    private readonly readingTableColumns: Array<IMuiTableColumn> = [];

    constructor(props: IKabDeviceDetailsProps) {
        super(props);
        this.kabID = props.match.params.kabID;
        this.aID = props.match.params.aID;
        this.zID = props.match.params.zID;
        this.gID = props.match.params.gID;

        this.masterDataActionBarItems = [
            {
                label: deTranslator.global.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () => this.setState({ openDeviceEditModal: true }),
            },
            {
                label: deTranslator.global.changeDevice,
                icon: <GeraetewechselIcon color={sbColors.orange} />,
                action: () => this.handleChangesDevices(),
            },
            /*{
                label: deTranslator.global.faults,
                icon: <StoerungIcon color={sbColors.orange} />,
                action: () => this.handleDeviceFailure(),
            },*/
            {
                label: deTranslator.global.removeDeviceOrder,
                icon: <AusbauauftragIcon color={sbColors.orange} />,
                action: () => this.handleRemoveDeviceOrder(),
            },
            {
                label: deTranslator.global.deviceRemovalConfirmation,
                icon: <GeraetezuordnenIcon color={sbColors.orange} />,
                action: () => this.handleRemoveDevicesConfirmation(),
            },
            {
                label: deTranslator.global.doneFaults,
                icon: <StoerungIcon color={sbColors.orange} />,
                action: () => '',
                disable: true,
            },
        ];

        this.readingTableColumns = [
            {
                title: 'Datum',
                field: 'Ablesedatum',
                defaultSort: 'asc',
                render: rowData => IsoformatToGermanDate(rowData.Ablesedatum),
            },
            {
                title: `Stand HT (${deTranslator.einheit.kilowatt})`,
                field: 'ZaehlerstandHT',
                emptyValue: '0',
            },
            {
                title: `Verbrauch HT (${deTranslator.einheit.kilowatt})`,
                field: 'VerbrauchHT',
                emptyValue: '0',
            },
            {
                title: `Korrigierter Verbrauch (${deTranslator.einheit.kilowatt})`,
                field: 'KorrigierterVerbrauch',
                emptyValue: ' ',
            },
            {
                title: `Wandlerfaktor`,
                field: 'Wandlerfaktor',
                emptyValue: ' ',
            },
            { title: 'Status', field: 'Status' },
            { title: 'Ablesegrund', field: 'Ablesegrund' },
        ];
        this.state = {
            openDeviceEditModal: false,
            showRemoveDeviceWarning: false,
            showOrderDeviceModal: false,
            showRemoveDeviceModal: false,
            selectedAblesung: null,
            openDeleteAlertAblesung: false,
        };
    }

    /* buildRowActions() {
         if (isUserRole(Roles.Admin)) {
             this.rowActions = [
                 (data) => {
                     return data.Ablesegrund !== 'Einbau' && {
                         icon: () => <MaterialUIIcons.DeleteOutline color={'secondary'}/>,
                         tooltip: 'Löschen',
                         onClick: this.deleteAblesungModal,

                     };
                 }

             ];
         }
     }*/

    public async componentDidMount() {
        // fetch bodyData from server
        this.kabID = this.props.match.params.kabID;
        this.aID = this.props.match.params.aID;
        this.zID = this.props.match.params.zID;
        this.gID = this.props.match.params.gID;
        await this.props.fetchDeviceData(this.kabID, this.aID, this.zID, this.gID);
        await this.reloadSelectedDevice();
    }

    public async componentDidUpdate(prevProps) {
        if (
            this.props.match.params.kabID !== prevProps.match.params.kabID ||
            this.props.match.params.aID !== prevProps.match.params.aID ||
            this.props.match.params.gID !== prevProps.match.params.gID
        ) {
            this.kabID = this.props.match.params.kabID;
            this.aID = this.props.match.params.aID;
            this.zID = this.props.match.params.zID;
            this.gID = this.props.match.params.gID;
            await this.props.fetchDeviceData(this.kabID, this.aID, this.zID, this.gID);
            await this.reloadSelectedDevice();
        }
    }

    private async reloadSelectedDevice() {
        await this.props.getDeviceById(this.kabID, this.aID, this.gID);
    }

    private handleRemoveDevicesConfirmation = () => {
        const { selectedDevice } = this.props.deviceState;
        if (selectedDevice.Ausbaudatum && selectedDevice.Ausbaudatum !== '') {
            this.setState({ showRemoveDeviceWarning: true });
        } else {
            this.setState({ showRemoveDeviceModal: true });
        }
    };

    private handleUpdateDevice = async () => {
        this.setState({ openDeviceEditModal: false });
        await this.props.updateDevices(this.kabID, this.aID, [this.props.deviceForm.formValue]);
        await this.reloadSelectedDevice();
    };

    private doDevicesOrder = async () => {
        this.setState({ showOrderDeviceModal: false });
        const auftrag: any = Object.assign(
            {},
            {
                Type: this.orderType,
                DeviceIds: [this.props.deviceState.selectedDevice.ID],
            },
            { ...this.props.auftragForm.formValue }
        );
        const { ID } = await this.props.postAuftrag(this.kabID, this.aID, auftrag);

        ID &&
            this.props.sendEmailAuftrag(
                auftrag,
                ID,
                [],
                [
                    {
                        device: this.props.deviceState.selectedDevice,
                        meterplace: this.props.meterPanelState.selectedMeterPanel,
                    },
                ]
            );
    };

    private handleChangesDevices = () => {
        const { selectedDevice } = this.props.deviceState;
        if (selectedDevice) {
            this.orderType = AuftragType.Wechselauftrag;
            this.orderTitle = deTranslator.global.changeDeviceOrder;
            if (selectedDevice.Ausbaudatum && selectedDevice.Ausbaudatum !== '') {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };

    private handleDeviceFailure = () => {
        const { selectedDevice } = this.props.deviceState;
        if (selectedDevice) {
            this.orderType = AuftragType.Entstoerungsauftrag;
            this.orderTitle = deTranslator.global.faults;
            if (selectedDevice.Ausbaudatum && selectedDevice.Ausbaudatum !== '') {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };

    private handleRemoveDeviceOrder = () => {
        const { selectedDevice } = this.props.deviceState;
        if (selectedDevice) {
            this.orderType = AuftragType.Ausbauauftrag;
            this.orderTitle = deTranslator.global.removeDeviceOrder;

            if (selectedDevice.Ausbaudatum && selectedDevice.Ausbaudatum !== '') {
                this.setState({ showRemoveDeviceWarning: true });
            } else {
                this.setState({ showOrderDeviceModal: true });
            }
        }
    };

    private removeDeviceOrder = async () => {
        this.setState({ showRemoveDeviceModal: false });
        await this.props.handleRemovingDevice(
            this.kabID,
            this.aID,
            this.props.removeDeviceForm.formValue.devices
        );
        await this.reloadSelectedDevice();
    };

    private handleDissmissError = () => {
        this.props.setError(null);
    };

    private renderPath = () => {
        if (this.props.location.pathname && this.props.deviceState.selectedDevice) {
            const paths = this.props.location.pathname.split('/');
            paths[paths.length - 1] = this.props.deviceState.selectedDevice.GeraeteID;
            return { ...this.props.location, pathname: paths.join('/') };
        }
        return '';
    };

    private renderAblesung = () => {
        const { selectedDevice } = this.props.deviceState;
        return (
            <div className={this.props.classes.block}>
                <MaterialUI.Divider className={this.props.classes.divider} />
                <BasicTable
                    columns={this.readingTableColumns}
                    title={`Zählerstände `}
                    options={{ toolbar: true, actionsColumnIndex: -1 }}
                    data={buildTableDataAblesungen(selectedDevice, this.props.ableseTypeList)}
                />
            </div>
        );
    };

    private renderMeterPanelInfo = () => {
        return (
            this.props.meterPanelState.selectedMeterPanel && (
                <SLMeterPanelInfo meterPanel={this.props.meterPanelState.selectedMeterPanel} />
            )
        );
    };

    private renderDeviceInfo = () => {
        return (
            this.props.deviceState.selectedDevice && (
                <div className={this.props.classes.block}>
                    <SLDeviceInfo device={this.props.deviceState.selectedDevice} />
                </div>
            )
        );
    };

    private renderMasterData() {
        if (
            this.props.deviceState.selectedDevice &&
            this.props.meterPanelState.selectedMeterPanel
        ) {
            return (
                <div>
                    {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                        <SlActionBar items={this.masterDataActionBarItems} />
                    )}
                    <div className={this.props.classes.tabContent}>
                        {this.renderMeterPanelInfo()}
                        {this.renderDeviceInfo()}
                        {this.renderAblesung()}
                    </div>
                </div>
            );
        }
        // return (<SLNotFound/>);
        return null;
    }

    private renderModal() {
        return (
            <div>
                <SlAlertComponent
                    open={this.state.showRemoveDeviceWarning}
                    showCancelButton={false}
                    handleOK={() => this.setState({ showRemoveDeviceWarning: false })}
                >
                    <ErrorComponent error={{ message: deTranslator.error.removeDevice }} />
                </SlAlertComponent>
                <SLModal
                    title={this.orderTitle}
                    open={this.state.showOrderDeviceModal}
                    onOkButtonClick={this.doDevicesOrder}
                    OkButtonDisabled={this.props.auftragForm.invalid}
                    onClose={() => {
                        this.setState({ showOrderDeviceModal: false });
                    }}
                >
                    <SlAuftragComponent
                        orderType={this.orderType}
                        facilityEmail={this.props.facilityEmail}
                        title={this.orderTitle}
                    />
                </SLModal>

                <SLModal
                    title={deTranslator.device.edit}
                    onClose={() => this.setState({ openDeviceEditModal: false })}
                    hideClose={true}
                    open={this.state.openDeviceEditModal}
                    onOkButtonClick={this.handleUpdateDevice}
                    OkButtonDisabled={this.props.deviceForm.invalid}
                >
                    <SlDeviceEdit
                        device={this.props.deviceState.selectedDevice}
                        productList={this.props.productList}
                        deviceTypeList={this.props.deviceTypeList}
                    />
                </SLModal>

                <SLModal
                    title={'Rückmeldung Geräteausbau'}
                    open={this.state.showRemoveDeviceModal}
                    onOkButtonClick={this.removeDeviceOrder}
                    OkButtonDisabled={this.props.removeDeviceForm.invalid}
                    onClose={() => {
                        this.setState({ showRemoveDeviceModal: false });
                    }}
                >
                    <SlRemoveDeviceForm devices={[this.props.deviceState.selectedDevice]} />
                </SLModal>
                <SlAlertComponent
                    open={this.state.showRemoveDeviceWarning}
                    showCancelButton={false}
                    handleOK={() => this.setState({ showRemoveDeviceWarning: false })}
                >
                    <ErrorComponent error={{ message: deTranslator.error.removeDevice }} />
                </SlAlertComponent>
            </div>
        );
    }

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader currentLocation={this.renderPath()} tabs={[]} />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    {this.renderMasterData()}
                    {this.renderModal()}
                </div>
                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDissmissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        auftragForm: {
            formValue: getFormValues('AuftragForm')(state),
            invalid: isInvalid('AuftragForm')(state),
        },
        addReadingForm: {
            formValue: getFormValues('AblesungHinzufuegenForm')(state),
            invalid: isInvalid('AblesungHinzufuegenForm')(state),
        },
        editReadingForm: {
            formValue: getFormValues('AblesungEditForm')(state),
            invalid: isInvalid('AblesungEditForm')(state),
        },
        deviceForm: {
            formValue: getFormValues('DeviceEditForm')(state),
            invalid: isInvalid('DeviceEditForm')(state),
        },
        removeDeviceForm: {
            formValue: getFormValues('SlRemoveDeviceForm')(state),
            invalid: isInvalid('SlRemoveDeviceForm')(state),
        },
        customerState: state.customerState,
        facilityEmail: state.facilityState.selectedFacilityEmail,
        meterPanelState: state.meterPanelState,
        deviceState: state.deviceState,
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
        deviceTypeList: state.settingState.deviceTypeList,
        productList: state.settingState.productManagementList,
        ableseTypeList: state.settingState.ableseTypeList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(
        Object.assign({}, MeterPanelsActions, GlobalActions, DevicesActions, AuftragActions),
        dispatch
    );
};

export const DeviceDataPage = withRouter(
    connect<IKabDeviceDetailsProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<IKabDeviceDetailsProps>(MaterialUI.withStyles(styles)(KabDeviceDetails))
);
