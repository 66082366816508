import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, touch } from 'redux-form';
import { DeleteButton } from '../../../../common/components/buttons';
import {
    renderItemForSelectBox,
    SlSelectInputComponent,
} from '../../../../common/components/forms';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { IMeterPlace } from '../../../../common/interfaces/interface';
import { FormValidator } from '../../../../common/validators/form.validator';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import * as Enumarations from '../../../../model/enums';
import { Montage } from '../../../../model/enums';
import { renderOptionsForSelectBox } from '../../../../common/components/forms/select-input.component';

const selector = formValueSelector('MeterPanelForm');

const styles = () =>
    MaterialUI.createStyles({
        container: {
            display: 'flex' as 'flex',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
        item: {},
    });

export interface IMeterPanelProps extends WithStyles<typeof styles> {
    onChange: (value, id, index) => void;
    onClickActionButton: (index) => void;
    value?: IMeterPlace;
    error?: boolean;
    index: string;
    onKeyDownPressAction: () => void;
    dispatch?: any;
}

class MeterPanel extends React.Component<IMeterPanelProps, {}> {
    private resetFieldValue = () => {
        this.props.dispatch(touch('MeterPanelForm', `Zaehlplaetze[${this.props.index}].WohnungNr`));
    };

    public hasRowValue = (): boolean => {
        return (
            this.props['WohnungNr'] ||
            this.props['Gebaeude'] ||
            this.props['Stockwerk'] ||
            this.props['Lage'] ||
            this.props['Montageart'] ||
            this.props['GeraetTyp']
        );
    };

    public render() {
        return (
            <div className={this.props.classes.container}>
                <div className={this.props.classes.item}>
                    <Field
                        name={`${this.props.value}.WohnungNr`}
                        component={SlTextInputComponent}
                        fullWith={true}
                        required={true}
                        label={'Wohnungs-Nr'}
                        touched={true}
                        error={true}
                        style={{ width: '90%' }}
                        validate={this.hasRowValue() ? [FormValidator.required] : []}
                    />
                </div>
                <div className={this.props.classes.item}>
                    <Field
                        name={`${this.props.value}.Gebaeude`}
                        label={'Gebaeude'}
                        component={SlTextInputComponent}
                        fullWith={true}
                        onChange={this.resetFieldValue}
                        required={false}
                        style={{ width: '90%' }}
                    />
                </div>
                <div className={this.props.classes.item}>
                    <Field
                        name={`${this.props.value}.Stockwerk`}
                        label={'Stockwerk'}
                        component={SlTextInputComponent}
                        fullWith={true}
                        onChange={this.resetFieldValue}
                        required={false}
                        style={{ width: '90%' }}
                    />
                </div>
                <div className={this.props.classes.item}>
                    <Field
                        name={`${this.props.value}.Lage`}
                        label={'Lage Zählerplatz'}
                        component={SlTextInputComponent}
                        fullWith={true}
                        onChange={this.resetFieldValue}
                        required={false}
                        style={{ width: '90%' }}
                    />
                </div>
                <div style={{ width: 200 }}>
                    <Field
                        component={SlSelectInputComponent}
                        style={{ width: '90%' }}
                        label={'Grundtyp'}
                        required={false}
                        name={`${this.props.value}.GeraetTyp`}
                    >
                        {renderOptionsForSelectBox(Enumarations.SB_GeraetTyp_Enum)}
                    </Field>
                </div>
                <div style={{ width: 200 }}>
                    <Field
                        component={SlSelectInputComponent}
                        style={{ width: '90%' }}
                        label={'Montageart'}
                        required={true}
                        validate={this.hasRowValue() ? [FormValidator.required] : []}
                        name={`${this.props.value}.Montageart`}
                    >
                        {renderItemForSelectBox(Montage)}
                    </Field>
                </div>

                <div style={{ paddingTop: 0 }}>
                    <DeleteButton
                        onClick={() => {
                            this.props.onClickActionButton(this.props.index);
                        }}
                    />
                </div>
            </div>
        );
    }
}

export const MeterPanelComponent = MaterialUI.withStyles(styles)(MeterPanel);

export const SLMeterPanelComponent = connect((state, props) => {
    const WohnungNr = selector(state, `${props.value}.WohnungNr`);
    const Gebaeude = selector(state, `${props.value}.Gebaeude`);
    const Stockwerk = selector(state, `${props.value}.Stockwerk`);
    const Lage = selector(state, `${props.value}.Lage`);
    const Montageart = selector(state, `${props.value}.Montageart`);
    const GeraetTyp = selector(state, `${props.value}.GeraetTyp`);

    return { WohnungNr, Gebaeude, Stockwerk, Lage, Montageart, GeraetTyp };
})(MeterPanelComponent);
