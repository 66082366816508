import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { SlSelectInputComponent } from '../../../../common/components/forms';
import { IMeterPlace } from '../../../../common/interfaces/interface';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { SlTextInputComponent } from '../../../../common/components/forms/text-field.component';
import { FormValidator } from '../../../../common/validators/form.validator';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import * as Enumarations from '../../../../model/enums';
import { GeraetTypEnum, Montage, NutzungstypEnum } from '../../../../model/enums';
import { renderOptionsForSelectBox } from '../../../../common/components/forms/select-input.component';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        item: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 30,
        },
        labelContainer: {
            display: 'flex',
            flexDirection: 'row',
        },

        label: {
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            width: '100%',
            overflow: 'hidden',
        },
        input: {
            marginTop: 0,
            background: 'whitesmoke',
            padding: 10,
            width: '100%',
            height: 20,
        },
        mediumInput: {},
        smallInput: {},
        date: {
            width: '30%',
            textAlign: 'left',
            marginTop: '0px',
            paddingRight: 10,
            paddingBottom: 5,
            paddingTop: 10,
            overflow: 'hidden',
        },
        tinyInput: {
            width: '15%',
        },
        marginRight: {
            marginRight: 24,
        },
        paddingLeft: {
            paddingLeft: 10,
        },
        divider: {
            margin: '15px',
        },
        responsiveContent: {
            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                margin: 'auto',
                width: '70%',
            },
            [theme.breakpoints.up('lg')]: {
                margin: 'auto',
                width: '70%',
            },
        },
    });

export interface IMeterPanelEditProps extends WithStyles<typeof styles> {
    readonly?: boolean;
    meterPanel?: IMeterPlace;
    initialize: (meterPanel: IMeterPlace) => void;
    appType?: 'SLIM' | 'TECHNIK';
}

class MeterPanelEdit extends React.Component<IMeterPanelEditProps, {}> {
    constructor(props: IMeterPanelEditProps) {
        super(props);

        this.props.initialize({
            ...this.props.meterPanel,
            Montageart:
                this.props.meterPanel.Montageart === Montage.DreiPunkt
                    ? 'DreiPunkt'
                    : this.props.meterPanel.Montageart,
        });
    }

    public render() {
        return (
            <div style={{ padding: 15 }}>
                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="WohnungNr"
                            label={deTranslator.meterPanel.apartmentNr}
                            component={SlTextInputComponent}
                            required={true}
                            className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight} `}
                            readonly={this.props.readonly}
                            validate={[FormValidator.required]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="Gebaeude"
                            label={deTranslator.meterPanel.building}
                            readonly={this.props.readonly}
                            options={{ fullWidth: true, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.tinyInput} ${this.props.classes.marginRight} `}
                            component={SlTextInputComponent}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="Melo"
                            component={SlTextInputComponent}
                            required={false}
                            label={deTranslator.meterPanel.Melo}
                            readonly={this.props.readonly}
                            className={`${this.props.classes.input} ${this.props.classes.tinyInput} ${this.props.classes.marginRight}`}
                            validate={[FormValidator.melo]}
                            options={{ fullWidth: true, shrink: true }}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="Stockwerk"
                            required={false}
                            label={deTranslator.meterPanel.floor}
                            readonly={this.props.readonly}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.mediumInput} ${this.props.classes.marginRight}`}
                            validate={[]}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="Lage"
                            readonly={this.props.readonly}
                            label={deTranslator.meterPanel.location}
                            options={{ fullWidth: true, shrink: true }}
                            component={SlTextInputComponent}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            // validate={[FormValidator.required]}
                            required={false}
                        />
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item={true} xs={true} className={this.props.classes.item}>
                        <Field
                            name="Malo"
                            readonly={this.props.readonly}
                            label={deTranslator.meterPanel.Malo}
                            options={{ fullWidth: true, shrink: true }}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            component={SlTextInputComponent}
                            validate={[FormValidator.malo]}
                        />
                    </MaterialUI.Grid>
                </MaterialUI.Grid>

                <MaterialUI.Grid container={true} spacing={3} direction={'row'}>
                    <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                        <Field
                            name="Montageart"
                            component={SlSelectInputComponent}
                            label={deTranslator.meterPanel.Montage}
                            readonly={this.props.readonly}
                            validate={[]}
                            className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                            options={{ fullWidth: false, shrink: true }}
                        >
                            {renderOptionsForSelectBox(Montage)}
                        </Field>
                    </MaterialUI.Grid>
                    {this.props.appType === 'SLIM' && (
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                name="GeraetTyp"
                                component={SlSelectInputComponent}
                                label={'Gerätetyp'}
                                readonly={this.props.readonly}
                                validate={[]}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                options={{ fullWidth: false, shrink: true }}
                            >
                                {renderOptionsForSelectBox(GeraetTypEnum)}
                            </Field>
                        </MaterialUI.Grid>
                    )}
                    {this.props.appType === 'SLIM' && (
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                name="Nutzungstyp"
                                component={SlSelectInputComponent}
                                label={'Nutzungstyp'}
                                readonly={this.props.readonly}
                                required={true}
                                validate={[FormValidator.required]}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                options={{ fullWidth: false, shrink: true }}
                            >
                                {renderOptionsForSelectBox(NutzungstypEnum)}
                            </Field>
                        </MaterialUI.Grid>
                    )}
                    {!this.props.appType && (
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                name="Verbrauchsprognose"
                                readonly={this.props.readonly}
                                label={deTranslator.meterPanel.Verbrauchsprognose}
                                options={{ fullWidth: true, shrink: true }}
                                className={`${this.props.classes.input} ${this.props.classes.smallInput} ${this.props.classes.marginRight}`}
                                component={SlTextInputComponent}
                                validate={[FormValidator.isNumber]}
                            />
                        </MaterialUI.Grid>
                    )}
                    {!this.props.appType && (
                        <MaterialUI.Grid item={true} xs={4} className={this.props.classes.item}>
                            <Field
                                component={SlSelectInputComponent}
                                style={{ width: '100%' }}
                                label={'Grundtyp'}
                                required={false}
                                name={`GeraetTyp`}
                            >
                                {renderOptionsForSelectBox(Enumarations.SB_GeraetTyp_Enum)}
                            </Field>
                        </MaterialUI.Grid>
                    )}
                </MaterialUI.Grid>
            </div>
        );
    }
}

export const MeterPanelEditComponent = MaterialUI.withStyles(styles)(MeterPanelEdit);

export const SLMeterPanelEditComponent = reduxForm({
    form: 'MeterPanelEditForm',
})(MeterPanelEditComponent);
