import { WithStyles } from '@material-ui/styles';
import * as jwtDecode from 'jwt-decode';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { LoadingComponent } from '../../../common/components/buttons';
import { GlobalActions } from '../../../common/redux';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IReduxState } from '../../../model/redux-state';

import { User } from '../../../model/user.model';
import { history } from '../../../store';
import { LoginActions } from '../redux';
import { TokenService } from '../services/token.service';

const styles = () => MaterialUI.createStyles({});

interface IAuthPageProps extends WithStyles<typeof styles> {
    loginSave: (user: User) => void;
}

interface IState {
    token: any
}

class AuthComponent extends React.Component<IAuthPageProps, IState> {


    constructor(props: IAuthPageProps) {
        super(props);
        this.state = {token: null};
        this.getToken().then();
    };

    private getToken = async () => {
        const token = await TokenService.fetchToken();
        if (token && token.id_token) {
            TokenService.saveToken(token);
            this.props.loginSave(new User(jwtDecode(token.id_token)));
            history.push('/kabs');
        } else {
            this.props.loginSave(null);
            history.push('/login');
        }
    };


    public render() {
        return (<LoadingComponent
            containerStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }} size={60}/>);


    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        loginState: state.loginState
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(Object.assign({}, LoginActions, GlobalActions), dispatch);
};

export const AuthPage = withRouter(connect<IAuthPageProps, any, any>(mapStateToProps, mapDispatchToProps)(MaterialUI.withStyles(styles)(AuthComponent)));