import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getFormValues, isInvalid } from 'redux-form';
import { deTranslator } from '../../../assets/i18n/deTranslator';
import { LoadingComponent } from '../../../common/components/buttons';
import { ErrorComponent } from '../../../common/components/error';
import { SlHeader } from '../../../common/components/header';
import { SlAlertComponent, SLModal } from '../../../common/components/modal';
import { SlSnackbarComponent } from '../../../common/components/snackbar/sl-snackbar.component';
import { IAbleseTyp, IDeviceType, IFile, IFileInfo, IProduct } from '../../../common/interfaces/interface';
import { GlobalActions } from '../../../common/redux';
import { IError, ISnackBar } from '../../../common/redux/reducers/global.reducer';
import { isNullOrUndefined } from '../../../common/utils';
import { convertBase64toBlob } from '../../../common/utils/Functions';
import * as MaterialUI from '../../../materialUI/MaterialUI';
import { IForm } from '../../../model/interfaces/IForm';
import { IReduxState } from '../../../model/redux-state';
import { sbColors } from '../../../theme/app.colors';
import { SLAblesetypComponent } from '../components/ablese-typ/ablesetyp.component';
import { SlAblesetypForm } from '../components/ablese-typ/ablesetyp.form';
import { DeviceTypeManagementComponent } from '../components/device-type/device-type.component';
import { FacilityEmail } from '../components/facility-email/facility-email.component';
import {
    FacilityServiceManagementComponent,
} from '../components/facility-service-management/facility-service-management.component';
import { SLGeraeteSettingComponent } from '../components/geraet-settings/geraete-settings.component';
import { SlAddProduct } from '../components/product-management/add-product.component';
import { SLProductManagement } from '../components/product-management/product-management.component';
import { SettingsActions } from '../redux';
import { SLDashboardNews } from '../components/dasboardNews/dashboardNews.component';
import { SLWebinar } from '../components/webinar/Webinar.component';
import { SlimAuftragSettings } from '../components/slim-auftrag/slimAuftrag-settings';
import { SlimActions } from '../../slim/redux';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            height: '100vh',
            backgroundColor: sbColors.whiteSmoke,
        },
        content: {
            flexGrow: 1,
            overflowX: 'hidden',
            overflowY: 'hidden',
            minWidth: 0,
            paddingTop: theme.spacing(3),
        },
        emailContainer: {
            display: 'flex',
        },
        responsiveContent: {
            height: 'calc(100vh - 220px)',
            overflowX: 'hidden',
            backgroundColor: sbColors.white,
            padding: 24,
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                margin: 'auto',
            },
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                margin: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: 'auto',
            },
        },
    });

export interface ISettingsContainerProps extends WithStyles<typeof styles> {
    location: any;
    error: IError;
    facilityEmailForm?: IForm;
    addProductForm?: IForm;
    facilityManagementForm?: IForm;
    deviceTypeManagementForm?: IForm;
    ablesetypForm?: IForm;
    loadingList?: Array<string>;
    facilityServicesTyp: Array<any>;
    deviceTypeList: Array<IDeviceType>;
    selectedDeviceType: IDeviceType;
    ableseTypeList?: Array<IAbleseTyp>;
    selectedAbleseTyp?: IAbleseTyp;
    uploadMeterServiceEmails?: (payload: any) => void;
    getMeterServiceEmails?: () => void;
    getProductList?: () => void;
    productList?: Array<IProduct>;
    postProduct?: (payload: IProduct) => void;
    setError?: (error: IError) => void;
    saveSelectedService: (item: any) => void;
    saveSelectedDeviceType?: (item: IDeviceType) => void;
    selectedFacilityService: any;
    getFacilityServicesTyp: () => void;
    postFacilityServiceTyp: (item: any) => void;
    deleteFacilityServiceTyp: (item: any) => void;
    updateProduct?: (product: IProduct) => void;
    deleteProduct?: (productId: string) => void;
    getDeviceTypeList?: () => void;
    postDeviceType?: (item: IDeviceType) => void;
    updateDeviceType?: (item: IDeviceType) => void;
    deleteDeviceType?: (id: string) => void;
    postAbleseType?: (item: IAbleseTyp) => void;
    getAbleseTypeList?: () => void;
    updateAbleseTyp?: (item: IAbleseTyp) => void;
    saveSelectedAblesTyp?: (item: IAbleseTyp) => void;
    deleteAbleseTyp?: (id: string) => void;
    snackbarStatus?: ISnackBar;
    setSnackbarStatus?: (item: ISnackBar) => void;
    downloadMeterServiceEmailsFile?: () => void;
    getMeterServiceEmailsInfo?: () => void;
    emailFileInfo: IFileInfo;
    emailFile: IFile;
}

interface IState {
    showProductModal: boolean;
    showAblesetypModal: boolean;
    shouldEditProduct: boolean;
    selectedProduct: IProduct;
    shouldShowDeleteAlert: boolean;
    productModalTitle: string;
    ablesetypModalTitle: string;
    shouldEditAbleseTyp: boolean;
}

class SettingsContainer extends React.Component<ISettingsContainerProps, IState> {
    constructor(props: ISettingsContainerProps) {
        super(props);
        this.state = {
            showProductModal: false,
            showAblesetypModal: false,
            shouldEditProduct: false,
            selectedProduct: null,
            shouldShowDeleteAlert: false,
            shouldEditAbleseTyp: false,
            productModalTitle: 'Produkt Hinzufügen',
            ablesetypModalTitle: 'Ablesetyp Hinzufügen',
        };
    }

    public componentDidMount() {
        // this.props.getMeterServiceEmails();
        this.props.getProductList();
        this.props.getDeviceTypeList();
        this.props.getAbleseTypeList();
        this.props.getMeterServiceEmailsInfo();
    }

    public uploadMeterPanelServiceEmails = () => {
        const reader = new FileReader();
        reader.readAsDataURL(this.props.facilityEmailForm.formValue.file);
        reader.onload = async () => {
            const archive: any = {
                Filename: this.props.facilityEmailForm.formValue.Filename,
                FileContents: reader.result,
            };
            await this.props.uploadMeterServiceEmails(archive);
            this.props.getMeterServiceEmailsInfo();
        };
    };

    public downloadMeterPanelServiceEmails = async () => {
        await this.props.downloadMeterServiceEmailsFile();
        if (this.props.emailFile) {
            const data = this.props.emailFile.FileContents.split(',').pop();
            const extension = this.props.emailFile.FileContents.split(';')[0]
                .split('/')
                .pop();
            const blob = convertBase64toBlob(data);
            saveAs(blob, `${this.props.emailFile.FileName}.${extension}`);
        }
    };
    public handleSelectProduct = (product: IProduct) => {
        this.setState({
            selectedProduct: this.props.productList.find(item => item.id === product.id),
        });
    };

    public handleEditProduct = () => {
        this.setState({ shouldEditProduct: true, productModalTitle: 'Produkt Bearbeiten' });
        // this.setState({selectedDeviceType: this.props.ablesetypList.find(item => item.ID === product.ID)});
        this.setState({ showProductModal: true });
    };

    public handleAddProduct = () => {
        this.setState({
            shouldEditProduct: false,
            productModalTitle: 'Produkte Hinzufügen',
            showProductModal: true,
        });
    };
    public handleAddAbelsetyp = () => {
        this.setState({
            ablesetypModalTitle: 'Ablesetyp Hinzufügen',
            shouldEditAbleseTyp: false,
            showAblesetypModal: true,
        });
    };
    public handleEditAbelsetyp = () => {
        this.setState({
            ablesetypModalTitle: 'Ablesetyp Bearbeiten',
            shouldEditAbleseTyp: true,
            showAblesetypModal: true,
        });
    };

    public handleDeleteProduct = () => {
        this.setState({ shouldShowDeleteAlert: true });
    };

    public deleteProduct = async () => {
        this.setState({ shouldShowDeleteAlert: false });
        await this.props.deleteProduct(this.state.selectedProduct.id);
        this.props.getProductList();
        this.setState({ selectedProduct: null });
    };

    public productOperation = async () => {
        this.setState({ showProductModal: false });
        this.setState({ selectedProduct: null });
        if (this.state.shouldEditProduct) {
            // edit product
            await this.props.updateProduct(this.props.addProductForm.formValue);
        } else {
            // add product
            await this.props.postProduct(this.props.addProductForm.formValue);
        }
        this.props.getProductList();
    };

    public addOrUpdateAblesetyp = async () => {
        this.setState({ showAblesetypModal: false });
        if (this.state.shouldEditAbleseTyp) {
            await this.props.updateAbleseTyp(this.props.ablesetypForm.formValue);
        } else {
            await this.props.postAbleseType(this.props.ablesetypForm.formValue);
        }
        this.props.getAbleseTypeList();
    };
    private saveService = async () => {
        await this.props.postFacilityServiceTyp(this.props.facilityManagementForm.formValue);
        this.props.getFacilityServicesTyp();
    };
    private deleteService = async service => {
        await this.props.deleteFacilityServiceTyp(service);
        this.props.getFacilityServicesTyp();
    };
    private handleDissmissError = () => {
        this.props.setError(null);
    };

    private addDeviceType = async item => {
        await this.props.postDeviceType(item);
        this.props.getDeviceTypeList();
    };
    private updateDeviceType = async item => {
        await this.props.updateDeviceType(item);
        this.props.getDeviceTypeList();
    };
    private deleteDeviceType = async id => {
        await this.props.deleteDeviceType(id);
        this.props.getDeviceTypeList();
    };
    private deleteAblesetyp = async () => {
        await this.props.deleteAbleseTyp(this.props.selectedAbleseTyp.id);
        this.props.saveSelectedAblesTyp(null);
        this.props.getAbleseTypeList();
    };

    public renderModals = () => {
        return (
            <div>
                <SLModal
                    title={this.state.productModalTitle}
                    open={this.state.showProductModal}
                    onOkButtonClick={this.productOperation}
                    OkButtonDisabled={this.props.addProductForm.invalid}
                    onClose={() => {
                        this.setState({ showProductModal: false });
                    }}
                >
                    <SlAddProduct
                        defaultProduct={this.state.selectedProduct}
                        editMode={this.state.shouldEditProduct}
                    />
                </SLModal>

                <SLModal
                    title={this.state.ablesetypModalTitle}
                    open={this.state.showAblesetypModal}
                    onOkButtonClick={this.addOrUpdateAblesetyp}
                    OkButtonDisabled={this.props.ablesetypForm.invalid}
                    onClose={() => {
                        this.setState({ showAblesetypModal: false });
                    }}
                >
                    <SlAblesetypForm
                        defaultAbelesetyp={this.props.selectedAbleseTyp}
                        editMode={this.state.shouldEditAbleseTyp}
                    />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={deTranslator.product.delete}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.shouldShowDeleteAlert}
                    handleOK={this.deleteProduct}
                    handleCancel={() => this.setState({ shouldShowDeleteAlert: false })}
                    showCancelButton={true}
                >
                    {`${deTranslator.product.deleteTextStart}`}
                    {this.state.selectedProduct && <b>{this.state.selectedProduct.name}</b>}
                    {`${deTranslator.product.deleteTextEnd}`}
                </SlAlertComponent>
                <SlAlertComponent
                    open={!isNullOrUndefined(this.props.error)}
                    showCancelButton={false}
                    handleOK={this.handleDissmissError}
                >
                    {this.props.error && <ErrorComponent error={this.props.error} />}
                </SlAlertComponent>
            </div>
        );
    };

    public render() {
        return (
            <div className={this.props.classes.root}>
                <SlHeader currentLocation={this.props.location} />
                <div className={this.props.classes.content}>
                    <MaterialUI.Toolbar />
                    <MaterialUI.Toolbar />
                    <div className={this.props.classes.responsiveContent}>
                        <FacilityEmail
                            facilityEmailForm={this.props.facilityEmailForm}
                            emailFileInfo={this.props.emailFileInfo}
                            downloadFile={this.downloadMeterPanelServiceEmails}
                            uploadFile={this.uploadMeterPanelServiceEmails}
                        />

                        <SLProductManagement
                            productList={this.props.productList}
                            selectedProduct={this.state.selectedProduct}
                            onSelectedProduct={this.handleSelectProduct}
                            onEditProductDialog={this.handleEditProduct}
                            onAddProductDialog={this.handleAddProduct}
                            onDeleteProductDialog={this.handleDeleteProduct}
                        />
                        <FacilityServiceManagementComponent
                            facilityManagementForm={this.props.facilityManagementForm}
                            onSelectedService={item => this.props.saveSelectedService(item)}
                            serviceList={this.props.facilityServicesTyp}
                            onSaveService={this.saveService}
                            selectedFacilityService={this.props.selectedFacilityService}
                            onDelete={this.deleteService}
                        />
                        <DeviceTypeManagementComponent
                            onAddDeviceType={this.addDeviceType}
                            onUpdateDeviceType={this.updateDeviceType}
                            onDelete={this.deleteDeviceType}
                            selectedDeviceType={this.props.selectedDeviceType}
                            onSelectedDeviceType={this.props.saveSelectedDeviceType}
                            deviceTypeList={this.props.deviceTypeList}
                            deviceTypeManagementForm={this.props.deviceTypeManagementForm}
                        />

                        <SLAblesetypComponent
                            ablesetypList={this.props.ableseTypeList}
                            onAddAblesetypDialog={this.handleAddAbelsetyp}
                            onEditAblesetypDialog={this.handleEditAbelsetyp}
                            selectedAblesetyp={this.props.selectedAbleseTyp}
                            onSelectedAblesetyp={this.props.saveSelectedAblesTyp}
                            onDeleteAblesetyp={this.deleteAblesetyp}
                        />
                        <SLDashboardNews />
                        <SLGeraeteSettingComponent  />
                        <SlimAuftragSettings />
                        <SLWebinar />
                    </div>
                </div>
                {this.renderModals()}
                {this.props.loadingList.length > 0 && <LoadingComponent size={60} />}
                {this.props.snackbarStatus && (
                    <SlSnackbarComponent
                        open={this.props.snackbarStatus.flag}
                        text={this.props.snackbarStatus.text}
                        handleClose={() => this.props.setSnackbarStatus(null)}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IReduxState) => {
    return {
        productList: state.settingState.productManagementList,
        facilityServicesTyp: state.settingState.servicesTyp,
        selectedFacilityService: state.settingState.selectedServiceTyp,
        deviceTypeList: state.settingState.deviceTypeList,
        selectedDeviceType: state.settingState.selectedDeviceType,
        ableseTypeList: state.settingState.ableseTypeList,
        selectedAbleseTyp: state.settingState.selectedAbleseTyp,
        snackbarStatus: state.globalState.snackbarStatus,
        emailFileInfo: state.settingState.emailFileInfo,
        emailFile: state.settingState.emailFile,
        facilityEmailForm: {
            formValue: getFormValues('SlUploadFileForm')(state),
            invalid: isInvalid('SlUploadFileForm')(state),
        },
        addProductForm: {
            formValue: getFormValues('SlAddProductForm')(state),
            invalid: isInvalid('SlAddProductForm')(state),
        },
        facilityManagementForm: {
            formValue: getFormValues('SlFacilityManagementForm')(state),
            invalid: isInvalid('SlFacilityManagementForm')(state),
        },
        deviceTypeManagementForm: {
            formValue: getFormValues('DeviceTypeManagementForm')(state),
            invalid: isInvalid('DeviceTypeManagementForm')(state),
        },
        ablesetypForm: {
            formValue: getFormValues('SlAblesetypForm')(state),
            invalid: isInvalid('SlAblesetypForm')(state),
        },
        error: state.globalState.error,
        loadingList: state.globalState.loadingList,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return bindActionCreators(Object.assign({}, GlobalActions, SettingsActions,SlimActions), dispatch);
};

export const SettingsContainerComponent = MaterialUI.withStyles(styles)(SettingsContainer);

export const SettingsPage = withRouter(
    connect<ISettingsContainerProps, any, any>(
        mapStateToProps,
        mapDispatchToProps
    )<ISettingsContainerProps>(SettingsContainerComponent)
);
