import { ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { ProtectedRoute } from './common/components/protected-route';
import { CustomerDataPage } from './features/customers/pages/customer-data.page';
import { CustomersPage } from './features/customers/pages/customers.page';
import { DeviceDataPage } from './features/devices/pages/device-data.page';
import { FacilityDataPage } from './features/facilities/pages/facility-data.page';
import { AuthPage } from './features/login/pages/auth.page';
import { LoginPage } from './features/login/pages/login.page';
import { MeterPanelDataPage } from './features/meterpanels/pages/meterpanel-data.page';
import { SettingsPage } from './features/settings/pages/settings.page';
import { SLimFacilityPage } from './features/slim/pages/slimAnlage.page';
import { SlimCustomer } from './features/slim/pages/slimCustomer.page';
import { SlimGeraetDetailsPage } from './features/slim/pages/slimGeraetDetails.page';
import { SlimZaehlerplatzPage } from './features/slim/pages/SlimZaehlerplatz.page';
import './index.css';
import { requestInterceptor, responseInterceptor } from './interceptors/http.interceptors';
import { history, store } from './store';
import { appTheme } from './theme/app.theme';

// Add a request interceptor

axios.interceptors.request.use(
    response => requestInterceptor.onFulfilled(response),
    error => requestInterceptor.onRejected(error)
);
// Add a response interceptor
axios.interceptors.response.use(
    response => responseInterceptor.onFulfilled(response),
    error => responseInterceptor.onRejected(error)
);

axios.defaults.baseURL = process.env.REACT_APP_API_URL; // set base url

export class Page extends React.Component<any> {
    private unsubscribe: any;

    public componentDidMount() {
        this.unsubscribe = Hub.listen('auth', payload => {
            console.log('payload: ', payload);
            switch (payload['event']) {
                case 'signIn':
                    console.log('hub-signIn: ', payload);
                    break;
                case 'signOut':
                    console.log('hub-signOut: ', payload);

                    break;
                case 'customOAuthState':
                    console.log('hub-customOAuthState: ', payload);
            }
        });
    }

    public componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render() {
        return (
            <Router history={history}>
                <Provider store={store}>
                    <ThemeProvider theme={appTheme}>
                        <Switch>
                            <Route exact={true} path="/login" component={LoginPage} />
                            <Route exact={true} path="/auth" component={AuthPage} />
                            <ProtectedRoute exact={true} path="/kabs" component={CustomersPage} />
                            <ProtectedRoute
                                exact={true}
                                path="/einstellungen"
                                component={SettingsPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/kabs/:kabID"
                                component={CustomerDataPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/kabs/:kabID/:aID"
                                component={FacilityDataPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/kabs/:kabID/:aID/:zID"
                                component={MeterPanelDataPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/kabs/:kabID/:aID/:zID/:gID"
                                component={DeviceDataPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/slim/kabs/:kabID"
                                component={SlimCustomer}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/slim/kabs/:kabID/:aID"
                                component={SLimFacilityPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/slim/kabs/:kabID/:aID/:zId"
                                component={SlimZaehlerplatzPage}
                            />
                            <ProtectedRoute
                                exact={true}
                                path="/slim/kabs/:kabID/:aID/:zId/:gID"
                                component={SlimGeraetDetailsPage}
                            />

                            <Redirect to="/kabs" />
                        </Switch>
                    </ThemeProvider>
                </Provider>
            </Router>
        );
    }
}
