import { GlobalActionTypes } from '../types/global.types';

export interface IError {
    message: string;
    title?: string;
    statusCode?: number;

}

export interface ISnackBar {
    flag: boolean;
    text: string;
    type: 'success' | 'error' | 'info' | 'warning'

}


export interface IGlobalState {
    error: IError;
    loadingList: Array<string>;
    snackbarStatus: ISnackBar;

}

const initialState: IGlobalState = {
    error: null,
    loadingList: [],
    snackbarStatus: null,
};

export const globalReducer = (state: IGlobalState = initialState, action: any): IGlobalState => {

    switch (action.type) {
        case GlobalActionTypes.SET_ERROR:
            return Object.assign({}, state, {error: action.payload});
        case GlobalActionTypes.ADD_LOADING_STATE:
            return Object.assign({}, state, {loadingList: [...state.loadingList, action.payload]});
        case GlobalActionTypes.REMOVE_LOADING_STATE:
            return Object.assign({}, state,
                {loadingList: state.loadingList.filter(text => text !== action.payload)});
        case GlobalActionTypes.SHOW_SNACKBAR:
            return Object.assign({}, state, {snackbarStatus: action.payload});
        default:
            return Object.assign({}, state);

    }


};