export const updateSlimAuftrag = /* GraphQL */ `
    mutation UpdateSlimAuftrag(
        $input: UpdateSlimAuftragInput!
        $condition: ModelSlimAuftragConditionInput
    ) {
        updateSlimAuftrag(input: $input, condition: $condition) {
            id
            auftrag_type
            auftraggeber {
                name
                address {
                    street
                    streetNumber
                    postalCode
                    city
                    country
                    __typename
                }
                __typename
            }
            organisation_id
            createdAt
            auftragdatum
            versanddatum
            status
            kundenanlagebetreiber_id
            kundenanlage_id
            zaehlerplatzIDs

            updatedAt
            __typename
        }
    }
`;

export const createSlimAuftrag = /* GraphQL */ `
    mutation CreateSlimAuftrag(
        $input: CreateSlimAuftragInput!
        $condition: ModelSlimAuftragConditionInput
    ) {
        createSlimAuftrag(input: $input, condition: $condition) {
            id
            auftrag_type
            auftraggeber {
                name
                address {
                    street
                    streetNumber
                    postalCode
                    city
                    country
                    __typename
                }
                __typename
            }
            organisation_id
            createdAt
            auftragdatum
            versanddatum
            status
            kundenanlagebetreiber_id
            kundenanlage_id
            zaehlerplatzIDs
            zaehlerplatzList {
                items {
                    id
                    organisation_id
                    KID
                    AID
                    ZID
                    Nutzungstyp
                    WohnungNr
                    slimAuftragID
                    Montageart
                    AuftragAktion
                    Gebaeude
                    Lage
                    Malo
                    Melo
                    Stockwerk
                    Verbrauchsprognose
                    GeraetTyp

                    createdAt
                    updatedAt
                    __typename
                }
                nextToken
                __typename
            }
            kundenanlage {
                id
                organisation_id
                KID
                AID
                owners
                updatedAt
                KAname
                Stichtag
                Adresse {
                    Land
                    Ort
                    PLZ
                    HausNummer
                    Postfach
                    Strasse
                    Zusatzinformationen
                    __typename
                }
                Abrechnungsmodell
                Vertragsbeginn
                Vertragsende
                isMetergridProject
                Identifikationsnummer
                Verteilnetzbetreiber
                CodenummerVNB

                createdAt
                __typename
            }
            kundenanlagenbetreiber {
                id
                organisation_id
                KID
                kabStammdaten {
                    Adresse {
                        Land
                        Ort
                        PLZ
                        HausNummer
                        Postfach
                        Strasse
                        Zusatzinformationen
                        __typename
                    }
                    Anrede {
                        Typ
                        Firma {
                            Firmenname1
                            Firmenname2
                            Steuernummer
                            UmsatzSteuerID
                            Umsatzsteuerpflichtig
                            __typename
                        }
                        Person {
                            Nachname
                            Vorname
                            Geburtsdatum
                            Titel
                            __typename
                        }
                        __typename
                    }
                    AnzahlAnlagen
                    AktivAdresseID
                    Zusatzinfo {
                        Nachname
                        Vorname
                        Rechtsform
                        Finanzamt
                        Handelsregisternumer
                        Registergericht
                        Hauptzollamt
                        __typename
                    }
                    Bankdaten {
                        Kontoinhaber
                        BIC
                        IBAN
                        __typename
                    }
                    __typename
                }
                createdAt
                updatedAt
                __typename
            }
            updatedAt
            __typename
        }
    }
`;
