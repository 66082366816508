import { CustomerActionTypes } from '../types/customer.action.types';

export const contactDataLoadingStatus = flag => {
    return {
        type: CustomerActionTypes.CONTACTDATA_IS_LOADING,
        payload: flag
    };
};














