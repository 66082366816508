import {
    IAbleseTyp,
    IAblesung,
    IDevice,
    ILastAbelsung,
    IMeterPlace,
} from '../../../common/interfaces/interface';
import { IsoformatToGermanDate } from '../../../common/utils';

export const buildReadingData = (
    devices: Array<IDevice>,
    meterPanels: Array<IMeterPlace>,
    ableseTypeList: Array<IAbleseTyp>
): Array<ILastAbelsung> => {
    return devices
        .filter(item => (item.Ausbaudatum && item.Ausbaudatum === '') || !item.Ausbaudatum)
        .map((device: IDevice) => {
            const meterpanel = meterPanels.find(panel => panel.ID === device.ZID);
            const Wandlerfaktor = device.Wandlerfaktor ? device.Wandlerfaktor : 1;
            if (meterpanel && device.Ablesungen && device.Ablesungen.length > 0) {
                const lastReading: IAblesung = device.Ablesungen[0];
                const ablesegrund = ableseTypeList.find(
                    ablesetyp => ablesetyp.id === lastReading.AblesegrundId
                );
                return {
                    ID: device.ID,
                    ZID: meterpanel.ID,
                    Ausbaudatum: device.Ausbaudatum ? device.Ausbaudatum : '',
                    letztAbleseDatum: lastReading.Ablesedatum,
                    status: lastReading.Status,
                    letztAblesungStandHT: lastReading.ZaehlerstandHT || '',
                    naechsteAblesungStand: IsoformatToGermanDate(
                        new Date(new Date().getFullYear(), 11, 31).toISOString()
                    ),
                    ablesegrund: ablesegrund ? ablesegrund.name : '',
                    GeraeteID: device.GeraeteID,
                    Ablesungen: device.Ablesungen,
                    WohnungNr: meterpanel.WohnungNr,
                    Lage: meterpanel.Lage,
                    ObisCodeHT: device.ObisCodeHT,
                    Gebaeude: meterpanel.Gebaeude,
                    ZaehlwerkNKS: device.ZaehlwerkNKS,
                    ZaehlwerkVKS: device.ZaehlwerkVKS,
                    Verbrauchsprognose: meterpanel.Verbrauchsprognose || '0',
                    EinbaustandHT: device.EinbaustandHT,
                    Einbaudatum: device.Einbaudatum,
                    Wandlerfaktor: Wandlerfaktor,
                };
            }

            return {
                ID: device.ID,
                ZID: meterpanel.ID || '',
                GeraeteID: device.GeraeteID,
                WohnungNr: meterpanel.WohnungNr || '',
                Verbrauchsprognose: meterpanel.Verbrauchsprognose || '0',
                Ablesungen: [],
                EinbaustandHT: device.EinbaustandHT,
                ZaehlwerkNKS: device.ZaehlwerkNKS,
                ZaehlwerkVKS: device.ZaehlwerkVKS,
                Einbaudatum: device.Einbaudatum,
                Lage: meterpanel.Lage,
                Gebaeude: meterpanel.Gebaeude,
                ObisCodeHT: device.ObisCodeHT,
                Wandlerfaktor: Wandlerfaktor,
            };
        });
};

export const buildDevicesDataTable = (devices: Array<IDevice>, meterPanels: Array<IMeterPlace>) => {
    return devices.map((device: IDevice) => {
        const counterPlace = meterPanels.find(panel => panel.ID === device.ZID);
        return {
            ZID: device.ZID,
            ID: device.ID,
            GeraeteID: device.GeraeteID,
            WohnungNr: counterPlace ? counterPlace.WohnungNr : '-',
            Gebaeude: counterPlace && counterPlace.Gebaeude ? counterPlace.Gebaeude : '-',
            Grundtyp: device.Grundtyp,
            Einbaudatum: device.Einbaudatum,
            Ausbaudatum: device.Ausbaudatum ? device.Ausbaudatum : '-',
            Eichdatum: device.Eichdatum,
            AuftragAktion: device.AuftragAktion ? device.AuftragAktion : '-',
        };
    });
};

export const buildMeterPlacesDataTable = (meterPanels: Array<IMeterPlace>) => {
    return meterPanels.map((counterPlace: IMeterPlace) => {
        return {
            WohnungNr: counterPlace.WohnungNr,
            Gebaeude: counterPlace.Gebaeude || '-',
            Stockwerk: counterPlace.Stockwerk || '-',
            Lage: counterPlace.Lage || '-',
            AuftragAktion: counterPlace.AuftragAktion,
            Montageart: counterPlace.Montageart,
            ID: counterPlace.ID,
            GeraetTyp: counterPlace.GeraetTyp ? counterPlace.GeraetTyp : '',
        };
    });
};
