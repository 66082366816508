export const createSensor = /* GraphQL */ `
    mutation CreateSensor($input: CreateSensorInput!, $condition: ModelSensorConditionInput) {
        createSensor(input: $input, condition: $condition) {
            id
            organisation_id
            kundenanlagenbetreiber_id
            kundenanlage_id
            zaehlerplatz_id
            elementIot_id
            Seriennummer
            Typ
            Einbaudatum
            Ausbaudatum
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            Versanddatum
            Technologie
            createdAt
            updatedAt
        }
    }
`;
export const updateSensor = /* GraphQL */ `
    mutation UpdateSensor($input: UpdateSensorInput!, $condition: ModelSensorConditionInput) {
        updateSensor(input: $input, condition: $condition) {
            id
            organisation_id
            kundenanlagenbetreiber_id
            kundenanlage_id
            zaehlerplatz_id
            elementIot_id
            Seriennummer
            Typ
            Einbaudatum
            Ausbaudatum
            Produkts {
                name
                preis
                ebene
                zyklus
                id
            }
            Versanddatum
            Technologie
            createdAt
            updatedAt
        }
    }
`;
