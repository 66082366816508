import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import * as React from 'react';

export const SlCheckboxInput = ({ input, label, name, disabled }) => (
    <FormControlLabel
        control={
            <Checkbox
                disabled={disabled}
                checked={input.value ? true : false}
                onChange={input.onChange}
                name={name}
            />
        }
        label={label}
    />
);
