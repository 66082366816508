import { IBillRecipient, IContactData, IKAB } from '../../../../common/interfaces/interface';
import { DEVIATING_BILL_RECIPIENT_DEFAULT_VALUE } from '../../../../model/interfaces/IDeviatingBillRecipient';
import { CustomerActionTypes } from '../types/customer.action.types';

export interface ICustomerState {
    customers: Array<IKAB>;
    selectedCustomer: IKAB;
    selectedDeviatinBillRecipient: IBillRecipient;
    kabContactData: Array<IContactData>;
    selectedKabContactData: IContactData;
    customerLoading: boolean;
    deviatinBillRecipientLoading: boolean;
    contactDataLoading: boolean;
    addedCustomerID: string;
    orders: Array<any>;
    dashboardActiveTab: number;
    customerActiveTab: number;
    reminders: Array<any>;
}

const initialState: ICustomerState = {
    customers: [],
    selectedCustomer: null,
    selectedKabContactData: null,
    kabContactData: [],
    selectedDeviatinBillRecipient: DEVIATING_BILL_RECIPIENT_DEFAULT_VALUE,
    deviatinBillRecipientLoading: false,
    customerLoading: false,
    contactDataLoading: false,
    addedCustomerID: null,
    orders: [],
    dashboardActiveTab: 0,
    customerActiveTab: 0,
    reminders: []
};

export const customerReducer = (state: ICustomerState = initialState, action: any): ICustomerState => {
    switch (action.type) {
        case CustomerActionTypes.SAVE_CUSTOMERS:
            return Object.assign({}, state, {customers: [...action.payload]});
        case CustomerActionTypes.SAVE_SELECTED_CUSTOMER:
            return Object.assign({}, state, {selectedCustomer: action.payload});
        case CustomerActionTypes.CUSTOMER_IS_LOADING:
            return Object.assign({}, state, {customerLoading: action.payload});
        case CustomerActionTypes.ADDED_CUSTOMER_ID:
            return Object.assign({}, state, {addedCustomerID: action.payload});
        case CustomerActionTypes.SAVE_SELECTED_DEVIATINGBILLRECIPIENT:
            return Object.assign({}, state, {selectedDeviatinBillRecipient: action.payload});
        case CustomerActionTypes.DEVIATINGBILLRECIPIENT_IS_LOADING:
            return Object.assign({}, state, {deviatinBillRecipientLoading: action.payload});
        case CustomerActionTypes.SAVE_KAB_CONTACTDATA:
            return Object.assign({}, state, {kabContactData: [...action.payload]});
        case CustomerActionTypes.CONTACTDATA_IS_LOADING:
            return Object.assign({}, state, {contactDataLoading: action.payload});
        case CustomerActionTypes.SAVE_SELECTED_KAB_CONTACTPERSON:
            return Object.assign({}, state, {selectedKabContactData: action.payload});
        case CustomerActionTypes.SAVE_ORDERS:
            return Object.assign({}, state, {orders: action.payload});
        case CustomerActionTypes.SAVE_DASHBOARD_ACTIVE_TAB:
            return Object.assign({}, state, {dashboardActiveTab: action.payload});
        case CustomerActionTypes.SAVE_CUSTOMER_ACTIVE_TAB:
            return Object.assign({}, state, {customerActiveTab: action.payload});
        case CustomerActionTypes.SAVE_REMINDERS:
            return Object.assign({}, state, {reminders: action.payload});
        default:
            return Object.assign({}, state);

    }
};