export const sensorByKundenanlageId = /* GraphQL */ `
    query SensorByKundenanlageId(
        $kundenanlage_id: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelSensorFilterInput
        $limit: Int
        $nextToken: String
    ) {
        sensorByKundenanlageId(
            kundenanlage_id: $kundenanlage_id
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organisation_id
                kundenanlagenbetreiber_id
                kundenanlage_id
                zaehlerplatz_id
                elementIot_id
                Seriennummer
                Typ
                Einbaudatum
                Ausbaudatum
                Produkts {
                    name
                    preis
                    ebene
                    zyklus
                    id
                }
                Versanddatum
                Technologie
                Zaehlerplatz {
                    id
                    organisation_id
                    KID
                    AID
                    ZID
                    WohnungNr
                    Montageart
                    AuftragAktion
                    Gebaeude
                    Lage
                    Malo
                    Melo
                    Stockwerk
                    Verbrauchsprognose
                    GeraetTyp
                    Nutzungstyp
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
