export const createWebinar = /* GraphQL */ `
    mutation CreateWebinar($input: CreateWebinarInput!, $condition: ModelWebinarConditionInput) {
        createWebinar(input: $input, condition: $condition) {
            id
            title
            subTitle
            content
            link
            publishedDate
            publishedTimeFrom
            publishedTimeTo
            createdAt
            updatedAt
        }
    }
`;
export const updateWebinar = /* GraphQL */ `
    mutation UpdateWebinar($input: UpdateWebinarInput!, $condition: ModelWebinarConditionInput) {
        updateWebinar(input: $input, condition: $condition) {
            id
            title
            subTitle
            content
            link
            publishedDate
            publishedTimeFrom
            publishedTimeTo
            status
            createdAt
            updatedAt
        }
    }
`;
