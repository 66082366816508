import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

interface IGeraetezuordnenProps {
  color: any
}

class Geraetezuordnen extends React.Component<IGeraetezuordnenProps, {}>{
    public static defaultProps: Partial<IGeraetezuordnenProps> = {
        color: 'inherit'

    };



  public render() {
    return (
      <MaterialUI.SvgIcon style={{ padding: 'inherit', paddingRight: '10px' }} >  
      <path
        d="M7.28906 8.88086C7 9.29492 6.5957 9.60547 6.07617 9.8125C5.56055 10.0156 4.95898 10.1172 4.27148 10.1172C3.57617 10.1172 2.95898 9.95508 2.41992 9.63086C1.88086 9.30273 1.46289 8.83789 1.16602
         8.23633C0.873047 7.63477 0.722656 6.9375 0.714844 6.14453V5.40039C0.714844 4.11523 1.01367 3.11914 1.61133 2.41211C2.21289 1.70508 3.05664 1.35156 4.14258 1.35156C5.0332 1.35156 5.75 1.58008 6.29297 
         2.03711C6.83594 2.49023 7.16797 3.13477 7.28906 3.9707H6.16406C5.95312 2.8418 5.28125 2.27734 4.14844 2.27734C3.39453 2.27734 2.82227 2.54297 2.43164 3.07422C2.04492 3.60156 1.84961 4.36719 1.8457 
         5.37109V6.06836C1.8457 7.02539 2.06445 7.78711 2.50195 8.35352C2.93945 8.91602 3.53125 9.19727 4.27734 9.19727C4.69922 9.19727 5.06836 9.15039 5.38477 9.05664C5.70117 8.96289 5.96289 8.80469 6.16992
          8.58203V6.66602H4.19531V5.75195H7.28906V8.88086ZM11.6309 10.1172C10.7715 10.1172 10.0723 9.83594 9.5332 9.27344C8.99414 8.70703 8.72461 7.95117 8.72461 7.00586V6.80664C8.72461 6.17773 8.84375 5.61719 
          9.08203 5.125C9.32422 4.62891 9.66016 4.24219 10.0898 3.96484C10.5234 3.68359 10.9922 3.54297 11.4961 3.54297C12.3203 3.54297 12.9609 3.81445 13.418 4.35742C13.875 4.90039 14.1035 5.67773 14.1035
           6.68945V7.14062H9.80859C9.82422 7.76562 10.0059 8.27148 10.3535 8.6582C10.7051 9.04102 11.1504 9.23242 11.6895 9.23242C12.0723 9.23242 12.3965 9.1543 12.6621 8.99805C12.9277 8.8418 13.1602 8.63477
            13.3594 8.37695L14.0215 8.89258C13.4902 9.70898 12.6934 10.1172 11.6309 10.1172ZM11.4961 4.43359C11.0586 4.43359 10.6914 4.59375 10.3945 4.91406C10.0977 5.23047 9.91406 5.67578 9.84375 
            6.25H13.0195V6.16797C12.9883 5.61719 12.8398 5.19141 12.5742 4.89062C12.3086 4.58594 11.9492 4.43359 11.4961 4.43359ZM18.4277 4.63281C18.2637 4.60547 18.0859 4.5918 17.8945 4.5918C17.1836 
            4.5918 16.7012 4.89453 16.4473 5.5V10H15.3633V3.66016H16.418L16.4355 4.39258C16.791 3.82617 17.2949 3.54297 17.9473 3.54297C18.1582 3.54297 18.3184 3.57031 18.4277 3.625V4.63281ZM23.1094 10C23.0469
             9.875 22.9961 9.65234 22.957 9.33203C22.4531 9.85547 21.8516 10.1172 21.1523 10.1172C20.5273 10.1172 20.0137 9.94141 19.6113 9.58984C19.2129 9.23438 19.0137 8.78516 19.0137 8.24219C19.0137 7.58203
              19.2637 7.07031 19.7637 6.70703C20.2676 6.33984 20.9746 6.15625 21.8848 6.15625H22.9395V5.6582C22.9395 5.2793 22.8262 4.97852 22.5996 4.75586C22.373 4.5293 22.0391 4.41602 21.5977 4.41602C21.2109
               4.41602 20.8867 4.51367 20.625 4.70898C20.3633 4.9043 20.2324 5.14062 20.2324 5.41797H19.1426C19.1426 5.10156 19.2539 4.79688 19.4766 4.50391C19.7031 4.20703 20.0078 3.97266 20.3906 3.80078C20.7773 
               3.62891 21.2012 3.54297 21.6621 3.54297C22.3926 3.54297 22.9648 3.72656 23.3789 4.09375C23.793 4.45703 24.0078 4.95898 24.0234 5.59961V8.51758C24.0234 9.09961 24.0977 9.5625 24.2461 
               9.90625V10H23.1094ZM21.3105 9.17383C21.6504 9.17383 21.9727 9.08594 22.2773 8.91016C22.582 8.73438 22.8027 8.50586 22.9395 8.22461V6.92383H22.0898C20.7617 6.92383 20.0977 7.3125 20.0977
                8.08984C20.0977 8.42969 20.2109 8.69531 20.4375 8.88672C20.6641 9.07812 20.9551 9.17383 21.3105 9.17383ZM19.8926 1.9668C19.8926 1.79102 19.9453 1.64453 20.0508 1.52734C20.1602 1.40625 
                20.3203 1.3457 20.5312 1.3457C20.7422 1.3457 20.9023 1.40625 21.0117 1.52734C21.1211 1.64453 21.1758 1.79102 21.1758 1.9668C21.1758 2.14258 21.1211 2.28906 21.0117 2.40625C20.9023 2.52344 
                20.7422 2.58203 20.5312 2.58203C20.3203 2.58203 20.1602 2.52344 20.0508 2.40625C19.9453 2.28906 19.8926 2.14258 19.8926 1.9668ZM22.4121 1.97852C22.4121 1.80273 22.4648 1.6543 22.5703
                 1.5332C22.6797 1.41211 22.8398 1.35156 23.0508 1.35156C23.2617 1.35156 23.4219 1.41211 23.5312 1.5332C23.6406 1.6543 23.6953 1.80273 23.6953 1.97852C23.6953 2.1543 23.6406 2.30078 23.5312 
                 2.41797C23.4219 2.53516 23.2617 2.59375 23.0508 2.59375C22.8398 2.59375 22.6797 2.53516 22.5703 2.41797C22.4648 2.30078 22.4121 2.1543 22.4121 1.97852ZM27.1934 
                 2.125V3.66016H28.377V4.49805H27.1934V8.42969C27.1934 8.68359 27.2461 8.875 27.3516 9.00391C27.457 9.12891 27.6367 9.19141 27.8906 9.19141C28.0156 9.19141 28.1875 9.16797 
                 28.4062 9.12109V10C28.1211 10.0781 27.8438 10.1172 27.5742 10.1172C27.0898 10.1172 26.7246 9.9707 26.4785 9.67773C26.2324 9.38477 26.1094 8.96875 26.1094 
                 8.42969V4.49805H24.9551V3.66016H26.1094V2.125H27.1934ZM32.2793 10.1172C31.4199 10.1172 30.7207 9.83594 30.1816 9.27344C29.6426 8.70703 29.373 7.95117 29.373 7.00586V6.80664C29.373 6.17773 
                 29.4922 5.61719 29.7305 5.125C29.9727 4.62891 30.3086 4.24219 30.7383 3.96484C31.1719 3.68359 31.6406 3.54297 32.1445 3.54297C32.9688 3.54297 33.6094 3.81445 34.0664 4.35742C34.5234 
                 4.90039 34.752 5.67773 34.752 6.68945V7.14062H30.457C30.4727 7.76562 30.6543 8.27148 31.002 8.6582C31.3535 9.04102 31.7988 9.23242 32.3379 9.23242C32.7207 9.23242 33.0449 9.1543 33.3105 
                 8.99805C33.5762 8.8418 33.8086 8.63477 34.0078 8.37695L34.6699 8.89258C34.1387 9.70898 33.3418 10.1172 32.2793 10.1172ZM32.1445 4.43359C31.707 4.43359 31.3398 4.59375 31.043 4.91406C30.7461 
                 5.23047 30.5625 5.67578 30.4922 6.25H33.668V6.16797C33.6367 5.61719 33.4883 5.19141 33.2227 4.89062C32.957 4.58594 32.5977 4.43359 32.1445 4.43359ZM40.0078 
                 9.11523H43.7168V10H38.6836V9.20312L42.1816 4.55664H38.7363V3.66016H43.541V4.42773L40.0078 9.11523ZM48.8555 9.37305C48.4336 9.86914 47.8145 10.1172 46.998 10.1172C46.3223 
                 10.1172 45.8066 9.92188 45.4512 9.53125C45.0996 9.13672 44.9219 8.55469 44.918 7.78516V3.66016H46.002V7.75586C46.002 8.7168 46.3926 9.19727 47.1738 9.19727C48.002 9.19727 
                 48.5527 8.88867 48.8262 8.27148V3.66016H49.9102V10H48.8789L48.8555 9.37305ZM51.2754 6.77148C51.2754 6.15039 51.3965 5.5918 51.6387 5.0957C51.8848 4.59961 52.2246 4.2168
                  52.6582 3.94727C53.0957 3.67773 53.5938 3.54297 54.1523 3.54297C55.0156 3.54297 55.7129 3.8418 56.2441 4.43945C56.7793 5.03711 57.0469 5.83203 57.0469 6.82422V6.90039C57.0469 
                  7.51758 56.9277 8.07227 56.6895 8.56445C56.4551 9.05273 56.1172 9.43359 55.6758 9.70703C55.2383 9.98047 54.7344 10.1172 54.1641 10.1172C53.3047 10.1172 52.6074 9.81836 52.0723 
                  9.2207C51.541 8.62305 51.2754 7.83203 51.2754 6.84766V6.77148ZM52.3652 6.90039C52.3652 7.60352 52.5273 8.16797 52.8516 8.59375C53.1797 9.01953 53.6172 9.23242 54.1641
                   9.23242C54.7148 9.23242 55.1523 9.01758 55.4766 8.58789C55.8008 8.1543 55.9629 7.54883 55.9629 6.77148C55.9629 6.07617 55.7969 5.51367 55.4648 5.08398C55.1367 4.65039 
                   54.6992 4.43359 54.1523 4.43359C53.6172 4.43359 53.1855 4.64648 52.8574 5.07227C52.5293 5.49805 52.3652 6.10742 52.3652 6.90039ZM61.4707 4.63281C61.3066 4.60547 61.1289 
                   4.5918 60.9375 4.5918C60.2266 4.5918 59.7441 4.89453 59.4902 5.5V10H58.4062V3.66016H59.4609L59.4785 4.39258C59.834 3.82617 60.3379 3.54297 60.9902 3.54297C61.2012 3.54297 
                   61.3613 3.57031 61.4707 3.625V4.63281ZM62.0918 6.77734C62.0918 5.80469 62.3223 5.02344 62.7832 4.43359C63.2441 3.83984 63.8477 3.54297 64.5938 3.54297C65.3359 3.54297 65.9238 
                   3.79687 66.3574 4.30469V1H67.4414V10H66.4453L66.3926 9.32031C65.959 9.85156 65.3555 10.1172 64.582 10.1172C63.8477 10.1172 63.248 9.81641 62.7832 9.21484C62.3223 8.61328 62.0918
                    7.82812 62.0918 6.85938V6.77734ZM63.1758 6.90039C63.1758 7.61914 63.3242 8.18164 63.6211 8.58789C63.918 8.99414 64.3281 9.19727 64.8516 9.19727C65.5391 9.19727 66.041 8.88867 
                    66.3574 8.27148V5.35938C66.0332 4.76172 65.5352 4.46289 64.8633 4.46289C64.332 4.46289 63.918 4.66797 63.6211 5.07812C63.3242 5.48828 63.1758 6.0957 63.1758 6.90039ZM70.1543 
                    3.66016L70.1895 4.45703C70.6738 3.84766 71.3066 3.54297 72.0879 3.54297C73.4277 3.54297 74.1035 4.29883 74.1152 5.81055V10H73.0312V5.80469C73.0273 5.34766 72.9219 5.00977 
                    72.7148 4.79102C72.5117 4.57227 72.1934 4.46289 71.7598 4.46289C71.4082 4.46289 71.0996 4.55664 70.834 4.74414C70.5684 4.93164 70.3613 5.17773 70.2129 5.48242V10H69.1289V3.66016H70.1543ZM78.3809 
                    10.1172C77.5215 10.1172 76.8223 9.83594 76.2832 9.27344C75.7441 8.70703 75.4746 7.95117 75.4746 7.00586V6.80664C75.4746 6.17773 75.5938 5.61719 75.832 5.125C76.0742 4.62891 76.4102 4.24219 
                    76.8398 3.96484C77.2734 3.68359 77.7422 3.54297 78.2461 3.54297C79.0703 3.54297 79.7109 3.81445 80.168 4.35742C80.625 4.90039 80.8535 5.67773 80.8535 6.68945V7.14062H76.5586C76.5742 7.76562 
                    76.7559 8.27148 77.1035 8.6582C77.4551 9.04102 77.9004 9.23242 78.4395 9.23242C78.8223 9.23242 79.1465 9.1543 79.4121 8.99805C79.6777 8.8418 79.9102 8.63477 80.1094 8.37695L80.7715 
                    8.89258C80.2402 9.70898 79.4434 10.1172 78.3809 10.1172ZM78.2461 4.43359C77.8086 4.43359 77.4414 4.59375 77.1445 4.91406C76.8477 5.23047 76.6641 5.67578 76.5938 6.25H79.7695V6.16797C79.7383 
                    5.61719 79.5898 5.19141 79.3242 4.89062C79.0586 4.58594 78.6992 4.43359 78.2461 4.43359ZM83.1387 3.66016L83.1738 4.45703C83.6582 3.84766 84.291 3.54297 85.0723 3.54297C86.4121 3.54297 87.0879 
                    4.29883 87.0996 5.81055V10H86.0156V5.80469C86.0117 5.34766 85.9062 5.00977 85.6992 4.79102C85.4961 4.57227 85.1777 4.46289 84.7441 4.46289C84.3926 4.46289 84.084 4.55664 83.8184 4.74414C83.5527 4.93164 83.3457 5.17773 83.1973 5.48242V10H82.1133V3.66016H83.1387Z"
        transform="translate(41 13)"
        fill={this.props.color}
      />
      <path
        d="M14.2994 9.15C14.3328 8.88333 14.3578 8.61667 14.3578 8.33333C14.3578 8.05 14.3328 7.78333 14.2994 7.51667L16.0578 6.14167C16.2161 6.01667 16.2578 5.79167 16.1578 5.60833L14.4911 2.725C14.3911 2.54167 
        14.1661 2.475 13.9828 2.54167L11.9078 3.375C11.4744 3.04167 11.0078 2.76667 10.4994 2.55833L10.1828 0.35C10.1578 0.15 9.98278 0 9.77444 0H6.44111C6.23278 0 6.05778 0.15 6.03278 0.35L5.71611 2.55833C5.20778
         2.76667 4.74111 3.05 4.30778 3.375L2.23278 2.54167C2.04111 2.46667 1.82444 2.54167 1.72444 2.725L0.0577763 5.60833C-0.0505571 5.79167 -0.000556968 6.01667 0.157776 6.14167L1.91611 7.51667C1.88278 7.78333 
         1.85778 8.05833 1.85778 8.33333C1.85778 8.60833 1.88278 8.88333 1.91611 9.15L0.157776 10.525C-0.000556968 10.65 -0.0422237 10.875 0.0577763 11.0583L1.72444 13.9417C1.82444 14.125 2.04944 14.1917 2.23278 
         14.125L4.30778 13.2917C4.74111 13.625 5.20778 13.9 5.71611 14.1083L6.03278 16.3167C6.05778 16.5167 6.23278 16.6667 6.44111 16.6667H9.77444C9.98278 16.6667 10.1578 16.5167 10.1828 16.3167L10.4994 
         14.1083C11.0078 13.9 11.4744 13.6167 11.9078 13.2917L13.9828 14.125C14.1744 14.2 14.3911 14.125 14.4911 13.9417L16.1578 11.0583C16.2578 10.875 16.2161 10.65 16.0578 10.525L14.2994 9.15ZM8.10778 
         11.25C6.49944 11.25 5.19111 9.94167 5.19111 8.33333C5.19111 6.725 6.49944 5.41667 8.10778 5.41667C9.71611 5.41667 11.0244 6.725 11.0244 8.33333C11.0244 9.94167 9.71611 11.25 8.10778 11.25Z"
        transform="translate(1.89258 1.6665)"
        fill={this.props.color}
      />
      <path
        d="M9.8392 8.74204C9.86213 8.48726 9.87934 8.23249 9.87934 7.96179C9.87934 7.69109 9.86213 7.43631 9.8392 7.18153L11.0491 5.86784C11.158 5.74841 11.1867 5.53344 11.1179 5.35828L9.97108 2.6035C9.90227 
        2.42834 9.74745 2.36465 9.62131 2.42835L8.19353 3.22452C7.89536 2.90605 7.57426 2.64331 7.22448 2.44427L7.00659 0.334395C6.98939 0.143312 6.86898 0 6.72562 0H4.43202C4.28866 0 4.16825 0.143312 4.15105 
        0.334395L3.93316 2.44427C3.58338 2.64331 3.26228 2.91401 2.96411 3.22452L1.53633 2.42835C1.40445 2.35669 1.25537 2.42834 1.18656 2.6035L0.0397548 5.35828C-0.0347874 5.53344 -0.00038324 5.74841 0.108563
         5.86784L1.31844 7.18153C1.29551 7.43631 1.2783 7.69905 1.2783 7.96179C1.2783 8.22453 1.29551 8.48726 1.31844 8.74204L0.108563 10.0557C-0.00038324 10.1752 -0.0290534 10.3901 0.0397548 10.5653L1.18656
          13.3201C1.25537 13.4952 1.41019 13.5589 1.53633 13.4952L2.96411 12.6991C3.26228 13.0175 3.58338 13.2803 3.93316 13.4793L4.15105 15.5892C4.16825 15.7803 4.28866 15.9236 4.43202 15.9236H6.72562C6.86898 
          15.9236 6.98939 15.7803 7.00659 15.5892L7.22448 13.4793C7.57426 13.2803 7.89536 13.0096 8.19353 12.6991L9.62131 13.4952C9.75319 13.5669 9.90227 13.4952 9.97108 13.3201L11.1179 10.5653C11.1867 10.3901 
          11.158 10.1752 11.0491 10.0557L9.8392 8.74204ZM5.57882 10.7484C4.47215 10.7484 3.57191 9.49841 3.57191 7.96179C3.57191 6.42516 4.47215 5.17516 5.57882 5.17516C6.68549 5.17516 7.58573 6.42516 7.58573
           7.96179C7.58573 9.49841 6.68549 10.7484 5.57882 10.7484Z"
        transform="matrix(0.964881 -0.233719 0.36759 0.893919 8.86816 17.3638)"
        fill={this.props.color}
      />
      <path
        d="M6.37308 5.07933C6.38794 4.9313 6.39908 4.78327 6.39908 4.62598C6.39908 4.4687 6.38794 4.32067 6.37308 4.17264L7.15675 3.40935C7.22732 3.33996 7.24589 3.21506 7.20132 3.11329L6.45851 
        1.5127C6.41394 1.41093 6.31366 1.37392 6.23195 1.41093L5.30715 1.87352C5.11402 1.68848 4.90603 1.53583 4.67947 1.42018L4.53834 0.194291C4.52719 0.0832678 4.4492 0 4.35635 0H2.87072C2.77787 0 2.69988 
        0.0832678 2.68873 0.194291L2.5476 1.42018C2.32104 1.53583 2.11305 1.69311 1.91992 1.87352L0.99512 1.41093C0.909697 1.36929 0.813131 1.41093 0.768563 1.5127L0.0257502 3.11329C-0.0225327 3.21506 -0.000248234 
        3.33996 0.070319 3.40935L0.853986 4.17264C0.83913 4.32067 0.827988 4.47333 0.827988 4.62598C0.827988 4.77864 0.83913 4.9313 0.853986 5.07933L0.070319 5.84262C-0.000248234 5.91201 -0.0188186 6.03691 0.0257502 
        6.13868L0.768563 7.73927C0.813131 7.84104 0.913411 7.87805 0.99512 7.84104L1.91992 7.37845C2.11305 7.56349 2.32104 7.71614 2.5476 7.83179L2.68873 9.05768C2.69988 9.1687 2.77787 9.25197 2.87072 
        9.25197H4.35635C4.4492 9.25197 4.52719 9.1687 4.53834 9.05768L4.67947 7.83179C4.90603 7.71614 5.11402 7.55886 5.30715 7.37845L6.23195 7.84104C6.31737 7.88268 6.41394 7.84104 6.45851 7.73927L7.20132 
        6.13868C7.24589 6.03691 7.22732 5.91201 7.15675 5.84262L6.37308 5.07933ZM3.61353 6.24508C2.89672 6.24508 2.31361 5.5188 2.31361 4.62598C2.31361 3.73317 2.89672 3.00689 3.61353 3.00689C4.33035 3.00689 4.91346 3.73317 4.91346 4.62598C4.91346 5.5188 4.33035 6.24508 3.61353 6.24508Z"
        transform="matrix(0.996399 -0.103973 0.246379 0.963058 22.0801 14.7422)"
        fill={this.props.color}
      />
      </MaterialUI.SvgIcon>
    );
  }


}

export const GeraetezuordnenIcon = (Geraetezuordnen);
