import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import * as MaterialUI from '../../../materialUI/MaterialUI';

const styles = () =>
    MaterialUI.createStyles({
        buttonStyle: { width: 150 },
    });

export interface ISlAlertProps extends WithStyles<typeof styles> {
    open: boolean;
    textOkButton?: string;
    textCancelButton?: string;
    dialogTitle?: string;
    dialogContent?: string;
    children?: any;
    handleOK?: (flag?: boolean) => void;
    handleCancel?: (flag: boolean) => void;
    showCancelButton?: boolean;
}

class SlAlert extends React.Component<ISlAlertProps> {
    public static defaultProps: Partial<ISlAlertProps> = {
        open: false,
        textOkButton: 'OK',
        textCancelButton: 'Abbrechen',
        dialogTitle: '',
        dialogContent: '',
        showCancelButton: true,
    };

    public cancel = () => {
        this.props.handleCancel(true);
    };
    public clickOnOK = () => {
        this.props.handleOK(true);
    };

    public render() {
        return (
            <div>
                <MaterialUI.Dialog open={this.props.open}>
                    {this.props.dialogTitle && (
                        <MaterialUI.DialogTitle style={{ color: 'red' }}>
                            {this.props.dialogTitle}
                        </MaterialUI.DialogTitle>
                    )}
                    <MaterialUI.DialogContent style={{ padding: 24 }}>
                        {this.props.children}
                    </MaterialUI.DialogContent>

                    <MaterialUI.DialogActions style={{ borderTop: '1px solid lightgray' }}>
                        {this.props.showCancelButton && (
                            <MaterialUI.Button
                                onClick={this.cancel}
                                className={this.props.classes.buttonStyle}
                                color="primary"
                            >
                                {this.props.textCancelButton}
                            </MaterialUI.Button>
                        )}
                        <MaterialUI.Button
                            onClick={this.clickOnOK}
                            className={this.props.classes.buttonStyle}
                            color="secondary"
                            autoFocus={true}
                        >
                            {this.props.textOkButton}
                        </MaterialUI.Button>
                    </MaterialUI.DialogActions>
                </MaterialUI.Dialog>
            </div>
        );
    }
}

export const SlAlertComponent = MaterialUI.withStyles(styles)(SlAlert);
