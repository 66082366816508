import { createMuiTheme } from '@material-ui/core';
import { sbColors } from './app.colors';

export const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: sbColors.blue,
            light: '#3333ad'
        },
        secondary: {
            main: sbColors.orange
        },
        error: {
            main: sbColors.red,
        },
        text: {
            primary: sbColors.blue,
        }

    },

    typography: {
        fontFamily: ['Roboto'].join(','),
    },

    overrides: {
        MuiAppBar: {
            root: {
                fontSize: '1.0rem',
                zIndex: 1201
            },
            colorPrimary: {
                backgroundColor: sbColors.blue,
                color: sbColors.white
            }
        },
        MuiFormControl: {
            root: {
                backgroundColor: sbColors.white
            }
        }

    }
});

