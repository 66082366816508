import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { deTranslator } from '../../../../assets/i18n/deTranslator';
import { SlActionBar } from '../../../../common/components/actionbar';
import { ISLActionBar } from '../../../../common/components/actionbar/sl-actionbar.component';
import { SlAlertComponent, SLModal } from '../../../../common/components/modal';
import { BasicTable } from '../../../../common/components/table/mui-table.component';
import { currencyFormatter, isUserRole } from '../../../../common/utils/Functions';
import * as MaterialUI from '../../../../materialUI/MaterialUI';
import { MaterialUIIcons } from '../../../../materialUI/MaterialUIIcons';
import { Roles } from '../../../../model/enums';
import { IForm } from '../../../../model/interfaces/IForm';
import { titleStyles } from '../../../../theme/common.styles';
import { SlFacilityManagementForm } from './facility-management.form';

const styles = (theme: Theme) =>
    MaterialUI.createStyles({
        container: { marginTop: 64 },
    });

interface IState {
    tableActionBarItems: Array<ISLActionBar>;
    showAddModal: boolean;
    editFacilityService: boolean;
    shouldShowDeleteAlert: boolean;
    modalTitle: string;
}

export interface IServiceManagementProps extends WithStyles<typeof styles> {
    serviceList: Array<any>;
    facilityManagementForm: IForm;
    selectedFacilityService?: any;
    onSelectedService?: (item: any) => void;
    onSaveService?: () => void;
    onDelete?: (item: any) => void;
}

class FacilityServiceManagement extends React.Component<IServiceManagementProps, IState> {
    private readonly actionBarItems: Array<ISLActionBar>;

    constructor(props: IServiceManagementProps) {
        super(props);
        this.actionBarItems = [
            {
                label: deTranslator.service.add,
                icon: <MaterialUIIcons.AddBox color={'secondary'} />,
                action: () =>
                    this.setState({
                        ...this.state,
                        showAddModal: false,
                        editFacilityService: false,
                    }),
                disable: true,
            },
            {
                label: deTranslator.service.edit,
                icon: <MaterialUIIcons.Edit color={'secondary'} />,
                action: () =>
                    this.props.selectedFacilityService &&
                    this.setState({
                        showAddModal: true,
                        modalTitle: 'Dienstleistung Bearbeiten',
                        editFacilityService: true,
                    }),
                disable: true,
            },
            {
                label: deTranslator.service.delete,
                icon: <MaterialUIIcons.Delete color={'secondary'} />,
                action: () =>
                    this.props.selectedFacilityService &&
                    this.setState({
                        shouldShowDeleteAlert: true,
                        editFacilityService: false,
                    }),
                disable: true,
            },
        ];

        this.state = {
            tableActionBarItems: this.actionBarItems,
            showAddModal: false,
            editFacilityService: false,
            shouldShowDeleteAlert: false,
            modalTitle: 'Dienstleistung Hinzufügen',
        };
    }

    private buildColumns = () => {
        return [
            {
                title: deTranslator.global.services,
                field: 'ID',
                render: rowData => {
                    const checked = this.props.selectedFacilityService
                        ? this.props.selectedFacilityService.ID === rowData.ID
                        : false;
                    return (
                        <div style={{ display: 'flex', marginLeft: '-20px' }}>
                            {(isUserRole(Roles.Admin) || isUserRole(Roles.SuperUser)) && (
                                <MaterialUI.Radio
                                    onClick={() => this.handleRadioButtonClick(rowData)}
                                    checked={checked}
                                    value={rowData.ID}
                                />
                            )}
                            <span style={{ marginTop: 16 }}>{rowData.ID}</span>
                        </div>
                    );
                },
            },
            { title: 'Preis', field: 'Preis' },
        ];
    };

    public handleRadioButtonClick = item => {
        this.props.onSelectedService(
            this.props.serviceList.find(service => service.ID === item.ID)
        );
    };

    public serviceOperation = () => {
        this.setState({ editFacilityService: false, showAddModal: false });
        this.props.onSelectedService(null);
        this.props.onSaveService();
    };
    public deleteService = () => {
        this.setState({ showAddModal: false, shouldShowDeleteAlert: false });
        this.props.onDelete(this.props.selectedFacilityService);
    };
    private buildTableActions = () => {
        const newValue = this.state.tableActionBarItems.slice(1).map(item => {
            item.disable = this.props.selectedFacilityService ? false : true;
            return item;
        });
        return [this.actionBarItems[0], ...newValue];
    };

    public render() {
        const serviceList = [...this.props.serviceList].map(service => {
            return Object.assign({}, service, { Preis: currencyFormatter(service.Preis / 100) });
        });

        return (
            <div className={this.props.classes.container}>
                <MaterialUI.Typography
                    variant={'h6'}
                    color={'inherit'}
                    style={titleStyles.fontWeight350}
                >
                    Dienstleistungen
                </MaterialUI.Typography>
                <MaterialUI.Divider />
                <div style={{ marginTop: 5 }}>
                    {isUserRole(Roles.Admin) && (
                        <SlActionBar items={this.buildTableActions()} showDivider={false} />
                    )}
                </div>
                <div>
                    <BasicTable
                        columns={[...this.buildColumns()]}
                        data={serviceList}
                        options={serviceList.length > 5 ? { paging: true, pageSize: 5 } : {}}
                    />
                </div>
                <SLModal
                    title={this.state.modalTitle}
                    open={this.state.showAddModal}
                    onOkButtonClick={this.serviceOperation}
                    OkButtonDisabled={this.props.facilityManagementForm.invalid}
                    onClose={() => {
                        this.setState({ showAddModal: false, editFacilityService: false });
                        this.props.onSelectedService(null);
                    }}
                >
                    <SlFacilityManagementForm
                        currentFormValue={this.props.facilityManagementForm.formValue}
                        editMode={this.state.editFacilityService}
                        defaultService={this.props.selectedFacilityService}
                    />
                </SLModal>
                <SlAlertComponent
                    dialogTitle={deTranslator.service.delete}
                    textCancelButton={deTranslator.global.cancel}
                    textOkButton={deTranslator.global.delete}
                    open={this.state.shouldShowDeleteAlert}
                    handleOK={this.deleteService}
                    handleCancel={() => this.setState({ shouldShowDeleteAlert: false })}
                    showCancelButton={true}
                >
                    {`${deTranslator.product.deleteTextStart}`}
                    {this.props.selectedFacilityService && (
                        <b>{this.props.selectedFacilityService.ID}</b>
                    )}
                    {`${deTranslator.product.deleteTextEnd}`}
                </SlAlertComponent>
            </div>
        );
    }
}

export const FacilityServiceManagementComponent = MaterialUI.withStyles(styles)(
    FacilityServiceManagement
);
