import { withStyles } from '@material-ui/styles';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import * as MaterialUI from '../../../materialUI/MaterialUI';

import { sbColors } from '../../../theme/app.colors';
import { TooltipButtonComponent } from '../buttons/tooltip-button.component';

const styles = {};

export const inputDateMask = (props) => {
    const {inputRef, ...other} = props;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            showMask={false}
            pipe={autoCorrectedDatePipe}
        />
    );
};

const CustomizedDateInputs = (inputProps) => {
    const {
        input,
        label,
        type,
        options,
        rows,
        readOnly,
        disabled,
        required,
        style,
        meta: {touched, error, warning}
    } = inputProps;


    return (
        <MaterialUI.TextField
            variant={'outlined'}
            label={label}
            type={type}
            style={{...style}}
            placeholder={'TT.MM.JJJJ'}
            {...input}
            error={touched && error ? true : false}
            disabled={disabled ? disabled : false}
            rows={rows ? rows : 1}
            helperText={touched && ((error && <span>{error}</span>) ||
                (warning && <span style={{color: sbColors.orange}}>{warning}</span>))}
            InputProps={{
                inputComponent: inputDateMask,
                readOnly: readOnly ? readOnly : false,
                classes: {
                    input: options && options.inputFieldClass,
                },
                endAdornment: options && options.showInfoIcon && <TooltipButtonComponent text={options.infoText}/>
            }}
            InputLabelProps={{
                shrink: options && options.shrink ? options.shrink : true,
            }}
            fullWidth={options && options.fullWidth}
            required={required}
        />
    );
};


export const SlDateInputComponent = withStyles(styles)(CustomizedDateInputs);